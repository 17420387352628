import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AuditLogResourceType } from 'src/core/apollo/__generated__/logsGlobalTypes';
import { selectUserId } from 'src/core/store/global/globalSlice';
import { LogsTable } from 'src/materials/logs/public';

const LogsPageComponent: React.FC<{}> = () => {
  const userId = useSelector(selectUserId)!;
  const resourceIds = useMemo(() => [{ key: userId, displayValue: '' }], [userId]);

  return <LogsTable relatedSiteId={undefined} resources={resourceIds} resourceType={AuditLogResourceType.USER} />;
};

export default LogsPageComponent;
