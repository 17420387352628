import { Group } from '@visx/group';
import { extent } from 'd3-array';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useMemo } from 'react';

import { getTimestampFormat } from 'src/cdk/formatter/relativeTimeFormat';
import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { METRIC_SYMBOL, N_A, UNIT } from 'src/constants';
import { MetricsMeasurementType, MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { toastService } from 'src/core/service/toastService';
import { ColorConfig, MeasurementToTitle } from 'src/enums';
import { DateRangeWithIntervals } from 'src/shared/components/DatePicker/SmartDateRangePicker/DateRangePickerWithIntervals';
import ComposableChartGrid from 'src/shared/components/charts/ComposableChart/ComposableChartGrid';
import ComposableChartHeatmapRow, {
  calcHeatmapRowVerticalOffset,
} from 'src/shared/components/charts/ComposableChart/ComposableChartHeatmapRow';
import ComposableChartLine from 'src/shared/components/charts/ComposableChart/ComposableChartLine';
import ComposableChartTooltip from 'src/shared/components/charts/ComposableChart/ComposableChartTooltip';
import ComposableChartWrapper from 'src/shared/components/charts/ComposableChart/ComposableChartWrapper';
import ComposableChartXAxis from 'src/shared/components/charts/ComposableChart/ComposableChartXAxis';
import ComposableChartYAxis from 'src/shared/components/charts/ComposableChart/ComposableChartYAxis';
import ComposableTooltipTable, {
  buildValuesForTooltipDataItem,
  TooltipDataItem,
} from 'src/shared/components/charts/ComposableChart/ComposableTooltipTable';
import { useChartContext } from 'src/shared/components/charts/ComposableChart/chartContext';
import { getMaxMinutesForStepFromFilter } from 'src/shared/components/charts/ComposableChart/utils';

import {
  ISystemMetricData,
  getMetricsDataForSystemWithIntervals,
} from '../../../gql/getMetricDataWithIntervals.resources.gql';
import { MetricWithData } from '../../../interface';

interface DataSource {
  metricId: number;
  metricData: ISystemMetricData[];
  yExtent: [number, number];
  isEmpty: boolean;
  xDomain: [Date, Date];
}

interface Axis {
  unit: UNIT;
  yDomain: [number, number];
  data: DataSource[];
}

interface Source {
  xDomain: Date[];
  leftAxis: Axis;
  rightAxis?: Axis;
  usageMetricsData: DataSource[];
  flatData: ISystemMetricData[];
  isEmpty: boolean;
  spaceAllocatedForUsage: number;
}

const DEFAULT_SOURCE: Source = {
  xDomain: [0, 0] as unknown as [Date, Date],
  leftAxis: {
    unit: UNIT.NUMBER,
    yDomain: [0, 0],
    data: [],
  },
  usageMetricsData: [],
  flatData: [],
  isEmpty: true,
  spaceAllocatedForUsage: 0,
};

interface Props {
  dateTimeFilter: DateRangeWithIntervals;
  timezone: string;
  selectedMetrics: Omit<MetricWithData, 'selected'>[]; // withou data
}

/**
 * This component is used to load and render system metrics data
 */
const SystemMetricsChart: React.FC<Props> = (props) => {
  const metricsMap = _.keyBy(props.selectedMetrics, 'id');

  const maxUsageValue = getMaxMinutesForStepFromFilter(props.dateTimeFilter);

  const { isLoading, response: source = DEFAULT_SOURCE } = useDataFetchOnMountWithDeps(
    async () => {
      // If no metrics were selected - return empty data
      if (_.isEmpty(props.selectedMetrics)) {
        return DEFAULT_SOURCE;
      }

      let response: ISystemMetricData[] = [];

      try {
        response = await getMetricsDataForSystemWithIntervals(
          props.selectedMetrics.map((i) => i.id),
          props.dateTimeFilter,
          props.timezone,
          _.fromPairs(props.selectedMetrics.map((i) => [i.id, i.type]))
        );
      } catch (error) {
        toastService.error('Failed to load metrics data');
        return DEFAULT_SOURCE;
      }

      const dataSources: DataSource[] = _.chain(response)
        .groupBy('systemMetricId')
        .toPairs()
        .orderBy(([metricId]) => metricsMap[metricId].measurement, 'asc')
        .map(([metricId, responseData]) => {
          return {
            metricId: Number(metricId),
            metricData: responseData.flat(),
            isEmpty: responseData.every((i) => i.value === null),
            yExtent: extent(responseData, (i) => i.value),
            xDomain: responseData.map((i) => i.timestamp),
          } as DataSource;
        })
        .value();

      // All metrics data within same range will return have same X axis range/values
      // so it is enough to take only first
      const xDomain = _.uniq(_.head(dataSources)?.xDomain ?? []);

      const [usageMetrics, nonUsageMetrics] = _.partition(
        dataSources,
        (i) => metricsMap[i.metricId].measurement === MetricsMeasurementType.USAGE
      );

      // Different metrics has different extents so we need to find highest and lowest values
      // across all metrics within same measurement type
      const [leftAxis, rightAxis] = _.chain(nonUsageMetrics)
        .groupBy((i) => metricsMap[i.metricId].measurement)
        .toPairs()
        .take(2)
        .map(([measurement, source]) => {
          const flatYExtents: number[] = source.flatMap((i) => i.yExtent).filter((i) => !_.isNil(i));

          return {
            unit: METRIC_SYMBOL[measurement as MetricsMeasurementType],
            yDomain: [_.min(flatYExtents) ?? 0, _.max(flatYExtents) ?? 0],
            data: source,
          } as Axis;
        })
        .value();

      const source: Source = {
        usageMetricsData: usageMetrics,
        flatData: response,
        xDomain,
        leftAxis: leftAxis || DEFAULT_SOURCE.leftAxis,
        rightAxis,
        isEmpty: dataSources.every((i) => i.isEmpty),
        spaceAllocatedForUsage: calcHeatmapRowVerticalOffset(usageMetrics.length),
      };

      return source;
    },
    [props.dateTimeFilter, props.selectedMetrics],
    true
  );

  return (
    <>
      <ComposableChartWrapper
        xDomain={source.xDomain}
        yDomain={source.leftAxis.yDomain}
        height='60vh'
        isLoading={isLoading}
        isEmpty={source.isEmpty}
        extraSpaceBetweenXAxisAndChart={source.spaceAllocatedForUsage}
      >
        <ComposableChartGrid />
        <ComposableChartXAxis top={source.spaceAllocatedForUsage} />
        <ComposableChartYAxis label={source.leftAxis.unit} />
        <ComposableChartYAxis
          right
          label={source.rightAxis?.unit ?? source.leftAxis.unit}
          yDomain={source.rightAxis?.yDomain}
        />

        {source.leftAxis.data.map((i) => (
          <ComposableChartLine
            key={i.metricId}
            styleVariant={metricsMap[i.metricId]?.styleVariant ?? ColorConfig.blueDottedOpaque}
            data={i.metricData}
          />
        ))}

        {source.rightAxis?.data.map((i) => (
          <ComposableChartLine
            key={i.metricId}
            styleVariant={metricsMap[i.metricId]?.styleVariant ?? ColorConfig.blueDottedOpaque}
            data={i.metricData}
            yDomain={source.rightAxis?.yDomain}
          />
        ))}

        <UsageGroup>
          {source.usageMetricsData.map((i, index) => (
            <ComposableChartHeatmapRow
              key={i.metricId}
              data={i.metricData}
              maxValue={maxUsageValue}
              styleVariant={metricsMap[i.metricId]?.styleVariant ?? ColorConfig.blueDottedOpaque}
              top={calcHeatmapRowVerticalOffset(index)}
            />
          ))}
        </UsageGroup>

        <ComposableChartTooltip<ISystemMetricData>
          verticalPadding={source.spaceAllocatedForUsage}
          data={source.flatData}
          buildTooltipContent={(xValue, dataAtPointedIndex) => {
            return (
              <TooltipContent
                metricsMap={metricsMap}
                xValue={xValue}
                step={props.dateTimeFilter.step}
                dataAtPointedIndex={dataAtPointedIndex}
              />
            );
          }}
        />
      </ComposableChartWrapper>
    </>
  );
};

/**
 * Private component move usage lines to the bottom of the chart
 */
const UsageGroup: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { yMax } = useChartContext();

  return <Group top={yMax}>{children}</Group>;
};

interface TooltipContentProps {
  xValue: Date;
  step: MetricsStep;
  dataAtPointedIndex: ISystemMetricData[];
  metricsMap: _.Dictionary<Omit<MetricWithData, 'selected'>>;
}

/**
 * Private component to render tooltip content for current chart
 */
function TooltipContent({ xValue, step, dataAtPointedIndex, metricsMap }: TooltipContentProps): JSX.Element {
  const timestampTitle = format(xValue, getTimestampFormat(step));

  const tooltipDataItemsGroupedByMetrics: Array<{
    metricLabel: string;
    unit: UNIT;
    unitNearValue: boolean;
    data: TooltipDataItem[];
  }> = useMemo(() => {
    const [usageMetrics, nonUsageMetrics] = _.partition(
      dataAtPointedIndex.filter((i) => metricsMap[i.systemMetricId]),
      (i) => metricsMap[i.systemMetricId].measurement === MetricsMeasurementType.USAGE
    );

    // Order by:
    // 1. measurement type
    // 2. put empty values in the bottom
    // 3. value
    // 4. name
    // Put usage data in the end
    const orderedData = [
      ..._.orderBy(
        nonUsageMetrics,
        [
          (i) => metricsMap[i.systemMetricId].measurement,
          (i) => _.isNull(i.value),
          (i) => Number(i.value),
          (i) => metricsMap[i.systemMetricId].name,
        ],
        ['asc', 'asc', 'desc', 'asc']
      ),
      // Usage always goes last
      ..._.reverse(usageMetrics),
    ];

    // Group metric by type and prepare data to display in tooltip
    const result: Array<{
      metricLabel: string;
      unitNearValue: boolean;
      unit: UNIT;
      data: TooltipDataItem[];
    }> = _.chain(orderedData)
      .groupBy((i) => metricsMap[i.systemMetricId].measurement)
      .toPairs()
      .map(([measurement, items]) => {
        const showMinMax = measurement !== MetricsMeasurementType.USAGE && step !== MetricsStep.FIFTEEN_MINUTES;

        const data = items.map((i) => {
          const metric = metricsMap[i.systemMetricId];

          const result: TooltipDataItem = {
            id: metric.id,
            renderAs: metric.measurement === MetricsMeasurementType.USAGE ? 'square' : 'line',
            styleVariant: metric.styleVariant ?? ColorConfig.whiteSolidTranslucent,
            label: metric.name ?? N_A,
            values: buildValuesForTooltipDataItem(i, showMinMax, metric.precision),
          };

          return result;
        });

        return {
          metricLabel: MeasurementToTitle[measurement as MetricsMeasurementType],
          unit: METRIC_SYMBOL[measurement as MetricsMeasurementType],
          unitNearValue: !showMinMax,
          data,
        };
      })
      .value();

    return result;
  }, [dataAtPointedIndex, metricsMap, step]);

  return (
    <div>
      <p className='body-semi-bold'>{timestampTitle}</p>
      {tooltipDataItemsGroupedByMetrics.map((group, i) => (
        <ComposableTooltipTable
          key={group.metricLabel}
          items={group.data}
          unit={group.unit}
          title={i === 0 ? 'Metric' : ''}
          valuesTitle={group.metricLabel}
        />
      ))}
    </div>
  );
}

export default SystemMetricsChart;
