import classnames from 'classnames';

import { Alert } from 'src/materials/alerts/Alert/Alert';
import { Button } from 'src/shared/components/Button/Button';
import { CardLayout } from 'src/shared/containers/UniversalCard/UniversalCard';

import styles from './LayoutWithSettingsAndAlarm.module.scss';

export interface LayoutWithSettingsAndAlarmProps {
  disabledSettings: boolean;
  hideSettings: boolean;
  hideAlarm: boolean;
  layout: CardLayout;
  onSettingsClick: () => void;
  children?: React.ReactNode;
  systemId: number;
  siteId: number;
}

const LayoutWithSettingsAndAlarm: React.FC<LayoutWithSettingsAndAlarmProps> = ({
  children,
  disabledSettings,
  hideSettings,
  hideAlarm,
  onSettingsClick,
  layout,
  systemId,
  siteId,
}) => {
  const buttonSize = layout === CardLayout.list ? 'small' : 'regular';
  return (
    <div className={classnames(styles['container'], styles[layout])}>
      <div className={classnames(styles['buttons'], 'buttons')}>
        {hideSettings ? (
          // Need to reserve space, otherwise flex will shuffle all elements
          <span />
        ) : (
          <Button icon='gear' size={buttonSize} shape='rounded' onClick={onSettingsClick} disabled={disabledSettings} />
        )}
        {hideAlarm ? (
          // Need to reserve space, otherwise flex will shuffle all elements
          <span />
        ) : (
          <div className={styles['tooltip-div']}>
            <Alert siteId={siteId} systemId={systemId} big={buttonSize === 'regular'} />
          </div>
        )}
      </div>
      <div className='d-flex'>{children}</div>
    </div>
  );
};

export default LayoutWithSettingsAndAlarm;
