import { persistentStorage } from 'src/core/service/persistentStorage';
import { PersistentStorageKeys } from 'src/enums';
import { CardLayout } from 'src/shared/containers/UniversalCard/UniversalCard';

class SystemCardLayoutService {
  init(): CardLayout {
    const layout = persistentStorage.getItem(PersistentStorageKeys.SelectedSystemLayout);
    if (layout) {
      return layout as CardLayout;
    }
    return CardLayout.vertical;
  }

  setLayout(layout: CardLayout): void {
    persistentStorage.setItem(PersistentStorageKeys.SelectedSystemLayout, layout);
  }
}

export const systemCardLayoutService = new SystemCardLayoutService();
