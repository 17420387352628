import type * as Types from '../../../../core/apollo/__generated__/alertsGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetSystemTypeConditionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSystemTypeConditionsQuery = {
  systemTypeConditions: Array<{
    systemType: Types.SystemType;
    calculationConditionEvents: Array<Types.CalculationConditionEvent>;
    instantConditionEvents: Array<Types.InstantConditionEvent>;
    metricTypesAsConditions: Array<{ metric: Types.MetricType; measurement: Types.MetricsMeasurementType }>;
  }>;
};

export type GetAlertQueryVariables = Types.Exact<{
  alertId: Types.Scalars['Int']['input'];
}>;

export type GetAlertQuery = {
  alertDetails: {
    organizationId: number;
    siteId: number;
    floors?: Array<string> | null;
    systemTypes?: Array<Types.SystemType> | null;
    systemIds?: Array<number> | null;
    createdAt: Date;
    updatedAt: Date;
    type: Types.AlertType;
    id: number;
    color: Types.AlertColor;
    name: string;
    description?: string | null;
    expression: string;
    recipients: Array<{ userId: string; actions: Array<Types.AlertAction> }>;
    schedule?: Array<{ hours: Array<number>; weekday: Types.WeekDays }> | null;
    conditions: Array<
      | {
          __typename: 'CalculationCondition';
          id: number;
          name: string;
          calculationEvent: Types.CalculationConditionEvent;
          arg1?: string | null;
          arg2?: string | null;
          lastHoursPeriod?: string | null;
          source?: { systemIds?: Array<number> | null } | null;
        }
      | {
          __typename: 'InstantCondition';
          id: number;
          name: string;
          instantEvent: Types.InstantConditionEvent;
          source?: { systemIds?: Array<number> | null } | null;
        }
      | {
          __typename: 'MetricCondition';
          id: number;
          name: string;
          metricEvent: Types.MetricsConditionEvent;
          metricType: Types.MetricType;
          arg1?: string | null;
          arg2?: string | null;
          source?: { systemIds?: Array<number> | null } | null;
        }
    >;
  };
};

export type CreateAlertMutationVariables = Types.Exact<{
  createAlertInput: Types.CreateAlertInput;
}>;

export type CreateAlertMutation = { createAlert: string };

export type UpdateAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['Int']['input'];
  updateAlertInput: Types.UpdateAlertInput;
}>;

export type UpdateAlertMutation = { updateAlert: { id: number } };

export const GetSystemTypeConditionsDocument = gql`
  query GetSystemTypeConditions {
    systemTypeConditions {
      systemType
      calculationConditionEvents
      instantConditionEvents
      metricTypesAsConditions {
        metric
        measurement
      }
    }
  }
`;
export const GetAlertDocument = gql`
  query GetAlert($alertId: Int!) {
    alertDetails(alertId: $alertId) {
      organizationId
      siteId
      floors
      systemTypes
      systemIds
      createdAt
      updatedAt
      recipients {
        userId
        actions
      }
      type
      id
      color
      name
      description
      expression
      schedule {
        hours
        weekday
      }
      conditions {
        __typename
        ... on CalculationCondition {
          id
          source {
            systemIds
          }
          name
          calculationEvent
          arg1
          arg2
          lastHoursPeriod
        }
        ... on InstantCondition {
          id
          source {
            systemIds
          }
          name
          instantEvent
        }
        ... on MetricCondition {
          id
          source {
            systemIds
          }
          name
          metricEvent
          metricType
          arg1
          arg2
        }
      }
    }
  }
`;
export const CreateAlertDocument = gql`
  mutation CreateAlert($createAlertInput: CreateAlertInput!) {
    createAlert(createAlertInput: $createAlertInput)
  }
`;
export const UpdateAlertDocument = gql`
  mutation UpdateAlert($alertId: Int!, $updateAlertInput: UpdateAlertInput!) {
    updateAlert(alertId: $alertId, updateAlertInput: $updateAlertInput) {
      id
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetSystemTypeConditions(
      variables?: GetSystemTypeConditionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSystemTypeConditionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSystemTypeConditionsQuery>(GetSystemTypeConditionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSystemTypeConditions',
        'query',
        variables
      );
    },
    GetAlert(variables: GetAlertQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAlertQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAlertQuery>(GetAlertDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetAlert',
        'query',
        variables
      );
    },
    CreateAlert(
      variables: CreateAlertMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateAlertMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateAlertMutation>(CreateAlertDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateAlert',
        'mutation',
        variables
      );
    },
    UpdateAlert(
      variables: UpdateAlertMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateAlertMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateAlertMutation>(UpdateAlertDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateAlert',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
