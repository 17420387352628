export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: DateStr; output: Date };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date };
  /** GraphQLMonthYear custom scalar type in format yyyy-MM */
  GraphQLMonthYear: { input: YearMonthStr; output: Date };
};

export type Benchmark = {
  cityName: Scalars['String']['output'];
  grossFloorArea: Scalars['Int']['output'];
  propertyId: Scalars['Int']['output'];
  propertyName: Scalars['String']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  siteEui?: Maybe<Scalars['Float']['output']>;
  siteId: Scalars['Int']['output'];
  siteTotalEnergyUse?: Maybe<Scalars['Float']['output']>;
  totalCarbonEmissions?: Maybe<Array<EnergyUsageByUtility>>;
  totalUsage?: Maybe<Array<EnergyUsageByUtility>>;
  year: Scalars['Int']['output'];
};

export type BenchmarkIntegrationBySite = {
  cityName: Scalars['String']['output'];
  integrationType: BenchmarkIntegrationType;
  propertyId: Scalars['Int']['output'];
  propertyName: Scalars['String']['output'];
  siteId: Scalars['Int']['output'];
};

export enum BenchmarkIntegrationType {
  ENERGY_STAR = 'ENERGY_STAR',
}

export type BenchmarkKPI = {
  generalData: GeneralData;
  kpi: KPIData;
};

export enum BenchmarkMetricType {
  ENERGY_USAGE = 'ENERGY_USAGE',
  ENERGY_USAGE_CARBON_EMISSIONS = 'ENERGY_USAGE_CARBON_EMISSIONS',
  ENERGY_USAGE_COST = 'ENERGY_USAGE_COST',
}

export enum CarbonEmissionUnit {
  KILO_GRAMS = 'KILO_GRAMS',
  METRIC_TON = 'METRIC_TON',
  MILLION_POUNDS = 'MILLION_POUNDS',
}

export enum ConedORUScopes {
  BILLING = 'BILLING',
  CONSUMPTION = 'CONSUMPTION',
  REAL_TIME = 'REAL_TIME',
  RETAIL_CUSTOMER = 'RETAIL_CUSTOMER',
}

export type ConedOruMeterExtraFieldsInput = {
  authorizationUID: Scalars['String']['input'];
};

export type ConedOruMeterIntegration = {
  authorizationUID: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expiry?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  meterType: UtilityServiceTypes;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  status: Scalars['Boolean']['output'];
  type: MeterIntegrationType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
  utility: Scalars['String']['output'];
};

export enum ConsumptionUnit {
  GALLONS = 'GALLONS',
  HCF = 'HCF',
  KBTU = 'KBTU',
  KWH = 'KWH',
  MILLION_POUNDS = 'MILLION_POUNDS',
  THERMS = 'THERMS',
}

export type CreateConedOruAuthorizationInput = {
  dataCustodianId: DataCustodian;
  endDate: Scalars['DateTime']['input'];
  maidId: Scalars['String']['input'];
  scope: Array<ConedORUScopes>;
  startDate: Scalars['DateTime']['input'];
};

export type CreateSystemDRConfigInput = {
  enable: Scalars['Boolean']['input'];
  enableLevel1: Scalars['Boolean']['input'];
  enableLevel2: Scalars['Boolean']['input'];
  enablePrecurtailment: Scalars['Boolean']['input'];
  level1Settings?: InputMaybe<DRSettingsInput>;
  level2Settings?: InputMaybe<DRSettingsInput>;
  precurtailmentSettings?: InputMaybe<DRSettingsInput>;
  settingsIntervalInMin?: InputMaybe<Scalars['Int']['input']>;
  systemId: Scalars['Int']['input'];
};

export enum DRAggregator {
  CPOWER = 'CPOWER',
}

export type DRConfigInfo = {
  enabled: Scalars['Boolean']['output'];
  hasLevel1Settings: Scalars['Boolean']['output'];
  hasLevel2Settings: Scalars['Boolean']['output'];
  hasPreEventSettings: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  settingsInterval?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DREvent = {
  duration: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  programName?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  status: DRStatusType;
  systemsControlled?: Maybe<Scalars['Int']['output']>;
  totalSystems: Scalars['Int']['output'];
};

export enum DREventSource {
  AUTOMATED = 'AUTOMATED',
  MANUAL = 'MANUAL',
}

export enum DREventStateStatusType {
  FAILED = 'FAILED',
  OVERRIDDEN = 'OVERRIDDEN',
  SKIPPED = 'SKIPPED',
  SUCCESS = 'SUCCESS',
}

export type DREventSummary = {
  curtailment?: Maybe<DREventStateStatusType>;
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  precurtailment?: Maybe<DREventStateStatusType>;
  summary?: Maybe<Scalars['String']['output']>;
  systemFloor: Scalars['String']['output'];
  systemId: Scalars['Int']['output'];
  systemName: Scalars['String']['output'];
  systemType: SystemType;
  system_restore?: Maybe<DREventStateStatusType>;
};

export type DRMeter = {
  aggregator?: Maybe<DRAggregator>;
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
};

export type DRMeterExtraFieldsInput = {
  drAggregator: DRAggregator;
};

export type DRMeterIntegration = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  drMeterAggregator?: Maybe<DRAggregator>;
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  type: MeterIntegrationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type DRSettings = {
  fan?: Maybe<FanType>;
  focusAlgorithm?: Maybe<LearningAlgorithmType>;
  mode?: Maybe<ModeType>;
  occupiedSetpointCool?: Maybe<Scalars['Int']['output']>;
  occupiedSetpointHeat?: Maybe<Scalars['Int']['output']>;
  outsideTempMax?: Maybe<Scalars['Int']['output']>;
  outsideTempMin?: Maybe<Scalars['Int']['output']>;
  sequenceId?: Maybe<Scalars['Int']['output']>;
  setLock?: Maybe<LockType>;
  setpoint?: Maybe<Scalars['Int']['output']>;
  setpointCool?: Maybe<Scalars['Int']['output']>;
  setpointHeat?: Maybe<Scalars['Int']['output']>;
  setpointMax?: Maybe<Scalars['Int']['output']>;
  setpointMin?: Maybe<Scalars['Int']['output']>;
  shiftBetweenCoolStages?: Maybe<Scalars['Int']['output']>;
  space?: Maybe<SpaceType>;
  systemState?: Maybe<SystemStatus>;
  unoccupiedSetpointCool?: Maybe<Scalars['Int']['output']>;
  unoccupiedSetpointHeat?: Maybe<Scalars['Int']['output']>;
};

export type DRSettingsInput = {
  fan?: InputMaybe<FanType>;
  focusAlgorithm?: InputMaybe<LearningAlgorithmType>;
  mode?: InputMaybe<ModeType>;
  occupiedSetpointCool?: InputMaybe<Scalars['Int']['input']>;
  occupiedSetpointHeat?: InputMaybe<Scalars['Int']['input']>;
  outsideTempMax?: InputMaybe<Scalars['Int']['input']>;
  outsideTempMin?: InputMaybe<Scalars['Int']['input']>;
  sequenceId?: InputMaybe<Scalars['Int']['input']>;
  setLock?: InputMaybe<LockType>;
  setpoint?: InputMaybe<Scalars['Int']['input']>;
  setpointCool?: InputMaybe<Scalars['Int']['input']>;
  setpointHeat?: InputMaybe<Scalars['Int']['input']>;
  setpointMax?: InputMaybe<Scalars['Int']['input']>;
  setpointMin?: InputMaybe<Scalars['Int']['input']>;
  shiftBetweenCoolStages?: InputMaybe<Scalars['Int']['input']>;
  space?: InputMaybe<SpaceType>;
  systemState?: InputMaybe<SystemStatus>;
  unoccupiedSetpointCool?: InputMaybe<Scalars['Int']['input']>;
  unoccupiedSetpointHeat?: InputMaybe<Scalars['Int']['input']>;
};

export enum DRStatusType {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  PRE_EVENT = 'PRE_EVENT',
  RECEIVED = 'RECEIVED',
}

export type DRSystemInfo = {
  floor: Scalars['String']['output'];
  floorType: FloorType;
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['Int']['output']>;
  meterName?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: SystemType;
};

export enum DataCustodian {
  CONED = 'CONED',
  ORU = 'ORU',
}

export type EnergyStarCoefficient = {
  carbonEmissionUnit: CarbonEmissionUnit;
  carbonEmissionValue: Scalars['Float']['output'];
  meterType: MeterType;
  perConsumptionUnit: ConsumptionUnit;
  regionId?: Maybe<Scalars['Int']['output']>;
  siteId?: Maybe<Scalars['Int']['output']>;
  usdCostValue: Scalars['Float']['output'];
};

export type EnergyStarCoefficientsInput = {
  carbonEmissionUnit: CarbonEmissionUnit;
  carbonEmissionValue: Scalars['Float']['input'];
  meterType: MeterType;
  perConsumptionUnit: ConsumptionUnit;
  usdCostValue: Scalars['Float']['input'];
};

export type EnergyStarMeterIntegration = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  energyStarMeterType: MeterType;
  expiry?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  propertyId: Scalars['Int']['output'];
  propertyName: Scalars['String']['output'];
  siteId: Scalars['Int']['output'];
  status: Scalars['Boolean']['output'];
  type: MeterIntegrationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type EnergyStarProperty = {
  cityName: Scalars['String']['output'];
  propertyId: Scalars['Int']['output'];
  propertyName: Scalars['String']['output'];
};

export type EnergyUsageByUtility = {
  meterType: MeterType;
  usage: Scalars['Float']['output'];
};

export enum FanType {
  AUTO = 'AUTO',
  CONTINUOUS = 'CONTINUOUS',
  FAN_CIRCULATE = 'FAN_CIRCULATE',
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  OFF = 'OFF',
  ON = 'ON',
}

export enum FloorType {
  BASEMENT = 'BASEMENT',
  CELLAR = 'CELLAR',
  LOBBY = 'LOBBY',
  MEZZANINE = 'MEZZANINE',
  NO_FLOOR = 'NO_FLOOR',
  NUMBER = 'NUMBER',
  ROOF = 'ROOF',
  SUBCELLAR = 'SUBCELLAR',
}

export type GeneralData = {
  primaryUse: Scalars['String']['output'];
  squareFeet: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type IntegrationFiltersInput = {
  meterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  meterUIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  siteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  types?: InputMaybe<Array<MeterIntegrationType>>;
};

export type KPIData = {
  ci: Scalars['Float']['output'];
  eci: Scalars['Float']['output'];
  eui: Scalars['Float']['output'];
  percentMoreThanMedianCI: Scalars['Float']['output'];
  percentMoreThanMedianECI: Scalars['Float']['output'];
  percentMoreThanMedianEUI: Scalars['Float']['output'];
  percentMoreThanMedianScore: Scalars['Float']['output'];
  score: Scalars['Int']['output'];
};

export enum LearningAlgorithmType {
  BALANCE = 'BALANCE',
  COMFORT = 'COMFORT',
  ENERGY = 'ENERGY',
}

export enum LockType {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export type MeterIntegration = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  type: MeterIntegrationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type MeterIntegrationFilterOption = {
  meterId: Scalars['Int']['output'];
  meterUID?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MeterIntegrationInput = {
  conedOruMeterFields?: InputMaybe<ConedOruMeterExtraFieldsInput>;
  drMeterFields?: InputMaybe<DRMeterExtraFieldsInput>;
  meterId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
  type: MeterIntegrationType;
  utilityAPIMeterFields?: InputMaybe<UtilityAPIMeterExtraFieldsInput>;
};

export enum MeterIntegrationType {
  CONED = 'CONED',
  DEMAND_RESPONSE = 'DEMAND_RESPONSE',
  ENERGY_STAR_PORTFOLIO = 'ENERGY_STAR_PORTFOLIO',
  ORU = 'ORU',
  UTILITY_API = 'UTILITY_API',
}

export type MeterIntegrationUnion =
  | ConedOruMeterIntegration
  | DRMeterIntegration
  | EnergyStarMeterIntegration
  | UtilityAPIMeterIntegration;

export type MeterMetricDataIntervalsInput = {
  from: Scalars['DateTime']['input'];
  hours?: InputMaybe<Array<Scalars['Int']['input']>>;
  previousInterval?: InputMaybe<TimeInterval>;
  step: MetricsStep;
  to: Scalars['DateTime']['input'];
  weekDays: Array<Scalars['Int']['input']>;
};

export enum MeterType {
  AVG_INFLUENT_FLOW = 'AVG_INFLUENT_FLOW',
  COAL_ANTHRACITE = 'COAL_ANTHRACITE',
  COAL_BITUMINOUS = 'COAL_BITUMINOUS',
  COKE = 'COKE',
  DIESEL = 'DIESEL',
  DISTRICT_CHILLED_WATER_ABSORPTION_NG = 'DISTRICT_CHILLED_WATER_ABSORPTION_NG',
  DISTRICT_CHILLED_WATER_ELECTRIC = 'DISTRICT_CHILLED_WATER_ELECTRIC',
  DISTRICT_CHILLED_WATER_ENGINE_NG = 'DISTRICT_CHILLED_WATER_ENGINE_NG',
  DISTRICT_CHILLED_WATER_OTHER = 'DISTRICT_CHILLED_WATER_OTHER',
  DISTRICT_HOT_WATER = 'DISTRICT_HOT_WATER',
  DISTRICT_STEAM = 'DISTRICT_STEAM',
  ELECTRIC = 'ELECTRIC',
  ELECTRIC_SOLAR = 'ELECTRIC_SOLAR',
  ELECTRIC_WIND = 'ELECTRIC_WIND',
  FUEL_OIL_NO_1 = 'FUEL_OIL_NO_1',
  FUEL_OIL_NO_2 = 'FUEL_OIL_NO_2',
  FUEL_OIL_NO_4 = 'FUEL_OIL_NO_4',
  FUEL_OIL_NO_5_OR_6 = 'FUEL_OIL_NO_5_OR_6',
  IT_EQUIPMENT_INPUT = 'IT_EQUIPMENT_INPUT',
  KEROSENE = 'KEROSENE',
  MUNICIPALLY_SUPPLIED_POTABLE_WATER_INDOOR = 'MUNICIPALLY_SUPPLIED_POTABLE_WATER_INDOOR',
  MUNICIPALLY_SUPPLIED_POTABLE_WATER_MIXED = 'MUNICIPALLY_SUPPLIED_POTABLE_WATER_MIXED',
  MUNICIPALLY_SUPPLIED_POTABLE_WATER_OUTDOOR = 'MUNICIPALLY_SUPPLIED_POTABLE_WATER_OUTDOOR',
  MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_INDOOR = 'MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_INDOOR',
  MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_MIXED = 'MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_MIXED',
  MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_OUTDOOR = 'MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_OUTDOOR',
  NATURAL_GAS = 'NATURAL_GAS',
  OTHER_ENERGY = 'OTHER_ENERGY',
  OTHER_INDOOR_WATER = 'OTHER_INDOOR_WATER',
  OTHER_MIXED_WATER = 'OTHER_MIXED_WATER',
  OTHER_OUTDOOR_WATER = 'OTHER_OUTDOOR_WATER',
  POWER_DISTRIBUTION_UNIT_INPUT = 'POWER_DISTRIBUTION_UNIT_INPUT',
  POWER_DISTRIBUTION_UNIT_OUTPUT = 'POWER_DISTRIBUTION_UNIT_OUTPUT',
  PROPANE = 'PROPANE',
  UNINTERRUPTIBLE_POWER_SUPPLY_OUTPUT = 'UNINTERRUPTIBLE_POWER_SUPPLY_OUTPUT',
  WELL_WATER_INDOOR = 'WELL_WATER_INDOOR',
  WELL_WATER_MIXED = 'WELL_WATER_MIXED',
  WELL_WATER_OUTDOOR = 'WELL_WATER_OUTDOOR',
  WOOD = 'WOOD',
}

export type MeterValuesByMonth = {
  key: Scalars['String']['output'];
  values?: Maybe<Array<ValuesByMonth>>;
};

export type MeterValuesByYear = {
  meterType: MeterType;
  values?: Maybe<Array<ValuesByYear>>;
};

export type MetricFields = {
  exists: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export enum MetricType {
  AVG_SPACE_TEMPERATURE = 'AVG_SPACE_TEMPERATURE',
  BATTERY = 'BATTERY',
  CDD_TEMPERATURE = 'CDD_TEMPERATURE',
  COOLING = 'COOLING',
  COOLING_2 = 'COOLING_2',
  COOLING_3 = 'COOLING_3',
  DAMPER_EFFECTIVE = 'DAMPER_EFFECTIVE',
  DAMPER_POSITION = 'DAMPER_POSITION',
  DHW_TEMPERATURE = 'DHW_TEMPERATURE',
  EFFICIENCY = 'EFFICIENCY',
  ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION',
  FAN = 'FAN',
  FUEL_OIL_CONSUMPTION = 'FUEL_OIL_CONSUMPTION',
  GAS_DEMAND = 'GAS_DEMAND',
  GAS_USAGE = 'GAS_USAGE',
  HDD_TEMPERATURE = 'HDD_TEMPERATURE',
  HEATING = 'HEATING',
  HEATING_2 = 'HEATING_2',
  HEATING_3 = 'HEATING_3',
  HUMIDITY = 'HUMIDITY',
  LIGHT = 'LIGHT',
  LOCAL_SETPOINT = 'LOCAL_SETPOINT',
  OCCUPANCY = 'OCCUPANCY',
  OUTLET_PRESSURE = 'OUTLET_PRESSURE',
  OUTSIDE_AIR_TEMPERATURE = 'OUTSIDE_AIR_TEMPERATURE',
  POWER = 'POWER',
  POWER_DEMAND = 'POWER_DEMAND',
  POWER_FACTOR = 'POWER_FACTOR',
  PRESSURE = 'PRESSURE',
  PRV_POSITION_1 = 'PRV_POSITION_1',
  PRV_POSITION_2 = 'PRV_POSITION_2',
  PRV_POSITION_3 = 'PRV_POSITION_3',
  PRV_POSITION_4 = 'PRV_POSITION_4',
  PRV_POSITION_5 = 'PRV_POSITION_5',
  PRV_POSITION_6 = 'PRV_POSITION_6',
  PRV_PRESSURE_1 = 'PRV_PRESSURE_1',
  PRV_PRESSURE_2 = 'PRV_PRESSURE_2',
  PRV_PRESSURE_3 = 'PRV_PRESSURE_3',
  PRV_PRESSURE_4 = 'PRV_PRESSURE_4',
  PRV_PRESSURE_5 = 'PRV_PRESSURE_5',
  PRV_PRESSURE_6 = 'PRV_PRESSURE_6',
  RAINFALL_TOTAL = 'RAINFALL_TOTAL',
  REACTIVE_POWER = 'REACTIVE_POWER',
  REMOTE_SETPOINT = 'REMOTE_SETPOINT',
  REMOTE_SETPOINT_MAX = 'REMOTE_SETPOINT_MAX',
  REMOTE_SETPOINT_MIN = 'REMOTE_SETPOINT_MIN',
  SPACE_TEMPERATURE = 'SPACE_TEMPERATURE',
  STEAM_CONSUMPTION = 'STEAM_CONSUMPTION',
  STEAM_DEMAND = 'STEAM_DEMAND',
  SUPPLY_TEMPERATURE = 'SUPPLY_TEMPERATURE',
  TEMPERATURE = 'TEMPERATURE',
  UPTIME = 'UPTIME',
  WASTE_CONSUMPTION = 'WASTE_CONSUMPTION',
  WATER_CONSUMPTION = 'WATER_CONSUMPTION',
  WATER_DEMAND = 'WATER_DEMAND',
  WIND_DIRECTION = 'WIND_DIRECTION',
  WIND_SPEED = 'WIND_SPEED',
  ch2o = 'ch2o',
  co = 'co',
  co2 = 'co2',
  light = 'light',
  noise = 'noise',
  o3 = 'o3',
  pm2_5 = 'pm2_5',
  pm10 = 'pm10',
  relative_humidity = 'relative_humidity',
  score = 'score',
  temperature = 'temperature',
  tvoc = 'tvoc',
}

export enum MetricsMeasurementType {
  DBA = 'DBA',
  DEGREES = 'DEGREES',
  FAHRENHEIT = 'FAHRENHEIT',
  FT3 = 'FT3',
  HCF = 'HCF',
  HCF_PER_HOUR = 'HCF_PER_HOUR',
  HECTO_PASCAL = 'HECTO_PASCAL',
  KBTU = 'KBTU',
  KVAR = 'KVAR',
  KW = 'KW',
  KWH = 'KWH',
  LUX = 'LUX',
  METERS_PER_SECOND = 'METERS_PER_SECOND',
  METRIC_TON = 'METRIC_TON',
  MICROGRAM_M3 = 'MICROGRAM_M3',
  MILES_PER_HOUR = 'MILES_PER_HOUR',
  MILLIMETER = 'MILLIMETER',
  MILLION_POUNDS = 'MILLION_POUNDS',
  MILLION_POUNDS_PER_HOUR = 'MILLION_POUNDS_PER_HOUR',
  NUMERIC = 'NUMERIC',
  PERCENTAGE = 'PERCENTAGE',
  PPB = 'PPB',
  PPM = 'PPM',
  PSI = 'PSI',
  THERMS = 'THERMS',
  THERMS_PER_HOUR = 'THERMS_PER_HOUR',
  USAGE = 'USAGE',
  USD = 'USD',
  WH = 'WH',
}

export enum MetricsStep {
  DAY = 'DAY',
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum ModeType {
  AUTO = 'AUTO',
  COOL = 'COOL',
  EMERGENCY_HEAT = 'EMERGENCY_HEAT',
  FAN_ONLY = 'FAN_ONLY',
  FREEZE_PROTECTION = 'FREEZE_PROTECTION',
  HEAT = 'HEAT',
  OFF = 'OFF',
  SERVICE_MODE = 'SERVICE_MODE',
  SMOKE_EMERGENCY = 'SMOKE_EMERGENCY',
  STARTUP_WAIT = 'STARTUP_WAIT',
}

export enum MonitorMeterFrequencyValues {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  OFF = 'OFF',
  WEEKLY = 'WEEKLY',
}

export enum MonitorMeterPrepayValues {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type MonthRangeInput = {
  from: Scalars['GraphQLMonthYear']['input'];
  to: Scalars['GraphQLMonthYear']['input'];
};

export type Mutation = {
  addSystemsToMeter: Scalars['Boolean']['output'];
  connectEnergyStar: Scalars['Boolean']['output'];
  copyDRConfig: Scalars['Boolean']['output'];
  createConedOruAuthorization: Scalars['String']['output'];
  createSystemDRConfig: SystemDRConfig;
  createUtilityAPIAuthorizationForm: Scalars['String']['output'];
  deleteAuthorization: Scalars['Boolean']['output'];
  deleteEnergyStarCoefficient: Scalars['Boolean']['output'];
  deleteSystemDRConfig: Scalars['Boolean']['output'];
  editAuthorization: Scalars['Boolean']['output'];
  /** Delete after deploy to prod */
  optimizeMeterMetricsDataIntervals: Scalars['Boolean']['output'];
  patchInvoice: Scalars['Boolean']['output'];
  refreshAuthorization: Scalars['Boolean']['output'];
  removeSystemFromMeter: Scalars['Boolean']['output'];
  runPropertyDataWorkflow: Scalars['Boolean']['output'];
  setupIntegration: MeterIntegration;
  stopIntegration: Scalars['Boolean']['output'];
  stopManualDREvent: Scalars['Boolean']['output'];
  submitConedOruAuthorization: Scalars['Boolean']['output'];
  submitUtilityAPIAuthorization: Scalars['Boolean']['output'];
  toggleSystemDRConfig: Scalars['Boolean']['output'];
  triggerManualDREvent: Scalars['Boolean']['output'];
  triggerUtilityAPIDataCollection: Scalars['Boolean']['output'];
  updateSystemDRConfig: SystemDRConfig;
  upsertEnergyStarCoefficient: Array<EnergyStarCoefficient>;
};

export type MutationaddSystemsToMeterArgs = {
  meterId: Scalars['Int']['input'];
  systemIds: Array<Scalars['Int']['input']>;
};

export type MutationconnectEnergyStarArgs = {
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  energyStarPropertyId: Scalars['Int']['input'];
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['Int']['input'];
};

export type MutationcopyDRConfigArgs = {
  copyToSystems: Array<Scalars['Int']['input']>;
  systemId: Scalars['Int']['input'];
};

export type MutationcreateConedOruAuthorizationArgs = {
  input: CreateConedOruAuthorizationInput;
};

export type MutationcreateSystemDRConfigArgs = {
  createSystemDRConfigInput: CreateSystemDRConfigInput;
};

export type MutationdeleteAuthorizationArgs = {
  authorizationUID: Scalars['String']['input'];
  type: MeterIntegrationType;
};

export type MutationdeleteEnergyStarCoefficientArgs = {
  regionId?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<MeterType>;
};

export type MutationdeleteSystemDRConfigArgs = {
  systemId: Scalars['Int']['input'];
};

export type MutationeditAuthorizationArgs = {
  authorizationUID: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: MeterIntegrationType;
};

export type MutationpatchInvoiceArgs = {
  invoiceId: Scalars['Int']['input'];
  invoiceInfo: UtilityInvoiceInput;
};

export type MutationrefreshAuthorizationArgs = {
  authorizationUID: Scalars['String']['input'];
  type: MeterIntegrationType;
};

export type MutationremoveSystemFromMeterArgs = {
  meterId: Scalars['Int']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationrunPropertyDataWorkflowArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  triggerDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationsetupIntegrationArgs = {
  createMeterIntegrationInput: MeterIntegrationInput;
};

export type MutationstopIntegrationArgs = {
  deleteData?: InputMaybe<Scalars['Boolean']['input']>;
  meterId: Scalars['Int']['input'];
};

export type MutationstopManualDREventArgs = {
  eventId: Scalars['Int']['input'];
};

export type MutationsubmitConedOruAuthorizationArgs = {
  authorizationCode: Scalars['String']['input'];
  maidId: Scalars['String']['input'];
};

export type MutationsubmitUtilityAPIAuthorizationArgs = {
  referral: Scalars['String']['input'];
};

export type MutationtoggleSystemDRConfigArgs = {
  enabled: Scalars['Boolean']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationtriggerManualDREventArgs = {
  endDate: Scalars['DateTime']['input'];
  meterId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MutationupdateSystemDRConfigArgs = {
  systemId: Scalars['Int']['input'];
  updateSystemDRConfigInput: UpdateSystemDRConfigInput;
};

export type MutationupsertEnergyStarCoefficientArgs = {
  inputs: Array<EnergyStarCoefficientsInput>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  benchmarkDataByMonth: Array<MeterValuesByMonth>;
  benchmarkDetailsKPI: BenchmarkKPI;
  benchmarks: Array<Benchmark>;
  carbonEmissionsForUtilityByYear: Array<MeterValuesByYear>;
  costForUtilityByYear: Array<MeterValuesByYear>;
  energyStarProperties: Array<EnergyStarProperty>;
  energyStarScoreByYear: Array<ValuesByYear>;
  getBenchmarksIntegrationForUser: Array<BenchmarkIntegrationBySite>;
  getDREventById: DREvent;
  getDREventMetricsById: Array<SystemMetricDR>;
  getDREventSummaryById: Array<DREventSummary>;
  getDREventsBySite: Array<DREvent>;
  getDREventsProgramNames: Array<Scalars['String']['output']>;
  getDRMeters: Array<DRMeter>;
  getDRMetersForUser: Array<DRMeter>;
  getEnergyStarCoefficients: Array<EnergyStarCoefficient>;
  getMeterFilterOptions: Array<MeterIntegrationFilterOption>;
  getMeterIntegrations: Array<MeterIntegrationUnion>;
  getSystemDRConfig: SystemDRConfig;
  getSystemDRStatus: SystemDREventStatus;
  getUtilityAuthorizations: Array<UtilityAuthorization>;
  getUtilityDashboardBreakdownTable: Array<UtilityDashboardTable>;
  getUtilityDashboardKPIs: UtilityDashboardKpi;
  getUtilityDataOverview: Array<UtilityDataOverview>;
  getUtilityForecastMetricData: Array<MetricFields>;
  getUtilityInvoice: UtilityInvoice;
  getUtilityInvoices: Array<UtilityInvoice>;
  getUtilityMeters: Array<UtilityMeter>;
  getUtilityMetersMetricsData: Array<UtilityMetricData>;
  getUtilityMetersToConnect: Array<UtilityMeterList>;
  getUtilityPerformanceBreakdownTable: Array<UtilityPerformance>;
  getUtilityPerformanceChart: Array<UtilityPerformanceChart>;
  siteEnergyStarIntegration?: Maybe<EnergyStarProperty>;
  sitesDREventsStatus: Array<SiteDREventStatus>;
  systemDRConfigs: Array<SystemDRConfigInfo>;
  usageForUtilityByYear: Array<MeterValuesByYear>;
  utilities: Array<Utility>;
  utilityIntegrations: Array<UtilityIntegration>;
};

export type QuerybenchmarkDataByMonthArgs = {
  meterType?: InputMaybe<MeterType>;
  metricType: BenchmarkMetricType;
  siteId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type QuerybenchmarkDetailsKPIArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerybenchmarksArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerycarbonEmissionsForUtilityByYearArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerycostForUtilityByYearArgs = {
  siteId: Scalars['Int']['input'];
};

export type QueryenergyStarScoreByYearArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerygetDREventByIdArgs = {
  eventId: Scalars['Int']['input'];
};

export type QuerygetDREventMetricsByIdArgs = {
  eventId: Scalars['Int']['input'];
};

export type QuerygetDREventSummaryByIdArgs = {
  eventId: Scalars['Int']['input'];
};

export type QuerygetDREventsBySiteArgs = {
  from: Scalars['DateTime']['input'];
  programName?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['Int']['input'];
  to: Scalars['DateTime']['input'];
};

export type QuerygetDREventsProgramNamesArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerygetDRMetersArgs = {
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetEnergyStarCoefficientsArgs = {
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  type?: InputMaybe<MeterType>;
};

export type QuerygetMeterIntegrationsArgs = {
  filters: IntegrationFiltersInput;
};

export type QuerygetSystemDRConfigArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetSystemDRStatusArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetUtilityAuthorizationsArgs = {
  type?: InputMaybe<MeterIntegrationType>;
};

export type QuerygetUtilityDashboardBreakdownTableArgs = {
  datePeriod: MonthRangeInput;
  meterIds: Array<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
};

export type QuerygetUtilityDashboardKPIsArgs = {
  currentPeriod: MonthRangeInput;
  meterIds: Array<Scalars['Int']['input']>;
  previousPeriod: MonthRangeInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetUtilityDataOverviewArgs = {
  siteIds: Array<Scalars['Int']['input']>;
  utilityServiceType: UtilityServiceTypes;
};

export type QuerygetUtilityForecastMetricDataArgs = {
  dateTimeFilter: MeterMetricDataIntervalsInput;
  meterId: Scalars['Int']['input'];
  metricType: MetricType;
  siteId: Scalars['Int']['input'];
};

export type QuerygetUtilityInvoiceArgs = {
  invoiceId: Scalars['Int']['input'];
};

export type QuerygetUtilityInvoicesArgs = {
  datePeriod?: InputMaybe<MonthRangeInput>;
  meterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  siteId: Scalars['Int']['input'];
  utilityType?: InputMaybe<UtilityServiceTypes>;
};

export type QuerygetUtilityMetersArgs = {
  siteId: Scalars['Int']['input'];
  type: UtilityServiceTypes;
};

export type QuerygetUtilityMetersMetricsDataArgs = {
  metricIds: Array<Scalars['Int']['input']>;
  metricsFilter: MeterMetricDataIntervalsInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetUtilityPerformanceBreakdownTableArgs = {
  currentPeriod: MonthRangeInput;
  meterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  previousPeriod: MonthRangeInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetUtilityPerformanceChartArgs = {
  datePeriod: MonthRangeInput;
  meterIds: Array<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
};

export type QuerysiteEnergyStarIntegrationArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerysitesDREventsStatusArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerysystemDRConfigsArgs = {
  siteId: Scalars['Int']['input'];
};

export type QueryusageForUtilityByYearArgs = {
  siteId: Scalars['Int']['input'];
};

export type SiteDREventStatus = {
  amountOfSystems: Scalars['Int']['output'];
  eventEnd?: Maybe<Scalars['DateTime']['output']>;
  eventStart?: Maybe<Scalars['DateTime']['output']>;
  eventsStatus?: Maybe<Array<DRStatusType>>;
  siteId: Scalars['Int']['output'];
};

export enum SpaceType {
  BYPASS = 'BYPASS',
  NO_OVERRIDE = 'NO_OVERRIDE',
  NULL = 'NULL',
  OCCUPIED = 'OCCUPIED',
  STANDBY = 'STANDBY',
  UNOCCUPIED = 'UNOCCUPIED',
}

export type SystemDRConfig = {
  enable: Scalars['Boolean']['output'];
  enableLevel1: Scalars['Boolean']['output'];
  enableLevel2: Scalars['Boolean']['output'];
  enablePrecurtailment: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  level1Settings?: Maybe<DRSettings>;
  level2Settings?: Maybe<DRSettings>;
  precurtailmentSettings?: Maybe<DRSettings>;
  settingsIntervalInMin?: Maybe<Scalars['Int']['output']>;
  systemId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemDRConfigInfo = {
  config?: Maybe<DRConfigInfo>;
  system: DRSystemInfo;
};

export type SystemDREventStatus = {
  configured: Scalars['Boolean']['output'];
  eventRunning?: Maybe<Scalars['Boolean']['output']>;
  runningEventType?: Maybe<DREventSource>;
};

export type SystemMetricDR = {
  floorOrder: Scalars['Float']['output'];
  metrics?: Maybe<Array<SystemMetricDataDR>>;
  systemFloor: Scalars['String']['output'];
  systemId: Scalars['Int']['output'];
  systemName: Scalars['String']['output'];
  systemType: SystemType;
};

export type SystemMetricDataDR = {
  measurement: MetricsMeasurementType;
  metricId: Scalars['Int']['output'];
  metricName: Scalars['String']['output'];
  metricType: MetricType;
  value: Scalars['Int']['output'];
};

export enum SystemStatus {
  OFF = 'OFF',
  ON = 'ON',
}

export enum SystemType {
  CHARGE_POINT = 'CHARGE_POINT',
  ELECTRICITY_METER = 'ELECTRICITY_METER',
  ELECTRICITY_SUBMETER = 'ELECTRICITY_SUBMETER',
  HW_BOILER_HT_HWR = 'HW_BOILER_HT_HWR',
  HW_BOILER_TEKMAR_284 = 'HW_BOILER_TEKMAR_284',
  LUTRON_VIVE_LIGHTING = 'LUTRON_VIVE_LIGHTING',
  NATURAL_GAS_METER = 'NATURAL_GAS_METER',
  PACKAGE_ALTC24PROG = 'PACKAGE_ALTC24PROG',
  PACKAGE_ECOBEE_DEFAULT = 'PACKAGE_ECOBEE_DEFAULT',
  PACKAGE_HONEYWELL_TC500AN = 'PACKAGE_HONEYWELL_TC500AN',
  SMART_OUTLET_T0006623 = 'SMART_OUTLET_T0006623',
  STEAM_METER = 'STEAM_METER',
  STEAM_PRV_STATION = 'STEAM_PRV_STATION',
  ST_BOILER_F_HT_MPC = 'ST_BOILER_F_HT_MPC',
  TBL_TCI = 'TBL_TCI',
  WATER_METER = 'WATER_METER',
  WEATHER_STATION_WTS506 = 'WEATHER_STATION_WTS506',
}

export type TimeInterval = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type UpdateSystemDRConfigInput = {
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  enableLevel1?: InputMaybe<Scalars['Boolean']['input']>;
  enableLevel2?: InputMaybe<Scalars['Boolean']['input']>;
  enablePrecurtailment?: InputMaybe<Scalars['Boolean']['input']>;
  level1Settings?: InputMaybe<DRSettingsInput>;
  level2Settings?: InputMaybe<DRSettingsInput>;
  precurtailmentSettings?: InputMaybe<DRSettingsInput>;
  settingsIntervalInMin?: InputMaybe<Scalars['Int']['input']>;
};

export type Utility = {
  id: Scalars['Int']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: MeterIntegrationType;
  utilityRedirectionUrl?: Maybe<Scalars['String']['output']>;
};

export type UtilityAPIMeterExtraFieldsInput = {
  authorizationUID: Scalars['String']['input'];
  historicalCollection: Scalars['Boolean']['input'];
  monitorMeterPrepayValues?: InputMaybe<MonitorMeterPrepayValues>;
  monitoringFrequency: MonitorMeterFrequencyValues;
};

export type UtilityAPIMeterIntegration = {
  authorizationUID: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expiry?: Maybe<Scalars['DateTime']['output']>;
  historicalCollection: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  meterType: UtilityServiceTypes;
  monitorMeterPrepayValues?: Maybe<MonitorMeterPrepayValues>;
  monitoringFrequency: MonitorMeterFrequencyValues;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  status: Scalars['Boolean']['output'];
  type: MeterIntegrationType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
  utility: Scalars['String']['output'];
};

export type UtilityAuthorization = {
  authorizationUID: Scalars['String']['output'];
  connectedItems: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiry?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
  type: MeterIntegrationType;
  userId: Scalars['String']['output'];
  utility: Scalars['String']['output'];
};

export type UtilityDashboardKpi = {
  carbonEmission?: Maybe<Scalars['Float']['output']>;
  carbonEmissionPercentToPreviousPeriod?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  costPercentToPreviousPeriod?: Maybe<Scalars['Float']['output']>;
  primaryValue?: Maybe<Scalars['Float']['output']>;
  primaryValuePercentToPreviousPeriod?: Maybe<Scalars['Float']['output']>;
};

export type UtilityDashboardTable = {
  accountName: Scalars['String']['output'];
  meterCost: Scalars['Float']['output'];
  meterName: Scalars['String']['output'];
  metricValue: Scalars['Float']['output'];
  percentOfTotal: Scalars['Float']['output'];
};

export type UtilityDataOverview = {
  amountOfAccounts: Scalars['Int']['output'];
  amountOfMeters: Scalars['Int']['output'];
  consumptionChange?: Maybe<Scalars['Int']['output']>;
  /** Unit for consumption is based on UtilityServiceType. For Electricity - KWH; Gas - Therms; Steam - Mlb; Water & Sewer (HCF) */
  consumptionForMonthBeforePreviousMonth?: Maybe<Scalars['Int']['output']>;
  consumptionForPreviousMonth?: Maybe<Scalars['Int']['output']>;
  costChange?: Maybe<Scalars['Int']['output']>;
  costForMonthBeforePreviousMonth?: Maybe<Scalars['Float']['output']>;
  costForPreviousMonth?: Maybe<Scalars['Float']['output']>;
  siteId: Scalars['Int']['output'];
};

export type UtilityIntegration = {
  connectedAt?: Maybe<Scalars['DateTime']['output']>;
  connectedEntities: Scalars['Int']['output'];
  expiryAt?: Maybe<Scalars['DateTime']['output']>;
  integrationsCount: Scalars['Int']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: MeterIntegrationType;
  utilityRedirectionUrl?: Maybe<Scalars['String']['output']>;
};

export type UtilityInvoice = {
  billingAccount: Scalars['String']['output'];
  billingAddress: Scalars['String']['output'];
  billingContact?: Maybe<Scalars['String']['output']>;
  billingMonth: Scalars['GraphQLMonthYear']['output'];
  cddDays?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['DateTime']['output'];
  endDate: Scalars['Date']['output'];
  files?: Maybe<Array<Scalars['String']['output']>>;
  hddDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceItems?: Maybe<Array<UtilityInvoiceItem>>;
  measurementType: MetricsMeasurementType;
  meterId: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['Date']['output'];
  statementDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<UtilityInvoiceStatus>;
  totalCost: Scalars['Float']['output'];
  totalVolume: Scalars['Float']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  utilityGroupType: UtilityServiceTypes;
};

export type UtilityInvoiceInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UtilityInvoiceStatus>;
};

export type UtilityInvoiceItem = {
  cost?: Maybe<Scalars['Float']['output']>;
  type: UtilityInvoiceItemType;
};

export enum UtilityInvoiceItemType {
  DELIVERY = 'DELIVERY',
  DEMAND = 'DEMAND',
  OTHER = 'OTHER',
  SUPPLY = 'SUPPLY',
  TAX = 'TAX',
  TIERS = 'TIERS',
  TIME_OF_USE = 'TIME_OF_USE',
  TOTAL_CONSUMPTION = 'TOTAL_CONSUMPTION',
  TOTAL_COST = 'TOTAL_COST',
}

export enum UtilityInvoiceStatus {
  FAILED_SOME_AUDITS = 'FAILED_SOME_AUDITS',
  MANUALLY_PASSED_BY_USER = 'MANUALLY_PASSED_BY_USER',
  MANUALLY_REJECTED_BY_USER = 'MANUALLY_REJECTED_BY_USER',
  PASSED_ALL_AUDITS = 'PASSED_ALL_AUDITS',
  WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW',
}

export type UtilityMeter = {
  integrationType: MeterIntegrationType;
  meterId: Scalars['Int']['output'];
  meterUID: Scalars['String']['output'];
  metrics: Array<UtilityMeterMetric>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UtilityMeterList = {
  authorizationCreatedAt: Scalars['DateTime']['output'];
  authorizationCreatedBy: Scalars['String']['output'];
  authorizationUID: Scalars['String']['output'];
  integrationType: MeterIntegrationType;
  meterUID: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: UtilityServiceTypes;
  utility: Scalars['String']['output'];
};

export type UtilityMeterMetric = {
  id: Scalars['Int']['output'];
  isPrimary: Scalars['Boolean']['output'];
  measurement: MetricsMeasurementType;
  name?: Maybe<Scalars['String']['output']>;
  type: MetricType;
};

export type UtilityMetricData = {
  exists: Scalars['Boolean']['output'];
  metricId: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type UtilityPerformance = {
  currentValue?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  measurementType: MetricsMeasurementType;
  name: UtilityInvoiceItemType;
  percentChange?: Maybe<Scalars['Float']['output']>;
  previousValue?: Maybe<Scalars['Float']['output']>;
};

export type UtilityPerformanceChart = {
  cost: Scalars['Float']['output'];
  month: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export enum UtilityServiceTypes {
  ELECTRIC = 'ELECTRIC',
  FUEL_OIL = 'FUEL_OIL',
  GAS = 'GAS',
  OTHER = 'OTHER',
  STEAM = 'STEAM',
  WASTE = 'WASTE',
  WATER = 'WATER',
}

export type ValuesByMonth = {
  month: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type ValuesByYear = {
  value: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};
