import { gql } from '@apollo/client';

export const SITE_DETAILS_FIELDS = gql`
  fragment SiteFullDetailsFields on Site {
    id
    city
    name
    state
    streetNumber
    streetName
    photo
    squareFeet
    primaryPropertyType
    yearBuilt
    zipCode
    regionId
    longitude
    latitude
  }
`;

export const ORDERED_SITE_DETAILS_FIELDS = gql`
  fragment OrderedSiteFullDetailsFields on OrderedSite {
    id
    city
    name
    state
    streetNumber
    streetName
    photo
    squareFeet
    primaryPropertyType
    yearBuilt
    zipCode
    regionId
    longitude
    latitude
    order
    siteFeatures {
      adr {
        type
        isActive
      }
      alerts {
        type
        isActive
      }
      billing {
        type
        isActive
      }
      leases {
        type
        isActive
      }
      scheduling {
        type
        isActive
      }
      spaceManagement {
        type
        isActive
      }
      utilities {
        isActive
        type
      }
      benchmarking {
        isActive
        type
      }
    }
  }
`;
