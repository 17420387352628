import { Grid } from '@visx/grid';
import { differenceInCalendarDays, eachHourOfInterval } from 'date-fns';
import _, { isDate } from 'lodash';
import React, { useMemo } from 'react';

import styles from './ComposableChartGrid.module.scss';
import { useChartContext } from './chartContext';
import { buildTicksForDays } from './utils';

/**
 * Renders background grid for ComposableChart which is based on x and y scales.
 */
const ComposableChartGrid: React.FC = () => {
  const { xMax, yMax, padding, xScale, yScale } = useChartContext();

  // Vertical lines will be built only for values which exists in that array
  const columnTickValues = useMemo(() => {
    const domain = xScale.domain();
    const min = _.first(domain);
    const max = _.last(domain);

    // Return all values which has 0 minutes
    if (!min || !max) {
      return [];
    }

    if (isDate(min) && isDate(max) && max > min) {
      const days = differenceInCalendarDays(max, min);
      if (days > 1) {
        return buildTicksForDays(domain as Date[]);
      }
      const hours = eachHourOfInterval({ start: min, end: max });
      return hours;
    }

    // If domain is not based on dates - return all values
    return domain;
  }, [xScale]);

  const numTicksRows = useMemo(() => {
    const domain = yScale.domain();
    // If domain is empty, return 0
    // If domain min and max are the same, return 0
    if (!domain.length || _.first(domain) === _.last(domain)) {
      return 0;
    }
    // If undefined - then row lines will be drawn automatically
    return undefined;
  }, [yScale]);

  return (
    <Grid
      xScale={xScale}
      yScale={yScale}
      numTicksRows={numTicksRows}
      className={styles['grid']}
      columnTickValues={columnTickValues}
      width={xMax}
      height={yMax}
      left={padding.left}
      top={padding.top}
    />
  );
};

export default ComposableChartGrid;
