import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { atf } from 'src/cdk/formatter/relativeTimeFormat';
import { FloorType, OnlineStatus, SystemStatus } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { RoutesResolver } from 'src/routes';
import StatusIndicator from 'src/shared/components/StatusIndicator/';
import StatusWithDate from 'src/shared/components/StatusWithDate/StatusWithDate';
import { CardLayout } from 'src/shared/containers/UniversalCard/UniversalCard';
import { getSystemStatusColorCode, getSystemStatusLabel } from 'src/shared/utils/system.utils';

import { selectCardLayout } from '../../../systemsSlice';

import styles from './GenericSystemHeader.module.scss';

export interface GenericSystemHeaderProps {
  systemId: number;
  name: string;
  status: SystemStatus;
  lastStatusChangedDatetime: Date;
  floor?: string | null;
  floorType: FloorType;
  onlineStatus?: OnlineStatus | null;
  disableLink?: boolean;
  layout?: CardLayout;
  timezone?: string | null;
  alterLink?: string;
  children?: React.ReactNode;
}

const GenericSystemHeader: React.FC<GenericSystemHeaderProps> = (props) => {
  const floorTitle = props.floor ? ` | ${props.floorType === FloorType.NUMBER ? 'Floor ' : ''}${props.floor}` : '';
  const layout = props.layout ?? useSelector(selectCardLayout);
  const label = getSystemStatusLabel(props.onlineStatus, props.status);
  const colorCode = getSystemStatusColorCode(props.onlineStatus, props.status);
  const lastStatusChangedDatetime =
    props.onlineStatus === OnlineStatus.NOT_CONFIGURED ? undefined : props.lastStatusChangedDatetime;
  const title = `${label}${
    lastStatusChangedDatetime ? ' since ' + atf(lastStatusChangedDatetime, false, props.timezone) : ''
  }${floorTitle}`;

  return (
    <Link
      to={props.disableLink ? '' : props.alterLink || RoutesResolver.SystemDetails(props.systemId)}
      onClick={(e) => props.disableLink && e.preventDefault()}
      className={classNames(styles['header'], styles[layout], 'card el-04 hov-el-08', {
        'no-pointer': props.disableLink,
      })}
    >
      <div className={styles['status-with-name']}>
        <StatusIndicator color={colorCode} />
        <h5 className='one-line-ellipsis' title={props.name}>
          {props.name}
        </h5>
      </div>
      <div style={{ marginBottom: '20px' }} className='one-line-ellipsis body-small color-secondary' title={title}>
        <StatusWithDate
          status={label}
          lastStatusChangedDatetime={lastStatusChangedDatetime}
          timezone={props.timezone}
        />
        {floorTitle}
      </div>
      {props.children}
    </Link>
  );
};

export default GenericSystemHeader;
