import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import { IOatResetRanges } from '../interface';

import {
  ChangePrvOatResetRangesMutation,
  ChangePrvOatResetRangesMutationVariables,
} from './__generated__/changeOatResetRanges.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type UpdatedSettings =
  | Extract<ChangePrvOatResetRangesMutation['updateSystemSettings'], { __typename: 'SystemSteamPrvStation' }>
  | Extract<ChangePrvOatResetRangesMutation['updateSystemSettings'], { __typename: 'SystemHwBoilerTekmar248' }>
  | Extract<ChangePrvOatResetRangesMutation['updateSystemSettings'], { __typename: 'SystemStBoilerFHtMpc' }>
  | Extract<ChangePrvOatResetRangesMutation['updateSystemSettings'], { __typename: 'SystemHwBoilerHtHwr' }>;

const CHANGE_SYSTEM_OAT_RESET_RANGES = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangePrvOatResetRanges(
    $systemId: Int!
    $setpointMax: Int!
    $setpointMin: Int!
    $outsideTempMax: Int!
    $outsideTempMin: Int!
  ) {
    updateSystemSettings(
      systemId: $systemId
      setpointMax: $setpointMax
      setpointMin: $setpointMin
      outsideTempMax: $outsideTempMax
      outsideTempMin: $outsideTempMin
    ) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changeOatResetRanges(
  systemId: number,
  oatResetRanges: IOatResetRanges
): Promise<UpdatedSettings | undefined> {
  const result = await resourcesAPI.mutate<ChangePrvOatResetRangesMutation, ChangePrvOatResetRangesMutationVariables>({
    mutation: CHANGE_SYSTEM_OAT_RESET_RANGES,
    variables: { systemId, ...oatResetRanges },
  });

  const updateResult = result.data?.updateSystemSettings as UpdatedSettings;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    } as UpdatedSettings;
  }
}
