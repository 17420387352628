import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';

import { Button } from '../../Button/Button';
import ElevatedBox from '../../ElevatedBox/ElevatedBox';
import { Input } from '../../Input/Input';
import { PopupActions } from '../../Popup/interface';
import Select from '../Select/Select';
import { OptionItem, SelectProps } from '../interface';

import styles from './SelectWithSearch.module.scss';

export interface SelectWithSearchProps<T>
  extends Exclude<
    SelectProps<T>,
    'customElement' | 'stickyCustomElement' | 'disabledWhenFewerOptions' | 'customElementPosition'
  > {
  onAddNewItem?: (searchedValue: string) => Promise<void> | void;
  onEdit?: (optionItem: OptionItem<T>) => void;
  onDelete?: (optionItem: OptionItem<T>) => void;
  /**
   * If passed - will display button to create new entity
   */
  entityName?: string;
}

export default function SelectWithSearch<T>(props: SelectWithSearchProps<T>): JSX.Element {
  const { onAddNewItem, onEdit, onDelete, renderOption, options, ...selectProps } = props;
  const [markAsLoading, setMarkAsLoading] = useState(false);
  const popupRef = useRef<PopupActions>(null);

  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(
    () =>
      options.filter((item) =>
        searchValue ? !item.hidden && item.displayValue.toLowerCase().includes(searchValue.toLowerCase()) : true
      ),
    [options, searchValue]
  );

  function onSearchValueChange(value: string): void {
    setSearchValue(value);

    // Trigger resize event to recalculate popup position
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
  }

  return (
    <Select<T>
      {...selectProps}
      popupActionsRef={popupRef}
      options={filteredOptions}
      markAsLoading={markAsLoading}
      renderOption={
        onEdit || onDelete
          ? (option, inList) => (
              <div key={String(option.key as unknown)} className={styles['editable-option']}>
                <p>{renderOption ? renderOption(option, inList) : option.displayValue}</p>
                <div className='d-flex gap-4'>
                  {onEdit && (
                    <Button
                      title='Edit'
                      variant='flat'
                      icon='edit'
                      size='small'
                      onClick={() => {
                        popupRef.current?.close();
                        onEdit(option);
                      }}
                    />
                  )}
                  {onDelete && (
                    <Button
                      title='Delete'
                      variant='flat'
                      icon='trash'
                      size='small'
                      onClick={() => {
                        popupRef.current?.close();
                        onDelete(option);
                      }}
                    />
                  )}
                </div>
              </div>
            )
          : renderOption
      }
      customElement={
        <div>
          <div className='d-flex'>
            <Input
              placeholder='Search...'
              type='text'
              className='flex-1'
              value={searchValue}
              onChange={onSearchValueChange}
              onSubmit={async () => {
                // If there is more than one option, select first one
                if (!_.isEmpty(searchValue) && filteredOptions.length > 0) {
                  popupRef.current?.close();
                  selectProps.onClick(filteredOptions[0].key);
                  setSearchValue('');
                  return;
                }
                // Otherwise, create new item
                if (onAddNewItem) {
                  popupRef.current?.close();
                  setMarkAsLoading(true);
                  await onAddNewItem(searchValue);
                  setSearchValue('');
                  setMarkAsLoading(false);
                }
              }}
              onEscape={() => setSearchValue('')}
            />
          </div>
          {/* Display button if should be submitted via form */}
          {props.entityName && (
            <p
              className='mt-8 px-16 py-8 caption-semi-bold with-pointer card flat el-08 hov-el-02 hov-color-primary-accent'
              style={{ marginLeft: '-16px', marginRight: '-16px' }}
              onClick={() => {
                popupRef.current?.close();
                if (onAddNewItem) {
                  onAddNewItem('');
                }
                setSearchValue('');
              }}
            >
              Create {props.entityName}
            </p>
          )}
          {/* Display text to prompt user to create new entity */}
          {filteredOptions.length === 0 && (
            <ElevatedBox
              icon='search'
              title={
                options.length === 0
                  ? `There are no ${_.lowerCase(props.entityName ?? 'item')}s yet`
                  : `${_.capitalize(props.entityName ?? 'item')} was not found`
              }
            >
              {onAddNewItem ? (
                <>
                  Press <b>Enter</b> to create one
                </>
              ) : null}
            </ElevatedBox>
          )}
        </div>
      }
      customElementPosition='top'
      stickyCustomElement={true}
      disabledWhenFewerOptions={false}
    />
  );
}
