import { gql } from '@apollo/client';
import _ from 'lodash';

import { SiteFeatureType, SystemGroupType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';
import { ISite } from 'src/interfaces';

import {
  SelectedSitesForHeaderRowQuery,
  SelectedSitesForHeaderRowQueryVariables,
} from './__generated__/getSelectedSitesForHeaderRow.resources.gql';
import { ORDERED_SITE_DETAILS_FIELDS } from './fragments.resources.gql';

const GET_SELECTED_SITES_FOR_HEADER_ROW = gql`
  ${ORDERED_SITE_DETAILS_FIELDS}
  query SelectedSitesForHeaderRow($ids: [Int!]!) {
    sites(ids: $ids) {
      availableSystemByTypes
      timezone
      utilityServiceTypes
      ...OrderedSiteFullDetailsFields
    }
  }
`;

// TODO: split queries for header and sites list
// TODO: rename or rewrite to getAllSitesQuery
export async function getSelectedSitesForHeaderRow(ids: number[]): Promise<ISite[]> {
  const result = await resourcesAPI.query<SelectedSitesForHeaderRowQuery, SelectedSitesForHeaderRowQueryVariables>({
    query: GET_SELECTED_SITES_FOR_HEADER_ROW,
    variables: {
      ids,
    },
  });

  return (result.data.sites ?? []).map((site) => {
    const address = _.trim(`${site.streetNumber ?? ''} ${site.streetName}`);
    const regionWithState = `${site.city}, ${site.state}`;
    return {
      id: site.id,
      img: site.photo,
      squareFeet: site.squareFeet,
      availableSystemByTypes: site.availableSystemByTypes,
      nameOrAddess: site.name || address,
      streetName: site.streetName,
      streetNumber: site.streetNumber,
      regionWithState: regionWithState,
      nameWithCity: `${site.name || address} | ${regionWithState}`,
      yearBuilt: site.yearBuilt,
      address,
      searchString: _.map(
        [site.name, address, site.city, site.state, site.zipCode],
        (i) => i?.toLowerCase()?.trim() ?? ''
      ).join(' '),
      zipCode: site.zipCode,
      regionId: site.regionId,
      timezone: site.timezone,
      amountOfSystemsByGroupTypes: _.fromPairs([]) as Record<SystemGroupType, number>, // TODO: Add `amountOfSystemsByGroupTypes` property to API as [ [groupType, number], [groupType, number] ]
      latitude: site.latitude,
      longitude: site.longitude,
      order: site.order,
      features: _.fromPairs(
        _.values(site.siteFeatures)
          .filter((i) => !!i)
          .map((feature) => [feature!.type, feature!.isActive] as const)
      ) as Record<SiteFeatureType, boolean>,
      utilityServiceTypes: site.utilityServiceTypes,
    };
  });
}
