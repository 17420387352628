import React, { useEffect, useMemo, useState } from 'react';

import { FloorType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { Input } from 'src/shared/components/Input/Input';
import { Select } from 'src/shared/components/Select';

import { SiteFloor } from '../../gql/getFloorsForSite.resources.gql';
import { floorTypeSelectOptions } from '../../utils/system.utils';

export interface Props {
  value?: Partial<SiteFloor>;
  onChange: VoidFunction;
}
/**
 * This validation method is parially copied from back end - resources-ms/../validateCreateFloorInput()
 * The method is used to verify floor creation and setting.
 * If this logic will change, it should be updated on the backend also.
 */
function getValidationMessage(type?: FloorType, floor?: string): string | undefined {
  if (!type || !floor) {
    // Form will be highlighted in red
    return;
  }
  if (type === FloorType.NUMBER && !/^[0-9]+$/.test(floor)) {
    return 'Selected Type must only consist of numbers';
  }
  return;
}

const FloorDetailsForm: React.FC<Props> = ({ value, onChange }) => {
  const [floor, setFloor] = useState(value?.floor);
  const [type, setType] = useState<FloorType | undefined>(value?.type);

  useEffect(() => {
    setFloor(value?.floor);
    setType(value?.type);
  }, [value?.floor, value?.type]);

  useEffect(() => {
    setTimeout(() => {
      onChange();
    }, 100);
  }, [floor, type]);

  const validationMessage = useMemo(() => getValidationMessage(type, floor), [type, floor]);

  return (
    <div className='d-flex flex-col gap-8 mb-8'>
      <p className='color-secondary body-small'>Type</p>
      <Select
        required
        className='w-100'
        id='floorType'
        onClick={setType}
        options={floorTypeSelectOptions}
        value={type}
      />
      <div />
      <p className='color-secondary body-small'>Floor</p>
      <Input
        required
        placeholder='Enter floor name'
        id='floorName'
        className='w-100'
        value={floor ?? ''}
        onChange={setFloor}
        pattern={type === FloorType.NUMBER ? '^[0-9]+$' : undefined}
      />
      <div className='d-flex'>
        <p
          className='color-error caption break-white-space'
          // That style will fit the text to the width of the container
          style={{
            flex: 'auto',
            width: '0',
          }}
        >
          {validationMessage}
        </p>
      </div>
    </div>
  );
};

export default FloorDetailsForm;
