import { gql } from '@apollo/client';

import { ExportMetricColumn } from 'src/core/apollo/__generated__/exportGlobalTypes';
import { exportAPI } from 'src/core/apollo/api';

import {
  ExportAnalyticsDataQuery,
  ExportAnalyticsDataQueryVariables,
} from './__generated__/exportAnalyticsData.export.gql';

const EXPORT_ANALYTICS_DATA = gql`
  query ExportAnalyticsData($fileName: String!, $columns: [ExportMetricColumn!]!, $dataArray: [[String!]!]!) {
    exportMetricsData(fileName: $fileName, columns: $columns, dataArray: $dataArray)
  }
`;

export async function exportAnalyticsData(
  fileName: string,
  columns: ExportMetricColumn[],
  dataArray: string[][]
): Promise<string> {
  const result = await exportAPI.query<ExportAnalyticsDataQuery, ExportAnalyticsDataQueryVariables>({
    query: EXPORT_ANALYTICS_DATA,
    variables: {
      fileName,
      columns,
      dataArray,
    },
  });

  return result.data.exportMetricsData;
}
