import { map } from 'lodash';

import { OnlineStatus, SiteFeatureType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { useAppSelector } from 'src/core/store/hooks';
import Guard from 'src/fragments/layouts/guard/Guard';
import { selectSystemNotifications } from 'src/logic/alerts/alertsSlice';
import NextSystemScheduleDescription from 'src/materials/system/NextSystemSchedule/NextSystemScheduleDescription';
import StatusIndicator from 'src/shared/components/StatusIndicator/';
import StatusWithDate from 'src/shared/components/StatusWithDate/StatusWithDate';
import SystemAlertTag from 'src/shared/components/Tag/SystemAlertTag';
import { getSystemStatusColorCode, getSystemStatusLabel } from 'src/shared/utils/system.utils';

import { AdditionalGeneralDetails, AnySystem } from '../../../interface';

import styles from './GeneralDetails.module.scss';

interface GeneralDetailsProps {
  additionalDetails?: AdditionalGeneralDetails;
  timezone?: string | null;
  systemId: number;
  system?: AnySystem;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = (props) => {
  const floor = props.system?.floor;
  const status = props.system?.status;
  const onlineStatus = props.system?.onlineStatus;

  const label = getSystemStatusLabel(onlineStatus, status);
  const colorCode = getSystemStatusColorCode(onlineStatus, status);
  const lastChangedTimeStatus =
    onlineStatus === OnlineStatus.NOT_CONFIGURED ? undefined : props.system?.statusUpdatedAt;
  const alerts = useAppSelector(selectSystemNotifications(props.systemId));

  return (
    <>
      <h5 className={styles['general-details-title']}>General Details</h5>
      <dl>
        <dt>Status</dt>
        <div className={styles['status-date-container']}>
          <StatusIndicator color={colorCode} />
          <StatusWithDate status={label} lastStatusChangedDatetime={lastChangedTimeStatus} timezone={props.timezone} />
        </div>
        <dt>Floor</dt>
        <dd>{floor}</dd>
        {props.additionalDetails &&
          map(props.additionalDetails, (value, key) => (
            <>
              <dt>{key}</dt>
              <dd>{value}</dd>
            </>
          ))}
        {/* TODO display real value later on */}
        {/* <dt>Last Received</dt>
        <dd>
          February 4, 05:29 PM
          <br />
          <span className='color-secondary'>by John Clarkson</span>
        </dd>
        <Button onClick={handleButtonClick} label='View Log Activity' /> */}
      </dl>
      <Guard siteId={props.system?.siteId} feature={SiteFeatureType.SCHEDULING} placeholder={null}>
        <NextSystemScheduleDescription systemId={props.systemId} system={props.system} timezone={props.timezone} />
      </Guard>
      <div className='mt-8'>
        {alerts.map((alert) => (
          <SystemAlertTag
            siteId={props.system?.siteId}
            key={alert.name}
            onlineStatus={null}
            withDescription
            className='mb-8 w-100'
            title={alert.name}
            description={alert.description ?? undefined}
            color={alert.color}
            showIndicator={alert.isRead}
          />
        ))}
      </div>
    </>
  );
};

export default GeneralDetails;
