import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  OverrideDRSequenceMutation,
  OverrideDRSequenceMutationVariables,
} from './__generated__/overrideDRSequence.resources.gql';

const OVERRIDE_DR_SEQUENCE = gql`
  mutation OverrideDRSequence($systemId: Int!) {
    overrideDRSequence(systemId: $systemId)
  }
`;

export async function overrideDRSequence(systemId: number): Promise<boolean | undefined> {
  const result = await resourcesAPI.mutate<OverrideDRSequenceMutation, OverrideDRSequenceMutationVariables>({
    mutation: OVERRIDE_DR_SEQUENCE,
    variables: {
      systemId,
    },
  });

  return result.data?.overrideDRSequence ?? false;
}
