import {
  AlertAction,
  AlertColor,
  AlertOperators,
  CalculationConditionEvent,
  InstantConditionEvent,
  MetricType,
} from './apollo/__generated__/alertsGlobalTypes';
import {
  CommunicationChannelType,
  CPRegions,
  FrequencyBandRFRegion,
  GatewayConnectivityType,
  GatewayManufacturer,
  GatewayModel,
  GatewayVersion,
} from './apollo/__generated__/iotGlobalTypes';
import { AuditLogResourceType, AuditLogType } from './apollo/__generated__/logsGlobalTypes';
import {
  ChargePointStationModeType,
  ChargePointStatus,
  OccupancyType,
  SiteFeatureType,
} from './apollo/__generated__/resourcesGlobalTypes';
import { UserAppearance } from './apollo/__generated__/usersGlobalTypes';
import {
  MonitorMeterFrequencyValues,
  MonitorMeterPrepayValues,
  UtilityInvoiceItemType,
  UtilityInvoiceStatus,
  UtilityServiceTypes,
} from './apollo/__generated__/utilityGlobalTypes';

export const GATEWAY_VERSION_LABEL: Record<GatewayVersion, string> = {
  [GatewayVersion.TBL_V1]: 'TBL v1',
  [GatewayVersion.TBL_V2]: 'TBL v2',
  [GatewayVersion.AWS_IOT_LORAWAN]: 'AWS IoT LoRaWAN',
};

export const GATEWAY_CONNECTIVITY_LABEL: Record<GatewayConnectivityType, string> = {
  [GatewayConnectivityType.ETHERNET]: 'Ethernet',
  [GatewayConnectivityType.WIFI]: 'WiFi',
  [GatewayConnectivityType.CELLULAR]: 'Cellular',
};

export const GATEWAY_MANUFACTURER_LABEL: Record<GatewayManufacturer, string> = {
  [GatewayManufacturer.MILESIGHT]: 'Milesight',
  [GatewayManufacturer.TBL]: 'TBL',
  [GatewayManufacturer.TEKTELIC]: 'TekTelic',
};

export const GATEWAY_FREQUENCY_BAND_LABEL: Record<FrequencyBandRFRegion, string> = {
  [FrequencyBandRFRegion.AU915]: 'AU915',
  [FrequencyBandRFRegion.EU868]: 'EU868',
  [FrequencyBandRFRegion.EU433]: 'EU433',
  [FrequencyBandRFRegion.CN470]: 'CN470',
  [FrequencyBandRFRegion.CN779]: 'CN779',
  [FrequencyBandRFRegion.RU864]: 'RU864',
  [FrequencyBandRFRegion.KR920]: 'KR920',
  [FrequencyBandRFRegion.IN865]: 'IN865',
  [FrequencyBandRFRegion.US915]: 'US915',
  [FrequencyBandRFRegion.AS923_1]: 'AS923-1',
  [FrequencyBandRFRegion.AS923_2]: 'AS923-2',
  [FrequencyBandRFRegion.AS923_3]: 'AS923_3',
  [FrequencyBandRFRegion.AS923_4]: 'AS923-4',
};

export const GATEWAY_MODEL_LABEL: Record<GatewayModel, string> = {
  [GatewayModel.UG56]: 'UG56',
  [GatewayModel.UG65]: 'UG65',
  [GatewayModel.UG67]: 'UG67',
  [GatewayModel.NODE_S]: 'Node-S',
  [GatewayModel.NODE_X]: 'Node-X',
  [GatewayModel.KONA_MICRO]: 'Kona Micro',
};

export const COMMUNICATION_CHANNEL_LABEL: Record<CommunicationChannelType, string> = {
  [CommunicationChannelType.ALGORITHM]: 'Algorithm',
  [CommunicationChannelType.AWS_IOT_LORAWAN]: 'AWS IoT LoRaWAN',
  [CommunicationChannelType.BACNET]: 'BACnet',
  [CommunicationChannelType.LORA]: 'LoRa',
  [CommunicationChannelType.LORAWANLOCAL]: 'LoRaWAN Local',
  [CommunicationChannelType.MODBUS]: 'Modbus',
  [CommunicationChannelType.MQTT]: 'MQTT',
  [CommunicationChannelType.SENSORS]: 'Sensors',
};

export const ALERT_COLOR_LABEL: Record<Exclude<AlertColor, AlertColor.GREEN>, string> = {
  [AlertColor.RED]: 'Red (High)',
  [AlertColor.ORANGE]: 'Orange (Medium)',
  [AlertColor.YELLOW]: 'Yellow (Low)',
};

export const ALERT_ACTION_LABEL: Record<AlertAction, string> = {
  [AlertAction.EMAIL]: 'Email',
  [AlertAction.SMS]: 'SMS',
  [AlertAction.WEB]: 'Web',
};

export const ALERT_OPERATOR_LABEL: Record<AlertOperators, string> = {
  [AlertOperators.EQUAL]: '=',
  [AlertOperators.LESS]: '<',
  [AlertOperators.LESS_OR_EQUAL]: '<=',
  [AlertOperators.MORE]: '>',
  [AlertOperators.MORE_OR_EQUAL]: '>=',
  [AlertOperators.NOT_EQUAL]: '!=',
};

// TODO: remove this when backend will be fixed
export const ALERT_OPERATOR_LABEL_REV: Record<string, AlertOperators> = {
  ['=']: AlertOperators.EQUAL,
  ['<']: AlertOperators.LESS,
  ['<=']: AlertOperators.LESS_OR_EQUAL,
  ['>']: AlertOperators.MORE,
  ['>=']: AlertOperators.MORE_OR_EQUAL,
  ['!=']: AlertOperators.NOT_EQUAL,
};

export const CALCULATION_CONDITION_EVENT_LABEL: Record<CalculationConditionEvent, string> = {
  [CalculationConditionEvent.CONNECTION_LOST]: 'Connection Lost',
  [CalculationConditionEvent.INEFFICIENT_SYSTEM]: 'Inefficient System',
  [CalculationConditionEvent.TOTAL_ENERGY_CONSUMPTION]: 'Total Electricity',
  [CalculationConditionEvent.TOTAL_NATURAL_GAS_CONSUMPTION]: 'Total Natural Gas',
  [CalculationConditionEvent.TOTAL_STEAM_CONSUMPTION]: 'Total Steam',
  [CalculationConditionEvent.TOTAL_WATER_CONSUMPTION]: 'Total Water',
};

export const INSTANT_CONDITION_EVENT_LABEL: Record<InstantConditionEvent, string> = {
  [InstantConditionEvent.FILTER_HEALTH]: 'Filter Health',
  [InstantConditionEvent.FREEZE_DETECTED]: 'Freeze Detected',
  [InstantConditionEvent.LEAK_DETECTED]: 'Leak Detected',
};

// TODO: update metrics labels
export const METRIC_TYPE_LABEL: Record<MetricType, string> = {
  [MetricType.FAN]: 'Fan',
  [MetricType.TEMPERATURE]: 'Temperature',
  [MetricType.AVG_SPACE_TEMPERATURE]: 'Avg Space Temperature',
  [MetricType.BATTERY]: 'Battery',
  [MetricType.COOLING]: 'Cooling',
  [MetricType.COOLING_2]: 'Cooling 2',
  [MetricType.COOLING_3]: 'Cooling 3',
  [MetricType.DAMPER_EFFECTIVE]: 'Damper Effective',
  [MetricType.DAMPER_POSITION]: 'Damper Expected',
  [MetricType.DHW_TEMPERATURE]: 'DHW Temperature',
  [MetricType.EFFICIENCY]: 'Efficiency',
  [MetricType.ENERGY_CONSUMPTION]: 'Electricity',
  [MetricType.HEATING]: 'Heating',
  [MetricType.HEATING_2]: 'Heating 2',
  [MetricType.HEATING_3]: 'Heating 3',
  [MetricType.HUMIDITY]: 'Humidity',
  [MetricType.LIGHT]: 'Light',
  [MetricType.LOCAL_SETPOINT]: 'Local Setpoint',
  [MetricType.OCCUPANCY]: 'Occupancy',
  [MetricType.OUTLET_PRESSURE]: 'Outlet Pressure',
  [MetricType.OUTSIDE_AIR_TEMPERATURE]: 'Outside Air Temperature',
  [MetricType.POWER]: 'Power',
  [MetricType.POWER_DEMAND]: 'Demand',
  [MetricType.POWER_FACTOR]: 'Power Factor',
  [MetricType.PRESSURE]: 'Pressure',
  [MetricType.PRV_POSITION_1]: 'Prv Position 1',
  [MetricType.PRV_POSITION_2]: 'Prv Position 2',
  [MetricType.PRV_POSITION_3]: 'Prv Position 3',
  [MetricType.PRV_POSITION_4]: 'Prv Position 4',
  [MetricType.PRV_POSITION_5]: 'Prv Position 5',
  [MetricType.PRV_POSITION_6]: 'Prv Position 6',
  [MetricType.PRV_PRESSURE_1]: 'Prv Pressure 1',
  [MetricType.PRV_PRESSURE_2]: 'Prv Pressure 2',
  [MetricType.PRV_PRESSURE_3]: 'Prv Pressure 3',
  [MetricType.PRV_PRESSURE_4]: 'Prv Pressure 4',
  [MetricType.PRV_PRESSURE_5]: 'Prv Pressure 5',
  [MetricType.PRV_PRESSURE_6]: 'Prv Pressure 6',
  [MetricType.RAINFALL_TOTAL]: 'Rainfall Total',
  [MetricType.REACTIVE_POWER]: 'Reactive Power',
  [MetricType.REMOTE_SETPOINT]: 'Remote Setpoint',
  [MetricType.REMOTE_SETPOINT_MAX]: 'Remote Setpoint Max',
  [MetricType.REMOTE_SETPOINT_MIN]: 'Remote Setpoint Min',
  [MetricType.SPACE_TEMPERATURE]: 'Space Temperature',
  [MetricType.SUPPLY_TEMPERATURE]: 'Supply Temperature',
  [MetricType.WIND_DIRECTION]: 'Wind Direction',
  [MetricType.WIND_SPEED]: 'Wind Speed',
  [MetricType.ch2o]: 'Formaldehyde',
  [MetricType.co]: 'Carbon Monoxide',
  [MetricType.co2]: 'Carbon Dioxide',
  [MetricType.light]: 'Light',
  [MetricType.noise]: 'Noise',
  [MetricType.o3]: 'Ozone',
  [MetricType.pm2_5]: 'Particulate Matter 2.5',
  [MetricType.pm10]: 'Particulate Matter 10',
  [MetricType.relative_humidity]: 'Relative Humidity',
  [MetricType.score]: 'Score',
  [MetricType.temperature]: 'Temperature',
  [MetricType.tvoc]: 'Total Volatile Organic Compounds',
  [MetricType.GAS_USAGE]: 'Natural Gas',
  [MetricType.WATER_CONSUMPTION]: 'Water',
  [MetricType.FUEL_OIL_CONSUMPTION]: 'Fuel Oil',
  [MetricType.STEAM_CONSUMPTION]: 'Steam',
  [MetricType.WASTE_CONSUMPTION]: 'Waste',
  [MetricType.CDD_TEMPERATURE]: 'CDD',
  [MetricType.HDD_TEMPERATURE]: 'HDD',
  [MetricType.UPTIME]: 'Uptime',
  [MetricType.GAS_DEMAND]: 'Demand',
  [MetricType.STEAM_DEMAND]: 'Demand',
  [MetricType.WATER_DEMAND]: 'Demand',
};

export const OCCUPANCY_TYPE_LABEL: Record<OccupancyType, string> = {
  [OccupancyType.EXTERNAL]: 'External',
  [OccupancyType.LOCAL]: 'Local',
  [OccupancyType.MANUAL]: 'Manual',
};

export const OCCUPANCY_TYPE_DESCRIPTION: Record<OccupancyType, string> = {
  [OccupancyType.EXTERNAL]: 'Occupancy is changed via an external sensor on Orion',
  [OccupancyType.LOCAL]: 'Occupancy is changed via a local sensor connected to the system',
  [OccupancyType.MANUAL]: 'Occupancy is changed manually on the system page or via a schedule',
};

export const METER_MONITORING_FREQUENCY_LABEL: Record<MonitorMeterFrequencyValues, string> = {
  [MonitorMeterFrequencyValues.OFF]: 'Off',
  [MonitorMeterFrequencyValues.DAILY]: 'Daily',
  [MonitorMeterFrequencyValues.MONTHLY]: 'Monthly',
  [MonitorMeterFrequencyValues.WEEKLY]: 'Weekly',
};

export const METER_MONITORING_PREPAY_LABEL: Record<MonitorMeterPrepayValues, string> = {
  [MonitorMeterPrepayValues.MONTHLY]: 'Monthly',
  [MonitorMeterPrepayValues.YEARLY]: 'Yearly',
};

export const SITE_FEATURE_LABEL: Record<SiteFeatureType, string> = {
  [SiteFeatureType.ADR]: 'Automated Demand Response',
  [SiteFeatureType.ALERTS]: 'Alerts',
  [SiteFeatureType.BENCHMARKING]: 'Benchmarking',
  [SiteFeatureType.BILLING]: 'Billing',
  [SiteFeatureType.LEASES]: 'Lease Management',
  [SiteFeatureType.SCHEDULING]: 'Scheduling',
  [SiteFeatureType.SPACE_MANAGEMENT]: 'Space Management',
  [SiteFeatureType.UTILITIES]: 'Utilities',
};

export const LOG_TYPE_LABEL: Record<AuditLogType, string> = {
  [AuditLogType.ACTIVITY]: 'Activity',
  [AuditLogType.ALERT]: 'Alert',
  [AuditLogType.NOTE]: 'Note',
  [AuditLogType.EMAIL_NOTIFICATION]: 'Email Notification',
  [AuditLogType.SMS_NOTIFICATION]: 'SMS Notification',
};

export const APPEARANCE_LABEL: Record<UserAppearance, string> = {
  [UserAppearance.DARK]: 'Dark',
  [UserAppearance.LIGHT]: 'Light',
  [UserAppearance.SYSTEM]: 'System',
};

export const UTILITY_SERVICE_TYPE_LABEL: Record<UtilityServiceTypes, string> = {
  [UtilityServiceTypes.ELECTRIC]: 'Electricity',
  [UtilityServiceTypes.GAS]: 'Natural Gas',
  [UtilityServiceTypes.FUEL_OIL]: 'Fuel Oil',
  [UtilityServiceTypes.OTHER]: 'Other',
  [UtilityServiceTypes.STEAM]: 'Steam',
  [UtilityServiceTypes.WASTE]: 'Waste',
  [UtilityServiceTypes.WATER]: 'Water',
};

export const UTILITY_SERVICE_TYPE_DESCRIPTION_LABEL: Record<UtilityServiceTypes, string> = {
  [UtilityServiceTypes.ELECTRIC]: '',
  [UtilityServiceTypes.GAS]: '',
  [UtilityServiceTypes.FUEL_OIL]: '',
  [UtilityServiceTypes.OTHER]: '',
  [UtilityServiceTypes.STEAM]: '',
  [UtilityServiceTypes.WASTE]: '',
  [UtilityServiceTypes.WATER]: '',
};

export const UTILITY_INVOICE_STATUS_LABEL: Record<UtilityInvoiceStatus, string> = {
  [UtilityInvoiceStatus.WAITING_FOR_REVIEW]: 'Waiting for review',
  [UtilityInvoiceStatus.MANUALLY_PASSED_BY_USER]: 'Manually passed by user',
  [UtilityInvoiceStatus.PASSED_ALL_AUDITS]: 'Passed all audits',
  [UtilityInvoiceStatus.MANUALLY_REJECTED_BY_USER]: 'Manually rejected by user',
  [UtilityInvoiceStatus.FAILED_SOME_AUDITS]: 'Failed some audits',
};

export const UTILITY_INVOICE_ITEM_TYPE: Record<UtilityInvoiceItemType, string> & Record<string, string> = {
  [UtilityInvoiceItemType.DELIVERY]: 'Delivery',
  [UtilityInvoiceItemType.DEMAND]: 'Demand',
  [UtilityInvoiceItemType.OTHER]: 'Other',
  [UtilityInvoiceItemType.SUPPLY]: 'Supply',
  [UtilityInvoiceItemType.TAX]: 'Tax',
  [UtilityInvoiceItemType.TIERS]: 'Tiers',
  [UtilityInvoiceItemType.TIME_OF_USE]: 'Time of Use',
  [UtilityInvoiceItemType.TOTAL_COST]: 'Cost',
  [UtilityInvoiceItemType.TOTAL_CONSUMPTION]: 'Consumption',
};

export const CP_REGION_LABEL: Record<CPRegions, string> = {
  [CPRegions.CENTRAL_AMERICA]: 'Central America',
  [CPRegions.NORTH_AMERICA]: 'North America',
  [CPRegions.EUROPE]: 'Europe',
};

export const AUDIT_LOG_RESOURCE_TYPE_LABEL: Record<AuditLogResourceType, string> = {
  [AuditLogResourceType.SYSTEM]: 'System',
  [AuditLogResourceType.USER]: 'User',
};

export const CP_STATION_MODE_TYPE_LABEL: Record<ChargePointStationModeType, string> = {
  [ChargePointStationModeType.STATION_MAX]: 'Station',
  [ChargePointStationModeType.PORTS_MAX]: 'Ports',
  [ChargePointStationModeType.STATION_SHED]: 'Station Shed',
  [ChargePointStationModeType.PORTS_SHED]: 'Ports Shed',
};

export const CP_STATION_PORT_STATUS_LABEL: Record<ChargePointStatus, string> = {
  [ChargePointStatus.AVAILABLE]: 'Available',
  [ChargePointStatus.INUSE]: 'In Use',
  [ChargePointStatus.REACHABLE]: 'Reachable',
  [ChargePointStatus.UNKNOWN]: 'Unknown',
  [ChargePointStatus.UNREACHABLE]: 'Unreachable',
};
