import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';

export interface Props {
  title?: string;
}

/**
 * By default will display message "Page not found" message
 */
const NotFoundPage: React.FC<Props> = ({ title }) => {
  return <ElevatedBox error title={title ?? 'Page not found'} />;
};

export default NotFoundPage;
