export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ConditionUnion: ['CalculationCondition', 'InstantCondition', 'MetricCondition'],
  },
};
export default result;
