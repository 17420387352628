import {
  AsYouType,
  ParseError,
  getCountries,
  isValidPhoneNumber,
  parsePhoneNumber,
  type CountryCode,
  type PhoneNumber,
} from 'libphonenumber-js';
import _ from 'lodash';

import { N_A } from 'src/constants';

export const phone = {
  countryOptions: getCountries().map((country) => ({
    key: country,
    displayValue: country,
  })),

  formatTyped(country: CountryCode = 'US') {
    const i = new AsYouType(country);
    return {
      i: i,
      update: (value: string) => {
        i.reset();
        i.input(value);
        return i.getNumber()?.number || '';
      },
      getUI: () => _.get(i, 'formattedOutput', ''),
      getNumber: () => i.getNumber()?.number,
      getCountry: () => i.getCountry() || 'US',
      setCountry: (country: CountryCode) => (i.country = country),
    };
  },

  isValid(value: string | undefined | null): boolean {
    // review validation - https://www.npmjs.com/package/libphonenumber-js#ispossible
    // return isPossiblePhoneNumber(value || '');
    return isValidPhoneNumber(value || '');
  },

  toApi(value: [CountryCode | undefined, string | undefined]): string | undefined {
    if (!value[0] || !value[1]) {
      return undefined;
    }
    try {
      const parsedPhone = parsePhoneNumber(value[1], value[0]);
      if (parsedPhone.isValid()) {
        return parsedPhone.number;
      }
      console.warn('PhoneParseError: [', value, '] Invalid phone number');
      return undefined;
    } catch (e) {
      if (e instanceof ParseError) {
        console.warn('PhoneParseError: [', value, '] Invalid phone number', e.message);
      } else {
        console.warn('PhoneParseError: [', value, '] Failed to parse phone number', e);
      }
      return undefined;
    }
  },

  toUser(value: string | undefined | null): string {
    if (!value) {
      return N_A;
    }
    let parsedPhone: PhoneNumber;
    try {
      parsedPhone = parsePhoneNumber(value);
    } catch (e) {
      if (e instanceof ParseError) {
        console.warn('PhoneParseError: [', value, '] Invalid phone number', e.message);
      } else {
        console.warn('PhoneParseError: [', value, '] Failed to parse phone number', e);
      }
      return N_A;
    }
    if (!parsedPhone.country) {
      console.warn('PhoneParseError: [', value, '] Not able to define country code');
      return N_A;
    }

    return parsedPhone.formatInternational();
  },
};
