import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import { IPackagedSystemSettings } from '../interface';

import {
  UpdatePackageSystemSettingsMutation,
  UpdatePackageSystemSettingsMutationVariables,
} from './__generated__/changePackageSystemSettings.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type UpdatedSettings = Extract<
  UpdatePackageSystemSettingsMutation['updatePackageSystemSettings'],
  {
    __typename: 'SystemPackageAltc24Prog';
  }
>;

const CHANGE_PACKAGE_SYSTEM_SETTINGS = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation UpdatePackageSystemSettings($updatePackageAltc24ProgInput: UpdateSystemPackageAltc24ProgInput!) {
    updatePackageSystemSettings(updatePackageAltc24ProgInput: $updatePackageAltc24ProgInput) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changePackageSystemSettings(
  systemId: number,
  packagedSystemSettings: IPackagedSystemSettings
): Promise<UpdatedSettings | undefined> {
  const result = await resourcesAPI.mutate<
    UpdatePackageSystemSettingsMutation,
    UpdatePackageSystemSettingsMutationVariables
  >({
    mutation: CHANGE_PACKAGE_SYSTEM_SETTINGS,
    variables: { updatePackageAltc24ProgInput: { systemId, ...packagedSystemSettings } },
  });

  const updateResult = result.data?.updatePackageSystemSettings as UpdatedSettings;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    } as UpdatedSettings;
  }
}
