import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import { type Matcher, type DateRange } from 'react-day-picker';

import { DATE_FORMAT, MONTH_NUMBERS } from 'src/constants';

import { Button } from '../../Button/Button';

import styles from './MonthPicker.module.scss';
import { monthMatchModifiers, toYearMonth } from './utils/monthMatchModifiers';

interface MonthsProps {
  year: number;
  selected?: DateRange | undefined;
  disabled?: Matcher[] | undefined;
  onMonthClick: (date: Date) => void;
}

export const Months: React.FC<MonthsProps> = ({ year, selected, disabled, onMonthClick }) => {
  function isInSelectedRange(month: Date): boolean {
    return toYearMonth(month) > toYearMonth(selected?.from) && toYearMonth(month) < toYearMonth(selected?.to);
  }

  function isStartOfRange(month: Date) {
    return toYearMonth(month) === toYearMonth(selected?.from);
  }

  function isEndOfRange(month: Date) {
    return toYearMonth(month) === toYearMonth(selected?.to);
  }

  return (
    <>
      {MONTH_NUMBERS.map((mon) => {
        const month = new Date(year, mon, 1);
        return (
          <Button
            key={`from_${mon}`}
            className={classNames(styles['month-button'], {
              [styles['selected-month-btn']]: isInSelectedRange(month),
              [styles['start-month-btn']]: isStartOfRange(month),
              [styles['end-month-btn']]: isEndOfRange(month),
              [styles['start-only-month-btn']]: isStartOfRange(month) && !selected?.to,
              [styles['row-start-btn']]: [0, 3, 6, 9].includes(mon),
              [styles['row-end-btn']]: [2, 5, 8, 11].includes(mon),
            })}
            disabled={monthMatchModifiers(month, disabled || [])}
            shape='rect'
            type='button'
            variant='secondary'
            label={format(month, DATE_FORMAT.MONTH_SHORT)}
            onClick={() => {
              onMonthClick(month);
            }}
          />
        );
      })}
    </>
  );
};
