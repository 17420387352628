import classNames from 'classnames';
import React, { cloneElement } from 'react';

import styles from './UniversalCard.module.scss';

/**
 * Note for dev:
 * All components are contained in one file, because all of them are connected to
 * @see UniversalCard component, and it makes no sense to use them without this components.
 */

// TODO: add storybook

interface ReusableProperties extends React.PropsWithChildren<unknown> {
  /**
   * If set to true - on horizontal card item will be added to left bar
   */
  // eslint-disable-next-line react-redux/no-unused-prop-types
  moveToLeftWhenHorizontal?: boolean;
  /**
   * If set to true - on vertical card item will be added to header
   */
  // eslint-disable-next-line react-redux/no-unused-prop-types
  moveToHeaderWhenVertical?: boolean;
  /**
   * If set to true - on horizontal card item will be not visible, and
   * on vertical card item will be on top
   */
  // eslint-disable-next-line react-redux/no-unused-prop-types
  assumeHeader?: boolean;

  /**
   * If set to true - on list item will be hidden
   */
  // eslint-disable-next-line react-redux/no-unused-prop-types
  hideWhenList?: boolean;

  /**
   * If set to true - on horizontal view item will be hidden
   */
  // eslint-disable-next-line react-redux/no-unused-prop-types
  hideWhenHorizontal?: boolean;
}

/**
 * Enum value is also used to specify css class
 */
export enum CardLayout {
  vertical = 'universal-card-vertical',
  horizontal = 'universal-card-horizontal',
  list = 'universal-card-list',
}

interface UniversalCardProps {
  /**
   * Layout value is also used as a class name
   */
  layout: CardLayout;
  className?: string;
  // eslint-disable-next-line react-redux/no-unused-prop-types
  contentClassName?: string;
  disabled?: boolean;
  children: React.ReactNode; // TODO: force children to be specific type
}

export const UniversalCard: React.FC<UniversalCardProps> = (props) => {
  switch (props.layout) {
    case CardLayout.horizontal:
      return <UniversalHorizontalCard {...props} />;
    case CardLayout.vertical:
      return <UniversalVerticalCard {...props} />;
    case CardLayout.list:
      return <UniversalListCard {...props} />;
  }
};

/**
 * Internal component for horizontal card layout.
 * Provides an ability to distinguish elements which needs to be displayed in
 * left part of card and move them there.
 */
const UniversalHorizontalCard: React.FC<UniversalCardProps> = (props) => {
  const left: React.ReactNode[] = [];
  const right: React.ReactNode[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.Children.forEach(props.children, (child?: any) => {
    const element = child as React.ReactElement<ReusableProperties> | null;
    if (element?.props.assumeHeader) {
      // Ignore header in horizontal layout
      return;
    }
    if (element?.props.moveToLeftWhenHorizontal) {
      left.push(child);
    } else {
      right.push(child);
    }
  });

  return (
    <div
      className={classNames(props.className, styles[props.layout], {
        [styles['disabled']]: props.disabled,
      })}
    >
      <div className={styles['layout-left']}>{left}</div>
      <div className={styles['layout-right']}>{right}</div>
    </div>
  );
};

/**
 * Internal component for vertical card layout.
 * Provides an ability to distinguish elements which needs to be displayed in
 * header and move them there.
 */
const UniversalVerticalCard: React.FC<UniversalCardProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let header: React.ReactElement<ReusableProperties> | undefined | null;
  const inHeader: React.ReactNode[] = [];
  const content: React.ReactNode[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.Children.forEach(props.children, (child?: React.ReactNode) => {
    const element = child as React.ReactElement<ReusableProperties> | null;
    if (element?.props.assumeHeader) {
      header = element;
    } else if (element?.props.moveToHeaderWhenVertical) {
      inHeader.push(child);
    } else {
      content.push(child);
    }
  });

  if (header) {
    const realHeader = header.props.children as React.ReactElement;
    const realHeaderWithContent = realHeader
      ? cloneElement(realHeader, realHeader?.props, inHeader)
      : header.props.children;
    header = cloneElement(header, header.props, realHeaderWithContent);
  }

  return (
    <div
      className={classNames(props.className, styles[props.layout], 'card el-02 hov-el-04', {
        [styles['disabled']]: props.disabled,
      })}
    >
      {header}
      <div className={classNames(styles['universal-content'], props.contentClassName)}>{content}</div>
    </div>
  );
};

/**
 * Internal component for list card layout.
 * Display element as table list item.
 */
const UniversalListCard: React.FC<UniversalCardProps> = (props) => {
  return (
    <div
      className={classNames(props.className, 'universal-card-table-view', styles[props.layout], {
        [styles['disabled']]: props.disabled,
      })}
    >
      {props.children}
    </div>
  );
};

interface UniversalHeaderProps extends ReusableProperties {
  className?: string;
}

/**
 * Wrapper for header in vertical layout
 */
export const UniversalHeader: React.FC<UniversalHeaderProps> = (props) => {
  return (
    <>
      <div className={classNames(props.className, styles['universal-header'])}>{props.children}</div>
    </>
  );
};

interface UniversalChartProps extends ReusableProperties {
  className?: string;
}

/**
 * Chart is displayed on the left side in hirozontal layout
 */
export const UniversalChart: React.FC<UniversalChartProps> = (props) => {
  return (
    <>
      <div className={classNames(props.className, styles['universal-chart'])}>{props.children}</div>
    </>
  );
};

interface UniversalAdditionalBlockProps extends ReusableProperties {
  className?: string;
}

/**
 * Additional block is displayed on the left side in hirozontal layout
 */
export const UniversalAdditionalBlock: React.FC<UniversalAdditionalBlockProps> = (props) => {
  return (
    <>
      <div className={classNames(props.className, styles['universal-additional-block'])}>{props.children}</div>
    </>
  );
};

interface UniversalMainPropertyProps extends ReusableProperties {
  /**
   * Will be visible only on horizontal layout
   */
  horizontalLabel?: string;
  className?: string;
  /**
   * Provides an ability to override order via Flex or Grid
   *
   * Can be negative
   */
  order?: number;
}

export const UniversalMainProperty: React.FC<UniversalMainPropertyProps> = (props) => {
  return (
    <>
      <p
        style={{ order: (props.order ?? 1) - 1 }}
        className={classNames('color-secondary', styles['universal-main-property-label'], {
          [styles['hide-in-list-view']]: props.hideWhenList,
          [styles['hide-in-horizontal-view']]: props.hideWhenHorizontal,
        })}
      >
        {props.horizontalLabel}
      </p>
      <div
        style={{ order: props.order }}
        className={classNames(props.className, styles['universal-main-property-value'], {
          [styles['hide-in-list-view']]: props.hideWhenList,
          [styles['hide-in-horizontal-view']]: props.hideWhenHorizontal,
        })}
      >
        {props.children}
      </div>
    </>
  );
};

interface UniversalSetpointProps extends ReusableProperties {
  /**
   * Will be visible only on vertical layout
   */
  verticalLabel: string;
  className?: string;
  forceShowVerticalLabel?: boolean;
}

/**
 * Wrapper for setpoint, also provides an ability to display label under setpoint
 * for vertical layout
 */
export const UniversalSetpoint: React.FC<UniversalSetpointProps> = (props) => {
  return (
    <>
      <div className={classNames(props.className, styles['universal-setpoint'])}>{props.children}</div>
      <p
        className={classNames(
          'text-center body-small color-secondary',
          props.forceShowVerticalLabel ? '' : styles['universal-setpoint-label-verical']
        )}
        style={{ marginTop: props.forceShowVerticalLabel ? '2px' : undefined }}
      >
        {props.verticalLabel}
      </p>
    </>
  );
};

interface UniversalDataListProps extends ReusableProperties {
  className?: string;
}

/**
 * Provides an ability to display a list of data properties, based on:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl
 */
export const UniversalDataList: React.FC<UniversalDataListProps> = (props) => {
  return (
    <>
      <dl className={classNames(props.className, styles['universal-data-list'])}>{props.children}</dl>
    </>
  );
};
