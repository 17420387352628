import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  DeleteOverriddenSchedulerProfileMutation,
  DeleteOverriddenSchedulerProfileMutationVariables,
} from './__generated__/deleteOverriddenSchedulerProfile.resources.gql';

const DELETE_OVERRIDDEN_SCHEDULER_PROFILE = gql`
  mutation DeleteOverriddenSchedulerProfile($systemId: Int!) {
    deleteOverriddenSchedulerProfile(systemId: $systemId)
  }
`;

export async function deleteOverriddenSchedulerProfile(systemId: number): Promise<boolean | undefined> {
  const result = await resourcesAPI.mutate<
    DeleteOverriddenSchedulerProfileMutation,
    DeleteOverriddenSchedulerProfileMutationVariables
  >({
    mutation: DELETE_OVERRIDDEN_SCHEDULER_PROFILE,
    variables: {
      systemId,
    },
  });

  return result.data?.deleteOverriddenSchedulerProfile ?? false;
}
