import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../Button/Button';
import CheckboxWithPartial from '../../Checkbox/CheckboxWithPartial/CheckboxWithPartial';
import { CheckboxState } from '../../Checkbox/interface';
import { Icon } from '../../Icon/Icon';
import { CircularLoader } from '../../Loader/Loader';
import { Dropdown } from '../../Popup';
import { PopupActions } from '../../Popup/interface';
import { MultiSelectProps } from '../interface';

import styles from './MultiSelect.module.scss';

export default function MultiSelect<T>({
  value,
  disabled = false,
  label,
  secondLabel,
  options,
  disabledWhenFewerOptions = true,
  className,
  id,
  labelPosition = 'up',
  placeholder = 'Select...',
  onClick,
  renderOption,
  useRenderInSelectedField = true,
  transformSelectedOption,
  isMultiselectMode = true,
  customElement,
  customElementPosition = 'top',
  stickyCustomElement = false,
  markAsLoading,
  required = false,
  popupActionsRef,
  hideAllButton = false,
  hasError,
}: MultiSelectProps<T>): JSX.Element {
  const [selected, setSelected] = useState(value);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [touched, setTouched] = useState(false);
  const loading = markAsLoading || waitingForResponse;
  const fewerOptionsDisabled = disabledWhenFewerOptions && options.length <= 1;

  const popupRef = popupActionsRef || useRef<PopupActions>(null);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  async function onHide() {
    setTouched(true);
    if (isMultiselectMode) {
      if (!_.isEqual(selected, value)) {
        setWaitingForResponse(true);
        try {
          await onClick(selected);
        } catch (error) {
          console.error(error);
        }
        setWaitingForResponse(false);
      }
    }
  }

  const selectedValues = options.filter((item) => selected.includes(item.key));
  const isAllSelected = selectedValues.length === options.length;
  const displayValue = _.map(selectedValues, (option) =>
    transformSelectedOption ? transformSelectedOption(option) : option.displayValue
  ).join(', ');

  async function handleOptionClick(option: T, e?: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    e?.preventDefault();
    e?.stopPropagation();
    if (isMultiselectMode) {
      if (selected.includes(option)) {
        setSelected(_.without(selected, option) as T[]);
      } else {
        setSelected([...selected, option]);
      }
    } else {
      setSelected([option]);

      popupRef?.current?.close();
      if (!selected.includes(option)) {
        setWaitingForResponse(true);
        try {
          await onClick([option]);
        } catch (error) {
          console.error(error);
        }
        setWaitingForResponse(false);
      }
    }
  }

  function toggleAll(e?: React.MouseEvent<HTMLElement, MouseEvent>): void {
    e?.preventDefault();
    e?.stopPropagation();
    if (isAllSelected) {
      setSelected([]);
    } else {
      setSelected(_.map(options, 'key'));
    }
  }

  function open(e: React.BaseSyntheticEvent): void {
    e.preventDefault();
    if (loading || disabled || fewerOptionsDisabled) {
      return;
    }
    popupRef?.current?.toggle();
  }

  // TODO: remove global style 'select-wrapper'
  return (
    <div
      className={classNames(styles['select-wrapper'], className, 'select-wrapper', {
        [styles['loading']]: loading,
      })}
    >
      {labelPosition === 'up' && label && (
        <div className='d-flex justify-content-space-between'>
          <span className={classNames('mb-8', 'color-secondary', styles['label'])}>{label}</span>
          {secondLabel && <span className={classNames('mb-8', 'color-secondary', styles['label'])}>{secondLabel}</span>}
        </div>
      )}
      <Dropdown
        forwardedRef={popupRef}
        triggerComponent={(isOpen) => (
          <div>
            <input
              className={classNames(styles['hidden-input'], {
                [styles['touched']]: touched,
              })}
              value={displayValue}
              type='text'
              name={id}
              required={required && !disabled}
              onChange={() => {
                setTouched(true);
              }}
              onFocus={open}
              onBlur={(e) => {
                e.preventDefault();
                setTouched(true);
              }}
            />
            <button
              id={id}
              className={classNames('input', 'with-pointer', styles['select-btn'], {
                ['touched']: touched,
                ['has-error']: hasError,
              })}
              type='button'
              disabled={loading || disabled || fewerOptionsDisabled}
              onClick={open}
            >
              <span className={classNames('color-secondary', styles['label-inside'])}>
                {labelPosition === 'inside' && <>{`${label}:`}&nbsp;</>}
              </span>
              {displayValue && (
                <span
                  className={classNames('color-primary', 'one-line-ellipsis')}
                  style={useRenderInSelectedField && renderOption ? { width: '100%' } : undefined}
                  title={displayValue}
                >
                  {useRenderInSelectedField && renderOption
                    ? selectedValues.map((i) => renderOption(i, false))
                    : displayValue}
                </span>
              )}
              {!displayValue && <span className={classNames('color-tertiary', 'body')}>{placeholder}</span>}
              {/* TODO: replace CircularLoader with designed `loading` icon */}
              {loading ? (
                <span className={styles['dropdown-down-loader']}>
                  <CircularLoader extraSmall />
                </span>
              ) : (
                <Icon
                  icon={loading ? 'refresh' : isOpen ? 'dropdown-up' : 'dropdown-down'}
                  size='s'
                  color={loading ? 'tertiary' : undefined}
                  rotating={loading}
                  className={styles['dropdown-down-icon']}
                />
              )}
            </button>
          </div>
        )}
        onHide={onHide}
      >
        <ul className={classNames('card el-08', styles['select-options'], 'select-options')}>
          {customElement && customElementPosition === 'top' && (
            <li
              className={classNames('card', 'el-08', styles['option'], styles['custom-element'], {
                [styles['custom-element-sticky-top']]: stickyCustomElement,
              })}
              key='custom'
            >
              {customElement}
            </li>
          )}
          {hideAllButton
            ? null
            : isMultiselectMode && (
                <li className={classNames('card', 'el-08', styles['option'])} key='all'>
                  <Button
                    onClick={toggleAll}
                    label={isAllSelected ? 'Deselect all' : 'Select all'}
                    size='small'
                    className={styles['all-button']}
                  />
                </li>
              )}
          {options.map((option) => {
            const description = _.get(option, 'config.description');
            return (
              <li
                className={classNames('card', 'el-08', 'hov-el-06', 'with-pointer', 'd-flex', styles['option'])}
                style={{ display: option.hidden ? 'none' : '' }}
                key={`${option.key}-${option.displayValue}`}
                onClick={(e) => handleOptionClick(option.key, e)}
              >
                {/* Do not use lazy-loading here, to prevent blinking (loading and component rebuild) of lazy-loaded component within Portals */}
                {isMultiselectMode && (
                  <CheckboxWithPartial
                    state={selected.includes(option.key) ? CheckboxState.Checked : CheckboxState.Empty}
                    onClick={() => handleOptionClick(option.key)}
                    className={styles['multiselect-checkbox']}
                  />
                )}

                {renderOption ? (
                  renderOption(option, true)
                ) : description ? (
                  <div className={'text-left ' + styles['option-with-description']}>
                    <p className='one-line-ellipsis'>{option.displayValue}</p>
                    <small className='color-secondary break-white-space'>{description}</small>
                  </div>
                ) : (
                  <span className='one-line-ellipsis'>{option.displayValue}</span>
                )}
              </li>
            );
          })}
          {customElement && customElementPosition === 'bottom' && (
            <li
              className={classNames('card', 'el-08', styles['option'], styles['custom-element'], {
                [styles['custom-element-sticky-bottom']]: stickyCustomElement,
              })}
              key='custom'
            >
              {customElement}
            </li>
          )}
        </ul>
      </Dropdown>
    </div>
  );
}
