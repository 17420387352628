import { gql } from '@apollo/client';

import { ModeType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  ChangePackageSystemModeMutation,
  ChangePackageSystemModeMutationVariables,
} from './__generated__/changePackageSystemMode.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterUpdatedPackageMode = Extract<
  ChangePackageSystemModeMutation['updatePackageSystemMode'],
  { __typename: 'SystemPackageAltc24Prog' }
>;

const CHANGE_PACKAGE_SYSTEM_MODE = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangePackageSystemMode($systemId: Int!, $mode: ModeType!) {
    updatePackageSystemMode(mode: $mode, systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changePackageSystemMode(
  systemId: number,
  mode: ModeType
): Promise<DataAfterUpdatedPackageMode | undefined> {
  const result = await resourcesAPI.mutate<ChangePackageSystemModeMutation, ChangePackageSystemModeMutationVariables>({
    mutation: CHANGE_PACKAGE_SYSTEM_MODE,
    variables: { systemId, mode },
  });

  const updateResult = result.data?.updatePackageSystemMode as DataAfterUpdatedPackageMode;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
