import { Icon } from '../Icon/Icon';

import styles from './ToastCloseButton.module.scss';

interface ToastCloseButtonProps {
  closeToast?: () => void;
}

const ToastCloseButton: React.FC<ToastCloseButtonProps> = (props) => {
  return <Icon icon='close' size='s' onClick={props.closeToast} className={styles['icon']} />;
};

export default ToastCloseButton;
