export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: string; output: string };
};

export type CreateTenantUserInput = {
  email: Scalars['EmailAddress']['input'];
  leaseIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  role: UserRoles;
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenantId: Scalars['Int']['input'];
};

export type CreateUserInput = {
  departmentIds: Array<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  organization_id: Scalars['Int']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  role: UserRoles;
};

export type Department = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  regions: Array<Scalars['Int']['output']>;
  siteFeatures: SiteFeaturesPermissions;
  sites: Array<Scalars['Int']['output']>;
  systems: Array<Scalars['Int']['output']>;
};

export type DepartmentInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
  regions: Array<Scalars['Int']['input']>;
  siteFeatures: SiteFeaturesPermissionsInput;
  sites: Array<Scalars['Int']['input']>;
  systems: Array<Scalars['Int']['input']>;
};

export type DepartmentOverview = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  regions: Array<Scalars['Int']['output']>;
  siteFeatures: SiteFeaturesPermissions;
  sites: Array<Scalars['Int']['output']>;
  systems: Array<Scalars['Int']['output']>;
  usersCount: Scalars['Int']['output'];
};

export type FilterConfig = {
  filters: Scalars['JSONObject']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  createDepartment: Scalars['String']['output'];
  createTenantUser: TenantUser;
  createUser: User;
  deleteDepartment: Scalars['Boolean']['output'];
  deleteFilterConfig: Scalars['Boolean']['output'];
  deleteTenantUser: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  putFilterConfig: Scalars['String']['output'];
  resetOrgUserOptionsCache: Scalars['Boolean']['output'];
  resetPassword: UserStatus;
  resetPasswordForEmail: UserStatus;
  updateDepartment: Scalars['Boolean']['output'];
  updateTenantUser: Scalars['Boolean']['output'];
  updateUser: Scalars['Boolean']['output'];
  updateUserSettings: Scalars['Boolean']['output'];
};

export type MutationcreateDepartmentArgs = {
  departmentInput: DepartmentInput;
};

export type MutationcreateTenantUserArgs = {
  createUserInput: CreateTenantUserInput;
};

export type MutationcreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type MutationdeleteDepartmentArgs = {
  departmentId: Scalars['String']['input'];
};

export type MutationdeleteFilterConfigArgs = {
  filterConfigId: Scalars['String']['input'];
};

export type MutationdeleteTenantUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationdeleteUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationputFilterConfigArgs = {
  filterConfigId?: InputMaybe<Scalars['String']['input']>;
  updateFilterConfigInput: UpdateFilterConfigInput;
};

export type MutationresetPasswordArgs = {
  userId: Scalars['String']['input'];
};

export type MutationresetPasswordForEmailArgs = {
  email: Scalars['EmailAddress']['input'];
};

export type MutationupdateDepartmentArgs = {
  departmentId: Scalars['String']['input'];
  departmentInput: DepartmentInput;
};

export type MutationupdateTenantUserArgs = {
  updateUserInput: UpdateTenantUserInput;
  userId: Scalars['String']['input'];
};

export type MutationupdateUserArgs = {
  updateUserInput: UpdateUserInput;
  userId: Scalars['String']['input'];
};

export type MutationupdateUserSettingsArgs = {
  updateUserSettingsInput: UpdateUserSettingsInput;
};

export type MyUser = {
  appearance?: Maybe<UserAppearance>;
  deleted: Scalars['Boolean']['output'];
  departmentIds: Array<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  filterConfigs?: Maybe<Array<FilterConfig>>;
  id: Scalars['String']['output'];
  last_login?: Maybe<Scalars['DateTime']['output']>;
  login_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<UserOrganization>;
  organization_id: Scalars['Int']['output'];
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  regions?: Maybe<Array<Scalars['Int']['output']>>;
  role: UserRoles;
  siteFeatures?: Maybe<SiteFeaturesPermissions>;
  sites?: Maybe<Array<Scalars['Int']['output']>>;
  systems?: Maybe<Array<Scalars['Int']['output']>>;
};

export type Query = {
  allTenantUsers: Array<TenantUser>;
  department: Department;
  departmentsOverview: Array<DepartmentOverview>;
  getFilterConfigsByKey: Array<FilterConfig>;
  getUserOptions: Array<UserOption>;
  getUsersWithExtraPermissions: Array<Scalars['String']['output']>;
  isEmailInUse: Scalars['Boolean']['output'];
  me: MyUser;
  /** This query wil return all users that have access to site. If `featureType` param is passed, will return only users that hae access to the feature on site. */
  siteUserOptions: Array<SiteUserOption>;
  tenantUser: TenantUser;
  tenantUsers: Array<TenantUser>;
  user: User;
  usersOverview: Array<UserOverview>;
};

export type QuerydepartmentArgs = {
  id: Scalars['String']['input'];
};

export type QuerydepartmentsOverviewArgs = {
  organizationId?: InputMaybe<Scalars['Float']['input']>;
};

export type QuerygetFilterConfigsByKeyArgs = {
  key: Scalars['String']['input'];
};

export type QueryisEmailInUseArgs = {
  email: Scalars['EmailAddress']['input'];
};

export type QuerymeArgs = {
  loginEvent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerysiteUserOptionsArgs = {
  featureType?: InputMaybe<SiteFeatureType>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
};

export type QuerytenantUserArgs = {
  userId: Scalars['String']['input'];
};

export type QuerytenantUsersArgs = {
  tenantId?: InputMaybe<Scalars['Int']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryuserArgs = {
  id: Scalars['String']['input'];
};

export enum SiteFeatureType {
  ADR = 'ADR',
  ALERTS = 'ALERTS',
  BENCHMARKING = 'BENCHMARKING',
  BILLING = 'BILLING',
  LEASES = 'LEASES',
  SCHEDULING = 'SCHEDULING',
  SPACE_MANAGEMENT = 'SPACE_MANAGEMENT',
  UTILITIES = 'UTILITIES',
}

export type SiteFeaturesPermissions = {
  ADR: Array<Scalars['Float']['output']>;
  ALERTS: Array<Scalars['Float']['output']>;
  BENCHMARKING: Array<Scalars['Float']['output']>;
  BILLING: Array<Scalars['Float']['output']>;
  LEASES: Array<Scalars['Float']['output']>;
  SCHEDULING: Array<Scalars['Float']['output']>;
  SPACE_MANAGEMENT: Array<Scalars['Float']['output']>;
  UTILITIES: Array<Scalars['Float']['output']>;
};

export type SiteFeaturesPermissionsInput = {
  ADR: Array<Scalars['Float']['input']>;
  ALERTS: Array<Scalars['Float']['input']>;
  BENCHMARKING: Array<Scalars['Float']['input']>;
  BILLING: Array<Scalars['Float']['input']>;
  LEASES: Array<Scalars['Float']['input']>;
  SCHEDULING: Array<Scalars['Float']['input']>;
  SPACE_MANAGEMENT: Array<Scalars['Float']['input']>;
  UTILITIES: Array<Scalars['Float']['input']>;
};

export type SiteUserOption = {
  email: Scalars['EmailAddress']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
};

export type TenantUser = {
  deleted: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  leaseIds: Array<Scalars['Int']['output']>;
  loginCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  role: UserRoles;
  spaceIds?: Maybe<Array<Scalars['Int']['output']>>;
  tenantId: Scalars['Int']['output'];
};

export type UpdateFilterConfigInput = {
  filters: Scalars['JSONObject']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTenantUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  leaseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  resetPassword?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<UserRoles>;
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenantId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserInput = {
  appearance?: InputMaybe<UserAppearance>;
  departmentIds: Array<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  role?: InputMaybe<UserRoles>;
};

export type UpdateUserSettingsInput = {
  appearance?: InputMaybe<UserAppearance>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
};

export type User = {
  appearance?: Maybe<UserAppearance>;
  deleted: Scalars['Boolean']['output'];
  departmentIds: Array<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  id: Scalars['String']['output'];
  last_login?: Maybe<Scalars['DateTime']['output']>;
  login_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<UserOrganization>;
  organization_id: Scalars['Int']['output'];
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  regions?: Maybe<Array<Scalars['Int']['output']>>;
  role: UserRoles;
  siteFeatures?: Maybe<SiteFeaturesPermissions>;
  sites?: Maybe<Array<Scalars['Int']['output']>>;
  systems?: Maybe<Array<Scalars['Int']['output']>>;
};

export enum UserAppearance {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  SYSTEM = 'SYSTEM',
}

export type UserOption = {
  displayAs: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type UserOrganization = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type UserOverview = {
  deleted: Scalars['Boolean']['output'];
  departments: Array<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  id: Scalars['String']['output'];
  last_login?: Maybe<Scalars['DateTime']['output']>;
  login_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<UserOrganization>;
  role: UserRoles;
};

export enum UserRoles {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  OPERATOR = 'OPERATOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TENANT = 'TENANT',
}

export enum UserStatus {
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  CONFIRMED = 'CONFIRMED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  RESET_REQUIRED = 'RESET_REQUIRED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNKNOWN = 'UNKNOWN',
}
