// TODO: remove this rule when eslint will be fixed, it just does not see that we are using these props
/* eslint-disable react-redux/no-unused-prop-types */
import React from 'react';

import { GroupedList } from 'src/atoms/GroupedList/GroupedList';
import { AlertColor } from 'src/core/apollo/__generated__/alertsGlobalTypes';
import { SiteFeatureType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { useAppSelector } from 'src/core/store/hooks';
import { selectNotificationsContentByFilters } from 'src/logic/alerts/alertsSlice';
import { selectSiteIdsWithFeature } from 'src/modules/sites/sitesSlice';
import { Icon, SupportedIconColors } from 'src/shared/components/Icon/Icon';
import { CustomTooltip } from 'src/shared/components/Popup';

interface Props {
  siteId?: number;
  sitesIds?: number[];
  systemType?: SystemType;
  systemsTypes?: SystemType[];
  systemId?: number;
  big?: boolean;
  className?: string;
}

/**
 * Automatically defines which alerts to display based on the provided props
 */
export const Alert: React.FC<Props> = (props) => {
  const siteIdsWithAlertsFeature = useAppSelector(selectSiteIdsWithFeature(SiteFeatureType.ALERTS));

  if (props.siteId) {
    if (siteIdsWithAlertsFeature.includes(props.siteId)) {
      return <AlertIcon {...props} />;
    }
    return <Placeholder {...props} showForDev={false} />;
  }

  if (props.sitesIds) {
    const siteIds = props.sitesIds.filter((siteId) => siteIdsWithAlertsFeature.includes(siteId));
    if (siteIds.length > 0) {
      return <AlertIcon {...props} sitesIds={siteIds} />;
    }

    return <Placeholder {...props} showForDev={false} />;
  }

  // If no siteId or sitesIds are provided, we show the alert icon for development purposes only
  if (props.systemId) {
    console.assert(props.systemId && props.siteId, 'You must provide a siteId when using systemId');
  }

  // It should be always be "true" - so we easily can find all the places where props are configured incorrectly
  return <Placeholder {...props} showForDev={true} />;
};

const Placeholder: React.FC<Props & { showForDev: boolean }> = (props) => (
  <span className={props.className}>
    <span
      style={{
        display: 'inline-flex',
        padding: props.big ? '10px' : '4px',
        background: 'red',
        visibility: props.showForDev ? undefined : 'hidden',
      }}
    >
      <Icon icon='warning' size='s' />
    </span>
  </span>
);

/**
 * Automatically defines which alerts to display based on the provided props
 */
const AlertIcon: React.FC<Props> = ({ siteId, sitesIds, systemType, systemsTypes, systemId, big, className }) => {
  const { color, data } = useAppSelector(
    selectNotificationsContentByFilters({
      siteId,
      siteIds: sitesIds,
      systemTypes: systemsTypes ? systemsTypes : systemType ? [systemType] : undefined,
      systemId,
    })
  );

  const iconColor = color ? AlertToIconColor[color] : 'secondary';

  return (
    <span className={className}>
      <CustomTooltip
        triggerComponent={
          <span
            style={{
              display: 'inline-flex',
              borderRadius: '50%',
              padding: big ? '10px' : '4px',
              background: 'var(--elevation-12)',
            }}
          >
            <Icon icon='warning' color={iconColor} size='s' />
          </span>
        }
      >
        <GroupedList content={data} />
      </CustomTooltip>
    </span>
  );
};

const AlertToIconColor: Record<AlertColor, SupportedIconColors> = {
  [AlertColor.RED]: 'red',
  [AlertColor.YELLOW]: 'yellow',
  [AlertColor.ORANGE]: 'orange',
  [AlertColor.GREEN]: 'green',
};
