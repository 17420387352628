import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetRegionsToSystemsTreeQueryVariables = Types.Exact<{
  organizationId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetRegionsToSystemsTreeQuery = {
  getRegionsToSystemsTree: Array<{
    id: number;
    name: string;
    countryCode: string;
    country: string;
    sites: Array<{
      id: number;
      name: string;
      streetNumber?: string | null;
      streetName: string;
      city: string;
      state: string;
      systems: Array<{
        id: number;
        name: string;
        floor?: string | null;
        type: Types.SystemType;
        groupType: Types.SystemGroupType;
        organizationIds: Array<number>;
        systemGroupId?: number | null;
      }>;
      features: Array<{ isActive: boolean; type: Types.SiteFeatureType }>;
    }>;
  }>;
};

export const GetRegionsToSystemsTreeDocument = gql`
  query GetRegionsToSystemsTree($organizationId: Int) {
    getRegionsToSystemsTree(organizationId: $organizationId) {
      id
      name
      countryCode
      country
      sites {
        id
        name
        streetNumber
        streetName
        city
        state
        systems {
          id
          name
          floor
          type
          groupType
          organizationIds
          systemGroupId
        }
        features {
          isActive
          type
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetRegionsToSystemsTree(
      variables?: GetRegionsToSystemsTreeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetRegionsToSystemsTreeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetRegionsToSystemsTreeQuery>(GetRegionsToSystemsTreeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetRegionsToSystemsTree',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
