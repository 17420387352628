export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    MeterIntegrationUnion: [
      'ConedOruMeterIntegration',
      'DRMeterIntegration',
      'EnergyStarMeterIntegration',
      'UtilityAPIMeterIntegration',
    ],
  },
};
export default result;
