import _ from 'lodash';
import { createContext, useContext, useEffect, useMemo } from 'react';

import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { mapAsOptions } from 'src/cdk/mappers/mapAsOptions';
import { mapUnitToPrecision } from 'src/cdk/mappers/mapUnitToPrecision';
import { fillColorByIndex } from 'src/cdk/utils/fillColorByIndex';
import { METRIC_SYMBOL, N_A } from 'src/constants';
import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { METRIC_TYPE_LABEL } from 'src/core/enum-labels';
import { useAppDispatch, useAppSelector } from 'src/core/store/hooks';
import { Metric } from 'src/materials/metrics/interface';
import { resetFilterFields, setFilterFields } from 'src/modules/filters/filtersSlice';
import { useFilterValues } from 'src/modules/filters/public/useFilterValues';
import { selectSiteById } from 'src/modules/sites/sitesSlice';

import api from './gql';
import { GetShadowMeterSystemsQuery } from './gql/__generated__/meters.resources.generated';

type ShadowMeterSystem = Extract<GetShadowMeterSystemsQuery['systems'][0], { id: number }> & {
  metrics: Metric[];
};

type UtilityFilterValues = {
  systemIds: number[];
};

interface ContextData {
  siteId: number;
  systems: ShadowMeterSystem[];
  filtersStorageKey: string;
  utilityType: SystemType;
  byId: Record<number, ShadowMeterSystem> | number;
}

const SystemMetersContext = createContext<ContextData | null>(null);

export function useMetersContextProvider(siteId: number, utilityType: SystemType) {
  const dispatch = useAppDispatch();

  const { isLoading, response: systemMetrics = [] } = useDataFetchOnMountWithDeps(
    () =>
      api.GetShadowMeterSystems({ siteIds: [siteId], types: [utilityType] }).then((res) => {
        const systems = res.systems as ShadowMeterSystem[];
        const systemIds = systems.map((system) => system.id);

        return api.GetSystemMetrics({ systemIds }).then((res) => {
          return systems.map((system) => {
            return {
              ...system,
              metrics: _.filter(res.systemMetrics, { systemId: system.id }).map((metric, index) => {
                const systemName = system?.name ?? N_A;
                return {
                  ...metric,
                  tooltipColumnValue: [['Station', systemName]],
                  name: METRIC_TYPE_LABEL[metric.type],
                  unit: METRIC_SYMBOL[metric.measurement],
                  precision: mapUnitToPrecision(METRIC_SYMBOL[metric.measurement]),
                  styleVariant: fillColorByIndex(index),
                } satisfies Metric;
              }),
            };
          }) as ShadowMeterSystem[];
        });
      }),
    [siteId, utilityType]
  );

  useEffect(() => {
    dispatch(resetFilterFields());
    dispatch(
      setFilterFields([
        {
          label: 'Systems',
          name: 'systemIds',
          options: mapAsOptions(systemMetrics, 'id', 'name'),
          type: 'multiselect',
        },
      ])
    );
  }, [systemMetrics]);

  const data = useMemo(() => {
    const contextData: ContextData = {
      siteId: siteId,
      systems: systemMetrics,
      filtersStorageKey: `shadow-meters-${siteId}-${utilityType}`,
      utilityType: utilityType,
      byId: _.keyBy(systemMetrics, 'id'),
    };

    return contextData;
  }, [systemMetrics, siteId, utilityType]);

  return {
    isLoading,
    Provider: ProviderHOC(data),
  };
}

function ProviderHOC(contextData: ContextData): React.FC<React.PropsWithChildren> {
  return function UtilityMetersContextProvider({ children }) {
    return <SystemMetersContext.Provider value={contextData}>{children}</SystemMetersContext.Provider>;
  };
}

export function useSystemMetersContext() {
  const context = useContext(SystemMetersContext);
  const site = useAppSelector(selectSiteById(context?.siteId));

  const { filterValues } = useFilterValues<UtilityFilterValues>({ storageKey: context!.filtersStorageKey });

  if (!context) {
    throw new Error('useSystemMetersContext must be used within a UtilityMetricsProvider');
  }

  const result = useMemo(() => {
    const allSystemIds = context.systems.map((m) => m.id);
    const selectedSystemIds = filterValues.systemIds || allSystemIds;
    const selecteSystems = context.systems.filter((system) => selectedSystemIds.includes(system.id));

    return {
      siteId: context.siteId,
      utilityType: context.utilityType,
      siteName: site?.nameOrAddess || N_A,
      timezone: site?.timezone || 'UTC',
      systems: selecteSystems,
      metrics: (_.map(selecteSystems, 'metrics') || []).flat(),
      filtersStorageKey: context.filtersStorageKey,
    };
  }, [context, filterValues.systemIds]);

  return result;
}
