/**
 * Modify the existing period to fit the new period
 * @returns updated existing period
 */
export function cutExistingInteraction(
  [currentStart, currentEnd]: [number, number],
  [newStart, newEnd]: [number, number]
): [number, number] {
  const currentEndWithinNewRange = newStart <= currentEnd && currentEnd <= newEnd;
  const currentStartWithinNewRange = newStart <= currentStart && currentStart <= newEnd;

  // If current range 100% within new range, return empty range
  if (currentEndWithinNewRange && currentStartWithinNewRange) {
    return [-1, -1];
  }

  // If current range is partially within new range, return the part that is not within new range
  if (currentEndWithinNewRange) {
    return [currentStart, newStart];
  }
  if (currentStartWithinNewRange) {
    return [newEnd, currentEnd];
  }

  // If new range inside of current range, return only first part
  if (currentStart <= newStart && newEnd <= currentEnd) {
    return [currentStart, newStart];
  }

  // Otherwise, return the current range without any changes
  return [currentStart, currentEnd];
}
