import { isBefore, subMinutes } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { isNumber, isUndefined } from 'lodash';

import { getExportTimestampFormat } from 'src/cdk/formatter/relativeTimeFormat';
import { DATE_FORMAT, METRIC_SYMBOL, N_A } from 'src/constants';
import { ColumnType, ExportMetricColumn } from 'src/core/apollo/__generated__/exportGlobalTypes';
import { MetricsMeasurementType, MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { formatValueToDisplay } from 'src/shared/components/charts/MultilineChart/utils';

import { MetricWithData } from '../interface';

function metricToExportAdapter(
  metricsWithData: MetricWithData[],
  timezone: string,
  step: MetricsStep
): [ExportMetricColumn[], Record<string, string | undefined>[]] {
  const lastMetricTime = subMinutes(utcToZonedTime(new Date().toISOString(), timezone), 15);
  const fields: ExportMetricColumn[] = [
    {
      name: 'Timestamp',
      type: ColumnType.STRING,
    },
    {
      name: 'Day',
      type: ColumnType.STRING,
    },
    {
      name: 'System ID',
      type: ColumnType.NUMBER,
    },
  ];
  const csvRawData: Record<string, string | undefined>[] = [];

  // Iterate through metrics data which constists of metrics and their data
  for (const { measurement, data, name, selected, systemId } of metricsWithData) {
    if (!selected) {
      continue;
    }

    const metricName = `${name} (${METRIC_SYMBOL[measurement]})`;
    fields.push({
      name: metricName,
      type: ColumnType.NUMBER,
    });

    // Create csv rows if needed and fill each row with data for specific metric
    for (let index = 0; index < (data?.length ?? 0); index++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const valueAndTimestamp = data![index];
      if (!isBefore(valueAndTimestamp!.timestamp, lastMetricTime)) {
        continue;
      }

      if (!csvRawData[index]) {
        csvRawData.push({});
      }

      if (measurement === MetricsMeasurementType.USAGE) {
        const value = formatValueToDisplay(valueAndTimestamp?.value);
        csvRawData[index][metricName] = isNumber(value) ? value.toString() : value;
      } else {
        csvRawData[index][metricName] = valueAndTimestamp?.value?.toString();
      }

      // Refresh timestamp if needed
      if (valueAndTimestamp?.timestamp && !csvRawData[index][fields[0].name]) {
        const timestampInTZ = zonedTimeToUtc(valueAndTimestamp?.timestamp.toISOString(), timezone);
        // Time with local time zone of the site, (calculated as absolute time, since `timestamp` is adjusted)
        csvRawData[index][fields[0].name] = formatInTimeZone(timestampInTZ, timezone, getExportTimestampFormat(step));
        // Local site week day for metric, since `timestamp` is already adjusted to local site time
        csvRawData[index][fields[1].name] = formatInTimeZone(timestampInTZ, timezone, DATE_FORMAT.WEEKDAY_LONG);
        csvRawData[index][fields[2].name] = `${systemId}`;
      }
    }
  }

  return [fields, csvRawData];
}

export function generateAnalyticsExportData(
  metricsWithData: MetricWithData[],
  timezone: string,
  step: MetricsStep
): [ExportMetricColumn[], string[][]] {
  const [fields, csvRawData] = metricToExportAdapter(metricsWithData, timezone, step);

  const data: string[][] = [];
  for (const tableRow of csvRawData) {
    const rowData: string[] = [];
    for (const column of fields) {
      const value = tableRow[column.name];
      rowData.push(isUndefined(value) ? N_A : value);
    }
    data.push(rowData);
  }

  return [fields, data];
}
