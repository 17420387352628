import classNames from 'classnames';

import { SpaceType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { packageSpaceDisplayValue } from 'src/enums';
import { OptionItem, Select } from 'src/shared/components/Select';

import styles from './PackageOccupancySelector.module.scss';

export interface PackageOccupancySelectorProps {
  systemType: SystemType;
  disabled: boolean;
  space?: SpaceType | null;
  hideLabel?: boolean;
  labelUp?: boolean;
  onChange: (value: SpaceType) => void;
}

export const PackageSystemSpaceValues: Record<string, SpaceType[]> = {
  [SystemType.PACKAGE_ALTC24PROG]: [SpaceType.OCCUPIED, SpaceType.UNOCCUPIED],
  [SystemType.PACKAGE_HONEYWELL_TC500AN]: [SpaceType.OCCUPIED, SpaceType.UNOCCUPIED, SpaceType.NO_OVERRIDE],
};

export function buildPackageSpaceOptions(systemType: SystemType): OptionItem<SpaceType>[] {
  return PackageSystemSpaceValues[systemType].map((key) => ({
    key,
    displayValue: packageSpaceDisplayValue[key],
  }));
}

const PackageOccupancySelector: React.FC<PackageOccupancySelectorProps> = (props) => {
  const options = buildPackageSpaceOptions(props.systemType);

  return (
    <div className={classNames(styles['package-occupancy-wrapper'])}>
      <Select
        label={props.hideLabel ? undefined : 'Occupancy'}
        labelPosition={props.hideLabel ? undefined : props.labelUp ? 'up' : 'inside'}
        disabled={props.disabled}
        options={options}
        value={props.space}
        onClick={props.onChange}
      />
    </div>
  );
};

export default PackageOccupancySelector;
