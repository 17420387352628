import { spaceTemperatureStorageService } from '../../modules/systems/service/spaceTemperatureRulesStorageService';
import { systemMetricsStorageService } from '../../modules/systems/service/systemMetricsStorageService';

import authService from './authService';
import Logger from './logger';

/**
 * Perform app initialization action and all lifecycle hooks, like get current theme, etc.
 *
 * Note: Application can be initialized even when user was not logged in.
 */
export async function appInit(): Promise<void> {
  Logger.info('onAppInit');

  await authService.init();
  systemMetricsStorageService.init();
  spaceTemperatureStorageService.init();

  // Ensure that login is executed in the end of that function
  const login = (await import('./appLifecycle')).login;
  login();
}
