import _ from 'lodash';

import { NonTenantUserRoles, SystemTypesForSchedule } from 'src/constants';
import { SiteFeatureType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import { UtilityServiceTypes } from 'src/core/apollo/__generated__/utilityGlobalTypes';
import { UTILITY_SERVICE_TYPE_LABEL } from 'src/core/enum-labels';
import { SystemMetersInfo } from 'src/logic/meter/config';
import { Routes } from 'src/routes';
import { SupportedIcon } from 'src/shared/components/Icon/gen/suported-icons';

export interface INavigationItem {
  type: NavItemType;
  label?: string;
  linkTo?: Routes;
  aliasToHighlight?: Routes;
  icon?: SupportedIcon;
  children?: INavigationItem[];
  onClick?: () => void;
  onlyFor?: UserRoles[] | null;
  systemTypes?: SystemType[] | null;
  feature?: SiteFeatureType;
  utilityType?: UtilityServiceTypes;
  sticky?: boolean;
}

export enum NavItemType {
  Item,
  Group,
  Divider,
}

export const navigationConfig: INavigationItem[] = [
  {
    label: 'Map',
    icon: 'pin',
    linkTo: Routes.Map,
    type: NavItemType.Item,
  },
  {
    label: 'Alerts',
    icon: 'warning',
    linkTo: Routes.Alerts,
    type: NavItemType.Item,
    feature: SiteFeatureType.ALERTS,
  },
  {
    label: 'Site Management',
    icon: 'site',
    linkTo: Routes.SiteManagementOverview,
    type: NavItemType.Item,
    feature: SiteFeatureType.SPACE_MANAGEMENT,
    onlyFor: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN],
  },
  {
    label: 'Billing',
    icon: 'dollar',
    linkTo: Routes.Billing,
    type: NavItemType.Item,
    feature: SiteFeatureType.BILLING,
    onlyFor: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN],
  },
  {
    label: 'Benchmarking',
    icon: 'chart',
    linkTo: Routes.Benchmark,
    onlyFor: NonTenantUserRoles,
    type: NavItemType.Item,
    feature: SiteFeatureType.BENCHMARKING,
  },
  {
    label: 'Schedule Profiles',
    icon: 'calendar',
    linkTo: Routes.ScheduleProfiles,
    type: NavItemType.Item,
    systemTypes: SystemTypesForSchedule,
    onlyFor: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN, UserRoles.OPERATOR],
    feature: SiteFeatureType.SCHEDULING,
  },
  {
    label: 'Demand Response',
    icon: 'chart-arrow-down-axis',
    linkTo: Routes.DemandResponse,
    type: NavItemType.Item,
    feature: SiteFeatureType.ADR,
  },
  {
    label: 'Utilities',
    icon: 'energy',
    type: NavItemType.Group,
    children: [
      ..._.orderBy(
        _.map(UtilityServiceTypes, (type) => ({
          feature: SiteFeatureType.UTILITIES,
          onlyFor: NonTenantUserRoles,
          label: UTILITY_SERVICE_TYPE_LABEL[type],
          linkTo: (Routes.Utilities + '/' + type.toLowerCase()) as Routes,
          type: NavItemType.Item,
          utilityType: type,
        })),
        'label',
        'asc'
      ),
    ],
  },
  {
    label: 'Meters',
    icon: 'gauge',
    type: NavItemType.Group,
    children: [
      {
        label: 'Electric Submeters',
        linkTo: Routes.ElectricSubmeters,
        type: NavItemType.Item,
        systemTypes: [SystemType.ELECTRICITY_SUBMETER],
      },
      ..._.map(SystemMetersInfo, (value, type) => ({
        label: value?.label,
        linkTo: (Routes.Meter + '/' + value?.url) as Routes,
        type: NavItemType.Item,
        systemTypes: [type as SystemType],
      })),
    ],
  },
  {
    label: 'Indoor Environment',
    icon: 'air',
    type: NavItemType.Group,
    systemTypes: [SystemType.TBL_TCI],
    onlyFor: NonTenantUserRoles,
    children: [
      {
        label: 'Air Quality',
        linkTo: Routes.AQ,
        type: NavItemType.Item,
        systemTypes: [SystemType.TBL_TCI],
      },
    ],
  },
  {
    type: NavItemType.Group,
    label: 'Operations',
    icon: 'systems',
    aliasToHighlight: Routes.Systems,
    onlyFor: NonTenantUserRoles,
    // linkTo: Routes.Systems,
    systemTypes: [
      SystemType.STEAM_PRV_STATION,
      SystemType.HW_BOILER_TEKMAR_284,
      SystemType.HW_BOILER_HT_HWR,
      SystemType.ST_BOILER_F_HT_MPC,
      SystemType.PACKAGE_ALTC24PROG,
      SystemType.PACKAGE_HONEYWELL_TC500AN,
      SystemType.LUTRON_VIVE_LIGHTING,
      SystemType.WEATHER_STATION_WTS506,
      SystemType.SMART_OUTLET_T0006623,
      SystemType.CHARGE_POINT,
    ],
    children: [
      {
        label: 'Air Handling Units',
        linkTo: Routes.PackagedSystems,
        type: NavItemType.Item,
        systemTypes: [SystemType.PACKAGE_ALTC24PROG, SystemType.PACKAGE_HONEYWELL_TC500AN],
      },
      {
        label: 'Boilers',
        linkTo: Routes.BoilersSystems,
        type: NavItemType.Item,
        systemTypes: [SystemType.HW_BOILER_TEKMAR_284, SystemType.HW_BOILER_HT_HWR, SystemType.ST_BOILER_F_HT_MPC],
      },
      {
        label: 'EV Chargers',
        linkTo: Routes.ChargingStationSystems,
        type: NavItemType.Item,
        systemTypes: [SystemType.CHARGE_POINT],
      },
      {
        label: 'Lighting Systems',
        linkTo: Routes.LightingSystems,
        type: NavItemType.Item,
        systemTypes: [SystemType.LUTRON_VIVE_LIGHTING],
      },
      {
        label: 'Smart Outlets',
        linkTo: Routes.SmartOutletSystems,
        type: NavItemType.Item,
        systemTypes: [SystemType.SMART_OUTLET_T0006623],
      },
      {
        label: 'Steam PRV Stations',
        linkTo: Routes.PrvSystems,
        type: NavItemType.Item,
        systemTypes: [SystemType.STEAM_PRV_STATION],
      },
      {
        label: 'Weather Stations',
        linkTo: Routes.WeatherSystems,
        type: NavItemType.Item,
        systemTypes: [SystemType.WEATHER_STATION_WTS506],
      },
    ],
  },
  {
    label: 'Administration',
    icon: 'gear',
    linkTo: Routes.Admin,
    type: NavItemType.Item,
    onlyFor: [UserRoles.SUPER_ADMIN], // TODO: provide access for Admin
    sticky: true,
  },
  {
    label: 'Devices',
    icon: 'device',
    linkTo: Routes.Devices,
    type: NavItemType.Item,
    onlyFor: [UserRoles.SUPER_ADMIN],
    sticky: true,
  },
  {
    label: 'Integrations',
    icon: 'external',
    onlyFor: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN],
    linkTo: Routes.Integrations,
    type: NavItemType.Item,
    sticky: true,
  },
];
