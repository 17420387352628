import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

import { useFetchOnMount } from 'src/cdk/hooks/useFetchOnMount';
import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { useAppDispatch } from 'src/core/store/hooks';
import DetailsPageLayout from 'src/fragments/layouts/DetailsPageLayout';
import { readAllAlertNotificationsForSystems } from 'src/logic/alerts/alertsActions';
import { fetchSystemsForSystemGroupIfNotExist } from 'src/modules/systems/systemsActions';
import { selectedSystemGroupById, selectedSystemsBySystemGroupById } from 'src/modules/systems/systemsSlice';
import { Routes } from 'src/routes';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';

interface Props {
  settings: Partial<Record<SystemType, [Routes, string[]]>>;
}

const SystemGroupDetailsPage: React.FC<Props> = ({ settings }) => {
  const dispatch = useAppDispatch();
  const systemGroupId = useNumberParam('id');
  const systemGroup = useSelector(selectedSystemGroupById(systemGroupId));
  const systems = useSelector(selectedSystemsBySystemGroupById(systemGroupId), _.isEqual);

  const { isLoading, isFailed } = useFetchOnMount(fetchSystemsForSystemGroupIfNotExist(systemGroupId));

  useEffect(() => {
    const systemIds = systems ? systems.map(({ id }) => id) : [];
    if (systemIds.length) {
      dispatch(readAllAlertNotificationsForSystems(systemIds));
    }
  }, [dispatch, systems]);

  const [returnPage, tabs] = useMemo(() => {
    return systemGroup ? settings[systemGroup.type] || ['..', []] : ['..', []];
  }, [systemGroup?.type, settings]);

  return (
    <DetailsPageLayout
      backUrl={returnPage}
      idParamName='id'
      entity='Systems Group'
      entityName={systemGroup?.name}
      relatedSiteId={systemGroup?.siteId}
      isInitialLoading={isLoading}
      isFailed={isFailed}
      removeBottomMargin
    >
      <UrlTabs names={tabs} />
      <Outlet />
    </DetailsPageLayout>
  );
};

export default SystemGroupDetailsPage;
