import classNames from 'classnames';

import { ColorConfig } from 'src/enums';

import DotsIndicator from '../../DotsIndicator/DotsIndicator';
import { Icon } from '../../Icon/Icon';
import { SupportedIcon } from '../../Icon/gen/suported-icons';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';
import styles from '../Checkbox.module.scss';
import { CheckboxState, CheckboxWithPartialProps } from '../interface';

const stateToIconMap: Record<CheckboxState, SupportedIcon | null> = {
  [CheckboxState.Empty]: null,
  [CheckboxState.Partial]: 'minus',
  [CheckboxState.Checked]: 'check',
};

/**
 * Checkbox component has no inner state, so value, which represents state
 * of it, should be passed via props within `state` field.
 */
const CheckboxWithPartial: React.FC<CheckboxWithPartialProps> = (props) => {
  const disabled = props.disabled ?? false;
  const type = !props.type ? 'checkbox' : props.type;

  const selected = props.state === CheckboxState.Partial || props.state === CheckboxState.Checked;
  const icon = stateToIconMap[props.state];
  const checkboxStyle: ColorConfig = props.styleVariant ?? ColorConfig.blueSolidOpaque;

  return (
    <label
      title={props.ellipsis ? props.tooltip || props.label : props.tooltip}
      className={classNames(
        'd-flex',
        'align-items-center',
        'with-pointer',
        {
          [styles['checkbox-container']]: type === 'checkbox',
          [styles['switch-container']]: type === 'switch' || type === 'switch-small',
          [styles['small']]: type === 'switch-small',
          [styles['disabled']]: disabled,
          'one-line-ellipsis pr-4': props.ellipsis,
        },
        props.className
      )}
      onClick={(e) => {
        if (props.stopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      <input
        type='checkbox'
        name={props.name}
        checked={selected}
        onChange={(e) => {
          if (disabled) {
            return;
          }
          e.stopPropagation();
          props.onClick();
        }}
      />
      <div
        className={classNames('card el-12 hov-el-16', styles[type === 'switch-small' ? 'switch' : type], {
          [styles['selected']]: selected,
          [styles[checkboxStyle]]: props.styleVariant,
        })}
      >
        {icon ? <Icon className={styles['tick-icon']} icon={icon} size='xs' /> : <span>&nbsp;</span>}
      </div>
      {!!props.label ? (
        <span className={classNames('one-line-ellipsis', { 'color-tertiary': props.dimmed })}>
          {props.label}
          {props.tooltipWithIcon && props.tooltip ? (
            <InfoTooltip className={styles['tooltip-i-icon']}>{props.tooltip}</InfoTooltip>
          ) : null}
        </span>
      ) : null}
      {/* TODO:
       * Rework Dots indicator after Checkbox labels with children or render prop approach.
       * So it will use composition and will be a bit more flexible.
       */}
      {props.dotWithColor && (
        <span className='mx-8'>
          <DotsIndicator value={1} colorBehavior={props.dotWithColor} numberOfDots={1} />
        </span>
      )}
    </label>
  );
};

export default CheckboxWithPartial;
