import { clamp } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Input } from '../Input/Input';

export interface InputNumericProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  /**
   * disabled={false} by default
   */
  disabled?: boolean;
  tabIndex?: number;
  /**
   * disabledHandleChangeValidation={false} by default
   */
  disabledHandleChangeValidation?: boolean;
}

/**
 * NumericInput built on top of <Input /> component
 * It's aimed to bypass some `type='number'` limitations, for example backspace is not working as expected
 * Unlike regular input, it takes number type as value and returns number type with onChange event
 * It allows to type any numeric value and then clamping it with onBlur event
 */
export const InputNumeric: React.FC<InputNumericProps> = ({
  disabled = false,
  disabledHandleChangeValidation = false,
  value,
  onChange,
  min,
  max,
  tabIndex,
}) => {
  const [valueToDisplay, setValueToDisplay] = useState<string>(value.toString());

  useEffect(() => {
    setValueToDisplay(value.toString());
  }, [value]);

  function handleChange(newValue: string) {
    const valueToDisplayNumber = Number(newValue);
    if (
      !disabledHandleChangeValidation &&
      (valueToDisplayNumber < min || valueToDisplayNumber > max || valueToDisplayNumber === value)
    ) {
      return;
    }
    onChange(valueToDisplayNumber);
    setValueToDisplay(newValue);
  }

  function onBlur() {
    const clampedValue = clamp(Number(valueToDisplay), min, max);
    onChange(clampedValue);
    setValueToDisplay(clampedValue.toString());
  }

  return (
    <Input
      tabIndex={tabIndex}
      type='number'
      disabled={disabled}
      onChange={handleChange}
      onBlur={onBlur}
      value={valueToDisplay}
    />
  );
};
