import type * as Types from '../../../../core/apollo/__generated__/usersGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetSiteUserOptionsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  organizationId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  featureType?: Types.InputMaybe<Types.SiteFeatureType>;
}>;

export type GetSiteUserOptionsQuery = {
  siteUserOptions: Array<{ id: string; name: string; email: string; organizationId: number }>;
};

export type GlobalUserOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GlobalUserOptionsQuery = { getUserOptions: Array<{ id: string; displayAs: string }> };

export const GetSiteUserOptionsDocument = gql`
  query GetSiteUserOptions($siteId: Int!, $organizationId: Int, $featureType: SiteFeatureType) {
    siteUserOptions(siteId: $siteId, organizationId: $organizationId, featureType: $featureType) {
      id
      name
      email
      organizationId
    }
  }
`;
export const GlobalUserOptionsDocument = gql`
  query GlobalUserOptions {
    getUserOptions {
      id
      displayAs
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetSiteUserOptions(
      variables: GetSiteUserOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSiteUserOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSiteUserOptionsQuery>(GetSiteUserOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSiteUserOptions',
        'query',
        variables
      );
    },
    GlobalUserOptions(
      variables?: GlobalUserOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GlobalUserOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GlobalUserOptionsQuery>(GlobalUserOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GlobalUserOptions',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
