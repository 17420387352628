import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type CreateSystemMutationVariables = Types.Exact<{
  createSystemInput: Types.CreateSystemInput;
}>;

export type CreateSystemMutation = { createSystem: number };

export type UpdateSystemMutationVariables = Types.Exact<{
  systemId: Types.Scalars['Int']['input'];
  updateSystemInput: Types.UpdateSystemInput;
}>;

export type UpdateSystemMutation = { updateSystem: boolean };

export type DeleteSystemMutationVariables = Types.Exact<{
  systemId: Types.Scalars['Int']['input'];
}>;

export type DeleteSystemMutation = { deleteSystem: boolean };

export type GetSystemQueryVariables = Types.Exact<{
  systemId: Types.Scalars['Int']['input'];
}>;

export type GetSystemQuery = {
  getSystemFullInfo: {
    id: number;
    siteId: number;
    organizationIds: Array<number>;
    meterId?: number | null;
    type: Types.SystemType;
    name: string;
    description?: string | null;
    floor: string;
    floorType: Types.FloorType;
    configurationUpdatedAt: Date;
    updatedAt: Date;
    extraFields: {
      groupId?: number | null;
      modelId?: number | null;
      occupancyType?: Types.OccupancyType | null;
      squareFeet?: number | null;
      submeterType?: string | null;
    };
    integrationDetails?: {
      appEui?: string | null;
      appKey?: string | null;
      deviceProfileId?: string | null;
      communicationId?: string | null;
      controlParams?: string | null;
      gatewayId?: number | null;
      gatewayVersion?: Types.GatewayVersion | null;
      installationDate?: Date | null;
      ipAddress?: string | null;
      networkIndex?: number | null;
      physicalLocation?: string | null;
      resetStationCode?: string | null;
      routeIPAddress?: string | null;
      subchannel?: number | null;
      subnetMask?: string | null;
      typeId: number;
      uuid: string;
      serialNumber?: string | null;
    } | null;
    sensors?: Array<{
      deviceId?: number | null;
      floor: string;
      floorType: Types.FloorType;
      id: number;
      line?: string | null;
      name: string;
      organizationId: number;
      siteId: number;
      status?: Types.SystemStatus | null;
      type: Types.SensorDeviceType;
      updatedAt: Date;
    }> | null;
  };
};

export type GetSystemsForConnectionOnSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
}>;

export type GetSystemsForConnectionOnSiteQuery = {
  systemsShortInfoBySiteId: Array<{
    id: number;
    name: string;
    type: Types.SystemType;
    floor: string;
    floorType: Types.FloorType;
    status: Types.SystemStatus;
    onlineStatus: Types.OnlineStatus;
  }>;
};

export type GetSystemOptionsForSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  organizationId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetSystemOptionsForSiteQuery = {
  systemOptionsForSite: Array<{
    id: number;
    name: string;
    type: Types.SystemType;
    floor: string;
    organizationIds: Array<number>;
  }>;
};

export const CreateSystemDocument = gql`
  mutation CreateSystem($createSystemInput: CreateSystemInput!) {
    createSystem(createSystemInput: $createSystemInput)
  }
`;
export const UpdateSystemDocument = gql`
  mutation UpdateSystem($systemId: Int!, $updateSystemInput: UpdateSystemInput!) {
    updateSystem(systemId: $systemId, updateSystemInput: $updateSystemInput)
  }
`;
export const DeleteSystemDocument = gql`
  mutation DeleteSystem($systemId: Int!) {
    deleteSystem(systemId: $systemId)
  }
`;
export const GetSystemDocument = gql`
  query GetSystem($systemId: Int!) {
    getSystemFullInfo(systemId: $systemId) {
      id
      siteId
      organizationIds
      meterId
      type
      name
      description
      floor
      floorType
      configurationUpdatedAt
      updatedAt
      extraFields {
        groupId
        modelId
        occupancyType
        squareFeet
        submeterType
      }
      integrationDetails {
        appEui
        appKey
        deviceProfileId
        communicationId
        controlParams
        gatewayId
        gatewayVersion
        installationDate
        ipAddress
        networkIndex
        physicalLocation
        resetStationCode
        routeIPAddress
        subchannel
        subnetMask
        typeId
        uuid
        serialNumber
      }
      sensors {
        deviceId
        floor
        floorType
        id
        line
        name
        organizationId
        siteId
        status
        type
        updatedAt
      }
    }
  }
`;
export const GetSystemsForConnectionOnSiteDocument = gql`
  query GetSystemsForConnectionOnSite($siteId: Int!) {
    systemsShortInfoBySiteId(siteId: $siteId) {
      id
      name
      type
      floor
      floorType
      status
      onlineStatus
    }
  }
`;
export const GetSystemOptionsForSiteDocument = gql`
  query GetSystemOptionsForSite($siteId: Int!, $organizationId: Int) {
    systemOptionsForSite(siteId: $siteId, organizationId: $organizationId) {
      id
      name
      type
      floor
      organizationIds
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateSystem(
      variables: CreateSystemMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateSystemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateSystemMutation>(CreateSystemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateSystem',
        'mutation',
        variables
      );
    },
    UpdateSystem(
      variables: UpdateSystemMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateSystemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateSystemMutation>(UpdateSystemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateSystem',
        'mutation',
        variables
      );
    },
    DeleteSystem(
      variables: DeleteSystemMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteSystemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteSystemMutation>(DeleteSystemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteSystem',
        'mutation',
        variables
      );
    },
    GetSystem(
      variables: GetSystemQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSystemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSystemQuery>(GetSystemDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetSystem',
        'query',
        variables
      );
    },
    GetSystemsForConnectionOnSite(
      variables: GetSystemsForConnectionOnSiteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSystemsForConnectionOnSiteQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSystemsForConnectionOnSiteQuery>(GetSystemsForConnectionOnSiteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSystemsForConnectionOnSite',
        'query',
        variables
      );
    },
    GetSystemOptionsForSite(
      variables: GetSystemOptionsForSiteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSystemOptionsForSiteQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSystemOptionsForSiteQuery>(GetSystemOptionsForSiteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSystemOptionsForSite',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
