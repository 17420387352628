import { ONE_SECOND_MS } from 'src/constants';
import Logger from 'src/core/service/logger';
import { TimeIntervalInMs } from 'src/enums';

// TODO: rewrite to setTimeout @see https://javascript.info/settimeout-setinterval

/**
 * Simple wrapper around single timer to make it controllable
 */
export class ManagedInterval {
  private intervalId?: number;
  private lastTimeStamp = new Date();

  get isRunning(): boolean {
    return !!this.intervalId;
  }

  constructor(
    private triggerInterval: TimeIntervalInMs,
    private callback: VoidFunction
  ) {}

  private shouldTriggerCallback(lastTriggerTime: Date, currentTime: Date): boolean {
    switch (this.triggerInterval) {
      case TimeIntervalInMs.ONE_SECOND:
        return true;
      case TimeIntervalInMs.ONE_MINUTE:
        return lastTriggerTime.getMinutes() !== currentTime.getMinutes();
      default:
        return false;
    }
  }

  start(triggerOnStart?: boolean): void {
    if (this.isRunning) {
      throw `Interval for each ${this.triggerInterval} ms is already running, no need to start it`;
    }

    this.lastTimeStamp = new Date();

    const run = () => {
      const current = new Date();
      if (this.shouldTriggerCallback(this.lastTimeStamp, current)) {
        this.lastTimeStamp = current;
        this.callback();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.intervalId = setTimeout(run, ONE_SECOND_MS) as any as number;
    };

    // TODO: find how to fix typings
    //
    // Need to make type conversion, because TS defines that setInterval
    // returns `NodeJS.Timeout` which is not right because React is running
    // in Browser and not Node env
    //
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.intervalId = setTimeout(run, ONE_SECOND_MS) as any as number;

    if (triggerOnStart) {
      this.callback();
    }
  }

  stop(): void {
    if (!this.isRunning) {
      Logger.info(`Interval for each ${this.triggerInterval} ms is already stopped, no need to stop it`);
    }

    clearTimeout(this.intervalId);
    this.intervalId = undefined;
  }

  restart(): void {
    this.stop();
    this.start();
  }
}
