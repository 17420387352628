import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  GetSiteDetailsQuery,
  GetSiteDetailsQueryVariables,
  GetSystemGroupQuery,
  GetSystemGroupQueryVariables,
  GetSystemPageDetailsQuery,
  GetSystemPageDetailsQueryVariables,
} from './__generated__/getSystemPageDetails.resources.gql';
import { SYSTEM_UNION_FIELDS } from './fragments.resources.gql';
import { SystemGroups } from './getSystemsForSite.resources.gql';

export type SystemDetails =
  | Extract<GetSystemPageDetailsQuery['system'], { __typename: 'SystemSteamPrvStation' }>
  | Extract<GetSystemPageDetailsQuery['system'], { __typename: 'SystemHwBoilerTekmar248' }>
  | Extract<GetSystemPageDetailsQuery['system'], { __typename: 'SystemStBoilerFHtMpc' }>
  | Extract<GetSystemPageDetailsQuery['system'], { __typename: 'SystemHwBoilerHtHwr' }>
  | Extract<GetSystemPageDetailsQuery['system'], { __typename: 'SystemPackageAltc24Prog' }>
  | Extract<GetSystemPageDetailsQuery['system'], { __typename: 'SystemLightingVive' }>
  | Extract<GetSystemPageDetailsQuery['system'], { __typename: 'SystemWeatherStationWts506' }>;
export type SiteDetails = GetSiteDetailsQuery['site'];

const GET_SYSTEM_PAGE_DETAILS = gql`
  ${SYSTEM_UNION_FIELDS}
  query GetSystemPageDetails($id: Int!) {
    system(id: $id) {
      __typename
      ...SystemUnionFields
    }
  }
`;

const GET_SITE_DETAILS = gql`
  query GetSiteDetails($id: Int!) {
    site(id: $id) {
      name
      streetNumber
      streetName
      city
      state
      timezone
    }
  }
`;

const GET_SYSTEM_GROUP = gql`
  query GetSystemGroup($id: Int!) {
    systemGroup(id: $id) {
      id
      siteId
      name
      type
    }
  }
`;

export async function getSiteDetailsForSystemPage(siteId: number): Promise<SiteDetails> {
  const siteDetails = await resourcesAPI.query<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>({
    query: GET_SITE_DETAILS,
    variables: {
      id: siteId,
    },
  });

  return siteDetails.data.site;
}

export async function getSystemPageDetails(id: number): Promise<SystemDetails> {
  const systemDetails = await resourcesAPI.query<GetSystemPageDetailsQuery, GetSystemPageDetailsQueryVariables>({
    query: GET_SYSTEM_PAGE_DETAILS,
    variables: {
      id,
    },
  });

  return systemDetails.data.system as SystemDetails;
}

export async function getSystemGroup(id: number): Promise<SystemGroups> {
  const result = await resourcesAPI.query<GetSystemGroupQuery, GetSystemGroupQueryVariables>({
    query: GET_SYSTEM_GROUP,
    variables: {
      id,
    },
  });

  return result.data.systemGroup;
}
