import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { PersistedFilterConfiguration } from 'src/core/store/global/gql/getCurrentUserConstraints.users.gql';
import type { RootState } from 'src/core/store/store';

import { isMultiSelectField } from './components/fields/FilterFieldMultiSelect';
import { isSelectField } from './components/fields/FilterFieldSelect';
import { ConfigAndFilter, FilterConfiguration, FilterValues, IFilterField } from './filter.interface';

// TODO: move pagination persistence to filter slice

/**
 * Definitions:
 * Filter - is an object that contains values for each filter field.
 * Filter Configuration - is a filter persisted in the database.
 * Field - is a configuration/setup/settings for filter field.
 */
interface IFiltersSlice {
  isOpen: boolean;

  /**
   * Key is a storage key for the page
   * @see FilterStorageKey @link src/enums.ts
   *
   * Saved filters are stored in the following format:
   * @example
   * ```
   * {
   *  "storageKey": [
   *   {
   *    "name": "filterName",
   *    "values": {
   *     "filterField": "filterValue"
   *    }
   *   }
   *  ]
   * }
   * ```
   */
  filterConfigurationsPerPage: Record<string, FilterConfiguration[]>;

  /**
   * Configuration for filters for current page.
   */
  fields: IFilterField[];

  /**
   * Key is page name, value is a optional config name and filter object for that page.
   * It is used to persist filters when user navigates between pages.
   */
  filtersPerPage: Record<string, ConfigAndFilter>;
}

const getInitialState = (): IFiltersSlice => ({
  isOpen: false,
  filtersPerPage: {},
  fields: [],
  filterConfigurationsPerPage: {},
});

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: getInitialState(),
  reducers: {
    resetSlice: () => getInitialState(),
    /**
     * Used to reset current fields when user navigates between pages.
     * So user will not see previous page filters on the new page.
     */
    resetFilterFields: (state) => {
      state.fields = [];
    },
    setFilterFields: (state, action: PayloadAction<IFilterField[]>) => {
      state.fields = action.payload;
    },
    setFilters: (
      state,
      action: PayloadAction<{ storageKey: string; filterConfigId?: string; filters: FilterValues }>
    ) => {
      // TODO: save filters to the server
      // TODO: add configurations support for page
      state.filtersPerPage[action.payload.storageKey] = {
        filterConfigId: action.payload.filterConfigId,
        filters: action.payload.filters,
      };
    },
    openFiltersSidebar: (state) => {
      state.isOpen = true;
    },
    closeFiltersSidebar: (state) => {
      state.isOpen = false;
    },
    setFilterConfigurationsForAllPages: (state, action: PayloadAction<PersistedFilterConfiguration[]>) => {
      state.filterConfigurationsPerPage = _.groupBy(
        action.payload.map(
          (i) =>
            ({
              key: i.id,
              displayValue: i.name,
              config: {
                filters: i.filters,
                storageKey: i.key,
              },
            }) as FilterConfiguration
        ),
        (i) => i.config?.storageKey
      );
    },
    addFilterConfiguration: (state, action: PayloadAction<FilterConfiguration>) => {
      state.filterConfigurationsPerPage[action.payload.config.storageKey] = _.orderBy(
        [...(state.filterConfigurationsPerPage[action.payload.config.storageKey] ?? []), action.payload],
        'displayValue'
      );
    },
    updateFilterConfiguration: (state, action: PayloadAction<FilterConfiguration>) => {
      state.filterConfigurationsPerPage[action.payload.config.storageKey] = _.orderBy(
        [
          ...(state.filterConfigurationsPerPage[action.payload.config.storageKey] ?? []).filter(
            (i) => i.key !== action.payload.key
          ),
          action.payload,
        ],
        'displayValue'
      );
    },
    removeFilterConfiguration: (state, action: PayloadAction<FilterConfiguration>) => {
      state.filterConfigurationsPerPage[action.payload.config.storageKey] = [
        ...(state.filterConfigurationsPerPage[action.payload.config.storageKey] ?? []).filter(
          (i) => i.key !== action.payload.key
        ),
      ];
    },
  },
});

export const {
  resetSlice: resetFiltersSlice,
  setFilters,
  resetFilterFields,
  setFilterFields,
  openFiltersSidebar,
  closeFiltersSidebar,
  setFilterConfigurationsForAllPages,
  addFilterConfiguration,
  updateFilterConfiguration,
  removeFilterConfiguration,
} = filtersSlice.actions;

export const selectFilterConfigurationsForPage =
  (storageKey: string) =>
  (state: RootState): FilterConfiguration[] =>
    state.filters.filterConfigurationsPerPage[storageKey] ?? [];

export const selectFiltersForPage =
  <T extends FilterValues>(storageKey: string) =>
  (state: RootState): T =>
    (state.filters.filtersPerPage[storageKey]?.filters ?? {}) as T;

export const selectFilterAndConfigIdForPage =
  (storageKey: string) =>
  (state: RootState): ConfigAndFilter =>
    state.filters.filtersPerPage[storageKey] ?? {
      filters: {},
    };

export const selectFilterFields = (state: RootState): IFilterField[] => state.filters.fields;

export const selectFilterField =
  <T extends FilterValues, C extends IFilterField>(fieldName: keyof T) =>
  (state: RootState): C | undefined =>
    state.filters.fields.find((field) => field.name === fieldName) as C | undefined;

export const selectIsOpenSidebar = (state: RootState): boolean => state.filters.isOpen;

export const selectDisplayValuesForCurrentPage =
  (storageKey: string, scope?: string) =>
  (state: RootState): { label: string; values: string[] }[] => {
    const filters = selectFiltersForPage(storageKey)(state);
    const fields = state.filters.fields;

    return fields
      .filter((field) => (scope && field.scope ? field.scope.includes(scope) : true))
      .map((field) => {
        const value = filters[field.name];
        const values = value ? (Array.isArray(value) ? value : [value]) : [];

        const valuesToRender =
          isSelectField(field) || isMultiSelectField(field)
            ? values.map((v) => {
                const option = field.options.find((o) => _.isEqual(o.key, v));
                return option?.displayValue ?? v;
              })
            : values;

        return {
          label: field.label,
          values: valuesToRender,
        };
      })
      .filter((item) => !_.isEmpty(item.values));
  };

export default filtersSlice.reducer;
