import classNames from 'classnames';

import { FanType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { packageFanDisplayValue } from 'src/enums';
import { OptionItem, Select } from 'src/shared/components/Select';

import styles from './PackageFanSelector.module.scss';

export interface PackageFanSelectorProps {
  systemType: SystemType;
  disabled: boolean;
  fan: FanType;
  hideLabel?: boolean;
  labelUp?: boolean;
  onChange: (value: FanType) => void;
  fanLevels: number;
}

const PackageSystemFanValues: Record<string, FanType[]> = {
  [SystemType.PACKAGE_ALTC24PROG]: [FanType.LOW, FanType.MEDIUM, FanType.HIGH, FanType.AUTO],
  [SystemType.PACKAGE_HONEYWELL_TC500AN]: [FanType.CONTINUOUS, FanType.FAN_CIRCULATE, FanType.AUTO],
};

export function buildPackageFanOptions(systemType: SystemType, fanLevels: number): OptionItem<FanType>[] {
  return PackageSystemFanValues[systemType].map((fan) => ({
    key: fan,
    displayValue: packageFanDisplayValue[fan],
    hidden: systemType === SystemType.PACKAGE_ALTC24PROG && fanLevels === 1 && fan !== FanType.LOW,
  }));
}

const PackageFanSelector: React.FC<PackageFanSelectorProps> = (props) => {
  const options: OptionItem<FanType>[] = buildPackageFanOptions(props.systemType, props.fanLevels);

  return (
    <div className={classNames(styles['package-fan-wrapper'])}>
      <Select
        label={props.hideLabel ? undefined : 'Fan'}
        labelPosition={props.hideLabel ? undefined : props.labelUp ? 'up' : 'inside'}
        disabled={props.disabled}
        options={options}
        value={props.fan}
        onClick={props.onChange}
      />
    </div>
  );
};

export default PackageFanSelector;
