import classNames from 'classnames';

import { ModeType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { packageModeDisplayValue } from 'src/enums';
import { OptionItem, Select } from 'src/shared/components/Select';

import styles from './PackageModeSelector.module.scss';

export interface PackageModeSelectorProps {
  systemType: SystemType;
  disabled: boolean;
  mode: ModeType;
  modeEffective: ModeType;
  hideLabel?: boolean;
  labelUp?: boolean;
  onChange: (value: ModeType) => void | Promise<void>;
  coolingStages: number;
  heatingStages: number;
}

export const PackageSystemModeValues: Record<string, ModeType[]> = {
  [SystemType.PACKAGE_ALTC24PROG]: [ModeType.FAN_ONLY, ModeType.COOL, ModeType.HEAT, ModeType.AUTO, ModeType.OFF],
  [SystemType.PACKAGE_HONEYWELL_TC500AN]: [
    ModeType.FAN_ONLY,
    ModeType.COOL,
    ModeType.HEAT,
    ModeType.AUTO,
    ModeType.OFF,
  ],
};

export function buildPackageModeOptions(
  systemType: SystemType,
  coolingStages: number,
  heatingStages: number
): OptionItem<ModeType>[] {
  return PackageSystemModeValues[systemType].map((mode) => ({
    key: mode,
    displayValue: packageModeDisplayValue[mode],
    hidden: (!coolingStages && mode === ModeType.COOL) || (!heatingStages && mode === ModeType.HEAT),
  }));
}

const PackageModeSelector: React.FC<PackageModeSelectorProps> = (props) => {
  const options = buildPackageModeOptions(props.systemType, props.coolingStages, props.heatingStages);
  const mode = props.modeEffective === ModeType.OFF ? props.modeEffective : props.mode;

  return (
    <div className={classNames(styles['package-mode-wrapper'])}>
      <Select
        label={props.hideLabel ? undefined : 'Mode'}
        labelPosition={props.hideLabel ? undefined : props.labelUp ? 'up' : 'inside'}
        disabled={props.disabled}
        options={options}
        value={mode}
        onClick={props.onChange}
      />
    </div>
  );
};

export default PackageModeSelector;
