import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  ChangeSystemSetpointMutation,
  ChangeSystemSetpointMutationVariables,
} from './__generated__/changeSystemSetpoint.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterUpdatingSetpoint =
  | Extract<ChangeSystemSetpointMutation['updateSetpoint'], { __typename: 'SystemSteamPrvStation' }>
  | Extract<ChangeSystemSetpointMutation['updateSetpoint'], { __typename: 'SystemHwBoilerTekmar248' }>
  | Extract<ChangeSystemSetpointMutation['updateSetpoint'], { __typename: 'SystemStBoilerFHtMpc' }>
  | Extract<ChangeSystemSetpointMutation['updateSetpoint'], { __typename: 'SystemHwBoilerHtHwr' }>
  | Extract<ChangeSystemSetpointMutation['updateSetpoint'], { __typename: 'SystemPackageAltc24Prog' }>
  | Extract<ChangeSystemSetpointMutation['updateSetpoint'], { __typename: 'SystemLightingVive' }>;

const CHANGE_SYSTEM_SETPOINT = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangeSystemSetpoint($systemId: Int!, $setpointValue: Int!) {
    updateSetpoint(setpoint: $setpointValue, systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changeSystemSetpoint(
  systemId: number,
  setpoint: number
): Promise<DataAfterUpdatingSetpoint | undefined> {
  const result = await resourcesAPI.mutate<ChangeSystemSetpointMutation, ChangeSystemSetpointMutationVariables>({
    mutation: CHANGE_SYSTEM_SETPOINT,
    variables: { systemId, setpointValue: setpoint },
  });

  const updateResult = result.data?.updateSetpoint as DataAfterUpdatingSetpoint;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
