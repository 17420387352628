import classnames from 'classnames';
import { lowerCase, upperFirst } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { UNIT } from 'src/constants';
import { OnlineStatus, SystemStatus } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import Logger from 'src/core/service/logger';
import { selectUserRole } from 'src/core/store/global/globalSlice';
import { useAppDispatch } from 'src/core/store/hooks';
import { SystemSequence } from 'src/enums';
import { NumericControls } from 'src/shared/components/NumericControls/NumericControls';
import SystemAlertTag, { hasAlert } from 'src/shared/components/Tag/SystemAlertTag';
import { WithUnit } from 'src/shared/components/Unit/WithUnit';
import GaugeChart from 'src/shared/components/charts/GaugeChart';
import StripedChart from 'src/shared/components/charts/ProgressBarChart/StripedChart';
import {
  CardLayout,
  UniversalAdditionalBlock,
  UniversalCard,
  UniversalChart,
  UniversalDataList,
  UniversalHeader,
  UniversalMainProperty,
  UniversalSetpoint,
} from 'src/shared/containers/UniversalCard/UniversalCard';

import OverrideScheduleProfileForSystemModal from '../../../scheduler/modal/OverrideScheduleProfileForSystemModal/OverrideScheduleProfileForSystemModal';
import OATResetSequenceIndicator from '../../components/OATResetSequenceIndicator/OATResetSequenceIndicator';
import GenericSystemHeader from '../../components/containers/GenericSystemHeader/GenericSystemHeader';
import LayoutWithSettingsAndAlarm from '../../components/containers/LayoutWithSettingsAndAlarm/LayoutWithSettingsAndAlarm';
import OATResetModal from '../../components/containers/OATResetModal/OATResetModal';
import RemoteSequenceIndicator from '../../components/containers/RemoteSequenceIndicator/RemoteSequenceIndicator';
import SequenceSelector from '../../components/containers/SequenceSelector/SequenceSelector';
import { HwBoilerHtHwrSystemCard } from '../../gql/getSystemsForSite.resources.gql';
import {
  changeOatResetRangeForSingleSystem,
  changeSequenceForSingleSystem,
  changeSetpointForSingleSystem,
} from '../../systemsActions';
import { selectedSystemById, updateSystemSetpoint } from '../../systemsSlice';
import { isDRTypeSequence } from '../../utils/system.utils';

import styles from './HwBoilerHtHwrCard.module.scss';

export interface HwBoilerHtHwrCardProps {
  systemId: number;
  layout: CardLayout;
  timezone?: string | null;
}

const HwBoilerHtHwrCard: React.FC<HwBoilerHtHwrCardProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentUserRole = useSelector(selectUserRole);
  const system = useSelector(selectedSystemById<HwBoilerHtHwrSystemCard>(props.systemId));
  const [isOatResetModalOpen, setOatResetModalOpen] = useState(false);

  if (!system) {
    return <p>Cannot find system with id {props.systemId}</p>;
  }

  const disabled =
    system.status === SystemStatus.OFF ||
    system.sequence.id === SystemSequence.Local ||
    system.onlineStatus === OnlineStatus.OFFLINE ||
    system.onlineStatus === OnlineStatus.NOT_CONFIGURED ||
    currentUserRole === UserRoles.MANAGER;

  const systemAutoControl = isDRTypeSequence(system.sequence.id) || system.schedulerProfile?.underSchedulerControl;

  const disabledSettings = disabled || system.sequence.id === SystemSequence.Manual;
  const disabledControl = disabled || systemAutoControl || system.sequence.id === SystemSequence.OATReset;

  const hideSettings = false;
  // Alarms are supported by PRV systems
  const hideAlarm = false;

  function updateSetpoint(value: number): void {
    dispatch(updateSystemSetpoint([props.systemId, value]));
  }

  function updateSetpointOnRemote(value: number): Promise<void> {
    return dispatch(changeSetpointForSingleSystem(props.systemId, value));
  }

  function openSettings(): void {
    setOatResetModalOpen(true);
  }

  function changeSequence(value: SystemSequence): Promise<void> {
    return dispatch(changeSequenceForSingleSystem(props.systemId, value));
  }

  const oatResetModal = (
    <OATResetModal
      open={isOatResetModalOpen}
      title={system.name}
      subtitleText='Boilers OAT reset sequence'
      acceptAction={(newConfig) => {
        dispatch(changeOatResetRangeForSingleSystem(system.id, newConfig))
          .catch((e) => Logger.error(e.message))
          .finally(() => setOatResetModalOpen(false));
      }}
      cancelAction={() => setOatResetModalOpen(false)}
      defaultOatTemperature={[system.oatRangeLow, system.oatRangeHigh]}
      defaultSetpoint={[system.setpointMin, system.setpointMax]}
      oatTemperatureLimits={[0, 65]}
      setpointLimits={[110, 180]}
      oatTemperatureLabel={`Outside Temperature (${UNIT.FAHRENHEIT})`}
      setpointLabel={`Supply Temperature (${UNIT.FAHRENHEIT})`}
      disabledSettings={systemAutoControl}
    />
  );

  const layout = props.layout;

  return (
    <UniversalCard layout={layout}>
      <UniversalHeader assumeHeader>
        <GenericSystemHeader
          systemId={props.systemId}
          name={system.name}
          floor={system.floor}
          floorType={system.floor_type}
          status={system.status}
          lastStatusChangedDatetime={system.statusUpdatedAt}
          onlineStatus={system.onlineStatus}
          timezone={props.timezone}
        />
      </UniversalHeader>
      <UniversalMainProperty horizontalLabel='Sequence' moveToHeaderWhenVertical>
        <SequenceSelector
          hideLabel
          disabled={disabled}
          sequenceId={system.sequence.id}
          systemType={system.type}
          schedulerProfile={system.schedulerProfile}
          onChange={changeSequence}
          layout={layout}
        >
          <OverrideScheduleProfileForSystemModal
            systemId={system.id}
            isScheduled={systemAutoControl}
            isOverridden={!!system.overriddenSchedulerProfile}
          />
        </SequenceSelector>
      </UniversalMainProperty>
      {hasAlert(system) && (
        <UniversalMainProperty order={-1} moveToHeaderWhenVertical hideWhenList hideWhenHorizontal>
          <SystemAlertTag systemId={system.id} onlineStatus={system.onlineStatus} />
        </UniversalMainProperty>
      )}
      <UniversalChart moveToLeftWhenHorizontal>
        <LayoutWithSettingsAndAlarm
          siteId={system.siteId}
          disabledSettings={disabledSettings}
          hideAlarm={hideAlarm}
          hideSettings={hideSettings}
          onSettingsClick={openSettings}
          layout={layout}
          systemId={system.id}
        >
          {layout === CardLayout.list && (
            <StripedChart
              min={system.setpointMin}
              max={system.setpointMax}
              targetValue={system.setpoint}
              currentValue={system.supplyTemperature}
              unit={UNIT.FAHRENHEIT}
              disabled={disabled}
            />
          )}
          {layout !== CardLayout.list && (
            <GaugeChart
              min={system.setpointMin}
              max={system.setpointMax}
              targetValue={system.setpoint}
              currentValue={system.supplyTemperature}
              amountOfSteps={system.setpointMax - system.setpointMin}
              disabled={disabled}
            >
              <>
                <p className={styles['supply-temperature']}>
                  <span className={classnames(styles['value'], 'body-semi-bold')}>{system.supplyTemperature}</span>
                  <span className={styles['metric']}> {UNIT.FAHRENHEIT}</span>
                </p>
                <p className='text-center body-small color-secondary'>
                  Supply
                  <br />
                  Temperature
                </p>
              </>
            </GaugeChart>
          )}
          {oatResetModal}
        </LayoutWithSettingsAndAlarm>
      </UniversalChart>
      <UniversalMainProperty horizontalLabel={`Setpoint (${UNIT.FAHRENHEIT})`}>
        <UniversalSetpoint verticalLabel={`Setpoint (${UNIT.FAHRENHEIT})`}>
          <NumericControls
            min={system.setpointMin}
            max={system.setpointMax}
            disabled={disabledControl}
            onChange={updateSetpoint}
            onSubmit={updateSetpointOnRemote}
            value={system.setpoint}
          />
        </UniversalSetpoint>
      </UniversalMainProperty>
      <UniversalAdditionalBlock moveToLeftWhenHorizontal>
        <div>
          {system.sequence.id !== SystemSequence.OATReset && (
            <RemoteSequenceIndicator
              oatTemperature={system.oat}
              averageSpaceTemperature={system.averageSpaceTemperature}
            />
          )}
          {system.sequence.id === SystemSequence.OATReset && (
            <OATResetSequenceIndicator
              oatTemperature={system.oat}
              oatRangeLow={system.oatRangeLow}
              oatRangeHigh={system.oatRangeHigh}
              setpointMin={system.setpointMin}
              setpointMax={system.setpointMax}
              setpointUnit={UNIT.FAHRENHEIT}
              cardView={layout}
            />
          )}
        </div>
      </UniversalAdditionalBlock>
      <UniversalDataList>
        <dt>Outside Air Temp.</dt>
        <dd>
          <WithUnit fahrenheit value={system.oat} />
        </dd>

        <dt>Space Temp.</dt>
        <dd>
          <WithUnit fahrenheit value={system.averageSpaceTemperature} />
        </dd>

        <dt>Mode</dt>
        <dd>{upperFirst(lowerCase(system.mode))}</dd>
      </UniversalDataList>
    </UniversalCard>
  );
};

export default HwBoilerHtHwrCard;
