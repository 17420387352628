import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { logout } from '../../service/appLifecycle';
import authService from '../../service/authService';
import Logger from '../../service/logger';

import { getErrorMessage, isUnauthorized } from './errorHandler';

/**
 * Add JWT to Apollo context, it is needed because extracting of JWT is an async task
 */
export const withToken = setContext(async () => {
  const token = await authService.getJWT();
  return { token };
});

/**
 * Add JWT to request as authorization header
 */
export const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();
  operation.setContext(() => ({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  }));
  return forward(operation);
});

export const errorHandlingMiddleware = onError(({ networkError, graphQLErrors }) => {
  console.error({ networkError, graphQLErrors });

  const shouldLogout = isUnauthorized(networkError, graphQLErrors);

  if (shouldLogout) {
    logout();
    return;
  }

  const message = getErrorMessage(networkError, graphQLErrors);

  if (message) {
    Logger.error(message);
  } else {
    console.log('Unknown error, silently ignoring');
  }
});
