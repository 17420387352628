import { Action, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { SiteFeatureType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { UtilityServiceTypes } from 'src/core/apollo/__generated__/utilityGlobalTypes';
import { persistentStorage } from 'src/core/service/persistentStorage';
import { selectAvailableSites } from 'src/core/store/global/globalSlice';
import type { RootState } from 'src/core/store/store';
import { LoadingStatus, PersistentStorageKeys } from 'src/enums';
import { ISite } from 'src/interfaces';

import { SiteDetails } from '../systems/gql/getSystemPageDetails.resources.gql';
import { selectSystemIdsForSite } from '../systems/systemsSlice';

const sitesAdapter = createEntityAdapter<ISite>({
  sortComparer: (a, b) => {
    if (a.order === b.order) {
      return 0;
    }
    return a.order > b.order ? 1 : -1;
  },
});

interface SliceState {
  data: EntityState<ISite>;
  status: LoadingStatus;
}

export const sitesSlice = createSlice({
  name: 'sites',
  initialState: <SliceState>{
    data: sitesAdapter.getInitialState(),
    status: LoadingStatus.WAITING,
  },
  reducers: {
    resetSlice: () =>
      <SliceState>{
        data: sitesAdapter.getInitialState(),
        status: LoadingStatus.WAITING,
      },
    updateSites: (state, action: PayloadAction<ISite[]>) => {
      // Even if there were multiples site-update changes - it will affect only selected sites
      sitesAdapter.updateMany(
        state.data,
        action.payload.map((site) => ({
          id: site.id,
          changes: site,
        }))
      );
    },
    setSites: (state, action: PayloadAction<ISite[]>) => {
      sitesAdapter.setMany(state.data, action.payload);
      // Set status only once to inform app that it is not waiting anymore
      state.status = LoadingStatus.LOADED;
    },
    removeSites: (state, action: PayloadAction<ISite[]>) => {
      const ids = action.payload.map(({ id }) => id);
      sitesAdapter.removeMany(state.data, ids);
    },
    toggleAllSite: (state, action: PayloadAction<ISite[]>) => {
      const allSites = action.payload;
      const allSelected = sitesAdapter.getSelectors().selectAll(state.data).length === allSites.length;

      if (allSelected) {
        sitesAdapter.removeAll(state.data);
      } else {
        sitesAdapter.setAll(state.data, allSites);
      }
    },
    toggleSite: (state, action: PayloadAction<ISite>) => {
      const hasItem = !!sitesAdapter.getSelectors().selectById(state.data, action.payload.id);

      if (hasItem) {
        sitesAdapter.removeOne(state.data, action.payload.id);
      } else {
        sitesAdapter.addOne(state.data, action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher<Action<string>>(
      (action) => {
        return (
          action.type === 'sites/setSites' ||
          action.type === 'sites/removeSites' ||
          action.type === 'sites/toggleSite' ||
          action.type === 'sites/toggleAllSite'
        );
      },
      (state) => {
        const ids = sitesAdapter.getSelectors().selectIds(state.data);
        persistentStorage.setArray(PersistentStorageKeys.SelectedSites, ids as number[]);
      }
    );
  },
});

export const {
  resetSlice: resetSitesSlice,
  setSites,
  toggleSite,
  toggleAllSite,
  removeSites,
  updateSites,
} = sitesSlice.actions;

const selectors = sitesAdapter.getSelectors<RootState>((state) => state.sites.data);

export const selectIsLoadingSites = (state: RootState): boolean =>
  state.sites.status === LoadingStatus.INITIAL || state.sites.status === LoadingStatus.WAITING;

export const selectedSites = (state: RootState): ISite[] => selectors.selectAll(state) as ISite[];

export const selectSiteById =
  (id?: number) =>
  (state: RootState): ISite | undefined =>
    id ? selectors.selectById(state, id) : undefined;

/**
 * If no feature was provided it will always return true
 * If no site was provided it will check if any site have given feature
 */
export const selectSiteHasFeature = (siteId?: number, feature?: SiteFeatureType) =>
  !feature
    ? () => true
    : _.isNil(siteId)
      ? (state: RootState): boolean => {
          const sites = selectors.selectAll(state);
          // If no sites exists in storage - allow access
          if (!sites.length) {
            return true;
          }
          return sites.some((i) => i.features[feature] || false);
        }
      : (state: RootState): boolean => selectors.selectById(state, siteId)?.features[feature] || false;

export const selectedSitesIds = (state: RootState): number[] => selectors.selectIds(state) as number[];

export const selectedSiteIdIfSingle = (state: RootState): number | undefined => {
  const ids = selectors.selectIds(state) as number[];
  if (ids.length === 1) {
    return ids[0];
  }
};

export const selectedSitesWithAmountOfSystems = (state: RootState): ISite[] => {
  const sites = selectors.selectAll(state).map((site) => {
    const systems = selectSystemIdsForSite(site.id)(state);

    return { ...site, amountOfSystems: systems.length };
  });

  // Move site without systems to the end, but do not order all sites by amount of systems
  return _.orderBy(sites, (a) => (a.amountOfSystems === 0 ? 1 : 0));
};

export const selectSiteIdsWithFeature =
  (...features: SiteFeatureType[]) =>
  (state: RootState): number[] => {
    return selectors
      .selectAll(state)
      .filter((i) => features.some((feature) => i.features[feature]))
      .map((i) => i.id);
  };

export const selectSiteIdsWithFeatureAndUtility =
  (feature: SiteFeatureType, utility: UtilityServiceTypes) =>
  (state: RootState): number[] => {
    return selectors
      .selectAll(state)
      .filter((i) => i.features[feature] && i.utilityServiceTypes?.includes(utility))
      .map((i) => i.id);
  };

export const selectSiteIdsWithSystemType =
  (type: SystemType) =>
  (state: RootState): number[] => {
    return selectors
      .selectAll(state)
      .filter((i) => i.availableSystemByTypes?.includes(type))
      .map((i) => i.id);
  };

export const selectSiteIdsFromSliceOrStorage = (state: RootState): number[] => {
  // TODO: extract to action, because in future selected systems will be stored on server
  // and we cannot extract data from server as a part of selector
  const ids = selectors.selectIds(state);
  if (!_.isEmpty(ids)) {
    return ids as number[];
  }

  const availableSiteIds = selectAvailableSites(state);

  const siteIdsFromStorage = persistentStorage
    .getArray(PersistentStorageKeys.SelectedSites)
    .filter((siteId) => availableSiteIds.includes(siteId));

  return siteIdsFromStorage;
};

export const selectAvailableSystemForSites = (state: RootState): (SystemType | null | undefined)[] => {
  const selectedSites = selectSiteIdsFromSliceOrStorage(state);

  return _.uniq(
    selectors
      .selectAll(state)
      .filter((site) => selectedSites.includes(site.id))
      .flatMap(({ availableSystemByTypes }) => availableSystemByTypes) ?? []
  );
};

/**
 * Returns list of uniq features across all selected sites
 */
export const selectUniqFeaturesForSites = (state: RootState): SiteFeatureType[] => {
  const selectedSites = selectors.selectAll(state);

  return _.uniq(
    selectedSites.flatMap((site) =>
      _.toPairs(site.features)
        .filter(([, value]) => value)
        .map(([key]) => key as SiteFeatureType)
    )
  );
};

/**
 * Returns list of uniq utility service types across all selected sites
 */
export const selectUniqUtilityServiceTypesForSites = (state: RootState): UtilityServiceTypes[] => {
  const selectedSites = selectors.selectAll(state);

  return _.uniq(selectedSites.flatMap((site) => site.utilityServiceTypes).filter((i) => i)) as UtilityServiceTypes[];
};

/**
 * If sites are still loading - that does not mean that there are no sites selected
 */
export const hasNoSelectedSites = (state: RootState): boolean =>
  state.sites.status !== LoadingStatus.WAITING && selectors.selectTotal(state) === 0;

export const selectTimezoneForSiteId =
  (id?: number) =>
  (state: RootState): string | undefined | null =>
    id ? selectors.selectById(state, id)?.timezone : undefined;

export const selectSiteDetailsForSiteId =
  (id: number) =>
  (state: RootState): SiteDetails | undefined => {
    const site = selectors.selectById(state, id);
    if (site) {
      const [city, state] = site.regionWithState.split(', ') as [string, string];
      const siteDetails: SiteDetails = {
        name: site.nameOrAddess,
        streetName: site.streetName,
        streetNumber: site.streetNumber,
        city,
        state,
        timezone: site.timezone,
      };
      return siteDetails;
    }
  };

export default sitesSlice.reducer;
