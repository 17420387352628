import classNames from 'classnames';
import _ from 'lodash';

import { N_A } from 'src/constants';
import { useNextSchedule } from 'src/logic/systems/useNextSchedule';
import OverrideScheduleProfileForSystemModal from 'src/modules/scheduler/modal/OverrideScheduleProfileForSystemModal/OverrideScheduleProfileForSystemModal';
import { AnySystem } from 'src/modules/systems/interface';
import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';
import { InfoTooltip } from 'src/shared/components/InfoTooltip/InfoTooltip';
import { SkeletonLoader } from 'src/shared/components/Loader/Loader';

import styles from './NextSystemScheduleDescription.module.scss';

interface Props {
  systemId: number;
  timezone?: string | null;
  system?: AnySystem;
}

const NextSystemSchedule: React.FC<Props> = (props) => {
  const schedule = useNextSchedule(props.systemId, props.system, props.timezone);

  const cardClass = 'card flat el-08 px-16 py-8';

  if (schedule.isLoading) {
    return (
      <div className={cardClass}>
        <div className={classNames('d-flex justify-content-space-between align-items-center mb-12')}>
          <SkeletonLoader type='text-50' />
          <SkeletonLoader rounded width='36px' height='36px' />
        </div>
        <SkeletonLoader type='text-100' />
      </div>
    );
  }

  if (schedule.isFailed) {
    return (
      <div className={cardClass}>
        <ElevatedBox title='Failed to define next schedule configuration' />
      </div>
    );
  }

  return (
    <div className='d-flex flex-col gap-8'>
      <div
        className={classNames(styles['system-card'], cardClass, {
          'd-flex flex-row align-items-center': schedule.settingsDiff.length === 0,
        })}
      >
        <div className={classNames(styles['system-card-title'], 'd-flex justify-content-space-between')}>
          <p className={classNames(styles['system-schedule-info'], 'd-flex align-items-center')}>
            <b className='color-secondary'>Current Schedule:&nbsp;</b>
            <span
              className={classNames({
                [styles['info-caption']]: !schedule.currentScheduleName,
              })}
            >
              {schedule.currentScheduleName || 'System is currently not on a schedule'}
            </span>
          </p>
          <OverrideScheduleProfileForSystemModal
            systemId={props.systemId}
            isScheduled={schedule.canOverride}
            isOverridden={schedule.isOverridden}
            onOverride={schedule.refresh}
          />
        </div>
        {schedule.settingsDiff.length > 0 && (
          <div className='pt-4'>
            {schedule.settingsDiff.map((item) => (
              <p key={item.key || item.label}>
                <b className='color-secondary'>{item.label}:</b> {item.current}{' '}
              </p>
            ))}
          </div>
        )}
      </div>

      <div
        className={classNames(styles['system-card'], cardClass, {
          'd-flex flex-row align-items-center': schedule.settingsDiff.length === 0,
        })}
      >
        <div
          className={classNames(styles['system-card-title'], 'd-flex justify-content-space-between align-items-center')}
        >
          <b className='color-secondary d-flex'>
            Next Change: &nbsp;
            {schedule.changeAt !== N_A && (
              <>
                {!schedule.nextScheduleName && _.isEmpty(schedule.settingsDiff) && (
                  <span className='color-primary d-flex align-items-center'>
                    <span>No Schedule</span>
                    <InfoTooltip className='ml-4 caption'>System will rely on local settings</InfoTooltip>
                  </span>
                )}
                {schedule.nextScheduleName && (
                  <span className='color-primary'>
                    {schedule.nextScheduleName === schedule.currentScheduleName
                      ? 'Same schedule'
                      : schedule.nextScheduleName}
                  </span>
                )}
              </>
            )}
          </b>

          {schedule.changeAt !== N_A ? (
            <span className={styles['info-caption']}>{schedule.changeAt}</span>
          ) : (
            <span className={styles['info-caption']}>No changes scheduled for the next 7 days</span>
          )}
        </div>

        {schedule.changeAt !== N_A && !_.isEmpty(schedule.settingsDiff) && (
          <div className='pt-8'>
            {schedule.settingsDiff.map((item) => (
              <p key={item.key || item.label}>
                <b className='color-secondary'>{item.label}:</b> {item.next}{' '}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NextSystemSchedule;
