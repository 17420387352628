export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    LiveUpdateUnion: ['NotificationsUpdate', 'RefetchNotifications', 'RefreshApp'],
  },
};
export default result;
