import Logger from '../../service/logger';
import { AppThunk } from '../store';

import { setConstraints, setModal } from './globalSlice';
import type { ModalConfig } from './globalSlice';
import { getCurrentUserConstraints } from './gql/getCurrentUserConstraints.users.gql';

export const refetchUserConstraints = (): AppThunk => async (dispatch) => {
  try {
    const constraints = await getCurrentUserConstraints(false);
    dispatch(setConstraints(constraints));
  } catch (error) {
    Logger.warn('Failed to refresh data, please reload your page');
  }
};

export function displayConfirmationModal<T extends {}>(
  modal: Omit<ModalConfig<T>, 'callback'>
): AppThunk<{ result: boolean; data: T }> {
  return async (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        setModal({
          ...modal,
          // This is a workaround for typescript not being able to infer type of generic function
          renderContent: modal.renderContent as ModalConfig['renderContent'],
          callback: (result) => {
            resolve(result as { result: boolean; data: T });
          },
        })
      );
    });
  };
}
