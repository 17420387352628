import { datetimeTypePolicy, dateTypePolicy } from 'src/core/apollo/utils/type-policies';

export const scalarTypePolicies = {
  ChargePointConnection: { fields: { statusChangedAt: datetimeTypePolicy } },
  DeviceType: { fields: { createdAt: datetimeTypePolicy } },
  Gateway: { fields: { createdAt: datetimeTypePolicy, installationDate: dateTypePolicy } },
  GatewayFile: { fields: { outdatedAt: datetimeTypePolicy, uploadedAt: datetimeTypePolicy } },
  GatewayOverview: { fields: { createdAt: datetimeTypePolicy, installationDate: dateTypePolicy } },
  SystemIntegration: { fields: { connectedAt: datetimeTypePolicy, expiryAt: datetimeTypePolicy } },
  SystemIntegrationCredentials: { fields: { createdAt: datetimeTypePolicy, invalidatedAt: datetimeTypePolicy } },
};
