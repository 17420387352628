import type * as Types from '../../../../core/apollo/__generated__/utilityGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetInvoicesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  type: Types.UtilityServiceTypes;
  datePeriod: Types.MonthRangeInput;
  meterIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type GetInvoicesQuery = {
  getUtilityInvoices: Array<{
    id: number;
    statementDate?: Date | null;
    startDate: Date;
    endDate: Date;
    billingMonth: Date;
    measurementType: Types.MetricsMeasurementType;
    totalVolume: number;
    totalCost: number;
    billingAccount: string;
    billingAddress: string;
    billingContact?: string | null;
    files?: Array<string> | null;
    status?: Types.UtilityInvoiceStatus | null;
    notes?: string | null;
    hddDays?: number | null;
    cddDays?: number | null;
    updated_at: Date;
    updated_by?: string | null;
    invoiceItems?: Array<{ type: Types.UtilityInvoiceItemType; cost?: number | null }> | null;
  }>;
};

export type UpdateInvoiceMutationVariables = Types.Exact<{
  utilityInvoiceId: Types.Scalars['Int']['input'];
  info: Types.UtilityInvoiceInput;
}>;

export type UpdateInvoiceMutation = { patchInvoice: boolean };

export const GetInvoicesDocument = gql`
  query GetInvoices($siteId: Int!, $type: UtilityServiceTypes!, $datePeriod: MonthRangeInput!, $meterIds: [Int!]!) {
    getUtilityInvoices(datePeriod: $datePeriod, siteId: $siteId, utilityType: $type, meterIds: $meterIds) {
      id
      statementDate
      startDate
      endDate
      billingMonth
      measurementType
      totalVolume
      totalCost
      billingAccount
      billingAddress
      billingContact
      files
      status
      notes
      hddDays
      cddDays
      updated_at
      updated_by
      invoiceItems {
        type
        cost
      }
    }
  }
`;
export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($utilityInvoiceId: Int!, $info: UtilityInvoiceInput!) {
    patchInvoice(invoiceId: $utilityInvoiceId, invoiceInfo: $info)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetInvoices(
      variables: GetInvoicesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetInvoicesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetInvoicesQuery>(GetInvoicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetInvoices',
        'query',
        variables
      );
    },
    UpdateInvoice(
      variables: UpdateInvoiceMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateInvoiceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateInvoiceMutation>(UpdateInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateInvoice',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
