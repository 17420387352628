import _ from 'lodash';
import { useMemo } from 'react';

import { UiSchema, extendFieldOrFactory } from './uiSchemaModel';

// TODO: review and refactor later to impore type safety

type ExtendField<T> = Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Omit<T extends { builder: any; field: any; deps: any } ? T['field'] : T, 'copyWith' | 'type'>
>;

/**
 * Safely extend ui schema with strict type-checking
 */
export function extendUiSchema<T, F extends UiSchema<T>>(
  uiSchema: F,
  propertiesToMergeIntoSchema: () => {
    [K in keyof F['fields']]?: ExtendField<F['fields'][K]>;
  }
): F {
  const fields = uiSchema.fields;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldsToMerge: any = {};
  const builtProperties = propertiesToMergeIntoSchema();

  for (const key in builtProperties) {
    if (Object.prototype.hasOwnProperty.call(fields, key)) {
      const field = builtProperties[key];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fieldsToMerge[key] = extendFieldOrFactory(fields[key], field as any) as any;
    } else {
      console.error('Field not found in ui schema:', key);
    }
  }

  const mergedSchema = _.merge(fields, fieldsToMerge);

  return {
    ...uiSchema,
    fields: mergedSchema,
  };
}

/**
 * This hook is used to safely extend ui schema with strict type-checking
 */
export function useExtendedUiSchema<T, F extends UiSchema<T>>(
  uiSchema: F,
  propertiesToMergeIntoSchema: () => {
    [K in keyof F['fields']]?: ExtendField<F['fields'][K]>;
  },
  deps: React.DependencyList
): F {
  return useMemo(() => extendUiSchema(uiSchema, propertiesToMergeIntoSchema), deps);
}
