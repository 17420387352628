import React, { lazy } from 'react';

import withSuspense from 'src/cdk/HOCs/withSuspense/withSuspence';

import { SkeletonLoader } from '../Loader/Loader';

const skeleton = <SkeletonLoader rounded height='8px' width='8px' />;

const StatusIndicator = withSuspense(
  lazy(() => import('./StatusIndicator')),
  skeleton
);

export default StatusIndicator;
