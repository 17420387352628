import { Navigate, RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import Guard from 'src/fragments/layouts/guard/Guard';
import { Routes } from 'src/routes';

import { MeterOverviewPerSiteLayout } from './[:type]/[:siteId]/layout';

const router: RouteObject[] = [
  {
    path: `${Routes.Meter}/:type`,
    children: [
      {
        index: true,
        lazy: asLazyPage(() => import('./[:type]/page')),
      },
      {
        path: ':siteId',
        element: <Guard siteIdRouteParamName='siteId' />,
        children: [
          {
            path: '',
            element: <MeterOverviewPerSiteLayout />,
            errorElement: <ExceptionHandler />,
            children: [
              {
                path: 'dashboard',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/dashboard/page')),
              },
              {
                path: 'trend',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/trend/page')),
              },
              {
                path: 'heatmap',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/heatmap/page')),
              },
              {
                path: 'activity',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/activity/page')),
              },
              {
                path: 'calendarized',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/calendarized/page')),
              },
              {
                path: '*',
                element: <Navigate to='dashboard' />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default router;
