import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/core/store/hooks';
import { Button } from 'src/shared/components/Button/Button';
import { Modal } from 'src/shared/components/Popup';

import { selectTimezoneForSiteId } from '../../../sites/sitesSlice';
import { SupportableSystems } from '../../../systems/gql/getSystemsForSite.resources.gql';
import {
  deleteOverriddenSchedulerProfileForSingleSystem,
  overrideDRSequenceForSingleSystem,
  overrideSchedulerProfileForSingleSystem,
} from '../../../systems/systemsActions';
import { selectedSystemById } from '../../../systems/systemsSlice';
import { isDRTypeSequence } from '../../../systems/utils/system.utils';
import AddScheduleModal from '../AddScheduleModal/AddScheduleModal';

export interface OverrideScheduleProfileForSystemModalProps {
  systemId: number;
  isScheduled?: boolean | null;
  isOverridden?: boolean | null;
  onOverride?: () => void;
}

/**
 * This modal also responsible for display of the button which controls this modal visibility
 */
const OverrideScheduleProfileForSystemModal: React.FC<OverrideScheduleProfileForSystemModalProps> = ({
  systemId,
  isScheduled,
  isOverridden,
  onOverride,
}) => {
  if (!isScheduled) {
    return null;
  }
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  function close() {
    setIsOpen(false);
  }

  const system = useSelector(selectedSystemById<SupportableSystems>(systemId));
  if (!system) {
    console.error(`Cannot find system with id ${systemId}`);
    return null;
  }

  const timeZone = useSelector(selectTimezoneForSiteId(system?.siteId));
  return (
    <div>
      <Button
        className='ml-16'
        icon='calendar'
        size='regular'
        variant='secondary'
        shape='rounded'
        onClick={() => setIsOpen(true)}
        showIndicator={isOverridden ?? false}
        isSmallIndicator={true}
        indicatorColor='green'
      />
      {!isDRTypeSequence(system.sequence.id) ? (
        <AddScheduleModal
          isOpen={isOpen}
          title={`Override ${system.schedulerProfile?.name} Schedule for ${system.name}`}
          acceptAction={async (config, endTime) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete config?.settings?.__typename;
            await dispatch(
              overrideSchedulerProfileForSingleSystem({
                systemId,
                endTime,
                sequenceId: config.sequenceId,
                settings: config.settings,
              })
            );
            onOverride?.();
            close();
          }}
          cancelAction={() => close()}
          deleteAction={async () => {
            await dispatch(deleteOverriddenSchedulerProfileForSingleSystem(systemId));
            onOverride?.();
            close();
          }}
          systemType={system.type}
          systemModel={system.__typename === 'SystemPackageAltc24Prog' ? system.model : undefined}
          underSchedulerControl={system.schedulerProfile?.underSchedulerControl}
          endTime={system.overriddenSchedulerProfile?.endTime}
          config={{
            sequenceId: system?.overriddenSchedulerProfile?.sequenceId,
            settings: system?.overriddenSchedulerProfile?.settings,
          }}
          scheduleName=''
          timeZone={timeZone || 'UTC'}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          acceptAction={async () => {
            await dispatch(overrideDRSequenceForSingleSystem(systemId));
            onOverride?.();
            close();
          }}
          cancelAction={() => close()}
          titleText={
            <p className='default-text'>
              Do you want to override<span className='subtitle'> Curtailment </span>sequence for {system.name} and{' '}
              revert it to its previous settings?
            </p>
          }
          subtitleText=''
          actionButtonLabel='Yes'
          cancelButtonLabel='No'
        />
      )}
    </div>
  );
};

export default OverrideScheduleProfileForSystemModal;
