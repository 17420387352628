import classNames from 'classnames';

import Logger from 'src/core/service/logger';
import { SupportedColors } from 'src/enums';

import styles from './DotsIndicator.module.scss';

const NUMBER_OF_DOTS = 10;

export interface DotsIndicatorProps {
  /**
   * 'colorBehavior' props represents a different scenarios related to filling dots with appropriate color
   * Always contains 10 number elements that represent a particular color
   */
  colorBehavior: SupportedColors[] | SupportedColors;
  /**
   * 'value' props represents a specific value from 0 to 10 that is stored on the server
   * Allows to fill specified amount of dots with color based on received value
   */
  value: number;

  /**
   * 'numberOfDots' props represents the amount of overall dots that should be rendered
   * the default value is 10
   */
  numberOfDots?: number;
  /**
   * @default false
   */
  small?: boolean;
}

const DotsIndicator: React.FC<DotsIndicatorProps> = (props) => {
  Logger.assert(
    props.value < NUMBER_OF_DOTS || props.value > 0,
    `value ${props.value} should be between 0 and ${NUMBER_OF_DOTS}`
  );

  const dotColor = Array.isArray(props.colorBehavior) ? props.colorBehavior[props.value - 1] : props.colorBehavior;

  function getDotColor(dotIndex: number): string {
    if (dotIndex < props.value) {
      return dotColor;
    } else {
      return SupportedColors.Grey;
    }
  }

  return (
    <div className={styles['dots-container']}>
      {Array(props.numberOfDots || NUMBER_OF_DOTS)
        .fill(null)
        .map((_, index) => {
          return (
            <span
              key={index}
              className={classNames(
                'card flat el-12',
                styles['dot'],
                {
                  [styles['small']]: props.small,
                },
                styles[`color-${getDotColor(index)}`]
              )}
            />
          );
        })}
    </div>
  );
};

export default DotsIndicator;
