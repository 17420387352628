import classNames from 'classnames';
import _ from 'lodash';
import React, { useMemo } from 'react';

import { numberWithCommasFormat } from 'src/cdk/formatter/numberFormatter';
import { N_A } from 'src/constants';

import { Icon, SupportedIconColors } from '../Icon/Icon';

import styles from './PercentChangePill.module.scss';

interface Props {
  value?: number | null;
  className?: string;
  inverseColor?: boolean;
}

const PercentChangePill: React.FC<Props> = ({ value, inverseColor, className }) => {
  const color: SupportedIconColors = useMemo(() => {
    if (_.isNil(value) || value === 0) {
      return 'primary';
    }

    if (inverseColor) {
      return value > 0 ? 'green' : 'red';
    }
    return value > 0 ? 'red' : 'green';
  }, [inverseColor, value]);

  const hasValue = !_.isNil(value);

  return (
    <div
      className={classNames(
        'd-flex body-small-semi-bold align-items-center',
        styles['pill'],
        styles[`color-${color}`],
        {
          [styles['with-icon']]: hasValue,
          [styles['empty']]: !hasValue,
        },
        className
      )}
    >
      <p>{hasValue ? `${value > 0 ? '+' : ''}${numberWithCommasFormat(value)}%` : N_A}</p>
      {hasValue &&
        (value === 0 ? <>&nbsp;</> : <Icon size='m' icon={`${value > 0 ? 'arrow-up' : 'arrow-down'}`} color={color} />)}
    </div>
  );
};

export default PercentChangePill;
