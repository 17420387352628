import _ from 'lodash';
import { useParams } from 'react-router';

import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';

import { SystemMetersInfo } from './config';

export function useSystemMeterType(): SystemType {
  const type = useParams<{ type: string }>().type ?? '';
  const systemType = _.findKey(SystemMetersInfo, (item) => item?.url === type);
  if (!type || !systemType) {
    throw new Error('System meter type is not valid');
  }

  return systemType as SystemType;
}
