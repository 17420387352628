import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import styles from './Loader.module.scss';

interface SkeletonProps {
  type?: 'block' | 'text-100' | 'text-50' | 'text-75' | 'text-25' | 'header-25';
  width?: string;
  height?: string;
  useParentWidth?: boolean;
  useParentHeight?: boolean;
  rounded?: boolean;
  stronger?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const SkeletonLoader: React.FC<SkeletonProps> = (props) => {
  const [parentHeight, setParentHeight] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);

  const loaderRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setParentHeight(node.parentElement?.clientHeight ?? 0);
      setParentWidth(node.parentElement?.clientWidth ?? 0);
    }
  }, []);

  return (
    <div
      ref={loaderRef}
      style={{
        width: props.useParentWidth ? `${parentWidth}px` : props.width,
        height: props.useParentHeight ? `${parentHeight}px` : props.height,
        borderRadius: props.rounded ? '50%' : undefined,
        ...(props.style ?? {}),
      }}
      className={classNames(
        styles['skeleton'],
        styles[props.type || ''],
        props.stronger ? styles['stronger'] : undefined,
        props.className
      )}
    />
  );
};

interface Props {
  small?: boolean;
  extraSmall?: boolean;
}

/**
 * Copied from https://loading.io/css/
 * Added ref logic to
 */
export const CircularLoader: React.FC<Props> = (props) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const loaderRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setHeight(node.parentElement?.clientHeight ?? 0);
      setWidth(node.parentElement?.clientWidth ?? 0);
    }
  }, []);

  return (
    <div
      ref={loaderRef}
      style={{
        maxWidth: `${width}px`,
        height: `${height}px`,
        margin: '0 auto !important',
        transform: props.extraSmall ? 'scale(0.25)' : props.small ? 'scale(0.5)' : undefined,
      }}
      className={styles['lds-wrapper']}
    >
      <div className={styles['lds-default']}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export const Loader: React.FC = () => {
  return <div className={styles.loader}>Loading...</div>;
};
