import classNames from 'classnames';
import { clamp, debounce, toSafeInteger } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '../Button/Button';
import { Input } from '../Input/Input';

import styles from './NumericControls.module.scss';

export interface NumericControlsProps {
  /**
   * Current 'value' to display on input.
   */
  value: number;
  /**
   * Disable [+] button if value is equal to max value.
   * Clamp to that value on blur or debounce.
   */
  max: number;
  /**
   * Disable [-] button if value is equal to min value.
   * Clamp to that value on blur or debounce.
   */
  min: number;
  /**
   * `false` by default
   */
  disabled?: boolean;
  /**
   * After specified time `onChange` callback will be executed with updated value.
   * Measured in 'ms'. And it's `1000`ms by default.
   */
  debounceTime?: number;
  /**
   * By default step = 1
   * The number which represents how value in input could be adjusted
   */
  step?: number;
  className?: string;
  onChange: (value: number) => void;
  onSubmit: (value: number) => void | Promise<void>;
}

export const NumericControls: React.FC<NumericControlsProps> = ({
  value: initialValue,
  max,
  min,
  disabled = false,
  debounceTime = 1000,
  step = 1,
  className,
  onChange,
  onSubmit,
}) => {
  const [initial, setInitial] = useState(initialValue);
  // Store current value as string to allow user type anything, including -10 value
  const [displayValue, setDisplayValue] = useState(initialValue.toString());
  // Used to ensure that value is valid, otherwise set 0
  const currentNumber = toSafeInteger(displayValue);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const isValueChanged = currentNumber !== initial;

  // Update inner state if value was updated from parent
  useEffect(() => {
    const newCurrentValue = disabled ? initialValue : clamp(initialValue, min, max);
    if (newCurrentValue.toString() !== displayValue) {
      setDisplayValue(newCurrentValue.toString());
    }
    if (initialValue !== newCurrentValue) {
      onChange(newCurrentValue);
    }
  }, [initialValue, min, max]);

  const debouncedOnSubmit = useCallback(
    debounce(async (nextValue: number) => {
      setWaitingForResponse(true);
      try {
        await onSubmit(nextValue);
        setInitial(nextValue);
      } catch (error) {
        console.error(error);
      }
      setWaitingForResponse(false);
    }, debounceTime),
    []
  );

  const isDecreaseButtonDisabled = currentNumber <= min || disabled || waitingForResponse;
  const isIncreaseButtonDisabled = currentNumber >= max || disabled || waitingForResponse;

  function pushChangeEvent(nextValue: number): void {
    onChange(nextValue);
    debouncedOnSubmit(nextValue);
  }

  // Returns clamped integer
  function clampAndSetValue(value: number | string): number {
    value = clamp(toSafeInteger(value), min, max);
    setDisplayValue(value.toString());
    return value;
  }

  // Utility function to perform increase/decrease by step
  function handleButtonClick(vector: number): () => void {
    return () => {
      const value = clampAndSetValue(currentNumber + vector * step);
      pushChangeEvent(value);
    };
  }

  function onInputChange(text: string): void {
    // TODO: define how to stop debounce without calling it, after user starts editing
    setDisplayValue(text);
  }

  function updateValueOnBlur(): void {
    const isCurrentValueNotValid = currentNumber.toString() !== displayValue;
    // Revert to original value if value is not valid, otherwise - clamp current one
    const value = clampAndSetValue(isCurrentValueNotValid ? initialValue : currentNumber);
    pushChangeEvent(value);
  }

  return (
    <fieldset className={styles['numeric-controls']} disabled={disabled || waitingForResponse}>
      <div className={styles['button-decrease']}>
        <Button
          icon='minus'
          shape='rect'
          variant='primary'
          size='small'
          onClick={handleButtonClick(-1)}
          disabled={isDecreaseButtonDisabled}
        />
      </div>
      <div className={classNames(styles['input-wrapper'], { [styles['loading']]: isValueChanged }, className)}>
        <Input
          value={displayValue}
          onChange={onInputChange}
          placeholder='0'
          className={classNames('subtitle', styles['controls-input'])}
          onBlur={updateValueOnBlur}
          onSubmit={(element) => element.blur()}
        />
      </div>
      <div className={styles['button-increase']}>
        <Button
          icon='plus'
          shape='rect'
          variant='primary'
          size='small'
          onClick={handleButtonClick(+1)}
          disabled={isIncreaseButtonDisabled}
        />
      </div>
    </fieldset>
  );
};
