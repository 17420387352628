export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date };
  /** InstantConditionEvent | MetricsConditionEvent | CalculationConditionEvent */
  EventConditionType: {
    input: InstantConditionEvent | MetricsConditionEvent | CalculationConditionEvent;
    output: InstantConditionEvent | MetricsConditionEvent | CalculationConditionEvent;
  };
};

export type Alert = {
  color: AlertColor;
  conditions: Array<ConditionUnion>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expression: Scalars['String']['output'];
  expressionDescription?: Maybe<Scalars['String']['output']>;
  floors?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  recipients: Array<AlertRecipients>;
  schedule?: Maybe<Array<Schedule>>;
  siteId: Scalars['Int']['output'];
  systemIds?: Maybe<Array<Scalars['Int']['output']>>;
  systemTypes?: Maybe<Array<SystemType>>;
  type: AlertType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum AlertAction {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WEB = 'WEB',
}

export enum AlertColor {
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export enum AlertOperators {
  EQUAL = 'EQUAL',
  LESS = 'LESS',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
  MORE = 'MORE',
  MORE_OR_EQUAL = 'MORE_OR_EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}

export type AlertOverview = {
  actions: Array<AlertAction>;
  createdBy?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  expression: Scalars['String']['output'];
  expressionDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  subscribed: Scalars['Boolean']['output'];
  systemsCount: Scalars['Int']['output'];
  type: AlertType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertRecipients = {
  actions: Array<AlertAction>;
  userId: Scalars['String']['output'];
};

export type AlertRecipientsInput = {
  actions: Array<AlertAction>;
  userId: Scalars['String']['input'];
};

export enum AlertType {
  DEFAULT = 'DEFAULT',
  FDD = 'FDD',
}

export type CalculationCondition = {
  alertId: Scalars['Int']['output'];
  arg1?: Maybe<Scalars['String']['output']>;
  arg2?: Maybe<Scalars['String']['output']>;
  calculationEvent: CalculationConditionEvent;
  id: Scalars['Int']['output'];
  lastHoursPeriod?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  source?: Maybe<ConditionSource>;
};

export enum CalculationConditionEvent {
  CONNECTION_LOST = 'CONNECTION_LOST',
  INEFFICIENT_SYSTEM = 'INEFFICIENT_SYSTEM',
  TOTAL_ENERGY_CONSUMPTION = 'TOTAL_ENERGY_CONSUMPTION',
  TOTAL_NATURAL_GAS_CONSUMPTION = 'TOTAL_NATURAL_GAS_CONSUMPTION',
  TOTAL_STEAM_CONSUMPTION = 'TOTAL_STEAM_CONSUMPTION',
  TOTAL_WATER_CONSUMPTION = 'TOTAL_WATER_CONSUMPTION',
}

export type ConditionSource = {
  systemIds?: Maybe<Array<Scalars['Int']['output']>>;
};

export type ConditionSourceInput = {
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ConditionUnion = CalculationCondition | InstantCondition | MetricCondition;

export type CreateAlertInput = {
  color: AlertColor;
  conditions: Array<CreateConditionInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  expression: Scalars['String']['input'];
  expressionDescription: Scalars['String']['input'];
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
  recipients: Array<AlertRecipientsInput>;
  schedule?: InputMaybe<Array<ScheduleInput>>;
  siteId: Scalars['Int']['input'];
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  systemTypes?: InputMaybe<Array<SystemType>>;
  type: AlertType;
};

export type CreateConditionInput = {
  event: Scalars['EventConditionType']['input'];
  index: Scalars['Int']['input'];
  lastHoursPeriod?: InputMaybe<Scalars['String']['input']>;
  metricType?: InputMaybe<MetricType>;
  name: Scalars['String']['input'];
  operator?: InputMaybe<AlertOperators>;
  source?: InputMaybe<ConditionSourceInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum FloorType {
  BASEMENT = 'BASEMENT',
  CELLAR = 'CELLAR',
  LOBBY = 'LOBBY',
  MEZZANINE = 'MEZZANINE',
  NO_FLOOR = 'NO_FLOOR',
  NUMBER = 'NUMBER',
  ROOF = 'ROOF',
  SUBCELLAR = 'SUBCELLAR',
}

export type InstantCondition = {
  alertId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  instantEvent: InstantConditionEvent;
  name: Scalars['String']['output'];
  source?: Maybe<ConditionSource>;
};

export enum InstantConditionEvent {
  FILTER_HEALTH = 'FILTER_HEALTH',
  FREEZE_DETECTED = 'FREEZE_DETECTED',
  LEAK_DETECTED = 'LEAK_DETECTED',
}

export type MetricCondition = {
  alertId: Scalars['Int']['output'];
  arg1?: Maybe<Scalars['String']['output']>;
  arg2?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  metricEvent: MetricsConditionEvent;
  metricType: MetricType;
  name: Scalars['String']['output'];
  source?: Maybe<ConditionSource>;
};

export type MetricMeasurement = {
  measurement: MetricsMeasurementType;
  metric: MetricType;
};

export enum MetricType {
  AVG_SPACE_TEMPERATURE = 'AVG_SPACE_TEMPERATURE',
  BATTERY = 'BATTERY',
  CDD_TEMPERATURE = 'CDD_TEMPERATURE',
  COOLING = 'COOLING',
  COOLING_2 = 'COOLING_2',
  COOLING_3 = 'COOLING_3',
  DAMPER_EFFECTIVE = 'DAMPER_EFFECTIVE',
  DAMPER_POSITION = 'DAMPER_POSITION',
  DHW_TEMPERATURE = 'DHW_TEMPERATURE',
  EFFICIENCY = 'EFFICIENCY',
  ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION',
  FAN = 'FAN',
  FUEL_OIL_CONSUMPTION = 'FUEL_OIL_CONSUMPTION',
  GAS_DEMAND = 'GAS_DEMAND',
  GAS_USAGE = 'GAS_USAGE',
  HDD_TEMPERATURE = 'HDD_TEMPERATURE',
  HEATING = 'HEATING',
  HEATING_2 = 'HEATING_2',
  HEATING_3 = 'HEATING_3',
  HUMIDITY = 'HUMIDITY',
  LIGHT = 'LIGHT',
  LOCAL_SETPOINT = 'LOCAL_SETPOINT',
  OCCUPANCY = 'OCCUPANCY',
  OUTLET_PRESSURE = 'OUTLET_PRESSURE',
  OUTSIDE_AIR_TEMPERATURE = 'OUTSIDE_AIR_TEMPERATURE',
  POWER = 'POWER',
  POWER_DEMAND = 'POWER_DEMAND',
  POWER_FACTOR = 'POWER_FACTOR',
  PRESSURE = 'PRESSURE',
  PRV_POSITION_1 = 'PRV_POSITION_1',
  PRV_POSITION_2 = 'PRV_POSITION_2',
  PRV_POSITION_3 = 'PRV_POSITION_3',
  PRV_POSITION_4 = 'PRV_POSITION_4',
  PRV_POSITION_5 = 'PRV_POSITION_5',
  PRV_POSITION_6 = 'PRV_POSITION_6',
  PRV_PRESSURE_1 = 'PRV_PRESSURE_1',
  PRV_PRESSURE_2 = 'PRV_PRESSURE_2',
  PRV_PRESSURE_3 = 'PRV_PRESSURE_3',
  PRV_PRESSURE_4 = 'PRV_PRESSURE_4',
  PRV_PRESSURE_5 = 'PRV_PRESSURE_5',
  PRV_PRESSURE_6 = 'PRV_PRESSURE_6',
  RAINFALL_TOTAL = 'RAINFALL_TOTAL',
  REACTIVE_POWER = 'REACTIVE_POWER',
  REMOTE_SETPOINT = 'REMOTE_SETPOINT',
  REMOTE_SETPOINT_MAX = 'REMOTE_SETPOINT_MAX',
  REMOTE_SETPOINT_MIN = 'REMOTE_SETPOINT_MIN',
  SPACE_TEMPERATURE = 'SPACE_TEMPERATURE',
  STEAM_CONSUMPTION = 'STEAM_CONSUMPTION',
  STEAM_DEMAND = 'STEAM_DEMAND',
  SUPPLY_TEMPERATURE = 'SUPPLY_TEMPERATURE',
  TEMPERATURE = 'TEMPERATURE',
  UPTIME = 'UPTIME',
  WASTE_CONSUMPTION = 'WASTE_CONSUMPTION',
  WATER_CONSUMPTION = 'WATER_CONSUMPTION',
  WATER_DEMAND = 'WATER_DEMAND',
  WIND_DIRECTION = 'WIND_DIRECTION',
  WIND_SPEED = 'WIND_SPEED',
  ch2o = 'ch2o',
  co = 'co',
  co2 = 'co2',
  light = 'light',
  noise = 'noise',
  o3 = 'o3',
  pm2_5 = 'pm2_5',
  pm10 = 'pm10',
  relative_humidity = 'relative_humidity',
  score = 'score',
  temperature = 'temperature',
  tvoc = 'tvoc',
}

export enum MetricsConditionEvent {
  GENERIC_METRIC_EVENT = 'GENERIC_METRIC_EVENT',
}

export enum MetricsMeasurementType {
  DBA = 'DBA',
  DEGREES = 'DEGREES',
  FAHRENHEIT = 'FAHRENHEIT',
  FT3 = 'FT3',
  HCF = 'HCF',
  HCF_PER_HOUR = 'HCF_PER_HOUR',
  HECTO_PASCAL = 'HECTO_PASCAL',
  KBTU = 'KBTU',
  KVAR = 'KVAR',
  KW = 'KW',
  KWH = 'KWH',
  LUX = 'LUX',
  METERS_PER_SECOND = 'METERS_PER_SECOND',
  METRIC_TON = 'METRIC_TON',
  MICROGRAM_M3 = 'MICROGRAM_M3',
  MILES_PER_HOUR = 'MILES_PER_HOUR',
  MILLIMETER = 'MILLIMETER',
  MILLION_POUNDS = 'MILLION_POUNDS',
  MILLION_POUNDS_PER_HOUR = 'MILLION_POUNDS_PER_HOUR',
  NUMERIC = 'NUMERIC',
  PERCENTAGE = 'PERCENTAGE',
  PPB = 'PPB',
  PPM = 'PPM',
  PSI = 'PSI',
  THERMS = 'THERMS',
  THERMS_PER_HOUR = 'THERMS_PER_HOUR',
  USAGE = 'USAGE',
  USD = 'USD',
  WH = 'WH',
}

export type Mutation = {
  bulkSubscribeToAlerts: Scalars['Boolean']['output'];
  /**
   * * This endpoint allows user to create an alert and subscribe to it, or subscribe other users or both.
   * * Only users with SuperAdmin role can create an alert and subscribe other Users to it by specifying them in recipients field.
   * * If SuperAdmin wants to create alert and subscribe other users and themselves, then userId for SuperAdmin has to be included in recipients field.
   * * If userId of SuperAdmin is not included in recipients field then only users in recipients field will be subscribed to the alert.
   * * When recipients field is empty, created AlertId is returned, E.g. '1'.
   * * When recipients field is not empty and userId of SuperAdmin is in recipients field, then created AlertId for SuperAdmin is returned, E.g. '1'.
   * * When recipients field is not empty and userId of SuperAdmin is not in recipients field, then simply a message 'Alert is created and all Recipients are subscribed!' is returned.
   */
  createAlert: Scalars['String']['output'];
  deleteAlert: Scalars['Boolean']['output'];
  deleteAllUserNotifications: Scalars['Boolean']['output'];
  deleteUnresolvedNotificationsForSystem: Scalars['Boolean']['output'];
  setStatusForAllNotifications: Scalars['Boolean']['output'];
  setStatusForNotifications: Scalars['Boolean']['output'];
  subscribeToAlert: Scalars['Boolean']['output'];
  toggleAlert: Scalars['Boolean']['output'];
  triggerAlertsWorkflow: Scalars['Boolean']['output'];
  updateAlert: Alert;
};

export type MutationbulkSubscribeToAlertsArgs = {
  actions?: InputMaybe<Array<AlertAction>>;
  alertIds: Array<Scalars['Int']['input']>;
  subscribe: Scalars['Boolean']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationcreateAlertArgs = {
  createAlertInput: CreateAlertInput;
};

export type MutationdeleteAlertArgs = {
  alertId: Scalars['Int']['input'];
};

export type MutationdeleteAllUserNotificationsArgs = {
  userId: Scalars['String']['input'];
};

export type MutationdeleteUnresolvedNotificationsForSystemArgs = {
  alertId?: InputMaybe<Scalars['Int']['input']>;
  systemId: Scalars['Int']['input'];
};

export type MutationsetStatusForAllNotificationsArgs = {
  status: UserNotificationStatus;
};

export type MutationsetStatusForNotificationsArgs = {
  notificationIds: Array<Scalars['Int']['input']>;
  status: UserNotificationStatus;
};

export type MutationsubscribeToAlertArgs = {
  actions?: InputMaybe<Array<AlertAction>>;
  alertId: Scalars['Int']['input'];
  subscribe: Scalars['Boolean']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationtoggleAlertArgs = {
  alertId: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type MutationupdateAlertArgs = {
  alertId: Scalars['Int']['input'];
  updateAlertInput: UpdateAlertInput;
};

export type NotificationsFilterInput = {
  systemTypes?: InputMaybe<Array<SystemType>>;
};

export type PaginatedUserNotifications = {
  data: Array<UserNotification>;
  pagination: Pagination;
};

export type Pagination = {
  currentPage: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  nextPage: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  prevPage: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  alertDetails: Alert;
  alertsOverviewAcrossSites: Array<SiteAlertsOverview>;
  alertsOverviewPerSite: Array<AlertOverview>;
  getPaginatedNotificationsHistory: PaginatedUserNotifications;
  systemTypeConditions: Array<SystemTypeConditions>;
  unresolvedNotifications: Array<UnresolvedNotification>;
};

export type QueryalertDetailsArgs = {
  alertId: Scalars['Int']['input'];
};

export type QueryalertsOverviewAcrossSitesArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QueryalertsOverviewPerSiteArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetPaginatedNotificationsHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  filter: NotificationsFilterInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Schedule = {
  hours: Array<Scalars['Int']['output']>;
  weekday: WeekDays;
};

export type ScheduleInput = {
  hours: Array<Scalars['Int']['input']>;
  weekday: WeekDays;
};

export type SiteAlertsOverview = {
  lastNotificationTime?: Maybe<Scalars['DateTime']['output']>;
  siteId: Scalars['Int']['output'];
  totalConfiguredAlerts?: Maybe<Scalars['Int']['output']>;
  totalSubscribedAlerts?: Maybe<Scalars['Int']['output']>;
  unreadNotificationsCount?: Maybe<Scalars['Int']['output']>;
  unresolvedNotificationsCount?: Maybe<Scalars['Int']['output']>;
};

export enum SystemType {
  CHARGE_POINT = 'CHARGE_POINT',
  ELECTRICITY_METER = 'ELECTRICITY_METER',
  ELECTRICITY_SUBMETER = 'ELECTRICITY_SUBMETER',
  HW_BOILER_HT_HWR = 'HW_BOILER_HT_HWR',
  HW_BOILER_TEKMAR_284 = 'HW_BOILER_TEKMAR_284',
  LUTRON_VIVE_LIGHTING = 'LUTRON_VIVE_LIGHTING',
  NATURAL_GAS_METER = 'NATURAL_GAS_METER',
  PACKAGE_ALTC24PROG = 'PACKAGE_ALTC24PROG',
  PACKAGE_ECOBEE_DEFAULT = 'PACKAGE_ECOBEE_DEFAULT',
  PACKAGE_HONEYWELL_TC500AN = 'PACKAGE_HONEYWELL_TC500AN',
  SMART_OUTLET_T0006623 = 'SMART_OUTLET_T0006623',
  STEAM_METER = 'STEAM_METER',
  STEAM_PRV_STATION = 'STEAM_PRV_STATION',
  ST_BOILER_F_HT_MPC = 'ST_BOILER_F_HT_MPC',
  TBL_TCI = 'TBL_TCI',
  WATER_METER = 'WATER_METER',
  WEATHER_STATION_WTS506 = 'WEATHER_STATION_WTS506',
}

export type SystemTypeConditions = {
  calculationConditionEvents: Array<CalculationConditionEvent>;
  instantConditionEvents: Array<InstantConditionEvent>;
  metricTypesAsConditions: Array<MetricMeasurement>;
  metricsConditionEvents: Array<MetricsConditionEvent>;
  systemType: SystemType;
};

export type UnresolvedNotification = {
  alertId: Scalars['Int']['output'];
  color: AlertColor;
  description?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floorType: FloorType;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  siteId: Scalars['Int']['output'];
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  systemId: Scalars['Int']['output'];
  systemName: Scalars['String']['output'];
  systemType: SystemType;
  triggeredAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type UpdateAlertInput = {
  color?: InputMaybe<AlertColor>;
  conditions?: InputMaybe<Array<UpdateConditionInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  expression?: InputMaybe<Scalars['String']['input']>;
  expressionDescription?: InputMaybe<Scalars['String']['input']>;
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  recipients?: InputMaybe<Array<AlertRecipientsInput>>;
  schedule?: InputMaybe<Array<ScheduleInput>>;
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  systemTypes?: InputMaybe<Array<SystemType>>;
  type: AlertType;
};

export type UpdateConditionInput = {
  event: Scalars['EventConditionType']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  index: Scalars['Int']['input'];
  lastHoursPeriod?: InputMaybe<Scalars['String']['input']>;
  metricType?: InputMaybe<MetricType>;
  name: Scalars['String']['input'];
  operator?: InputMaybe<AlertOperators>;
  source?: InputMaybe<ConditionSourceInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UserNotification = {
  alertId: Scalars['Int']['output'];
  color: AlertColor;
  description?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floorType: FloorType;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  siteId: Scalars['Int']['output'];
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  systemId: Scalars['Int']['output'];
  systemName: Scalars['String']['output'];
  systemType: SystemType;
  triggeredAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export enum UserNotificationStatus {
  ARCHIVE = 'ARCHIVE',
  READ = 'READ',
  UNARCHIVE = 'UNARCHIVE',
  UNREAD = 'UNREAD',
}

export enum WeekDays {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}
