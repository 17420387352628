import { toast } from 'react-toastify';

import Toast from 'src/shared/components/Toast/Toast';

class ToastService {
  error(message: string): void {
    toast(<Toast icon='disabled' toastStatus='error' toastMessage={message} />);
  }

  warn(message: string): void {
    toast.warn(<Toast icon='warning' toastStatus='warn' toastMessage={message} />);
  }

  success(message: string): void {
    toast(<Toast icon='check' toastStatus='success' toastMessage={message} />);
  }
}

export const toastService = new ToastService();
