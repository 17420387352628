import { toastService } from 'src/core/service/toastService';
import { AppThunk } from 'src/core/store/store';

import { FilterConfiguration } from './filter.interface';
import { addFilterConfiguration, removeFilterConfiguration, updateFilterConfiguration } from './filtersSlice';
import { createFilterConfig, deleteFilterConfig, updateFilterConfig } from './gql/manageSavedFilter.users.gql';

export const putFilterOnRemote =
  (filter: FilterConfiguration): AppThunk<FilterConfiguration> =>
  async (dispatch) => {
    // If filter already exists, update it
    if (filter.key) {
      await updateFilterConfig(filter);
      dispatch(updateFilterConfiguration(filter));
      toastService.success('Filter configuration updated');
      return filter;
    }

    // Otherwise create new filter
    const createdId = await createFilterConfig(filter);
    filter = {
      ...filter,
      key: createdId,
    };
    dispatch(addFilterConfiguration(filter));
    toastService.success('Filter configuration created');
    return filter;
  };

export const deleteFilterFromRemote =
  (filter: FilterConfiguration): AppThunk<void> =>
  async (dispatch) => {
    await deleteFilterConfig(filter);
    dispatch(removeFilterConfiguration(filter));
    toastService.success('Filter configuration deleted');
  };
