import { ColorConfig, SupportedColors } from 'src/enums';

export enum CheckboxState {
  Empty,
  Partial,
  Checked,
}

export interface CheckboxWithPartialProps extends CheckboxGenericProps {
  /**
   * You can use `CheckboxState` enum as well as numbers: `0, 1, 2`
   *
   * `CheckboxState` usage is recommended from semantic point of view
   */
  state: CheckboxState;
}

export interface CheckboxProps extends CheckboxGenericProps {
  selected: boolean;
}
interface CheckboxGenericProps {
  /**
   * Name is passed to checkbox input, it can be usefull for forms
   */
  name?: string;
  /**
   * Label is rendered on the right side of checkbox
   */
  label?: string;
  /**
   * Specifies if the label color should be dimmed
   */
  dimmed?: boolean;
  /**
   * Tooltip is the text shown on hover over the whole element
   */
  tooltip?: string;
  /**
   * If enabled - will add (i) icon to the right of the label
   */
  tooltipWithIcon?: boolean;
  /**
   * Use `checkbox` for normal view, use switch for slider view
   * @default 'checkbox'
   */
  type?: 'checkbox' | 'switch' | 'switch-small';
  /**
   * disabled={false} by default
   */
  disabled?: boolean;
  /**
   * style variation that of the checkbox;
   * ColorConfig.blueSolidOpaque by default
   */
  styleVariant?: ColorConfig;

  className?: string;

  ellipsis?: boolean;

  /**
   * Prevent propagation of event
   */
  stopPropagation?: boolean;

  dotWithColor?: SupportedColors;

  onClick: () => void;
}
