import { NumberRange } from 'src/interfaces';
import { RangeSliderWithInputs } from 'src/shared/components/RangeSlider/RangeSliderWithInputs';

import styles from './OATResetModal.module.scss';

export interface OATResetProps {
  oatTemperatureLimits: NumberRange;
  setpointLimits: NumberRange;
  oatTemperatureLabel: string;
  setpointLabel: string;
  selectedOatTemperature: NumberRange;
  setSelectedOatTemperature: (value: NumberRange) => void;
  selectedSetpoint: NumberRange;
  setSelectedSetpoint: (value: NumberRange) => void;
  disabled?: boolean;
}

const OATReset: React.FC<OATResetProps> = (props) => {
  const [minPresetTemperatureLimit, maxPresetTemperatureLimit] = props.oatTemperatureLimits;
  const [minPresetSetpointLimit, maxPresetSetpointLimit] = props.setpointLimits;

  return (
    <div className={styles['reset-modal-content']}>
      <RangeSliderWithInputs
        label={props.oatTemperatureLabel}
        value={props.selectedOatTemperature}
        min={minPresetTemperatureLimit}
        max={maxPresetTemperatureLimit}
        onChange={props.setSelectedOatTemperature}
        disabled={props.disabled}
      />

      <RangeSliderWithInputs
        label={props.setpointLabel}
        value={props.selectedSetpoint}
        min={minPresetSetpointLimit}
        max={maxPresetSetpointLimit}
        onChange={props.setSelectedSetpoint}
        reverse
        disabled={props.disabled}
      />
    </div>
  );
};

export default OATReset;
