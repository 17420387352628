import _ from 'lodash';

import { ColorConfig, ColorConfigMap, ColorConfigToOpacityMap } from 'src/enums';

import styles from './ColorIndicator.module.scss';

export const ColorIndicator: React.FC<{
  renderAs: 'square' | 'none' | 'line';
  /**
   * If custom string is passed - it will be applied only to "square" indicator as fill color.
   */
  styleVariant: ColorConfig | string;
}> = ({ renderAs, styleVariant }) => {
  if (renderAs === 'none') {
    return <svg className='flex-none' viewBox='0 0 10 10' height={10} width={10} />;
  }

  return (
    <svg className='flex-none' viewBox='0 0 10 10' height={10} width={10}>
      {renderAs === 'square' ? (
        <rect
          width={10}
          height={10}
          fill={_.get(ColorConfigMap, styleVariant, styleVariant)}
          opacity={_.get(ColorConfigToOpacityMap, styleVariant, 1)}
          rx={2}
        />
      ) : (
        <path d='M0,5 10,5z' className={styles[styleVariant]} strokeWidth={2} />
      )}
    </svg>
  );
};
