import classNames from 'classnames';

import { Icon } from '../Icon/Icon';
import { SupportedIcon } from '../Icon/gen/suported-icons';

import styles from './Toast.module.scss';

export interface ToastProps {
  icon: SupportedIcon;
  /**
   * Status of a particular notification toast
   * Related to a background-color of an icon container;
   */
  toastStatus: 'error' | 'warn' | 'success';
  toastMessage: string;
}

const Toast: React.FC<ToastProps> = (props) => {
  return (
    <div
      className={classNames(
        'card',
        'flat',
        'el-08',
        'hov-el-20',
        'with-pointer',
        'd-flex',
        styles['toast-notification']
      )}
    >
      <div className={classNames(styles['icon-wrapper'], styles[`toast-${props.toastStatus}`])}>
        <Icon icon={props.icon} size='m' />
      </div>
      <div className={styles['toast-message']}>
        <p className='body-semi-bold'>{props.toastMessage}</p>
      </div>
    </div>
  );
};

export default Toast;
