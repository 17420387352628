import type * as Types from '../../../../core/apollo/__generated__/alertsGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetMyUnresolvedNotificationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMyUnresolvedNotificationsQuery = {
  unresolvedNotifications: Array<{
    alertId: number;
    color: Types.AlertColor;
    description?: string | null;
    floor?: string | null;
    floorType: Types.FloorType;
    id: number;
    isDeleted: boolean;
    isRead: boolean;
    name: string;
    siteId: number;
    systemGroupId?: number | null;
    systemId: number;
    systemName: string;
    systemType: Types.SystemType;
    triggeredAt: Date;
    updatedAt: Date;
    userId: string;
  }>;
};

export type ReadNotificationsMutationVariables = Types.Exact<{
  notificationIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type ReadNotificationsMutation = { setStatusForNotifications: boolean };

export type ReadAllNotificationsMutationVariables = Types.Exact<{ [key: string]: never }>;

export type ReadAllNotificationsMutation = { setStatusForAllNotifications: boolean };

export const GetMyUnresolvedNotificationsDocument = gql`
  query GetMyUnresolvedNotifications {
    unresolvedNotifications {
      alertId
      color
      description
      floor
      floorType
      id
      isDeleted
      isRead
      name
      siteId
      systemGroupId
      systemId
      systemName
      systemType
      triggeredAt
      updatedAt
      userId
    }
  }
`;
export const ReadNotificationsDocument = gql`
  mutation ReadNotifications($notificationIds: [Int!]!) {
    setStatusForNotifications(notificationIds: $notificationIds, status: READ)
  }
`;
export const ReadAllNotificationsDocument = gql`
  mutation ReadAllNotifications {
    setStatusForAllNotifications(status: READ)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetMyUnresolvedNotifications(
      variables?: GetMyUnresolvedNotificationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetMyUnresolvedNotificationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMyUnresolvedNotificationsQuery>(GetMyUnresolvedNotificationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetMyUnresolvedNotifications',
        'query',
        variables
      );
    },
    ReadNotifications(
      variables: ReadNotificationsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<ReadNotificationsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReadNotificationsMutation>(ReadNotificationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ReadNotifications',
        'mutation',
        variables
      );
    },
    ReadAllNotifications(
      variables?: ReadAllNotificationsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<ReadAllNotificationsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReadAllNotificationsMutation>(ReadAllNotificationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ReadAllNotifications',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
