import type * as Types from '../../../../core/apollo/__generated__/usersGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetDepartmentListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetDepartmentListQuery = {
  departmentsOverview: Array<{
    id: string;
    name: string;
    organizationId: number;
    regions: Array<number>;
    sites: Array<number>;
    systems: Array<number>;
    usersCount: number;
    siteFeatures: {
      ADR: Array<number>;
      ALERTS: Array<number>;
      BILLING: Array<number>;
      LEASES: Array<number>;
      SCHEDULING: Array<number>;
      SPACE_MANAGEMENT: Array<number>;
      UTILITIES: Array<number>;
      BENCHMARKING: Array<number>;
    };
  }>;
};

export type DeleteDepartmentMutationVariables = Types.Exact<{
  departmentId: Types.Scalars['String']['input'];
}>;

export type DeleteDepartmentMutation = { deleteDepartment: boolean };

export type CreateDepartmentMutationVariables = Types.Exact<{
  departmentInput: Types.DepartmentInput;
}>;

export type CreateDepartmentMutation = { createDepartment: string };

export type UpdateDepartmentMutationVariables = Types.Exact<{
  departmentInput: Types.DepartmentInput;
  departmentId: Types.Scalars['String']['input'];
}>;

export type UpdateDepartmentMutation = { updateDepartment: boolean };

export type GetDepartmentByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type GetDepartmentByIdQuery = {
  department: {
    id: string;
    name: string;
    organizationId: number;
    regions: Array<number>;
    sites: Array<number>;
    systems: Array<number>;
    siteFeatures: {
      ADR: Array<number>;
      ALERTS: Array<number>;
      BILLING: Array<number>;
      LEASES: Array<number>;
      SCHEDULING: Array<number>;
      SPACE_MANAGEMENT: Array<number>;
      UTILITIES: Array<number>;
      BENCHMARKING: Array<number>;
    };
  };
};

export const GetDepartmentListDocument = gql`
  query GetDepartmentList {
    departmentsOverview {
      id
      name
      organizationId
      regions
      sites
      systems
      usersCount
      siteFeatures {
        ADR
        ALERTS
        BILLING
        LEASES
        SCHEDULING
        SPACE_MANAGEMENT
        UTILITIES
        BENCHMARKING
      }
    }
  }
`;
export const DeleteDepartmentDocument = gql`
  mutation DeleteDepartment($departmentId: String!) {
    deleteDepartment(departmentId: $departmentId)
  }
`;
export const CreateDepartmentDocument = gql`
  mutation CreateDepartment($departmentInput: DepartmentInput!) {
    createDepartment(departmentInput: $departmentInput)
  }
`;
export const UpdateDepartmentDocument = gql`
  mutation UpdateDepartment($departmentInput: DepartmentInput!, $departmentId: String!) {
    updateDepartment(departmentInput: $departmentInput, departmentId: $departmentId)
  }
`;
export const GetDepartmentByIdDocument = gql`
  query GetDepartmentById($id: String!) {
    department(id: $id) {
      id
      name
      organizationId
      regions
      sites
      systems
      siteFeatures {
        ADR
        ALERTS
        BILLING
        LEASES
        SCHEDULING
        SPACE_MANAGEMENT
        UTILITIES
        BENCHMARKING
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetDepartmentList(
      variables?: GetDepartmentListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetDepartmentListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDepartmentListQuery>(GetDepartmentListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetDepartmentList',
        'query',
        variables
      );
    },
    DeleteDepartment(
      variables: DeleteDepartmentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteDepartmentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteDepartmentMutation>(DeleteDepartmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteDepartment',
        'mutation',
        variables
      );
    },
    CreateDepartment(
      variables: CreateDepartmentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateDepartmentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateDepartmentMutation>(CreateDepartmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateDepartment',
        'mutation',
        variables
      );
    },
    UpdateDepartment(
      variables: UpdateDepartmentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateDepartmentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateDepartmentMutation>(UpdateDepartmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateDepartment',
        'mutation',
        variables
      );
    },
    GetDepartmentById(
      variables: GetDepartmentByIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetDepartmentByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDepartmentByIdQuery>(GetDepartmentByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetDepartmentById',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
