import type * as Types from '../../../../core/apollo/__generated__/utilityGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetUtilityIntegrationEntitiesToConnectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUtilityIntegrationEntitiesToConnectQuery = {
  getUtilityMetersToConnect: Array<{
    meterUID: string;
    authorizationUID: string;
    type: Types.UtilityServiceTypes;
    utility: string;
    name?: string | null;
    authorizationCreatedBy: string;
    authorizationCreatedAt: Date;
    integrationType: Types.MeterIntegrationType;
  }>;
};

export type GetUtilityIntegrationConnectionsQueryVariables = Types.Exact<{
  filters: Types.IntegrationFiltersInput;
}>;

export type GetUtilityIntegrationConnectionsQuery = {
  getMeterIntegrations: Array<
    | {
        __typename: 'ConedOruMeterIntegration';
        authorizationUID: string;
        userId: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt?: Date | null;
        expiry?: Date | null;
        status: boolean;
        id: number;
        meterId?: string | null;
        name?: string | null;
        organizationId?: number | null;
        type: Types.MeterIntegrationType;
        utility: string;
        meterType: Types.UtilityServiceTypes;
      }
    | { __typename: 'DRMeterIntegration' }
    | {
        __typename: 'EnergyStarMeterIntegration';
        id: number;
        energyStarMeterType: Types.MeterType;
        type: Types.MeterIntegrationType;
        siteId: number;
        meterId?: string | null;
        name?: string | null;
        organizationId?: number | null;
        propertyId: number;
        propertyName: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt?: Date | null;
        expiry?: Date | null;
        status: boolean;
      }
    | {
        __typename: 'UtilityAPIMeterIntegration';
        authorizationUID: string;
        userId: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt?: Date | null;
        expiry?: Date | null;
        status: boolean;
        historicalCollection: boolean;
        id: number;
        meterId?: string | null;
        monitorMeterPrepayValues?: Types.MonitorMeterPrepayValues | null;
        monitoringFrequency: Types.MonitorMeterFrequencyValues;
        name?: string | null;
        organizationId?: number | null;
        type: Types.MeterIntegrationType;
        utility: string;
        meterType: Types.UtilityServiceTypes;
      }
  >;
};

export type DeleteUtilityIntegrationConnectionMutationVariables = Types.Exact<{
  meterId: Types.Scalars['Int']['input'];
}>;

export type DeleteUtilityIntegrationConnectionMutation = { stopIntegration: boolean };

export type SetupUtilityIntegrationConnectionMutationVariables = Types.Exact<{
  createMeterIntegrationInput: Types.MeterIntegrationInput;
}>;

export type SetupUtilityIntegrationConnectionMutation = { setupIntegration: { id: number } };

export type EnergyStar_GetUtilityIntegrationEntitiesToConnectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EnergyStar_GetUtilityIntegrationEntitiesToConnectQuery = {
  energyStarProperties: Array<{ propertyId: number; propertyName: string; cityName: string }>;
};

export type EnergyStar_SetupUtilityIntegrationConnectionMutationVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  energyStarPropertyId: Types.Scalars['Int']['input'];
}>;

export type EnergyStar_SetupUtilityIntegrationConnectionMutation = { connectEnergyStar: boolean };

export type EnergyStar_DeleteUtilityIntegrationConnectionMutationVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  energyStarPropertyId: Types.Scalars['Int']['input'];
}>;

export type EnergyStar_DeleteUtilityIntegrationConnectionMutation = { connectEnergyStar: boolean };

export type EnergyStar_UpdateUtilityIntegrationConnectionMutationVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  energyStarPropertyId: Types.Scalars['Int']['input'];
}>;

export type EnergyStar_UpdateUtilityIntegrationConnectionMutation = { connectEnergyStar: boolean };

export type GetUtilityAuthorizationsQueryVariables = Types.Exact<{
  integrationType: Types.MeterIntegrationType;
}>;

export type GetUtilityAuthorizationsQuery = {
  getUtilityAuthorizations: Array<{
    __typename: 'UtilityAuthorization';
    authorizationUID: string;
    connectedItems: number;
    createdAt: Date;
    expiry?: Date | null;
    name?: string | null;
    status: boolean;
    type: Types.MeterIntegrationType;
    userId: string;
    utility: string;
  }>;
};

export type DeleteAuthorizationMutationVariables = Types.Exact<{
  authorizationUID: Types.Scalars['String']['input'];
  type: Types.MeterIntegrationType;
}>;

export type DeleteAuthorizationMutation = { deleteAuthorization: boolean };

export type RefreshAuthorizationMutationVariables = Types.Exact<{
  authorizationUID: Types.Scalars['String']['input'];
  type: Types.MeterIntegrationType;
}>;

export type RefreshAuthorizationMutation = { refreshAuthorization: boolean };

export type EditAuthorizationMutationVariables = Types.Exact<{
  authorizationUID: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
  type: Types.MeterIntegrationType;
}>;

export type EditAuthorizationMutation = { editAuthorization: boolean };

export const GetUtilityIntegrationEntitiesToConnectDocument = gql`
  query GetUtilityIntegrationEntitiesToConnect {
    getUtilityMetersToConnect {
      meterUID
      authorizationUID
      type
      utility
      name
      authorizationCreatedBy
      authorizationCreatedAt
      integrationType
    }
  }
`;
export const GetUtilityIntegrationConnectionsDocument = gql`
  query GetUtilityIntegrationConnections($filters: IntegrationFiltersInput!) {
    getMeterIntegrations(filters: $filters) {
      __typename
      ... on UtilityAPIMeterIntegration {
        authorizationUID
        userId
        createdAt
        updatedAt
        deletedAt
        expiry
        status
        historicalCollection
        id
        meterId
        monitorMeterPrepayValues
        monitoringFrequency
        name
        organizationId
        type
        utility
        meterType
      }
      ... on ConedOruMeterIntegration {
        authorizationUID
        userId
        createdAt
        updatedAt
        deletedAt
        expiry
        status
        id
        meterId
        name
        organizationId
        type
        utility
        meterType
      }
      ... on EnergyStarMeterIntegration {
        id
        energyStarMeterType
        type
        siteId
        meterId
        name
        organizationId
        propertyId
        propertyName
        createdAt
        updatedAt
        deletedAt
        expiry
        status
      }
    }
  }
`;
export const DeleteUtilityIntegrationConnectionDocument = gql`
  mutation DeleteUtilityIntegrationConnection($meterId: Int!) {
    stopIntegration(meterId: $meterId)
  }
`;
export const SetupUtilityIntegrationConnectionDocument = gql`
  mutation SetupUtilityIntegrationConnection($createMeterIntegrationInput: MeterIntegrationInput!) {
    setupIntegration(createMeterIntegrationInput: $createMeterIntegrationInput) {
      id
    }
  }
`;
export const EnergyStar_GetUtilityIntegrationEntitiesToConnectDocument = gql`
  query EnergyStar_GetUtilityIntegrationEntitiesToConnect {
    energyStarProperties {
      propertyId
      propertyName
      cityName
    }
  }
`;
export const EnergyStar_SetupUtilityIntegrationConnectionDocument = gql`
  mutation EnergyStar_SetupUtilityIntegrationConnection($siteId: Int!, $energyStarPropertyId: Int!) {
    connectEnergyStar(siteId: $siteId, energyStarPropertyId: $energyStarPropertyId, refresh: false, disconnect: false)
  }
`;
export const EnergyStar_DeleteUtilityIntegrationConnectionDocument = gql`
  mutation EnergyStar_DeleteUtilityIntegrationConnection($siteId: Int!, $energyStarPropertyId: Int!) {
    connectEnergyStar(siteId: $siteId, energyStarPropertyId: $energyStarPropertyId, refresh: false, disconnect: true)
  }
`;
export const EnergyStar_UpdateUtilityIntegrationConnectionDocument = gql`
  mutation EnergyStar_UpdateUtilityIntegrationConnection($siteId: Int!, $energyStarPropertyId: Int!) {
    connectEnergyStar(siteId: $siteId, energyStarPropertyId: $energyStarPropertyId, refresh: true, disconnect: false)
  }
`;
export const GetUtilityAuthorizationsDocument = gql`
  query GetUtilityAuthorizations($integrationType: MeterIntegrationType!) {
    getUtilityAuthorizations(type: $integrationType) {
      __typename
      ... on UtilityAuthorization {
        authorizationUID
        connectedItems
        createdAt
        expiry
        name
        status
        type
        userId
        utility
      }
    }
  }
`;
export const DeleteAuthorizationDocument = gql`
  mutation DeleteAuthorization($authorizationUID: String!, $type: MeterIntegrationType!) {
    deleteAuthorization(authorizationUID: $authorizationUID, type: $type)
  }
`;
export const RefreshAuthorizationDocument = gql`
  mutation RefreshAuthorization($authorizationUID: String!, $type: MeterIntegrationType!) {
    refreshAuthorization(authorizationUID: $authorizationUID, type: $type)
  }
`;
export const EditAuthorizationDocument = gql`
  mutation EditAuthorization($authorizationUID: String!, $name: String!, $type: MeterIntegrationType!) {
    editAuthorization(authorizationUID: $authorizationUID, name: $name, type: $type)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetUtilityIntegrationEntitiesToConnect(
      variables?: GetUtilityIntegrationEntitiesToConnectQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUtilityIntegrationEntitiesToConnectQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUtilityIntegrationEntitiesToConnectQuery>(
            GetUtilityIntegrationEntitiesToConnectDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetUtilityIntegrationEntitiesToConnect',
        'query',
        variables
      );
    },
    GetUtilityIntegrationConnections(
      variables: GetUtilityIntegrationConnectionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUtilityIntegrationConnectionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUtilityIntegrationConnectionsQuery>(GetUtilityIntegrationConnectionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetUtilityIntegrationConnections',
        'query',
        variables
      );
    },
    DeleteUtilityIntegrationConnection(
      variables: DeleteUtilityIntegrationConnectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteUtilityIntegrationConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteUtilityIntegrationConnectionMutation>(
            DeleteUtilityIntegrationConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'DeleteUtilityIntegrationConnection',
        'mutation',
        variables
      );
    },
    SetupUtilityIntegrationConnection(
      variables: SetupUtilityIntegrationConnectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<SetupUtilityIntegrationConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetupUtilityIntegrationConnectionMutation>(
            SetupUtilityIntegrationConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'SetupUtilityIntegrationConnection',
        'mutation',
        variables
      );
    },
    EnergyStar_GetUtilityIntegrationEntitiesToConnect(
      variables?: EnergyStar_GetUtilityIntegrationEntitiesToConnectQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EnergyStar_GetUtilityIntegrationEntitiesToConnectQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EnergyStar_GetUtilityIntegrationEntitiesToConnectQuery>(
            EnergyStar_GetUtilityIntegrationEntitiesToConnectDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'EnergyStar_GetUtilityIntegrationEntitiesToConnect',
        'query',
        variables
      );
    },
    EnergyStar_SetupUtilityIntegrationConnection(
      variables: EnergyStar_SetupUtilityIntegrationConnectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EnergyStar_SetupUtilityIntegrationConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EnergyStar_SetupUtilityIntegrationConnectionMutation>(
            EnergyStar_SetupUtilityIntegrationConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'EnergyStar_SetupUtilityIntegrationConnection',
        'mutation',
        variables
      );
    },
    EnergyStar_DeleteUtilityIntegrationConnection(
      variables: EnergyStar_DeleteUtilityIntegrationConnectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EnergyStar_DeleteUtilityIntegrationConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EnergyStar_DeleteUtilityIntegrationConnectionMutation>(
            EnergyStar_DeleteUtilityIntegrationConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'EnergyStar_DeleteUtilityIntegrationConnection',
        'mutation',
        variables
      );
    },
    EnergyStar_UpdateUtilityIntegrationConnection(
      variables: EnergyStar_UpdateUtilityIntegrationConnectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EnergyStar_UpdateUtilityIntegrationConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EnergyStar_UpdateUtilityIntegrationConnectionMutation>(
            EnergyStar_UpdateUtilityIntegrationConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'EnergyStar_UpdateUtilityIntegrationConnection',
        'mutation',
        variables
      );
    },
    GetUtilityAuthorizations(
      variables: GetUtilityAuthorizationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUtilityAuthorizationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUtilityAuthorizationsQuery>(GetUtilityAuthorizationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetUtilityAuthorizations',
        'query',
        variables
      );
    },
    DeleteAuthorization(
      variables: DeleteAuthorizationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteAuthorizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteAuthorizationMutation>(DeleteAuthorizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteAuthorization',
        'mutation',
        variables
      );
    },
    RefreshAuthorization(
      variables: RefreshAuthorizationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<RefreshAuthorizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RefreshAuthorizationMutation>(RefreshAuthorizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RefreshAuthorization',
        'mutation',
        variables
      );
    },
    EditAuthorization(
      variables: EditAuthorizationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EditAuthorizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EditAuthorizationMutation>(EditAuthorizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'EditAuthorization',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
