import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import OverviewPerSiteLayout from 'src/fragments/layouts/OverviewPerSiteLayout';
import { useChargingStationsContextProvider } from 'src/logic/systems/useChargingStationsContext';
import { CircularLoader } from 'src/shared/components/Loader/Loader';

export function ChargingStationOverviewPerSiteLayout() {
  const siteId = useNumberParam('siteId');

  const stationsContext = useChargingStationsContextProvider(siteId);

  const tabNames = useMemo(() => {
    return ['Dashboard', 'Insights', 'Trend', 'Heatmap', 'Schedule', 'Activity', 'Stations'];
  }, []);

  return (
    <OverviewPerSiteLayout title='EV Chargers' tabsNames={tabNames}>
      {stationsContext.isLoading ? (
        <CircularLoader />
      ) : (
        <stationsContext.Provider>
          <Outlet />
        </stationsContext.Provider>
      )}
    </OverviewPerSiteLayout>
  );
}
