import _ from 'lodash';

export function tryParseHoursValue(value: string): number | undefined {
  if (!value) {
    return undefined;
  }

  const hourRegex = /^(0?[0-9]|1[0-9]|2[0-3])$/;
  const amPmRegex = /^(0?[1-9]|1[0-2])(:[0-5][0-9])?\s?([AP]M)$/i;

  const matchAmPm = value.match(amPmRegex);
  if (matchAmPm) {
    const hour = parseInt(matchAmPm[1]);
    const isPM = matchAmPm[3].toLowerCase() === 'pm';
    const convertedHour = isPM ? (hour === 12 ? 12 : hour + 12) : hour === 12 ? 0 : hour;
    return convertedHour;
  }

  const matchHour = value.match(hourRegex);
  if (matchHour) {
    const hour = parseInt(matchHour[0]);
    return hour;
  }

  return undefined;
}

export function formatToAmPm(hour: number): string {
  if (hour === 0 || hour === 24 || hour < 0 || hour > 24) {
    return '12:00 AM';
  } else if (hour === 12) {
    return '12:00 PM';
  } else if (hour < 12) {
    return `${_.padStart(hour.toString(), 2, '0')}:00 AM`;
  } else {
    const formattedHour = hour - 12;
    return `${_.padStart(formattedHour.toString(), 2, '0')}:00 PM`;
  }
}
