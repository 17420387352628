import classNames from 'classnames';
import Slider from 'rc-slider';

import { NumberRange } from 'src/interfaces';

import styles from './RangeSlider.module.scss';
import 'rc-slider/assets/index.css';

export interface RangeSliderProps {
  min: number;
  max: number;
  value: NumberRange;
  onChange: (v: NumberRange) => void;
  step?: number;
  reverse?: boolean;
  pushable?: boolean;
  disabled?: boolean;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step = 1,
  reverse = false,
  onChange,
  value,
  pushable = false,
  disabled,
}) => {
  return (
    <Slider
      range={true}
      tabIndex={[-1, -1]}
      min={min}
      max={max}
      step={step}
      className={classNames(styles['range-slider'], {
        [styles['disabled']]: disabled,
      })}
      allowCross={false}
      pushable={pushable}
      reverse={reverse}
      onChange={(s) => onChange(s as NumberRange)}
      value={value}
      disabled={disabled}
    />
  );
};
