import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { UNIT } from 'src/constants';
import {
  FanType,
  FilterAlarmType,
  ModeType,
  OccupancyType,
  SpaceType,
  OnlineStatus,
  SystemPackageAltc24Prog,
  SystemStatus,
} from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import { selectUserRole } from 'src/core/store/global/globalSlice';
import { useAppDispatch } from 'src/core/store/hooks';
import { packageFanDisplayValue, packageModeDisplayValue, packageSpaceDisplayValue, SystemSequence } from 'src/enums';
import { Button } from 'src/shared/components/Button/Button';
import ConfirmationMessageModal from 'src/shared/components/ConfirmationMessageModal/ConfirmationMessageModal';
import { NumericControls } from 'src/shared/components/NumericControls/NumericControls';
import SystemAlertTag, { hasAlert } from 'src/shared/components/Tag/SystemAlertTag';
import GaugeChart from 'src/shared/components/charts/GaugeChart';
import StripedChart from 'src/shared/components/charts/ProgressBarChart/StripedChart';
import {
  CardLayout,
  UniversalAdditionalBlock,
  UniversalCard,
  UniversalChart,
  UniversalDataList,
  UniversalHeader,
  UniversalMainProperty,
  UniversalSetpoint,
} from 'src/shared/containers/UniversalCard/UniversalCard';

import OverrideScheduleProfileForSystemModal from '../../../scheduler/modal/OverrideScheduleProfileForSystemModal/OverrideScheduleProfileForSystemModal';
import GenericSystemHeader from '../../components/containers/GenericSystemHeader/GenericSystemHeader';
import LayoutWithSettingsAndAlarm from '../../components/containers/LayoutWithSettingsAndAlarm/LayoutWithSettingsAndAlarm';
import PackageFanSelector from '../../components/containers/PackageFanSelector/PackageFanSelector';
import PackageModeSelector from '../../components/containers/PackageModeSelector/PackageModeSelector';
import PackageOccupancySelector from '../../components/containers/PackageOccupancySelector/PackageOccupancySelector';
import PackagedSystemSettingsModal from '../../components/containers/PackagedSystemSettingsModal/PackagedSystemSettingsModal';
import SequenceSelector from '../../components/containers/SequenceSelector/SequenceSelector';
import { PackageAltc24ProgSystemCard } from '../../gql/getSystemsForSite.resources.gql';
import { AnySystemSpecificFields, IPackagedSystemSettings } from '../../interface';
import {
  changePackagedSystemSettingsForSingleSystem,
  changePackageFanForSingleSystem,
  changePackageModeForSingleSystem,
  changePackageSetpointForSingleSystem,
  changeSequenceForSingleSystem,
  packageSystemResetFilter,
} from '../../systemsActions';
import { selectedSystemById, updateSystem, updateSystemSetpoint } from '../../systemsSlice';
import { isDRTypeSequence } from '../../utils/system.utils';

import styles from './PackageAltc24ProgCard.module.scss';

export interface PackageAltc24ProgCardProps {
  systemId: number;
  layout: CardLayout;
  timezone?: string | null;
}

const PackageAltc24ProgCard: React.FC<PackageAltc24ProgCardProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentUserRole = useSelector(selectUserRole);
  const system = useSelector(selectedSystemById<PackageAltc24ProgSystemCard>(props.systemId));
  const [isPackagedSystemSettingsModalOpen, setPackagedSystemSettingsModalOpen] = useState(false);
  const [isResetFilterConfirmationModalOpen, setResetFilterConfirmationModalOpen] = useState(false);
  const systemSettings = useRef<IPackagedSystemSettings>();

  if (!system) {
    return <p>Cannot find system with id {props.systemId}</p>;
  }

  const { setpointCool, setpointHeat } = system;
  systemSettings.current = { setpointCool, setpointHeat };

  const systemAutoControl =
    isDRTypeSequence(system.sequence.id) || system.schedulerProfile?.underSchedulerControl === true;

  const disabledBySettings =
    system.sequence.id === SystemSequence.Local ||
    system.onlineStatus === OnlineStatus.OFFLINE ||
    system.onlineStatus === OnlineStatus.NOT_CONFIGURED ||
    currentUserRole === UserRoles.MANAGER;

  const disabled = disabledBySettings || systemAutoControl;
  const disabledResetFilter = system.replaceFilter !== FilterAlarmType.ALARM || disabledBySettings;

  // Alarms are supported by AHU systems
  const hideAlarm = false;

  const isDualSetpointPageMode =
    system.model.coolingStages !== 0 && system.model.heatingStages !== 0 && props?.layout === CardLayout.horizontal;

  function updatePackageSetpoint(value: number, mode: ModeType): void {
    const payload: Partial<SystemPackageAltc24Prog> = {};
    if (mode === ModeType.COOL) {
      payload.setpointCool = value;
    } else if (mode === ModeType.HEAT) {
      payload.setpointHeat = value;
    }
    dispatch(updateSystem([props.systemId, payload as AnySystemSpecificFields]));
  }

  function updatePackageSetpointOnRemote(value: number, mode: ModeType): Promise<void> {
    const payload: IPackagedSystemSettings = {
      setpointCool: mode === ModeType.COOL ? value : undefined,
      setpointHeat: mode === ModeType.HEAT ? value : undefined,
    };
    return dispatch(changePackagedSystemSettingsForSingleSystem(props.systemId, payload));
  }

  function updateSetpoint(value: number): void {
    dispatch(updateSystemSetpoint([props.systemId, value]));
  }

  function updateSetpointOnRemote(value: number): Promise<void> {
    return dispatch(changePackageSetpointForSingleSystem(props.systemId, value));
  }

  function openSettings(): void {
    setPackagedSystemSettingsModalOpen(true);
  }

  function resetFilter(): void {
    setResetFilterConfirmationModalOpen(false);
    dispatch(packageSystemResetFilter(props.systemId));
  }

  function changeSequence(value: SystemSequence): Promise<void> {
    return dispatch(changeSequenceForSingleSystem(props.systemId, value));
  }

  function changeMode(value: ModeType): Promise<void> {
    return dispatch(changePackageModeForSingleSystem(props.systemId, value));
  }

  function changeFan(value: FanType): Promise<void> {
    return dispatch(changePackageFanForSingleSystem(props.systemId, value));
  }

  function changeSpaceOccupancy(space: SpaceType): Promise<void> {
    const payload: IPackagedSystemSettings = { space };
    return dispatch(changePackagedSystemSettingsForSingleSystem(props.systemId, payload));
  }

  function changePackagedSystemSettings(settings: IPackagedSystemSettings): void {
    dispatch(changePackagedSystemSettingsForSingleSystem(props.systemId, settings));
    setPackagedSystemSettingsModalOpen(false);
  }

  const packagedSystemSettingsModal = (
    <PackagedSystemSettingsModal
      isOpen={isPackagedSystemSettingsModalOpen}
      title={system.name}
      system={system}
      acceptAction={changePackagedSystemSettings}
      cancelAction={() => setPackagedSystemSettingsModalOpen(false)}
      disabledSettings={systemAutoControl}
      settings={{
        setpointMax: system.setpointMax,
        setpointMin: system.setpointMin,
        focusAlgorithm: system.occupancyType !== OccupancyType.MANUAL ? system.focusAlgorithm : null,
        setLock: system.setLock,
        occupiedSetpointCool: system.occupiedSetpointCool,
        occupiedSetpointHeat: system.occupiedSetpointHeat,
        unoccupiedSetpointCool: system.unoccupiedSetpointCool,
        unoccupiedSetpointHeat: system.unoccupiedSetpointHeat,
      }}
    />
  );

  const resetFilterConfirmationModal = (
    <ConfirmationMessageModal
      isOpen={isResetFilterConfirmationModalOpen}
      type='confirm'
      title='Are you sure want to reset the filter alarm?'
      actionButtonLabel='Yes'
      cancelButtonLabel='No'
      onCancel={() => setResetFilterConfirmationModalOpen(false)}
      onAccept={resetFilter}
    />
  );

  const layout = props.layout;

  return (
    <UniversalCard layout={layout}>
      <UniversalHeader assumeHeader>
        <GenericSystemHeader
          systemId={props.systemId}
          name={system.name}
          floor={system.floor}
          floorType={system.floor_type}
          status={system.status}
          lastStatusChangedDatetime={system.statusUpdatedAt}
          onlineStatus={system.onlineStatus}
          timezone={props.timezone}
        />
      </UniversalHeader>
      <UniversalMainProperty horizontalLabel='Sequence' moveToHeaderWhenVertical>
        <div className={props?.layout === CardLayout.horizontal ? styles['row'] : ''}>
          <SequenceSelector
            hideLabel
            disabled={disabledBySettings}
            sequenceId={system.sequence.id}
            systemType={system.type}
            schedulerProfile={system.schedulerProfile}
            onChange={changeSequence}
            layout={layout}
          >
            <OverrideScheduleProfileForSystemModal
              systemId={system.id}
              isScheduled={systemAutoControl}
              isOverridden={!!system.overriddenSchedulerProfile}
            />
          </SequenceSelector>
        </div>
      </UniversalMainProperty>
      {hasAlert(system) && (
        <UniversalMainProperty order={-1} moveToHeaderWhenVertical hideWhenList hideWhenHorizontal>
          <SystemAlertTag systemId={system.id} onlineStatus={system.onlineStatus} />
        </UniversalMainProperty>
      )}
      <UniversalChart moveToLeftWhenHorizontal>
        <LayoutWithSettingsAndAlarm
          siteId={system.siteId}
          disabledSettings={disabledBySettings}
          hideAlarm={hideAlarm}
          hideSettings={false}
          onSettingsClick={openSettings}
          layout={layout}
          systemId={system.id}
        >
          {layout === CardLayout.list && (
            <StripedChart
              min={system.setpointMin}
              max={system.setpointMax}
              targetValue={system.setpoint}
              currentValue={system.spaceTemperature}
              disabled={disabled}
              unit={UNIT.FAHRENHEIT}
              gradient={
                system.packageSystemModeEffective === ModeType.HEAT
                  ? 'heat'
                  : system.packageSystemModeEffective === ModeType.COOL
                    ? 'cool'
                    : system.status === SystemStatus.OFF || system.packageSystemModeEffective === ModeType.OFF
                      ? 'disabled'
                      : 'default'
              }
            />
          )}
          {layout !== CardLayout.list && (
            <GaugeChart
              min={system.setpointMin}
              max={system.setpointMax}
              targetValue={system.setpoint}
              currentValue={system.spaceTemperature}
              amountOfSteps={system.setpointMax - system.setpointMin}
              disabled={disabled}
              gradient={
                system.packageSystemModeEffective === ModeType.HEAT
                  ? 'heat'
                  : system.packageSystemModeEffective === ModeType.COOL
                    ? 'cool'
                    : system.status === SystemStatus.OFF || system.packageSystemModeEffective === ModeType.OFF
                      ? 'disabled'
                      : 'default'
              }
            >
              <>
                <p className={styles['space-temperature']}>
                  <span className={classnames(styles['value'], 'body-semi-bold')}>{system.spaceTemperature}</span>
                  <span className={styles['metric']}> {UNIT.FAHRENHEIT}</span>
                </p>
                <p className='text-center body-small color-secondary'>
                  Space
                  <br />
                  Temperature
                </p>
              </>
            </GaugeChart>
          )}
          {packagedSystemSettingsModal}
        </LayoutWithSettingsAndAlarm>
      </UniversalChart>
      <UniversalMainProperty horizontalLabel={`Setpoint (${UNIT.FAHRENHEIT})`}>
        <div className={props?.layout === CardLayout.horizontal ? styles['row'] : ''}>
          {isDualSetpointPageMode && (
            <span>
              <UniversalSetpoint verticalLabel='Heat' forceShowVerticalLabel>
                <NumericControls
                  min={system.setpointMin}
                  max={system.setpointMax}
                  disabled={disabled || system.packageSystemModeEffective !== ModeType.HEAT}
                  onChange={(value) => updatePackageSetpoint(value, ModeType.HEAT)}
                  onSubmit={(value) => updatePackageSetpointOnRemote(value, ModeType.HEAT)}
                  value={system.setpointHeat}
                  className={props?.layout === CardLayout.horizontal ? styles['numeric-control'] : undefined}
                />
              </UniversalSetpoint>
            </span>
          )}
          <span style={{ marginLeft: isDualSetpointPageMode ? '15px' : undefined }}>
            <UniversalSetpoint
              verticalLabel={isDualSetpointPageMode ? 'Cool' : `Setpoint (${UNIT.FAHRENHEIT})`}
              forceShowVerticalLabel={isDualSetpointPageMode}
            >
              <NumericControls
                min={system.setpointMin}
                max={system.setpointMax}
                disabled={
                  disabled ||
                  (props?.layout === CardLayout.horizontal &&
                    (system.packageSystemModeEffective === ModeType.HEAT ||
                      system.packageSystemModeEffective === ModeType.FAN_ONLY))
                }
                onChange={(value) =>
                  isDualSetpointPageMode ? updatePackageSetpoint(value, ModeType.COOL) : updateSetpoint(value)
                }
                onSubmit={(value) =>
                  isDualSetpointPageMode
                    ? updatePackageSetpointOnRemote(value, ModeType.COOL)
                    : updateSetpointOnRemote(value)
                }
                value={isDualSetpointPageMode ? system.setpointCool : system.setpoint}
                className={props?.layout === CardLayout.horizontal ? styles['numeric-control'] : undefined}
              />
            </UniversalSetpoint>
          </span>
        </div>
      </UniversalMainProperty>
      {props?.layout === CardLayout.horizontal && (
        <UniversalMainProperty horizontalLabel='Mode'>
          <div className={styles['row']}>
            <span style={{ width: '50%' }}>
              <PackageModeSelector
                systemType={system.type}
                hideLabel
                disabled={disabled}
                mode={system.packageSystemMode}
                modeEffective={system.packageSystemModeEffective}
                onChange={changeMode}
                heatingStages={system.model.heatingStages}
                coolingStages={system.model.coolingStages}
              />
            </span>
            <UniversalDataList className={styles['custom-universal-block']}>
              <dt>Effective</dt>
              <dd>
                <div className='d-flex align-items-center justify-content-end'>
                  <span className='pl-4'>{packageModeDisplayValue[system.packageSystemModeEffective]}</span>
                </div>
              </dd>
            </UniversalDataList>
          </div>
        </UniversalMainProperty>
      )}
      {props?.layout === CardLayout.horizontal && (
        <UniversalMainProperty horizontalLabel='Fan'>
          <div className={styles['row']}>
            <span style={{ width: '50%' }}>
              <PackageFanSelector
                systemType={system.type}
                hideLabel
                disabled={disabled}
                fan={system.fan}
                onChange={changeFan}
                fanLevels={system.model.fanLevels}
              />
            </span>
            <UniversalDataList className={styles['custom-universal-block']}>
              <dt>Effective</dt>
              <dd>
                <div className='d-flex align-items-center justify-content-end'>
                  <span className='pl-4'>{packageFanDisplayValue[system.fanEffective]}</span>
                </div>
              </dd>
            </UniversalDataList>
          </div>
        </UniversalMainProperty>
      )}
      {props?.layout === CardLayout.horizontal && (
        <UniversalMainProperty horizontalLabel='Occupancy'>
          <div className={styles['row']}>
            <span style={{ width: '50%' }}>
              <PackageOccupancySelector
                systemType={system.type}
                hideLabel
                disabled={disabled || system.occupancyType !== OccupancyType.MANUAL}
                space={system.space}
                onChange={changeSpaceOccupancy}
              />
            </span>
            <UniversalDataList className={styles['custom-universal-block']}>
              <dt>Effective</dt>
              <dd>
                <div className='d-flex align-items-center justify-content-end'>
                  <span className='pl-8'>{packageSpaceDisplayValue[system.spaceEffective]}</span>
                </div>
              </dd>
            </UniversalDataList>
          </div>
        </UniversalMainProperty>
      )}

      <UniversalAdditionalBlock moveToLeftWhenHorizontal>
        {system.model.filter && (
          <div className='d-flex align-items-center justify-content-center'>
            {resetFilterConfirmationModal}
            <Button
              className={classnames('mb-8', styles['reset-filter-button'])}
              disabled={disabledResetFilter}
              onClick={() => setResetFilterConfirmationModalOpen(true)}
              shape='rounded'
              icon='filter'
              variant={disabledResetFilter ? 'secondary' : 'flat'}
              size='big'
              iconSize='l'
              label={disabledResetFilter ? 'Filter' : 'Reset Filter'}
              labelPosition='bottom'
              showIndicator={!disabledResetFilter}
            />
          </div>
        )}
      </UniversalAdditionalBlock>
    </UniversalCard>
  );
};

export default PackageAltc24ProgCard;
