import classNames from 'classnames';
import { clamp, isNil } from 'lodash';

import { toPercentage } from 'src/cdk/utils/toPercentage';
import { Icon } from 'src/shared/components/Icon/Icon';
import { WithUnit } from 'src/shared/components/Unit/WithUnit';

import styles from './RemoteSequenceIndicator.module.scss';

export interface RemoteModeProps {
  /**
   * Current outside air temperature
   */
  oatTemperature?: number | null;
  /**
   * Average Temperature inside specific building
   */
  averageSpaceTemperature?: number | null;
  /**
   * Clamp to that value if current temperature is lower than minTemperature
   * Arrow stays at the bottom of a line
   * Measured in "°F" (Fahrenheit)
   */
  minTemperature?: number;
  /**
   * Clamp to that value if current temperature is higher than maxTemperature
   * Arrow stays at the top of a line
   * Measured in "°F" (Fahrenheit)
   */
  maxTemperature?: number;
}

const RemoteSequenceIndicator: React.FC<RemoteModeProps> = ({
  oatTemperature,
  averageSpaceTemperature,
  minTemperature = 0,
  maxTemperature = 100,
}) => {
  function clampedTemperatureValue(temperature?: number | null): number | undefined {
    if (isNil(temperature)) {
      return undefined;
    }
    return clamp(temperature, minTemperature, maxTemperature);
  }

  const oatClampedTemperature = clampedTemperatureValue(oatTemperature);
  const spaceClampedTemperature = clampedTemperatureValue(averageSpaceTemperature);

  function calcTemperaturePosition(temperature?: number): React.CSSProperties | undefined {
    if (isNil(temperature)) {
      return undefined;
    }

    return {
      bottom: `${toPercentage(minTemperature, maxTemperature, temperature)}%`,
    };
  }

  return (
    <div className={styles['indicator-container']}>
      <div>
        <p className={classNames('body-small', 'color-secondary')}>Outside Air Temp.</p>
        <h5>
          <WithUnit fahrenheit value={oatTemperature} />
        </h5>
      </div>
      <div className={styles['indicator']}>
        <Icon
          icon='dropdown-right'
          className={classNames(styles['icon-left'], {
            [styles['hide-icon']]: isNil(oatClampedTemperature),
          })}
          style={calcTemperaturePosition(oatClampedTemperature)}
        />
        <div className={styles['indicator-scale']} />
        <Icon
          icon='dropdown-left'
          className={classNames(styles['icon-right'], {
            [styles['hide-icon']]: isNil(spaceClampedTemperature),
          })}
          style={calcTemperaturePosition(spaceClampedTemperature)}
        />
      </div>
      <div>
        <p className={classNames('body-small', 'color-secondary')}>Space Temp.</p>
        <h5>
          <WithUnit fahrenheit value={averageSpaceTemperature} />
        </h5>
      </div>
    </div>
  );
};

export default RemoteSequenceIndicator;
