import Inputmask from 'inputmask';

import { FloorUseTypes } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { SupportedIcon } from 'src/shared/components/Icon/gen/suported-icons';
import { SelectWithSearchProps } from 'src/shared/components/Select/SelectWithSearch/SelectWithSearch';
import { MultiSelectProps, OptionItem, SelectProps } from 'src/shared/components/Select/interface';

type Deps = ReadonlyArray<unknown>;

export interface FieldFactory<F, T, D extends Deps> {
  field: F;
  builder(field: F, ...arg: D): F;
  deps(form?: T): D;
}

export function rebuild<T, C extends BaseField, D extends Deps>(
  field: C,
  deps: FieldFactory<C, T, D>['deps'],
  builder: FieldFactory<C, T, D>['builder']
): FieldFactory<C, T, D> {
  return {
    field,
    deps,
    builder,
  };
}

export enum FormFieldType {
  UIElement = 'ui-element',
  CustomField = 'custom-field',
  Text = 'string',
  Phone = 'phone',
  TextArea = 'textarea',
  Checkbox = 'checkbox',
  Date = 'date',
  Select = 'select',
  Multiselect = 'multiselect',
  Floor = 'floor',
}

// TODO: guarantee type validation and remove this function :)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extendFieldOrFactory<T extends ObjectCopier, F = T | FieldFactory<T, any, Deps>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: any,
  options: Parameters<T['copyWith']>[0]
): F {
  if (isFieldFactory(field)) {
    return {
      ...field,
      field: field.field.copyWith(options),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  }
  return field.copyWith(options);
}

abstract class ObjectCopier {
  copyWith(modifyObject: Omit<{ [P in keyof this]?: this[P] }, 'copyWith'>): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...modifyObject });
  }
}

interface FieldConstructor<T> {
  new (): T;
}

function asFactory<I extends BaseField, Options = Omit<I, 'type' | 'copyWith'>>(ClassRef: FieldConstructor<I>) {
  return function (options: Options): I {
    const instance = new ClassRef();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return instance.copyWith(options as any);
  };
}

export abstract class BaseField<C = unknown> extends ObjectCopier {
  abstract readonly type: FormFieldType;
  readonly fieldsetClassName?: string;
  readonly label?: string;
  readonly description?: string;
  readonly hint?: string;
  readonly required?: boolean;
  readonly hideOptional?: boolean;
  /**
   * By default defines if field is visible or hidden by Validation Schema
   * Example: `Joi.forbidden()`
   *
   * If set to true - field will be hidden, ignoring Validation Schema
   */
  readonly hidden?: boolean;
  readonly disabled?: boolean;
  readonly extras?: C;
  readonly gridArea?: string;
  /**
   * This field has higher priority than `gridArea` and will be used if it is defined.
   * If true, the field will be placed into the available slot of CSS Grid.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-flow
   */
  readonly autoFlow?: boolean;
}

export class UIElementField extends BaseField {
  static with = asFactory(UIElementField);

  readonly type = FormFieldType.UIElement;
  readonly render!: React.FC<FieldProps<UIElementField>>;
}

export class CustomField extends BaseField {
  static with = asFactory(CustomField);

  readonly type = FormFieldType.CustomField;
  readonly render!: React.FC<FieldProps<CustomField>>;
}

export class DateField extends BaseField {
  static with = asFactory(DateField);

  readonly type = FormFieldType.Date;
  readonly min?: Date;
  readonly max?: Date;
  readonly disabledDays?: {
    from?: Date | undefined;
    to?: Date | undefined;
  }[];
}

export class TextField extends BaseField {
  static with = asFactory(TextField);

  readonly type = FormFieldType.Text;
  readonly placeholder?: string;
  readonly icon?: SupportedIcon;
  readonly inputMask?: Inputmask.Instance;
  readonly onFocus?: (value: string) => string;
}

export class MoneyField extends TextField {
  static with = asFactory(MoneyField);

  placeholder?: string | undefined = '0';

  readonly inputMask?: Inputmask.Instance = new Inputmask('currency', {
    showMaskOnHover: false,
    outputFormat: '9+.9+',
    autoUnmask: true,
    digitsOptional: true,
  });

  readonly icon?: SupportedIcon = 'dollar';
}

export class PercentField extends TextField {
  static with = asFactory(PercentField);

  placeholder?: string | undefined = '0';

  // Even if we use percent, we still need to use currency mask,
  // so users will be able to provide decimal values
  // and UX will be stable across fields.
  readonly inputMask?: Inputmask.Instance = new Inputmask('currency', {
    showMaskOnHover: false,
    outputFormat: '9+.9+',
    autoUnmask: true,
    digitsOptional: true,
  });

  readonly icon?: SupportedIcon = 'percent';
}

export class EmailField extends TextField {
  static with = asFactory(EmailField);

  readonly label?: string = 'Email';
  readonly placeholder?: string = 'example@gmail.com';
  // TODO: add mask?
}

export class PhoneField extends BaseField {
  static with = asFactory(PhoneField);

  readonly label?: string = 'Phone';
  readonly placeholder?: string = '(XXX) XXX-XXXX';
  readonly type = FormFieldType.Phone;
}

export class TextAreaField extends BaseField {
  static with = asFactory(TextAreaField);

  readonly type = FormFieldType.TextArea;
  readonly placeholder?: string;
  readonly icon?: SupportedIcon;
}

export class CheckboxField<T = unknown> extends BaseField {
  static with = asFactory(CheckboxField);

  readonly type = FormFieldType.Checkbox;
  mapValueToFlag?(value: T): boolean {
    return value as boolean;
  }
  mapFlagToValue?(flag: boolean): T {
    return flag as T;
  }
}

export class SelectField<O> extends BaseField {
  static with = asFactory(SelectField);

  readonly placeholder?: string;
  readonly type = FormFieldType.Select;
  readonly options?: OptionItem<O>[];
  readonly renderOption?: SelectProps<O>['renderOption'];
  readonly entityName?: SelectWithSearchProps<O>['entityName'];
  readonly onAddNewItem?: SelectWithSearchProps<O>['onAddNewItem'];
  /**
   * By default if only one option is available, it will be selected automatically.
   */
  readonly disableAutoSelect?: boolean;
}

export class MultiSelectField<O> extends BaseField {
  static with = asFactory(MultiSelectField);

  readonly placeholder?: string;
  readonly type = FormFieldType.Multiselect;
  readonly options?: OptionItem<O>[];
  readonly renderOption?: SelectProps<O>['renderOption'];
  readonly transformSelectedOption?: MultiSelectProps<O>['transformSelectedOption'];
  /**
   * By default if only one option is available, it will be selected automatically.
   */
  readonly disableAutoSelect?: boolean;
  readonly showSelectedValuesInTooltip?: boolean;
  readonly showSelectedValuesInCounter?: boolean;
}

export class FloorField extends BaseField {
  static with = asFactory(FloorField);

  readonly type = FormFieldType.Floor;
  readonly siteId?: number;
  readonly deviceType?: FloorUseTypes | undefined;
}

type SupportedFields = {
  [FormFieldType.Text]: TextField;
  [FormFieldType.Select]: SelectField<unknown>;
  [FormFieldType.Multiselect]: MultiSelectField<unknown>;
  [FormFieldType.UIElement]: UIElementField;
  [FormFieldType.CustomField]: CustomField;
  [FormFieldType.Text]: TextField;
  [FormFieldType.TextArea]: TextAreaField;
  [FormFieldType.Checkbox]: CheckboxField;
  [FormFieldType.Date]: DateField;
  [FormFieldType.Floor]: FloorField;
  [FormFieldType.Phone]: PhoneField;
};

type Field = SupportedFields[FormFieldType];

export type FieldOrFactory<T> = Field | FieldFactory<Field, T, Deps>;

export function getType(field: FieldOrFactory<unknown>): FormFieldType {
  if (field instanceof BaseField) {
    return field.type;
  }

  return field.field.type;
}

export function isFieldFactory<T>(field: FieldOrFactory<T>): field is FieldFactory<Field, T, Deps> {
  return !(field instanceof BaseField);
}

export function isField<T>(field: FieldOrFactory<T>): field is Field {
  return field instanceof BaseField;
}

/**
 * UI Schema defines how the form will be rendered:
 * - order of fields
 * - size and position
 * - labels
 * - descriptions (will be rendered as (i) icon with tooltip)
 * - classNames
 *
 * Other properties can be added if needed, the main idea is to split UiSchema and DataSchema, because usually they are not related
 *
 * @template T refers to the data type.
 * @template F refers to the UI type/schema of the form, which usually extends partial of <T> and may have extra fields.
 */
export interface UiSchema<T, F extends Partial<T> = Partial<T> & { [key: string]: unknown }> {
  gridTemplateColumns: string;
  gridRowGap?: string;
  gridColGap?: string;
  gridTemplateAreas: string;
  fields: UISchemaFields<F>;
}

export type UISchemaFields<F> = {
  [key in keyof F]: FieldOrFactory<F>;
};

/**
 * Represents tuple of [field name, field data] for each field in the UI form.
 * It is needed to optimise the rendering of the form.
 */
export type UISchemaFieldsPairs<F> = [Extract<keyof F, string>, UISchemaFields<F>[Extract<keyof F, string>]][];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface FieldProps<F, T = any> {
  error?: string;
  value: T;
  onChange: (value: T) => void;
  fieldName: string;
  field: F;
  children?: React.ReactNode;
  fieldsetClassName?: string;
}

export type FieldComponentMap = {
  [K in keyof SupportedFields]: React.FC<FieldProps<SupportedFields[K]>>;
};

export type AnyFieldComponent = React.FC<FieldProps<Field>>;
export type AnyFieldProps = FieldProps<Field>;
