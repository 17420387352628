import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useOnMount } from 'src/cdk/hooks/useOnMount';
import { METRIC_SYMBOL } from 'src/constants';
import { MetricsMeasurementType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { MeasurementToTitle } from 'src/enums';
import { Button } from 'src/shared/components/Button/Button';
import { CheckboxGroup } from 'src/shared/components/CheckboxGroup/CheckboxGroup';
import { Icon } from 'src/shared/components/Icon/Icon';
import { Modal } from 'src/shared/components/Popup';
import { RenderUnit } from 'src/shared/components/Unit/RenderUnit';

import { MetricWithData } from '../../../interface';

import styles from './SelectMetricsModal.module.scss';

export interface SelectMetricsModalProps {
  metrics: MetricWithData[];
  cancelAction: () => void;
  updateMetrics: (metrics: MetricWithData[]) => void;
}

const SelectMetricsModal: React.FC<SelectMetricsModalProps> = (props) => {
  const [metricsGroups, setMetricsGroups] = useState<Record<string, MetricWithData[]>>({});
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  useOnMount(() => {
    const metrics: Record<string, MetricWithData[]> = _.groupBy(
      _.orderBy(props.metrics, ['measurement', 'name'], ['asc', 'asc']),
      'measurement'
    );
    setMetricsGroups(metrics);
  });

  useEffect(() => {
    updateSelectedGroups();
  }, [metricsGroups]);

  const isMetricGroupSelected = (measurement: string) => metricsGroups[measurement].some((value) => value.selected);

  const isMetricsGroupDisabled = (measurement: string) => {
    return !(selectedGroups.includes(measurement) || selectedGroups.length < 2);
  };

  const toggleMetricsGroup = (measurement: string) => {
    const isGroupSelected = isMetricGroupSelected(measurement);
    setMetricsGroups({
      ...metricsGroups,
      [measurement]: metricsGroups[measurement].map((metric) => ({
        ...metric,
        selected: !isGroupSelected,
      })),
    });
  };

  const toggleMetric = (measurement: string, id: number) => {
    setMetricsGroups({
      ...metricsGroups,
      [measurement]: metricsGroups[measurement].map((metric) =>
        metric.id === id ? { ...metric, selected: !metric.selected } : metric
      ),
    });
  };

  const updateSelectedGroups = () => {
    const measurements = _.keys(metricsGroups).filter(isMetricGroupSelected);
    setSelectedGroups(measurements);
  };

  return (
    <Modal
      isOpen={true}
      acceptAction={() => props.updateMetrics(_.concat(..._.values(metricsGroups)))}
      cancelAction={() => props.cancelAction()}
      titleText='Analytics Chart Legend'
      subtitleText=''
      actionButtonLabel='Apply'
      cancelButtonLabel='Cancel'
    >
      {Object.keys(metricsGroups).length >= 3 && (
        <div className={classNames('card', 'flat', styles['tooltip-content'])}>
          <Icon icon='warning-alt' size='s' color='secondary' className={styles['icon']} />
          Two units of measurement can be selected at a time.
        </div>
      )}

      {_.keys(metricsGroups).map((measurement) => (
        <div key={measurement} className={styles['metrics-group']}>
          <div className={styles['metrics-group-title']}>
            <span>
              {`${MeasurementToTitle[measurement as MetricsMeasurementType]} `}
              <span className='color-secondary'>(</span>
              <RenderUnit unit={METRIC_SYMBOL[measurement as MetricsMeasurementType]} dimmed />
              <span className='color-secondary'>)</span>
            </span>
            <Button
              label={isMetricGroupSelected(measurement) ? 'Deselect All' : 'Select All'}
              className={styles['button']}
              onClick={() => toggleMetricsGroup(measurement)}
              disabled={isMetricsGroupDisabled(measurement)}
              shape='rect'
              type='button'
              variant='flat'
              title={
                isMetricsGroupDisabled(measurement)
                  ? 'To select these metrics please deselect one of selected groups.'
                  : ''
              }
            />
          </div>
          <div className={styles['metrics-group-checkboxes']}>
            <CheckboxGroup
              items={metricsGroups[measurement]}
              disableGroup={isMetricsGroupDisabled(measurement)}
              onClick={(id) => toggleMetric(measurement, id)}
            />
          </div>
        </div>
      ))}
    </Modal>
  );
};

export default SelectMetricsModal;
