import classNames from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/core/store/hooks';
import { Button } from 'src/shared/components/Button/Button';
import { SelectWithSearchAndForm } from 'src/shared/components/Select';

import { ConfigAndFilter, FilterConfiguration } from '../filter.interface';
import { deleteFilterFromRemote, putFilterOnRemote } from '../filtersActions';
import { selectFilterConfigurationsForPage } from '../filtersSlice';

import ConfigForm from './ConfigForm';
import styles from './Filter.module.scss';

interface SidebarProps {
  storageKey: string;
  configAndFilter: ConfigAndFilter;
  setConfigAndFilter: (value: ConfigAndFilter) => void;
}

const ConfigSelector: React.FC<SidebarProps> = ({ storageKey, configAndFilter, setConfigAndFilter }) => {
  const dispatch = useAppDispatch();

  const configurations = useSelector(selectFilterConfigurationsForPage(storageKey));

  function onSelectConfig(key?: string): void {
    const filters = _.find(configurations, { key: key })?.config.filters;
    setConfigAndFilter({
      filterConfigId: key,
      filters: filters ?? {},
    });
  }

  async function handlePutConfig(item: FilterConfiguration): Promise<void> {
    const createdFilter = await dispatch(putFilterOnRemote(item));
    setConfigAndFilter({
      filterConfigId: createdFilter.key,
      filters: configAndFilter.filters,
    });
  }

  async function handleDeleteConfig(item: FilterConfiguration): Promise<void> {
    await dispatch(deleteFilterFromRemote(item));
    if (item.key === configAndFilter.filterConfigId) {
      setConfigAndFilter({
        filterConfigId: undefined,
        filters: configAndFilter.filters,
      });
    }
  }

  // If config is selected and persisted values are not equal to current values in form
  const persistedFilter = _.find(configurations, { key: configAndFilter.filterConfigId });
  const changed = persistedFilter && !_.isEqual(persistedFilter?.config.filters, configAndFilter.filters);

  return (
    <div className='d-flex gap-12'>
      <SelectWithSearchAndForm
        className={classNames(styles['select'], 'flex-1')}
        disabled={false}
        onClick={onSelectConfig}
        options={configurations}
        value={configAndFilter.filterConfigId}
        entityName='Configuration'
        FormContent={({ searchValue, option, onChange }) => (
          <ConfigForm id={option?.key} name={searchValue || option?.displayValue} onChange={onChange} />
        )}
        onDelete={(option) => handleDeleteConfig(option as FilterConfiguration)}
        onPut={function (formData: Record<string, string>): void | Promise<void> {
          return handlePutConfig({
            key: formData.id,
            displayValue: formData.configName,
            config: {
              storageKey: storageKey,
              filters: configAndFilter.filters,
            },
          });
        }}
      />
      <Button
        className='flex-none'
        disabled={!changed}
        shape='rounded'
        icon='refresh'
        iconSize='s'
        showIndicator={changed}
        isSmallIndicator
        title={changed ? 'Update configuration' : 'Change filters to update configuration'}
        onClick={() => {
          if (!configAndFilter.filterConfigId || !persistedFilter) {
            console.error('Filter must be selected');
            return;
          }
          handlePutConfig({
            key: persistedFilter.key,
            displayValue: persistedFilter.displayValue,
            config: {
              storageKey: storageKey,
              filters: configAndFilter.filters,
            },
          });
        }}
      />
    </div>
  );
};

export default ConfigSelector;
