import {
  addDays,
  addMonths,
  addYears,
  format,
  lastDayOfMonth,
  lastDayOfQuarter,
  lastDayOfYear,
  previousSunday,
  startOfQuarter,
  subDays,
  subHours,
  subMinutes,
  subQuarters,
} from 'date-fns';

import { roundToLocalDays } from 'src/cdk/utils/roundToDays';
import roundToHours from 'src/cdk/utils/roundToHours';
import { roundToNearestMinutes } from 'src/cdk/utils/roundToNearestMinutes';
import { DATE_FORMAT, NUM_OF_HOURS_IN_DAY } from 'src/constants';

export interface ButtonActionConfig {
  getTimeRange: () => [Date, Date];
  getPrevTimeRange: () => [Date, Date];
}

export enum DatePickerRangeType {
  CUSTOM = 'Custom',
  LAST_SIX_HOURS = 'Last 6 Hours',
  LAST_TWELVE_HOURS = 'Last 12 Hours',
  LAST_24_HOURS = 'Last 24 Hours',
  LAST_72_HOURS = 'Last 72 Hours',
  THIS_WEEK = 'This Week',
  LAST_WEEK = 'Last Week',
  LAST_30_DAYS = 'Last 30 days',
  THIS_MONTH = 'This Month',
  LAST_MONTH = 'Last Month',
  LAST_QUARTER = 'Last Quarter',
  THIS_YEAR = 'This Year',
  LAST_YEAR = 'Last Year',
  LAST_6_MONTHS = 'Last 6 Months',
  LAST_12_MONTHS = 'Last 12 Months',
}

export const ButtonsLayoutOrder: DatePickerRangeType[] = [
  DatePickerRangeType.LAST_SIX_HOURS,
  DatePickerRangeType.THIS_WEEK,
  DatePickerRangeType.LAST_TWELVE_HOURS,
  DatePickerRangeType.LAST_WEEK,
  DatePickerRangeType.LAST_24_HOURS,
  DatePickerRangeType.THIS_MONTH,
  DatePickerRangeType.LAST_72_HOURS,
  DatePickerRangeType.LAST_MONTH,
];

export const MonthPickerQuickRanges = [
  DatePickerRangeType.LAST_MONTH,
  DatePickerRangeType.LAST_QUARTER,
  DatePickerRangeType.LAST_6_MONTHS,
  DatePickerRangeType.LAST_12_MONTHS,
  DatePickerRangeType.THIS_YEAR,
  DatePickerRangeType.LAST_YEAR,
];

export function formatDate(date: Date): string {
  return format(date, DATE_FORMAT.DATE_SHORT);
}

export function formatMonthYear(date: Date): string {
  return format(date, DATE_FORMAT.MONTH_YEAR_SHORT);
}

/**
 * Round current datetime to the nearest 15 minutes, then subtract 15 minutes
 * It is needed after change of metrics calculation according to "start of interval" logic
 */
function last15Minutes(): Date {
  return subMinutes(roundToNearestMinutes(new Date(), { nearestTo: 15, roundingMethod: 'floor' }), 15);
}

export const buttonsConfig: Record<DatePickerRangeType, ButtonActionConfig | undefined> = {
  [DatePickerRangeType.LAST_SIX_HOURS]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      return [subHours(roundToHours(now), 6), now];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_SIX_HOURS]!.getTimeRange();
      return [subHours(subMinutes(from, 15), 6), subHours(subMinutes(to, 15), 6)];
    },
  },
  [DatePickerRangeType.THIS_WEEK]: {
    getTimeRange: (): [Date, Date] => {
      const to = last15Minutes();
      let from;
      if (to.getDay()) {
        const prevSunday = previousSunday(to);
        from = roundToLocalDays(prevSunday);
      } else {
        from = roundToLocalDays(to);
      }

      return [from, to];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.THIS_WEEK]!.getTimeRange();
      return [subDays(from, 7), subDays(to, 7)];
    },
  },
  [DatePickerRangeType.LAST_TWELVE_HOURS]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      return [subHours(roundToHours(now), 12), now];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_TWELVE_HOURS]!.getTimeRange();
      return [subHours(subMinutes(from, 15), 12), subHours(subMinutes(to, 15), 12)];
    },
  },
  [DatePickerRangeType.LAST_WEEK]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      const prevSunday = previousSunday(now);
      let from, to;
      if (!now.getDay()) {
        from = roundToLocalDays(prevSunday);
        to = roundToLocalDays(subDays(now, 1), true);
      } else {
        const sundayBefore = previousSunday(prevSunday);
        from = roundToLocalDays(sundayBefore);
        to = roundToLocalDays(subDays(prevSunday, 1), true);
      }

      return [from, to];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_WEEK]!.getTimeRange();
      return [subDays(from, 7), subDays(to, 7)];
    },
  },
  [DatePickerRangeType.LAST_24_HOURS]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      return [subHours(roundToHours(now), NUM_OF_HOURS_IN_DAY), now];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_24_HOURS]!.getTimeRange();
      return [subHours(subMinutes(from, 15), 24), subHours(subMinutes(to, 15), 24)];
    },
  },
  [DatePickerRangeType.LAST_30_DAYS]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      const thirtyDaysAgo = addDays(now, -30);
      return [roundToLocalDays(thirtyDaysAgo), roundToLocalDays(now, true)];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_30_DAYS]!.getTimeRange();
      return [addDays(from, -30), addDays(to, -30)];
    },
  },
  [DatePickerRangeType.THIS_MONTH]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      const startOfMonth = new Date().setDate(1);
      return [roundToLocalDays(startOfMonth), now];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.THIS_MONTH]!.getTimeRange();
      const prevFrom = addMonths(from, -1);
      let prevTo;
      if (lastDayOfMonth(prevFrom).getDate() >= to.getDate()) {
        prevTo = addMonths(to, -1);
      } else {
        prevTo = roundToLocalDays(lastDayOfMonth(prevFrom), true);
      }
      return [prevFrom, prevTo];
    },
  },
  [DatePickerRangeType.LAST_72_HOURS]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      return [subHours(roundToHours(now), 72), now];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_72_HOURS]!.getTimeRange();
      return [subHours(subMinutes(from, 15), 72), subHours(subMinutes(to, 15), 72)];
    },
  },
  [DatePickerRangeType.LAST_MONTH]: {
    getTimeRange: (): [Date, Date] => {
      const from = roundToLocalDays(addMonths(new Date().setDate(1), -1));
      const to = roundToLocalDays(lastDayOfMonth(from), true);
      return [from, to];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_MONTH]!.getTimeRange();
      return [addMonths(from, -1), addMonths(to, -1)];
    },
  },
  [DatePickerRangeType.THIS_YEAR]: {
    getTimeRange: (): [Date, Date] => {
      const now = last15Minutes();
      const firstDayOfTheYear = new Date();
      firstDayOfTheYear.setDate(1);
      firstDayOfTheYear.setMonth(0);
      return [roundToLocalDays(firstDayOfTheYear), now];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.THIS_YEAR]!.getTimeRange();
      return [addYears(from, -1), addYears(to, -1)];
    },
  },
  [DatePickerRangeType.LAST_YEAR]: {
    getTimeRange: (): [Date, Date] => {
      const firstDayOfTheYear = new Date();
      firstDayOfTheYear.setDate(1);
      firstDayOfTheYear.setMonth(0);
      firstDayOfTheYear.setFullYear(firstDayOfTheYear.getFullYear() - 1);
      return [roundToLocalDays(firstDayOfTheYear), roundToLocalDays(lastDayOfYear(firstDayOfTheYear), true)];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_YEAR]!.getTimeRange();
      return [addYears(from, -1), addYears(to, -1)];
    },
  },
  [DatePickerRangeType.LAST_6_MONTHS]: {
    getTimeRange: (): [Date, Date] => {
      const to = roundToLocalDays(new Date().setDate(0), true);
      return [roundToLocalDays(addMonths(to, -5).setDate(1)), to];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_6_MONTHS]!.getTimeRange();
      return [addMonths(from, -6), addMonths(to, -6)];
    },
  },
  [DatePickerRangeType.LAST_12_MONTHS]: {
    getTimeRange: (): [Date, Date] => {
      const to = roundToLocalDays(new Date().setDate(0), true);
      return [roundToLocalDays(addMonths(to, -11).setDate(1)), to];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const [from, to] = buttonsConfig[DatePickerRangeType.LAST_12_MONTHS]!.getTimeRange();
      return [addMonths(from, -12), addMonths(to, -12)];
    },
  },
  [DatePickerRangeType.LAST_QUARTER]: {
    getTimeRange: (): [Date, Date] => {
      const from = startOfQuarter(subQuarters(new Date(), 1));
      const to = lastDayOfQuarter(subQuarters(new Date(), 1));
      return [from, to];
    },
    getPrevTimeRange: (): [Date, Date] => {
      const from = startOfQuarter(subQuarters(new Date(), 2));
      const to = lastDayOfQuarter(subQuarters(new Date(), 2));
      return [from, to];
    },
  },
  [DatePickerRangeType.CUSTOM]: undefined,
};
