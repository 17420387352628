import classNames from 'classnames';
import React, { useState } from 'react';

import { useOnMount } from 'src/cdk/hooks/useOnMount';

import { Icon } from '../Icon/Icon';
import { SupportedIcon } from '../Icon/gen/suported-icons';

import styles from './TextArea.module.scss';

export interface TextAreaProps {
  placeholder?: string;
  icon?: SupportedIcon;

  /**
   * @param {boolean} [disabled=false]
   */
  disabled?: boolean;
  value: string;
  className?: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onSubmit?: (element: HTMLTextAreaElement) => void;
  /**
   * @param {string} [id] - id allows to bond label and corresponding textarea field inside a form
   */
  id?: string;
  tabIndex?: number;
  /**
   * @param {boolean} [autoFocus=false]
   */
  autoFocus?: boolean;
  maxLength?: number;
  onEscape?: (element: HTMLTextAreaElement) => void;
  rows?: number;
  cols?: number;
  name?: string;
  required?: boolean;
  /**
   * @default true
   */
  adjustHeightWhileTyping?: boolean;
  /**
   * @default true
   */
  trimOnBlur?: boolean;
  hasError?: boolean;
  /**
   * Set 0 to disable
   * @default 3 lines
   */
  maxAmountOfLines?: number;
}

export const TextArea: React.FC<TextAreaProps> = ({
  placeholder,
  icon,
  disabled = false,
  value,
  className,
  onChange,
  onBlur,
  onSubmit,
  id,
  tabIndex,
  autoFocus = false,
  maxLength,
  onEscape,
  rows,
  cols,
  required,
  name,
  adjustHeightWhileTyping = true,
  trimOnBlur = true,
  hasError,
  maxAmountOfLines = 3,
}) => {
  const [touched, setTouched] = useState(false);

  useOnMount(() => {
    const textElement = document.getElementById(id || 'commentId');
    if (textElement) {
      adjustHeight(textElement);
    }
  });

  function adjustHeight(el: HTMLElement) {
    if (adjustHeightWhileTyping) {
      el.style.height = el.scrollHeight > el.clientHeight ? el.scrollHeight + 'px' : '48px';
    }
  }

  return (
    <div className={classNames(styles['textarea-wrapper'], className)}>
      {icon && <Icon icon={icon} size='s' className={styles['icon']} />}
      <textarea
        tabIndex={tabIndex}
        className={classNames('input break-word break-white-space', styles['textarea'], {
          [styles['with-icon']]: !!icon,
          touched: touched,
          ['has-error']: hasError,
        })}
        name={name}
        id={id || 'commentId'}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => {
          e.preventDefault();
          if (maxAmountOfLines && e.target.value.split('\n').length > maxAmountOfLines) {
            return;
          }
          adjustHeight(e.currentTarget);
          onChange(e.target.value);
        }}
        onBlur={(e) => {
          e.preventDefault();
          if (!touched) {
            setTouched(true);
          }
          const result = trimOnBlur ? e.target.value.trim() : e.target.value;
          if (trimOnBlur) {
            onChange(result);
          }
          onBlur && onBlur(result);
        }}
        onKeyDown={(e) => {
          adjustHeight(e.currentTarget);
          if (e.key === 'Enter' && !e.shiftKey && onSubmit) {
            onSubmit(e.currentTarget);
          }
          if (e.key === 'Escape' && onEscape) {
            onEscape(e.currentTarget);
          }
        }}
        autoFocus={autoFocus}
        maxLength={maxLength}
        rows={rows || 1}
        cols={cols}
        value={value}
        required={required}
      />
    </div>
  );
};
