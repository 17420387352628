import { ManagedInterval } from './managedInterval';
import { SimpleObservable } from './simpleObservable';

/**
 * Represents managed interval connected to observable to broadcast events.
 * On each time when browser tab is blured/focused - timer is suspended/resumed.
 *
 * Do not forget to execute `onDestroy` method when current object is not used anymore.
 */
export class ObservedInterval<T> {
  private interval: ManagedInterval;
  private observable: SimpleObservable<T>;

  private onBlurCallback = this.pauseOnWindowBlur.bind(this);
  private onFocusCallback = this.resumeOnWindowFocus.bind(this);

  get observableInstance(): SimpleObservable<T> {
    return this.observable;
  }

  /**
   * @param intervalDuration Duration is provided in milliseconds
   * @param callback Callback to execute to get value after each interval
   */
  constructor(intervalDuration: number, callback: () => T) {
    this.observable = new SimpleObservable<T>(callback());
    this.interval = new ManagedInterval(intervalDuration, () => {
      this.observable.updateValue(callback());
    });
    this.connectIntervalTimerToBrowserFocus();
  }

  /**
   * Call that function to cleanup all registered event listeners before object removal
   */
  onDestroy(): void {
    window.removeEventListener('blur', this.pauseOnWindowBlur.bind(this));
    window.removeEventListener('focus', this.resumeOnWindowFocus.bind(this));
  }

  isRunning(): boolean {
    return this.interval.isRunning;
  }

  start(): void {
    this.interval.start();
  }

  stop(): void {
    this.interval.stop();
  }

  private connectIntervalTimerToBrowserFocus(): void {
    window.addEventListener('blur', this.onBlurCallback);
    window.addEventListener('focus', this.onFocusCallback);
  }

  private pauseOnWindowBlur(): void {
    // Clearing interval on window blur
    this.interval.stop();
  }

  private resumeOnWindowFocus(): void {
    // Clearing interval if for some reason it has not been cleared yet
    this.interval.stop();

    if (!this.interval.isRunning) {
      this.interval.start(true);
    }
  }
}
