import { format } from 'date-fns';
import _ from 'lodash';
import React, { useMemo } from 'react';

import { numberWithCommasFormat } from 'src/cdk/formatter/numberFormatter';
import { getTimestampFormat } from 'src/cdk/formatter/relativeTimeFormat';
import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { METRIC_SYMBOL, N_A, UNIT } from 'src/constants';
import { MetricsMeasurementType, MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { toastService } from 'src/core/service/toastService';
import { ColorConfig, MeasurementToTitle } from 'src/enums';
import { DateRangeWithIntervals } from 'src/shared/components/DatePicker/SmartDateRangePicker/DateRangePickerWithIntervals';
import { CustomTooltip } from 'src/shared/components/Popup';
import ComposableChartHeatmapRow, {
  calcHeatmapRowVerticalOffset,
} from 'src/shared/components/charts/ComposableChart/ComposableChartHeatmapRow';
import ComposableChartTooltip from 'src/shared/components/charts/ComposableChart/ComposableChartTooltip';
import ComposableChartWrapper from 'src/shared/components/charts/ComposableChart/ComposableChartWrapper';
import ComposableChartXAxis from 'src/shared/components/charts/ComposableChart/ComposableChartXAxis';
import ComposableTooltipTable, {
  TooltipDataItem,
} from 'src/shared/components/charts/ComposableChart/ComposableTooltipTable';
import { getMaxMinutesForStepFromFilter } from 'src/shared/components/charts/ComposableChart/utils';

import {
  getMetricsDataForSystemWithIntervals,
  ISystemMetricData,
} from '../../../gql/getMetricDataWithIntervals.resources.gql';
import { MetricWithData } from '../../../interface';

interface DataSource {
  metricId: number;
  metricData: ISystemMetricData[];
  isEmpty: boolean;
  xDomain: [Date, Date];
}

interface Source {
  xDomain: Date[];
  usageMetricsData: DataSource[];
  flatData: ISystemMetricData[];
  isEmpty: boolean;
  spaceAllocatedForUsage: number;
}

const DEFAULT_SOURCE: Source = {
  xDomain: [0, 0] as unknown as [Date, Date],
  usageMetricsData: [],
  flatData: [],
  isEmpty: true,
  spaceAllocatedForUsage: 0,
};

interface Props {
  dateTimeFilter: DateRangeWithIntervals;
  timezone: string;
  selectedMetrics: MetricWithData[];
  systems: Array<{ id: number; name: string } | undefined>;
}

/**
 * This component is used to load and render system metrics data
 * Assumed that all metrics in group are only USAGE
 */
const GroupedSystemsMetricsChart: React.FC<Props> = (props) => {
  const metricsMap = _.keyBy(props.selectedMetrics, 'id');
  const systemMap = _.keyBy(props.systems, 'id');

  const maxUsageValue = getMaxMinutesForStepFromFilter(props.dateTimeFilter);

  const { isLoading, response: source = DEFAULT_SOURCE } = useDataFetchOnMountWithDeps(
    async () => {
      // If no metrics were selected - return empty data
      if (_.isEmpty(props.selectedMetrics)) {
        return DEFAULT_SOURCE;
      }

      let response: ISystemMetricData[] = [];

      try {
        response = await getMetricsDataForSystemWithIntervals(
          props.selectedMetrics.map((i) => i.id),
          props.dateTimeFilter,
          props.timezone,
          _.fromPairs(props.selectedMetrics.map((i) => [i.id, i.type]))
        );
      } catch (error) {
        toastService.error('Failed to load metrics data');
        return DEFAULT_SOURCE;
      }

      const dataSources: DataSource[] = _.chain(response)
        .groupBy('systemMetricId')
        .toPairs()
        .orderBy(([metricId]) => metricsMap[metricId].measurement, 'asc')
        .map(([metricId, responseData]) => {
          return {
            metricId: Number(metricId),
            metricData: responseData.flat(),
            isEmpty: responseData.every((i) => i.value === null),
            xDomain: responseData.map((i) => i.timestamp),
          } as DataSource;
        })
        .value();

      // All metrics data within same range will return have same X axis range/values
      // so it is enough to take only first
      const xDomain = _.uniq(_.head(dataSources)?.xDomain ?? []);

      const source: Source = {
        usageMetricsData: dataSources,
        flatData: response,
        xDomain,
        isEmpty: dataSources.every((i) => i.isEmpty),
        spaceAllocatedForUsage: calcHeatmapRowVerticalOffset(dataSources.length),
      };

      return source;
    },
    [props.dateTimeFilter, props.selectedMetrics],
    true
  );

  return (
    <>
      <ComposableChartWrapper
        xDomain={source.xDomain}
        yDomain={[0, 1]}
        height={40}
        isLoading={isLoading}
        isEmpty={source.isEmpty}
        extraSpaceBetweenXAxisAndChart={source.spaceAllocatedForUsage}
        padding={{
          top: 24,
          bottom: 30,
          left: 100,
          right: 0,
        }}
      >
        <ComposableChartXAxis top={source.spaceAllocatedForUsage} />

        {source.usageMetricsData.map((i, index) => (
          <ComposableChartHeatmapRow
            key={i.metricId}
            data={i.metricData}
            maxValue={maxUsageValue}
            styleVariant={metricsMap[i.metricId]?.styleVariant ?? ColorConfig.blueDottedOpaque}
            top={calcHeatmapRowVerticalOffset(index - 1)}
          />
        ))}

        {source.usageMetricsData.map((i, index) => {
          const systemId = metricsMap[i.metricId].systemId;
          const prevSystemId = index === 0 ? -1 : metricsMap[source.usageMetricsData[index - 1].metricId].systemId;
          if (systemId === prevSystemId) {
            return null;
          }
          const system = systemMap[systemId];
          return (
            <foreignObject key={i.metricId} x='0' y={calcHeatmapRowVerticalOffset(index) + 4} width='100' height='15'>
              <CustomTooltip triggerComponent={<p className='one-line-ellipsis caption'>{system?.name ?? N_A}</p>}>
                <p className='caption'>{system?.name ?? N_A}</p>
              </CustomTooltip>
            </foreignObject>
          );
        })}

        <ComposableChartTooltip<ISystemMetricData>
          verticalPadding={source.spaceAllocatedForUsage}
          data={source.flatData}
          buildTooltipContent={(xValue, dataAtPointedIndex) => {
            return (
              <TooltipContent
                metricsMap={metricsMap}
                xValue={xValue}
                step={props.dateTimeFilter.step}
                dataAtPointedIndex={dataAtPointedIndex}
                systemMap={systemMap}
              />
            );
          }}
        />
      </ComposableChartWrapper>
    </>
  );
};

interface TooltipContentProps {
  xValue: Date;
  step: MetricsStep;
  dataAtPointedIndex: ISystemMetricData[];
  metricsMap: _.Dictionary<MetricWithData>;
  systemMap: _.Dictionary<{ id: number; name: string } | undefined>;
}

/**
 * Private component to render tooltip content for current chart
 */
function TooltipContent({ xValue, step, dataAtPointedIndex, metricsMap, systemMap }: TooltipContentProps): JSX.Element {
  const timestampTitle = format(xValue, getTimestampFormat(step));

  const tooltipDataItemsGroupedByMetrics: Array<{
    metricLabel: string;
    unit: UNIT;
    data: TooltipDataItem[];
  }> = useMemo(() => {
    // Group metric by type and prepare data to display in tooltip
    const result: Array<{
      metricLabel: string;
      unit: UNIT;
      data: TooltipDataItem[];
    }> = _.chain(dataAtPointedIndex)
      .groupBy((i) => metricsMap[i.systemMetricId].measurement)
      .toPairs()
      .map(([measurement, items]) => {
        const data = items.reverse().map((i, index) => {
          const metric = metricsMap[i.systemMetricId];

          // If there is no previous metric - then it is first metric in group
          // if there are several metrics under same system - do not duplicate system name
          const previousMetric = index === 0 ? undefined : metricsMap[items[index - 1]?.systemMetricId];
          const systemName =
            previousMetric?.systemId === metric.systemId ? '' : systemMap[metric.systemId]?.name ?? N_A;

          const result: TooltipDataItem = {
            id: metric.id,
            renderAs: 'square',
            styleVariant: metric.styleVariant ?? ColorConfig.whiteSolidTranslucent,
            label: metric.name ?? N_A,
            meta: {
              system: systemName,
            },
            values: {
              sum: numberWithCommasFormat(i.value, metric.precision),
            },
          };

          return result;
        });

        return {
          metricLabel: MeasurementToTitle[measurement as MetricsMeasurementType],
          unit: METRIC_SYMBOL[measurement as MetricsMeasurementType],
          data,
        };
      })
      .value();

    return result;
  }, [dataAtPointedIndex, metricsMap, systemMap]);

  return (
    <div>
      <p className='body-semi-bold'>{timestampTitle}</p>
      {tooltipDataItemsGroupedByMetrics.map((group) => (
        <ComposableTooltipTable
          key={group.metricLabel}
          title='Metric'
          valuesTitle={group.metricLabel}
          precedingColumns={[
            {
              property: 'meta.system',
              title: 'System',
              size: '100px',
            },
          ]}
          items={group.data}
          unit={group.unit}
        />
      ))}
    </div>
  );
}

export default GroupedSystemsMetricsChart;
