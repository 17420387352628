import { gql } from '@apollo/client';

import { SystemStatus } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  ChangeSmartOutletSystemModeMutation,
  ChangeSmartOutletSystemModeMutationVariables,
} from './__generated__/changeSmartOutletSystemMode.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterUpdatingOutletState = Extract<
  ChangeSmartOutletSystemModeMutation['updateSmartOutletSystemMode'],
  { __typename: 'SystemSmartOutlet' }
>;

const CHANGE_SMART_OUTLET_SYSTEM_MODE = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangeSmartOutletSystemMode($systemId: Int!, $mode: SystemStatus!) {
    updateSmartOutletSystemMode(mode: $mode, systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changeSmartOutletSystemMode(
  systemId: number,
  mode: SystemStatus
): Promise<DataAfterUpdatingOutletState | undefined> {
  const result = await resourcesAPI.mutate<
    ChangeSmartOutletSystemModeMutation,
    ChangeSmartOutletSystemModeMutationVariables
  >({
    mutation: CHANGE_SMART_OUTLET_SYSTEM_MODE,
    variables: { systemId, mode },
  });

  const updateResult = result.data?.updateSmartOutletSystemMode as DataAfterUpdatingOutletState;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
