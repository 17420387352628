import { RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { NonTenantUserRoles } from 'src/constants';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import NotFoundPage from 'src/fragments/NotFoundPage/NotFoundPage';
import OverviewPerSiteLayout from 'src/fragments/layouts/OverviewPerSiteLayout';
import Guard from 'src/fragments/layouts/guard/Guard';

const router: RouteObject[] = [
  {
    path: 'alerts',
    element: <Guard roles={NonTenantUserRoles} />,
    children: [
      {
        index: true,
        lazy: asLazyPage(() => import('./page'), import('./skeleton')),
      },
      {
        path: ':siteId',
        element: <OverviewPerSiteLayout title='Alerts Management' tabsNames={['History', 'Alerts']} />,
        errorElement: <ExceptionHandler />,
        children: [
          {
            path: 'alerts',
            lazy: asLazyPage(() => import('./[:siteId]/alerts/page'), import('./[:siteId]/alerts/skeleton')),
          },
          {
            path: 'history',
            lazy: asLazyPage(() => import('./[:siteId]/history/page'), import('./[:siteId]/history/skeleton')),
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: ':siteId/alerts/create',
        lazy: asLazyPage(() => import('./[:siteId]/[:alertId]/page'), import('./[:siteId]/[:alertId]/skeleton')),
      },
      {
        path: ':siteId/alerts/:alertId',
        lazy: asLazyPage(() => import('./[:siteId]/[:alertId]/page'), import('./[:siteId]/[:alertId]/skeleton')),
      },
    ],
  },
];

export default router;
