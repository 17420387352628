import Joi from 'joi';
import _ from 'lodash';

import {
  BaseField,
  FormFieldType,
  UISchemaFields,
  UISchemaFieldsPairs,
  getType,
  isField,
  isFieldFactory,
} from './uiSchemaModel';

export function buildUISchemaFields<T, C extends T>(
  baseSchema: Joi.ObjectSchema<T>,
  fields: UISchemaFields<C>,
  fieldsPresence: Record<string, string>
): UISchemaFieldsPairs<C> {
  const result = convertFieldsToPairsDeep(fields, (fieldPath, fieldData) => {
    const subSchema = extractFieldFromSchema(baseSchema, fieldPath, fieldData);
    return buildUISchemaField(subSchema, _.get(fieldsPresence, fieldPath));
  });

  return result;
}

type OptionalBaseOptions = Parameters<BaseField['copyWith']>['0'];

function convertFieldsToPairsDeep<T>(
  input: UISchemaFields<T>,
  getOptionsFromSchema: (fieldName: string, uiFieldType: FormFieldType) => OptionalBaseOptions
): UISchemaFieldsPairs<T> {
  const resultPairs: UISchemaFieldsPairs<T> = [];

  function traverse(obj: UISchemaFields<T>) {
    for (const key in obj) {
      const value = obj[key];
      if (isField(value)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const optionsFromSchema: any = getOptionsFromSchema(key, getType(value));
        resultPairs.push([
          key,
          value.copyWith({
            ...optionsFromSchema,
            hidden: value.hidden || optionsFromSchema.hidden,
          }),
        ]);
      } else if (isFieldFactory(value)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const optionsFromSchema: any = getOptionsFromSchema(key, getType(value));
        resultPairs.push([
          key,
          {
            ...value,
            field: value.field.copyWith({
              ...optionsFromSchema,
              hidden: value.field.hidden || optionsFromSchema.hidden,
            }),
          },
        ]);
      } else {
        traverse(value as UISchemaFields<T>);
      }
    }
  }

  traverse(input);

  return resultPairs;
}

export function extractFieldFromSchema<F>(
  schema: Joi.Schema<F>,
  fieldPath: string,
  uiFieldType: FormFieldType
): Joi.Schema {
  if (uiFieldType === FormFieldType.UIElement) {
    return Joi.any();
  }

  try {
    return schema.extract(fieldPath);
  } catch (e) {
    console.error(`Cannot extract schema for ${fieldPath}`, e);
    return Joi.forbidden();
  }
}

function buildUISchemaField(schema: Joi.Schema, presence?: string): OptionalBaseOptions {
  // TODO: TBD if needed
  // const meta = _.merge({}, ...schema.$_terms.metas);

  if (_.isEmpty(schema)) {
    return {};
  }

  const label = schema.$_getFlag('label');
  const description = schema.$_getFlag('description');
  const fieldPresence = presence || schema.$_getFlag('presence');

  return {
    ...(label && { label }),
    ...(description && { description }),
    required: fieldPresence === 'required',
    hidden: fieldPresence === 'forbidden',
    // TODO: TBD if needed
    // min: subschema.$_getRule('min'),
    // max: subschema.$_getRule('max'),
    // meta: meta,
  };
}
