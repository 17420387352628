import { gql } from '@apollo/client';

import { FanType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  ChangePackageSystemFanMutation,
  ChangePackageSystemFanMutationVariables,
} from './__generated__/changePackageSystemFan.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterUpdatedPackageFan = Extract<
  ChangePackageSystemFanMutation['updatePackageSystemFan'],
  { __typename: 'SystemPackageAltc24Prog' }
>;

const CHANGE_PACKAGE_SYSTEM_FAN = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangePackageSystemFan($systemId: Int!, $fan: FanType!) {
    updatePackageSystemFan(fan: $fan, systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changePackageSystemFan(
  systemId: number,
  fan: FanType
): Promise<DataAfterUpdatedPackageFan | undefined> {
  const result = await resourcesAPI.mutate<ChangePackageSystemFanMutation, ChangePackageSystemFanMutationVariables>({
    mutation: CHANGE_PACKAGE_SYSTEM_FAN,
    variables: { systemId, fan },
  });

  const updateResult = result.data?.updatePackageSystemFan as DataAfterUpdatedPackageFan;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
