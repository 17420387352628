import classNames from 'classnames';

import { Icon } from 'src/shared/components/Icon/Icon';
import { CustomTooltip } from 'src/shared/components/Popup';

import styles from './Filter.module.scss';

interface Props {
  prefix?: string;

  label: string;

  /**
   * If provided, the chip be deletable
   */
  onDelete?: () => void;

  /**
   * If provided, the chip be deletable
   */
  onClick?: () => void;

  /**
   * @default default
   */
  variant?: 'default' | 'red';

  trimmed?: boolean;
  tooltipContent?: React.ReactNode;
}

export const FilterChip: React.FC<Props> = ({
  prefix,
  label,
  onDelete,
  onClick,
  variant = 'default',
  trimmed,
  tooltipContent,
}) => {
  const chip = (
    <div
      onClick={onClick}
      className={classNames('card flat', styles['filter-chip'], styles['filter-chip-' + variant], {
        'color-primary': variant === 'default',
        'color-error': variant === 'red',
        'with-pointer': onClick,
        [styles['trimmed']]: trimmed,
      })}
    >
      {prefix && <span className='body-extra-small pr-4'>{prefix}</span>}
      <span className={classNames('body-small', styles['chip-value'])}>{label}</span>
      {onDelete && <Icon icon='close' className='ml-8 with-pointer' onClick={onDelete} size='xs' />}
    </div>
  );

  if (!trimmed) {
    return chip;
  }
  return (
    <CustomTooltip lighterBackground triggerComponent={chip}>
      {tooltipContent}
    </CustomTooltip>
  );
};
