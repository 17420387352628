import Joi from 'joi';
import _ from 'lodash';

import { FloorType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';

import { phone } from './phone';

declare module 'joi' {
  interface StringSchema {
    nullable: () => Joi.StringSchema;
  }
  interface NumberSchema {
    nullable: () => Joi.NumberSchema;
  }
  interface Root {
    phone: () => Joi.StringSchema;
    email: () => Joi.StringSchema;
    floor: () => Joi.ObjectSchema;
  }
}

/**
 * This is a custom Joi rule that allows to pass null or empty string to the field.
 * And if the field is empty - it will be converted to null.
 */
const nullable: Joi.ExtensionRule = {
  method() {
    return (this as Joi.StringSchema).optional().empty('').allow(null).default(null);
  },
};

export const J: typeof Joi = Joi.extend(
  {
    type: 'string',
    base: Joi.string(),
    rules: {
      nullable,
    },
  },
  {
    type: 'number',
    base: Joi.number(),
    rules: {
      nullable,
    },
  }
);

J.phone = () =>
  J.string()
    .custom((value, helper) => {
      if (!phone.isValid(value)) {
        return helper.error('not-valid-phone');
      }

      return value;
    })
    .rule({ message: 'Phone number format is invalid' });

J.email = () => J.string().email({ tlds: { allow: false } });

J.floor = () =>
  J.object({
    floor: J.string().required(),
    floorType: J.string()
      .required()
      .valid(..._.values(FloorType)),
  });
