import { curveMonotoneX, curveStep } from '@visx/curve';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import _ from 'lodash';

import { ChartType, ColorConfig } from 'src/enums';

import styles from './ComposableChartLine.module.scss';
import { useChartContext } from './chartContext';

interface Props<T> {
  /**
   * If you need to have chunks - use ComposableChartLineWithChunks instead.
   */
  data?: T[];
  styleVariant: ColorConfig;
  yDomain?: number[];
  /**
   * @default 'ChartType.LINE'
   */
  curveType?: ChartType.LINE | ChartType.STEPPED_LINE;
}

function ComposableChartLine<
  T extends {
    timestamp: Date;
    value?: number | null;
    exists: boolean;
  },
>({ data = [], styleVariant, yDomain, curveType = ChartType.LINE }: React.PropsWithChildren<Props<T>>): JSX.Element {
  const { xScale, yScale, padding } = useChartContext(yDomain);

  const defined = (d: T) => d.exists && _.isNumber(d.value);
  const x = (d: T) => xScale(d.timestamp) || -1;
  const y = (d: T) => (_.isNil(d.value) ? -1 : (yScale(d.value) as number));

  return (
    <Group left={padding.left + xScale.step() / 2} top={padding.top}>
      <LinePath
        // This property will break the line if data is null at some point
        defined={defined}
        data={data}
        x={x}
        y={y}
        className={styles[styleVariant]}
        strokeWidth={2}
        curve={curveType === ChartType.LINE ? curveMonotoneX : curveStep}
      />
    </Group>
  );
}

export default ComposableChartLine;
