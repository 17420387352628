/**
 * This file is a copy from DateFNS v3.2.0 with all dependencies included.
 * @see https://github.com/date-fns/date-fns/blob/main/src/roundToNearestMinutes/index.ts#L65
 */

import { toDate } from 'date-fns';

// TODO: Remove this file when project will be migrated to DateFNS v3.2.0 is released & DateFNS-TZ will be migrated to support v3.+ of DateFNS

type RoundingMethod = 'ceil' | 'floor' | 'round' | 'trunc';

function getRoundingMethod(method: RoundingMethod | undefined) {
  return (number: number) => {
    const round = method ? Math[method] : Math.trunc;
    const result = round(number);
    // Prevent negative zero
    return result === 0 ? 0 : result;
  };
}

/**
 * The generic date constructor. Replicates the Date constructor. Used to build
 * generic functions.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments. Allows to use extensions like [`UTCDate`](https://github.com/date-fns/utc).
 */
interface GenericDateConstructor<DateType extends Date = Date> {
  /**
   * The date constructor. Creates date with the current date and time.
   *
   * @returns The date instance
   */
  new (): DateType;

  /**
   * The date constructor. Creates date with the passed date, number of
   * milliseconds or string to parse.
   *
   * @param value - The date, number of milliseconds or string to parse
   *
   * @returns The date instance
   */
  new (value: Date | number | string): DateType;

  /**
   * The date constructor. Creates date with the passed date values (year,
   * month, etc.) Note that the month is 0-indexed.
   *
   * @param year - The year
   * @param month - The month. Note that the month is 0-indexed.
   * @param date - The day of the month
   * @param hours - The hours
   * @param minutes - The minutes
   * @param seconds - The seconds
   * @param ms - The milliseconds
   *
   * @returns The date instance
   */
  new (
    year: number,
    month: number,
    date?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number
  ): DateType;
}

/**
 * @name constructFrom
 * @category Generic Helpers
 * @summary Constructs a date using the reference date and the value
 *
 * @description
 * The function constructs a new date using the constructor from the reference
 * date and the given value. It helps to build generic functions that accept
 * date extensions.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments. Allows to use extensions like [`UTCDate`](https://github.com/date-fns/utc).
 *
 * @param date - The reference date to take constructor from
 * @param value - The value to create the date
 *
 * @returns Date initialized using the given date and value
 *
 * @example
 * import { constructFrom } from 'date-fns'
 *
 * // A function that clones a date preserving the original type
 * function cloneDate<DateType extends Date(date: DateType): DateType {
 *   return constructFrom(
 *     date, // Use contrustor from the given date
 *     date.getTime() // Use the date value to create a new date
 *   )
 * }
 */
function constructFrom<DateType extends Date>(
  date: DateType | number | string,
  value: Date | number | string
): DateType {
  if (date instanceof Date) {
    return new (date.constructor as GenericDateConstructor<DateType>)(value);
  } else {
    return new Date(value) as DateType;
  }
}

/**
 * @name roundToNearestMinutes
 * @category Minute Helpers
 * @summary Rounds the given date to the nearest minute
 *
 * @description
 * Rounds the given date to the nearest minute (or number of minutes).
 * Rounds up when the given date is exactly between the nearest round minutes.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments. Allows to use extensions like [`UTCDate`](https://github.com/date-fns/utc).
 *
 * @param date - The date to round
 * @param options - An object with options.
 *
 * @returns The new date rounded to the closest minute
 *
 * @example
 * // Round 10 July 2014 12:12:34 to nearest minute:
 * const result = roundToNearestMinutes(new Date(2014, 6, 10, 12, 12, 34))
 * //=> Thu Jul 10 2014 12:13:00
 *
 * @example
 * // Round 10 July 2014 12:12:34 to nearest quarter hour:
 * const result = roundToNearestMinutes(new Date(2014, 6, 10, 12, 12, 34), { nearestTo: 15 })
 * //=> Thu Jul 10 2014 12:15:00
 *
 * @example
 * // Floor (rounds down) 10 July 2014 12:12:34 to nearest minute:
 * const result = roundToNearestMinutes(new Date(2014, 6, 10, 12, 12, 34), { roundingMethod: 'floor' })
 * //=> Thu Jul 10 2014 12:12:00
 *
 * @example
 * // Ceil (rounds up) 10 July 2014 12:12:34 to nearest half hour:
 * const result = roundToNearestMinutes(new Date(2014, 6, 10, 12, 12, 34), { roundingMethod: 'ceil', nearestTo: 30 })
 * //=> Thu Jul 10 2014 12:30:00
 */
export function roundToNearestMinutes<DateType extends Date>(
  date: DateType | number,
  options?: {
    nearestTo?: number;
    roundingMethod?: RoundingMethod;
  }
): DateType {
  const nearestTo = options?.nearestTo ?? 1;

  if (nearestTo < 1 || nearestTo > 30) return constructFrom(date, NaN);

  const _date = toDate(date);
  const fractionalSeconds = _date.getSeconds() / 60;
  const fractionalMilliseconds = _date.getMilliseconds() / 1000 / 60;
  const minutes = _date.getMinutes() + fractionalSeconds + fractionalMilliseconds;

  // Unlike the `differenceIn*` functions, the default rounding behavior is `round` and not 'trunc'
  const method = options?.roundingMethod ?? 'round';
  const roundingMethod = getRoundingMethod(method);

  const roundedMinutes = roundingMethod(minutes / nearestTo) * nearestTo;

  const result = constructFrom(date, _date);
  result.setMinutes(roundedMinutes, 0, 0);
  return result;
}
