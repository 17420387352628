import { Group } from '@visx/group';
import _ from 'lodash';

import { ColorConfig, ColorConfigMap } from 'src/enums';

import { useChartContext } from './chartContext';

type T = {
  timestamp: Date;
  value?: number | null;
  exists: boolean;
};

interface Props {
  data: T[];
  styleVariant: ColorConfig;
  maxValue: number;
  top?: number;
}

const heatmapRowConfig = {
  padding: 1,
  lineHeight: 12,
  verticalPadding: 8,
  large: { spaceBetweenBlocks: 2, borderRadius: 3 },
  small: { spaceBetweenBlocks: 1, borderRadius: 2 },
  none: { spaceBetweenBlocks: 0, borderRadius: 0 },
};

export function calcHeatmapRowVerticalOffset(index: number): number {
  return (
    index * heatmapRowConfig.lineHeight + heatmapRowConfig.verticalPadding * index + heatmapRowConfig.verticalPadding
  );
}

const ComposableChartHeatmapRow: React.FC<Props> = ({ data, styleVariant, maxValue, top = 0 }) => {
  // const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const { xScale, padding, xMax } = useChartContext();

  const defaultBlockWidth = xScale.step();

  const { spaceBetweenBlocks, borderRadius } =
    defaultBlockWidth > 5
      ? heatmapRowConfig.large
      : defaultBlockWidth > 2
        ? heatmapRowConfig.small
        : heatmapRowConfig.none;

  // TODO: uncomment this to draw on canvas (some issues with canvas positioning in svg)
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvasRef.current?.getContext('2d');
  //   if (!ctx || !canvas) {
  //     return;
  //   }

  //   // Get the DPR and size of the canvas
  //   const dpr = window.devicePixelRatio;
  //   const rect = canvas.getBoundingClientRect();

  //   // Set the "actual" size of the canvas
  //   canvas.width = rect.width * dpr;
  //   canvas.height = rect.height * dpr;

  //   // Scale the context to ensure correct drawing operations
  //   ctx.scale(dpr, dpr);

  //   // Set the "drawn" size of the canvas
  //   canvas.style.width = `${rect.width}px`;
  //   canvas.style.height = `${rect.height}px`;

  //   // Clear the canvas
  //   ctx.clearRect(0, 0, xMax, usageConfig.lineHeight);

  //   ctx.fillStyle = '#303033';
  //   ctx.rect(0, 0, xMax, usageConfig.lineHeight);
  //   ctx.fill();

  //   for (const chunk of dataChunks) {
  //     for (const d of chunk) {
  //       if (_.isNull(d.value)) {
  //         continue;
  //       }

  //       const isZeroValue = d?.value === 0;

  //       const blockColor = isZeroValue ? ColorConfig.whiteDottedTranslucent : styleVariant;

  //       const blockOpacity = isZeroValue ? 0.15 : d.value / maxValue;

  //       const y = 1;
  //       const x = xScale(d.timestamp) ?? -1 + xSpace;
  //       const width = xScale.step() - xSpace;
  //       const height = usageConfig.lineHeight - 2;

  //       ctx.beginPath();
  //       ctx.fillStyle = ColorConfigMap[blockColor];
  //       ctx.globalAlpha = blockOpacity;
  //       (ctx as any).roundRect(x, y, width, height, borderRadius);
  //       // ctx.closePath();
  //       ctx.fill();
  //       ctx.globalAlpha = 1;
  //     }
  //   }
  // }, [dataChunks, xScale, canvasRef.current, styleVariant, maxValue]);

  // <foreignObject x={padding.left} y={yMax} width={xMax} height={usageConfig.lineHeight}>
  //   <canvas
  //     ref={canvasRef}
  //     height={usageConfig.lineHeight}
  //     width={xMax}
  //     // style={{
  //     //   width: xMax + 'px !important',
  //     //   height: usageConfig.lineHeight + 'px !important',
  //     // }}
  //   />
  // </foreignObject>

  return (
    <Group top={heatmapRowConfig.verticalPadding / 2 + top + padding.top} left={padding.left}>
      {/* Background area */}
      <rect y={0} x={0} width={xMax} height={heatmapRowConfig.lineHeight} rx={borderRadius} fill='#303033' />

      {/* Squares which represent usage */}
      {data.map((d, stepIndex) => {
        if (!d.exists || _.isNil(d.value)) {
          return null;
        }

        const isZeroValue = d?.value === 0;

        const blockColor = isZeroValue ? ColorConfig.whiteSolidTranslucent : styleVariant;

        const blockOpacity = isZeroValue ? 0.15 : d.value / maxValue;

        return (
          <rect
            key={`heatmap-cell_${stepIndex}`}
            y={heatmapRowConfig.padding}
            x={(xScale(d.timestamp) ?? -1) + spaceBetweenBlocks / 2}
            width={xScale.step() - spaceBetweenBlocks}
            height={heatmapRowConfig.lineHeight - heatmapRowConfig.padding * 2}
            rx={borderRadius}
            fill={ColorConfigMap[blockColor]}
            opacity={blockOpacity}
          />
        );
      })}
    </Group>
  );
};

export default ComposableChartHeatmapRow;
