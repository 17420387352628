import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Button } from '../Button/Button';

import styles from './StickyBottomPopup.module.scss';

interface Props {
  selected: number;
  total: number;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  entityName: string;
  children?: React.ReactNode;
}

const StickyBottomPopup: React.FC<Props> = (props) => {
  function toggleSelection() {
    if (props.total === props.selected) {
      props.onDeselectAll();
    } else {
      props.onSelectAll();
    }
  }

  const hidden = props.total === 0 || props.selected === 0;

  // Add delay to prevent flickering, if amount of selected items is 0, wait 500ms before showing 0
  // If amount of selected items is not 0, show it immediately
  const [delayedSelected, setDelayedSelected] = useState(0);
  const [timeoutId, setTimeoutId] = useState<number>();
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (props.selected === 0) {
      const timeout = setTimeout(() => {
        setDelayedSelected(props.selected);
      }, 500);
      setTimeoutId(timeout as unknown as number);
    } else {
      setDelayedSelected(props.selected);
    }
  }, [props.selected]);

  const selectLabel = props.total === delayedSelected ? 'Deselect All' : 'Select All';

  return (
    <div
      className={classNames('card', 'regular', 'el-04', styles['selected-sites-popup'], {
        [styles['hidden']]: hidden,
      })}
    >
      <p className='color-secondary'>
        Selected:{' '}
        <b className='body-semi-bold color-primary'>
          {delayedSelected} of {props.total} {props.entityName}
        </b>
      </p>
      <Button onClick={toggleSelection} label={selectLabel} />
      <div className='d-flex gap-8'>{props.children}</div>
    </div>
  );
};

export default StickyBottomPopup;
