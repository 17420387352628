export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: unknown; output: unknown };
};

export enum BenchmarkMetricType {
  ENERGY_USAGE = 'ENERGY_USAGE',
  ENERGY_USAGE_CARBON_EMISSIONS = 'ENERGY_USAGE_CARBON_EMISSIONS',
  ENERGY_USAGE_COST = 'ENERGY_USAGE_COST',
}

export type CognitivePerformanceIndex = {
  column: Scalars['Int']['output'];
  columnEnd: Scalars['Int']['output'];
  dataSource?: Maybe<DataSource>;
  expandable?: Maybe<Scalars['Boolean']['output']>;
  row: Scalars['Int']['output'];
  rowEnd: Scalars['Int']['output'];
  type: WidgetType;
};

export enum ColumnType {
  DATE_TIME = 'DATE_TIME',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

export type DataSource = {
  leaseId: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  systemIds: Array<Scalars['Int']['output']>;
  type: DataSourceType;
};

export type DataSourceInput = {
  leaseId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  type: DataSourceType;
};

export enum DataSourceType {
  LEASE_AND_SITE = 'LEASE_AND_SITE',
  SITE_AND_ORGANIZATION = 'SITE_AND_ORGANIZATION',
}

export type Dispatch = {
  day: Scalars['Int']['output'];
  hour: Scalars['Int']['output'];
  type: DispatchType;
  weekDay: WeekDays;
};

export type DispatchInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  /** Hour to trigger the report. It will be used as local time within site timezone. */
  hour?: InputMaybe<Scalars['Int']['input']>;
  type: DispatchType;
  weekDay?: InputMaybe<WeekDays>;
};

export enum DispatchType {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type ExportIAQTrendlinesInput = {
  from: Scalars['DateTime']['input'];
  hours?: InputMaybe<Array<Scalars['Int']['input']>>;
  siteId: Scalars['Int']['input'];
  step: MetricsStep;
  systemsIds: Array<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
  weekDays: Array<Scalars['Int']['input']>;
};

export type ExportMetricColumn = {
  name: Scalars['String']['input'];
  type: ColumnType;
};

export type GBISettings = {
  standardIds: Array<Scalars['Int']['output']>;
};

export type GreenBuildingIndex = {
  column: Scalars['Int']['output'];
  columnEnd: Scalars['Int']['output'];
  dataSource?: Maybe<DataSource>;
  expandable?: Maybe<Scalars['Boolean']['output']>;
  row: Scalars['Int']['output'];
  rowEnd: Scalars['Int']['output'];
  settings: GBISettings;
  type: WidgetType;
};

export enum MeterType {
  AVG_INFLUENT_FLOW = 'AVG_INFLUENT_FLOW',
  COAL_ANTHRACITE = 'COAL_ANTHRACITE',
  COAL_BITUMINOUS = 'COAL_BITUMINOUS',
  COKE = 'COKE',
  DIESEL = 'DIESEL',
  DISTRICT_CHILLED_WATER_ABSORPTION_NG = 'DISTRICT_CHILLED_WATER_ABSORPTION_NG',
  DISTRICT_CHILLED_WATER_ELECTRIC = 'DISTRICT_CHILLED_WATER_ELECTRIC',
  DISTRICT_CHILLED_WATER_ENGINE_NG = 'DISTRICT_CHILLED_WATER_ENGINE_NG',
  DISTRICT_CHILLED_WATER_OTHER = 'DISTRICT_CHILLED_WATER_OTHER',
  DISTRICT_HOT_WATER = 'DISTRICT_HOT_WATER',
  DISTRICT_STEAM = 'DISTRICT_STEAM',
  ELECTRIC = 'ELECTRIC',
  ELECTRIC_SOLAR = 'ELECTRIC_SOLAR',
  ELECTRIC_WIND = 'ELECTRIC_WIND',
  FUEL_OIL_NO_1 = 'FUEL_OIL_NO_1',
  FUEL_OIL_NO_2 = 'FUEL_OIL_NO_2',
  FUEL_OIL_NO_4 = 'FUEL_OIL_NO_4',
  FUEL_OIL_NO_5_OR_6 = 'FUEL_OIL_NO_5_OR_6',
  IT_EQUIPMENT_INPUT = 'IT_EQUIPMENT_INPUT',
  KEROSENE = 'KEROSENE',
  MUNICIPALLY_SUPPLIED_POTABLE_WATER_INDOOR = 'MUNICIPALLY_SUPPLIED_POTABLE_WATER_INDOOR',
  MUNICIPALLY_SUPPLIED_POTABLE_WATER_MIXED = 'MUNICIPALLY_SUPPLIED_POTABLE_WATER_MIXED',
  MUNICIPALLY_SUPPLIED_POTABLE_WATER_OUTDOOR = 'MUNICIPALLY_SUPPLIED_POTABLE_WATER_OUTDOOR',
  MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_INDOOR = 'MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_INDOOR',
  MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_MIXED = 'MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_MIXED',
  MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_OUTDOOR = 'MUNICIPALLY_SUPPLIED_RECLAIMED_WATER_OUTDOOR',
  NATURAL_GAS = 'NATURAL_GAS',
  OTHER_ENERGY = 'OTHER_ENERGY',
  OTHER_INDOOR_WATER = 'OTHER_INDOOR_WATER',
  OTHER_MIXED_WATER = 'OTHER_MIXED_WATER',
  OTHER_OUTDOOR_WATER = 'OTHER_OUTDOOR_WATER',
  POWER_DISTRIBUTION_UNIT_INPUT = 'POWER_DISTRIBUTION_UNIT_INPUT',
  POWER_DISTRIBUTION_UNIT_OUTPUT = 'POWER_DISTRIBUTION_UNIT_OUTPUT',
  PROPANE = 'PROPANE',
  UNINTERRUPTIBLE_POWER_SUPPLY_OUTPUT = 'UNINTERRUPTIBLE_POWER_SUPPLY_OUTPUT',
  WELL_WATER_INDOOR = 'WELL_WATER_INDOOR',
  WELL_WATER_MIXED = 'WELL_WATER_MIXED',
  WELL_WATER_OUTDOOR = 'WELL_WATER_OUTDOOR',
  WOOD = 'WOOD',
}

export type MetricDataIntervalsInput = {
  from: Scalars['DateTime']['input'];
  hours?: InputMaybe<Array<Scalars['Int']['input']>>;
  previousInterval?: InputMaybe<TimeInterval>;
  step: MetricsStep;
  to: Scalars['DateTime']['input'];
  weekDays: Array<Scalars['Int']['input']>;
};

export enum MetricsStep {
  DAY = 'DAY',
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type Mutation = {
  createReportConfig: ReportConfig;
  updateReportConfig: ReportConfig;
};

export type MutationcreateReportConfigArgs = {
  config: ReportConfigInput;
};

export type MutationupdateReportConfigArgs = {
  config: ReportConfigInput;
  reportId: Scalars['String']['input'];
};

export type Page = {
  layout: PageLayoutEnum;
  widgets: Array<WidgetsUnion>;
};

export type PageInput = {
  layout: PageLayoutEnum;
  widgets: Array<WidgetInput>;
};

export enum PageLayoutEnum {
  VERTICAL = 'VERTICAL',
}

export type ProductivityIndex = {
  column: Scalars['Int']['output'];
  columnEnd: Scalars['Int']['output'];
  dataSource?: Maybe<DataSource>;
  expandable?: Maybe<Scalars['Boolean']['output']>;
  row: Scalars['Int']['output'];
  rowEnd: Scalars['Int']['output'];
  type: WidgetType;
};

export type Query = {
  downloadTenantInvoiceFiles: Scalars['String']['output'];
  downloadUtilityInvoiceFiles: Scalars['String']['output'];
  exportIaqTrendlines: Scalars['String']['output'];
  exportMetricsData: Scalars['String']['output'];
  exportMonthlyBenchmarkData: Scalars['String']['output'];
  exportSubmetersTrends: Scalars['String']['output'];
  generateReport: Scalars['String']['output'];
  reportConfig: ReportConfig;
};

export type QuerydownloadTenantInvoiceFilesArgs = {
  invoiceIds: Array<Scalars['Int']['input']>;
};

export type QuerydownloadUtilityInvoiceFilesArgs = {
  utilityInvoiceId: Scalars['Int']['input'];
};

export type QueryexportIaqTrendlinesArgs = {
  exportIAQTrendlinesInput: ExportIAQTrendlinesInput;
};

export type QueryexportMetricsDataArgs = {
  columns: Array<ExportMetricColumn>;
  dataArray: Array<Array<Scalars['String']['input']>>;
  fileName: Scalars['String']['input'];
};

export type QueryexportMonthlyBenchmarkDataArgs = {
  meterType?: InputMaybe<MeterType>;
  metricType: BenchmarkMetricType;
  siteId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type QueryexportSubmetersTrendsArgs = {
  metricsFilter: MetricDataIntervalsInput;
  siteId: Scalars['Int']['input'];
  submeterFilter: SystemElectricitySubmeterFilterInput;
};

export type QuerygenerateReportArgs = {
  customTriggerDate?: InputMaybe<Scalars['DateTime']['input']>;
  reportId: Scalars['String']['input'];
};

export type QueryreportConfigArgs = {
  reportId: Scalars['String']['input'];
};

export type ReportConfig = {
  dataSource: DataSource;
  dispatch: Dispatch;
  id: Scalars['String']['output'];
  pages: Array<Page>;
  recipients: Array<Scalars['EmailAddress']['output']>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ReportConfigInput = {
  dataSource: DataSourceInput;
  dispatch: DispatchInput;
  pages: Array<PageInput>;
  recipients: Array<Scalars['EmailAddress']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<ReportType>;
};

export enum ReportType {
  GENERAL_REPORT = 'GENERAL_REPORT',
  LEASE_INVOICE = 'LEASE_INVOICE',
}

export enum SpaceStatus {
  COMMON = 'COMMON',
  MANAGEMENT = 'MANAGEMENT',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
}

export type SystemElectricitySubmeterFilterInput = {
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  spaceStatuses?: InputMaybe<Array<SpaceStatus>>;
  submeterTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type TimeInterval = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export enum WeekDays {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export type WidgetInput = {
  column: Scalars['Int']['input'];
  columnEnd: Scalars['Int']['input'];
  dataSource?: InputMaybe<DataSourceInput>;
  expandable?: InputMaybe<Scalars['Boolean']['input']>;
  row: Scalars['Int']['input'];
  rowEnd?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  type: WidgetType;
};

export enum WidgetType {
  BILLING_CHARGES_TABLE = 'BILLING_CHARGES_TABLE',
  BILLING_DEFINITIONS_LIST = 'BILLING_DEFINITIONS_LIST',
  BILLING_TIME_BASED_LINE_CHART = 'BILLING_TIME_BASED_LINE_CHART',
  BILLING_TOTAL_AMOUNT = 'BILLING_TOTAL_AMOUNT',
  CIRCLE_WITH_TOTAL = 'CIRCLE_WITH_TOTAL',
  COGNITIVE_PERFORMANCE_INDEX = 'COGNITIVE_PERFORMANCE_INDEX',
  DEFINITIONS_LIST = 'DEFINITIONS_LIST',
  ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION',
  GREEN_BUILDING_INDEX = 'GREEN_BUILDING_INDEX',
  IAQ_VENTILATION_PERFORMANCE = 'IAQ_VENTILATION_PERFORMANCE',
  KEY_PERFORMANCE_INDICATOR = 'KEY_PERFORMANCE_INDICATOR',
  PRODUCTIVITY_INDEX = 'PRODUCTIVITY_INDEX',
}

export type WidgetsUnion = CognitivePerformanceIndex | GreenBuildingIndex | ProductivityIndex;
