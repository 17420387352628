export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: DateStr; output: Date };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string };
  /** GraphQLMonthYear custom scalar type in format yyyy-MM */
  GraphQLMonthYear: { input: YearMonthStr; output: Date };
  /** A field whose value is either an IPv4 or IPv6 address: https://en.wikipedia.org/wiki/IP_address. */
  IP: { input: string; output: string };
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: string; output: string };
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: string; output: string };
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: Date; output: Date };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File };
};

export type AdditionalCharge = {
  chargeCalculationType: ChargeCalculationType;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  isAdjustment: Scalars['Boolean']['output'];
  leaseId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type Altc24ProgSystemModelInformation = {
  /** This field indicates the number of cooling stages model supports */
  coolingStages: Scalars['Int']['output'];
  /** This field indicates the number of fan speeds model supports */
  fanLevels: Scalars['Int']['output'];
  /** This field indicates if the model has filter */
  filter: Scalars['Boolean']['output'];
  /** This field indicates the number of heating stages model supports */
  heatingStages: Scalars['Int']['output'];
  /** This field specifies the model name. */
  name: Scalars['String']['output'];
};

export type ApiKey = {
  /** Expiration date for API key */
  expirationDate: Scalars['DateTime']['output'];
  /** ID of API key */
  id: Scalars['String']['output'];
  /** Name of API key */
  name: Scalars['String']['output'];
  organization?: Maybe<ApiKeyOrganization>;
  /** Organization ID */
  organizationId: Scalars['Int']['output'];
  /** Token valid 10 years, need to be stored in a safe place, if lose it, need create a new one. */
  token?: Maybe<Scalars['String']['output']>;
};

export type ApiKeyOrganization = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BillingInfoChargeInput = {
  chargeCalculationType: ChargeCalculationType;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  type: ChargeType;
  value: Scalars['Float']['input'];
};

export enum BillingRateType {
  ALL_INCLUSIVE = 'ALL_INCLUSIVE',
  ENERGY = 'ENERGY',
  ENERGY_AND_DEMAND = 'ENERGY_AND_DEMAND',
  ENERGY_AND_TOU_DEMAND = 'ENERGY_AND_TOU_DEMAND',
  TOU_ENERGY_AND_DEMAND = 'TOU_ENERGY_AND_DEMAND',
  TOU_ENERGY_AND_TOU_DEMAND = 'TOU_ENERGY_AND_TOU_DEMAND',
}

export type BillingRates = {
  demandOffPeakRate?: Maybe<Scalars['Float']['output']>;
  demandOnPeakRate?: Maybe<Scalars['Float']['output']>;
  energyOffPeakRate: Scalars['Float']['output'];
  energyOnPeakRate?: Maybe<Scalars['Float']['output']>;
  peakRanges?: Maybe<Array<PeakRange>>;
  rateType: BillingRateType;
};

export type BillingRatesInput = {
  demandOffPeakRate?: InputMaybe<Scalars['Float']['input']>;
  demandOnPeakRate?: InputMaybe<Scalars['Float']['input']>;
  energyOffPeakRate: Scalars['Float']['input'];
  energyOnPeakRate?: InputMaybe<Scalars['Float']['input']>;
  peakRanges?: InputMaybe<Array<PeakRangeInput>>;
  rateType: BillingRateType;
};

export type CalendarizedMeterConsumption = {
  consumption?: Maybe<Scalars['Int']['output']>;
  month: Scalars['GraphQLMonthYear']['output'];
  peakDemand?: Maybe<Scalars['Float']['output']>;
  peakDemandTimestamp?: Maybe<Scalars['DateTime']['output']>;
};

export enum ChargeCalculationType {
  EXTRA_RATE_FOR_SUBMETER_DEMAND_CHARGE = 'EXTRA_RATE_FOR_SUBMETER_DEMAND_CHARGE',
  EXTRA_RATE_FOR_SUBMETER_USAGE_CHARGE = 'EXTRA_RATE_FOR_SUBMETER_USAGE_CHARGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENT_FOR_SUBMETER_DEMAND_COST_CHARGE = 'PERCENT_FOR_SUBMETER_DEMAND_COST_CHARGE',
  PERCENT_FOR_SUBMETER_USAGE_COST_CHARGE = 'PERCENT_FOR_SUBMETER_USAGE_COST_CHARGE',
  PERCENT_FOR_SUB_TOTAL = 'PERCENT_FOR_SUB_TOTAL',
}

export type ChargePointStation = {
  allowedLoad: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isShed: Scalars['Boolean']['output'];
  lastActivity: Scalars['DateTime']['output'];
  manufacturer?: Maybe<Scalars['String']['output']>;
  mode: ChargePointStationModeType;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  percentShed: Scalars['Float']['output'];
  stationId: Scalars['String']['output'];
  stationLoad: Scalars['Float']['output'];
  status: ChargePointStatus;
  statusChangedAt: Scalars['DateTime']['output'];
  warranty?: Maybe<Scalars['String']['output']>;
  warrantyExpiration?: Maybe<Scalars['DateTime']['output']>;
};

export enum ChargePointStationModeType {
  PORTS_MAX = 'PORTS_MAX',
  PORTS_SHED = 'PORTS_SHED',
  STATION_MAX = 'STATION_MAX',
  STATION_SHED = 'STATION_SHED',
}

export type ChargePointStationPort = {
  allowedLoad: Scalars['Float']['output'];
  connector?: Maybe<Scalars['String']['output']>;
  faultReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isShed: Scalars['Boolean']['output'];
  level?: Maybe<Scalars['String']['output']>;
  percentShed: Scalars['Int']['output'];
  portLoad: Scalars['Float']['output'];
  portNumber: Scalars['String']['output'];
  power: Scalars['Float']['output'];
  status: ChargePointStatus;
  statusChangedAt: Scalars['DateTime']['output'];
};

export type ChargePointStationPortSettings = {
  allowedLoad: Scalars['Float']['input'];
  portId: Scalars['Int']['input'];
};

export type ChargePointStationsInsightsKPI = {
  energy?: Maybe<Scalars['Float']['output']>;
  energyPercentToPreviousPeriod?: Maybe<Scalars['Int']['output']>;
  gasolineSavings?: Maybe<Scalars['Float']['output']>;
  gasolineSavingsPercentToPreviousPeriod?: Maybe<Scalars['Int']['output']>;
  peakDemand?: Maybe<Scalars['Float']['output']>;
  peakDemandPercentToPreviousPeriod?: Maybe<Scalars['Int']['output']>;
  peakDemandTimestamp?: Maybe<Scalars['DateTime']['output']>;
  sessionsCount?: Maybe<Scalars['Int']['output']>;
  sessionsCountPercentToPreviousPeriod?: Maybe<Scalars['Int']['output']>;
};

export type ChargePointStationsInsightsTableData = {
  avgBatterCharged: Scalars['Int']['output'];
  avgChargeTime: Scalars['String']['output'];
  electricity: Scalars['Float']['output'];
  numberOfSessions: Scalars['Int']['output'];
  percentOfTotal: Scalars['Float']['output'];
  portNumber: Scalars['String']['output'];
  stationId: Scalars['Int']['output'];
  utilization: Scalars['Float']['output'];
};

export type ChargePointStationsOverviewBySite = {
  lastMonthUptime?: Maybe<Scalars['Float']['output']>;
  lastMonthUptimePercentChange?: Maybe<Scalars['Float']['output']>;
  lastMonthUtilization?: Maybe<Scalars['Float']['output']>;
  lastMonthUtilizationPercentChange?: Maybe<Scalars['Float']['output']>;
  portsCount: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  stationsCount: Scalars['Int']['output'];
};

export enum ChargePointStatus {
  AVAILABLE = 'AVAILABLE',
  INUSE = 'INUSE',
  REACHABLE = 'REACHABLE',
  UNKNOWN = 'UNKNOWN',
  UNREACHABLE = 'UNREACHABLE',
}

export type ChargePointSystemMetric = {
  id: Scalars['Int']['output'];
  measurement: MetricsMeasurementType;
  name: Scalars['String']['output'];
  portId?: Maybe<Scalars['Int']['output']>;
  relationType: MetricsRelationType;
  systemId: Scalars['Int']['output'];
  type: MetricType;
  view: MetricsView;
};

export enum ChargeType {
  ADJUSTMENT = 'ADJUSTMENT',
  CHARGE = 'CHARGE',
  TAX = 'TAX',
}

export type CompressedSystemMetrics = {
  exists: Array<Scalars['Boolean']['output']>;
  max?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  min?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  systemMetricId: Array<Scalars['Int']['output']>;
  t: Array<Scalars['Timestamp']['output']>;
  v: Array<Maybe<Scalars['Float']['output']>>;
};

export type CompressedSystemTrends = {
  exists: Array<Scalars['Boolean']['output']>;
  max?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  min?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  systemId: Array<Scalars['Int']['output']>;
  t: Array<Scalars['Timestamp']['output']>;
  v: Array<Maybe<Scalars['Float']['output']>>;
};

export type ConnectedEntity = {
  deviceUUID?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ConnectedToGateway = {
  sensors: Array<ConnectedEntity>;
  systems: Array<ConnectedEntity>;
};

export type CreateAdditionalChargeInput = {
  chargeCalculationType: ChargeCalculationType;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  isAdjustment: Scalars['Boolean']['input'];
  leaseId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  type: ChargeType;
  value: Scalars['Float']['input'];
};

export type CreateApiKeyInput = {
  name: Scalars['String']['input'];
  organization_id: Scalars['Int']['input'];
};

export type CreateLeaseInput = {
  billingInfo?: InputMaybe<LeaseBillingInfoInput>;
  leaseInfo: LeaseInfoInput;
  occupiedSpacesIds: Array<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
  tenantId?: InputMaybe<Scalars['Int']['input']>;
  tenantInfo?: InputMaybe<CreateTenantInput>;
  users: Array<LeaseUserInfoInput>;
};

export type CreateRegionInput = {
  country: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSensorInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  floor: Scalars['String']['input'];
  floorType: FloorType;
  integrationDetails?: InputMaybe<SensorDeviceIntegrationInput>;
  line?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
  siteId: Scalars['Int']['input'];
  systems: Array<SensorToSystemInput>;
  type: SensorDeviceType;
};

export type CreateSiteInput = {
  city: Scalars['String']['input'];
  latitude: Scalars['Latitude']['input'];
  longitude: Scalars['Longitude']['input'];
  name: Scalars['String']['input'];
  photo?: InputMaybe<Scalars['Upload']['input']>;
  primary_property_type: SitePrimaryPropertyType;
  region_id: Scalars['Int']['input'];
  square_feet: Scalars['Int']['input'];
  state: Scalars['String']['input'];
  street_name: Scalars['String']['input'];
  street_number?: InputMaybe<Scalars['String']['input']>;
  year_built?: InputMaybe<Scalars['Int']['input']>;
  zip_code: Scalars['String']['input'];
  zip_code_title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSubmeterRateInput = {
  demandOffPeakRate?: InputMaybe<Scalars['Float']['input']>;
  demandOnPeakRate?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  energyOffPeakRate: Scalars['Float']['input'];
  energyOnPeakRate?: InputMaybe<Scalars['Float']['input']>;
  leaseId: Scalars['Int']['input'];
  peakRanges?: InputMaybe<Array<PeakRangeInput>>;
  rateType: BillingRateType;
  startDate: Scalars['Date']['input'];
  systemId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSystemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraFields: SystemExtraFieldsCreateInput;
  floor: Scalars['String']['input'];
  floorType: FloorType;
  integrationDetails?: InputMaybe<SystemDeviceIntegrationInput>;
  name: Scalars['String']['input'];
  organizationIds: Array<Scalars['Int']['input']>;
  sensors?: InputMaybe<Array<SystemToSensorInput>>;
  siteId: Scalars['Int']['input'];
  type: SystemType;
};

export type CreateTenantInput = {
  contactName: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreatedLease = {
  leaseId: Scalars['Int']['output'];
  tenantId: Scalars['Int']['output'];
};

export type DateInterval = {
  from: Scalars['Date']['output'];
  to?: Maybe<Scalars['Date']['output']>;
};

export type DefaultSetpointRange = {
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type DeviceIntegration = {
  appEui?: Maybe<Scalars['String']['output']>;
  appKey?: Maybe<Scalars['String']['output']>;
  communicationId?: Maybe<Scalars['String']['output']>;
  controlParams?: Maybe<Scalars['String']['output']>;
  deviceProfileId?: Maybe<Scalars['String']['output']>;
  gatewayId?: Maybe<Scalars['Int']['output']>;
  gatewayVersion?: Maybe<GatewayVersion>;
  installationDate?: Maybe<Scalars['Date']['output']>;
  ipAddress?: Maybe<Scalars['IP']['output']>;
  networkIndex?: Maybe<Scalars['Int']['output']>;
  physicalLocation?: Maybe<Scalars['String']['output']>;
  resetStationCode?: Maybe<Scalars['String']['output']>;
  routeIPAddress?: Maybe<Scalars['IP']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  subchannel?: Maybe<Scalars['Int']['output']>;
  subnetMask?: Maybe<Scalars['IP']['output']>;
  typeId: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
};

export enum DispatchType {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type ElectricitySubmeterDetails = {
  floor: Scalars['String']['output'];
  floorOrder: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nameOrder: Scalars['Int']['output'];
  occupancyType: SpaceStatus;
  ownership?: Maybe<Scalars['Int']['output']>;
  submeterType: Scalars['String']['output'];
  systemId: Scalars['Int']['output'];
  tenantName?: Maybe<Scalars['String']['output']>;
};

export type ElectricitySubmeterTrendsKPI = {
  energyConsumption?: Maybe<Scalars['Int']['output']>;
  energyConsumptionPercentToPrevPeriod?: Maybe<Scalars['Int']['output']>;
  peakDemand?: Maybe<Scalars['Float']['output']>;
  peakDemandPercentToPrevPeriod?: Maybe<Scalars['Int']['output']>;
  peakDemandTimestamp?: Maybe<Scalars['DateTime']['output']>;
  totalCarbonEmissions?: Maybe<Scalars['Float']['output']>;
  totalCarbonEmissionsPercentToPrevPeriod?: Maybe<Scalars['Int']['output']>;
};

export enum FanType {
  AUTO = 'AUTO',
  CONTINUOUS = 'CONTINUOUS',
  FAN_CIRCULATE = 'FAN_CIRCULATE',
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  OFF = 'OFF',
  ON = 'ON',
}

export enum FilterAlarmType {
  ALARM = 'ALARM',
  NORMAL = 'NORMAL',
  RESET = 'RESET',
}

export enum FloorType {
  BASEMENT = 'BASEMENT',
  CELLAR = 'CELLAR',
  LOBBY = 'LOBBY',
  MEZZANINE = 'MEZZANINE',
  NO_FLOOR = 'NO_FLOOR',
  NUMBER = 'NUMBER',
  ROOF = 'ROOF',
  SUBCELLAR = 'SUBCELLAR',
}

export enum FloorUseTypes {
  GATEWAY = 'GATEWAY',
  SENSOR = 'SENSOR',
  SPACES = 'SPACES',
  SYSTEM = 'SYSTEM',
}

export enum GatewayVersion {
  AWS_IOT_LORAWAN = 'AWS_IOT_LORAWAN',
  TBL_V1 = 'TBL_V1',
  TBL_V2 = 'TBL_V2',
}

export type HistoricalAdjustment = {
  chargeAmount: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fileUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invoiceBillingMonth: Scalars['GraphQLMonthYear']['output'];
  invoiceEndPeriod: Scalars['Date']['output'];
  invoiceId: Scalars['Int']['output'];
  invoiceKey: Scalars['String']['output'];
  invoiceStartPeriod: Scalars['Date']['output'];
  leaseAccountNumber: Scalars['String']['output'];
  leaseId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  spaces: Array<Scalars['String']['output']>;
  tenantName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type IAQConnectedSensors = {
  /** This field indicates if the system has Damper Effective Sensor connected to it */
  damperEffective: Scalars['Boolean']['output'];
  /** This field indicates if the system has Damper Expected Sensor connected to it */
  damperPosition: Scalars['Boolean']['output'];
};

export type IAQMetricsHeatMapData = {
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type IAQModelInformation = {
  /** This field indicates if the model supports Formaldehyde measurement */
  ch2o: Scalars['Boolean']['output'];
  /** This field indicates if the model supports Carbon Monoxide measurement */
  co: Scalars['Boolean']['output'];
  /** This field indicates if the model supports Carbon Dioxide measurement */
  co2: Scalars['Boolean']['output'];
  /** This field specifies the model name. */
  name: Scalars['String']['output'];
  /** This field indicates if the model supports Ozone measurement */
  o3: Scalars['Boolean']['output'];
  /** This field indicates if the model supports Particulate Matter 2.5 measurement */
  pm2_5: Scalars['Boolean']['output'];
  /** This field indicates if the model supports Particulate Matter 10 measurement */
  pm10: Scalars['Boolean']['output'];
  /** This field indicates if the model supports Humidity measurement */
  relativeHumidity: Scalars['Boolean']['output'];
  /** This field indicates if the model supports Temperature measurement */
  temperature: Scalars['Boolean']['output'];
  /** This field indicates if the model supports Total Volatile Organic Compounds measurement */
  tvoc: Scalars['Boolean']['output'];
};

export type IAQSystemMetricData = {
  ch2o?: Maybe<Array<SystemMetricData>>;
  co?: Maybe<Array<SystemMetricData>>;
  co2?: Maybe<Array<SystemMetricData>>;
  damperEffective?: Maybe<Array<SystemMetricData>>;
  damperPosition?: Maybe<Array<SystemMetricData>>;
  o3?: Maybe<Array<SystemMetricData>>;
  pm2_5?: Maybe<Array<SystemMetricData>>;
  pm10?: Maybe<Array<SystemMetricData>>;
  relativeHumidity?: Maybe<Array<SystemMetricData>>;
  score?: Maybe<Array<SystemMetricData>>;
  systemId: Scalars['Int']['output'];
  temperature?: Maybe<Array<SystemMetricData>>;
  tvoc?: Maybe<Array<SystemMetricData>>;
};

export type IaqScoreConfig = {
  ch2o: IaqScoreConfigItem;
  co: IaqScoreConfigItem;
  co2: IaqScoreConfigItem;
  damperEffective: IaqScoreConfigItem;
  damperPosition: IaqScoreConfigItem;
  o3: IaqScoreConfigItem;
  pm2_5: IaqScoreConfigItem;
  pm10: IaqScoreConfigItem;
  relativeHumidity: IaqScoreConfigItem;
  score: IaqScoreConfigItem;
  temperature: IaqScoreConfigItem;
  tvoc: IaqScoreConfigItem;
};

export type IaqScoreConfigItem = {
  fair: Array<IaqScoreConfigItemParams>;
  good: Array<IaqScoreConfigItemParams>;
  maxValue: Scalars['Float']['output'];
  measurement: Scalars['String']['output'];
  minValue: Scalars['Float']['output'];
  poor: Array<IaqScoreConfigItemParams>;
};

export type IaqScoreConfigItemParams = {
  gt?: Maybe<Scalars['Float']['output']>;
  gte?: Maybe<Scalars['Float']['output']>;
  lt?: Maybe<Scalars['Float']['output']>;
  lte?: Maybe<Scalars['Float']['output']>;
};

export type IaqScorePerSite = {
  IAQFair: Scalars['Int']['output'];
  IAQGood: Scalars['Int']['output'];
  IAQPoor: Scalars['Int']['output'];
  IAQScore: Scalars['Int']['output'];
  hasSystems: Scalars['Boolean']['output'];
  siteId: Scalars['Int']['output'];
};

export type Invoice = {
  billingMonth: Scalars['GraphQLMonthYear']['output'];
  charges: Array<InvoiceCharge>;
  dispatchDate: Scalars['Date']['output'];
  endPeriod: Scalars['Date']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invoiceKey: Scalars['String']['output'];
  leaseId: Scalars['Int']['output'];
  spaces: Array<InvoiceSpace>;
  startPeriod: Scalars['Date']['output'];
  tenantName: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  totalPeakDemandCost: Scalars['Float']['output'];
  totalUsageCost: Scalars['Float']['output'];
};

export type InvoiceCharge = {
  chargeName: Scalars['String']['output'];
  chargeValue: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
};

export type InvoiceSpace = {
  id: Scalars['Int']['output'];
  spaceName: Scalars['String']['output'];
};

export type InvoicesFilterInput = {
  from: Scalars['GraphQLMonthYear']['input'];
  previousInterval?: InputMaybe<MonthRangeInput>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to: Scalars['GraphQLMonthYear']['input'];
};

export type InvoicesKPIForSite = {
  demand?: Maybe<Scalars['Float']['output']>;
  demandPercentToPreviousPeriod?: Maybe<Scalars['Float']['output']>;
  energy?: Maybe<Scalars['Float']['output']>;
  energyPercentToPreviousPeriod?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalPercentToPreviousPeriod?: Maybe<Scalars['Float']['output']>;
};

export enum LearningAlgorithmType {
  BALANCE = 'BALANCE',
  COMFORT = 'COMFORT',
  ENERGY = 'ENERGY',
}

export type Lease = {
  billingInfo?: Maybe<LeaseBilling>;
  leaseInfo: LeaseGeneral;
  occupiedSpacesIds: Array<Scalars['Int']['output']>;
  tenantId: Scalars['Int']['output'];
  users: Array<LeaseUser>;
};

export type LeaseBilling = {
  billingFrequency: DispatchType;
  billingStartDate: Scalars['Date']['output'];
  billingStructure: BillingRateType;
  charges: Array<LeaseBillingCharge>;
  paymentInfo?: Maybe<Scalars['String']['output']>;
  paymentTerms: Scalars['Int']['output'];
  rates: BillingRates;
};

export type LeaseBillingCharge = {
  chargeCalculationType: ChargeCalculationType;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
  type: ChargeType;
  value: Scalars['Float']['output'];
};

export type LeaseBillingInfoInput = {
  billingFrequency: DispatchType;
  billingStartDate: Scalars['Date']['input'];
  billingStructure: BillingRateType;
  charges: Array<BillingInfoChargeInput>;
  paymentInfo: Scalars['String']['input'];
  paymentTerms: Scalars['Int']['input'];
  rates: BillingRatesInput;
};

export type LeaseGeneral = {
  accountNumber: Scalars['String']['output'];
  billable: Scalars['Boolean']['output'];
  contactName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['Date']['output'];
};

export type LeaseInfoInput = {
  accountNumber: Scalars['String']['input'];
  billable: Scalars['Boolean']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
};

export type LeaseOverview = {
  contactName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  leaseAccountNumber: Scalars['String']['output'];
  leaseId: Scalars['Int']['output'];
  leasePeriod: DateInterval;
  leaseStatus: LeaseStatus;
  numberOfUsers: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  spaceNames: Array<Scalars['String']['output']>;
  tenantId: Scalars['Int']['output'];
  tenantName: Scalars['String']['output'];
};

export type LeaseShort = {
  accountNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  paymentInfo?: Maybe<Scalars['String']['output']>;
  paymentTerms?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  startBillingDate?: Maybe<Scalars['Date']['output']>;
  tenantId: Scalars['Int']['output'];
};

export enum LeaseStatus {
  ACTIVE = 'ACTIVE',
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
}

export type LeaseUser = {
  email: Scalars['EmailAddress']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  spacesIds: Array<Scalars['Int']['output']>;
};

export type LeaseUserInfoInput = {
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  spacesIds: Array<Scalars['Int']['input']>;
};

export type LeasesOverviewFilter = {
  expirationYears?: InputMaybe<Array<Scalars['Int']['input']>>;
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<LeaseStatus>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum LockType {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export type MeasurementName = {
  measurement: MetricType;
  name: Scalars['String']['input'];
};

export type MetricDataIntervalsInput = {
  from: Scalars['DateTime']['input'];
  hours?: InputMaybe<Array<Scalars['Int']['input']>>;
  previousInterval?: InputMaybe<TimeInterval>;
  step: MetricsStep;
  to: Scalars['DateTime']['input'];
  weekDays: Array<Scalars['Int']['input']>;
};

export enum MetricType {
  AVG_SPACE_TEMPERATURE = 'AVG_SPACE_TEMPERATURE',
  BATTERY = 'BATTERY',
  CDD_TEMPERATURE = 'CDD_TEMPERATURE',
  COOLING = 'COOLING',
  COOLING_2 = 'COOLING_2',
  COOLING_3 = 'COOLING_3',
  DAMPER_EFFECTIVE = 'DAMPER_EFFECTIVE',
  DAMPER_POSITION = 'DAMPER_POSITION',
  DHW_TEMPERATURE = 'DHW_TEMPERATURE',
  EFFICIENCY = 'EFFICIENCY',
  ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION',
  FAN = 'FAN',
  FUEL_OIL_CONSUMPTION = 'FUEL_OIL_CONSUMPTION',
  GAS_DEMAND = 'GAS_DEMAND',
  GAS_USAGE = 'GAS_USAGE',
  HDD_TEMPERATURE = 'HDD_TEMPERATURE',
  HEATING = 'HEATING',
  HEATING_2 = 'HEATING_2',
  HEATING_3 = 'HEATING_3',
  HUMIDITY = 'HUMIDITY',
  LIGHT = 'LIGHT',
  LOCAL_SETPOINT = 'LOCAL_SETPOINT',
  OCCUPANCY = 'OCCUPANCY',
  OUTLET_PRESSURE = 'OUTLET_PRESSURE',
  OUTSIDE_AIR_TEMPERATURE = 'OUTSIDE_AIR_TEMPERATURE',
  POWER = 'POWER',
  POWER_DEMAND = 'POWER_DEMAND',
  POWER_FACTOR = 'POWER_FACTOR',
  PRESSURE = 'PRESSURE',
  PRV_POSITION_1 = 'PRV_POSITION_1',
  PRV_POSITION_2 = 'PRV_POSITION_2',
  PRV_POSITION_3 = 'PRV_POSITION_3',
  PRV_POSITION_4 = 'PRV_POSITION_4',
  PRV_POSITION_5 = 'PRV_POSITION_5',
  PRV_POSITION_6 = 'PRV_POSITION_6',
  PRV_PRESSURE_1 = 'PRV_PRESSURE_1',
  PRV_PRESSURE_2 = 'PRV_PRESSURE_2',
  PRV_PRESSURE_3 = 'PRV_PRESSURE_3',
  PRV_PRESSURE_4 = 'PRV_PRESSURE_4',
  PRV_PRESSURE_5 = 'PRV_PRESSURE_5',
  PRV_PRESSURE_6 = 'PRV_PRESSURE_6',
  RAINFALL_TOTAL = 'RAINFALL_TOTAL',
  REACTIVE_POWER = 'REACTIVE_POWER',
  REMOTE_SETPOINT = 'REMOTE_SETPOINT',
  REMOTE_SETPOINT_MAX = 'REMOTE_SETPOINT_MAX',
  REMOTE_SETPOINT_MIN = 'REMOTE_SETPOINT_MIN',
  SPACE_TEMPERATURE = 'SPACE_TEMPERATURE',
  STEAM_CONSUMPTION = 'STEAM_CONSUMPTION',
  STEAM_DEMAND = 'STEAM_DEMAND',
  SUPPLY_TEMPERATURE = 'SUPPLY_TEMPERATURE',
  TEMPERATURE = 'TEMPERATURE',
  UPTIME = 'UPTIME',
  WASTE_CONSUMPTION = 'WASTE_CONSUMPTION',
  WATER_CONSUMPTION = 'WATER_CONSUMPTION',
  WATER_DEMAND = 'WATER_DEMAND',
  WIND_DIRECTION = 'WIND_DIRECTION',
  WIND_SPEED = 'WIND_SPEED',
  ch2o = 'ch2o',
  co = 'co',
  co2 = 'co2',
  light = 'light',
  noise = 'noise',
  o3 = 'o3',
  pm2_5 = 'pm2_5',
  pm10 = 'pm10',
  relative_humidity = 'relative_humidity',
  score = 'score',
  temperature = 'temperature',
  tvoc = 'tvoc',
}

export enum MetricsMeasurementType {
  DBA = 'DBA',
  DEGREES = 'DEGREES',
  FAHRENHEIT = 'FAHRENHEIT',
  FT3 = 'FT3',
  HCF = 'HCF',
  HCF_PER_HOUR = 'HCF_PER_HOUR',
  HECTO_PASCAL = 'HECTO_PASCAL',
  KBTU = 'KBTU',
  KVAR = 'KVAR',
  KW = 'KW',
  KWH = 'KWH',
  LUX = 'LUX',
  METERS_PER_SECOND = 'METERS_PER_SECOND',
  METRIC_TON = 'METRIC_TON',
  MICROGRAM_M3 = 'MICROGRAM_M3',
  MILES_PER_HOUR = 'MILES_PER_HOUR',
  MILLIMETER = 'MILLIMETER',
  MILLION_POUNDS = 'MILLION_POUNDS',
  MILLION_POUNDS_PER_HOUR = 'MILLION_POUNDS_PER_HOUR',
  NUMERIC = 'NUMERIC',
  PERCENTAGE = 'PERCENTAGE',
  PPB = 'PPB',
  PPM = 'PPM',
  PSI = 'PSI',
  THERMS = 'THERMS',
  THERMS_PER_HOUR = 'THERMS_PER_HOUR',
  USAGE = 'USAGE',
  USD = 'USD',
  WH = 'WH',
}

export enum MetricsRelationType {
  CUSTOM_METRIC = 'CUSTOM_METRIC',
  DEVICE = 'DEVICE',
  SENSOR = 'SENSOR',
}

export enum MetricsStep {
  DAY = 'DAY',
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum MetricsView {
  CONTINUES_LINE = 'CONTINUES_LINE',
  DEFAULT = 'DEFAULT',
}

export enum ModeType {
  AUTO = 'AUTO',
  COOL = 'COOL',
  EMERGENCY_HEAT = 'EMERGENCY_HEAT',
  FAN_ONLY = 'FAN_ONLY',
  FREEZE_PROTECTION = 'FREEZE_PROTECTION',
  HEAT = 'HEAT',
  OFF = 'OFF',
  SERVICE_MODE = 'SERVICE_MODE',
  SMOKE_EMERGENCY = 'SMOKE_EMERGENCY',
  STARTUP_WAIT = 'STARTUP_WAIT',
}

export type MonthRangeInput = {
  from: Scalars['GraphQLMonthYear']['input'];
  to: Scalars['GraphQLMonthYear']['input'];
};

export type Mutation = {
  calculatePastMetrics: Scalars['Boolean']['output'];
  /** @deprecated API deprecated after metrics were migrated */
  calculatePastMetricsForMetricType: Scalars['Boolean']['output'];
  changeMetricName: Scalars['Boolean']['output'];
  connectSystemToSpace: Scalars['Boolean']['output'];
  createAdditionalCharge: AdditionalCharge;
  createApiKey: ApiKey;
  createLease: CreatedLease;
  createMetricsForSensor: Scalars['Boolean']['output'];
  createOrganization: Organization;
  createRegion: Region;
  createSchedulerProfile: SchedulerProfile;
  createSite: Site;
  createSpace: Space;
  /** @deprecated API deprecated because not being used on Orion since 07/21 */
  createSubmeterRate: SubmeterRate;
  createSystem: Scalars['Int']['output'];
  /** @deprecated API deprecated because not being used on Orion since 07/21 */
  createTenant: Tenant;
  creteSensor: Scalars['Int']['output'];
  deleteAdditionalCharge: Scalars['Boolean']['output'];
  deleteApiKey: Scalars['Boolean']['output'];
  deleteLease: Scalars['Boolean']['output'];
  deleteOverriddenSchedulerProfile: Scalars['Boolean']['output'];
  deleteRegion: Scalars['Boolean']['output'];
  deleteSchedulerProfile: Scalars['Boolean']['output'];
  deleteSensor: Scalars['Boolean']['output'];
  deleteSite: Scalars['Boolean']['output'];
  deleteSpace: Scalars['Boolean']['output'];
  deleteSystem: Scalars['Boolean']['output'];
  deleteSystemFromSpace: Scalars['Boolean']['output'];
  expireChargeForNextBill: AdditionalCharge;
  /** @deprecated API deprecated after metrics were migrated */
  migratePastMetrics: Scalars['Boolean']['output'];
  /** @deprecated API deprecated after metrics were migrated */
  migratePastMetricsInChunks: Scalars['Boolean']['output'];
  overrideDRSequence: Scalars['Boolean']['output'];
  overrideSchedulerProfile: Scalars['Boolean']['output'];
  processSystemGroups: Scalars['Int']['output'];
  setOrgSitesFeatures: Scalars['Boolean']['output'];
  transferDeviceMetrics: Scalars['Boolean']['output'];
  updateAdditionalCharge: Scalars['Boolean']['output'];
  updateChargePointSystemMode: Scalars['Boolean']['output'];
  updateChargePointSystemSettings: Scalars['Boolean']['output'];
  updateLease: Scalars['Boolean']['output'];
  updateLightingSystemMode: SystemUnion;
  updateMetricCreationDates: Scalars['Boolean']['output'];
  updateOrganization: Organization;
  updatePackageSystemFan: SystemUnion;
  updatePackageSystemMode: SystemUnion;
  updatePackageSystemResetFilter: SystemUnion;
  updatePackageSystemSetpoint: SystemUnion;
  updatePackageSystemSettings: SystemUnion;
  updateSchedulerProfile: SchedulerProfile;
  updateSchedulerProfilesForSite: Array<SchedulerProfileForSite>;
  updateSensor: Scalars['Boolean']['output'];
  updateSequence: SystemUnion;
  updateSetpoint: SystemUnion;
  updateSite: Site;
  updateSiteFitwell: SiteFitwell;
  updateSmartOutletSystemMode: SystemUnion;
  updateSpace: Scalars['Boolean']['output'];
  updateSystem: Scalars['Boolean']['output'];
  updateSystemSettings: SystemUnion;
  /** @deprecated API deprecated because not being used on Orion since 07/21 */
  updateTenant: Scalars['Boolean']['output'];
  upsertSiteFeatures: Scalars['Boolean']['output'];
};

export type MutationcalculatePastMetricsArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MutationcalculatePastMetricsForMetricTypeArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  metricType: MetricType;
  startDate: Scalars['DateTime']['input'];
};

export type MutationchangeMetricNameArgs = {
  metricId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type MutationconnectSystemToSpaceArgs = {
  occupation?: InputMaybe<Scalars['Int']['input']>;
  spaceId: Scalars['Int']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationcreateAdditionalChargeArgs = {
  createAdditionalChargeInput: CreateAdditionalChargeInput;
};

export type MutationcreateApiKeyArgs = {
  createApiKeyInput: CreateApiKeyInput;
};

export type MutationcreateLeaseArgs = {
  createLeaseInput: CreateLeaseInput;
};

export type MutationcreateMetricsForSensorArgs = {
  measurementsNames: Array<MeasurementName>;
  sensorId: Scalars['Int']['input'];
};

export type MutationcreateOrganizationArgs = {
  organizationInput: OrganizationInput;
};

export type MutationcreateRegionArgs = {
  createRegionInput: CreateRegionInput;
};

export type MutationcreateSchedulerProfileArgs = {
  input: SchedulerProfileInput;
};

export type MutationcreateSiteArgs = {
  createSiteInput: CreateSiteInput;
};

export type MutationcreateSpaceArgs = {
  spaceInput: SpaceInput;
};

export type MutationcreateSubmeterRateArgs = {
  submeterRateInput: CreateSubmeterRateInput;
};

export type MutationcreateSystemArgs = {
  createSystemInput: CreateSystemInput;
};

export type MutationcreateTenantArgs = {
  createTenantInput: CreateTenantInput;
};

export type MutationcreteSensorArgs = {
  createSensorInput: CreateSensorInput;
};

export type MutationdeleteAdditionalChargeArgs = {
  chargeId: Scalars['Int']['input'];
};

export type MutationdeleteApiKeyArgs = {
  apiKeyId: Scalars['String']['input'];
};

export type MutationdeleteLeaseArgs = {
  leaseId: Scalars['Int']['input'];
};

export type MutationdeleteOverriddenSchedulerProfileArgs = {
  systemId: Scalars['Int']['input'];
};

export type MutationdeleteRegionArgs = {
  regionId: Scalars['Int']['input'];
};

export type MutationdeleteSchedulerProfileArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteSensorArgs = {
  sensorId: Scalars['Int']['input'];
};

export type MutationdeleteSiteArgs = {
  siteId: Scalars['Int']['input'];
};

export type MutationdeleteSpaceArgs = {
  softDelete: Scalars['Boolean']['input'];
  spaceId: Scalars['Int']['input'];
};

export type MutationdeleteSystemArgs = {
  systemId: Scalars['Int']['input'];
};

export type MutationdeleteSystemFromSpaceArgs = {
  softDelete: Scalars['Boolean']['input'];
  spaceId: Scalars['Int']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationexpireChargeForNextBillArgs = {
  chargeId: Scalars['Int']['input'];
};

export type MutationmigratePastMetricsArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MutationmigratePastMetricsInChunksArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MutationoverrideDRSequenceArgs = {
  systemId: Scalars['Int']['input'];
};

export type MutationoverrideSchedulerProfileArgs = {
  input: OverrideSchedulerProfileInput;
};

export type MutationprocessSystemGroupsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  systemGroupInput?: InputMaybe<SystemGroupInput>;
};

export type MutationsetOrgSitesFeaturesArgs = {
  orgSiteFeaturesInput: SiteFeaturesInput;
  organizationId: Scalars['Int']['input'];
  siteId: Scalars['Int']['input'];
};

export type MutationtransferDeviceMetricsArgs = {
  newDeviceId: Scalars['String']['input'];
  oldDeviceId: Scalars['String']['input'];
};

export type MutationupdateAdditionalChargeArgs = {
  chargeId: Scalars['Int']['input'];
  siteId: Scalars['Int']['input'];
  updateAdditionalChargeInput: UpdateAdditionalChargeInput;
};

export type MutationupdateChargePointSystemModeArgs = {
  mode: ChargePointStationModeType;
  systemId: Scalars['Int']['input'];
};

export type MutationupdateChargePointSystemSettingsArgs = {
  systemId: Scalars['Int']['input'];
  updateStationSettings: UpdateChargePointStationSettings;
};

export type MutationupdateLeaseArgs = {
  leaseId: Scalars['Int']['input'];
  updateLeaseInput: UpdateLeaseInput;
};

export type MutationupdateLightingSystemModeArgs = {
  mode: SystemStatus;
  systemId: Scalars['Int']['input'];
};

export type MutationupdateOrganizationArgs = {
  id: Scalars['Int']['input'];
  organizationInput: OrganizationInput;
};

export type MutationupdatePackageSystemFanArgs = {
  fan: FanType;
  systemId: Scalars['Int']['input'];
};

export type MutationupdatePackageSystemModeArgs = {
  mode: ModeType;
  systemId: Scalars['Int']['input'];
};

export type MutationupdatePackageSystemResetFilterArgs = {
  systemId: Scalars['Int']['input'];
};

export type MutationupdatePackageSystemSetpointArgs = {
  setpoint: Scalars['Int']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationupdatePackageSystemSettingsArgs = {
  updatePackageAltc24ProgInput: UpdateSystemPackageAltc24ProgInput;
};

export type MutationupdateSchedulerProfileArgs = {
  id: Scalars['Int']['input'];
  input: SchedulerProfileInput;
};

export type MutationupdateSchedulerProfilesForSiteArgs = {
  input: Array<UpdateSchedulerProfileForSiteInput>;
  siteId: Scalars['Int']['input'];
};

export type MutationupdateSensorArgs = {
  sensorId: Scalars['Int']['input'];
  updateSensorInput: UpdateSensorInput;
};

export type MutationupdateSequenceArgs = {
  sequenceId: Scalars['Int']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationupdateSetpointArgs = {
  setpoint: Scalars['Int']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationupdateSiteArgs = {
  id: Scalars['Int']['input'];
  updateSiteInput: UpdateSiteInput;
};

export type MutationupdateSiteFitwellArgs = {
  site_id: Scalars['Int']['input'];
  updateSiteFitwellInput?: InputMaybe<UpdateSiteFitwellInput>;
};

export type MutationupdateSmartOutletSystemModeArgs = {
  mode: SystemStatus;
  systemId: Scalars['Int']['input'];
};

export type MutationupdateSpaceArgs = {
  spaceData: UpdateSpaceInput;
  spaceId: Scalars['Int']['input'];
};

export type MutationupdateSystemArgs = {
  systemId: Scalars['Int']['input'];
  updateSystemInput: UpdateSystemInput;
};

export type MutationupdateSystemSettingsArgs = {
  outsideTempMax: Scalars['Int']['input'];
  outsideTempMin: Scalars['Int']['input'];
  setpointMax: Scalars['Int']['input'];
  setpointMin: Scalars['Int']['input'];
  systemId: Scalars['Int']['input'];
};

export type MutationupdateTenantArgs = {
  tenantId: Scalars['Int']['input'];
  updateTenantInput: UpdateTenantInput;
};

export type MutationupsertSiteFeaturesArgs = {
  siteFeaturesInput: SiteFeaturesInput;
  siteId: Scalars['Int']['input'];
};

export enum OccupancyType {
  EXTERNAL = 'EXTERNAL',
  LOCAL = 'LOCAL',
  MANUAL = 'MANUAL',
}

export enum OnlineStatus {
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export type OrderedSite = {
  availableSystemByTypes?: Maybe<Array<SystemType>>;
  city: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Latitude']['output'];
  longitude: Scalars['Longitude']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  owner?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  primaryPropertyType?: Maybe<SitePrimaryPropertyType>;
  regionId: Scalars['Int']['output'];
  siteFeatures: SiteFeatures;
  squareFeet: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  streetNumber?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  utilityServiceTypes: Array<UtilityServiceTypes>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
  zipCode: Scalars['String']['output'];
};

export type OrgSiteFeatures = {
  siteFeatures: SiteFeatures;
  siteId: Scalars['Int']['output'];
};

export type Organization = {
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type OrganizationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type OverrideSchedulerProfileInput = {
  endTime: Scalars['DateTime']['input'];
  sequenceId: Scalars['Int']['input'];
  settings?: InputMaybe<SchedulerProfileSystemConfigurationSettingsInput>;
  systemId: Scalars['Int']['input'];
};

export type PaginatedSensorGeneralInfo = {
  data: Array<SensorExtendedGeneralInfo>;
  pagination: Pagination;
};

export type PaginatedSystemGeneralInfo = {
  data: Array<SystemPaginatedGeneralInfo>;
  pagination: Pagination;
};

export type Pagination = {
  currentPage: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  nextPage: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  prevPage: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PeakRange = {
  hours: Array<Scalars['Int']['output']>;
  weekday: WeekDays;
};

export type PeakRangeInput = {
  hours: Array<Scalars['Int']['input']>;
  weekday: WeekDays;
};

export type PendingAdjustmentInfo = {
  amountPerInvoice: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  leaseId: Scalars['Int']['output'];
  leaseStartBillingDate: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  nextInvoiceDate: Scalars['Date']['output'];
  startDate: Scalars['Date']['output'];
  tenantName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type Query = {
  apiKeys: Array<ApiKey>;
  defaultSetpointRange: DefaultSetpointRange;
  getAdditionalChargesForSite: Array<AdditionalCharge>;
  getCPStationSitesOverview: Array<ChargePointStationsOverviewBySite>;
  getCalendarizedMeterConsumptionData: Array<CalendarizedMeterConsumption>;
  getChargePointInsightsKPI: ChargePointStationsInsightsKPI;
  getChargePointInsightsTableData: Array<ChargePointStationsInsightsTableData>;
  getChargePointStationsMetrics: Array<ChargePointSystemMetric>;
  getChargeStartDay: DateInterval;
  getConnectedEntities: ConnectedToGateway;
  getFloorsForSite: Array<SiteFloor>;
  getFutureBusyDatesForSpaces: Array<DateInterval>;
  getHistoricalAdjustmentsForSite: Array<HistoricalAdjustment>;
  getHwBoilerHtHwrDetails: Array<SystemHwBoilerHtHwrDetails>;
  getHwBoilerTekmarDetails: Array<SystemHwBoilerTekmarDetails>;
  getIAQScoreConfigurations: IaqScoreConfig;
  getIAQScorePerSite: Array<IaqScorePerSite>;
  getIAQsAvgTrendsDataForSite?: Maybe<CompressedSystemMetrics>;
  getIAQsHeatmapDataForSite: Array<IAQMetricsHeatMapData>;
  getIAQsTrendsDataForSite?: Maybe<CompressedSystemTrends>;
  getInvoicesForSite: Array<Invoice>;
  getInvoicesKPIForSite: InvoicesKPIForSite;
  getLeaseInfo: Lease;
  getLeasesBySite: Array<LeaseShort>;
  getLeasesExpirationYears: Array<Scalars['Float']['output']>;
  getLeasesOverview: Array<LeaseOverview>;
  getMeterConsumptionBySite: Array<SystemMetersConsumptionOverviewBySite>;
  getOrgSitesFeatures: Array<OrgSiteFeatures>;
  getPackageAltc24ProgDetails: Array<SystemPackageAltc24ProgDetails>;
  getPaginatedSensorFullInfo: PaginatedSensorGeneralInfo;
  getPaginatedSystemGeneralInfo: PaginatedSystemGeneralInfo;
  getPendingAdjustmentsForSite: Array<PendingAdjustmentInfo>;
  getRegionsToSystemsTree: Array<RegionNode>;
  getSchedulerCoverage: Array<SchedulerProfileCoverage>;
  getSensorFullInfo: SensorFullInfo;
  getSensorsLines: Array<Scalars['String']['output']>;
  getSetpointDetails: Array<SetpointDetails>;
  getShadowMeterBreakdownTableData: Array<SystemMeterBreakdownTable>;
  getSmartOutletTrendsForSystems: Array<SmartOutletTrendsData>;
  getSpaceById: Space;
  getSpaceOccupationHistoryTableData: Array<SpaceOccupationHistoryTableData>;
  getSpaceTemperature: Array<SpaceTemperature>;
  getSpacesLeasesOverview: Array<SpacesLeasesOverview>;
  getSpacesOverview: Array<SpaceOverview>;
  getStackingPlanBySite: Array<StackingPlan>;
  getSubmeterTrendsForSite: Array<SubmetersTrendsData>;
  getSubmeterTrendsKPIForSite: ElectricitySubmeterTrendsKPI;
  getSubmeterTypesForSite: Array<Scalars['String']['output']>;
  getSubmetersDetailsForSite: Array<ElectricitySubmeterDetails>;
  getSubmetersHeatmapDataForSite: Array<SubmetersHeatMapData>;
  getSystemFullInfo: SystemFullInfo;
  getSystemModels: Array<SystemModel>;
  getSystemSchedulePlan: Array<SchedulePlan>;
  /** @deprecated API deprecated because not being used on Orion since 07/21 */
  getTenantById: Tenant;
  getTenantsBySite: Array<Tenant>;
  getUpcomingSchedulerConfig?: Maybe<UpcomingScheduleConfig>;
  iaqAvgMetricData: IAQSystemMetricData;
  organization: Organization;
  organizations: Array<Organization>;
  regions: Array<Region>;
  schedulerProfile: SchedulerProfile;
  schedulerProfilesForSite: Array<SchedulerProfileForSite>;
  site: OrderedSite;
  siteFitwell?: Maybe<SiteFitwell>;
  siteSpacesOptions: Array<SiteSpaceOption>;
  sites: Array<OrderedSite>;
  sitesBillingOverview: Array<SiteBillingOverview>;
  system: SystemUnion;
  systemGroup: SystemGroup;
  systemGroups: Array<SystemGroup>;
  systemMetrics: Array<SystemMetric>;
  systemMetricsDataByIntervals: Array<SystemMetricData>;
  systemOptionsForSite: Array<SystemOption>;
  systemTypeSensors: Array<SystemTypeSensors>;
  systems: Array<SystemUnion>;
  systemsElectricityShortInfoBySiteId: Array<SystemElectricityShortInfo>;
  systemsShortInfoBySiteId: Array<SystemShortInfo>;
  tenantLeaseOptions: Array<TenantLeaseOption>;
};

export type QueryapiKeysArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerydefaultSetpointRangeArgs = {
  systemType: SystemType;
};

export type QuerygetAdditionalChargesForSiteArgs = {
  chargesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  siteId: Scalars['Int']['input'];
};

export type QuerygetCPStationSitesOverviewArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerygetCalendarizedMeterConsumptionDataArgs = {
  filter: SystemElectricitySubmeterFilterInput;
  monthPeriod: MonthRangeInput;
  siteId: Scalars['Int']['input'];
  type: SystemType;
};

export type QuerygetChargePointInsightsKPIArgs = {
  chargePointFilter: SystemChargePointStationInsightsFilterInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetChargePointInsightsTableDataArgs = {
  chargePointFilter: SystemChargePointStationInsightsFilterInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetChargePointStationsMetricsArgs = {
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  portNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  systemIds: Array<Scalars['Int']['input']>;
};

export type QuerygetChargeStartDayArgs = {
  billingStart: Scalars['Date']['input'];
  leaseEnd?: InputMaybe<Scalars['Date']['input']>;
  leaseStart: Scalars['Date']['input'];
  siteToday: Scalars['Date']['input'];
};

export type QuerygetConnectedEntitiesArgs = {
  gatewayId: Scalars['Int']['input'];
};

export type QuerygetFloorsForSiteArgs = {
  only?: InputMaybe<FloorUseTypes>;
  siteId: Scalars['Int']['input'];
};

export type QuerygetFutureBusyDatesForSpacesArgs = {
  leaseId?: InputMaybe<Scalars['Int']['input']>;
  spaceIds: Array<Scalars['Int']['input']>;
};

export type QuerygetHistoricalAdjustmentsForSiteArgs = {
  filter: InvoicesFilterInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetHwBoilerHtHwrDetailsArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetHwBoilerTekmarDetailsArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetIAQScorePerSiteArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type QuerygetIAQsAvgTrendsDataForSiteArgs = {
  filters: SystemIAQFilterInput;
  metricType: MetricType;
  metricsFilter: MetricDataIntervalsInput;
};

export type QuerygetIAQsHeatmapDataForSiteArgs = {
  filters: SystemIAQFilterInput;
  metricType: MetricType;
  metricsFilter: MetricDataIntervalsInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetIAQsTrendsDataForSiteArgs = {
  filters: SystemIAQFilterInput;
  metricType: MetricType;
  metricsFilter: MetricDataIntervalsInput;
};

export type QuerygetInvoicesForSiteArgs = {
  filter: InvoicesFilterInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetInvoicesKPIForSiteArgs = {
  filter: InvoicesFilterInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetLeaseInfoArgs = {
  leaseId: Scalars['Int']['input'];
};

export type QuerygetLeasesBySiteArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerygetLeasesExpirationYearsArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerygetLeasesOverviewArgs = {
  filter: LeasesOverviewFilter;
  siteId: Scalars['Int']['input'];
};

export type QuerygetMeterConsumptionBySiteArgs = {
  siteIds: Array<Scalars['Int']['input']>;
  type: SystemType;
};

export type QuerygetOrgSitesFeaturesArgs = {
  organizationId: Scalars['Int']['input'];
};

export type QuerygetPackageAltc24ProgDetailsArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetPaginatedSensorFullInfoArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  filter: SensorFullDetailsFilterInput;
  getAll?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetPaginatedSystemGeneralInfoArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  filter: SystemGeneralFilterInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetPendingAdjustmentsForSiteArgs = {
  siteId: Scalars['Int']['input'];
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QuerygetRegionsToSystemsTreeArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetSchedulerCoverageArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerygetSensorFullInfoArgs = {
  sensorId: Scalars['Int']['input'];
};

export type QuerygetSensorsLinesArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerygetSetpointDetailsArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetShadowMeterBreakdownTableDataArgs = {
  period: TimeInterval;
  systemIds: Array<Scalars['Int']['input']>;
};

export type QuerygetSmartOutletTrendsForSystemsArgs = {
  filter: SystemSmartOutletFilterInput;
  systemsIds: Array<Scalars['Int']['input']>;
};

export type QuerygetSpaceByIdArgs = {
  spaceId: Scalars['Int']['input'];
};

export type QuerygetSpaceOccupationHistoryTableDataArgs = {
  spaceId: Scalars['Int']['input'];
};

export type QuerygetSpaceTemperatureArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetSpacesLeasesOverviewArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerygetSpacesOverviewArgs = {
  filter: SpacesOverviewFilter;
  siteId: Scalars['Int']['input'];
};

export type QuerygetStackingPlanBySiteArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerygetSubmeterTrendsForSiteArgs = {
  metricType: MetricType;
  metricsFilter: MetricDataIntervalsInput;
  siteId: Scalars['Int']['input'];
  submeterFilter: SystemElectricitySubmeterFilterInput;
};

export type QuerygetSubmeterTrendsKPIForSiteArgs = {
  metricsFilter: MetricDataIntervalsInput;
  siteId: Scalars['Int']['input'];
  submeterFilter: SystemElectricitySubmeterFilterInput;
};

export type QuerygetSubmeterTypesForSiteArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerygetSubmetersDetailsForSiteArgs = {
  filter: SystemElectricitySubmeterFilterInput;
  siteId: Scalars['Int']['input'];
};

export type QuerygetSubmetersHeatmapDataForSiteArgs = {
  metricType: MetricType;
  metricsFilter: MetricDataIntervalsInput;
  siteId: Scalars['Int']['input'];
  submeterFilter: SystemElectricitySubmeterFilterInput;
};

export type QuerygetSystemFullInfoArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetSystemSchedulePlanArgs = {
  systemId: Scalars['Int']['input'];
};

export type QuerygetTenantByIdArgs = {
  tenantId: Scalars['Int']['input'];
};

export type QuerygetTenantsBySiteArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerygetUpcomingSchedulerConfigArgs = {
  systemId: Scalars['Int']['input'];
};

export type QueryiaqAvgMetricDataArgs = {
  from: Scalars['DateTime']['input'];
  step: MetricsStep;
  systemsIds: Array<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};

export type QueryorganizationArgs = {
  id: Scalars['Int']['input'];
};

export type QueryregionsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryschedulerProfileArgs = {
  id: Scalars['Int']['input'];
};

export type QueryschedulerProfilesForSiteArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerysiteArgs = {
  id: Scalars['Int']['input'];
};

export type QuerysiteFitwellArgs = {
  id: Scalars['Int']['input'];
};

export type QuerysiteSpacesOptionsArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerysitesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QuerysitesBillingOverviewArgs = {
  siteIds: Array<Scalars['Int']['input']>;
};

export type QuerysystemArgs = {
  id: Scalars['Int']['input'];
};

export type QuerysystemGroupArgs = {
  id: Scalars['Int']['input'];
};

export type QuerysystemGroupsArgs = {
  siteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  types?: InputMaybe<Array<SystemType>>;
};

export type QuerysystemMetricsArgs = {
  systemIds: Array<Scalars['Int']['input']>;
};

export type QuerysystemMetricsDataByIntervalsArgs = {
  metricDataIntervalInput: MetricDataIntervalsInput;
  metricIds: Array<Scalars['Int']['input']>;
};

export type QuerysystemOptionsForSiteArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['Int']['input'];
};

export type QuerysystemsArgs = {
  siteIds: Array<Scalars['Int']['input']>;
  types: Array<SystemType>;
};

export type QuerysystemsElectricityShortInfoBySiteIdArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerysystemsShortInfoBySiteIdArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerytenantLeaseOptionsArgs = {
  tenantId: Scalars['Int']['input'];
};

export type Region = {
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

export type RegionNode = {
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sites: Array<SiteNode>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type SchedulePlan = {
  config: SchedulerProfileSystemConfiguration;
  overridden: Scalars['Boolean']['output'];
  scheduleName: Scalars['String']['output'];
};

export enum ScheduleProfilePermission {
  ALL_ACCESS = 'ALL_ACCESS',
  NO_ACCESS = 'NO_ACCESS',
  VIEW_ONLY_ACCESS = 'VIEW_ONLY_ACCESS',
}

export type SchedulerProfile = {
  dateConfiguration: SchedulerProfileDateConfiguration;
  id: Scalars['Int']['output'];
  isEditable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  selectedMoments: Array<Scalars['String']['output']>;
  siteId: Scalars['Int']['output'];
  systemsConfiguration: Array<SchedulerProfileConfiguration>;
};

export type SchedulerProfileConfiguration = {
  configurations: Array<SchedulerProfileSystemConfiguration>;
  systemIds: Array<Scalars['Int']['output']>;
  systemType: SystemType;
  systemsGroupName: Scalars['String']['output'];
};

export type SchedulerProfileConfigurationInput = {
  configurations: Array<SchedulerProfileSystemConfigurationInput>;
  systemIds: Array<Scalars['Int']['input']>;
  systemType: SystemType;
  systemsGroupName: Scalars['String']['input'];
};

export type SchedulerProfileCoverage = {
  apr?: Maybe<Scalars['Int']['output']>;
  aug?: Maybe<Scalars['Int']['output']>;
  dec?: Maybe<Scalars['Int']['output']>;
  feb?: Maybe<Scalars['Int']['output']>;
  jan?: Maybe<Scalars['Int']['output']>;
  jul?: Maybe<Scalars['Int']['output']>;
  jun?: Maybe<Scalars['Int']['output']>;
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  mar?: Maybe<Scalars['Int']['output']>;
  may?: Maybe<Scalars['Int']['output']>;
  nov?: Maybe<Scalars['Int']['output']>;
  oct?: Maybe<Scalars['Int']['output']>;
  schedulerAmount: Scalars['Int']['output'];
  sep?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  totalOfTheYear?: Maybe<Scalars['Int']['output']>;
};

export enum SchedulerProfileDateConfiguration {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export type SchedulerProfileForSite = {
  appliedToSystems: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  permission: ScheduleProfilePermission;
  siteId: Scalars['Int']['output'];
};

export type SchedulerProfileInput = {
  dateConfiguration: SchedulerProfileDateConfiguration;
  name: Scalars['String']['input'];
  selectedMoments: Array<Scalars['String']['input']>;
  siteId: Scalars['Int']['input'];
  systemsConfiguration: Array<SchedulerProfileConfigurationInput>;
};

export type SchedulerProfileSystemConfiguration = {
  date?: Maybe<Scalars['Date']['output']>;
  dayWeek: Scalars['Int']['output'];
  fromHour: Scalars['Int']['output'];
  sequenceId: Scalars['Int']['output'];
  settings?: Maybe<SchedulerProfileSystemConfigurationSettings>;
  toHour: Scalars['Int']['output'];
};

export type SchedulerProfileSystemConfigurationInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  dayWeek?: InputMaybe<Scalars['Int']['input']>;
  fromHour: Scalars['Int']['input'];
  month?: InputMaybe<Scalars['String']['input']>;
  sequenceId: Scalars['Int']['input'];
  settings?: InputMaybe<SchedulerProfileSystemConfigurationSettingsInput>;
  toHour: Scalars['Int']['input'];
};

export type SchedulerProfileSystemConfigurationSettings = {
  fan?: Maybe<FanType>;
  mode?: Maybe<ModeType>;
  occupiedSetpointCool?: Maybe<Scalars['Int']['output']>;
  occupiedSetpointHeat?: Maybe<Scalars['Int']['output']>;
  outsideTempMax?: Maybe<Scalars['Int']['output']>;
  outsideTempMin?: Maybe<Scalars['Int']['output']>;
  setLock?: Maybe<LockType>;
  setpoint?: Maybe<Scalars['Int']['output']>;
  setpointCool?: Maybe<Scalars['Int']['output']>;
  setpointHeat?: Maybe<Scalars['Int']['output']>;
  setpointMax?: Maybe<Scalars['Int']['output']>;
  setpointMin?: Maybe<Scalars['Int']['output']>;
  space?: Maybe<SpaceType>;
  systemState?: Maybe<SystemStatus>;
  unoccupiedSetpointCool?: Maybe<Scalars['Int']['output']>;
  unoccupiedSetpointHeat?: Maybe<Scalars['Int']['output']>;
};

export type SchedulerProfileSystemConfigurationSettingsInput = {
  fan?: InputMaybe<FanType>;
  mode?: InputMaybe<ModeType>;
  occupiedSetpointCool?: InputMaybe<Scalars['Int']['input']>;
  occupiedSetpointHeat?: InputMaybe<Scalars['Int']['input']>;
  outsideTempMax?: InputMaybe<Scalars['Int']['input']>;
  outsideTempMin?: InputMaybe<Scalars['Int']['input']>;
  setLock?: InputMaybe<LockType>;
  setpoint?: InputMaybe<Scalars['Int']['input']>;
  setpointCool?: InputMaybe<Scalars['Int']['input']>;
  setpointHeat?: InputMaybe<Scalars['Int']['input']>;
  setpointMax?: InputMaybe<Scalars['Int']['input']>;
  setpointMin?: InputMaybe<Scalars['Int']['input']>;
  space?: InputMaybe<SpaceType>;
  systemState?: InputMaybe<SystemStatus>;
  unoccupiedSetpointCool?: InputMaybe<Scalars['Int']['input']>;
  unoccupiedSetpointHeat?: InputMaybe<Scalars['Int']['input']>;
};

export type SensorDeviceIntegrationInput = {
  appEui?: InputMaybe<Scalars['String']['input']>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  communicationId?: InputMaybe<Scalars['String']['input']>;
  deviceProfileId?: InputMaybe<Scalars['String']['input']>;
  gatewayId?: InputMaybe<Scalars['Int']['input']>;
  gatewayVersion?: InputMaybe<GatewayVersion>;
  installationDate?: InputMaybe<Scalars['Date']['input']>;
  ipAddress?: InputMaybe<Scalars['IP']['input']>;
  networkIndex?: InputMaybe<Scalars['Int']['input']>;
  physicalLocation?: InputMaybe<Scalars['String']['input']>;
  routeIPAddress?: InputMaybe<Scalars['IP']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  subchannel?: InputMaybe<Scalars['Float']['input']>;
  subnetMask?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export enum SensorDeviceType {
  DAMPER_EFFECTIVE = 'DAMPER_EFFECTIVE',
  DAMPER_EXPECTED = 'DAMPER_EXPECTED',
  PRESSURE = 'PRESSURE',
  SPACE_TEMPERATURE = 'SPACE_TEMPERATURE',
  TEMPERATURE_HUMIDITY = 'TEMPERATURE_HUMIDITY',
  WATER_FLOW = 'WATER_FLOW',
}

export type SensorExtendedGeneralInfo = {
  communicationId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['Int']['output']>;
  floor: Scalars['String']['output'];
  floorType: FloorType;
  gatewayId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  line?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  status?: Maybe<SystemStatus>;
  systemsCount: Scalars['Int']['output'];
  type: SensorDeviceType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SensorFullDetailsFilterInput = {
  gatewayIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  gatewayVersions?: InputMaybe<Array<GatewayVersion>>;
  organizationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sensorTypes?: InputMaybe<Array<SensorDeviceType>>;
  siteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SensorFullInfo = {
  description?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floorType: FloorType;
  id: Scalars['Int']['output'];
  integrationDetails?: Maybe<DeviceIntegration>;
  line?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  status?: Maybe<SystemStatus>;
  systems?: Maybe<Array<SystemShortInfo>>;
  type: SensorDeviceType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SensorToSystemInput = {
  status: Scalars['Boolean']['input'];
  systemId: Scalars['Int']['input'];
};

export enum SensorType {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT',
}

export type SetpointDetails = {
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  pressure?: Maybe<Scalars['Int']['output']>;
  setPoint?: Maybe<Scalars['Int']['output']>;
  signal?: Maybe<Scalars['Int']['output']>;
};

export type Site = {
  availableSystemByTypes?: Maybe<Array<SystemType>>;
  city: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Latitude']['output'];
  longitude: Scalars['Longitude']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  primaryPropertyType?: Maybe<SitePrimaryPropertyType>;
  regionId: Scalars['Int']['output'];
  siteFeatures: SiteFeatures;
  squareFeet: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  streetNumber?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  utilityServiceTypes: Array<UtilityServiceTypes>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
  zipCode: Scalars['String']['output'];
};

export type SiteADRFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteADRFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SiteAlertsFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteAlertsFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SiteBenchmarkingFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteBenchmarkingFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SiteBillingFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteBillingFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SiteBillingOverview = {
  leaseCount?: Maybe<Scalars['Int']['output']>;
  pendingAdjustmentsCount?: Maybe<Scalars['Int']['output']>;
  percentToPreviousPeriod?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  totalOneMonthAgo?: Maybe<Scalars['Float']['output']>;
  totalTwoMonthAgo?: Maybe<Scalars['Float']['output']>;
};

export type SiteFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export enum SiteFeatureType {
  ADR = 'ADR',
  ALERTS = 'ALERTS',
  BENCHMARKING = 'BENCHMARKING',
  BILLING = 'BILLING',
  LEASES = 'LEASES',
  SCHEDULING = 'SCHEDULING',
  SPACE_MANAGEMENT = 'SPACE_MANAGEMENT',
  UTILITIES = 'UTILITIES',
}

export type SiteFeatures = {
  adr?: Maybe<SiteADRFeature>;
  alerts?: Maybe<SiteAlertsFeature>;
  benchmarking?: Maybe<SiteBenchmarkingFeature>;
  billing?: Maybe<SiteBillingFeature>;
  leases?: Maybe<SiteLeasesFeature>;
  scheduling?: Maybe<SiteSchedulingFeature>;
  spaceManagement?: Maybe<SiteSpaceMAnagementFeature>;
  utilities?: Maybe<SiteUtilitiesFeature>;
};

export type SiteFeaturesInput = {
  adr: SiteADRFeatureInput;
  alerts: SiteAlertsFeatureInput;
  benchmarking: SiteBenchmarkingFeatureInput;
  billing: SiteBillingFeatureInput;
  leases: SiteLeasesFeatureInput;
  scheduling: SiteSchedulingFeatureInput;
  spaceManagement: SiteSpaceMAnagementFeatureInput;
  utilities: SiteUtilitiesFeatureInput;
};

export type SiteFitwell = {
  ch2o?: Maybe<Scalars['Int']['output']>;
  co?: Maybe<Scalars['Float']['output']>;
  co2?: Maybe<Scalars['Int']['output']>;
  o3?: Maybe<Scalars['Int']['output']>;
  pm2_5?: Maybe<Scalars['Float']['output']>;
  pm10?: Maybe<Scalars['Float']['output']>;
  relativeHumidity?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Int']['output']>;
  tvoc?: Maybe<Scalars['Int']['output']>;
};

export type SiteFloor = {
  floor: Scalars['String']['output'];
  type: FloorType;
};

export type SiteLeasesFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteLeasesFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SiteNode = {
  city: Scalars['String']['output'];
  features: Array<SiteFeature>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  streetNumber?: Maybe<Scalars['String']['output']>;
  systems: Array<SystemLeaf>;
};

export enum SitePrimaryPropertyType {
  COMMERCIAL = 'COMMERCIAL',
  INDUSTRIAL = 'INDUSTRIAL',
  MIXED_USE = 'MIXED_USE',
  MULTIFAMILY_HOUSING = 'MULTIFAMILY_HOUSING',
  RETAIL = 'RETAIL',
}

export type SiteSchedulingFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteSchedulingFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SiteSpaceMAnagementFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteSpaceMAnagementFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SiteSpaceOption = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SiteUtilitiesFeature = {
  isActive: Scalars['Boolean']['output'];
  type: SiteFeatureType;
};

export type SiteUtilitiesFeatureInput = {
  isActive: Scalars['Boolean']['input'];
  type: SiteFeatureType;
};

export type SmartOutletTrendsData = {
  exists: Scalars['Boolean']['output'];
  systemId: Scalars['Int']['output'];
  systemName: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type Space = {
  floor: Scalars['String']['output'];
  floorType: FloorType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  siteId: Scalars['Int']['output'];
  spaceSystems?: Maybe<Array<SpaceSystem>>;
  squareFeet: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SpaceInput = {
  floor: Scalars['String']['input'];
  floorType: FloorType;
  name: Scalars['String']['input'];
  siteId: Scalars['Int']['input'];
  squareFeet: Scalars['Int']['input'];
  type: SpaceOccupationType;
};

export type SpaceOccupationHistoryTableData = {
  billable: Scalars['Boolean']['output'];
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  occupationDate: Scalars['Date']['output'];
  status: SpaceStatus;
  tenantName?: Maybe<Scalars['String']['output']>;
};

export enum SpaceOccupationType {
  COMMON = 'COMMON',
  LEASABLE = 'LEASABLE',
  MANAGEMENT = 'MANAGEMENT',
}

export type SpaceOverview = {
  floor: Scalars['String']['output'];
  floorOrder: Scalars['Int']['output'];
  floorType: FloorType;
  leaseId?: Maybe<Scalars['Int']['output']>;
  nameOrder: Scalars['Int']['output'];
  numberOfSystems: Scalars['Int']['output'];
  occupationEndDate?: Maybe<Scalars['Date']['output']>;
  occupationStartDate?: Maybe<Scalars['Date']['output']>;
  spaceId: Scalars['Int']['output'];
  spaceName: Scalars['String']['output'];
  squareFootage: Scalars['Int']['output'];
  status: SpaceStatus;
  tenantId?: Maybe<Scalars['Int']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
};

export enum SpaceStatus {
  COMMON = 'COMMON',
  MANAGEMENT = 'MANAGEMENT',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
}

export type SpaceSystem = {
  dateAdded: Scalars['Date']['output'];
  floor: Scalars['String']['output'];
  occupation: Scalars['Int']['output'];
  systemId: Scalars['Int']['output'];
  systemName: Scalars['String']['output'];
  systemType: SystemType;
};

export type SpaceTemperature = {
  floor: Scalars['String']['output'];
  floorType: FloorType;
  id: Scalars['Float']['output'];
  line: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export enum SpaceType {
  BYPASS = 'BYPASS',
  NO_OVERRIDE = 'NO_OVERRIDE',
  NULL = 'NULL',
  OCCUPIED = 'OCCUPIED',
  STANDBY = 'STANDBY',
  UNOCCUPIED = 'UNOCCUPIED',
}

export type SpacesLeasesOverview = {
  commonSquareFootage: Scalars['Int']['output'];
  leasedSquareFootage: Scalars['Int']['output'];
  numberOfActiveLeases: Scalars['Int']['output'];
  numberOfSpaces: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  vacantSquareFootage: Scalars['Int']['output'];
};

export type SpacesOverviewFilter = {
  expirationYears?: InputMaybe<Array<Scalars['Int']['input']>>;
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<SpaceStatus>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type StackingPlan = {
  expirationDate?: Maybe<Scalars['Date']['output']>;
  floor: Scalars['String']['output'];
  floorType: FloorType;
  isActive: Scalars['Boolean']['output'];
  isCommonArea?: Maybe<Scalars['Boolean']['output']>;
  isManagementArea?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  occupationDate?: Maybe<Scalars['Date']['output']>;
  spaceId: Scalars['Int']['output'];
  squareFeet: Scalars['Int']['output'];
  tenantId?: Maybe<Scalars['Int']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
};

export type SubmeterRate = {
  demandOffPeakRate: Scalars['Float']['output'];
  demandOnPeakRate?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  energyOffPeakRate: Scalars['Float']['output'];
  energyOnPeakRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  leaseId: Scalars['Int']['output'];
  peakRanges?: Maybe<Array<PeakRange>>;
  startDate: Scalars['Date']['output'];
  systemId: Scalars['Int']['output'];
};

export type SubmetersHeatMapData = {
  metricType: MetricType;
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type SubmetersTrendsData = {
  exists: Scalars['Boolean']['output'];
  isCommon?: Maybe<Scalars['Boolean']['output']>;
  isManagement?: Maybe<Scalars['Boolean']['output']>;
  isVacant?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  occupancyType: SpaceStatus;
  spaceId?: Maybe<Scalars['Int']['output']>;
  spaceName?: Maybe<Scalars['String']['output']>;
  systemId: Scalars['Int']['output'];
  systemName: Scalars['String']['output'];
  tenantId?: Maybe<Scalars['Int']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  usage: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type SystemChargePointStation = {
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  ports: Array<ChargePointStationPort>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  station: ChargePointStation;
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemChargePointStationInsightsFilterInput = {
  levels?: InputMaybe<Array<Scalars['String']['input']>>;
  period: TimeInterval;
  portNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  previousPeriod?: InputMaybe<TimeInterval>;
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SystemDeviceIntegrationInput = {
  appEui?: InputMaybe<Scalars['String']['input']>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  communicationId?: InputMaybe<Scalars['String']['input']>;
  controlParams?: InputMaybe<Scalars['String']['input']>;
  deviceProfileId?: InputMaybe<Scalars['String']['input']>;
  gatewayId?: InputMaybe<Scalars['Int']['input']>;
  gatewayVersion?: InputMaybe<GatewayVersion>;
  installationDate?: InputMaybe<Scalars['Date']['input']>;
  ipAddress?: InputMaybe<Scalars['IP']['input']>;
  networkIndex?: InputMaybe<Scalars['Int']['input']>;
  physicalLocation?: InputMaybe<Scalars['String']['input']>;
  resetStationCode?: InputMaybe<Scalars['String']['input']>;
  routeIPAddress?: InputMaybe<Scalars['IP']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  subchannel?: InputMaybe<Scalars['Float']['input']>;
  subnetMask?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export enum SystemEcobeeMode {
  auto = 'auto',
  auxHeatOnly = 'auxHeatOnly',
  cool = 'cool',
  heat = 'heat',
  off = 'off',
}

export type SystemElectricityMeter = {
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemElectricityShortInfo = {
  floor: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  submeterType: Scalars['String']['output'];
};

export type SystemElectricitySubmeter = {
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  submeterType: Scalars['String']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemElectricitySubmeterFilterInput = {
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  spaceStatuses?: InputMaybe<Array<SpaceStatus>>;
  submeterTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SystemExtraFields = {
  groupId?: Maybe<Scalars['Int']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  occupancyType?: Maybe<OccupancyType>;
  squareFeet?: Maybe<Scalars['Int']['output']>;
  submeterType?: Maybe<Scalars['String']['output']>;
};

export type SystemExtraFieldsCreateInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  occupancyType?: InputMaybe<OccupancyType>;
  submeterType?: InputMaybe<Scalars['String']['input']>;
};

export type SystemExtraFieldsUpdateInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  occupancyType?: InputMaybe<OccupancyType>;
  submeterType?: InputMaybe<Scalars['String']['input']>;
};

export type SystemFullInfo = {
  configurationUpdatedAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraFields: SystemExtraFields;
  floor: Scalars['String']['output'];
  floorType: FloorType;
  id: Scalars['Int']['output'];
  integrationDetails?: Maybe<DeviceIntegration>;
  meterId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organizationIds: Array<Scalars['Int']['output']>;
  sensors?: Maybe<Array<SystemSensorGeneralInfo>>;
  siteId: Scalars['Int']['output'];
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemGeneralFilterInput = {
  gatewayIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  gatewayVersions?: InputMaybe<Array<GatewayVersion>>;
  groupTypes?: InputMaybe<Array<SystemGroupType>>;
  organizationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SystemGroup = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  siteId: Scalars['Int']['output'];
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemGroupInput = {
  name: Scalars['String']['input'];
  siteId: Scalars['Int']['input'];
  type: SystemType;
};

export enum SystemGroupType {
  AIR_HANDLING_UNIT = 'AIR_HANDLING_UNIT',
  CHARGE_POINT = 'CHARGE_POINT',
  LIGHTING_SYSTEM = 'LIGHTING_SYSTEM',
  METER = 'METER',
  SMART_OUTLET = 'SMART_OUTLET',
  STEAM_BOILER = 'STEAM_BOILER',
  STEAM_PRV = 'STEAM_PRV',
  SUBMETER = 'SUBMETER',
  TBL_TCI = 'TBL_TCI',
  WEATHER_STATION = 'WEATHER_STATION',
}

export type SystemHwBoilerHtHwr = {
  averageSpaceTemperature?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  oat?: Maybe<Scalars['Int']['output']>;
  oatRangeHigh: Scalars['Int']['output'];
  oatRangeLow: Scalars['Int']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sensorType: SensorType;
  sequence: SystemSequence;
  setpoint: Scalars['Int']['output'];
  setpointMax: Scalars['Int']['output'];
  setpointMin: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  spaceTempRangeHigh: Scalars['Int']['output'];
  spaceTempRangeLow: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  supplyTemperature: Scalars['Int']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemHwBoilerHtHwrDetails = {
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SystemHwBoilerTekmar248 = {
  averageSpaceTemperature?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  oat?: Maybe<Scalars['Int']['output']>;
  oatRangeHigh: Scalars['Int']['output'];
  oatRangeLow: Scalars['Int']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  setpoint: Scalars['Int']['output'];
  setpointMax: Scalars['Int']['output'];
  setpointMin: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  spaceTempRangeHigh: Scalars['Int']['output'];
  spaceTempRangeLow: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  supplyTemperature: Scalars['Int']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemHwBoilerTekmarDetails = {
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

export type SystemIAQFilterInput = {
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  step?: InputMaybe<MetricsStep>;
  systemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SystemLeaf = {
  floor?: Maybe<Scalars['String']['output']>;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationIds: Array<Scalars['Int']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
};

export type SystemLightingVive = {
  disableOccupancy: Scalars['Boolean']['output'];
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  lightingState: SystemStatus;
  loadShedAllowed: Scalars['Boolean']['output'];
  loadShedGoal: Scalars['Int']['output'];
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  maximumPower: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  oat?: Maybe<Scalars['Int']['output']>;
  occupancyMode: Scalars['String']['output'];
  occupancyState: Scalars['String']['output'];
  occupiedLevel: Scalars['Int']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  setpoint: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  totalPower: Scalars['Int']['output'];
  type: SystemType;
  unoccupiedLevel: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemMeterBreakdownTable = {
  consumption?: Maybe<Scalars['Float']['output']>;
  peakDemand?: Maybe<Scalars['Float']['output']>;
  peakDemandTimestamp?: Maybe<Scalars['DateTime']['output']>;
  percentOfTotal?: Maybe<Scalars['Int']['output']>;
  systemId: Scalars['Int']['output'];
};

export type SystemMetersConsumptionOverviewBySite = {
  changeFromPreviousMonth?: Maybe<Scalars['Int']['output']>;
  consumptionForCurrentMonth?: Maybe<Scalars['Int']['output']>;
  consumptionForPreviousMonth?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  projConsumptionForCurrentMonth?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
};

export type SystemMetric = {
  id: Scalars['Int']['output'];
  measurement: MetricsMeasurementType;
  name: Scalars['String']['output'];
  relationType: MetricsRelationType;
  systemId: Scalars['Int']['output'];
  type: MetricType;
  view: MetricsView;
};

export type SystemMetricData = {
  exists: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  systemMetricId: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export enum SystemMode {
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE',
}

export type SystemModel = {
  modelId: Scalars['Int']['output'];
  modelName: Scalars['String']['output'];
  systemType: SystemType;
};

export type SystemNaturalGasMeter = {
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemOption = {
  floor: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationIds: Array<Scalars['Int']['output']>;
  type: SystemType;
};

export type SystemOverriddenSchedulerProfileModel = {
  endTime: Scalars['DateTime']['output'];
  sequenceId: Scalars['Int']['output'];
  settings?: Maybe<SchedulerProfileSystemConfigurationSettings>;
};

export type SystemPackageAltc24Prog = {
  economySetpointCool?: Maybe<Scalars['Int']['output']>;
  economySetpointHeat?: Maybe<Scalars['Int']['output']>;
  fan: FanType;
  fanEffective: FanType;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  focusAlgorithm?: Maybe<LearningAlgorithmType>;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  model: Altc24ProgSystemModelInformation;
  name: Scalars['String']['output'];
  oat?: Maybe<Scalars['Int']['output']>;
  occupancyType: OccupancyType;
  occupiedSetpointCool?: Maybe<Scalars['Int']['output']>;
  occupiedSetpointHeat?: Maybe<Scalars['Int']['output']>;
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  packageSystemMode: ModeType;
  packageSystemModeEffective: ModeType;
  replaceFilter?: Maybe<FilterAlarmType>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sensorType: SensorType;
  sequence: SystemSequence;
  setLock: LockType;
  setpoint: Scalars['Int']['output'];
  setpointConfiguration: Scalars['String']['output'];
  setpointCool: Scalars['Int']['output'];
  setpointHeat: Scalars['Int']['output'];
  setpointMax: Scalars['Int']['output'];
  setpointMin: Scalars['Int']['output'];
  shiftBetweenCoolStages?: Maybe<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  space?: Maybe<SpaceType>;
  spaceEffective: SpaceType;
  spaceTemperature: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  unoccupiedSetpointCool?: Maybe<Scalars['Int']['output']>;
  unoccupiedSetpointHeat?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemPackageAltc24ProgDetails = {
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SystemPackageEcobeeDefault = {
  coolRangeHigh?: Maybe<Scalars['Int']['output']>;
  coolRangeLow?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  heatRangeHigh?: Maybe<Scalars['Int']['output']>;
  heatRangeLow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  oat?: Maybe<Scalars['Int']['output']>;
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  setpoint: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  spaceTemperature?: Maybe<Scalars['Int']['output']>;
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  temperatureMode: SystemEcobeeMode;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemPaginatedGeneralInfo = {
  communicationId?: Maybe<Scalars['String']['output']>;
  configurationUpdatedAt: Scalars['DateTime']['output'];
  floor: Scalars['String']['output'];
  floorType: FloorType;
  gatewayId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  meterId?: Maybe<Scalars['Int']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organizationIds: Array<Scalars['Int']['output']>;
  sensorsCount: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemSchedulerProfileModel = {
  name: Scalars['String']['output'];
  underSchedulerControl: Scalars['Boolean']['output'];
};

export type SystemSensorGeneralInfo = {
  dateAdded: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['Int']['output']>;
  floor: Scalars['String']['output'];
  floorType: FloorType;
  id: Scalars['Int']['output'];
  line?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  status?: Maybe<SystemStatus>;
  type: SensorDeviceType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemSequence = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SystemShortInfo = {
  floor: Scalars['String']['output'];
  floorType: FloorType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  type: SystemType;
};

export type SystemSmartOutlet = {
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  outletState: SystemStatus;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemSmartOutletFilterInput = {
  from: Scalars['DateTime']['input'];
  step?: InputMaybe<MetricsStep>;
  to: Scalars['DateTime']['input'];
};

export type SystemStBoilerFHtMpc = {
  averageSpaceTemperature?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  oat?: Maybe<Scalars['Int']['output']>;
  oatRangeHigh: Scalars['Int']['output'];
  oatRangeLow: Scalars['Int']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sensorType: SensorType;
  sequence: SystemSequence;
  setpoint: Scalars['Int']['output'];
  setpointMax: Scalars['Int']['output'];
  setpointMin: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  spaceTempRangeHigh: Scalars['Int']['output'];
  spaceTempRangeLow: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  supplyTemperature: Scalars['Int']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum SystemStatus {
  OFF = 'OFF',
  ON = 'ON',
}

export type SystemSteamMeter = {
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemSteamPrvStation = {
  averageSpaceTemperature?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  oat?: Maybe<Scalars['Int']['output']>;
  oatRangeHigh: Scalars['Int']['output'];
  oatRangeLow: Scalars['Int']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  outletPressure: Scalars['Int']['output'];
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  setpoint: Scalars['Int']['output'];
  setpointMax: Scalars['Int']['output'];
  setpointMin: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  spaceTempRangeHigh: Scalars['Int']['output'];
  spaceTempRangeLow: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemTblTci = {
  ch2o?: Maybe<Scalars['Int']['output']>;
  co?: Maybe<Scalars['Float']['output']>;
  co2?: Maybe<Scalars['Int']['output']>;
  damperEffective?: Maybe<Scalars['Int']['output']>;
  damperPosition?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  model: IAQModelInformation;
  name: Scalars['String']['output'];
  o3?: Maybe<Scalars['Int']['output']>;
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  pm2_5?: Maybe<Scalars['Float']['output']>;
  pm10?: Maybe<Scalars['Float']['output']>;
  relativeHumidity?: Maybe<Scalars['Int']['output']>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  score?: Maybe<Scalars['Int']['output']>;
  scoreColor?: Maybe<SystemTciScoreColor>;
  sensors: IAQConnectedSensors;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Int']['output']>;
  tvoc?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum SystemTciScoreColor {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export type SystemToSensorInput = {
  sensorId: Scalars['Int']['input'];
  status: Scalars['Boolean']['input'];
};

export enum SystemType {
  CHARGE_POINT = 'CHARGE_POINT',
  ELECTRICITY_METER = 'ELECTRICITY_METER',
  ELECTRICITY_SUBMETER = 'ELECTRICITY_SUBMETER',
  HW_BOILER_HT_HWR = 'HW_BOILER_HT_HWR',
  HW_BOILER_TEKMAR_284 = 'HW_BOILER_TEKMAR_284',
  LUTRON_VIVE_LIGHTING = 'LUTRON_VIVE_LIGHTING',
  NATURAL_GAS_METER = 'NATURAL_GAS_METER',
  PACKAGE_ALTC24PROG = 'PACKAGE_ALTC24PROG',
  PACKAGE_ECOBEE_DEFAULT = 'PACKAGE_ECOBEE_DEFAULT',
  PACKAGE_HONEYWELL_TC500AN = 'PACKAGE_HONEYWELL_TC500AN',
  SMART_OUTLET_T0006623 = 'SMART_OUTLET_T0006623',
  STEAM_METER = 'STEAM_METER',
  STEAM_PRV_STATION = 'STEAM_PRV_STATION',
  ST_BOILER_F_HT_MPC = 'ST_BOILER_F_HT_MPC',
  TBL_TCI = 'TBL_TCI',
  WATER_METER = 'WATER_METER',
  WEATHER_STATION_WTS506 = 'WEATHER_STATION_WTS506',
}

export type SystemTypeSensors = {
  sensorTypes: Array<SensorDeviceType>;
  systemType: SystemType;
};

export type SystemUnion =
  | SystemChargePointStation
  | SystemElectricityMeter
  | SystemElectricitySubmeter
  | SystemHwBoilerHtHwr
  | SystemHwBoilerTekmar248
  | SystemLightingVive
  | SystemNaturalGasMeter
  | SystemPackageAltc24Prog
  | SystemPackageEcobeeDefault
  | SystemSmartOutlet
  | SystemStBoilerFHtMpc
  | SystemSteamMeter
  | SystemSteamPrvStation
  | SystemTblTci
  | SystemWaterMeter
  | SystemWeatherStationWts506;

export type SystemWaterMeter = {
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemWeatherStationWts506 = {
  battery?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floor_type: FloorType;
  groupType: SystemGroupType;
  humidity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerModel?: Maybe<Scalars['String']['output']>;
  meterId?: Maybe<Scalars['Int']['output']>;
  mode: SystemMode;
  name: Scalars['String']['output'];
  onlineStatus: OnlineStatus;
  organizationIds: Array<Scalars['Int']['output']>;
  overriddenSchedulerProfile?: Maybe<SystemOverriddenSchedulerProfileModel>;
  pressure?: Maybe<Scalars['Int']['output']>;
  rainfallTotal?: Maybe<Scalars['Int']['output']>;
  schedulerProfile?: Maybe<SystemSchedulerProfileModel>;
  sequence: SystemSequence;
  siteId: Scalars['Int']['output'];
  status: SystemStatus;
  statusUpdatedAt: Scalars['DateTime']['output'];
  systemDescription?: Maybe<Scalars['String']['output']>;
  systemGroupId?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Int']['output']>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
  windDirection?: Maybe<Scalars['Int']['output']>;
  windSpeed?: Maybe<Scalars['Float']['output']>;
};

export type Tenant = {
  contactName?: Maybe<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type TenantLeaseOption = {
  accountNumber: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type TimeInterval = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type UpcomingScheduleConfig = {
  changeAt: Scalars['DateTime']['output'];
  config: SchedulerProfileSystemConfiguration;
  name?: Maybe<Scalars['String']['output']>;
};

export type UpdateAdditionalChargeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChargePointStationSettings = {
  portsLoad?: InputMaybe<Array<ChargePointStationPortSettings>>;
  stationAllowedLoad?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateLeaseInput = {
  chargesToAdd?: InputMaybe<Array<BillingInfoChargeInput>>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  paymentInfo?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  rates?: InputMaybe<BillingRatesInput>;
};

export type UpdateSchedulerProfileForSiteInput = {
  dateConfiguration?: InputMaybe<SchedulerProfileDateConfiguration>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  overridden?: InputMaybe<Scalars['Boolean']['input']>;
  selectedMoments?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateSensorInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  floorType?: InputMaybe<FloorType>;
  integrationDetails?: InputMaybe<SensorDeviceIntegrationInput>;
  line?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  systems?: InputMaybe<Array<SensorToSystemInput>>;
};

export type UpdateSiteFitwellInput = {
  ch2o?: InputMaybe<Scalars['Int']['input']>;
  co?: InputMaybe<Scalars['Float']['input']>;
  co2?: InputMaybe<Scalars['Int']['input']>;
  o3?: InputMaybe<Scalars['Int']['input']>;
  pm2_5?: InputMaybe<Scalars['Float']['input']>;
  pm10?: InputMaybe<Scalars['Float']['input']>;
  relative_humidity?: InputMaybe<Scalars['Int']['input']>;
  temperature?: InputMaybe<Scalars['Int']['input']>;
  tvoc?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSiteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Latitude']['input']>;
  longitude?: InputMaybe<Scalars['Longitude']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['Upload']['input']>;
  primary_property_type?: InputMaybe<SitePrimaryPropertyType>;
  region_id?: InputMaybe<Scalars['Int']['input']>;
  square_feet?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_name?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['String']['input']>;
  year_built?: InputMaybe<Scalars['Int']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
  zip_code_title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSpaceInput = {
  floor: Scalars['String']['input'];
  floorType: FloorType;
  name: Scalars['String']['input'];
  squareFeet: Scalars['Int']['input'];
};

export type UpdateSystemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraFields: SystemExtraFieldsUpdateInput;
  floor: Scalars['String']['input'];
  floorType: FloorType;
  integrationDetails: SystemDeviceIntegrationInput;
  name: Scalars['String']['input'];
  organizationIds: Array<Scalars['Int']['input']>;
  sensors: Array<SystemToSensorInput>;
  type: SystemType;
};

export type UpdateSystemPackageAltc24ProgInput = {
  focusAlgorithm?: InputMaybe<LearningAlgorithmType>;
  occupiedSetpointCool?: InputMaybe<Scalars['Int']['input']>;
  occupiedSetpointHeat?: InputMaybe<Scalars['Int']['input']>;
  setLock?: InputMaybe<LockType>;
  setpointCool?: InputMaybe<Scalars['Int']['input']>;
  setpointHeat?: InputMaybe<Scalars['Int']['input']>;
  setpointMax?: InputMaybe<Scalars['Int']['input']>;
  setpointMin?: InputMaybe<Scalars['Int']['input']>;
  space?: InputMaybe<SpaceType>;
  systemId: Scalars['Int']['input'];
  unoccupiedSetpointCool?: InputMaybe<Scalars['Int']['input']>;
  unoccupiedSetpointHeat?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTenantInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum UtilityServiceTypes {
  ELECTRIC = 'ELECTRIC',
  FUEL_OIL = 'FUEL_OIL',
  GAS = 'GAS',
  OTHER = 'OTHER',
  STEAM = 'STEAM',
  WASTE = 'WASTE',
  WATER = 'WATER',
}

export enum WeekDays {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}
