import React, { lazy } from 'react';

import withSuspence from 'src/cdk/HOCs/withSuspense/withSuspence';

const Modal = withSuspence(
  lazy(() => import('./Modal')),
  <></>
);

const Dropdown = withSuspence(
  lazy(() => import('./Dropdown')),
  <></>
);

const CustomTooltip = withSuspence(
  lazy(() => import('./CustomTooltip')),
  <></>
);

export { Dropdown, Modal, CustomTooltip };
