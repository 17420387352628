import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetShadowMeterSystemsQueryVariables = Types.Exact<{
  siteIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  types: Array<Types.SystemType> | Types.SystemType;
}>;

export type GetShadowMeterSystemsQuery = {
  systems: Array<
    | {
        floor?: string | null;
        floor_type: Types.FloorType;
        groupType: Types.SystemGroupType;
        id: number;
        lastActivity?: Date | null;
        manufacturer?: string | null;
        manufacturerModel?: string | null;
        meterId?: number | null;
        mode: Types.SystemMode;
        name: string;
        onlineStatus: Types.OnlineStatus;
        organizationIds: Array<number>;
        siteId: number;
        status: Types.SystemStatus;
        statusUpdatedAt: Date;
        systemDescription?: string | null;
        systemGroupId?: number | null;
        type: Types.SystemType;
        overriddenSchedulerProfile?: {
          endTime: Date;
          sequenceId: number;
          settings?: {
            fan?: Types.FanType | null;
            mode?: Types.ModeType | null;
            occupiedSetpointCool?: number | null;
            occupiedSetpointHeat?: number | null;
            outsideTempMax?: number | null;
            outsideTempMin?: number | null;
            setLock?: Types.LockType | null;
            setpoint?: number | null;
            setpointCool?: number | null;
            setpointHeat?: number | null;
            setpointMax?: number | null;
            setpointMin?: number | null;
            space?: Types.SpaceType | null;
            systemState?: Types.SystemStatus | null;
            unoccupiedSetpointCool?: number | null;
            unoccupiedSetpointHeat?: number | null;
          } | null;
        } | null;
        schedulerProfile?: { name: string; underSchedulerControl: boolean } | null;
        sequence: { id: number; name: string };
      }
    | {
        floor?: string | null;
        floor_type: Types.FloorType;
        groupType: Types.SystemGroupType;
        id: number;
        lastActivity?: Date | null;
        manufacturer?: string | null;
        manufacturerModel?: string | null;
        meterId?: number | null;
        mode: Types.SystemMode;
        name: string;
        onlineStatus: Types.OnlineStatus;
        organizationIds: Array<number>;
        siteId: number;
        status: Types.SystemStatus;
        statusUpdatedAt: Date;
        systemDescription?: string | null;
        systemGroupId?: number | null;
        type: Types.SystemType;
        overriddenSchedulerProfile?: {
          endTime: Date;
          sequenceId: number;
          settings?: {
            fan?: Types.FanType | null;
            mode?: Types.ModeType | null;
            occupiedSetpointCool?: number | null;
            occupiedSetpointHeat?: number | null;
            outsideTempMax?: number | null;
            outsideTempMin?: number | null;
            setLock?: Types.LockType | null;
            setpoint?: number | null;
            setpointCool?: number | null;
            setpointHeat?: number | null;
            setpointMax?: number | null;
            setpointMin?: number | null;
            space?: Types.SpaceType | null;
            systemState?: Types.SystemStatus | null;
            unoccupiedSetpointCool?: number | null;
            unoccupiedSetpointHeat?: number | null;
          } | null;
        } | null;
        schedulerProfile?: { name: string; underSchedulerControl: boolean } | null;
        sequence: { id: number; name: string };
      }
    | {
        floor?: string | null;
        floor_type: Types.FloorType;
        groupType: Types.SystemGroupType;
        id: number;
        lastActivity?: Date | null;
        manufacturer?: string | null;
        manufacturerModel?: string | null;
        meterId?: number | null;
        mode: Types.SystemMode;
        name: string;
        onlineStatus: Types.OnlineStatus;
        organizationIds: Array<number>;
        siteId: number;
        status: Types.SystemStatus;
        statusUpdatedAt: Date;
        systemDescription?: string | null;
        systemGroupId?: number | null;
        type: Types.SystemType;
        overriddenSchedulerProfile?: {
          endTime: Date;
          sequenceId: number;
          settings?: {
            fan?: Types.FanType | null;
            mode?: Types.ModeType | null;
            occupiedSetpointCool?: number | null;
            occupiedSetpointHeat?: number | null;
            outsideTempMax?: number | null;
            outsideTempMin?: number | null;
            setLock?: Types.LockType | null;
            setpoint?: number | null;
            setpointCool?: number | null;
            setpointHeat?: number | null;
            setpointMax?: number | null;
            setpointMin?: number | null;
            space?: Types.SpaceType | null;
            systemState?: Types.SystemStatus | null;
            unoccupiedSetpointCool?: number | null;
            unoccupiedSetpointHeat?: number | null;
          } | null;
        } | null;
        schedulerProfile?: { name: string; underSchedulerControl: boolean } | null;
        sequence: { id: number; name: string };
      }
    | {
        floor?: string | null;
        floor_type: Types.FloorType;
        groupType: Types.SystemGroupType;
        id: number;
        lastActivity?: Date | null;
        manufacturer?: string | null;
        manufacturerModel?: string | null;
        meterId?: number | null;
        mode: Types.SystemMode;
        name: string;
        onlineStatus: Types.OnlineStatus;
        organizationIds: Array<number>;
        siteId: number;
        status: Types.SystemStatus;
        statusUpdatedAt: Date;
        systemDescription?: string | null;
        systemGroupId?: number | null;
        type: Types.SystemType;
        overriddenSchedulerProfile?: {
          endTime: Date;
          sequenceId: number;
          settings?: {
            fan?: Types.FanType | null;
            mode?: Types.ModeType | null;
            occupiedSetpointCool?: number | null;
            occupiedSetpointHeat?: number | null;
            outsideTempMax?: number | null;
            outsideTempMin?: number | null;
            setLock?: Types.LockType | null;
            setpoint?: number | null;
            setpointCool?: number | null;
            setpointHeat?: number | null;
            setpointMax?: number | null;
            setpointMin?: number | null;
            space?: Types.SpaceType | null;
            systemState?: Types.SystemStatus | null;
            unoccupiedSetpointCool?: number | null;
            unoccupiedSetpointHeat?: number | null;
          } | null;
        } | null;
        schedulerProfile?: { name: string; underSchedulerControl: boolean } | null;
        sequence: { id: number; name: string };
      }
    | {}
  >;
};

export type GetSystemMetricsQueryVariables = Types.Exact<{
  systemIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type GetSystemMetricsQuery = {
  systemMetrics: Array<{
    id: number;
    measurement: Types.MetricsMeasurementType;
    name: string;
    relationType: Types.MetricsRelationType;
    systemId: number;
    type: Types.MetricType;
    view: Types.MetricsView;
  }>;
};

export type GetCalendarizedMeterConsumptionDataQueryVariables = Types.Exact<{
  filter: Types.SystemElectricitySubmeterFilterInput;
  monthPeriod: Types.MonthRangeInput;
  siteId: Types.Scalars['Int']['input'];
  type: Types.SystemType;
}>;

export type GetCalendarizedMeterConsumptionDataQuery = {
  getCalendarizedMeterConsumptionData: Array<{
    consumption?: number | null;
    month: Date;
    peakDemand?: number | null;
    peakDemandTimestamp?: Date | null;
  }>;
};

export const GetShadowMeterSystemsDocument = gql`
  query GetShadowMeterSystems($siteIds: [Int!]!, $types: [SystemType!]!) {
    systems(siteIds: $siteIds, types: $types) {
      ... on SystemElectricityMeter {
        floor
        floor_type
        groupType
        id
        lastActivity
        manufacturer
        manufacturerModel
        meterId
        mode
        name
        onlineStatus
        organizationIds
        overriddenSchedulerProfile {
          endTime
          sequenceId
          settings {
            fan
            mode
            occupiedSetpointCool
            occupiedSetpointHeat
            outsideTempMax
            outsideTempMin
            setLock
            setpoint
            setpointCool
            setpointHeat
            setpointMax
            setpointMin
            space
            systemState
            unoccupiedSetpointCool
            unoccupiedSetpointHeat
          }
        }
        schedulerProfile {
          name
          underSchedulerControl
        }
        sequence {
          id
          name
        }
        siteId
        status
        statusUpdatedAt
        systemDescription
        systemGroupId
        type
      }
      ... on SystemNaturalGasMeter {
        floor
        floor_type
        groupType
        id
        lastActivity
        manufacturer
        manufacturerModel
        meterId
        mode
        name
        onlineStatus
        organizationIds
        overriddenSchedulerProfile {
          endTime
          sequenceId
          settings {
            fan
            mode
            occupiedSetpointCool
            occupiedSetpointHeat
            outsideTempMax
            outsideTempMin
            setLock
            setpoint
            setpointCool
            setpointHeat
            setpointMax
            setpointMin
            space
            systemState
            unoccupiedSetpointCool
            unoccupiedSetpointHeat
          }
        }
        schedulerProfile {
          name
          underSchedulerControl
        }
        sequence {
          id
          name
        }
        siteId
        status
        statusUpdatedAt
        systemDescription
        systemGroupId
        type
      }
      ... on SystemSteamMeter {
        floor
        floor_type
        groupType
        id
        lastActivity
        manufacturer
        manufacturerModel
        meterId
        mode
        name
        onlineStatus
        organizationIds
        overriddenSchedulerProfile {
          endTime
          sequenceId
          settings {
            fan
            mode
            occupiedSetpointCool
            occupiedSetpointHeat
            outsideTempMax
            outsideTempMin
            setLock
            setpoint
            setpointCool
            setpointHeat
            setpointMax
            setpointMin
            space
            systemState
            unoccupiedSetpointCool
            unoccupiedSetpointHeat
          }
        }
        schedulerProfile {
          name
          underSchedulerControl
        }
        sequence {
          id
          name
        }
        siteId
        status
        statusUpdatedAt
        systemDescription
        systemGroupId
        type
      }
      ... on SystemWaterMeter {
        floor
        floor_type
        groupType
        id
        lastActivity
        manufacturer
        manufacturerModel
        meterId
        mode
        name
        onlineStatus
        organizationIds
        overriddenSchedulerProfile {
          endTime
          sequenceId
          settings {
            fan
            mode
            occupiedSetpointCool
            occupiedSetpointHeat
            outsideTempMax
            outsideTempMin
            setLock
            setpoint
            setpointCool
            setpointHeat
            setpointMax
            setpointMin
            space
            systemState
            unoccupiedSetpointCool
            unoccupiedSetpointHeat
          }
        }
        schedulerProfile {
          name
          underSchedulerControl
        }
        sequence {
          id
          name
        }
        siteId
        status
        statusUpdatedAt
        systemDescription
        systemGroupId
        type
      }
    }
  }
`;
export const GetSystemMetricsDocument = gql`
  query GetSystemMetrics($systemIds: [Int!]!) {
    systemMetrics(systemIds: $systemIds) {
      id
      measurement
      name
      relationType
      systemId
      type
      view
    }
  }
`;
export const GetCalendarizedMeterConsumptionDataDocument = gql`
  query GetCalendarizedMeterConsumptionData(
    $filter: SystemElectricitySubmeterFilterInput!
    $monthPeriod: MonthRangeInput!
    $siteId: Int!
    $type: SystemType!
  ) {
    getCalendarizedMeterConsumptionData(filter: $filter, monthPeriod: $monthPeriod, siteId: $siteId, type: $type) {
      consumption
      month
      peakDemand
      peakDemandTimestamp
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetShadowMeterSystems(
      variables: GetShadowMeterSystemsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetShadowMeterSystemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetShadowMeterSystemsQuery>(GetShadowMeterSystemsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetShadowMeterSystems',
        'query',
        variables
      );
    },
    GetSystemMetrics(
      variables: GetSystemMetricsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSystemMetricsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSystemMetricsQuery>(GetSystemMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSystemMetrics',
        'query',
        variables
      );
    },
    GetCalendarizedMeterConsumptionData(
      variables: GetCalendarizedMeterConsumptionDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetCalendarizedMeterConsumptionDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCalendarizedMeterConsumptionDataQuery>(
            GetCalendarizedMeterConsumptionDataDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetCalendarizedMeterConsumptionData',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
