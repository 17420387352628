import React, { lazy } from 'react';

import withSuspense from 'src/cdk/HOCs/withSuspense/withSuspence';

import { PageHeaderWithActionsSkeletonLoader } from './PageHeaderWithActions.skeleton';

const skeleton = <PageHeaderWithActionsSkeletonLoader subtitle />;

const PageHeaderWithActionsLazy = withSuspense(
  lazy(() => import('./PageHeaderWithActions')),
  skeleton
);

export default PageHeaderWithActionsLazy;
