import { useMemo } from 'react';
import { Matcher } from 'react-day-picker';

export function useDisabledDays(
  availableFrom?: Date,
  availableTo?: Date,
  disabledRanges?: {
    from?: Date | undefined;
    to?: Date | undefined;
  }[]
): Matcher[] {
  const disabledDaysModifiers = useMemo(() => {
    const result: Matcher[] = [];

    // All days before "available from" will be disabled
    if (availableFrom) {
      result.push({ before: availableFrom });
    }

    // All days after "available to" will be disabled
    if (availableTo) {
      result.push({ after: availableTo });
    }

    if (disabledRanges) {
      for (const item of disabledRanges) {
        if (item.from && item.to) {
          result.push({
            from: item.from,
            to: item.to,
          });
        } else if (item.to) {
          result.push({ before: item.to });
        } else if (item.from) {
          result.push({ after: item.from });
        }
      }
    }
    return result;
  }, [availableFrom, availableTo, disabledRanges]);

  return disabledDaysModifiers;
}
