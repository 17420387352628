import _ from 'lodash';
import React from 'react';
import { Outlet } from 'react-router';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import { SiteFeatureType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import { selectUserRole } from 'src/core/store/global/globalSlice';
import { useAppSelector } from 'src/core/store/hooks';
import { selectSiteHasFeature } from 'src/modules/sites/sitesSlice';
import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';

/**
 * Used to define if user has access to specific page or component by passed params
 */
const Guard: React.FC<
  React.PropsWithChildren<{
    roles?: UserRoles[];
    feature?: SiteFeatureType;
    siteIdRouteParamName?: string;
    siteId?: number;
    /**
     * Set to null if you want to hide component from UI
     */
    placeholder?: React.ReactNode | null;
  }>
> = (props) => {
  const currentUserRole = useAppSelector(selectUserRole);
  const siteIdFromParam = useNumberParam(props.siteIdRouteParamName ?? '-1', false);
  const siteId = props.siteIdRouteParamName ? siteIdFromParam : props.siteId;
  const hasFeature = useAppSelector(
    selectSiteHasFeature(props.siteIdRouteParamName ? siteId : undefined, props.feature)
  );

  const hasAccess = currentUserRole && props.roles ? props.roles.includes(currentUserRole) : true;

  if (!hasAccess || !hasFeature) {
    // Placeholder can be null if we want to hide component from UI
    if (_.isUndefined(props.placeholder)) {
      return <ElevatedBox error>Access Denied</ElevatedBox>;
    }
    return props.placeholder;
  }

  return props.children ? props.children : <Outlet />;
};

export default Guard;
