import { isSameDay } from 'date-fns';

import { MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';

import { DateRangeWithIntervals } from '../../DatePicker/SmartDateRangePicker/DateRangePickerWithIntervals';

/**
 * Split data by chunks, so each chunk starts when date is changed
 * Assumed that data is sorted by timestamp in ascending order
 */
export function splitDataByChunks<T extends { timestamp: Date }>(data?: T[]): T[][] {
  if (!data || data.length === 0) {
    return [[]];
  }
  const chunks = [];
  let prevChunkIndex = 0;
  let prevXValue = data[0].timestamp;
  for (let index = 1; index < data.length; index++) {
    const xValue = data[index].timestamp;
    if (!isSameDay(xValue, prevXValue)) {
      chunks.push(data.slice(prevChunkIndex, index));
      prevChunkIndex = index;
      prevXValue = xValue;
    }
  }
  chunks.push(data.slice(prevChunkIndex));
  return chunks;
}

/**
 * Generates array of ticks so each tick is a new day.
 * Domain may have gaps, so function should not generate ticks for timestamps which are not in domain.
 */
export function buildTicksForDays(domain: Date[]): Date[] {
  const startOfTheDays = [];
  let prevXValue = domain[0];
  startOfTheDays.push(domain[0]);
  for (let index = 1; index < domain.length; index++) {
    const xValue = domain[index];
    if (!isSameDay(xValue, prevXValue)) {
      startOfTheDays.push(domain[index]);
      prevXValue = xValue;
    }
  }
  return startOfTheDays;
}

/**
 * If hour intervals are used with day step we need to calculate value based on amount of "included" hours
 */
export function getMaxMinutesForStepFromFilter(dateTimeFilter: DateRangeWithIntervals): number {
  switch (dateTimeFilter.step) {
    case MetricsStep.FIFTEEN_MINUTES:
      return 15;
    case MetricsStep.HOUR:
      return 60;
    case MetricsStep.DAY:
      return 60 * (dateTimeFilter.hours?.length ?? 24);
    case MetricsStep.MONTH:
      return 60 * 24 * 30 * (dateTimeFilter.months?.length ?? 1);
    case MetricsStep.YEAR:
      return 60 * 24 * 30 * 12 * (dateTimeFilter.years?.length ?? 1);
  }
}
