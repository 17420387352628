import classNames from 'classnames';
import { clamp, isNil } from 'lodash';

import { toPercentage } from 'src/cdk/utils/toPercentage';
import { UNIT } from 'src/constants';
import { WithUnit } from 'src/shared/components/Unit/WithUnit';
import { CardLayout } from 'src/shared/containers/UniversalCard/UniversalCard';

import styles from './OATResetSequenceIndicator.module.scss';

export interface OATResetSequenceIndicatorProps {
  /**
   * Current Outside Air Temperature
   */
  oatTemperature?: number | null;
  /**
   * Minimum oat temperature withing specific temperature range(expected outside temperature range in particular location)
   */
  oatRangeLow: number;
  /**
   * Maximum oat temperature withing specific temperature range(expected outside temperature range in particular location)
   */
  oatRangeHigh: number;
  /**
   * Minimum setpoint value
   */
  setpointMin: number;
  /**
   * Maximum setpoint value
   */
  setpointMax: number;
  /**
   * Allows to disable indicator in case connection is lost
   * By default "false"
   */
  disabled?: boolean;
  setpointUnit: UNIT;
  /**
   * Allows to deternmine a system card layout
   *OAT temperature should be displayed only on Vertical system card
   */
  cardView: string;
}

const OATResetSequenceIndicator: React.FC<OATResetSequenceIndicatorProps> = ({ disabled = false, ...props }) => {
  function clampedTemperatureValue(temperature?: number | null): number | null {
    if (isNil(temperature)) {
      return null;
    }
    return clamp(temperature, props.oatRangeLow, props.oatRangeHigh);
  }

  const oatClampedTemperature = clampedTemperatureValue(props.oatTemperature);

  function calcIndicatorScalePosition(temperature: number | null | undefined): React.CSSProperties | undefined {
    if (isNil(temperature)) {
      return;
    }

    return {
      left: `${toPercentage(props.oatRangeLow, props.oatRangeHigh, temperature)}%`,
    };
  }

  return (
    <div className={styles['oat-reset']} style={{ opacity: disabled ? 0.28 : 1 }}>
      <div className={styles['indicators-container']}>
        <div className={styles['temperature-indicator']}>
          <p className={classNames('body-small', 'color-secondary ')}>
            <WithUnit fahrenheit value={props.oatRangeLow} />
          </p>
          <div className={styles['temperature-indicator-container']}>
            <div className={classNames(styles['indicator'], styles['indicator-temperature'])} />
            <div
              className={classNames(styles['indicator-line'], {
                [styles['hide-indicator']]: isNil(oatClampedTemperature),
              })}
              style={calcIndicatorScalePosition(oatClampedTemperature)}
            />
          </div>
          <p className={classNames('body-small', 'color-secondary ')}>
            <WithUnit fahrenheit value={props.oatRangeHigh} />
          </p>
        </div>
        <div className={styles['setpoint-indicator']}>
          <p className={classNames('body-small', 'color-secondary ')}>
            <WithUnit unit={props.setpointUnit} value={props.setpointMax} />
          </p>
          <div className={classNames(styles['indicator'], styles['indicator-setpoint'])} />
          <p className={classNames('body-small', 'color-secondary ')}>
            <WithUnit unit={props.setpointUnit} value={props.setpointMin} />
          </p>
        </div>
      </div>
      {props.cardView === CardLayout.vertical && (
        <div className={styles['temperature-container']}>
          <p className={classNames('body-small', 'color-secondary ')}>Outside Air Temp.</p>
          <h5>
            <WithUnit fahrenheit value={props.oatTemperature} />
          </h5>
        </div>
      )}
    </div>
  );
};

export default OATResetSequenceIndicator;
