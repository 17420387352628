import { Outlet, useParams } from 'react-router';

import { useDataFetchOnMount } from 'src/cdk/hooks/useFetchDataOnMount';
import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import DetailsPageLayout from 'src/fragments/layouts/DetailsPageLayout';
import optionsApi from 'src/logic/options';
import api from 'src/logic/users/gql';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';

interface Props {
  tabsNames: string[];
}

const UserDetailsPage: React.FC<Props> = ({ tabsNames }) => {
  const params = useParams<{ id: string }>();
  const userId = params.id;
  const isCreationFlow = !params.id || params.id === 'create';

  const { response: organizationsOptions = [] } = useDataFetchOnMount(optionsApi.getOrganizations);
  const { isInitialLoading, isFailed, response } = useDataFetchOnMountWithDeps(
    () =>
      isCreationFlow ? Promise.resolve(undefined) : api.GetUserById({ id: userId! }).then((response) => response.user),
    [userId, isCreationFlow]
  );

  const subtitle =
    isCreationFlow || !response
      ? undefined
      : `${response.email} | ${organizationsOptions.find((i) => i.key === response.organization_id)?.displayValue}`;

  return (
    <DetailsPageLayout
      idParamName='id'
      entity='User'
      entityName={response?.name}
      subtitle={subtitle}
      isInitialLoading={isInitialLoading}
      isFailed={isFailed}
      removeBottomMargin
    >
      <UrlTabs disabled={isCreationFlow} names={tabsNames} />
      <Outlet />
    </DetailsPageLayout>
  );
};

export default UserDetailsPage;
