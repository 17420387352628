import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchOnMount } from 'src/cdk/hooks/useFetchOnMount';
import { mapMeasurmentToPrecision } from 'src/cdk/mappers/mapMeasurmentToPrecision';
import { MetricsMeasurementType, MetricsRelationType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { downloadFromServer } from 'src/core/service/downloadData';
import Logger from 'src/core/service/logger';
import { toastService } from 'src/core/service/toastService';
import { UsageMetricsPreferredColor } from 'src/enums';
import { DatePickerRangeType } from 'src/shared/components/DatePicker/DateRangePicker/config';
import DateRangePickerWithIntervals, {
  buildInitialDateRange,
} from 'src/shared/components/DatePicker/SmartDateRangePicker/DateRangePickerWithIntervals';
import { ExportButton } from 'src/shared/components/ExportButton/ExportButton';
import { CircularLoader } from 'src/shared/components/Loader/Loader';
import { chartColorByIndex } from 'src/shared/utils/charts/colors-order';

import { selectTimezoneForSiteId } from '../../../../sites/sitesSlice';
import { exportAnalyticsData } from '../../../gql/exportAnalyticsData.export.gql';
import { getMetricsDataForSystemWithIntervals } from '../../../gql/getMetricDataWithIntervals.resources.gql';
import { ISystemAnalyticMetric, getMetricsForSystem } from '../../../gql/getMetricsForSystem.resources.gql';
import { MetricWithoutData } from '../../../interface';
import { systemMetricsStorageService } from '../../../service/systemMetricsStorageService';
import { selectedSystemsByIds } from '../../../systemsSlice';
import { generateAnalyticsExportData } from '../../../utils/metricToExportAdapter';
import TileHeader from '../../shared/TileHeader/TileHeader';
import SelectedMetricsTile from '../SelectedMetricsTile/SelectedMetricsTile';

import styles from './AnalyticsTile.module.scss';
import GroupedSystemsMetricsChart from './GroupedSystemsMetricsChart';
import SystemMetricsChart from './SystemMetricsChart';

interface AnalyticsTileProps {
  /**
   * Assumed that all systems have same system group type
   */
  systemIds: number[];
  /**
   * If true then chart for system groups will be rendered
   *
   * @note For not it is assumed that systems in groups support only UsageCharts
   */
  forGroupOfSystems?: boolean;
  exportFileName: string;
}

const AnalyticsTile: React.FC<AnalyticsTileProps> = ({ systemIds, forGroupOfSystems = false, exportFileName }) => {
  const [dateTimeFilter, setDateTimeFilter] = useState(buildInitialDateRange(DatePickerRangeType.LAST_24_HOURS));

  const systems = useSelector(selectedSystemsByIds(systemIds), _.isEqual);

  const timeZone = useSelector(selectTimezoneForSiteId(_.first(systems)?.siteId ?? -1));

  const [metrics, setMetrics] = useState<MetricWithoutData[]>([]);

  const { isFailed, isLoading } = useFetchOnMount(fetchMetrics, onMetricsForSystemLoaded);

  async function exportMetricsData(): Promise<void> {
    // Check if export feature is ready to use
    const selectedMetrics = _.filter(metrics, 'selected');
    if (_.isEmpty(selectedMetrics)) {
      toastService.warn('Select at least 1 metric to download');
      throw 'No metric selected to download';
    }
    if (!timeZone) {
      toastService.warn('Site info was not loaded yet');
      throw 'Site info was not loaded yet';
    }

    // TODO: move it to API
    const response = await getMetricsDataForSystemWithIntervals(
      selectedMetrics.map((i) => i.id),
      dateTimeFilter,
      timeZone,
      _.fromPairs(selectedMetrics.map((i) => [i.id, i.type]))
    );

    const selectedMetricWithData = selectedMetrics.map((metric) => {
      const metricData = response.filter((i) => i.systemMetricId === metric.id);
      return {
        ...metric,
        data: metricData ?? [],
      };
    });

    const [exportColumns, exportData] = generateAnalyticsExportData(
      selectedMetricWithData,
      timeZone,
      dateTimeFilter.step
    );
    return exportAnalyticsData(exportFileName, exportColumns, exportData)
      .then((downloadUrl) => downloadFromServer(downloadUrl, exportFileName))
      .catch(() => Logger.error('Failed to generate data for export'));
  }

  function updateSelectedMetrics(updatedMetrics: MetricWithoutData[]) {
    setMetrics(updatedMetrics);
    if (!systems?.[0]) {
      return;
    }

    // Save selected metrics to storage
    const { groupType } = systems[0];
    const storedTypes = systemMetricsStorageService.getBySystemId(groupType);
    const [selectedTypes, unselectedTypes] = _.partition(updatedMetrics, 'selected').map((i) => _.map(i, 'type'));
    const metricTypesToKeepInStorage = _.chain(storedTypes).difference(unselectedTypes).union(selectedTypes).value();
    systemMetricsStorageService.update(groupType, metricTypesToKeepInStorage);
  }

  function onMetricsForSystemLoaded(systemMetrics: ISystemAnalyticMetric[]) {
    if (!systems?.[0]) {
      return;
    }
    const storedSystemsMetrics = systemMetricsStorageService.getBySystemId(systems[0]?.groupType);

    // If there are no stored metrics - select metrics from first 2 metrics measurement groups
    const metricTypesToSelect = !storedSystemsMetrics.length
      ? _.chain(systemMetrics)
          .filter((metric) => metric.relationType === MetricsRelationType.DEVICE)
          .map(({ measurement, type }) => ({
            measurement,
            type,
          }))
          .groupBy((tuple) => tuple.measurement)
          .toPairs()
          .take(2)
          .flatMap(([, tuples]) => tuples.map((i) => i.type))
          .value()
      : storedSystemsMetrics;

    const metricsWithColor: MetricWithoutData[] = systemMetrics.map((metric, index) => {
      /**
       * If metric is usage type - use preferred color
       * Otherwise - use color from colorConfigOrder
       */
      const styleVariantByIndex = chartColorByIndex(index);
      const styleVariant =
        metric.measurement === MetricsMeasurementType.USAGE
          ? UsageMetricsPreferredColor[metric.type] ?? styleVariantByIndex
          : styleVariantByIndex;

      const selected = metric.measurement === MetricsMeasurementType.USAGE || metricTypesToSelect.includes(metric.type);

      return {
        ...metric,
        selected,
        styleVariant,
        precision: mapMeasurmentToPrecision(metric.measurement),
      };
    });

    setMetrics(metricsWithColor);
    updateSelectedMetrics(metricsWithColor);
  }

  async function fetchMetrics(): Promise<ISystemAnalyticMetric[]> {
    const requests = systems.map((system) => (system ? getMetricsForSystem([system.id]) : []));
    const data = await Promise.all(requests);
    return data.flat();
  }

  if (isLoading || !timeZone) {
    return <CircularLoader />;
  }

  if (isFailed) {
    return <div>Failed to load analytics</div>;
  }

  const selectedMetrics = _.filter(metrics, 'selected');

  return (
    <>
      <TileHeader
        title='Analytics'
        subtitle='Select data sources to view analytics chart'
        className={styles['tile-header']}
      >
        <div className={styles['date-and-export-container']}>
          <DateRangePickerWithIntervals value={dateTimeFilter} onChange={setDateTimeFilter} />
          <ExportButton onClick={() => exportMetricsData()} />
        </div>
      </TileHeader>

      <div className={styles['content']}>
        {!forGroupOfSystems && (
          <div className={styles['selected-metrics-container']}>
            <SelectedMetricsTile updateMetrics={updateSelectedMetrics} metrics={metrics} />
          </div>
        )}

        <div className={styles['chart-container']}>
          {forGroupOfSystems ? (
            <GroupedSystemsMetricsChart
              dateTimeFilter={dateTimeFilter}
              selectedMetrics={selectedMetrics}
              timezone={timeZone}
              systems={systems}
            />
          ) : (
            <SystemMetricsChart dateTimeFilter={dateTimeFilter} selectedMetrics={selectedMetrics} timezone={timeZone} />
          )}
        </div>
      </div>
    </>
  );
};

export default AnalyticsTile;
