import { connectSdk, resourcesAPI } from 'src/core/apollo/api';

import {
  getSdk as consumptionOverviewSdk,
  GetMeterConsumptionBySiteQuery,
} from './__generated__/consumption-overview.resources.generated';
import { getSdk as systemMetersSdk } from './__generated__/meters.resources.generated';

export type GetMeterConsumptionBySite = GetMeterConsumptionBySiteQuery['getMeterConsumptionBySite'][0];

const api = {
  ...connectSdk(resourcesAPI, consumptionOverviewSdk),
  ...connectSdk(resourcesAPI, systemMetersSdk),
};

export default api;
