import { UNIT } from 'src/constants';

export { UNIT } from 'src/constants';

/**
 * Specify unit as property, and component will define how to render it
 *
 * @usage
 * <RenderUnit unit={unit} dimmed />
 */

export const RenderUnit: React.FC<{ unit: UNIT; dimmed?: boolean; wrapInBrackets?: boolean; perUnit?: UNIT }> = ({
  unit,
  dimmed,
  perUnit,
  wrapInBrackets,
}) => {
  const unitClassName = dimmed ? 'body-small color-secondary' : '';

  switch (unit) {
    case UNIT.MICROGRAM_M3:
      return (
        <>
          <span className={unitClassName}>
            {wrapInBrackets && ' ('}
            µg/m<sup className='caption-small'>3</sup>
            {wrapInBrackets && ')'}
          </span>
        </>
      );
    case UNIT.SQUARE_FEET:
      return (
        <>
          <span className={unitClassName}>
            {wrapInBrackets && ' ('}
            ft<sup className='caption-small'>2</sup>
            {wrapInBrackets && ')'}
          </span>
        </>
      );
    case UNIT.METRIC_TONNES_CO2_EMISSION:
      return (
        <>
          <span className={unitClassName}>
            {wrapInBrackets && ' ('}
            tCO<sub className='caption-small'>2</sub>e
            {perUnit && (
              <>
                /<RenderUnit unit={perUnit} />
              </>
            )}
            {wrapInBrackets && ')'}
          </span>
        </>
      );
  }

  return (
    <span className={unitClassName}>
      {wrapInBrackets && ' ('}
      {unit}
      {perUnit && (
        <>
          /<RenderUnit unit={perUnit} />
        </>
      )}
      {wrapInBrackets && ')'}
    </span>
  );
};
