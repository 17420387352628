import { useEffect } from 'react';

/**
 * 'useOnClickOutside' custom hook is used for listening for clicks outside of a specified element
 * This can be useful for closing a modal, a dropdown menu etc
 */

export function useOnClickOutside<T extends HTMLElement>(
  /**
   * An array of refs to DOM-elements on clicking on which call handler will not fire
   */
  refs: React.RefObject<T>[],
  /**
   * mousedown listener is only working if 'isListening' is true
   * 'isListening=false' by default
   * It is needed to not overload browser by multiple listeners
   */
  isListening = false,
  /**
   * Callback that called if clicked outside of refs passed by the first argument
   */
  handler: (event: Event) => void
): void {
  const handleClickOutside = (event: Event) => {
    const isClickedToRef = refs.some((ref) => ref.current?.contains(event.target as T));
    if (!isClickedToRef) {
      handler(event);
    }
  };
  useEffect(() => {
    if (isListening) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler, isListening]);
}
