import { toastService } from './toastService';

// eslint-disable-next-line
type AnyArguments = any[];

/**
 * Singleton Facade for console.log
 */
class LoggerFacade {
  success(message: string): void {
    console.info('success', message);
    toastService.success(message);
  }

  info(message: string): void {
    console.info(message);
  }

  /**
   * If message is not a string, it will be converted to string and logged as error
   */
  error(message: unknown | string, error?: unknown): void {
    if (typeof message !== 'string') {
      console.error(message);
      const messageToDisplay = (message ? String(message) : null) || 'Unknown error';
      toastService.error(messageToDisplay);
      return;
    }
    if (error) {
      console.error(message, error);
    } else {
      console.error(message);
    }
    toastService.error(message);
  }

  warn(message: string, error?: unknown): void {
    if (error) {
      console.warn(message, error);
    } else {
      console.warn(message);
    }
  }

  debug(message: string, ...args: AnyArguments): void {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }
    console.debug(message, ...args);
  }

  assert(condition: boolean, message: string): void {
    console.assert(condition, message);
  }
}

const Logger = new LoggerFacade();

export default Logger;
