import { persistentStorage } from 'src/core/service/persistentStorage';
import { PersistentStorageKeys } from 'src/enums';

import { ISpaceTemperatureRule } from '../interface';

/**
 * Service provide methods to get selected by user system metrics and update selected metrics for specific system
 */
class SpaceTemperatureStorageService {
  /**
   * Record<system id, space temperature rules[]>
   * @private
   */
  private data: Record<number, ISpaceTemperatureRule[]> = {};

  init() {
    const data = persistentStorage.getObject<Record<number, ISpaceTemperatureRule[]>>(
      PersistentStorageKeys.SpaceTemperatureRules
    );
    if (data) {
      this.data = data;
    }
  }

  update(systemId: number, rules: ISpaceTemperatureRule[]): void {
    this.data[systemId] = rules;
    persistentStorage.setObject<Record<number, ISpaceTemperatureRule[]>>(
      PersistentStorageKeys.SpaceTemperatureRules,
      this.data
    );
  }

  getBySystemId(systemId: number): ISpaceTemperatureRule[] {
    return this.data[systemId] || [];
  }

  getAll(): Record<number, ISpaceTemperatureRule[]> {
    return this.data;
  }
}

export const spaceTemperatureStorageService = new SpaceTemperatureStorageService();
