export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    WidgetsUnion: ['CognitivePerformanceIndex', 'GreenBuildingIndex', 'ProductivityIndex'],
  },
};
export default result;
