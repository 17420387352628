import Ellipsis from 'src/atoms/Ellipsis/Ellipsis';
import { useUserDisplay } from 'src/logic/options/useUserDisplay';

/**
 * Display user name by id, if user is from another org - it will show org name
 * Note: all users are cached to reduce the number of requests
 */
export default function DisplayUser({ userId, className }: { userId?: string; className?: string }) {
  const display = useUserDisplay();

  return <Ellipsis className={className}>{display(userId)}</Ellipsis>;
}
