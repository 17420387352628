import { Navigate, RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { AuditLogResourceType } from 'src/core/apollo/__generated__/logsGlobalTypes';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import Guard from 'src/fragments/layouts/guard/Guard';
import LogsPage from 'src/materials/logs/pages/LogsPage';
import { Routes } from 'src/routes';

import SystemDetailsPage from './systems/[id]/page';

const router: RouteObject[] = [
  {
    path: Routes.Devices,
    element: <Guard roles={[UserRoles.SUPER_ADMIN]} />,
    errorElement: <ExceptionHandler />,
    children: [
      // TODO: restructure overview page
      {
        index: true,
        path: '*',
        lazy: asLazyPage(() => import('./page'), import('./skeleton')),
      },
      {
        path: 'systems/:id',
        element: <SystemDetailsPage tabsNames={['General', 'Activity']} />,
        children: [
          {
            path: 'general',
            lazy: asLazyPage(() => import('./systems/[id]/details/page')),
          },
          {
            path: 'activity',
            element: <LogsPage resourceIdParamName='id' resourceType={AuditLogResourceType.SYSTEM} />,
          },
          {
            path: '*',
            element: <Navigate to='general' />,
          },
        ],
      },
      {
        path: 'sensors/:id',
        lazy: asLazyPage(() => import('./sensors/[id]/page')),
      },
      {
        path: 'gateways/:id',
        lazy: asLazyPage(() => import('./gateways/[id]/page')),
      },
      // {
      //   path: '*',
      //   element: <NotFoundPage />,
      // },
    ],
  },
];

export default router;
