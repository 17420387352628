import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import type { RootState } from 'src/core/store/store';

import { ApiKey } from './gql/getApiKeys.resources.gql';
import { Organization, OrganizationWithAmountOfApiKey } from './gql/getOrganizations.resources.gql';

interface IOrganizationsSlice {
  organizationsMap: Record<number, Organization>;
  /**
   * Map for api keys to organization - [organizationId]: [ApiKey]
   */
  apiKeysMap: Record<number, ApiKey[]>;
}

const initialState: IOrganizationsSlice = {
  organizationsMap: {},
  apiKeysMap: {},
};

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: initialState,
  reducers: {
    resetSlice: () => initialState,
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizationsMap = _.keyBy(action.payload, 'id');
    },
    setApiKeys: (state, action: PayloadAction<ApiKey[]>) => {
      state.apiKeysMap = _.groupBy(action.payload, 'organizationId');
    },
    deleteKeyFromOrganization: (state, action: PayloadAction<{ organizationId: number; keyId: string }>) => {
      const { organizationId, keyId } = action.payload;
      state.apiKeysMap[organizationId] = state.apiKeysMap[organizationId]
        ? state.apiKeysMap[organizationId].filter((key) => key.id !== keyId)
        : [];
    },
    addKeyToOrganization: (state, action: PayloadAction<ApiKey>) => {
      // Here we pass the newly created key, which has a token in its payload, thus we parse it to not store it in state.
      const { id, name, expirationDate, organizationId } = action.payload;
      if (!state.apiKeysMap[organizationId]) {
        state.apiKeysMap[organizationId] = [];
      }
      state.apiKeysMap[organizationId].push({ id, name, expirationDate, organizationId });
    },
  },
});

export const {
  resetSlice: resetOrganizationsSlice,
  setOrganizations,
  setApiKeys,
  deleteKeyFromOrganization,
  addKeyToOrganization,
} = organizationsSlice.actions;

export const selectOrganizations = (state: RootState): OrganizationWithAmountOfApiKey[] =>
  _.orderBy(
    _.values(state.organizations.organizationsMap).map((item) => ({
      ...item,
      amountOfApiKeys: state.organizations.apiKeysMap[item.id]?.length || 0,
    })),
    'name'
  );

export const selectApiKeysForOrganization =
  (organizationId: number) =>
  (state: RootState): ApiKey[] | undefined =>
    state.organizations.apiKeysMap[organizationId];

export const selectOrganizationById =
  (id: number) =>
  (state: RootState): Organization | undefined =>
    state.organizations.organizationsMap[id];

export default organizationsSlice.reducer;
