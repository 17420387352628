import { Navigate, RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { SiteFeatureType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import Guard from 'src/fragments/layouts/guard/Guard';
import { Routes } from 'src/routes';

import { UtilityOverviewPerSiteLayout } from './[:type]/[:siteId]/layout';

const router: RouteObject[] = [
  // Used to view data
  {
    path: '/utility/:type',
    element: <Guard feature={SiteFeatureType.UTILITIES} />,
    children: [
      {
        index: true,
        lazy: asLazyPage(() => import('./[:type]/page')),
      },
      {
        path: ':siteId',
        element: <Guard feature={SiteFeatureType.UTILITIES} siteIdRouteParamName='siteId' />,
        children: [
          {
            path: '',
            element: <UtilityOverviewPerSiteLayout />,
            errorElement: <ExceptionHandler />,
            children: [
              {
                path: 'dashboard',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/dashboard/page')),
              },
              {
                path: 'trend',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/trend/page')),
              },
              {
                path: 'forecasting',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/forecasting/page')),
              },
              {
                path: 'performance',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/performance/page')),
              },
              {
                path: 'bills',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/bills/page')),
              },
              {
                path: 'audits',
                lazy: asLazyPage(() => import('./[:type]/[:siteId]/audits/page')),
              },
              {
                path: '*',
                element: <Navigate to='dashboard' />,
              },
            ],
          },
        ],
      },
    ],
  },
  // Used to setup UtilityAPI integration
  {
    path: '/utility/api',
    children: [
      {
        path: 'create',
        lazy: asLazyPage(() => import('./utility-api/create/page')),
      },
      {
        path: 'auth',
        element: <Navigate to='/integrations/utility_api/flow' />,
      },
    ],
  },
  // Used to setup Coned integration
  {
    path: Routes.UtilityConedOruDetails,
    lazy: asLazyPage(() => import('src/modules/utility/pages/coned-oru/UtilityConedOruDetailsPage')),
  },
  {
    path: Routes.UtilityConedOruScopeSelection,
    lazy: asLazyPage(() => import('src/modules/utility/pages/coned-oru/UtilityConedOruScopePage')),
  },

  // {
  //   path: Routes.DemandResponse,
  //   children: [
  //     {
  //       index: true,
  //       lazy: asLazyPage(() => import('src/modules/utility/pages/utility-api/UtilityAPICreatePage')),
  //     },
  //     {
  //       path: ':siteId',
  //       element: (
  //         <OverviewPerSiteLayout title='Demand Response' tabsNames={['History', 'Connected Systems', 'Meters']} />
  //       ),
  //       errorElement: <ExceptionHandler />,
  //       children: [
  //         {
  //           path: 'history',
  //           lazy: asLazyPage(() => import('../../modules/adr/pages/ADRPage')),
  //           errorElement: <ExceptionHandler />,
  //         },
  //         {
  //           path: 'connected-systems',
  //           lazy: asLazyPage(
  //             () => import('./[:siteId]/connected-systems/page'),
  //             import('./[:siteId]/connected-systems/skeleton')
  //           ),
  //           errorElement: <ExceptionHandler />,
  //         },
  //         {
  //           path: 'meters',
  //           lazy: asLazyPage(() => import('./[:siteId]/meters/page'), import('./[:siteId]/meters/skeleton')),
  //           errorElement: <ExceptionHandler />,
  //         },
  //         {
  //           path: '*',
  //           element: <NotFoundPage />,
  //         },
  //       ],
  //     },
  //     {
  //       path: ':siteId/history/:eventId',
  //       lazy: asLazyPage(() => import('../../modules/adr/pages/EventDetails/EventDetailsPage')),
  //       errorElement: <ExceptionHandler />,
  //     },
  //     {
  //       path: ':siteId/connected-systems/:systemId',
  //       lazy: asLazyPage(() => import('./[:siteId]/[:systemId]/page'), import('./[:siteId]/[:systemId]/skeleton')),
  //     },
  //   ],
  // },
];

export default router;
