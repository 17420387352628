import classNames from 'classnames';
import _, { intersection } from 'lodash';

import { selectUserRole } from 'src/core/store/global/globalSlice';
import { useAppSelector } from 'src/core/store/hooks';
import {
  selectAvailableSystemForSites,
  selectUniqFeaturesForSites,
  selectUniqUtilityServiceTypesForSites,
} from 'src/modules/sites/sitesSlice';
import { Icon } from 'src/shared/components/Icon/Icon';

import styles from './Navigation.module.scss';
import NavigationItem from './NavigationItem';
import { INavigationItem } from './navigationConfig';

const NAV_ITEM_HEIGHT = 56;

const NavigationGroup: React.FC<{
  group: INavigationItem;
  onClick: (item: INavigationItem) => void;
  onArrowClick: (item: INavigationItem) => void;
  isActive: boolean;
  isExpanded: boolean;
}> = ({ group, onClick, onArrowClick, isActive, isExpanded }) => {
  if (!group.children?.length) {
    //if children for a group were not defined, group wouldn't be rendered
    return null;
  }
  const userRole = useAppSelector(selectUserRole);
  const availableSystemForSites = useAppSelector(selectAvailableSystemForSites, _.isEqual);
  const features = useAppSelector(selectUniqFeaturesForSites, _.isEqual);
  const utilityServiceTypes = useAppSelector(selectUniqUtilityServiceTypesForSites, _.isEqual);

  const availableChildren = group.children.filter((navigationItem) => {
    // Filter By system Type
    const systemTypeCondition =
      !navigationItem.systemTypes || intersection(navigationItem.systemTypes, availableSystemForSites).length > 0;

    // Filter By User Role
    const userRoleCondition = !navigationItem.onlyFor || navigationItem.onlyFor?.includes(userRole);

    // Filter by feature to ADR Meters if available
    const featureAccess = navigationItem.feature ? features.includes(navigationItem.feature) : true;

    // Filter by utility service type if available
    const utilityAccess = navigationItem.utilityType ? utilityServiceTypes.includes(navigationItem.utilityType) : true;

    return systemTypeCondition && userRoleCondition && featureAccess && utilityAccess;
  });

  //Dynamic height is needed for smooth group expanding animation
  const childrenContainerHeight = isExpanded ? NAV_ITEM_HEIGHT * availableChildren.length : 0;

  // Do not render group if there are no children
  if (availableChildren.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(styles['group'], {
        [styles['expanded']]: isExpanded,
      })}
    >
      <NavigationItem
        linkTo={group.linkTo}
        label={group.label}
        isActive={isActive}
        icon={group.icon}
        onClick={() => onClick(group)}
      >
        <Icon
          onClick={() => onArrowClick(group)}
          icon={isExpanded ? 'dropdown-down' : 'dropdown-right'}
          size='s'
          className={classNames('card flat', styles['group-expand-icon'])}
        />
      </NavigationItem>

      <div className={styles['group-children']} style={{ height: childrenContainerHeight }}>
        {availableChildren.map((navigationItemChild) => (
          <NavigationItem
            key={navigationItemChild.label}
            linkTo={navigationItemChild.linkTo}
            label={navigationItemChild.label}
            onClick={() => onClick(navigationItemChild)}
            icon={navigationItemChild.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default NavigationGroup;
