import { gql } from '@apollo/client';

import { usersAPI } from 'src/core/apollo/api';

import { FilterConfiguration } from '../filter.interface';

import {
  DeleteSavedFilterMutation,
  DeleteSavedFilterMutationVariables,
  PutSavedFilterMutation,
  PutSavedFilterMutationVariables,
} from './__generated__/manageSavedFilter.users.gql';

const PUT_SAVED_FILTER = gql`
  mutation PutSavedFilter($filterConfigId: String, $updateFilterConfigInput: UpdateFilterConfigInput!) {
    putFilterConfig(updateFilterConfigInput: $updateFilterConfigInput, filterConfigId: $filterConfigId)
  }
`;

const DELETE_SAVED_FILTER = gql`
  mutation DeleteSavedFilter($filterConfigId: String!) {
    deleteFilterConfig(filterConfigId: $filterConfigId)
  }
`;

export async function createFilterConfig(filter: FilterConfiguration): Promise<string> {
  const result = await usersAPI.mutate<PutSavedFilterMutation, PutSavedFilterMutationVariables>({
    mutation: PUT_SAVED_FILTER,
    variables: {
      filterConfigId: null,
      updateFilterConfigInput: {
        key: filter.config.storageKey,
        name: filter.displayValue,
        filters: filter.config.filters,
      },
    },
  });

  if (!result.data?.putFilterConfig) {
    throw 'Failed to created filter configuration';
  }

  return result.data.putFilterConfig;
}

export async function updateFilterConfig(filter: FilterConfiguration): Promise<void> {
  await usersAPI.mutate<PutSavedFilterMutation, PutSavedFilterMutationVariables>({
    mutation: PUT_SAVED_FILTER,
    variables: {
      filterConfigId: filter.key,
      updateFilterConfigInput: {
        key: filter.config.storageKey,
        name: filter.displayValue,
        filters: filter.config.filters,
      },
    },
  });

  return;
}

export async function deleteFilterConfig(filter: FilterConfiguration): Promise<void> {
  await usersAPI.mutate<DeleteSavedFilterMutation, DeleteSavedFilterMutationVariables>({
    mutation: DELETE_SAVED_FILTER,
    variables: {
      filterConfigId: filter.key,
    },
  });

  return;
}
