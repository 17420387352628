import classNames from 'classnames';
import { add, set } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import React, { useEffect, useState } from 'react';

import { useGlobalNowWithMinuteInterval } from 'src/cdk/hooks/useGlobalNowWithMinuteInterval';
import { mapSystemTypeToGroupType } from 'src/cdk/mappers/mapNotificationTypeToGroup.mapper';
import {
  DATE_FORMAT,
  OAT_MAX,
  OAT_MIN,
  OAT_RESET_LIMITS,
  PACKAGED_SYSTEM_SETPOINT_MAX,
  PACKAGED_SYSTEM_SETPOINT_MIN,
  SystemTypesForSchedule,
  UNIT,
  defaultPackagedSystemSettings,
  getSetpointLimits,
  getSetpointResetText,
  getSetpointText,
} from 'src/constants';
import {
  LockType,
  SpaceType,
  SystemGroupType,
  SystemStatus,
  SystemType,
} from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { toastService } from 'src/core/service/toastService';
import { SystemSequence, SystemTypeToTitle } from 'src/enums';
import { NumberRange, PackagedSystemSettings } from 'src/interfaces';
import LightingSlider from 'src/modules/systems/components/containers/LightingSlider/LightingSlider';
import OATReset from 'src/modules/systems/components/containers/OATResetModal/OATReset';
import PackageFanSelector from 'src/modules/systems/components/containers/PackageFanSelector/PackageFanSelector';
import PackageModeSelector from 'src/modules/systems/components/containers/PackageModeSelector/PackageModeSelector';
import PackageOccupancySelector from 'src/modules/systems/components/containers/PackageOccupancySelector/PackageOccupancySelector';
import SequenceSelector from 'src/modules/systems/components/containers/SequenceSelector/SequenceSelector';
import { SupportableSystemModels } from 'src/modules/systems/gql/getSystemsForSite.resources.gql';
import { Button } from 'src/shared/components/Button/Button';
import { ButtonGroup } from 'src/shared/components/ButtonGroup/ButtonGroup';
import { NumericControls } from 'src/shared/components/NumericControls/NumericControls';
import { Modal } from 'src/shared/components/Popup';
import { RangeSliderWithInputs } from 'src/shared/components/RangeSlider/RangeSliderWithInputs';
import { OptionItem, Select } from 'src/shared/components/Select';
import { CardLayout, UniversalSetpoint } from 'src/shared/containers/UniversalCard/UniversalCard';

import {
  SchedulerProfileSystemsConfiguration,
  SchedulerProfileSystemsSettings,
} from '../../gql/getScheduler.resources.gql';

import styles from './AddScheduleModal.module.scss';

const SCHEDULER_OPTIONS_ARRAY = new Array(24).fill(null);

export interface AddScheduleModalProps {
  isOpen?: boolean;
  acceptAction: (config: SchedulerProfileSystemsConfiguration, timePeriod: Date) => void;
  cancelAction: () => void;
  deleteAction?: () => void;
  systemType: SystemType;
  scheduleName: string;
  timeZone: string;
  title?: string;
  underSchedulerControl?: boolean | null;
  endTime?: Date;
  config?: Partial<SchedulerProfileSystemsConfiguration>;
  systemModel?: SupportableSystemModels;
  busyHours?: [number, number][];
  disabled?: boolean;
}

const AddScheduleModal: React.FC<AddScheduleModalProps> = (props) => {
  const setpointLimits = getSetpointLimits(props.systemType);
  const systemGroup = mapSystemTypeToGroupType(props.systemType);
  // Override scheduler profile
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [currentTime, setCurrentTime] = useState<Date>(utcToZonedTime(new Date(), props.timeZone));
  const [availableOverrideTimePeriod, setAvailableOverrideTimePeriod] = useState<OptionItem<string>[]>([]);

  useGlobalNowWithMinuteInterval(() => setCurrentTime(utcToZonedTime(new Date().toISOString(), props.timeZone)));

  useEffect(() => {
    if (props.isOpen) {
      setCurrentTime(utcToZonedTime(new Date().toISOString(), props.timeZone));
      const options: OptionItem<string>[] = [];

      for (let index = 0; index < SCHEDULER_OPTIONS_ARRAY.length; index++) {
        const newDate = add(set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }), {
          hours: 1 + (index ?? 0),
        });
        const date = utcToZonedTime(newDate.toISOString(), props.timeZone);
        if (currentTime.getDate() !== date.getDate()) {
          break;
        }

        options.push({
          key: newDate.toISOString(),
          displayValue: format(date, DATE_FORMAT.LOCAL_TIME, { timeZone: props.timeZone }),
        });
      }
      setAvailableOverrideTimePeriod(options);
    }
  }, [props.isOpen]);

  const packagedSystemSettingsInit: PackagedSystemSettings = {
    setpointMax: props.config?.settings?.setpointMax ?? defaultPackagedSystemSettings.setpointMax,
    setpointMin: props.config?.settings?.setpointMin ?? defaultPackagedSystemSettings.setpointMin,
    mode: props.config?.settings?.mode ?? defaultPackagedSystemSettings.mode,
    fan: props.config?.settings?.fan ?? defaultPackagedSystemSettings.fan,
    space: props.config?.settings?.space ?? defaultPackagedSystemSettings.space,
    occupiedSetpointCool:
      props.config?.settings?.occupiedSetpointCool ?? defaultPackagedSystemSettings.occupiedSetpointCool,
    occupiedSetpointHeat:
      props.config?.settings?.occupiedSetpointHeat ?? defaultPackagedSystemSettings.occupiedSetpointHeat,
    unoccupiedSetpointCool:
      props.config?.settings?.unoccupiedSetpointCool ?? defaultPackagedSystemSettings.unoccupiedSetpointCool,
    unoccupiedSetpointHeat:
      props.config?.settings?.unoccupiedSetpointHeat ?? defaultPackagedSystemSettings.unoccupiedSetpointHeat,
    setLock: props.config?.settings?.setLock ?? defaultPackagedSystemSettings.setLock,
  };
  const [selectedStartTime, setSelectedStartTime] = useState<number>(props.config?.fromHour ?? 0);
  const [selectedEndTime, setSelectedEndTime] = useState<number>(props.config?.toHour ?? 0);
  const [selectedSequence, setSelectedSequence] = useState<SystemSequence>(props.config?.sequenceId as SystemSequence);
  const [selectedSetpoint, setSelectedPoint] = useState<number>(props.config?.settings?.setpoint ?? 0);
  const [selectedStatus, setSelectedStatus] = useState<SystemStatus>(
    props.config?.settings?.systemState ?? SystemStatus.OFF
  );
  const [selectedOatTemperature, setSelectedOatTemperature] = useState<NumberRange>([
    props.config?.settings?.outsideTempMin ?? OAT_MIN,
    props.config?.settings?.outsideTempMax ?? OAT_MAX,
  ]);
  const [selectedSetpointLimits, setSelectedSetpointLimits] = useState<NumberRange>([
    props.config?.settings?.setpointMin ?? setpointLimits[0],
    props.config?.settings?.setpointMax ?? setpointLimits[1],
  ]);
  const [packagedSystemSettings, setPackagedSystemSettings] =
    useState<PackagedSystemSettings>(packagedSystemSettingsInit);

  const title = props.title || `${SystemTypeToTitle[props.systemType]}: ${props.scheduleName}`;
  const subtitleText = props?.underSchedulerControl
    ? 'System will revert back to schedule after time range'
    : 'Define time period and sequence needed to be applied';

  useEffect(() => {
    if (props.endTime) {
      // even out the time conversion difference from utc
      setSelectedTime(props.endTime.toISOString());
    }
    setSelectedStartTime(props.config?.fromHour ?? 0);
    setSelectedEndTime(props.config?.toHour ?? 0);
    setSelectedSequence((props.config?.sequenceId as SystemSequence) ?? SystemSequence.Manual);
    setSelectedPoint(props.config?.settings?.setpoint ?? 0);
    setSelectedStatus(props.config?.settings?.systemState ?? SystemStatus.OFF);
    setSelectedOatTemperature([
      props.config?.settings?.outsideTempMin ?? OAT_MIN,
      props.config?.settings?.outsideTempMax ?? OAT_MAX,
    ]);
    setSelectedSetpointLimits([
      props.config?.settings?.setpointMin ?? setpointLimits[0],
      props.config?.settings?.setpointMax ?? setpointLimits[1],
    ]);
    setPackagedSystemSettings(packagedSystemSettingsInit);
  }, [props.config, props.endTime]);

  function prepareConfigPayload(): SchedulerProfileSystemsConfiguration {
    const settings: SchedulerProfileSystemsSettings = {
      setpointMax: null,
      setpointMin: null,
      outsideTempMax: null,
      outsideTempMin: null,
      mode: null,
      fan: null,
      setpoint: null,
      systemState: null,
      space: null,
      occupiedSetpointCool: null,
      occupiedSetpointHeat: null,
      unoccupiedSetpointCool: null,
      unoccupiedSetpointHeat: null,
      setLock: null,
    };

    if (systemGroup === SystemGroupType.AIR_HANDLING_UNIT) {
      settings.setpointMax = packagedSystemSettings.setpointMax;
      settings.setpointMin = packagedSystemSettings.setpointMin;
      settings.mode = packagedSystemSettings.mode;
      settings.fan = packagedSystemSettings.fan;
      settings.space = packagedSystemSettings.space;
      settings.setLock = packagedSystemSettings.setLock;
      if (packagedSystemSettings.space === SpaceType.OCCUPIED) {
        settings.occupiedSetpointCool = packagedSystemSettings.occupiedSetpointCool;
        settings.occupiedSetpointHeat = packagedSystemSettings.occupiedSetpointHeat;
      } else {
        settings.unoccupiedSetpointCool = packagedSystemSettings.unoccupiedSetpointCool;
        settings.unoccupiedSetpointHeat = packagedSystemSettings.unoccupiedSetpointHeat;
      }
    } else if (
      props.systemType === SystemType.LUTRON_VIVE_LIGHTING ||
      props.systemType === SystemType.SMART_OUTLET_T0006623
    ) {
      settings.systemState = selectedStatus;
    } else {
      if (selectedSequence === SystemSequence.Manual) {
        settings.setpoint = selectedSetpoint;
      } else if (selectedSequence === SystemSequence.OATReset) {
        settings.outsideTempMin = selectedOatTemperature[0];
        settings.outsideTempMax = selectedOatTemperature[1];
        settings.setpointMax = selectedSetpointLimits[1];
        settings.setpointMin = selectedSetpointLimits[0];
      }
    }

    const config: SchedulerProfileSystemsConfiguration = {
      fromHour: selectedStartTime,
      toHour: selectedEndTime,
      sequenceId: selectedSequence,
      settings,
      date: props.config?.date as Date | null,
      dayWeek: props.config?.dayWeek as number,
    };

    return config;
  }

  const hasError = hasOverlaps(selectedStartTime, selectedEndTime, props.busyHours);

  return (
    <Modal
      isOpen={props.isOpen}
      acceptAction={() => {
        if (!selectedSequence) {
          toastService.warn('Select sequence');
          return;
        }
        props.acceptAction(prepareConfigPayload(), new Date(selectedTime));
      }}
      cancelAction={props.cancelAction}
      titleText={title}
      subtitleText={subtitleText}
      actionButtonLabel='Save'
      cancelButtonLabel='Cancel'
      deleteButtonLabel={props.config?.sequenceId ? 'Delete' : undefined}
      deleteAction={props.deleteAction}
      disableAccept={props.disabled}
      disableDelete={props.disabled}
    >
      <div
        className={classNames(styles['add-schedule-modal-content'], {
          [styles['modal-disabled']]: props.disabled,
        })}
      >
        {props.underSchedulerControl ? (
          <div className={styles['row']}>
            <Select
              className={styles['select-time-range']}
              labelPosition='up'
              label='Time Range'
              secondLabel={props.timeZone && `Local Time: ${format(currentTime, DATE_FORMAT.DATE_TIME_SHORT)}`}
              value={selectedTime}
              options={availableOverrideTimePeriod}
              onClick={setSelectedTime}
            />
          </div>
        ) : (
          <div className={styles['row']} style={{ position: 'relative' }}>
            <Select
              className={styles['select-wrapper']}
              labelPosition='up'
              label='Start Time'
              value={selectedStartTime}
              options={timeOptions(true, selectedEndTime, props.busyHours)}
              onClick={setSelectedStartTime}
            />
            <Select
              className={styles['select-wrapper']}
              labelPosition='up'
              label='End Time'
              value={selectedEndTime}
              options={timeOptions(false, selectedStartTime, props.busyHours)}
              onClick={setSelectedEndTime}
            />
            {hasError && (
              <p
                className='body-small color-error'
                style={{
                  position: 'absolute',
                  bottom: '-20px',
                }}
              >
                Selected time overlaps with other schedule configurations
              </p>
            )}
          </div>
        )}

        <div className={styles['row']}>
          <div
            className={styles['select-wrapper']}
            style={{
              width: selectedSequence !== SystemSequence.Manual ? '100%' : '45%',
            }}
          >
            <SequenceSelector
              disabled={false}
              labelPosition='up'
              sequenceId={selectedSequence}
              systemType={props.systemType}
              onChange={setSelectedSequence}
              layout={CardLayout.horizontal}
            />
          </div>
          {selectedSequence === SystemSequence.Manual && systemGroup === SystemGroupType.AIR_HANDLING_UNIT && (
            <div className={styles['select-wrapper']}>
              <PackageOccupancySelector
                systemType={props.systemType}
                labelUp
                disabled={false}
                space={packagedSystemSettings.space}
                onChange={(space) => setPackagedSystemSettings({ ...packagedSystemSettings, space })}
              />
            </div>
          )}
          {selectedSequence === SystemSequence.Manual &&
            SystemTypesForSchedule.includes(props.systemType) &&
            systemGroup !== SystemGroupType.AIR_HANDLING_UNIT &&
            props.systemType !== SystemType.SMART_OUTLET_T0006623 &&
            props.systemType !== SystemType.LUTRON_VIVE_LIGHTING && (
              <div className={styles['select-wrapper']}>
                <p className='mb-8 color-secondary'>{getSetpointText(props.systemType)}</p>
                <NumericControls
                  min={setpointLimits[0]}
                  max={setpointLimits[1]}
                  onChange={setSelectedPoint}
                  onSubmit={setSelectedPoint}
                  value={selectedSetpoint}
                  className={styles['numeric-control']}
                />
              </div>
            )}
          {selectedSequence === SystemSequence.Manual &&
            (props.systemType === SystemType.LUTRON_VIVE_LIGHTING ||
              props.systemType === SystemType.SMART_OUTLET_T0006623) && (
              <div className={styles['select-wrapper']}>
                <p className='mb-8 color-secondary'>Mode</p>
                <ButtonGroup>
                  <Button
                    className={classNames('body-small', styles['button-group'])}
                    label='On'
                    isPressed={selectedStatus === SystemStatus.ON}
                    onClick={() => setSelectedStatus(SystemStatus.ON)}
                  />
                  <Button
                    className={classNames('body-small', styles['button-group'])}
                    label='Off'
                    isPressed={selectedStatus === SystemStatus.OFF}
                    onClick={() => setSelectedStatus(SystemStatus.OFF)}
                  />
                </ButtonGroup>
              </div>
            )}
        </div>
        {selectedSequence === SystemSequence.OATReset && (
          <OATReset
            oatTemperatureLimits={OAT_RESET_LIMITS}
            setpointLimits={setpointLimits}
            oatTemperatureLabel={`Outside Temperature (${UNIT.FAHRENHEIT})`}
            setpointLabel={getSetpointResetText(props.systemType)}
            selectedOatTemperature={selectedOatTemperature}
            setSelectedOatTemperature={setSelectedOatTemperature}
            selectedSetpoint={selectedSetpointLimits}
            setSelectedSetpoint={setSelectedSetpointLimits}
          />
        )}
        {systemGroup === SystemGroupType.AIR_HANDLING_UNIT && selectedSequence === SystemSequence.Manual && (
          <>
            <div className={styles['row']}>
              <div className={styles['select-wrapper']}>
                <PackageModeSelector
                  labelUp
                  systemType={props.systemType}
                  disabled={false}
                  mode={packagedSystemSettings.mode}
                  modeEffective={packagedSystemSettings.mode}
                  onChange={(mode) =>
                    setPackagedSystemSettings({
                      ...packagedSystemSettings,
                      mode,
                    })
                  }
                  heatingStages={props.systemModel?.heatingStages ?? 1}
                  coolingStages={props.systemModel?.coolingStages ?? 1}
                />
              </div>
              <div className={styles['select-wrapper']}>
                <PackageFanSelector
                  systemType={props.systemType}
                  labelUp
                  disabled={false}
                  fan={packagedSystemSettings.fan}
                  onChange={(fan) =>
                    setPackagedSystemSettings({
                      ...packagedSystemSettings,
                      fan,
                    })
                  }
                  fanLevels={props.systemModel?.fanLevels ?? 3}
                />
              </div>
            </div>

            <div className={styles['row']}>
              <div className='w-100'>
                <p className='mb-8 color-secondary'>Local Control</p>
                <ButtonGroup>
                  <Button
                    className={classNames(styles['button-width-manual'], 'body-small')}
                    size='small'
                    label='Disabled'
                    isPressed={packagedSystemSettings.setLock === LockType.LOCKED}
                    onClick={() => setPackagedSystemSettings({ ...packagedSystemSettings, setLock: LockType.LOCKED })}
                  />
                  <Button
                    className={classNames(styles['button-width-manual'], 'body-small')}
                    size='small'
                    label='Enabled'
                    isPressed={packagedSystemSettings.setLock === LockType.UNLOCKED}
                    onClick={() => setPackagedSystemSettings({ ...packagedSystemSettings, setLock: LockType.UNLOCKED })}
                  />
                </ButtonGroup>
              </div>
            </div>

            <RangeSliderWithInputs
              label={`Setpoint Limits (${UNIT.FAHRENHEIT})`}
              value={[packagedSystemSettings.setpointMin, packagedSystemSettings.setpointMax]}
              onChange={([setpointMin, setpointMax]) =>
                setPackagedSystemSettings((prevValue) => ({ ...prevValue, setpointMin, setpointMax }))
              }
              min={PACKAGED_SYSTEM_SETPOINT_MIN}
              max={PACKAGED_SYSTEM_SETPOINT_MAX}
            />

            <p className='mb-8 color-secondary'>{`${
              packagedSystemSettings.space === SpaceType.UNOCCUPIED ? 'Unoccupied' : 'Occupied'
            } Setpoints (${UNIT.FAHRENHEIT})`}</p>
            <div className={styles['row']}>
              <div className={styles['universal-setpoint']}>
                <UniversalSetpoint verticalLabel='Heat'>
                  <NumericControls
                    min={packagedSystemSettings.setpointMin}
                    max={packagedSystemSettings.setpointMax}
                    onChange={(setpointHeat) => {
                      packagedSystemSettings.space === SpaceType.UNOCCUPIED
                        ? setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            unoccupiedSetpointHeat: setpointHeat,
                          }))
                        : setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            occupiedSetpointHeat: setpointHeat,
                          }));
                    }}
                    onSubmit={(setpointHeat) => {
                      packagedSystemSettings.space === SpaceType.UNOCCUPIED
                        ? setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            unoccupiedSetpointHeat: setpointHeat,
                          }))
                        : setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            occupiedSetpointHeat: setpointHeat,
                          }));
                    }}
                    value={
                      packagedSystemSettings.space === SpaceType.UNOCCUPIED
                        ? packagedSystemSettings.unoccupiedSetpointHeat
                        : packagedSystemSettings.occupiedSetpointHeat
                    }
                    className={styles['numeric-control']}
                  />
                </UniversalSetpoint>
              </div>
              <div className={styles['universal-setpoint']}>
                <UniversalSetpoint verticalLabel='Cool'>
                  <NumericControls
                    min={packagedSystemSettings.setpointMin}
                    max={packagedSystemSettings.setpointMax}
                    onChange={(setpointCool) => {
                      packagedSystemSettings.space === SpaceType.UNOCCUPIED
                        ? setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            unoccupiedSetpointCool: setpointCool,
                          }))
                        : setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            occupiedSetpointCool: setpointCool,
                          }));
                    }}
                    onSubmit={(setpointCool) => {
                      packagedSystemSettings.space === SpaceType.UNOCCUPIED
                        ? setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            unoccupiedSetpointCool: setpointCool,
                          }))
                        : setPackagedSystemSettings((prevValue) => ({
                            ...prevValue,
                            occupiedSetpointCool: setpointCool,
                          }));
                    }}
                    value={
                      packagedSystemSettings.space === SpaceType.UNOCCUPIED
                        ? packagedSystemSettings.unoccupiedSetpointCool
                        : packagedSystemSettings.occupiedSetpointCool
                    }
                    className={styles['numeric-control']}
                  />
                </UniversalSetpoint>
              </div>
            </div>
          </>
        )}
        {props.systemType === SystemType.LUTRON_VIVE_LIGHTING && selectedSequence === SystemSequence.Manual && (
          <div className={styles['select-wrapper']}>
            <p className='mb-16 color-secondary'>{getSetpointText(props.systemType)}</p>
            <div className='d-flex'>
              <LightingSlider
                disabled={false}
                onChange={setSelectedPoint}
                setpoint={selectedSetpoint}
                setpointRange={setpointLimits}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export function hasOverlaps(cFrom: number, cTo: number, busyHours: [number, number][] = []): boolean {
  for (const [from, to] of busyHours) {
    if ((cFrom >= from && cTo < to) || (from >= cFrom && to < cTo)) {
      return true;
    }
  }
  return false;
}

export function timeOptions(
  isStartTime: boolean,
  relatedTime?: number,
  busyHours: [number, number][] = []
): OptionItem<number>[] {
  const options: OptionItem<number>[] = [];
  for (let i = 0; i < 24; i++) {
    const key = isStartTime ? i : i + 1;
    const displayValue = format(new Date(2023, 1, 1, key, 0, 0, 0), DATE_FORMAT.HOURS_AM_PM);

    if (isStartTime && relatedTime && i > relatedTime) {
      continue;
    } else if (!isStartTime && relatedTime && i < relatedTime) {
      continue;
    }
    const isBusy = busyHours.some(([from, to]) => {
      return i >= from && i < to;
    });
    if (isBusy) {
      continue;
    }

    options.push({
      key,
      displayValue,
    });
  }
  return options;
}

export default AddScheduleModal;
