import _ from 'lodash';
import { Outlet } from 'react-router';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import { useAppSelector } from 'src/core/store/hooks';
import { selectSiteById } from 'src/modules/sites/sitesSlice';
import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';
import PageHeaderWithActions from 'src/shared/containers/PageHeaderWithActions';
import { PageHeaderWithActionsSkeletonLoader } from 'src/shared/containers/PageHeaderWithActions/PageHeaderWithActions.skeleton';

interface Props {
  children?: React.ReactNode;
  title?: string;
  tabsNames?: string[];
  isInitialLoading?: boolean;
  isFailed?: boolean;
  /**
   * TODO: remove when all pages will be migrated to new router
   */
  backUrl?: string;
}

const OverviewPerSiteLayout: React.FC<Props> = ({
  title,
  children,
  tabsNames,
  isFailed,
  isInitialLoading,
  backUrl,
}) => {
  const siteId = useNumberParam('siteId');
  const site = useAppSelector(selectSiteById(siteId), _.isEqual);

  if (isFailed) {
    return <ElevatedBox error>Failed to load site details</ElevatedBox>;
  }

  return (
    <>
      {!site || isInitialLoading ? (
        <PageHeaderWithActionsSkeletonLoader
          hideBackButtonWhenSingleSiteIsSelected
          withBackButton
          subtitle
          removeBottomMargin
        />
      ) : (
        <PageHeaderWithActions
          hideBackButtonWhenSingleSiteIsSelected
          backUrl={backUrl ? backUrl : '...'}
          title={title || site.nameOrAddess}
          subtitle={title ? site.nameWithCity : site.regionWithState}
          timezone={site.timezone}
          removeBottomMargin
        />
      )}
      {tabsNames && <UrlTabs names={tabsNames} />}
      {isInitialLoading ? null : children ? children : <Outlet />}
    </>
  );
};

export default OverviewPerSiteLayout;
