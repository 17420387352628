import classNames from 'classnames';

import { useAppSelector } from 'src/core/store/hooks';
import { selectedSiteIdIfSingle } from 'src/modules/sites/sitesSlice';
import { SkeletonLoader } from 'src/shared/components/Loader/Loader';

import styles from './PageHeaderWithActions.module.scss';

interface PageHeaderWithActionsSkeletonLoaderProps {
  subtitle?: boolean;
  withBackButton?: boolean;
  hideBackButtonWhenSingleSiteIsSelected?: boolean;
  defaultTextInsteadOfTitle?: boolean;
  removeBottomMargin?: boolean;
  children?: React.ReactNode;
}

export const PageHeaderWithActionsSkeletonLoader: React.FC<PageHeaderWithActionsSkeletonLoaderProps> = (props) => {
  const isSingleSiteSelected = useAppSelector(selectedSiteIdIfSingle);
  const showBackUrl = props.withBackButton
    ? isSingleSiteSelected
      ? !props.hideBackButtonWhenSingleSiteIsSelected
      : true
    : false;

  return (
    <div className={classNames(styles['header'])} style={props.removeBottomMargin ? { marginBottom: '0px' } : {}}>
      {showBackUrl && <SkeletonLoader height='36px' width='36px' className='mr-24' />}
      <div className={classNames(styles['info'])}>
        <SkeletonLoader
          type='header-25'
          className='mb-4'
          height={props.defaultTextInsteadOfTitle ? '20px' : '24px'}
          width='100px'
        />
        {props.subtitle && <SkeletonLoader type='text-50' height='16px' width='200px' />}
      </div>
      <div className={styles['actions']}>{props.children}</div>
    </div>
  );
};
