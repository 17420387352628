import classnames from 'classnames';
import Slider from 'rc-slider';

import styles from './StandardSlider.module.scss';
import 'rc-slider/assets/index.css';

export interface StandardSliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (v: number) => void;
  disabled: boolean;
  step?: number;
  reverse?: boolean;
}

const StandardSlider: React.FC<StandardSliderProps> = ({
  min,
  max,
  step = 1,
  reverse = false,
  onChange,
  value,
  disabled,
}) => {
  return (
    <span
      className={classnames(styles['slider-container'], {
        [styles['disabled']]: disabled,
      })}
    >
      <Slider
        range={false}
        min={min}
        max={max}
        step={step}
        className={styles['standard-slider']}
        reverse={reverse}
        onChange={(s) => onChange(s as number)}
        value={value}
      />
    </span>
  );
};

export default StandardSlider;
