import _ from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router';

import { useAppSelector } from 'src/core/store/hooks';
import { selectSiteById } from 'src/modules/sites/sitesSlice';
import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';
import { CircularLoader } from 'src/shared/components/Loader/Loader';
import PageHeaderWithActions from 'src/shared/containers/PageHeaderWithActions';

import { PageMetadata, PageMetadataContextProvider } from './pageContext';

const DetailsPageLayout: React.FC<{
  skeleton?: React.ReactElement;
  isInitialLoading: boolean;
  isFailed: boolean;
  entity: string;
  entityName?: string;
  children: React.ReactNode;
  subtitle?: string;
  subtitlePrefix?: string;
  idParamName: string;
  relatedSiteId?: number;
  removeBottomMargin?: boolean;
  backUrl?: string;
}> = ({
  entity,
  entityName: title,
  skeleton,
  isInitialLoading,
  isFailed,
  children,
  subtitle,
  subtitlePrefix,
  idParamName,
  relatedSiteId,
  removeBottomMargin,
  backUrl,
}) => {
  const params = useParams();
  const entityId = params[idParamName];
  const isCreationFlow = _.isNil(entityId) || entityId === 'create';
  const site = useAppSelector(selectSiteById(relatedSiteId), _.isEqual);

  const [metadata, setMetadata] = useState<PageMetadata>();

  const pageTitle = isInitialLoading
    ? ''
    : isCreationFlow
      ? `Create ${entity}`
      : metadata?.title || title || `${entity} Details`;

  const pageSubtitle = isInitialLoading
    ? ''
    : (metadata?.subtitle || subtitle) ??
      (subtitlePrefix ? subtitlePrefix + ' | ' + site?.nameWithCity : site?.nameWithCity) ??
      (isCreationFlow ? '' : `Review and edit ${entity} details`);

  return (
    <PageMetadataContextProvider
      value={{
        setMetadata,
      }}
    >
      <PageHeaderWithActions
        backUrl={backUrl || '..'}
        title={pageTitle}
        subtitle={pageSubtitle}
        timezone={site?.timezone}
        removeBottomMargin={removeBottomMargin}
      />
      {isInitialLoading ? (
        skeleton ?? <CircularLoader />
      ) : isFailed ? (
        <ElevatedBox error>Failed to load {entity} Information</ElevatedBox>
      ) : (
        children
      )}
    </PageMetadataContextProvider>
  );
};

export default DetailsPageLayout;
