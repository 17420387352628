import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import { mapSystemTypeToGroupType } from 'src/cdk/mappers/mapNotificationTypeToGroup.mapper';
import { SystemGroupType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { selectSiteById } from 'src/modules/sites/sitesSlice';
import AnalyticsTile from 'src/modules/systems/components/containers/AnalyticsTile/AnalyticsTile';
import GeneralDetails from 'src/modules/systems/pages/SystemPage/GeneralDetails/GeneralDetails';
import HwBoilerHtHwrCard from 'src/modules/systems/system-cards/HwBoilerHtHwrCard/HwBoilerHtHwrCard';
import HwBoilerTekmar284Card from 'src/modules/systems/system-cards/HwBoilerTekmar284Card/HwBoilerTekmar284Card';
import PackageAltc24ProgCard from 'src/modules/systems/system-cards/PackageAltc24ProgCard/PackageAltc24ProgCard';
import PrvSystemCard from 'src/modules/systems/system-cards/PrvSystemCard/PrvSystemCard';
import StBoilerFHtMpcCard from 'src/modules/systems/system-cards/StBoilerFHtMpc/StBoilerFHtMpcCard';
import WeatherSystemCard from 'src/modules/systems/system-cards/WeatherSystemCard/WeatherSystemCard';
import { selectedSystemById } from 'src/modules/systems/systemsSlice';
import { CardLayout } from 'src/shared/containers/UniversalCard/UniversalCard';

import styles from './page.module.scss';

const SystemPage: React.FC = () => {
  const systemId = useNumberParam('id');

  const system = useSelector(selectedSystemById(systemId));
  const { nameOrAddess: siteName, timezone } = useSelector(selectSiteById(system?.siteId)) || {};

  return (
    <div className={classNames(styles['tiles-container'], 'mb-32')}>
      <div className={styles['info-tiles']}>
        <section className='card el-04'>
          {system?.type === SystemType.STEAM_PRV_STATION && (
            <PrvSystemCard systemId={systemId} layout={CardLayout.horizontal} timezone={timezone} />
          )}
          {system?.type === SystemType.HW_BOILER_TEKMAR_284 && (
            <HwBoilerTekmar284Card systemId={systemId} layout={CardLayout.horizontal} timezone={timezone} />
          )}
          {system?.type === SystemType.ST_BOILER_F_HT_MPC && (
            <StBoilerFHtMpcCard systemId={systemId} layout={CardLayout.horizontal} timezone={timezone} />
          )}
          {system?.type === SystemType.HW_BOILER_HT_HWR && (
            <HwBoilerHtHwrCard systemId={systemId} layout={CardLayout.horizontal} timezone={timezone} />
          )}
          {mapSystemTypeToGroupType(system?.type) === SystemGroupType.AIR_HANDLING_UNIT && (
            <PackageAltc24ProgCard systemId={systemId} layout={CardLayout.horizontal} timezone={timezone} />
          )}
          {system?.type === SystemType.WEATHER_STATION_WTS506 && (
            <WeatherSystemCard systemId={systemId} layout={CardLayout.horizontal} timezone={timezone} />
          )}
        </section>
        <section className='card el-04'>
          <GeneralDetails system={system} timezone={timezone} systemId={systemId} />
        </section>
      </div>
      <section className={classNames('card el-04 p-24', styles['analytics-tile'])}>
        <AnalyticsTile systemIds={[systemId]} exportFileName={`${siteName} Analytics Metrics`} />
      </section>
    </div>
  );
};

export default SystemPage;
