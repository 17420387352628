import classNames from 'classnames';
import { addDays, differenceInCalendarDays } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { DayPicker, addToRange } from 'react-day-picker';

import { roundToLocalDays } from 'src/cdk/utils/roundToDays';
import { roundToNearestMinutes } from 'src/cdk/utils/roundToNearestMinutes';
import { WEEKDAYS, WEEKDAY_NUMBERS } from 'src/constants';
import { MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import Logger from 'src/core/service/logger';

import { Button } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import { Input } from '../../Input/Input';
import { formatToAmPm } from '../../InputTime/utils';
import { Dropdown } from '../../Popup';
import { Select } from '../../Select';
import { DatePickerRangeType, buttonsConfig, formatDate } from '../DateRangePicker/config';
import { baseDatePickerClassNames, baseDatePickerComponents } from '../base/base-date-picker';

import styles from './DateRangePickerWithIntervals.module.scss';
import { defineStepFromDateRange, getAvailableSteps } from './dateRangeStep.util';

const QuickFilters: DatePickerRangeType[] = [
  DatePickerRangeType.LAST_24_HOURS,
  DatePickerRangeType.THIS_WEEK,
  DatePickerRangeType.LAST_WEEK,
  DatePickerRangeType.THIS_MONTH,
  DatePickerRangeType.LAST_MONTH,
];

export interface DateRangeWithIntervals {
  type: DatePickerRangeType;
  from: Date;
  to: Date;
  step: MetricsStep;
  /**
   * @default All days are selected
   */
  weekDays: number[];
  hours?: number[];
  months?: number[];
  years?: number[];
}

/**
 * Use this function to build initial date range for DateRangePickerWithIntervals component
 */
export function buildInitialDateRange(
  defaultType: DatePickerRangeType,
  step?: MetricsStep
): () => DateRangeWithIntervals {
  return () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [from, to] = buttonsConfig[defaultType]!.getTimeRange();
    return {
      type: defaultType,
      from,
      to,
      step: step ?? defineStepFromDateRange(from, to),
      weekDays: WEEKDAY_NUMBERS,
    };
  };
}

interface Props {
  value: DateRangeWithIntervals;
  className?: string;
  buttonsToDisplay?: DatePickerRangeType[];
  onChange: (timeRange: DateRangeWithIntervals) => void;
  /**
   * If specified - user will not be able to change or choose step
   */
  forceStep?: MetricsStep;
}

const MAX_DAYS_DIFFERENCE = 366;

let today = roundToNearestMinutes(new Date(), { nearestTo: 15 });

function refreshToday() {
  today = roundToNearestMinutes(new Date(), { nearestTo: 15 });
}

const DateRangePickerWithIntervals: React.FC<Props> = ({
  value,
  className,
  onChange,
  buttonsToDisplay = QuickFilters,
  forceStep,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fromHour, setFromHour] = useState<number>();
  const [toHour, setToHour] = useState<number>();
  const [internalValue, setInternalValue] = useState<Partial<DateRangeWithIntervals>>(value);
  const [quickRangeType, setQuickRangeType] = useState<DatePickerRangeType>(DatePickerRangeType.CUSTOM);
  const [month, setMonth] = useState<Date>(value.to || value.from || today);

  const availableSteps = useMemo(() => {
    return getAvailableSteps({
      from: internalValue.from,
      to: internalValue.to,
    });
  }, [internalValue.from, internalValue.to]);

  // TODO: update `useDisabledDays` and use it

  // Used to display a label in the field
  const selectedType = value.type || DatePickerRangeType.CUSTOM;

  useEffect(() => {
    setQuickRangeType(selectedType);
  }, [selectedType]);

  function onShow() {
    refreshToday();
    const { from, to } = extractBoundaryHoursFromRange(value.hours);
    setFromHour(from);
    setToHour(to);
    setInternalValue(value);
  }

  function onSubmit() {
    if (internalValue.from && internalValue.to && internalValue.step) {
      const changedProperties = _.cloneDeep(internalValue as DateRangeWithIntervals);
      changedProperties.hours = buildHoursRange(fromHour, toHour);
      changedProperties.weekDays = changedProperties.weekDays ?? WEEKDAY_NUMBERS;

      // Used to close dropdown immediately and run processes which will be triggered by onChange in the next tick
      setTimeout(() => {
        onChange(changedProperties);
      }, 10);

      setIsOpen(false);
    } else {
      Logger.error('Invalid date range');
    }
  }

  function setStep(step: MetricsStep) {
    setInternalValue((prev) => ({ ...prev, step: forceStep ?? step }));
  }

  function toggleWeekday(i: number) {
    setInternalValue((prev) => ({ ...prev, weekDays: buildSelectedWeekdays(i, internalValue.weekDays) }));
  }

  function cancelClick(): void {
    setInternalValue(value);
    setQuickRangeType(selectedType);
    setIsOpen(false);
  }

  function handleDayClick(day: Date) {
    const { from, to } = buildUpdatedDateRange(day, internalValue.from, internalValue.to);

    const step = forceStep ?? (from && to ? defineStepFromDateRange(from, to) : undefined);

    setInternalValue((prev) => ({
      ...prev,
      from,
      to,
      previousInterval: undefined,
      step,
      type: DatePickerRangeType.CUSTOM,
    }));
    setQuickRangeType(DatePickerRangeType.CUSTOM);
  }

  function presetRangeClick(range: [Date, Date], key: DatePickerRangeType) {
    const step = forceStep ?? defineStepFromDateRange(range[0], range[1]);
    setInternalValue({
      type: key,
      from: range[0],
      to: range[1],
      hours: undefined,
      weekDays: WEEKDAY_NUMBERS,
      step,
    });
    setFromHour(undefined);
    setToHour(undefined);
    setQuickRangeType(key);
  }

  const isApplyDisabled =
    _.isEqual(value, {
      ...internalValue,
      hours: buildHoursRange(fromHour, toHour),
      weekDays: internalValue.weekDays ?? WEEKDAY_NUMBERS,
    }) ||
    !internalValue.from ||
    !internalValue.to;

  const fromDateLabel = formatDate(value.from);
  const toDateLabel = formatDate(value.to);

  return (
    <div className={styles['date-picker-wrapper']}>
      <Dropdown
        isOpen={isOpen}
        onShow={onShow}
        onHide={cancelClick}
        alwaysUnderTrigger
        rootId='dateRangePickerWithInterval'
        triggerComponent={(isOpen) => (
          <button
            className={classNames('input', 'with-pointer', styles['date-picker-btn'], className)}
            onClick={() => setIsOpen(true)}
          >
            <span className={classNames('color-secondary', styles['label-inside'])}>{selectedType}:</span>
            <span className={classNames('color-primary', 'body-semi-bold')}>{`${fromDateLabel} - ${toDateLabel}`}</span>
            <Icon icon={isOpen ? 'dropdown-up' : 'dropdown-down'} size='s' className={styles['dropdown-down-icon']} />
          </button>
        )}
      >
        <div className={classNames('card el-08', styles['date-picker-body'])}>
          <div className={styles['date-picker-grid']}>
            <div className='d-flex gap-16'>
              <div>
                {/* Disabled inputs */}
                <div className='d-flex align-items-center mb-8'>
                  <Input
                    id='name'
                    value={internalValue.from ? formatDate(internalValue.from) : ''}
                    className={styles['date-picker-input']}
                    onChange={() => null}
                    readonly
                    disabled
                  />
                  &nbsp;-&nbsp;
                  <Input
                    id='name'
                    value={internalValue.to ? formatDate(internalValue.to) : ''}
                    className={styles['date-picker-input']}
                    onChange={() => null}
                    readonly
                    disabled
                  />
                </div>

                {/* Calendar */}
                <DayPicker
                  mode='range'
                  showOutsideDays={true}
                  month={month}
                  toMonth={today}
                  disabled={[
                    {
                      after: new Date(),
                    },
                    {
                      dayOfWeek:
                        internalValue.weekDays?.length === 7
                          ? []
                          : WEEKDAY_NUMBERS.filter((i) => !internalValue.weekDays?.includes(i)),
                    },
                  ]}
                  selected={{ from: internalValue.from, to: internalValue.to || internalValue.from }}
                  onDayClick={(d, modifiers) => {
                    if (modifiers.disabled) {
                      return;
                    }
                    handleDayClick(d);
                  }}
                  onMonthChange={setMonth}
                  classNames={baseDatePickerClassNames}
                  components={baseDatePickerComponents}
                />
              </div>
            </div>

            {/* Weekday & Hours & Step picker */}
            <div className='pt-2'>
              <p className='body-small mt-8 color-secondary'>Days of Week </p>
              <div className='d-flex mt-12 gap-8'>
                {WEEKDAYS.map((day, i) => (
                  <Button
                    key={day}
                    variant={
                      internalValue.weekDays &&
                      internalValue.weekDays.length !== 7 &&
                      internalValue.weekDays.includes(i)
                        ? 'primary'
                        : 'secondary'
                    }
                    label={day.slice(0, 3)}
                    onClick={() => {
                      toggleWeekday(i);
                    }}
                  />
                ))}
              </div>

              <p className='body-small mt-28 color-secondary d-flex gap-4'>Hours of Day</p>
              <div className='d-flex mt-12 align-items-center gap-8'>
                <p className='body-small color-secondary'>from</p>
                <Select
                  className={styles['date-picker-inputs-hours']}
                  value={fromHour}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  options={buildHoursRange(0, toHour || 23)!.map((i) => ({
                    key: i,
                    displayValue: formatToAmPm(i),
                  }))}
                  onClick={setFromHour}
                  placeholder='12:00 AM'
                />
                <p className='body-small color-secondary'>to</p>
                <Select
                  className={styles['date-picker-inputs-hours']}
                  value={toHour}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  options={buildHoursRange((fromHour || 0) + 1, 23)!.map((i) => ({
                    // So user will be able to select date only for 1 hour
                    key: i - 1,
                    displayValue: formatToAmPm(i),
                  }))}
                  onClick={setToHour}
                  placeholder='11:00 PM'
                />
                {(toHour !== undefined || fromHour !== undefined) && (
                  <Button
                    icon='trash'
                    size='small'
                    iconSize='xs'
                    iconColor='secondary'
                    shape='rounded'
                    variant='flat'
                    onClick={() => {
                      setFromHour(undefined);
                      setToHour(undefined);
                    }}
                  />
                )}
              </div>

              {forceStep ? null : (
                <>
                  <p className='body-small mt-28 color-secondary'>Interval</p>
                  <div className='d-flex mt-12 gap-8'>
                    <Button
                      isPressed={internalValue.step === MetricsStep.FIFTEEN_MINUTES}
                      disabled={!availableSteps.includes(MetricsStep.FIFTEEN_MINUTES)}
                      label='15 min'
                      onClick={() => {
                        setStep(MetricsStep.FIFTEEN_MINUTES);
                      }}
                    />
                    <Button
                      isPressed={internalValue.step === MetricsStep.HOUR}
                      disabled={!availableSteps.includes(MetricsStep.HOUR)}
                      label='1 hour'
                      onClick={() => {
                        setStep(MetricsStep.HOUR);
                      }}
                    />
                    <Button
                      isPressed={internalValue.step === MetricsStep.DAY}
                      disabled={!availableSteps.includes(MetricsStep.DAY)}
                      label='1 day'
                      onClick={() => {
                        setStep(MetricsStep.DAY);
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            {/* Quick ranges */}
            <div className='pt-2'>
              <p className='body-small mt-8 color-secondary'>Quick Ranges</p>
              <div className='d-flex mt-12 gap-14 flex-col'>
                {buttonsToDisplay.map((configKey, i) => {
                  const config = buttonsConfig[configKey];
                  if (!config) {
                    return;
                  }
                  return (
                    <Button
                      key={i}
                      label={configKey}
                      onClick={() => presetRangeClick(config.getTimeRange(), configKey)}
                      shape='rect'
                      type='button'
                      variant={configKey === quickRangeType ? 'primary' : 'secondary'}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className={'d-flex gap-24 mt-16 ' + styles['action-buttons']}>
            <Button className={styles['button']} label='Cancel' size='big' variant='flat' onClick={cancelClick} />
            <Button
              className={styles['button']}
              onClick={onSubmit}
              label='Apply'
              size='big'
              variant='primary'
              disabled={isApplyDisabled}
            />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

/**
 * If from and to are not defined, then it means that all hours are selected
 * When all hours are selected, then we don't need to pass hours to the backend
 */
function buildHoursRange(from?: number, to?: number): number[] | undefined {
  const hoursNotDefined = from === undefined && to === undefined;
  const isFullHourRange =
    (from === 0 && to === 24) || (from === undefined && to === 24) || (from === 0 && to === undefined);

  if (hoursNotDefined || isFullHourRange) {
    return undefined;
  }

  return _.range(from ?? 0, (to ?? 24) + 1);
}

/**
 * Extracts boundary hours from the range
 * If boundary hour is 0 or 24, then there is not need to select default value
 */
function extractBoundaryHoursFromRange(hours?: number[]): { from?: number; to?: number } {
  const from = _.first(hours);
  const to = _.last(hours);

  return {
    from: from === 0 ? undefined : from,
    to: to === 24 ? undefined : to,
  };
}

function buildSelectedWeekdays(day: number, weekdays: number[] = WEEKDAY_NUMBERS): number[] {
  // If all days are selected, then we need to deselect all except current one
  if (weekdays.length === 7) {
    return [day];
  }

  // If only one day is selected and it is the same as current one, then we need to select all days
  if (weekdays.length === 1 && weekdays.includes(day)) {
    return WEEKDAY_NUMBERS;
  }

  // Otherwise deselect current day
  if (weekdays.includes(day)) {
    return weekdays.filter((weekday) => weekday !== day);
  }

  // Guarantee that weekdays are sorted and unique
  return _.orderBy(_.uniq([...weekdays, day]));
}

/**
 * Builds new date range based on the current range, limitations and date which user selected
 */
function buildUpdatedDateRange(selectedDate: Date, from?: Date, to?: Date): { from?: Date; to?: Date } {
  const range = addToRange(selectedDate, { from: from, to: to });

  let toDate = range?.to && range?.to > today ? today : range?.to;
  let fromDate = range?.from && range?.from > today ? today : range?.from;
  if (toDate && fromDate && Math.round(differenceInCalendarDays(toDate, fromDate)) >= MAX_DAYS_DIFFERENCE) {
    if (selectedDate === toDate) {
      fromDate = addDays(toDate, -MAX_DAYS_DIFFERENCE);
    } else {
      toDate = undefined;
    }
  }

  return {
    from: fromDate ? roundToLocalDays(fromDate) : undefined,
    to: toDate ? roundToLocalDays(toDate, true) : undefined,
  };
}

export default DateRangePickerWithIntervals;
