import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import OverviewPerSiteLayout from 'src/fragments/layouts/OverviewPerSiteLayout';
import { SystemMetersInfo } from 'src/logic/meter/config';
import { useMetersContextProvider } from 'src/logic/meter/useMetersContext';
import { useSystemMeterType } from 'src/logic/meter/useSystemMeterType';
import { CircularLoader } from 'src/shared/components/Loader/Loader';

export function MeterOverviewPerSiteLayout() {
  const siteId = useNumberParam('siteId');
  const systemType = useSystemMeterType();

  const metersContext = useMetersContextProvider(siteId, systemType);

  const tabNames = useMemo(() => {
    return ['Dashboard', 'Trend', 'Heatmap', 'Activity', 'Calendarized'];
  }, [systemType]);

  return (
    <OverviewPerSiteLayout title={`${SystemMetersInfo[systemType]?.label}`} tabsNames={tabNames}>
      {metersContext.isLoading ? (
        <CircularLoader />
      ) : (
        <metersContext.Provider>
          <Outlet />
        </metersContext.Provider>
      )}
    </OverviewPerSiteLayout>
  );
}
