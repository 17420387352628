import { gql } from '@apollo/client';
import _ from 'lodash';

import { resourcesAPI } from 'src/core/apollo/api';

import { RegionsQuery, RegionsQueryVariables } from './__generated__/getRegions.resources.gql';

export type Region = RegionsQuery['regions'][0];

const GET_REGIONS = gql`
  query Regions($ids: [Int!]) {
    regions(ids: $ids) {
      id
      name
      countryCode
      country
    }
  }
`;

export async function getRegions(ids?: number[]): Promise<Region[]> {
  const result = await resourcesAPI.query<RegionsQuery, RegionsQueryVariables>({
    query: GET_REGIONS,
    variables: {
      ids,
    },
  });

  return _.orderBy(result?.data?.regions ?? [], ['name', 'countryCode', 'country']);
}
