import _ from 'lodash';

import { systemGroupTypeToLabel } from 'src/constants';
import {
  SensorDeviceType,
  OnlineStatus,
  SystemGroupType,
  SystemStatus,
  SystemType,
} from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { SensorTypeToTitle, SupportedColors, OnlineStatusToTitle, SystemTypeToTitle } from 'src/enums';
import { OptionItem } from 'src/shared/components/Select';

export const SystemAlertsForStatus = [OnlineStatus.OFFLINE, OnlineStatus.NOT_CONFIGURED];

export function humaniseStatusLabel(label: string): string {
  if (label === SystemStatus.ON) {
    return 'Online';
  }
  if (label === SystemStatus.OFF) {
    return 'Offline';
  }
  return label;
}

export function getSystemStatusLabel(onlineStatus?: OnlineStatus | null, status?: SystemStatus): string {
  const label =
    onlineStatus && SystemAlertsForStatus.includes(onlineStatus)
      ? OnlineStatusToTitle[onlineStatus]
      : status === SystemStatus.ON
        ? SystemStatus.ON
        : SystemStatus.OFF;
  return label;
}

export function getSystemStatusColorCode(
  onlineStatus?: OnlineStatus | null,
  status?: SystemStatus | null
): SupportedColors {
  const colorCode =
    onlineStatus && SystemAlertsForStatus.includes(onlineStatus)
      ? SupportedColors.Red
      : status === SystemStatus.ON
        ? SupportedColors.Green
        : SupportedColors.Grey;

  return colorCode;
}

export const systemTypeOptions: OptionItem<SystemType>[] = _.orderBy(
  Object.keys(SystemType).map((key) => ({
    key: key as SystemType,
    displayValue: SystemTypeToTitle[key as SystemType],
  })),
  ['displayValue'],
  ['asc']
);

export const groupTypeFilterOptions: OptionItem<SystemGroupType>[] = _.orderBy(
  Object.keys(SystemGroupType).map((key) => ({
    key: key as SystemGroupType,
    displayValue: systemGroupTypeToLabel[key as SystemGroupType],
  })),
  ['displayValue'],
  ['asc']
);

export const groupTypeFilterUndefinedOptions: OptionItem<SystemGroupType | undefined>[] = [
  { key: undefined, displayValue: 'All' },
  ...groupTypeFilterOptions,
];

export const sensorDeviceTypeFilterOptions: OptionItem<SensorDeviceType>[] = _.orderBy(
  Object.keys(SensorDeviceType).map((key) => ({
    key: key as SensorDeviceType,
    displayValue: SensorTypeToTitle[key as SensorDeviceType],
  })),
  'displayValue',
  'asc'
);

export const sensorTypeFilterUndefinedOptions: OptionItem<SensorDeviceType | undefined>[] = [
  { key: undefined, displayValue: 'All Types' },
  ...sensorDeviceTypeFilterOptions,
];
