import classNames from 'classnames';

import { NumberRange } from 'src/interfaces';
import { Icon } from 'src/shared/components/Icon/Icon';
import StandardSlider from 'src/shared/components/StandardSlider/StandardSlider';

import styles from './LightingSlider.module.scss';

interface LightingSliderProps {
  disabled: boolean;
  setpointRange: NumberRange;
  setpoint: number;
  onChange: (v: number) => void;
}

const LightingSlider: React.FC<LightingSliderProps> = (props) => {
  return (
    <>
      <Icon
        icon='light'
        size='s'
        className={classNames('mr-14 pt-4', styles['min-brightness-icon'], { [styles['disabled']]: props.disabled })}
      />
      <StandardSlider
        min={props.setpointRange[0]}
        max={props.setpointRange[1]}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.setpoint}
      />
      <Icon
        icon='light'
        size='s'
        className={classNames('ml-14 pt-4', styles['max-brightness-icon'], { [styles['disabled']]: props.disabled })}
      />
      <p className={classNames('ml-32', styles['label'], { [styles['disabled']]: props.disabled })}>
        <span className='body-small-semi-bold'>{props.setpoint} %</span>
      </p>
    </>
  );
};

export default LightingSlider;
