import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetSitesOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSitesOptionsQuery = {
  sites: Array<{ id: number; name: string; regionId: number; streetNumber?: string | null; streetName: string }>;
};

export type GetRegionsOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetRegionsOptionsQuery = { regions: Array<{ id: number; name: string }> };

export type GetOrganizationsOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetOrganizationsOptionsQuery = { organizations: Array<{ id: number; name: string }> };

export type GetSystemGroupsOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSystemGroupsOptionsQuery = {
  systemGroups: Array<{ id: number; name: string; siteId: number; type: Types.SystemType }>;
};

export type GetSiteSpacesOptionsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
}>;

export type GetSiteSpacesOptionsQuery = { siteSpacesOptions: Array<{ id: number; name: string }> };

export type GetTenantLeaseOptionsQueryVariables = Types.Exact<{
  tenantId: Types.Scalars['Int']['input'];
}>;

export type GetTenantLeaseOptionsQuery = { tenantLeaseOptions: Array<{ id: number; accountNumber: string }> };

export const GetSitesOptionsDocument = gql`
  query GetSitesOptions {
    sites {
      id
      name
      regionId
      streetNumber
      streetName
    }
  }
`;
export const GetRegionsOptionsDocument = gql`
  query GetRegionsOptions {
    regions {
      id
      name
    }
  }
`;
export const GetOrganizationsOptionsDocument = gql`
  query GetOrganizationsOptions {
    organizations {
      id
      name
    }
  }
`;
export const GetSystemGroupsOptionsDocument = gql`
  query GetSystemGroupsOptions {
    systemGroups {
      id
      name
      siteId
      type
    }
  }
`;
export const GetSiteSpacesOptionsDocument = gql`
  query GetSiteSpacesOptions($siteId: Int!) {
    siteSpacesOptions(siteId: $siteId) {
      id
      name
    }
  }
`;
export const GetTenantLeaseOptionsDocument = gql`
  query GetTenantLeaseOptions($tenantId: Int!) {
    tenantLeaseOptions(tenantId: $tenantId) {
      id
      accountNumber
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetSitesOptions(
      variables?: GetSitesOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSitesOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSitesOptionsQuery>(GetSitesOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSitesOptions',
        'query',
        variables
      );
    },
    GetRegionsOptions(
      variables?: GetRegionsOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetRegionsOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetRegionsOptionsQuery>(GetRegionsOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetRegionsOptions',
        'query',
        variables
      );
    },
    GetOrganizationsOptions(
      variables?: GetOrganizationsOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetOrganizationsOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOrganizationsOptionsQuery>(GetOrganizationsOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetOrganizationsOptions',
        'query',
        variables
      );
    },
    GetSystemGroupsOptions(
      variables?: GetSystemGroupsOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSystemGroupsOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSystemGroupsOptionsQuery>(GetSystemGroupsOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSystemGroupsOptions',
        'query',
        variables
      );
    },
    GetSiteSpacesOptions(
      variables: GetSiteSpacesOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSiteSpacesOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSiteSpacesOptionsQuery>(GetSiteSpacesOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSiteSpacesOptions',
        'query',
        variables
      );
    },
    GetTenantLeaseOptions(
      variables: GetTenantLeaseOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTenantLeaseOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTenantLeaseOptionsQuery>(GetTenantLeaseOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetTenantLeaseOptions',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
