import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';

import alertsReducer from 'src/logic/alerts/alertsSlice';
import permissionsReducer from 'src/logic/users/permissionsSlice';

import filtersReducer from '../../modules/filters/filtersSlice';
import iaqReducer from '../../modules/iaq/iaqSlice';
import organizationsReducer from '../../modules/organizations/organizationsSlice';
import schedulerReducer from '../../modules/scheduler/schedulerSlice';
import sitesReducer from '../../modules/sites/sitesSlice';
import systemsReducer from '../../modules/systems/systemsSlice';

import globalReducer from './global/globalSlice';

const store = configureStore({
  reducer: {
    global: globalReducer,
    alerts: alertsReducer,
    filters: filtersReducer,
    sites: sitesReducer,
    systems: systemsReducer,
    iaq: iaqReducer,
    organizations: organizationsReducer,
    scheduler: schedulerReducer,
    permissions: permissionsReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      thunk: true,
      // TODO: TEMPORARY HACK TO STORE DATES IN REDUX
      // See: https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
      immutableCheck: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      serializableCheck: false as any as true,
    });

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { createLogger } = require('redux-logger');

      const reduxLogger = createLogger({
        collapsed: true,
        diff: true,
      });

      return middleware.concat(reduxLogger);
    }

    return middleware;
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<T = void> = ThunkAction<Promise<T>, RootState, undefined, AnyAction>;

export default store;
