import classNames from 'classnames';
import { format, isSameMonth } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { DayModifiers, DayPicker } from 'react-day-picker';

import { datetimeToLocalDate } from 'src/cdk/utils/datetimeToDate';
import { DATE_FORMAT } from 'src/constants';

import { Icon } from '../../Icon/Icon';
import Dropdown from '../../Popup/Dropdown';
import { PopupActions } from '../../Popup/interface';
import { baseDatePickerClassNames, baseDatePickerComponents } from '../base/base-date-picker';
import { useDisabledDays } from '../base/useDisabledDays';

import styles from './DatePicker.module.scss';

const EARLIEST_AVAILABLE_DATE = new Date(2020, 0);

const today = new Date();

interface DatePickerProps {
  value: Date | undefined;
  onChange: (days: Date | undefined) => void;
  className?: string;
  from?: Date;
  to?: Date;
  disabledDays?: {
    from?: Date | undefined;
    to?: Date | undefined;
  }[];
  hasError?: boolean;
  disabled?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  className,
  from,
  to,
  disabledDays,
  hasError,
  disabled,
}) => {
  const popupRef = useRef<PopupActions>(null);
  const [touched, setTouched] = useState(false);
  const [month, setMonth] = useState<Date>(value || from || today);

  useEffect(() => {
    // So when value is not selected yet, but min date was changed and
    // user did not moved to another month - set month to min date
    if (!value && from && isSameMonth(today, month)) {
      setMonth(from);
    } else if (value && !isSameMonth(value, month)) {
      setMonth(value);
    }
  }, [value, from]);

  const selectedDate = value ? format(value, DATE_FORMAT.DATE_SHORT) : '';

  function handleDayClick(datetime: Date, { selected }: DayModifiers) {
    const day = datetimeToLocalDate(datetime);
    const newValue = selected ? undefined : day;
    if (!_.isEqual(newValue, value)) {
      onChange(newValue);
      popupRef.current?.close();
    }
  }

  const disabledDaysModifiers = useDisabledDays(from, to, disabledDays);

  return (
    <div className={classNames(styles['date-picker-wrapper'], className)}>
      <Dropdown
        rootId='dateRangePickerWithInterval'
        forwardedRef={popupRef}
        alwaysUnderTrigger
        onHide={() => {
          setTouched(true);
        }}
        triggerComponent={(isOpen) => (
          <button
            className={classNames('input', 'with-pointer', styles['date-picker-btn'], {
              ['touched']: touched,
              ['has-error']: hasError,
            })}
            type='button'
            title={selectedDate}
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              popupRef?.current?.toggle();
            }}
          >
            {selectedDate && <span className={classNames('color-primary', 'one-line-ellipsis')}>{selectedDate}</span>}
            {!selectedDate && <span className={classNames('color-tertiary', 'body')}>Select...</span>}{' '}
            <Icon icon={isOpen ? 'dropdown-up' : 'dropdown-down'} size='s' className={styles['dropdown-down-icon']} />
          </button>
        )}
      >
        <div className={classNames('card el-04', styles['date-picker-body'])}>
          <DayPicker
            month={month}
            mode='single'
            showOutsideDays
            fromMonth={EARLIEST_AVAILABLE_DATE}
            numberOfMonths={1}
            selected={value}
            onDayClick={handleDayClick}
            disabled={disabledDaysModifiers}
            onMonthChange={setMonth}
            classNames={baseDatePickerClassNames}
            components={baseDatePickerComponents}
          />
        </div>
      </Dropdown>
    </div>
  );
};
