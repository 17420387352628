import _ from 'lodash';

import { N_A } from 'src/constants';

/**
 * Represent list of items to display
 */
export type ContentList = string[];

/**
 * Represent group of items to display
 */
export interface GroupedContentList {
  /**
   * Items will be grouped under this name
   */
  name: string;
  items: ContentList;
}

/**
 * Data can be one of 2 types - list of items or list of groups
 * UI will display items or groups depending on data type
 */
export type Content = GroupedContentList[] | ContentList | null | undefined;

/**
 * Component will display items list or groups of items lists.
 * Usually used as tooltip content.
 */
export const GroupedList: React.FC<{
  content: Content;
}> = ({ content }) => {
  if (!content) {
    return <p className='color-secondary text-center'>{N_A}</p>;
  }

  // Render as simple list of items
  if (isItemsList(content)) {
    return content.map((item) => (
      <p className='mt-2' key={item}>
        {item}
      </p>
    ));
  }

  // Render as groups of lists
  return content.map((group, index) => (
    <div key={group.name} className={index === 0 ? '' : 'mt-8 '}>
      <p className='mb-2 color-secondary'>{group.name}</p>
      {group.items.map((item) => (
        <p className='mt-2' key={item}>
          {item}
        </p>
      ))}
    </div>
  ));
};

function isItemsList(data: Content): data is ContentList {
  return !data?.some(_.isObject);
}
