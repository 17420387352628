import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { FILE_DOWNLOAD_URL } from 'src/constants';
import authService from 'src/core/service/authService';
import { toastService } from 'src/core/service/toastService';

// TODO: change integration with API
export function useUrlToProtectedFile(path?: string | null) {
  const { isFailed, isLoading, response } = useDataFetchOnMountWithDeps(
    getUrlToProtectedFile.bind(null, path),
    [path],
    true
  );

  return { isFailed, isLoading, file: response };
}

export async function getUrlToProtectedFile(path?: string | null) {
  if (!path) {
    return null;
  }
  const exportMsEndpoint = `${FILE_DOWNLOAD_URL}${path}`;
  const authToken = await authService.getJWT();

  if (!authToken) {
    console.error('File Error: No auth token');
    return null;
  }

  const response = await fetch(exportMsEndpoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  if (response.ok) {
    const blob = await response.blob();
    if (blob.size === 0) {
      console.error(`File [ ${exportMsEndpoint} ] is empty or corrupted`);
      return null;
    }
    const url = URL.createObjectURL(blob);
    return {
      url,
      type: blob.type,
      name: response.headers.get('Content-Disposition')?.split('"')?.[1] ?? null,
    };
  } else {
    if (response.status === 404) {
      console.error(`File [ ${exportMsEndpoint} ] not found`);
      return null;
    }
    if (response.status === 401) {
      toastService.error(`You do not have access to this file`);
      return null;
    }
  }

  throw new Error('Failed to download file, please contact administrator');
}
