import { isNil } from 'lodash';

import { moneyWithCommasFormat, numberWithCommasFormat } from 'src/cdk/formatter/numberFormatter';
import { mapUnitToPrecision } from 'src/cdk/mappers/mapUnitToPrecision';
import { N_A, UNIT } from 'src/constants';

import { Icon } from '../Icon/Icon';

import { RenderUnit } from './RenderUnit';

export { UNIT } from 'src/constants';

type UnitNames = Lowercase<keyof typeof UNIT>;
type UnitAsProp = {
  [key in UnitNames]?: boolean;
};

/**
 * @property asBigNumber - if true, will use numberWithCommasFormat
 * @property dimmedUnit - if true, will use className='body-small color-secondary' on unit
 */
const ValueWithUnit: React.FC<{ value?: number | null; unit?: UNIT; asBigNumber?: boolean; dimmedUnit?: boolean }> = ({
  value,
  unit,
  asBigNumber = false,
  dimmedUnit = false,
}) => {
  if (isNil(value)) {
    return <>{N_A}</>;
  }

  const precision = unit ? mapUnitToPrecision(unit) : undefined;

  const renderValue = asBigNumber ? numberWithCommasFormat(value) : precision ? value.toFixed(precision) : value;

  if (unit === UNIT.DEGREES) {
    return (
      <>
        <Icon
          icon='arrow-top'
          size='xs'
          style={{
            transform: `rotate(${value}deg)`,
            transformOrigin: 'center',
            display: 'inline-block',
          }}
        />{' '}
        {renderValue} <RenderUnit unit={UNIT.DEGREES} dimmed={dimmedUnit} />
      </>
    );
  }

  if (
    unit === UNIT.MILES_PER_HOUR ||
    unit === UNIT.METERS_PER_SECOND ||
    unit === UNIT.HECTO_PASCAL ||
    unit === UNIT.OF_HUNDRED ||
    unit === UNIT.FAHRENHEIT ||
    unit === UNIT.PSI ||
    unit === UNIT.PERCENTAGE ||
    unit === UNIT.PPM ||
    unit === UNIT.MICROGRAM_M3 ||
    unit === UNIT.MINUTES ||
    unit === UNIT.SQUARE_FEET ||
    unit === UNIT.MILLIMETER
  ) {
    return (
      <>
        {renderValue} <RenderUnit unit={unit} dimmed={dimmedUnit} />
      </>
    );
  }

  if (unit === UNIT.DOLLARS) {
    return <>{moneyWithCommasFormat(Number(renderValue))}</>;
  }

  return <>{renderValue}</>;
};

/**
 * Specify unit as property, and component will define how to render it
 *
 * @usage
 * <WithUnit value={value} psi />
 *
 * @property asBigNumber - if true, will use numberWithCommasFormat
 * @property dimmedUnit - if true, will use className='body-small color-secondary' on unit
 */
export const WithUnit: React.FC<
  UnitAsProp & { value?: number | null; unit?: UNIT; asBigNumber?: boolean; dimmedUnit?: boolean }
> = ({
  value,
  unit,
  fahrenheit,
  psi,
  percentage,
  ppm,
  microgram_m3,
  minutes,
  square_feet,
  of_hundred,
  degrees,
  hecto_pascal,
  meters_per_second,
  millimeter,
  miles_per_hour,
  asBigNumber = false,
  dimmedUnit = false,
}) => {
  if (!unit) {
    if (fahrenheit) {
      unit = UNIT.FAHRENHEIT;
    } else if (psi) {
      unit = UNIT.PSI;
    } else if (percentage) {
      unit = UNIT.PERCENTAGE;
    } else if (ppm) {
      unit = UNIT.PPM;
    } else if (microgram_m3) {
      unit = UNIT.MICROGRAM_M3;
    } else if (minutes) {
      unit = UNIT.MINUTES;
    } else if (square_feet) {
      unit = UNIT.SQUARE_FEET;
    } else if (of_hundred) {
      unit = UNIT.OF_HUNDRED;
    } else if (degrees) {
      unit = UNIT.DEGREES;
    } else if (hecto_pascal) {
      unit = UNIT.HECTO_PASCAL;
    } else if (meters_per_second) {
      unit = UNIT.METERS_PER_SECOND;
    } else if (miles_per_hour) {
      unit = UNIT.MILES_PER_HOUR;
    } else if (millimeter) {
      unit = UNIT.MILLIMETER;
    }
  }
  return <ValueWithUnit value={value} unit={unit} asBigNumber={asBigNumber} dimmedUnit={dimmedUnit} />;
};
