import _ from 'lodash';

import { toAtfDate } from 'src/cdk/formatter/relativeTimeFormat';
import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { N_A } from 'src/constants';
import { LockType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import {
  SystemSequence,
  packageFanDisplayValue,
  packageModeDisplayValue,
  packageSpaceDisplayValue,
  sequenceDisplayValue,
} from 'src/enums';
import { AnySystem } from 'src/modules/systems/interface';

import api from './gql';
import { GetNextScheduleConfigurationQuery } from './gql/__generated__/schedule.resources.generated';

export type SettingsItem = {
  key?: string;
  label: string;
  current?: string;
  next: string;
};

type NextSettings = NonNullable<
  Required<GetNextScheduleConfigurationQuery>['getUpcomingSchedulerConfig']
>['config']['settings'];

export function useNextSchedule(systemId: number, system?: AnySystem, timezone?: string | null) {
  const {
    isLoading,
    isFailed,
    refetch,
    response: nextSchedule,
  } = useDataFetchOnMountWithDeps(
    () => api.GetNextScheduleConfiguration({ systemId }).then((res) => res.getUpcomingSchedulerConfig),
    [systemId],
    true
  );

  const changeAt = nextSchedule?.changeAt ? toAtfDate(nextSchedule?.changeAt, timezone) : N_A;
  const nextScheduleName = nextSchedule?.name;
  const settingsDiff = humanizeSettings(
    {
      current: system?.sequence.id,
      next: nextSchedule?.config.sequenceId,
    },
    systemSettings(system),
    nextSchedule?.config.settings
  );
  const currentScheduleName = system?.schedulerProfile?.name;

  return {
    canOverride: Boolean(system?.schedulerProfile?.underSchedulerControl),
    isOverridden: Boolean(system?.overriddenSchedulerProfile),
    isLoading,
    isFailed,
    changeAt,
    nextScheduleName,
    currentScheduleName,
    settingsDiff,
    refresh: refetch,
  };
}

function systemSettings(system?: AnySystem): NextSettings {
  // TODO: change/review
  return {
    fan: _.get(system, 'fan'),
    mode: _.get(system, 'packageSystemMode'),
    setLock: _.get(system, 'setLock'),
    space: _.get(system, 'space'),
    systemState: _.get(system, 'status'),
    setpoint: _.get(system, 'setpoint'),
    setpointMin: _.get(system, 'setpointMin'),
    setpointMax: _.get(system, 'setpointMax'),
    setpointCool: _.get(system, 'setpointCool'),
    setpointHeat: _.get(system, 'setpointHeat'),
    unoccupiedSetpointCool: _.get(system, 'unoccupiedSetpointCool'),
    unoccupiedSetpointHeat: _.get(system, 'unoccupiedSetpointHeat'),
    occupiedSetpointCool: _.get(system, 'occupiedSetpointCool'),
    occupiedSetpointHeat: _.get(system, 'occupiedSetpointHeat'),
    outsideTempMin: undefined,
    outsideTempMax: undefined,
  };
}

function humanizeSettings(
  sequence: {
    current?: SystemSequence;
    next?: SystemSequence;
  },
  current: NextSettings,
  next?: NextSettings | null
): SettingsItem[] {
  // TODO: replace tihs implementation with usage of humanizeScheduleSettings & _zip

  const result: SettingsItem[] = [];

  if (sequence.next && sequence.current !== sequence.next) {
    result.push({
      label: 'Sequence',
      current: _.get(sequenceDisplayValue, sequence.current || -1)?.toString(),
      next: sequenceDisplayValue[sequence.next],
    });
  }

  if (!next) {
    return result;
  }

  if (next?.fan && next?.fan !== current?.fan) {
    result.push({
      label: 'Fan',
      current: current?.fan ? packageFanDisplayValue[current?.fan] : undefined,
      next: packageFanDisplayValue[next.fan],
    });
  }

  if (next?.mode && next?.mode !== current?.mode) {
    result.push({
      label: 'Mode',
      current: current?.mode ? packageModeDisplayValue[current?.mode] : undefined,
      next: packageModeDisplayValue[next.mode],
    });
  }

  if (next?.setLock && next?.setLock !== current?.setLock) {
    result.push({
      label: 'Local Control',
      current: current?.setLock ? (current?.setLock === LockType.LOCKED ? 'Locked' : 'Unlocked') : undefined,
      next: next.setLock === LockType.LOCKED ? 'Locked' : 'Unlocked',
    });
  }

  if (next?.space && next?.space !== current?.space) {
    result.push({
      label: 'Occupation',
      current: current?.space ? packageSpaceDisplayValue[current.space] : undefined,
      next: packageSpaceDisplayValue[next.space],
    });
  }

  if (next?.systemState && next?.systemState !== current?.systemState) {
    result.push({
      label: 'System',
      current: current?.systemState?.toString() || N_A,
      next: next.systemState?.toString() || N_A,
    });
  }

  if (next?.setpoint && next?.setpoint !== current?.setpoint) {
    result.push({
      label: 'Setpoint',
      current: current?.setpoint?.toString(),
      next: next.setpoint?.toString(),
    }); // TODO: add unit}s?
  }

  if (
    (next?.setpointMin || next?.setpointMax) &&
    (next?.setpointMin !== current?.setpointMin || next?.setpointMax !== current?.setpointMax)
  ) {
    result.push({
      key: 'setpoint-range',
      label: 'Setpoint Limits',
      current:
        current?.setpointMin || current?.setpointMax ? `${current?.setpointMin} - ${current?.setpointMax}` : undefined,
      next: `${next.setpointMin} - ${next.setpointMax}`,
    }); // TODO: add unit}s?
  }

  if (
    (next.setpointCool || next.setpointHeat) &&
    (next.setpointCool !== current?.setpointCool || next.setpointHeat !== current?.setpointHeat)
  ) {
    result.push({
      label: 'Setpoint (Cool, Heat)',
      current:
        current?.setpointCool || current?.setpointHeat
          ? `(${current?.setpointCool}, ${current?.setpointHeat})`
          : undefined,
      next: `(${next.setpointCool}, ${next.setpointHeat})`,
    }); // TODO: add unit}s?
  }

  if (next?.unoccupiedSetpointCool && next?.unoccupiedSetpointCool !== current?.unoccupiedSetpointCool) {
    result.push({
      label: 'Unoccupied Setpoint Cool',
      current: current?.unoccupiedSetpointCool?.toString(),
      next: next.unoccupiedSetpointCool?.toString(),
    }); // TODO: add unit}s?
  }

  if (next?.unoccupiedSetpointHeat && next?.unoccupiedSetpointHeat !== current?.unoccupiedSetpointHeat) {
    result.push({
      label: 'Unoccupied Setpoint Heat',
      current: current?.unoccupiedSetpointHeat?.toString(),
      next: next.unoccupiedSetpointHeat?.toString(),
    }); // TODO: add unit}s?
  }

  if (next?.occupiedSetpointCool && next?.occupiedSetpointCool !== current?.occupiedSetpointCool) {
    result.push({
      label: 'Occupied Setpoint Cool',
      current: current?.occupiedSetpointCool?.toString(),
      next: next.occupiedSetpointCool?.toString(),
    }); // TODO: add unit}s?
  }

  if (next?.occupiedSetpointHeat && next?.occupiedSetpointHeat !== current?.occupiedSetpointHeat) {
    result.push({
      label: 'Occupied Setpoint Heat',
      current: current?.occupiedSetpointHeat?.toString(),
      next: next.occupiedSetpointHeat?.toString(),
    }); // TODO: add unit}s?
  }

  if (
    (next?.outsideTempMin || next?.outsideTempMax) &&
    (next?.outsideTempMin !== current?.outsideTempMin || next?.outsideTempMax !== current?.outsideTempMax)
  ) {
    result.push({
      label: `OAT`,
      current:
        current?.outsideTempMin || current?.outsideTempMax
          ? `${current?.outsideTempMin} - ${current?.outsideTempMax}`
          : undefined,
      next: `${next.outsideTempMin} - ${next.outsideTempMax}`,
    }); // TODO: add unit}s?
  }

  return result;
}
