import _ from 'lodash';

import { refetchUserConstraints } from 'src/core/store/global/globalActions';
import { selectAvailableSites } from 'src/core/store/global/globalSlice';
import { AppThunk } from 'src/core/store/store';
import { ISite } from 'src/interfaces';

import { getRegions, Region } from './gql/getRegions.resources.gql';
import { getSelectedSitesForHeaderRow } from './gql/getSelectedSitesForHeaderRow.resources.gql';
import { selectSiteIdsFromSliceOrStorage, setSites, updateSites } from './sitesSlice';

export type SiteRegionData = {
  sites: ISite[];
  regions: Region[];
};

export const fetchSelectedSites = (): AppThunk => async (dispatch, getState) => {
  const siteIds = selectSiteIdsFromSliceOrStorage(getState());
  const allSiteIds = selectAvailableSites(getState());

  let selectedSites: ISite[] = [];

  if (siteIds.length) {
    selectedSites = await getSelectedSitesForHeaderRow(siteIds);
  }

  const sitesToSet = selectedSites.length ? selectedSites : await getSelectedSitesForHeaderRow(allSiteIds);
  dispatch(setSites(sitesToSet));
};

export const fetchAllAvailableSitesAndRegions: AppThunk<SiteRegionData> = async (__, getState) => {
  const siteIds = selectAvailableSites(getState());
  const siteRegionData: SiteRegionData = { sites: [], regions: [] };
  if (siteIds.length) {
    const sites = await getSelectedSitesForHeaderRow(siteIds);
    if (sites?.length) {
      const regions = await getRegions();
      if (regions?.length) {
        siteRegionData.sites = _.orderBy(sites, 'order', 'asc');
        siteRegionData.regions = regions;
      }
    }
  }

  return siteRegionData;
};

export const refetchSitesAndUserData: AppThunk = async (dispatch) => {
  // In most cases update to site leads to change of user constraints
  await dispatch(refetchUserConstraints());
  const siteRegionData = await dispatch(fetchAllAvailableSitesAndRegions);
  dispatch(updateSites(siteRegionData.sites));
};
