import _ from 'lodash';

import { SystemTypesWithGroups } from 'src/constants';
import {
  ChargePointStationModeType,
  FanType,
  ModeType,
  OverrideSchedulerProfileInput,
  SpaceType,
  SystemPackageAltc24Prog,
  SystemStatus,
  SystemType,
} from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import Logger from 'src/core/service/logger';
import { AppThunk } from 'src/core/store/store';
import { SystemSequence } from 'src/enums';
import api from 'src/logic/systems/gql';

import { selectSiteIdsFromSliceOrStorage } from '../sites/sitesSlice';

import { changeLightingSystemMode } from './gql/changeLightingSystemMode.resources.gql';
import { changeOatResetRanges } from './gql/changeOatResetRanges.resources.gql';
import { changePackageSystemFan } from './gql/changePackageSystemFan.resources.gql';
import { changePackageSystemMode } from './gql/changePackageSystemMode.resources.gql';
import { changePackageSystemSetpoint } from './gql/changePackageSystemSetpoint.resources.gql';
import { changePackageSystemSettings } from './gql/changePackageSystemSettings.resources.gql';
import { changeSmartOutletSystemMode } from './gql/changeSmartOutletSystemMode.resources.gql';
import { changeSystemSequence } from './gql/changeSystemSequence.resources.gql';
import { changeSystemSetpoint } from './gql/changeSystemSetpoint.resources.gql';
import { deleteOverriddenSchedulerProfile } from './gql/deleteOverriddenSchedulerProfile.resources.gql';
import {
  getSiteDetailsForSystemPage,
  getSystemGroup,
  getSystemPageDetails,
  SiteDetails,
} from './gql/getSystemPageDetails.resources.gql';
import {
  ChargeStationSystemCard,
  getSystemGroupsForSite,
  getSystemsForSite,
  SystemGroups,
} from './gql/getSystemsForSite.resources.gql';
import { overrideDRSequence } from './gql/overrideDRSequence.resources.gql';
import { overrideSchedulerProfile } from './gql/overrideSchedulerProfile.resources.gql';
import { updatePackageSystemResetFilter } from './gql/updatePackageSystemResetFilter.resources.gql';
import { AnySystem, AnySystemSpecificFields, IOatResetRanges, IPackagedSystemSettings } from './interface';
import {
  selectedSystemById,
  setSingleSystem,
  setSingleSystemGroup,
  setSystemGroups,
  setSystems,
  setSystemType,
  updateSystem,
  updateSystemSequence,
} from './systemsSlice';

export const fetchSystemsForSelectedSites =
  (siteId?: number): AppThunk =>
  async (dispatch, getState) => {
    const root = getState();
    const selectedSiteIds = siteId ? [siteId] : selectSiteIdsFromSliceOrStorage(root);
    const systemType = root.systems.systemType;

    // Send multiple requests for each site in parallel
    const results: PromiseSettledResult<AnySystem[]>[] = await Promise.allSettled(
      selectedSiteIds.map((siteId) => getSystemsForSite(siteId, systemType))
    );
    const systems: AnySystem[] = _.flatMap(_.filter(results, { status: 'fulfilled' }), 'value');

    dispatch(setSystems(systems));

    if (_.intersection(systemType, SystemTypesWithGroups).length) {
      const systemGroupsResults: PromiseSettledResult<SystemGroups[]>[] = await Promise.allSettled(
        selectedSiteIds.map((siteId) => getSystemGroupsForSite([siteId], systemType))
      );

      const systemGroups: SystemGroups[] = _.flatMap(_.filter(systemGroupsResults, { status: 'fulfilled' }), 'value');
      dispatch(setSystemGroups(systemGroups));
    }
  };

export const refreshCurrentSystems = (): AppThunk => async (dispatch) => {
  dispatch(fetchSystemsForSelectedSites());
};

export const refetchSingleSystem =
  (systemId: number): AppThunk =>
  async (dispatch) => {
    const systemDetails = await getSystemPageDetails(systemId);
    dispatch(setSingleSystem(systemDetails));
  };

export const fetchSingleSystemIfNotExist =
  (systemId: number): AppThunk<SiteDetails> =>
  async (dispatch, getState) => {
    const root = getState();
    const system = root.systems.systemsMap[systemId];
    let siteId = system?.siteId;

    if (!system) {
      const systemDetails = await getSystemPageDetails(systemId);
      siteId = systemDetails.siteId;
      dispatch(setSingleSystem(systemDetails));
    }

    const siteInfo = getSiteDetailsForSystemPage(siteId);

    return siteInfo;
  };

export const fetchSystemsForSystemGroupIfNotExist =
  (systemGroupId: number): AppThunk =>
  async (dispatch, getState) => {
    const systemGroup = getState().systems.systemGroupsMap[systemGroupId];
    if (!systemGroup) {
      const systemGroup = await getSystemGroup(systemGroupId);
      const systems = await getSystemsForSite(systemGroup.siteId, [systemGroup.type]);
      dispatch(setSystemType([systemGroup.type]));
      dispatch(setSingleSystemGroup(systemGroup));
      dispatch(setSystems(systems));
    } else {
      dispatch(setSystemType([systemGroup.type]));
    }
  };

async function changeSystem(
  systemId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeRemote: () => Promise<any>,
  updateSystemDispatch: (systemSettings: AnySystemSpecificFields) => void,
  successMessage: string,
  errorMessage: string,
  doesMutationReturnData = true,
  updateSystemOnFailure = true
): Promise<boolean> {
  let systemSettings = null;
  let result = true;
  try {
    systemSettings = await changeRemote();
  } catch (error) {
    Logger.error(errorMessage);
    result = false;
  } finally {
    if (systemSettings) {
      doesMutationReturnData && updateSystemDispatch(systemSettings);
      Logger.success(successMessage);
    }
    if (updateSystemOnFailure && (!systemSettings || !doesMutationReturnData)) {
      const systemDetails = await getSystemPageDetails(systemId);
      updateSystemDispatch(systemDetails);
    }

    return result;
  }
}

export const changeSequenceForSingleSystem =
  (systemId: number, sequence: SystemSequence): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    dispatch(updateSystemSequence([systemId, sequence]));
    await changeSystem(
      systemId,
      () => changeSystemSequence(systemId, sequence),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} sequence`,
      `Failed to update ${name} sequence`
    );
  };

export const changeSetpointForSingleSystem =
  (systemId: number, setpoint: number): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    await changeSystem(
      systemId,
      () => changeSystemSetpoint(systemId, setpoint),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} setpoint`,
      `Failed to update ${name} setpoint`
    );
  };

export const changeLightingSystemModeForSingleSystem =
  (systemId: number, mode: SystemStatus): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    const payload: Partial<AnySystemSpecificFields> = {
      lightingState: mode,
    };
    dispatch(updateSystem([systemId, payload as AnySystemSpecificFields]));
    await changeSystem(
      systemId,
      () => changeLightingSystemMode(systemId, mode),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} mode`,
      `Failed to update ${name} mode`
    );
  };

export const changeOutletSystemModeForSingleSystem =
  (systemId: number, mode: SystemStatus): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    const payload: Partial<AnySystemSpecificFields> = {
      outletState: mode,
    };
    dispatch(updateSystem([systemId, payload as AnySystemSpecificFields]));
    await changeSystem(
      systemId,
      () => changeSmartOutletSystemMode(systemId, mode),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} mode`,
      `Failed to update ${name} mode`
    );
  };

export const changePackageSetpointForSingleSystem =
  (systemId: number, setpoint: number): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    await changeSystem(
      systemId,
      () => changePackageSystemSetpoint(systemId, setpoint),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} setpoint`,
      `Failed to update ${name} setpoint`
    );
  };

export const packageSystemResetFilter =
  (systemId: number): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    await changeSystem(
      systemId,
      () => updatePackageSystemResetFilter(systemId),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully reset ${name} filter`,
      `Failed to reset ${name} filter`
    );
  };

export const changePackageModeForSingleSystem =
  (systemId: number, packageSystemMode: ModeType): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    const result = await changeSystem(
      systemId,
      () => changePackageSystemMode(systemId, packageSystemMode),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} mode`,
      `Failed to update ${name} mode`
    );
    if (result) {
      const payload: Partial<SystemPackageAltc24Prog> = {
        status: packageSystemMode === ModeType.OFF ? SystemStatus.OFF : SystemStatus.ON,
        packageSystemModeEffective: packageSystemMode,
      };
      if (packageSystemMode !== ModeType.OFF) {
        payload.packageSystemMode = packageSystemMode;
      }
      dispatch(updateSystem([systemId, payload as AnySystemSpecificFields]));
    }
  };

export const changePackageFanForSingleSystem =
  (systemId: number, fan: FanType): AppThunk =>
  async (dispatch, getState) => {
    const system = selectedSystemById(systemId)(getState());
    const result = await changeSystem(
      systemId,
      () => changePackageSystemFan(systemId, fan),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${system?.name} fan`,
      `Failed to update ${system?.name} fan`
    );
    if (result) {
      const payload: Partial<AnySystemSpecificFields> = {
        fan,
        fanEffective: system?.type === SystemType.PACKAGE_HONEYWELL_TC500AN && fan !== FanType.OFF ? FanType.ON : fan,
      };
      dispatch(updateSystem([systemId, payload as AnySystemSpecificFields]));
    }
  };

export const changeOatResetRangeForSingleSystem =
  (systemId: number, oatResetRange: IOatResetRanges): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    await changeSystem(
      systemId,
      () => changeOatResetRanges(systemId, oatResetRange),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} OAT range settings`,
      `Failed to update ${name} OAT range settings`,
      true,
      false
    );
  };

export const changePackagedSystemSettingsForSingleSystem =
  (systemId: number, packagedSystemSettings: IPackagedSystemSettings): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    const parameter = _.isFinite(packagedSystemSettings.setpointCool)
      ? 'setpoint cool'
      : _.isFinite(packagedSystemSettings.setpointHeat)
        ? 'setpoint heat'
        : packagedSystemSettings.space
          ? 'occupancy'
          : 'settings';
    const result = await changeSystem(
      systemId,
      () => changePackageSystemSettings(systemId, packagedSystemSettings),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${name} ${parameter}`,
      `Failed to update ${name} ${parameter}`
    );
    if (result && packagedSystemSettings.space) {
      const payload: Partial<SystemPackageAltc24Prog> = {
        space: packagedSystemSettings.space,
      };
      if (packagedSystemSettings.space !== SpaceType.NO_OVERRIDE) {
        payload.spaceEffective = packagedSystemSettings.space;
      }
      dispatch(updateSystem([systemId, payload as AnySystemSpecificFields]));
    }
  };

export const changeChargePointSystemSettingsForSinglePort =
  (systemId: number, portId: number, maxLoad: number): AppThunk =>
  async (dispatch, getState) => {
    const system = selectedSystemById<ChargeStationSystemCard>(systemId)(getState()) || ({} as ChargeStationSystemCard);

    if (system.type !== SystemType.CHARGE_POINT) {
      Logger.error('It is not a charge point system');
      return;
    }

    if (system.station.mode === ChargePointStationModeType.PORTS_MAX) {
      const portNumber = system.ports.find((port) => port.id === portId)?.portNumber || '';

      await changeSystem(
        systemId,
        () => changeCPPortMaxLoad(system, portId, maxLoad),
        (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
        `Successfully updated ${portNumber} ${system.name} max load`,
        `Failed to update ${portNumber} ${system.name} max load`
      );
    } else {
      Logger.warn('Station is not under Ports mode');
    }
  };

export const changeChargePointSystemSettingsForStation =
  (systemId: number, maxLoad: number): AppThunk =>
  async (dispatch, getState) => {
    const system = selectedSystemById<ChargeStationSystemCard>(systemId)(getState()) || ({} as ChargeStationSystemCard);

    if (system.type !== SystemType.CHARGE_POINT) {
      Logger.error('It is not a charge point system');
      return;
    }

    if (system.station.mode === ChargePointStationModeType.STATION_MAX) {
      await changeSystem(
        systemId,
        () => changeCPStationMaxLoad(system, maxLoad),
        (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
        `Successfully updated ${system.name} max load`,
        `Failed to update ${system.name} max load`
      );
    } else {
      Logger.warn('Station is not under Stations mode');
    }
  };

export const changeChargePointSystemMode =
  (systemId: number, mode: ChargePointStationModeType): AppThunk =>
  async (dispatch, getState) => {
    const system = selectedSystemById<ChargeStationSystemCard>(systemId)(getState()) || ({} as ChargeStationSystemCard);

    if (system.type !== SystemType.CHARGE_POINT) {
      Logger.error('It is not a charge point system');
      return;
    }

    await changeSystem(
      systemId,
      () => changeCPStationMode(system, mode),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully updated ${system.name} mode`,
      `Failed to update ${system.name} mode`
    );
  };

async function changeCPPortMaxLoad(
  system: ChargeStationSystemCard,
  portId: number,
  allowedLoad: number
): Promise<AnySystemSpecificFields | undefined> {
  const res = await api.UpdateChargePointSettings({
    systemId: system.id,
    updateStationSettings: {
      portsLoad: system.ports.map((port) => ({
        portId: port.id,
        allowedLoad: port.id === portId ? allowedLoad : port.allowedLoad,
      })),
    },
  });

  if (!res.updateChargePointSystemSettings) {
    return;
  }

  return {
    ports: system.ports.map((port) => ({
      ...port,
      allowedLoad: port.id === portId ? allowedLoad : port.allowedLoad,
    })),
  } as AnySystemSpecificFields;
}

async function changeCPStationMaxLoad(
  system: ChargeStationSystemCard,
  allowedLoad: number
): Promise<AnySystemSpecificFields | undefined> {
  const res = await api.UpdateChargePointSettings({
    systemId: system.id,
    updateStationSettings: {
      stationAllowedLoad: allowedLoad,
    },
  });

  if (!res.updateChargePointSystemSettings) {
    return;
  }

  return {
    station: {
      ...system.station,
      allowedLoad: allowedLoad,
    },
  } as AnySystemSpecificFields;
}

async function changeCPStationMode(
  system: ChargeStationSystemCard,
  mode: ChargePointStationModeType
): Promise<AnySystemSpecificFields | undefined> {
  const res = await api.UpdateChargePointMode({
    systemId: system.id,
    mode,
  });

  if (!res.updateChargePointSystemMode) {
    return;
  }

  return {
    station: {
      ...system.station,
      mode,
    },
  } as AnySystemSpecificFields;
}

export const overrideSchedulerProfileForSingleSystem =
  (input: OverrideSchedulerProfileInput): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(input.systemId)(getState())?.name;
    await changeSystem(
      input.systemId,
      () => overrideSchedulerProfile(input),
      (systemSettings) => dispatch(updateSystem([input.systemId, systemSettings])),
      `Successfully overridden schedule profile for ${name} system`,
      `Failed to override schedule profile for ${name}. Check logs please`,
      false
    );
  };

export const deleteOverriddenSchedulerProfileForSingleSystem =
  (systemId: number): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    await changeSystem(
      systemId,
      () => deleteOverriddenSchedulerProfile(systemId),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully deleted overridden schedule profile for ${name} system`,
      `Failed to delete override schedule profile for ${name}. Check logs please`,
      false
    );
  };

export const overrideDRSequenceForSingleSystem =
  (systemId: number): AppThunk =>
  async (dispatch, getState) => {
    const name = selectedSystemById(systemId)(getState())?.name;
    await changeSystem(
      systemId,
      () => overrideDRSequence(systemId),
      (systemSettings) => dispatch(updateSystem([systemId, systemSettings])),
      `Successfully overridden curtailment sequence for ${name} system`,
      `Failed to override curtailment sequence for ${name}. Check logs please`,
      false
    );
  };
