import _ from 'lodash';

import { N_A, systemGroupTypeToLabel } from 'src/constants';
import { SiteFeatureType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { SiteFeaturesPermissionsInput, User, UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import { SITE_FEATURE_LABEL } from 'src/core/enum-labels';
import { SystemTypeToTitle } from 'src/enums';
import { RegionTree } from 'src/logic/users/gql';

import { SystemGroups } from '../../modules/systems/gql/getSystemsForSite.resources.gql';

type NonNullableFields<T> = Required<{
  [P in keyof T]: NonNullable<T[P]>;
}>;

type Permissions = Pick<User, 'regions' | 'sites' | 'systems' | 'siteFeatures'>;

export type NodePath = {
  regionId: number;
  siteId?: number;
  item?: { id: number | string; type: 'system' | 'siteFeature' | 'energySite' };
};

export interface Node<T extends number | string> {
  id: T;
  selected: boolean;
  /**
   * Means that access is provided by default, for example because user is an Admin
   */
  preselected: boolean;
  title: string;
  subtitle?: string;
  searchString: string;
}

type NodeWithChildren = Node<number | string> & {
  children?: NodeWithChildren[];
};

export type Tree = RegionNode[];

export type RegionNode = Node<number> & {
  children: SiteNode[];
};

export type SiteNode = Node<number> & {
  children: SiteItemNode[];
};

export type SiteItemNode = SystemNode | SiteFeatureNode;

export type SystemNode = Node<number> & {
  group: string;
  groupLabel: string;
  subGroupId?: number;
  subGroupLabel?: string;
  organizationIds: number[];
  type: 'system';
};

export type SiteFeatureNode = Node<SiteFeatureType> & {
  groupLabel: string;
  type: 'siteFeature';
};

export type LayerNode =
  | Omit<
      RegionNode & { hasChildren: boolean; isAllChildrenSelected: boolean; isAllChildrenPreSelected: boolean },
      'children'
    >
  | Omit<
      SiteNode & { hasChildren: boolean; isAllChildrenSelected: boolean; isAllChildrenPreSelected: boolean },
      'children'
    >
  | SiteItemNode;

export type GroupedNodes = {
  id: string;
  label: string;
  selected: boolean;
  preselected?: boolean;
  allChildrenSelected: boolean;
  totalAmount: number;
  selectedAmount: number;
  children: SiteItemNode[];
};

export function filterNodesBySearchString<T extends { searchString: string }>(nodes: T[], searchString: string): T[] {
  if (!searchString) {
    return nodes;
  }
  return nodes.filter((node) => node.searchString.includes(searchString.toLowerCase()));
}

export function mapResponsesToModel(treeResponse: RegionTree[], systemGroups: SystemGroups[]): Tree {
  return treeResponse.map((region) => {
    const regionNode: RegionNode = {
      id: region.id,
      selected: false,
      preselected: false,
      title: region.name,
      subtitle: region.country,
      searchString: `${region.name} ${region.country} ${region.countryCode}`.toLowerCase(),
      children: region.sites.map((site) => {
        const siteNode: SiteNode = {
          id: site.id,
          selected: false,
          preselected: false,
          title: site.name || _.trim(`${site.streetNumber ?? ''} ${site.streetName}`),
          subtitle: `${site.city}, ${site.state}`,
          searchString: `${site.name || _.trim(`${site.streetNumber ?? ''} ${site.streetName}`)}`.toLowerCase(),
          children: [
            // Systems
            ...site.systems.map((system) => {
              const systemNode: SystemNode = {
                id: system.id,
                organizationIds: system.organizationIds,
                selected: false,
                preselected: false,
                title: `[${system.floor}] ${system.name}`,
                subtitle: SystemTypeToTitle[system.type],
                searchString: `${system.name} ${system.floor} ${SystemTypeToTitle[system.type]}`.toLowerCase(),
                group: system.groupType,
                groupLabel: systemGroupTypeToLabel[system.groupType],
                subGroupId: system.systemGroupId || undefined,
                subGroupLabel: system.systemGroupId
                  ? systemGroups.find((group) => group.id === system.systemGroupId)?.name || N_A
                  : undefined,
                type: 'system',
              };
              return systemNode;
            }),
            // Features
            ...site.features
              .filter((i) => i.isActive)
              .map((feature) => {
                const featureNode: SiteFeatureNode = {
                  id: feature.type,
                  selected: false,
                  preselected: false,
                  title: SITE_FEATURE_LABEL[feature.type],
                  subtitle: undefined,
                  searchString: SITE_FEATURE_LABEL[feature.type].toLowerCase(),
                  groupLabel: 'Site Features',
                  type: 'siteFeature',
                };
                return featureNode;
              }),
          ],
        };
        return siteNode;
      }),
    };
    return regionNode;
  });
}

export function mapModelToPermissionsRequest(tree: Tree, userRole?: UserRoles): NonNullableFields<Permissions> {
  const regions: number[] = [];
  const sites: number[] = [];
  const systems: number[] = [];
  const siteFeatures: SiteFeaturesPermissionsInput = {
    [SiteFeatureType.ADR]: [],
    [SiteFeatureType.ALERTS]: [],
    [SiteFeatureType.SPACE_MANAGEMENT]: [],
    [SiteFeatureType.LEASES]: [],
    [SiteFeatureType.BILLING]: [],
    [SiteFeatureType.SCHEDULING]: [],
    [SiteFeatureType.UTILITIES]: [],
    [SiteFeatureType.BENCHMARKING]: [],
  };

  // Always keep empty because super admin have access to everything
  if (userRole === UserRoles.SUPER_ADMIN) {
    return {
      regions,
      sites,
      systems,
      siteFeatures,
    };
  }

  for (const region of tree) {
    if (region.selected || region.preselected) {
      regions.push(region.id);
    }
    for (const site of region.children) {
      if (site.selected || site.preselected) {
        sites.push(site.id);
      }
      for (const item of site.children) {
        if (item.selected || item.preselected) {
          switch (item.type) {
            case 'system':
              systems.push(item.id);
              break;
            case 'siteFeature':
              siteFeatures[item.id].push(site.id);
              break;
          }
        }
      }
    }
  }

  return {
    regions,
    sites,
    systems,
    siteFeatures,
  };
}

/**
 * @note This method mutates the tree
 */
export function toggleSelectForAllNodes(tree: Tree, state: boolean): void {
  for (const region of tree) {
    toggleNode(region, state);
  }
}

/**
 * @note This method mutates the tree
 */
export function togglePreselectForAllNodes(tree: Tree, state: boolean): void {
  for (const region of tree) {
    region.preselected = state;
    for (const site of region.children) {
      site.preselected = state;
      for (const item of site.children) {
        item.preselected = state;
      }
    }
  }
}

/**
 * @note This method mutates the tree
 */
export function preselectTreeForAdmin(tree: Tree, organizationId: number): void {
  for (const region of tree) {
    let regionHasOrgSystem = false;

    for (const site of region.children) {
      let siteHasOrgSystem = false;

      for (const item of site.children) {
        if (item.type === 'system' && item.organizationIds.includes(organizationId)) {
          item.preselected = true;
          siteHasOrgSystem = true;
          regionHasOrgSystem = true;
        }
      }

      if (siteHasOrgSystem) {
        site.preselected = true;
      }
    }

    if (regionHasOrgSystem) {
      region.preselected = true;
    }
  }
}

/**
 * @note This method mutates the tree
 */
export function preventUnconnectedLeaf(tree: Tree): void {
  for (const region of tree) {
    const regionHasOrgSystem = { preselected: false, selected: false };

    for (const site of region.children) {
      const siteHasOrgSystem = { preselected: false, selected: false };

      for (const item of site.children) {
        siteHasOrgSystem.preselected = siteHasOrgSystem.preselected || item.preselected;
        siteHasOrgSystem.selected = siteHasOrgSystem.selected || item.selected;
        regionHasOrgSystem.preselected = regionHasOrgSystem.preselected || item.preselected;
        regionHasOrgSystem.selected = regionHasOrgSystem.selected || item.selected;
      }

      if (siteHasOrgSystem) {
        site.preselected = siteHasOrgSystem.preselected;
        site.selected = siteHasOrgSystem.selected;
      }
    }

    if (regionHasOrgSystem) {
      region.preselected = regionHasOrgSystem.preselected;
      region.selected = regionHasOrgSystem.selected;
    }
  }
}

/**
 * @note This method return tree with filtered nodes
 */
export function filterTreeForOrg(tree: Tree, organizationId: number): Tree {
  // Clone is required to prevent mutation of the original tree
  return (
    _.cloneDeep(tree)
      .map((region) => ({
        ...region,
        children: region.children
          .map((site) => {
            // If site has at least one system from the organization - then allow features under this site
            // If site has at least one feature - it was already filtered on server - so org has access to this site
            // This logic will be changed in future
            const hasAtLeastOneOrgSystem = site.children.some(
              (item) =>
                item.type === 'siteFeature' || (item.type === 'system' && item.organizationIds.includes(organizationId))
            );
            // Filter systems by organization
            // TODO: add filter by features
            const systems = site.children.filter((item) => {
              if (item.type === 'system') {
                return item.organizationIds.includes(organizationId);
              }
              return hasAtLeastOneOrgSystem;
            });

            return {
              ...site,
              children: systems,
            };
          })
          // Keep not empty sites
          .filter((site) => site.children.length > 0),
      }))
      // Keep not empty regions
      .filter((region) => region.children.length > 0)
  );
}

/**
 * @note This method mutates the tree
 */
export function preselectTreeForPermissions(tree: Tree, permissions: Permissions): void {
  for (const region of tree) {
    if (permissions.regions?.includes(region.id)) {
      region.preselected = true;
    }
    for (const site of region.children) {
      if (permissions.sites?.includes(site.id)) {
        site.preselected = true;
      }
      for (const item of site.children) {
        if (item.type === 'system' && permissions.systems?.includes(item.id)) {
          item.preselected = true;
        }
        if (item.type === 'siteFeature' && permissions.siteFeatures?.[item.id].includes(site.id)) {
          item.preselected = true;
        }
      }
    }
  }
}

/**
 * @note This method mutates the tree
 */
export function selectTreeForPermissions(tree: Tree, permissions: Permissions): void {
  for (const region of tree) {
    if (permissions.regions?.includes(region.id)) {
      region.selected = true;
    }
    for (const site of region.children) {
      if (permissions.sites?.includes(site.id)) {
        site.selected = true;
      }
      for (const item of site.children) {
        if (item.type === 'system' && permissions.systems?.includes(item.id)) {
          item.selected = true;
        }
        if (item.type === 'siteFeature' && permissions.siteFeatures?.[item.id].includes(site.id)) {
          item.selected = true;
        }
      }
    }
  }
}

/**
 * @note This method mutates the tree
 */
export function removeDoubleFlags(tree: Tree): void {
  for (const region of tree) {
    keepOnlyOneFlag(region);
    for (const site of region.children) {
      keepOnlyOneFlag(site);
      for (const item of site.children) {
        keepOnlyOneFlag(item);
      }
    }
  }
  preventUnconnectedLeaf(tree);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function keepOnlyOneFlag(tree: Node<any>): void {
  if (tree.selected && tree.preselected) {
    tree.selected = false;
  }
}

/**
 * @note This method returns link to the node
 */
export function findNode(tree: Tree, path: NodePath): NodeWithChildren | undefined {
  const region = tree.find((r) => r.id === path.regionId);
  if (!region) {
    return undefined;
  }
  if (!path.siteId) {
    return region;
  }
  const site = region.children.find((s) => s.id === path.siteId);
  if (!site) {
    return undefined;
  }
  if (!path.item) {
    return site;
  }
  return site.children.find((i) => i.id === path.item!.id && i.type === path.item!.type);
}

/**
 * @note This method mutates the node
 */
export function toggleNode(node: NodeWithChildren, state?: boolean): void {
  const targetState = _.isNil(state) ? !node.selected : state;

  node.selected = targetState;
  // If passed node is region or site -> update sites / systems / features
  if (node.children) {
    for (const child of node.children) {
      child.selected = targetState;
      // If passed node is region -> update systems / features
      if (child.children) {
        for (const grandChild of child.children) {
          grandChild.selected = targetState;
        }
      }
    }
  }
}

/**
 * @note This method mutates the tree
 */
export function selectParentNodes(tree: Tree, path: NodePath): void {
  const node = findNode(tree, path)!;

  // If the node is a system - also select parent site
  if (path.item) {
    const siteNode = findNode(tree, {
      regionId: path.regionId,
      siteId: path.siteId,
    });
    if (siteNode && node.selected && !siteNode?.selected) {
      siteNode.selected = true;
    }
  }

  // If the node is a site - also select parent region
  if (path.siteId) {
    const regionNode = findNode(tree, {
      regionId: path.regionId,
    });
    if (regionNode && node.selected && !regionNode?.selected) {
      regionNode.selected = true;
    }
  }
}

export function getFirstLayerNodes(tree: Tree): LayerNode[] {
  return tree.map((region) => ({
    id: region.id,
    selected: region.selected,
    hasChildren: region.children.length > 0,
    isAllChildrenSelected: region.children.every(
      (site) => site.selected && site.children.every((item) => item.selected)
    ),
    isAllChildrenPreSelected: region.children.every(
      (site) => site.preselected && site.children.every((item) => item.preselected)
    ),
    preselected: region.preselected,
    title: region.title,
    subtitle: region.subtitle,
    searchString: region.searchString,
  }));
}

export function getSecondLayerNodes(tree: Tree, regionId: number | null | undefined): LayerNode[] {
  if (!regionId) {
    return [];
  }
  const region = tree.find((r) => r.id === regionId);
  if (!region) {
    return [];
  }
  return region.children.map((site) => ({
    id: site.id,
    selected: site.selected,
    hasChildren: site.children.length > 0,
    isAllChildrenSelected: site.children.every((item) => item.selected),
    isAllChildrenPreSelected: site.children.every((item) => item.preselected),
    preselected: site.preselected,
    title: site.title,
    subtitle: site.subtitle,
    searchString: site.searchString,
  }));
}

export function getThirdLayerNodes(
  tree: Tree,
  regionId: number | null | undefined,
  siteId: number | null | undefined
): LayerNode[] {
  const region = tree.find((r) => r.id === regionId);
  if (!region) {
    return [];
  }
  const site = region.children.find((s) => s.id === siteId);
  if (!site) {
    return [];
  }
  return site.children;
}

export function groupNodesBy(
  items: SiteItemNode[],
  buildKey: (item: SiteItemNode) => string,
  labelKey: string = 'groupLabel'
): GroupedNodes[] {
  const groups: Record<string, GroupedNodes> = {};
  for (const item of items) {
    const key = buildKey(item);
    if (!groups[key]) {
      groups[key] = {
        id: key,
        children: [],
        selected: true,
        allChildrenSelected: true,
        label: _.get(item, labelKey),
        totalAmount: 0,
        selectedAmount: 0,
        preselected: item.preselected,
      };
    }
    const group = groups[key];
    group.selected = group.selected || item.selected;
    group.preselected = group.preselected && item.preselected;
    group.totalAmount += 1;
    group.selectedAmount += item.selected || item.preselected ? 1 : 0;
    group.allChildrenSelected = group.totalAmount === group.selectedAmount;
    group.children.push(item);
  }
  return _.values(groups);
}
