import type * as Types from '../../../../core/apollo/__generated__/exportGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type DownloadInvoiceQueryVariables = Types.Exact<{
  utilityInvoiceId: Types.Scalars['Int']['input'];
}>;

export type DownloadInvoiceQuery = { downloadUtilityInvoiceFiles: string };

export const DownloadInvoiceDocument = gql`
  query DownloadInvoice($utilityInvoiceId: Int!) {
    downloadUtilityInvoiceFiles(utilityInvoiceId: $utilityInvoiceId)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    DownloadInvoice(
      variables: DownloadInvoiceQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DownloadInvoiceQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DownloadInvoiceQuery>(DownloadInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DownloadInvoice',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
