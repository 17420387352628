import { InMemoryCacheConfig, Reference } from '@apollo/client';

import { UTCStringToLocalDate } from 'src/cdk/utils/datetimeToDate';
import Logger from 'src/core/service/logger';

import possibleTypes from './__generated__/possible-types';
import { scalarTypePolicies } from './__generated__/type-policy';
import { StrictTypedTypePolicies } from './__generated__/type-policy-types';

/**
 * Same as above, but forces datetime to stay in UTC timezone
 */
const UTCDateCacheConfig = {
  merge(_existing: Date, incoming: string): Date | null {
    // Review how new implementation affects schedule
    // if (incoming) {
    if (typeof incoming === 'string') {
      return UTCStringToLocalDate(incoming);
    }
    return incoming;
  },
};

const typePolicies: StrictTypedTypePolicies = {
  ...scalarTypePolicies,
  // Override codegen type policy
  SchedulerProfileSystemConfiguration: {
    fields: {
      ...scalarTypePolicies.SchedulerProfileSystemConfiguration.fields,
      date: UTCDateCacheConfig,
    },
  },
  SpaceOccupationHistoryTableData: {
    fields: scalarTypePolicies.SpaceOccupationHistoryTableData.fields,
    keyFields: ['occupationDate', 'expirationDate', 'id'],
  },
  Query: {
    fields: {
      /**
       * Read more about cache redirects here:
       * https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
       */
      sites(_, { args, toReference, cache }) {
        const cacheObject = cache.extract();
        const refList = args?.ids.map((id: number) =>
          toReference({
            __typename: 'OrderedSite',
            id: id,
          })
        );

        // Ensure that each requested Site exists in cache
        const isAllRequestedSitesExist = refList?.every((i: Reference) => cacheObject.hasOwnProperty(i.__ref));

        if (isAllRequestedSitesExist) {
          // Use cached values
          Logger.debug('Used cached data for sites', args);
          return refList;
        }
        // Make request to the server
        Logger.debug('Sending request for sites', args);
        return undefined;
      },
    },
  },
};

export const cacheConfig: InMemoryCacheConfig = { ...possibleTypes, typePolicies };
