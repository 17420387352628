import type * as Types from '../../../../core/apollo/__generated__/exportGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type DownloadInvoicesQueryVariables = Types.Exact<{
  invoiceIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type DownloadInvoicesQuery = { downloadTenantInvoiceFiles: string };

export const DownloadInvoicesDocument = gql`
  query DownloadInvoices($invoiceIds: [Int!]!) {
    downloadTenantInvoiceFiles(invoiceIds: $invoiceIds)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    DownloadInvoices(
      variables: DownloadInvoicesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DownloadInvoicesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DownloadInvoicesQuery>(DownloadInvoicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DownloadInvoices',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
