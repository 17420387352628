import { toDate } from 'date-fns';

/**
 * Modified solution of https://github.com/date-fns/date-fns/blob/619f31c216bc6fa40542eaddb30337c20dd4daae/src/roundToNearestMinutes/index.ts
 */
export default function roundToHours(dirtyDate: Date | number, upToUpperLimit = false): Date {
  const date = toDate(dirtyDate);

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), upToUpperLimit ? 59 : 0, 0);
}
