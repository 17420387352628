import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  GetSubmeterTypesForSiteQuery,
  GetSubmeterTypesForSiteQueryVariables,
} from './__generated__/getSubmeterTypesForSite.resources.gql';

const GET_SUBMETER_TYPES_FOR_SITE = gql`
  query GetSubmeterTypesForSite($siteId: Int!) {
    getSubmeterTypesForSite(siteId: $siteId)
  }
`;

export async function getSubmeterTypesForSite(siteId: number): Promise<string[]> {
  const result = await resourcesAPI.query<GetSubmeterTypesForSiteQuery, GetSubmeterTypesForSiteQueryVariables>({
    query: GET_SUBMETER_TYPES_FOR_SITE,
    variables: {
      siteId,
    },
  });

  return result.data.getSubmeterTypesForSite;
}
