import Joi from 'joi';
import _ from 'lodash';
import * as immutable from 'object-path-immutable';
import React, { useMemo } from 'react';

import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { combineSchemaValidationResult } from 'src/cdk/utils/combineSchemaValidationResult';

import { extractJoiPresence } from './extractJoiPresence';
import { uiFormFields } from './uiFormFields';
import { buildUISchemaFields, extractFieldFromSchema } from './uiSchemaBuilder';
import {
  AnyFieldComponent,
  FieldOrFactory,
  UISchemaFieldsPairs,
  UiSchema,
  getType,
  isFieldFactory,
} from './uiSchemaModel';
import { validationMessages } from './validationMessages';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = any;

interface Props<T = AnyObject> {
  schema: Joi.ObjectSchema<T>;
  uiSchema: UiSchema<T>;
  formData: T | undefined;
  onChange: (formData: T) => void;
  definePresence?: boolean;
}

export function UiFormBuilder({ schema, uiSchema, formData, onChange, definePresence }: Props) {
  const presence = useMemo(() => (definePresence ? extractJoiPresence(schema, formData) : {}), [formData, schema]);
  const fieldsAsArrays = useMemo(
    () => buildUISchemaFields(schema, uiSchema.fields, presence),
    [schema, uiSchema, presence]
  );

  // Validation should be run for the whole schema and not for each field separately,
  // because it can be nested or related to other fields.
  const { response: errors } = useDataFetchOnMountWithDeps(
    async () =>
      _.fromPairs(
        (
          await combineSchemaValidationResult(
            schema.validateAsync(formData, {
              errors: { label: false },
              messages: validationMessages,
              abortEarly: false,
            })
          )
        ).error?.details.map((e) => [e.path.join('.'), e.message])
      ),
    [formData, schema]
  );

  return (
    <div
      style={{
        gap: `${uiSchema.gridRowGap ? uiSchema.gridRowGap : '6px'} ${uiSchema.gridColGap ? uiSchema.gridColGap : '24px'}`,
        display: 'grid',
        gridTemplateColumns: uiSchema.gridTemplateColumns,
        gridTemplateRows: 'auto',
        gridTemplateAreas: uiSchema.gridTemplateAreas,
        gridAutoFlow: 'row dense',
      }}
    >
      <FormPartial
        fields={fieldsAsArrays ?? []}
        schema={schema}
        data={formData}
        onChange={(value) => {
          onChange(value);
        }}
        errors={errors}
      />
    </div>
  );
}

type Hook<T, C> = (hookConfig: C & { value: T }) => {
  validationSchema: Joi.ObjectSchema<T>;
  uiSchema: UiSchema<T>;
  onChangeMiddleware?: (oldValue: T, newValue: T) => T;
};

type PropsHook<T, C> = {
  value: T;
  onChange: (value: T) => void;
  hook: Hook<T, C>;
} & Parameters<Hook<T, C>>[0];

UiFormBuilder.fromHook = function UiFormBuilderFromHook<T, C>({ hook, onChange, value, ...props }: PropsHook<T, C>) {
  const { validationSchema, uiSchema, onChangeMiddleware } = hook({ value, ...((props || {}) as C) });

  return (
    <UiFormBuilder
      schema={validationSchema}
      uiSchema={uiSchema}
      formData={value}
      onChange={(newValue) => {
        const updatedValue = onChangeMiddleware ? onChangeMiddleware(value, newValue) : newValue;
        onChange(updatedValue);
      }}
      definePresence
    />
  );
};

interface PartialProps<T = AnyObject> {
  schema: Joi.Schema<T>;
  fields: UISchemaFieldsPairs<T>;
  data: T;
  onChange: (value: T) => void;
  errors?: Record<string, string>;
}

const FormPartial: React.FC<PartialProps> = ({ fields, schema, data, onChange, errors }) => {
  const onChangeCallback = (fieldName: string) => (value: unknown) => {
    // Shorthand to update complex data structures and React-safe way
    const newData = immutable.set(data, fieldName, value);
    onChange(newData);
  };

  return (
    <>
      {fields.map(([fieldName, field]) => {
        const subSchema = extractFieldFromSchema(schema, fieldName, getType(field));
        const value = _.get(data, fieldName);

        if (_.isArray(field)) {
          // TODO: TBD if it is needed to support arrays of fields, or CustomField type is enough?
          return (
            <FormPartial
              key={fieldName}
              fields={field}
              schema={subSchema}
              data={data}
              onChange={onChangeCallback(fieldName)}
              errors={errors}
            />
          );
        }

        return (
          <FieldBuilder
            formData={data}
            key={fieldName}
            field={field}
            value={value}
            onChange={onChangeCallback(fieldName)}
            fieldName={fieldName}
            error={_.get(errors, fieldName)}
          />
        );
      })}
    </>
  );
};

function FieldBuilder<T>(props: {
  formData: T;
  fieldName: string;
  field: FieldOrFactory<T>;
  error?: string;
  value: unknown;
  onChange: (value: unknown) => void;
}) {
  const { formData: data, fieldName, field, error, value, onChange } = props;

  const isFactory = isFieldFactory(field);
  const deps = isFactory ? [field.field, ...field.deps(data)] : [field];
  const fieldToRender = useMemo(() => {
    if (isFactory) {
      const [, ...propsToPass] = deps;
      return field.builder(field.field, ...propsToPass);
    }

    return field;
  }, deps);

  const FieldComponent = uiFormFields[fieldToRender.type] as AnyFieldComponent;

  return (
    <FieldComponent
      key={fieldName}
      field={fieldToRender}
      value={value}
      onChange={onChange}
      fieldName={fieldName}
      error={error}
    />
  );
}
