import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type CreateLeaseMutationVariables = Types.Exact<{
  createLeaseInput: Types.CreateLeaseInput;
}>;

export type CreateLeaseMutation = { createLease: { leaseId: number; tenantId: number } };

export type UpdateLeaseMutationVariables = Types.Exact<{
  leaseId: Types.Scalars['Int']['input'];
  updateLeaseInput: Types.UpdateLeaseInput;
}>;

export type UpdateLeaseMutation = { updateLease: boolean };

export type DeleteLeaseMutationVariables = Types.Exact<{
  leaseId: Types.Scalars['Int']['input'];
}>;

export type DeleteLeaseMutation = { deleteLease: boolean };

export type GetLeaseQueryVariables = Types.Exact<{
  leaseId: Types.Scalars['Int']['input'];
}>;

export type GetLeaseQuery = {
  getLeaseInfo: {
    occupiedSpacesIds: Array<number>;
    tenantId: number;
    billingInfo?: {
      billingFrequency: Types.DispatchType;
      billingStartDate: Date;
      billingStructure: Types.BillingRateType;
      paymentInfo?: string | null;
      paymentTerms: number;
      rates: {
        demandOffPeakRate?: number | null;
        demandOnPeakRate?: number | null;
        energyOffPeakRate: number;
        energyOnPeakRate?: number | null;
        rateType: Types.BillingRateType;
        peakRanges?: Array<{ hours: Array<number>; weekday: Types.WeekDays }> | null;
      };
      charges: Array<{
        chargeCalculationType: Types.ChargeCalculationType;
        type: Types.ChargeType;
        createdAt: Date;
        description?: string | null;
        endDate?: Date | null;
        id: number;
        name: string;
        order: number;
        startDate: Date;
        value: number;
      }>;
    } | null;
    leaseInfo: {
      accountNumber: string;
      billable: boolean;
      contactName?: string | null;
      email?: string | null;
      endDate?: Date | null;
      phone?: string | null;
      startDate: Date;
    };
  };
};

export type GetBillingPeriodQueryVariables = Types.Exact<{
  billingStart: Types.Scalars['Date']['input'];
  leaseEnd?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  leaseStart: Types.Scalars['Date']['input'];
  siteToday: Types.Scalars['Date']['input'];
}>;

export type GetBillingPeriodQuery = { getChargeStartDay: { from: Date; to?: Date | null } };

export type UpdateChargeMutationVariables = Types.Exact<{
  chargeId: Types.Scalars['Int']['input'];
  siteId: Types.Scalars['Int']['input'];
  updateAdditionalChargeInput: Types.UpdateAdditionalChargeInput;
}>;

export type UpdateChargeMutation = { updateAdditionalCharge: boolean };

export type DeleteChargeMutationVariables = Types.Exact<{
  chargeId: Types.Scalars['Int']['input'];
}>;

export type DeleteChargeMutation = { deleteAdditionalCharge: boolean };

export type MarkChargeAsExpiredMutationVariables = Types.Exact<{
  chargeId: Types.Scalars['Int']['input'];
}>;

export type MarkChargeAsExpiredMutation = {
  expireChargeForNextBill: { updatedAt: Date; updatedBy: string; endDate?: Date | null; id: number };
};

export const CreateLeaseDocument = gql`
  mutation CreateLease($createLeaseInput: CreateLeaseInput!) {
    createLease(createLeaseInput: $createLeaseInput) {
      leaseId
      tenantId
    }
  }
`;
export const UpdateLeaseDocument = gql`
  mutation UpdateLease($leaseId: Int!, $updateLeaseInput: UpdateLeaseInput!) {
    updateLease(leaseId: $leaseId, updateLeaseInput: $updateLeaseInput)
  }
`;
export const DeleteLeaseDocument = gql`
  mutation DeleteLease($leaseId: Int!) {
    deleteLease(leaseId: $leaseId)
  }
`;
export const GetLeaseDocument = gql`
  query GetLease($leaseId: Int!) {
    getLeaseInfo(leaseId: $leaseId) {
      billingInfo {
        billingFrequency
        billingStartDate
        billingStructure
        paymentInfo
        paymentTerms
        rates {
          demandOffPeakRate
          demandOnPeakRate
          energyOffPeakRate
          energyOnPeakRate
          peakRanges {
            hours
            weekday
          }
          rateType
        }
        charges {
          chargeCalculationType
          type
          createdAt
          description
          endDate
          id
          name
          order
          startDate
          value
        }
      }
      leaseInfo {
        accountNumber
        billable
        contactName
        email
        endDate
        phone
        startDate
      }
      occupiedSpacesIds
      tenantId
    }
  }
`;
export const GetBillingPeriodDocument = gql`
  query GetBillingPeriod($billingStart: Date!, $leaseEnd: Date, $leaseStart: Date!, $siteToday: Date!) {
    getChargeStartDay(
      billingStart: $billingStart
      leaseEnd: $leaseEnd
      leaseStart: $leaseStart
      siteToday: $siteToday
    ) {
      from
      to
    }
  }
`;
export const UpdateChargeDocument = gql`
  mutation UpdateCharge($chargeId: Int!, $siteId: Int!, $updateAdditionalChargeInput: UpdateAdditionalChargeInput!) {
    updateAdditionalCharge(
      chargeId: $chargeId
      siteId: $siteId
      updateAdditionalChargeInput: $updateAdditionalChargeInput
    )
  }
`;
export const DeleteChargeDocument = gql`
  mutation DeleteCharge($chargeId: Int!) {
    deleteAdditionalCharge(chargeId: $chargeId)
  }
`;
export const MarkChargeAsExpiredDocument = gql`
  mutation MarkChargeAsExpired($chargeId: Int!) {
    expireChargeForNextBill(chargeId: $chargeId) {
      updatedAt
      updatedBy
      endDate
      id
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateLease(
      variables: CreateLeaseMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateLeaseMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateLeaseMutation>(CreateLeaseDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateLease',
        'mutation',
        variables
      );
    },
    UpdateLease(
      variables: UpdateLeaseMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateLeaseMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateLeaseMutation>(UpdateLeaseDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateLease',
        'mutation',
        variables
      );
    },
    DeleteLease(
      variables: DeleteLeaseMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteLeaseMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteLeaseMutation>(DeleteLeaseDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteLease',
        'mutation',
        variables
      );
    },
    GetLease(variables: GetLeaseQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetLeaseQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetLeaseQuery>(GetLeaseDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetLease',
        'query',
        variables
      );
    },
    GetBillingPeriod(
      variables: GetBillingPeriodQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetBillingPeriodQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBillingPeriodQuery>(GetBillingPeriodDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetBillingPeriod',
        'query',
        variables
      );
    },
    UpdateCharge(
      variables: UpdateChargeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateChargeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateChargeMutation>(UpdateChargeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateCharge',
        'mutation',
        variables
      );
    },
    DeleteCharge(
      variables: DeleteChargeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteChargeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteChargeMutation>(DeleteChargeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteCharge',
        'mutation',
        variables
      );
    },
    MarkChargeAsExpired(
      variables: MarkChargeAsExpiredMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<MarkChargeAsExpiredMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MarkChargeAsExpiredMutation>(MarkChargeAsExpiredDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'MarkChargeAsExpired',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
