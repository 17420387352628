import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import { UtilityServiceTypes } from 'src/core/apollo/__generated__/utilityGlobalTypes';
import { UTILITY_SERVICE_TYPE_LABEL } from 'src/core/enum-labels';
import OverviewPerSiteLayout from 'src/fragments/layouts/OverviewPerSiteLayout';
import { useUtilityMetersContextProvider } from 'src/logic/utility/useUtilityMetersContext';
import { useUtilityType } from 'src/logic/utility/useUtilityType';
import { CircularLoader } from 'src/shared/components/Loader/Loader';

export function UtilityOverviewPerSiteLayout() {
  const siteId = useNumberParam('siteId');
  const utilityType = useUtilityType();

  const metersContext = useUtilityMetersContextProvider(siteId, utilityType);

  // TODO: add Audits tab when it will be ready
  const tabNames = useMemo(() => {
    if (utilityType === UtilityServiceTypes.ELECTRIC) {
      return ['Dashboard', 'Trend', 'Forecasting', 'Performance', 'Bills'];
    }
    return ['Dashboard', 'Trend', 'Performance', 'Bills'];
  }, [utilityType]);

  return (
    <OverviewPerSiteLayout title={`${UTILITY_SERVICE_TYPE_LABEL[utilityType]}`} tabsNames={tabNames}>
      {metersContext.isLoading ? (
        <CircularLoader />
      ) : (
        <metersContext.Provider>
          <Outlet />
        </metersContext.Provider>
      )}
    </OverviewPerSiteLayout>
  );
}
