import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'src/routes';

import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { SupportedIcon } from '../Icon/gen/suported-icons';

export interface Props {
  /**
   * @default 'warning-alt'
   */
  icon?: SupportedIcon;
  /**
   * @default false
   */
  error?: boolean;
  /**
   * @default 'No data available'
   */
  title?: string;
  children?: React.ReactNode;
}

const ElevatedBox: React.FC<Props> = ({
  children,
  title = 'No data available',
  error = false,
  icon = 'warning-alt',
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames('text-center px-8 py-16', {
        'color-tertiary': true,
      })}
    >
      <Icon icon={icon} color='tertiary' />
      <p className='body-semibold mb-4'>{title}</p>
      <p className='body-small'>{children}</p>
      {error && (
        <Button className='mt-8' label='Go to home page' onClick={() => navigate(Routes.Map)} variant='primary' />
      )}
    </div>
  );
};

export default ElevatedBox;
