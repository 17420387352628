import { Input } from 'src/shared/components/Input/Input';

import { IFilterField, IFilterFieldSearch } from '../../filter.interface';

import { FilterLabel } from './FieldLabel';

export const isSearchField = (field: IFilterField): field is IFilterFieldSearch => field.type === 'search';

export const FilterFieldSearch: React.FC<{
  field: IFilterFieldSearch;
  value: unknown | undefined;
  onChange: (value: unknown | undefined) => void;
}> = (props) => {
  return (
    <fieldset className='d-flex flex-col flex-1'>
      <FilterLabel field={props.field} />

      <Input
        className='w-100'
        placeholder='Search'
        icon='search'
        onChange={(value) => props.onChange(value)}
        value={(props.value as string | number)?.toString() ?? ''}
      />
    </fieldset>
  );
};
