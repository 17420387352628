import classNames from 'classnames';

import styles from './TileHeader.module.scss';

interface TileHeaderProps {
  title: string;
  subtitle?: string;
  className?: string;
  children?: React.ReactNode;
}

const TileHeader: React.FC<TileHeaderProps> = ({ title, subtitle, className, children }) => {
  return (
    <div className={classNames(styles['header'], className)}>
      <div>
        <p className='subtitle color-primary'>{title}</p>
        {subtitle && <p className='body-small color-secondary'>{subtitle}</p>}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default TileHeader;
