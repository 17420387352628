import { gql } from '@apollo/client';

import { FilterConfig } from '../../../apollo/__generated__/usersGlobalTypes';
import { usersAPI } from '../../../apollo/api';

import {
  GetCurrentUserConstraintsQuery,
  GetCurrentUserConstraintsQueryVariables,
} from './__generated__/getCurrentUserConstraints.users.gql';

export type PersistedFilterConfiguration = FilterConfig;
export type UserConstraints = GetCurrentUserConstraintsQuery['me'];

const GET_CURRENT_USER_CONSTRAINTS = gql`
  query GetCurrentUserConstraints($loginEvent: Boolean) {
    me(loginEvent: $loginEvent) {
      id
      organization_id
      regions
      sites
      systems
      role
      filterConfigs {
        id
        key
        name
        filters
      }
      appearance
      phone
    }
  }
`;

export async function getCurrentUserConstraints(loginEvent?: boolean | null): Promise<UserConstraints> {
  const result = await usersAPI.query<GetCurrentUserConstraintsQuery, GetCurrentUserConstraintsQueryVariables>({
    query: GET_CURRENT_USER_CONSTRAINTS,
    variables: {
      loginEvent,
    },
  });

  return result.data.me;
}
