import classNames from 'classnames';
import _ from 'lodash';

import { N_A } from 'src/constants';

interface Props {
  className?: string;
  children?: string | null | string[] | (string | null | undefined)[];
}

const Ellipsis: React.FC<Props> = ({ children, className }) => {
  const value = _.isArray(children) ? children.join('') : children ?? undefined;

  return (
    <p className={classNames('one-line-ellipsis', className)} title={value}>
      {value || N_A}
    </p>
  );
};

// TODO: TBD maybe use tooltip like this?
// <CustomTooltip
//       key='description'
//       disabled={_.isEmpty(description)}
//       hideArrow
//       triggerComponent={
//         <div className='one-line-ellipsis'>
//           <Ellipsis>{description}</Ellipsis>
//         </div>
//       }>
//       <div>
//         <p className='color-secondary'>Description</p>
//         <p>{description}</p>
//       </div>
//     </CustomTooltip>

export default Ellipsis;
