import Logger from 'src/core/service/logger';

type DataCallback<T> = (data: T) => void;

export class SimpleObservable<T> {
  private callbacks: Set<DataCallback<T>> = new Set();

  constructor(private data: T) {}

  readLatestValue(): T {
    return this.data;
  }

  /**
   * Triggers all callbacks connected to current observable.
   */
  updateValue(value: T): void {
    this.data = value;

    // Make process of iteration over all callbacks async - to improve performance
    setTimeout(() => {
      this.callbacks.forEach((callback) => {
        // Make process of execution of single callback async - to improve performance
        setTimeout(() => {
          callback(this.data);
        });
      });
    });
  }

  /**
   * Should be the same callback which will be passed to `unregister` method.
   * So it is recommended to pass function by name, and not arrow functions.
   *
   * @example
   * ```
   *   useOnMount(() => {
   *     NOW_OBSERVER.register(setNow);
   *
   *     return () => {
   *       NOW_OBSERVER.unregister(setNow);
   *     };
   *   });
   * ```
   */
  register(callback: DataCallback<T>): void {
    this.callbacks.add(callback);
    Logger.debug('Amount of callbacks', this.callbacks.size);
  }

  /**
   * Should be the same callback which was passed to `register` method.
   */
  unregister(callback: DataCallback<T>): void {
    this.callbacks.delete(callback);
    Logger.debug('Amount of callbacks', this.callbacks.size);
  }
}
