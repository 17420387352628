import _ from 'lodash';
import { useRouteError } from 'react-router';

import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';

export interface Props {
  title?: string;
}

/**
 * By default will display message of thrown error/exception
 */
const ExceptionHandler: React.FC<Props> = ({ title }) => {
  const error = useRouteError();

  return <ElevatedBox error title={title ?? _.isString(error) ? String(error) : _.get(error, 'message')} />;
};

export default ExceptionHandler;
