import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

import { selectUserName } from 'src/core/store/global/globalSlice';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';
import PageHeaderWithActions from 'src/shared/containers/PageHeaderWithActions';

interface Props {
  tabsNames: string[];
}

const UserSettingsPage: React.FC<Props> = ({ tabsNames }) => {
  const userName = useSelector(selectUserName);

  return (
    <>
      <PageHeaderWithActions title='Account Settings' subtitle={userName} removeBottomMargin />
      <UrlTabs names={tabsNames} />
      <Outlet />
    </>
  );
};

export default UserSettingsPage;
