import classNames from 'classnames';
import { addMonths, differenceInCalendarMonths, differenceInMonths, subMonths } from 'date-fns';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { DateRange, addToRange } from 'react-day-picker';

import { useOnClickOutside } from 'src/cdk/hooks/useOnClickOutside';
import { useOnMount } from 'src/cdk/hooks/useOnMount';
import { roundToLocalDays } from 'src/cdk/utils/roundToDays';
import { roundToNearestMinutes } from 'src/cdk/utils/roundToNearestMinutes';
import Logger from 'src/core/service/logger';

import { isSameMoment } from '../../../utils/isSameMoment';
import { Button } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import { Input } from '../../Input/Input';
import { ButtonsLayoutOrder, DatePickerRangeType, buttonsConfig, formatMonthYear } from '../DateRangePicker/config';
import { MonthPicker } from '../MonthPicker/MonthPicker';
import { useDisabledDays } from '../base/useDisabledDays';

import styles from './MonthRangePicker.module.scss';

export interface MonthRangePickerProps {
  /**
   * Time range as tuple of two dates
   * [from, to]
   *
   * @example [new Date('2020-01-01'), new Date('2020-01-02')]
   */
  timeRange: [Date, Date];
  initTimerangeType: DatePickerRangeType;
  className?: string;
  buttonsToDisplay?: DatePickerRangeType[];
  onClick: (timeRange: [Date, Date], previousPeriod: [Date, Date]) => void;
  disabled?: boolean;
}

const MAX_MONTHS_DIFFERENCE = 24;

export const MonthRangePicker: React.FC<MonthRangePickerProps> = ({
  timeRange,
  initTimerangeType,
  className,
  onClick,
  buttonsToDisplay = ButtonsLayoutOrder,
  disabled = false,
}) => {
  // TODO: rework that component with use of Dropdown component
  const [isOpen, setIsOpen] = useState(false);
  const [today] = useState(roundToNearestMinutes(new Date(), { nearestTo: 15 }));
  const [dates, setDates] = useState<DateRange>({
    from: timeRange[0],
    to: timeRange[1],
  });
  const [labelKey, setLabelKey] = useState<DatePickerRangeType>(initTimerangeType);
  const [viewLabelKey, setViewLabelKey] = useState<DatePickerRangeType>(initTimerangeType);
  const [month, setMonth] = useState<Date>(dates.to || dates.from || today);

  const isApplyDisabled =
    (isSameMoment(dates.from, timeRange[0]) && isSameMoment(dates.to, timeRange[1])) || !dates.from || !dates.to;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const datePicker = useRef<HTMLDivElement>(null);

  const fromDateLabel = formatMonthYear(timeRange[0]);
  const toDateLabel = formatMonthYear(timeRange[1]);

  const disabledDaysModifiers = useDisabledDays(undefined, today);

  useOnClickOutside<HTMLButtonElement | HTMLDivElement>([buttonRef, datePicker], isOpen, () => cancelClick());

  useOnMount(() => {
    const labelKey = buttonsToDisplay.find((button) => {
      const buttonRange = buttonsConfig[button]?.getTimeRange();
      if (buttonRange) {
        return _.isEqual(buttonRange[0], timeRange[0]) && _.isEqual(buttonRange[1], timeRange[1]);
      }
      return false;
    });
    if (labelKey) {
      setViewLabelKey(labelKey);
    }
  });

  function handleOpenDropDown(): void {
    setIsOpen(!isOpen);
  }

  function cancelClick(): void {
    setDates({
      from: timeRange[0],
      to: timeRange[1],
    });
    setViewLabelKey(labelKey);
    setIsOpen(false);
  }

  function handleDayClick(day: Date) {
    const range = addToRange(day, { from: dates.from, to: dates.to });
    let toDate = range?.to && range?.to > today ? today : range?.to;
    let fromDate = range?.from && range?.from > today ? today : range?.from;
    if (toDate && fromDate && Math.round(differenceInCalendarMonths(toDate, fromDate)) >= MAX_MONTHS_DIFFERENCE) {
      if (day === toDate) {
        fromDate = addMonths(toDate, -MAX_MONTHS_DIFFERENCE);
      } else {
        toDate = undefined;
      }
    }
    setDates({ from: fromDate && roundToLocalDays(fromDate), to: toDate && roundToLocalDays(toDate, true) });
    setViewLabelKey(DatePickerRangeType.CUSTOM);
  }

  function presetRangeClick(range: [Date, Date], key: DatePickerRangeType) {
    setDates({ from: range[0], to: range[1] });
    setViewLabelKey(key);
  }

  function getPreviousMonthRage(start: Date, end: Date): [Date, Date] {
    const previousRanges = buttonsConfig[viewLabelKey]?.getPrevTimeRange();
    if (previousRanges && viewLabelKey !== DatePickerRangeType.CUSTOM) {
      return previousRanges;
    }
    const diffMonths = differenceInMonths(end, addMonths(start, 1));
    return [subMonths(start, diffMonths), subMonths(end, diffMonths)];
  }

  return (
    <div className={styles['date-picker-wrapper']}>
      <button
        ref={buttonRef}
        className={classNames('input', 'with-pointer', styles['date-picker-btn'], className)}
        onClick={handleOpenDropDown}
        disabled={disabled}
      >
        <span className={classNames('color-secondary', styles['label-inside'])}>{labelKey}:</span>
        <span className={classNames('color-primary', 'body-semi-bold')}>{`${fromDateLabel} - ${toDateLabel}`}</span>
        <Icon icon={isOpen ? 'dropdown-up' : 'dropdown-down'} size='s' className={styles['dropdown-down-icon']} />
      </button>
      {isOpen && (
        <div ref={datePicker} className={classNames('card el-08', styles['date-picker-body'])}>
          <div className={styles['date-picker-left-column']}>
            <div className={styles['date-picker-left-header']}>
              <p className='body-small mt-4 color-secondary'>Custom Ranges</p>
              <div className={styles['date-picker-input-container']}>
                <Input
                  id='name'
                  value={dates.from ? formatMonthYear(dates.from) : ''}
                  className={styles['date-picker-inputs']}
                  onChange={() => null}
                  readonly
                  disabled
                />
                &nbsp;-&nbsp;
                <Input
                  id='name'
                  value={dates.to ? formatMonthYear(dates.to) : ''}
                  className={styles['date-picker-inputs']}
                  onChange={() => null}
                  readonly
                  disabled
                />
              </div>
            </div>
            <MonthPicker
              selected={dates}
              month={month}
              disabled={disabledDaysModifiers}
              onMonthClick={handleDayClick}
              onYearChange={setMonth}
            />
          </div>
          <div className={styles['date-picker-right-column']}>
            <p className='body-small mt-8 color-secondary'>Quick Ranges</p>
            <div className={classNames(styles['date-picker-buttons-container'], 'mt-24')}>
              {buttonsToDisplay.map((configKey, i) => {
                const config = buttonsConfig[configKey];
                if (!config) {
                  return;
                }
                return (
                  <Button
                    key={i}
                    className={styles['date-picker-quick-button']}
                    label={configKey}
                    onClick={() => presetRangeClick(config.getTimeRange(), configKey)}
                    shape='rect'
                    type='button'
                    variant={configKey === viewLabelKey ? 'primary' : 'secondary'}
                  />
                );
              })}
            </div>
            <div className={classNames(styles['date-picker-action-container'], 'mt-48')}>
              <Button
                className={styles['date-picker-action-button']}
                label='Cancel'
                size='big'
                variant='flat'
                onClick={() => cancelClick()}
              />
              <Button
                className={styles['date-picker-action-button']}
                onClick={() => {
                  if (dates.from && dates.to) {
                    setLabelKey(viewLabelKey);
                    onClick([dates.from, dates.to], getPreviousMonthRage(dates.from, dates.to));
                    handleOpenDropDown();
                  } else {
                    Logger.error('Invalid date range');
                  }
                }}
                label='Apply'
                size='big'
                variant='primary'
                disabled={isApplyDisabled}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
