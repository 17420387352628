import type * as Types from '../../../../core/apollo/__generated__/usersGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetUserListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserListQuery = {
  usersOverview: Array<{
    id: string;
    name: string;
    email: string;
    role: Types.UserRoles;
    last_login?: Date | null;
    login_count?: number | null;
    deleted: boolean;
    departments: Array<string>;
    organization?: { id: number; name: string } | null;
  }>;
};

export type CheckIsEmailInUseQueryVariables = Types.Exact<{
  email: Types.Scalars['EmailAddress']['input'];
}>;

export type CheckIsEmailInUseQuery = { isEmailInUse: boolean };

export type DeleteUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;

export type DeleteUserMutation = { deleteUser: boolean };

export type CreateUserMutationVariables = Types.Exact<{
  createUserInput: Types.CreateUserInput;
}>;

export type CreateUserMutation = { createUser: { id: string } };

export type ResetPasswordMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;

export type ResetPasswordMutation = { resetPassword: Types.UserStatus };

export type UpdateUserMutationVariables = Types.Exact<{
  updateUserInput: Types.UpdateUserInput;
  userId: Types.Scalars['String']['input'];
}>;

export type UpdateUserMutation = { updateUser: boolean };

export type UpdateUserSettingsMutationVariables = Types.Exact<{
  updateUserSettingsInput: Types.UpdateUserSettingsInput;
}>;

export type UpdateUserSettingsMutation = { updateUserSettings: boolean };

export type GetUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type GetUserByIdQuery = {
  user: {
    id: string;
    name: string;
    email: string;
    role: Types.UserRoles;
    organization_id: number;
    departmentIds: Array<string>;
    regions?: Array<number> | null;
    sites?: Array<number> | null;
    systems?: Array<number> | null;
    phone?: string | null;
    last_login?: Date | null;
    login_count?: number | null;
    deleted: boolean;
    appearance?: Types.UserAppearance | null;
    siteFeatures?: {
      ADR: Array<number>;
      ALERTS: Array<number>;
      BILLING: Array<number>;
      LEASES: Array<number>;
      SCHEDULING: Array<number>;
      SPACE_MANAGEMENT: Array<number>;
      UTILITIES: Array<number>;
      BENCHMARKING: Array<number>;
    } | null;
  };
};

export const GetUserListDocument = gql`
  query GetUserList {
    usersOverview {
      id
      name
      email
      role
      last_login
      login_count
      deleted
      departments
      organization {
        id
        name
      }
    }
  }
`;
export const CheckIsEmailInUseDocument = gql`
  query CheckIsEmailInUse($email: EmailAddress!) {
    isEmailInUse(email: $email)
  }
`;
export const DeleteUserDocument = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;
export const CreateUserDocument = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
    }
  }
`;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($userId: String!) {
    resetPassword(userId: $userId)
  }
`;
export const UpdateUserDocument = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!, $userId: String!) {
    updateUser(updateUserInput: $updateUserInput, userId: $userId)
  }
`;
export const UpdateUserSettingsDocument = gql`
  mutation UpdateUserSettings($updateUserSettingsInput: UpdateUserSettingsInput!) {
    updateUserSettings(updateUserSettingsInput: $updateUserSettingsInput)
  }
`;
export const GetUserByIdDocument = gql`
  query GetUserById($id: String!) {
    user(id: $id) {
      id
      name
      email
      role
      organization_id
      departmentIds
      regions
      sites
      systems
      phone
      siteFeatures {
        ADR
        ALERTS
        BILLING
        LEASES
        SCHEDULING
        SPACE_MANAGEMENT
        UTILITIES
        BENCHMARKING
      }
      last_login
      login_count
      deleted
      appearance
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetUserList(
      variables?: GetUserListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUserListQuery>(GetUserListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetUserList',
        'query',
        variables
      );
    },
    CheckIsEmailInUse(
      variables: CheckIsEmailInUseQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CheckIsEmailInUseQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckIsEmailInUseQuery>(CheckIsEmailInUseDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CheckIsEmailInUse',
        'query',
        variables
      );
    },
    DeleteUser(
      variables: DeleteUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteUserMutation>(DeleteUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteUser',
        'mutation',
        variables
      );
    },
    CreateUser(
      variables: CreateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateUserMutation>(CreateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateUser',
        'mutation',
        variables
      );
    },
    ResetPassword(
      variables: ResetPasswordMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<ResetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ResetPassword',
        'mutation',
        variables
      );
    },
    UpdateUser(
      variables: UpdateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserMutation>(UpdateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateUser',
        'mutation',
        variables
      );
    },
    UpdateUserSettings(
      variables: UpdateUserSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateUserSettingsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserSettingsMutation>(UpdateUserSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateUserSettings',
        'mutation',
        variables
      );
    },
    GetUserById(
      variables: GetUserByIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUserByIdQuery>(GetUserByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetUserById',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
