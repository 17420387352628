import classNames from 'classnames';
import _ from 'lodash';

import { N_A } from 'src/constants';

import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import PercentChangePill from '../PercentChangePill/PercentChangePill';
import { RenderUnit, UNIT } from '../Unit/RenderUnit';

import styles from './KPIWithComparison.module.scss';

interface KPIWithComparisonProps {
  name: string;
  subtitle?: string;
  unit: UNIT;
  perUnit?: UNIT;
  value?: number | null;
  percentValue?: number | null;
  percentComparisonText?: string;
  inverseColor?: boolean;
  tooltipText?: string;
  compareValueTooltipText?: string;
  format?: (value: number) => string;
}

export const KPIWithComparison: React.FC<KPIWithComparisonProps> = ({
  name,
  subtitle,
  unit,
  perUnit,
  value,
  percentValue,
  percentComparisonText,
  inverseColor,
  tooltipText,
  compareValueTooltipText,
  format = (value) => value.toString(),
}) => {
  return (
    <div className={classNames('card', 'flat', 'el-04', styles['section'])}>
      <div className='d-flex justify-content-space-between'>
        <div className='d-flex align-items-center'>
          <h5>{name}</h5>
          {tooltipText && <InfoTooltip className='ml-12'>{tooltipText}</InfoTooltip>}
        </div>
        {subtitle && <p className='body-small'>{subtitle}</p>}
      </div>
      <span className='body-small d-flex align-items-baseline'>
        <h3>{!_.isNil(value) ? format(value) : N_A}</h3>
        {!_.isUndefined(value) && (
          <p>
            <RenderUnit unit={unit} perUnit={perUnit} dimmed />
          </p>
        )}
      </span>
      {!_.isUndefined(percentValue) && (
        <div className='d-flex align-items-center mt-24 '>
          <PercentChangePill value={percentValue} inverseColor={inverseColor} />
          {percentComparisonText && <p className='body-small color-secondary ml-12'>{percentComparisonText}</p>}
          {compareValueTooltipText && <InfoTooltip className='ml-12'>{compareValueTooltipText}</InfoTooltip>}
        </div>
      )}
    </div>
  );
};
