import { useNavigate } from 'react-router-dom';

import { Button } from 'src/shared/components/Button/Button';

import styles from './ErrorPage.module.scss';

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles['page']}>
      <img src='/assets/orion-logo.png' className={styles['logo']} />
      <img
        src='/assets/cubes.webp'
        srcSet='/assets/cubes@2x.webp 2x, /assets/cubes@3x.webp 3x'
        className={styles['cubes']}
      />
      <div className={styles['content']}>
        <h1>Oh, no!</h1>
        <p className='mt-32 mb-60'>Something went wrong, please contact Admin.</p>
        <Button onClick={() => navigate('/')} label='Go to Home Page' size='big' variant='primary' />
      </div>
    </div>
  );
};

export default ErrorPage;
