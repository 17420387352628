import React, { lazy } from 'react';
import { LazyRouteFunction, RouteObject } from 'react-router-dom';

import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';

import withSuspense from './withSuspense/withSuspence';

/**
 * This HoC intended to wrap pages that you want to lazy load with React Router V6.
 * But also it provides a way to show skeleton while static files are loading.
 *
 * @usage
 * ```tsx
 * {
 *   path: Routes.PrivacyPolicy,
 *   lazy: asLazyPage(() => import('./pages/PrivacyPolicyPage')),
 * }
 * ```
 * or with skeleton:
 * ```tsx
 * {
 *   path: Routes.PrivacyPolicy,
 *   lazy: asLazyPage(() => import('./pages/PrivacyPolicyPage'), PrivacyPolicyPageSkeleton),
 * }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function asLazyPage<T extends React.FC<any>>(
  factory: () => Promise<{ default: T }>,
  Skeleton?: React.FC | Promise<{ default: React.FC }>
): LazyRouteFunction<RouteObject> {
  if (Skeleton instanceof Promise) {
    // Promised skeleton wrapped as lazy just to prevent React errors,
    // but actually it will be loaded at the moment when router is defined
    const ResolvedSkeleton = withSuspense(lazy(() => Skeleton));

    return () => {
      const Component = withSuspense(lazy(factory), <ResolvedSkeleton />);

      return Promise.resolve({ Component: Component, errorElement: <ExceptionHandler /> });
    };
  }

  return () => {
    const Component = withSuspense(lazy(factory), Skeleton ? <Skeleton /> : undefined);

    return Promise.resolve({ Component: Component, errorElement: <ExceptionHandler /> });
  };
}
