import { gql } from '@apollo/client';

import { FloorUseTypes } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import { GetFloorsForSiteQuery, GetFloorsForSiteQueryVariables } from './__generated__/getFloorsForSite.resources.gql';

export type SiteFloor = GetFloorsForSiteQuery['getFloorsForSite'][0];

const GET_FLOORS_FOR_SITE = gql`
  query GetFloorsForSite($siteId: Int!, $only: FloorUseTypes) {
    getFloorsForSite(siteId: $siteId, only: $only) {
      floor
      type
    }
  }
`;

export async function getFloorsForSite(siteId: number, only?: FloorUseTypes): Promise<SiteFloor[]> {
  const result = await resourcesAPI.query<GetFloorsForSiteQuery, GetFloorsForSiteQueryVariables>({
    query: GET_FLOORS_FOR_SITE,
    variables: {
      siteId,
      only,
    },
  });

  return result.data.getFloorsForSite;
}
