import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetMeterConsumptionBySiteQueryVariables = Types.Exact<{
  siteIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  type: Types.SystemType;
}>;

export type GetMeterConsumptionBySiteQuery = {
  getMeterConsumptionBySite: Array<{
    siteId: number;
    count: number;
    consumptionForPreviousMonth?: number | null;
    consumptionForCurrentMonth?: number | null;
    projConsumptionForCurrentMonth?: number | null;
    changeFromPreviousMonth?: number | null;
  }>;
};

export const GetMeterConsumptionBySiteDocument = gql`
  query GetMeterConsumptionBySite($siteIds: [Int!]!, $type: SystemType!) {
    getMeterConsumptionBySite(siteIds: $siteIds, type: $type) {
      siteId
      count
      consumptionForPreviousMonth
      consumptionForCurrentMonth
      projConsumptionForCurrentMonth
      changeFromPreviousMonth
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetMeterConsumptionBySite(
      variables: GetMeterConsumptionBySiteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetMeterConsumptionBySiteQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMeterConsumptionBySiteQuery>(GetMeterConsumptionBySiteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetMeterConsumptionBySite',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
