import _ from 'lodash';
import { useEffect } from 'react';
import { useMatches } from 'react-router-dom';

import { useUrlToProtectedFile } from 'src/cdk/hooks/useUrlToProtectedFile';
import { downloadByUrl } from 'src/core/service/downloadData';
import { Button } from 'src/shared/components/Button/Button';
import { CircularLoader } from 'src/shared/components/Loader/Loader';

const DownloadReportPage: React.FC = () => {
  const routes = useMatches();
  const filePath = _.last(routes)?.pathname.replace(routes[routes.length - 2].pathname, '');
  const fileName = filePath?.split('/').pop();

  const { isFailed, isLoading, file } = useUrlToProtectedFile(filePath);

  useEffect(() => {
    if (file && fileName) {
      downloadByUrl(file.url, fileName);
    }
  }, [file]);

  if (isFailed) {
    throw 'Failed to download report';
  }

  return (
    <div
      className='d-flex align-items-center justify-content-center flex-col text-center px-8 py-16 color-tertiary'
      style={{
        height: '60vh',
      }}
    >
      {isLoading || file?.url ? (
        <>
          <p className='body-semi-bold mb-4'>Report will automatically download within a few seconds.</p>
          <p className='mb-4'>If not, please click the button below.</p>
        </>
      ) : null}
      {isLoading ? (
        <CircularLoader />
      ) : file?.url ? (
        <Button
          variant='primary'
          label='Download'
          onClick={() => {
            downloadByUrl(file.url, fileName || 'Unknown', true);
          }}
        />
      ) : (
        <p>File was not found</p>
      )}
    </div>
  );
};

export default DownloadReportPage;
