import { createContext, useContext, useEffect } from 'react';

export interface PageMetadata {
  title?: string;
  subtitle?: string;
}

interface PageMetadataContext {
  setMetadata: (metadata: PageMetadata) => void;
}

const PageContext = createContext<PageMetadataContext | null>(null);

/**
 * Hook to update page title and subtitle from descendant components & routes
 */
export function usePageMetadata(title?: string, subtitle?: string): void {
  const context = useContext(PageContext);

  useEffect(() => {
    if (context && (title || subtitle)) {
      context.setMetadata({ title, subtitle });
    }
  }, [title, subtitle]);
}

export const PageMetadataContextProvider = PageContext.Provider;
