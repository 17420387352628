import { BroadcastChannel } from 'broadcast-channel';

import store from 'src/core/store/store';

import { setUnresolvedNotifications } from './alertsSlice';
import { UnresolvedNotification } from './interface';

export class SyncNotificationsService {
  private channel = new BroadcastChannel('syncNotifications');

  constructor() {
    this.channel.onmessage = (data: UnresolvedNotification[]) => {
      store.dispatch(setUnresolvedNotifications(data));
    };
  }

  sync(data: UnresolvedNotification[]): void {
    this.channel.postMessage(data);
  }
}

export default new SyncNotificationsService();
