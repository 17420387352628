import { FieldPolicy } from '@apollo/client';

/**
 * If that type policy is applied to specific field - that field will be stored as Date inside of Cache object.
 * Transforms custom Scalar type DateTime, which is passed as ISO string to Date object.
 * It is expected that income is passed as UTC date-time string.
 */
export const datetimeTypePolicy: FieldPolicy<Date | null, string | null, Date | null> = {
  merge(_existing, incoming) {
    if (typeof incoming === 'string' || typeof incoming === 'number') {
      return new Date(incoming);
    }
    return incoming;
  },
};

/**
 * Same as above, but forces datetime to display values from UTC timezone, even if user is in different timezone
 *
 * @example
 * > '2021-08-31T00:00:00.000Z'
 * < 'Tue Aug 31 2021 00:00:00 GMT-0400' - Date object
 * 'GMT-0400' - is timezone of the user
 *
 * @note
 * So after formatting on UI user will always see the value which was recieved from API.
 * Such behaviour is important for date-only fields, because they are not timezone dependent,
 * but JS Date object is ALWAYS timezone dependent & ALWAYS includes time value.
 *
 * @note
 * This type of conversion should be used only when we only READ values, ex. in Tables
 * if we will send them to backend later - there is a risk that value will be shifted to -1 day..
 * because:
 *
 * @example
 * > new Date("Tue Aug 31 2021 00:00:00 GMT+0400").toISOString()
 * < '2021-08-30T20:00:00.000Z'
 *
 * TODO: find another way to display date to the user ignoring timezone,
 * but still have an ability to edit it via datepicker without reworking the whole app
 */
export const dateTypePolicy: FieldPolicy<Date | null, string | null, Date | null> = {
  merge(_existing, incoming) {
    if (typeof incoming === 'string') {
      if (incoming.includes('Z')) {
        // if date is passed as ISO string
        // remove Z from iso date string, so JS will automatically add timezone of the user
        return new Date(incoming.replace('Z', ''));
      } else if (incoming.length === 10) {
        // if date is passed as YYYY-MM-DD
        // Add time to the date, so JS will automatically add timezone of the user
        return new Date(incoming + 'T00:00:00');
      } else if (incoming.length === 7) {
        // if date is passed as YYYY-MM
        // Add date and time to the date, so JS will automatically add timezone of the user
        return new Date(incoming + '-01T00:00:00');
      } else {
        throw new Error(`Date string ${incoming} has invalid format`);
      }
    }
    return incoming;
  },
};
