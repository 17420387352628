import { keys, sortBy } from 'lodash';

import { FloorType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { FloorTypeToLabel, SystemSequence } from 'src/enums';

export function isDRTypeSequence(sequenceId: number | SystemSequence): boolean {
  return sequenceId === SystemSequence.DemandResponse || sequenceId === SystemSequence.DemandResponsePreEvent;
}

export const FloorTypeToOrder: Record<FloorType, number> = {
  [FloorType.ROOF]: 1,
  [FloorType.NUMBER]: 2,
  [FloorType.MEZZANINE]: 3,
  [FloorType.LOBBY]: 4,
  [FloorType.SUBCELLAR]: 5,
  [FloorType.BASEMENT]: 6,
  [FloorType.CELLAR]: 7,
  [FloorType.NO_FLOOR]: 8,
};

export const floorTypeSelectOptions = sortBy(keys(FloorType), (key) => FloorTypeToOrder[key as FloorType], ['asc']).map(
  (key) => ({ key, displayValue: FloorTypeToLabel[key as FloorType] })
);
