import type * as Types from '../../../../core/apollo/__generated__/utilityGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetDRMeterOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetDRMeterOptionsQuery = {
  getDRMeters: Array<{
    id: number;
    meterId?: string | null;
    name?: string | null;
    siteId: number;
    aggregator?: Types.DRAggregator | null;
  }>;
};

export const GetDRMeterOptionsDocument = gql`
  query GetDRMeterOptions {
    getDRMeters {
      id
      meterId
      name
      siteId
      aggregator
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetDRMeterOptions(
      variables?: GetDRMeterOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetDRMeterOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDRMeterOptionsQuery>(GetDRMeterOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetDRMeterOptions',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
