import { Navigate, RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import Guard from 'src/fragments/layouts/guard/Guard';

const router: RouteObject[] = [
  {
    path: '/integrations',
    element: <Guard roles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]} />,
    errorElement: <ExceptionHandler />,
    children: [
      {
        index: true,
        lazy: asLazyPage(() => import('./page')),
      },
      {
        path: 'coned/*',
        lazy: asLazyPage(() => import('./coned/page')),
      },
      {
        path: 'charge_point/*',
        lazy: asLazyPage(() => import('./charging-point/page')),
      },
      {
        path: 'energy_star_portfolio/*',
        lazy: asLazyPage(() => import('./energy-star-portfolio/page')),
      },
      {
        path: 'utility_api',
        children: [
          {
            // TODO: check on dev env that referral is working as expected
            path: 'flow',
            lazy: asLazyPage(() => import('./utility-api/flow/page')),
          },
          {
            path: '*',
            index: true,
            lazy: asLazyPage(() => import('./utility-api/page')),
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to='/integrations' />,
      },
    ],
  },
];

export default router;
