import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { FloorUseTypes, FloorType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import Logger from 'src/core/service/logger';
import { SelectWithSearchAndForm, OptionItem } from 'src/shared/components/Select';

import { SiteFloor, getFloorsForSite } from '../../gql/getFloorsForSite.resources.gql';
import { FloorTypeToOrder, floorTypeSelectOptions } from '../../utils/system.utils';

import FloorDetailsForm from './FloorDetailsForm';

interface Props {
  siteId: number | undefined;
  deviceType?: FloorUseTypes;
  selectedType: FloorType | undefined;
  selectedFloor: string | undefined;
  className: string;
  required?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  onChange(floor: SiteFloor): void;
}
/**
 * This method was copied from backend from - resources-ms//systems.service - getSystemsBySites()
 * The method was adapted for the current logic.
 * If there are any changes in the algorithm. it should be implemented on the backend also.
 */
function sortFloorOptions(options: OptionItem<SiteFloor>[]) {
  return orderBy(
    options,
    [
      ({ key }) => FloorTypeToOrder[key.type],
      ({ key }) => key.type !== FloorType.NUMBER && key.floor,
      ({ key }) => key.type === FloorType.NUMBER && Number(key.floor),
    ],
    ['asc', 'asc', 'desc']
  );
}

const FloorSelector: React.FC<Props> = ({
  siteId: siteid,
  deviceType,
  selectedType,
  selectedFloor,
  onChange,
  className,
  required = false,
  hasError,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [floorOptions, setFloorOptions] = useState<OptionItem<SiteFloor>[]>([]);
  const [systemFloor, setSystemFloor] = useState<SiteFloor | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    if (!siteid) {
      return;
    }
    getFloorsForSite(siteid, deviceType)
      .then((floors) => {
        const floorOptions: OptionItem<SiteFloor>[] = floors.map((floor) => ({
          key: floor,
          displayValue: floor.floor,
        }));
        setFloorOptions(sortFloorOptions(floorOptions));
        if (selectedType && selectedFloor) {
          setSystemFloor(
            floorOptions.find(({ key: { type, floor } }) => type === selectedType && selectedFloor === floor)?.key
          );
        }
        setIsLoading(false);
      })
      .catch(() => Logger.error('Failed to load floors'));
  }, [siteid]);

  useEffect(() => {
    if (selectedType && selectedFloor) {
      setSystemFloor(
        floorOptions.find(({ key: { type, floor } }) => type === selectedType && selectedFloor === floor)?.key
      );
    } else {
      setSystemFloor(undefined);
    }
  }, [selectedType, selectedFloor]);

  useEffect(() => {
    if (!floorOptions || !systemFloor) {
      return;
    }
    if (systemFloor.type === selectedType && systemFloor.floor === selectedFloor) {
      return;
    } else {
      onChange(systemFloor);
    }
  }, [systemFloor]);

  function addFloor(floor: SiteFloor) {
    const options = sortFloorOptions([...floorOptions, { key: floor, displayValue: floor.floor }]);
    setFloorOptions(options);
  }

  return (
    <SelectWithSearchAndForm
      id='floor'
      className={className}
      onClick={(value) => {
        setSystemFloor(value);
      }}
      options={floorOptions}
      disabledWhenFewerOptions={false}
      useRenderInSelectedField={false}
      value={systemFloor}
      required={required}
      hasError={hasError}
      markAsLoading={isLoading}
      disabled={disabled || isLoading}
      entityName='Floor'
      onPut={async (formData) => {
        const newFloor: SiteFloor = {
          floor: formData['floorName'],
          type:
            (floorTypeSelectOptions.find((i) => i.displayValue === formData['floorType'])?.key as FloorType) ??
            FloorType.NUMBER,
        };
        addFloor(newFloor);
        setSystemFloor(newFloor);
      }}
      FormContent={({ searchValue, option, onChange }) => {
        return (
          <FloorDetailsForm
            value={{
              floor: option?.key?.floor ?? searchValue,
              type: option?.key?.type,
            }}
            onChange={onChange}
          />
        );
      }}
    />
  );
};

export default FloorSelector;
