import { AxisLeft, AxisRight } from '@visx/axis';
import { Text } from '@visx/text';

import { numberInAbbrFormat } from 'src/cdk/formatter/numberFormatter';

import styles from './ComposableChartXYAxis.module.scss';
import { useChartContext } from './chartContext';

interface Props {
  /**
   * If true - axis will be rendered on the right side of the chart
   * @default false
   */
  right?: boolean;

  /**
   * If provided - will use yScale with this domain
   */
  yDomain?: number[];
  label: string | undefined;
}

/**
 * This is default value from @visx/axis / d3
 */
const SPACING_BETWEEN_AXIS_AND_CHART = 10;

const ComposableChartYAxis: React.FC<Props> = ({ right, yDomain, label = '' }) => {
  const { xMax, yScale, padding } = useChartContext(yDomain);

  if (right) {
    return (
      <>
        <Text
          verticalAnchor='start'
          textAnchor='start'
          x={xMax + padding.left + SPACING_BETWEEN_AXIS_AND_CHART}
          y={0}
          className={styles['label']}
        >
          {label}
        </Text>
        <AxisRight
          top={padding.top}
          left={xMax + padding.left}
          scale={yScale}
          hideAxisLine
          hideTicks
          tickClassName={styles['y-axis']}
          tickFormat={numberInAbbrFormat}
        />
      </>
    );
  }

  return (
    <>
      <Text
        verticalAnchor='start'
        textAnchor='end'
        x={padding.left - SPACING_BETWEEN_AXIS_AND_CHART}
        y={0}
        className={styles['label']}
      >
        {label}
      </Text>
      <AxisLeft
        top={padding.top}
        left={padding.left}
        scale={yScale}
        hideAxisLine
        hideTicks
        tickClassName={styles['y-axis']}
        tickFormat={numberInAbbrFormat}
      />
    </>
  );
};

export default ComposableChartYAxis;
