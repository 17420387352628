import { Navigate, RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { AuditLogResourceType } from 'src/core/apollo/__generated__/logsGlobalTypes';
import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import LogsPage from 'src/materials/logs/pages/LogsPage';
import { Routes } from 'src/routes';
import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';

import SystemPage from './[id]/general/page';
import { ChargingStationOverviewPerSiteLayout } from './charging-station/[:siteId]/layout';
import SystemGroupsPage from './group/[id]/general/page';
import SystemGroupDetailsPage from './group/page';
import SystemDetailsPage from './page';

const DETAILS_PAGE_SETTINGS: Partial<Record<SystemType, [Routes, string[]]>> = {
  [SystemType.STEAM_PRV_STATION]: [Routes.PrvSystems, ['PRV Setpoint']],
  [SystemType.ST_BOILER_F_HT_MPC]: [Routes.BoilersSystems, []],
  [SystemType.HW_BOILER_HT_HWR]: [Routes.BoilersSystems, []],
  [SystemType.HW_BOILER_TEKMAR_284]: [Routes.BoilersSystems, ['Boiler Pump']],
  [SystemType.PACKAGE_ALTC24PROG]: [Routes.PackagedSystems, []],
  [SystemType.PACKAGE_HONEYWELL_TC500AN]: [Routes.PackagedSystems, []],
  [SystemType.WEATHER_STATION_WTS506]: [Routes.WeatherSystems, []],
  [SystemType.LUTRON_VIVE_LIGHTING]: [Routes.LightingSystems, ['General', 'Trends', 'Activity']],
  [SystemType.SMART_OUTLET_T0006623]: [Routes.SmartOutletSystems, ['General', 'Trends', 'Activity']],
};

const router: RouteObject[] = [
  {
    path: 'systems',
    errorElement: <ExceptionHandler />,
    children: [
      { path: 'prv', lazy: asLazyPage(() => import('src/modules/systems/pages/PRVSystemsPage')) },
      { path: 'weather', lazy: asLazyPage(() => import('src/modules/systems/pages/WeatherSystemsPage')) },
      { path: 'boilers', lazy: asLazyPage(() => import('src/modules/systems/pages/BoilersSystemsPage')) },
      { path: 'ahu', lazy: asLazyPage(() => import('src/modules/systems/pages/PackagedSystemsPage')) },
      { path: 'outlet', lazy: asLazyPage(() => import('src/modules/systems/pages/SmartOutletSystemsPage')) },

      {
        path: 'lighting',
        children: [
          { index: true, lazy: asLazyPage(() => import('./lighting/page')) },
          { path: ':siteId/*', lazy: asLazyPage(() => import('./lighting/[:siteId]/page')) },
        ],
      },

      {
        path: 'charging-station',
        children: [
          {
            index: true,
            lazy: asLazyPage(() => import('./charging-station/page')),
          },
          {
            path: ':siteId',
            element: <ChargingStationOverviewPerSiteLayout />,
            children: [
              {
                path: 'dashboard',
                lazy: asLazyPage(() => import('./charging-station/[:siteId]/dashboard/page')),
              },
              {
                path: 'insights',
                lazy: asLazyPage(() => import('./charging-station/[:siteId]/insights/page')),
              },
              {
                path: 'trend',
                lazy: asLazyPage(() => import('./charging-station/[:siteId]/trend/page')),
              },
              {
                path: 'heatmap',
                lazy: asLazyPage(() => import('./charging-station/[:siteId]/heatmap/page')),
              },
              {
                path: 'schedule',
                element: <ElevatedBox>Not Implemented Yet</ElevatedBox>,
              },
              {
                path: 'activity',
                element: <ElevatedBox>Not Implemented Yet</ElevatedBox>,
                lazy: asLazyPage(() => import('./charging-station/[:siteId]/activity/page')),
              },
              {
                path: 'stations',
                lazy: asLazyPage(() => import('./charging-station/[:siteId]/stations/page')),
              },
              {
                path: '*',
                element: <Navigate to='dashboard' />,
              },
            ],
          },
        ],
      },

      {
        path: 'groups/:id',
        element: <SystemGroupDetailsPage settings={DETAILS_PAGE_SETTINGS} />,
        children: [
          {
            path: 'general',
            // TODO: to make it as lazy - all children should be properly lazy as well, due to CSS modules order definition issue
            element: <SystemGroupsPage />,
            // lazy: asLazyPage(() => import('./group/[id]/general/page')),
          },
          {
            path: 'trends',
            lazy: asLazyPage(() => import('./group/[id]/trends/page')),
          },
          {
            path: 'activity',
            lazy: asLazyPage(() => import('./group/[id]/activity/page')),
          },
          {
            path: '*',
            element: <Navigate to='general' />,
          },
        ],
      },

      {
        path: ':id',
        element: <SystemDetailsPage settings={DETAILS_PAGE_SETTINGS} />,
        children: [
          {
            path: 'general',
            // TODO: to make it as lazy - all children should be properly lazy as well, due to CSS modules order definition issue
            element: <SystemPage />,
            // lazy: asLazyPage(() => import('./[id]/general/page')),
          },
          {
            path: 'prv-setpoint',
            lazy: asLazyPage(() => import('./[id]/prv-setpoint/page')),
          },
          {
            path: 'boiler-pump',
            lazy: asLazyPage(() => import('./[id]/boiler-pump/page')),
          },
          {
            path: 'space-temperature',
            lazy: asLazyPage(() => import('./[id]/space-temperature/page')),
          },
          {
            path: 'schedule',
            // TODO: add guard
            lazy: asLazyPage(() => import('./[id]/schedule/page')),
          },
          {
            path: 'activity',
            element: <LogsPage resourceIdParamName='id' resourceType={AuditLogResourceType.SYSTEM} />,
          },
          {
            path: '*',
            element: <Navigate to='general' />,
          },
        ],
      },
    ],
  },
];

export default router;
