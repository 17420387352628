import _ from 'lodash';
import { useSelector } from 'react-redux';

import { FilterValues } from '../filter.interface';
import { selectFiltersForPage } from '../filtersSlice';

// TODO: Add pagination support

/**
 * Hook to get current filter values for current page.
 */
export function useFilterValues<T extends FilterValues>({ storageKey }: { storageKey: string }): { filterValues: T } {
  const filterValues = useSelector(selectFiltersForPage<T>(storageKey), _.isEqual);

  return { filterValues };
}
