import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { format, isSameDay } from 'date-fns';
import _, { NumericDictionary } from 'lodash';

import { localDateToUTCString, UTCStringToLocalDate } from 'src/cdk/utils/datetimeToDate';
import { DATE_FORMAT, WEEKDAYS } from 'src/constants';
import { SchedulerProfileDateConfiguration, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import type { RootState } from 'src/core/store/store';
import { LoadingState } from 'src/enums';
import { ISchedulerListItem } from 'src/interfaces';
import { CheckboxGroupItemProps } from 'src/shared/components/CheckboxGroup/CheckboxGroup';
import { OptionItem } from 'src/shared/components/Select/interface';
import { systemTypeOptions } from 'src/shared/utils/system.utils';

import {
  SchedulerProfile,
  SchedulerProfileSystemsConfiguration,
  SchedulerProfileSystemsSetup,
  SchedulerProfileWithOrder,
} from './gql/getScheduler.resources.gql';
import { SystemModel, SystemsBasicInfo } from './gql/getSystemsBasicInfo.resources.gql';
import { cutExistingInteraction } from './scheduleCutter';

const systemsAdapter = createEntityAdapter<SystemsBasicInfo>();

export interface ISchedulerSlice {
  // TODO: replace with adapter
  schedulerListMap: NumericDictionary<ISchedulerListItem>;
  schedulerId: number | null;
  schedulerData: Partial<Omit<SchedulerProfileWithOrder, 'siteId'>>;
  schedulerSystemsConfig: NumericDictionary<SchedulerProfileSystemsSetup>;
  originalData: {
    schedulerId: number | null;
    schedulerData: Partial<Omit<SchedulerProfileWithOrder, 'siteId'>>;
    schedulerSystemsConfig: NumericDictionary<SchedulerProfileSystemsSetup>;
  };
  status: LoadingState;
  systems: EntityState<SystemsBasicInfo>;
  systemTypeOptions: OptionItem<SystemType, OptionItem<string, SystemModel>[]>[];
}

const initialState: ISchedulerSlice = {
  status: LoadingState.WAITING,
  schedulerListMap: {},
  schedulerId: null,
  schedulerData: {
    dateConfiguration: SchedulerProfileDateConfiguration.MONTHS,
    isEditable: true,
  },
  schedulerSystemsConfig: {},
  originalData: {
    schedulerId: null,
    schedulerData: {
      dateConfiguration: SchedulerProfileDateConfiguration.MONTHS,
    },
    schedulerSystemsConfig: {},
  },
  systems: systemsAdapter.getInitialState(),
  systemTypeOptions: [],
};

export const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState: initialState,
  reducers: {
    resetSlice: () => initialState,
    setLoading: (state) => {
      state.status = LoadingState.LOADING;
    },
    setFailed: (state) => {
      state.status = LoadingState.FAILED;
    },
    setSchedulerList: (state, action: PayloadAction<ISchedulerListItem[]>) => {
      state.schedulerListMap = _.keyBy(action.payload, 'id');
    },
    deleteSchedulerFromList: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      delete state.schedulerListMap[id];
      if (state.schedulerId === id) {
        state.schedulerId = null;
        state.schedulerData = {
          name: '',
          dateConfiguration: SchedulerProfileDateConfiguration.MONTHS,
          selectedMoments: [],
          systemsConfiguration: [],
        };
        state.schedulerSystemsConfig = {};
      }
    },
    resetScheduler: (state) => {
      state.schedulerId = null;
      state.schedulerData = {
        name: '',
        dateConfiguration: SchedulerProfileDateConfiguration.MONTHS,
        selectedMoments: [],
        systemsConfiguration: [],
        isEditable: true,
      };
      state.schedulerSystemsConfig = {};
      state.originalData = {
        schedulerId: null,
        schedulerData: {
          name: '',
          dateConfiguration: SchedulerProfileDateConfiguration.MONTHS,
          selectedMoments: [],
          systemsConfiguration: [],
          isEditable: true,
        },
        schedulerSystemsConfig: {},
      };
    },
    setScheduler: (state, action: PayloadAction<[number, SchedulerProfile]>) => {
      const [id, data] = action.payload;
      state.status = LoadingState.WAITING;
      state.schedulerId = id;
      state.schedulerData = data;
      state.schedulerSystemsConfig = Object.fromEntries(_.entries(data.systemsConfiguration));
      state.originalData = {
        schedulerId: id,
        schedulerData: _.cloneDeep(data),
        schedulerSystemsConfig: _.cloneDeep(state.schedulerSystemsConfig),
      };
    },
    resetSchedulerChanges: (state) => {
      state.status = LoadingState.WAITING;
      state.schedulerId = _.cloneDeep(state.originalData.schedulerId);
      state.schedulerData = _.cloneDeep(state.originalData.schedulerData);
      state.schedulerSystemsConfig = _.cloneDeep(state.originalData.schedulerSystemsConfig);
    },
    setSchedulerName: (state, action: PayloadAction<string>) => {
      state.schedulerData.name = action.payload;
    },
    setSchedulerDateConfig: (state, action: PayloadAction<SchedulerProfileDateConfiguration>) => {
      state.schedulerData.dateConfiguration = action.payload;
      state.schedulerData.selectedMoments = [];
      state.schedulerSystemsConfig = _.mapValues(state.schedulerSystemsConfig, (value) => ({
        ...value,
        configurations: [],
      }));
    },
    setSchedulerDates: (state, action: PayloadAction<Date[]>) => {
      state.schedulerData.selectedMoments = action.payload.map((date) => localDateToUTCString(date));
      state.schedulerSystemsConfig = _.mapValues(state.schedulerSystemsConfig, (value) => ({
        ...value,
        configurations: value.configurations.filter((config) =>
          action.payload.some((date) => config.date && isSameDay(config.date, date))
        ),
      }));
    },
    setSchedulerMonths: (state, action: PayloadAction<string[]>) => {
      state.schedulerData.selectedMoments = action.payload;
    },
    addSystemsConfiguration: (state, action: PayloadAction<SchedulerProfileSystemsSetup>) => {
      const maxKey = _.max<number>(_.keys(state.schedulerSystemsConfig).map(Number)) || 0;
      state.schedulerSystemsConfig[maxKey + 1] = action.payload;
    },
    updateSystemsConfiguration: (state, action: PayloadAction<[number, number[], string]>) => {
      const [key, data, systemsGroupName] = action.payload;
      state.schedulerSystemsConfig[key].systemIds = data;
      state.schedulerSystemsConfig[key].systemsGroupName = systemsGroupName;
    },
    deleteSystemConfiguration: (state, action: PayloadAction<number>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload]: _, ...rest } = state.schedulerSystemsConfig;
      state.schedulerSystemsConfig = rest;
    },
    updateScheduleForSpecificRange: (state, action: PayloadAction<[number, SchedulerProfileSystemsConfiguration]>) => {
      const [key, data] = action.payload;

      // Cut existing configs by time if config from payload has intersections
      state.schedulerSystemsConfig[key].configurations.forEach((config) => {
        const isSameMoment =
          (data.date && config.date && isSameDay(config.date, data.date)) ||
          (!_.isUndefined(data.dayWeek) && data.dayWeek === config.dayWeek);
        if (isSameMoment) {
          [config.fromHour, config.toHour] = cutExistingInteraction(
            [config.fromHour, config.toHour],
            [data.fromHour, data.toHour]
          );
        }
      });

      // Remove all non valid configurations
      state.schedulerSystemsConfig[key].configurations = _.filter(
        state.schedulerSystemsConfig[key].configurations,
        (value) => value.fromHour < value.toHour
      );

      // Push updated or new config
      state.schedulerSystemsConfig[key].configurations.push({
        ...data,
      });
    },
    deleteScheduleForSpecificRange: (state, action: PayloadAction<[number, SchedulerProfileSystemsConfiguration]>) => {
      const [key, config] = action.payload;
      _.remove(state.schedulerSystemsConfig[key].configurations, {
        fromHour: config.fromHour,
        toHour: config.toHour,
        date: config.date,
        dayWeek: config.dayWeek,
      });
    },
    copyScheduleConfig: (state, action: PayloadAction<[number, number | Date, Array<number | Date>]>) => {
      const [key, source, dist] = action.payload;
      const isMonthConfiguration = state.schedulerData.dateConfiguration === SchedulerProfileDateConfiguration.MONTHS;

      // Cleanup dist configurations
      state.schedulerSystemsConfig[key].configurations = _.filter(
        state.schedulerSystemsConfig[key].configurations,
        (config) => {
          if (state.schedulerData.dateConfiguration === SchedulerProfileDateConfiguration.DAYS) {
            return !dist.some((date) => config.date && isSameDay(config.date, date));
          }
          if (state.schedulerData.dateConfiguration === SchedulerProfileDateConfiguration.MONTHS) {
            return !dist.some((day) => !_.isUndefined(config.dayWeek) && config.dayWeek === day);
          }
          return true;
        }
      );

      //Extract source configs
      const sourceConfigs = _.filter(state.schedulerSystemsConfig[key].configurations, (config) => {
        return (
          (source && config.date && !isMonthConfiguration && isSameDay(config.date, source)) ||
          source === config.dayWeek
        );
      });

      // Copy source configs to dist with date substitution
      for (const config of sourceConfigs) {
        for (const distMoment of dist) {
          state.schedulerSystemsConfig[key].configurations.push({
            ...config,
            date: !_.isUndefined(config.date) && !isMonthConfiguration ? (distMoment as Date) : null,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dayWeek: !_.isUndefined(config.dayWeek) && isMonthConfiguration ? (distMoment as number) : (null as any),
          });
        }
      }
    },
    duplicateScheduler: (state) => {
      state.schedulerId = null;
      state.schedulerData.name = state.schedulerData.name;
      state.schedulerData.isEditable = true;
      state.schedulerSystemsConfig = _.mapValues(state.schedulerSystemsConfig, (value) => ({
        ...value,
        systemIds: [],
      }));
    },
    setSiteSystems: (state, action: PayloadAction<SystemsBasicInfo[]>) => {
      systemsAdapter.setAll(state.systems, action.payload);

      // Filter out system types that don't have any systems within current site
      // Also, keep model options for each type
      state.systemTypeOptions = systemTypeOptions.map((option) => {
        const systemsWithOptionType = action.payload.filter((system) => system.type === option.key);
        const systemModels = _.uniqBy(systemsWithOptionType, (i) => _.get(i, 'model.name'))
          .map(
            (system) =>
              ({
                key: _.get(system, 'model.name'),
                displayValue: _.get(system, 'model.name', ''),
                config: _.get(system, 'model'),
              }) as unknown as OptionItem<string, SystemModel>
          )
          .filter((option) => option.key);

        return {
          ...option,
          hidden: _.isEmpty(systemsWithOptionType),
          config: systemModels,
        };
      });
    },
  },
});

export const {
  resetSlice: resetSchedulerSlice,
  setLoading,
  setFailed,
  setSchedulerList,
  setSiteSystems,
  deleteSchedulerFromList,
  resetScheduler,
  setScheduler,
  resetSchedulerChanges,
  setSchedulerName,
  setSchedulerDateConfig,
  setSchedulerDates,
  setSchedulerMonths,
  addSystemsConfiguration,
  updateSystemsConfiguration,
  deleteSystemConfiguration,
  updateScheduleForSpecificRange,
  deleteScheduleForSpecificRange,
  copyScheduleConfig,
  duplicateScheduler,
} = schedulerSlice.actions;

export const selectSchedulerList = (state: RootState): ISchedulerListItem[] =>
  _.values(state.scheduler.schedulerListMap);

export const selectCurrentSchedulerId = (state: RootState): number | null => state.scheduler.schedulerId;

export const selectIsSchedulerChanged = (state: RootState): boolean =>
  !_.isEqual(state.scheduler.originalData.schedulerId, state.scheduler.schedulerId) ||
  !_.isEqual(state.scheduler.originalData.schedulerData, state.scheduler.schedulerData) ||
  !_.isEqual(state.scheduler.originalData.schedulerSystemsConfig, state.scheduler.schedulerSystemsConfig);

export const selectLoadingStatus = (state: RootState): LoadingState => state.scheduler.status;

export const selectSchedulerData = (state: RootState): Partial<SchedulerProfile> => state.scheduler.schedulerData;

export const selectSchedulerMoments = (state: RootState): string[] | Date[] =>
  state.scheduler.schedulerData.dateConfiguration === SchedulerProfileDateConfiguration.DAYS
    ? state.scheduler.schedulerData.selectedMoments?.map((item) => UTCStringToLocalDate(item)) ?? []
    : state.scheduler.schedulerData.selectedMoments ?? [];

export const selectSchedulerMomentsForTimeline = (
  state: RootState
): { displayValue: string; value: number | Date; displayDayValue?: string }[] =>
  state.scheduler.schedulerData.dateConfiguration === SchedulerProfileDateConfiguration.DAYS
    ? state.scheduler.schedulerData.selectedMoments?.map((item) => ({
        value: UTCStringToLocalDate(item),
        displayValue: format(UTCStringToLocalDate(item), DATE_FORMAT.WEEKDAY_MEDIUM),
        displayDayValue: format(UTCStringToLocalDate(item), DATE_FORMAT.DATE_FULL),
      })) ?? []
    : WEEKDAYS.map((item, i) => ({
        value: i,
        displayValue: item.substring(0, 3),
      }));

export const selectSystemsConfig = (state: RootState): NumericDictionary<SchedulerProfileSystemsSetup> =>
  state.scheduler.schedulerSystemsConfig;

export const getSchedulerError = (state: RootState): string | undefined => {
  if (_.isEmpty(state.scheduler.schedulerData.name) || _.isEmpty(state.scheduler.schedulerData.selectedMoments)) {
    return 'Please fill out all required fields';
  }
  if (_.isEmpty(state.scheduler.schedulerSystemsConfig)) {
    return 'Please select at least 1 system';
  }
  if (
    _.some(state.scheduler.schedulerSystemsConfig, ({ systemIds, configurations }) => {
      return _.isEmpty(systemIds) || _.isEmpty(configurations);
    })
  ) {
    return 'Configuration can not be empty';
  }
};

export const selectSystemsAsCheckboxItems =
  (selectedType?: SystemType, selectedModel?: string, disabledSystemIds?: number[], preselectedSystemIds?: number[]) =>
  (state: RootState): CheckboxGroupItemProps<number>[] => {
    if (!selectedType) {
      return [];
    }

    const modelOptions = state.scheduler.systemTypeOptions.find((option) => option.key === selectedType)?.config ?? [];
    if (!selectedModel && modelOptions.length > 0) {
      return [];
    }

    const systems = systemsAdapter
      .getSelectors()
      .selectAll(state.scheduler.systems)
      .filter(
        (system) =>
          system.type === selectedType && (selectedModel ? _.get(system, 'model.name') === selectedModel : true)
      );

    const result = systems.map((system) => ({
      id: system.id,
      name: system.name,
      selected: preselectedSystemIds?.includes(system.id) ?? false,
      disabled: disabledSystemIds?.includes(system.id) ?? false,
    }));

    return result;
  };

export const selectModelBySystemId =
  (systemId?: number) =>
  (state: RootState): SystemModel | undefined => {
    if (!systemId) {
      return undefined;
    }

    const system = systemsAdapter.getSelectors().selectById(state.scheduler.systems, systemId);

    return _.get(system, 'model');
  };

export const selectSystemTypeOptions = (state: RootState): ISchedulerSlice['systemTypeOptions'] =>
  state.scheduler.systemTypeOptions;

export default schedulerSlice.reducer;
