import classNames from 'classnames';
import { format } from 'date-fns';
import _ from 'lodash';
import { CaptionProps, ClassNames, CustomComponents, useNavigation } from 'react-day-picker';

import { Button } from '../../Button/Button';

import styles from './base-date-picker-styles.module.scss';

function DatePickerNavbar(props: CaptionProps): React.JSX.Element {
  const { goToMonth, nextMonth, previousMonth, displayMonths } = useNavigation();

  const hasOneMonth = displayMonths.length === 1;

  return (
    <div className='d-flex justify-content-space-between align-items-center'>
      {hasOneMonth || _.isUndefined(props.displayIndex) || props.displayIndex === 0 ? (
        <Button
          className={styles['date-nav-picker-button']}
          icon='angle-left'
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
          shape='rect'
          type='button'
          variant='flat'
        />
      ) : (
        <div className={styles['date-nav-picker-button']} />
      )}
      <p className='default-text text-center'>{format(props.displayMonth, 'MMMM yyy')}</p>
      {hasOneMonth || _.isUndefined(props.displayIndex) || props.displayIndex === 1 ? (
        <Button
          className={styles['date-nav-picker-button']}
          icon='angle-right'
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
          shape='rect'
          type='button'
          variant='flat'
        />
      ) : (
        <div className={styles['date-nav-picker-button']} />
      )}
    </div>
  );
}

export const baseDatePickerClassNames: ClassNames = {
  root: '', // Reset default margin
  day: classNames('body-small color-primary', styles['day']),
  day_selected: styles['day-selected'],
  head_cell: 'body-small color-secondary py-8',
  day_disabled: styles['disabled-day'],
  day_outside: styles['outside-day'],
  day_range_end: styles['day-range-end'],
  day_range_start: styles['day-range-start'],
  day_range_middle: styles['day-range-middle'],
  button: styles['day-button'],
};

export const baseDatePickerComponents: CustomComponents = {
  Caption: DatePickerNavbar,
};
