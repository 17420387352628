import classNames from 'classnames';

import { GroupedList } from 'src/atoms/GroupedList/GroupedList';
import { N_A } from 'src/constants';
import { InfoTooltip } from 'src/shared/components/InfoTooltip/InfoTooltip';

interface Props<T = string> {
  entity: string;
  items?: T[];
  /**
   * @default right
   */
  iconPosition?: 'left' | 'right';
  tooltipItemRenderer?: (item: T) => React.ReactNode;
  dashIfEmpty?: boolean;
  displayValueIfSingle?: boolean;
  color?: 'secondary' | 'primary';
}

/**
 * If there are no items to display - whole element will be hidden
 */
export function CountWithInfoTooltip<T = string>({
  tooltipItemRenderer,
  entity,
  items,
  iconPosition = 'right',
  dashIfEmpty = false,
  displayValueIfSingle = false,
  color = 'secondary',
}: Props<T>) {
  if (!items?.length) {
    return dashIfEmpty ? <p className='body-small color-secondary'>{N_A}</p> : null;
  }

  const content = tooltipItemRenderer ? (
    items.map((i) => tooltipItemRenderer(i))
  ) : (
    <GroupedList content={items as string[]} />
  );

  if (displayValueIfSingle && items.length === 1) {
    return (
      <p
        className={classNames('body-small d-flex align-items-center gap-4', {
          'color-secondary': color === 'secondary',
          'color-primary': color === 'primary',
        })}
      >
        {content}
      </p>
    );
  }

  const icon = <InfoTooltip>{content}</InfoTooltip>;

  return (
    <p
      className={classNames('body-small d-flex align-items-center gap-4', {
        'color-secondary': color === 'secondary',
        'color-primary': color === 'primary',
      })}
    >
      {iconPosition === 'left' && icon}
      <span>
        {items.length} {items.length === 1 ? entity : entity + 's'}
      </span>
      {iconPosition === 'right' && icon}
    </p>
  );
}
