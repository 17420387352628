import _ from 'lodash';
import { createContext, useContext, useEffect, useMemo } from 'react';

import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { mapAsOptions } from 'src/cdk/mappers/mapAsOptions';
import { mapUnitToPrecision } from 'src/cdk/mappers/mapUnitToPrecision';
import { fillColorByIndex } from 'src/cdk/utils/fillColorByIndex';
import { METRIC_SYMBOL, N_A, UNIT } from 'src/constants';
import {
  MetricType,
  MetricsMeasurementType,
  UtilityServiceTypes,
} from 'src/core/apollo/__generated__/utilityGlobalTypes';
import { METRIC_TYPE_LABEL } from 'src/core/enum-labels';
import { useAppDispatch } from 'src/core/store/hooks';
import { ColorConfig } from 'src/enums';
import { resetFilterFields, setFilterFields } from 'src/modules/filters/filtersSlice';
import { useFilterValues } from 'src/modules/filters/public/useFilterValues';

import api from './gql';
import { GetMetricsQuery } from './gql/__generated__/data.utility.generated';

export type MeterMetric = GetMetricsQuery['getUtilityMeters'][0]['metrics'][0] & {
  meterId: number;
  meterUID: string;
  meterName: string;
  typeLabel: string;
  measurement: MetricsMeasurementType;
  type: MetricType;
  tooltipIcon: 'square' | 'none' | 'line';
  unit: UNIT;
  precision: number;
  styleVariant: ColorConfig;
};

export type Meter = GetMetricsQuery['getUtilityMeters'][0] & {
  tooltipIcon: 'square' | 'none' | 'line';
  unit: UNIT;
  precision: number;
  styleVariant: ColorConfig;
  metricType: MetricType;
  metrics: MeterMetric[];
};

type UtilityFilterValues = {
  meterIds: number[];
};

interface ContextData {
  meters: Meter[];
  filtersStorageKey: string;
  byId: Record<number, Meter>;
}

const MetricContext = createContext<ContextData | null>(null);

export function useUtilityMetersContextProvider(siteId: number, utilityType: UtilityServiceTypes) {
  const dispatch = useAppDispatch();
  const { isLoading, response: meters = [] } = useDataFetchOnMountWithDeps(
    () =>
      api
        .GetMetrics({
          siteId,
          utilityServiceType: utilityType,
        })
        .then((res) => {
          return res.getUtilityMeters.map((meter, index) => {
            const primary = meter.metrics.find((metric) => metric.isPrimary);
            const unit = primary ? METRIC_SYMBOL[primary.measurement] : UNIT.NUMBER;

            return {
              ...meter,
              metrics: meter.metrics.map((metric) => ({
                ...metric,
                meterName: meter.name ?? N_A,
                meterId: meter.meterId,
                meterUID: meter.meterUID,
                tooltipIcon: 'square',
                typeLabel: metric.type ? METRIC_TYPE_LABEL[metric.type] : N_A,
                unit: METRIC_SYMBOL[metric.measurement],
                precision: mapUnitToPrecision(METRIC_SYMBOL[metric.measurement]),
                styleVariant: fillColorByIndex(index),
              })),
              name: meter.name ?? N_A,
              metricType: primary?.type,
              tooltipIcon: 'square',
              unit,
              precision: mapUnitToPrecision(unit),
              styleVariant: fillColorByIndex(index),
            } as Meter;
          });
        }),
    [siteId, utilityType]
  );

  useEffect(() => {
    dispatch(resetFilterFields());
    dispatch(
      setFilterFields([
        {
          label: 'Meters',
          name: 'meterIds',
          options: mapAsOptions(meters, 'meterId', 'name'),
          type: 'multiselect',
        },
      ])
    );
  }, [meters]);

  const data = useMemo(() => {
    const contextData: ContextData = {
      meters: meters,
      filtersStorageKey: `utility-meters-${siteId}-${utilityType}`,
      // primaryMetrics: metrics.filter((m) => m.isPrimary),
      // primaryMetricsOptions: mapAsOptions(
      //   metrics.filter((m) => m.isPrimary),
      //   'id',
      //   'name'
      // ),
      byId: _.keyBy(meters, 'meterId'),
    };

    return contextData;
  }, [meters, siteId, utilityType]);

  return {
    isLoading,
    Provider: ProviderHOC(data),
  };
}

function ProviderHOC(contextData: ContextData): React.FC<React.PropsWithChildren> {
  return function UtilityMetricsContextProvider({ children }) {
    return <MetricContext.Provider value={contextData}>{children}</MetricContext.Provider>;
  };
}

export function useUtilityMetersContext() {
  const context = useContext(MetricContext);
  const { filterValues } = useFilterValues<UtilityFilterValues>({ storageKey: context!.filtersStorageKey });

  if (!context) {
    throw new Error('useUtilityMetrics must be used within a UtilityMetricsProvider');
  }

  const result = useMemo(() => {
    const { unit = UNIT.NUMBER, metricType, precision } = context.meters[0];
    const allMeterIds = context.meters.map((m) => m.meterId);
    const selectedMeterIds = filterValues.meterIds || allMeterIds;
    const selectedMeters = context.meters.filter((meter) => selectedMeterIds.includes(meter.meterId));

    return {
      filterValues: {
        meterIds: selectedMeterIds,
      } as UtilityFilterValues,
      filtersStorageKey: context.filtersStorageKey,
      meterOptions: mapAsOptions(selectedMeters, 'meterId', 'name'),
      metricTypesOptions: mapAsOptions(
        _.toPairs(
          _.groupBy(
            selectedMeters.flatMap((m) => m.metrics),
            'type'
          )
        ),
        '0',
        (pairs) => pairs[1][0].typeLabel,
        (paris) => paris[1] // option config will store all metrics grouped by type for selected meters
      ),
      primaryType: metricType,
      primaryTypeLabel: metricType ? METRIC_TYPE_LABEL[metricType] : N_A,
      primaryUnit: unit,
      primaryPrecision: precision,
      byId: (id?: number) => _.get(context.byId, id || -999),
      primaryMetricOfMeter: (meterId?: number): MeterMetric | undefined => {
        if (_.isNil(meterId)) {
          return undefined;
        }
        return context.byId[meterId]?.metrics.find((m) => m.isPrimary) as MeterMetric | undefined;
      },
    };
  }, [context, filterValues.meterIds]);

  return result;
}
