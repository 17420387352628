import type { CognitoUser } from '@aws-amplify/auth';

import { IUser } from 'src/interfaces';

/**
 * Singleton Facade for Amplify
 */
class AuthService {
  private userAuthorizationState: string | undefined = undefined;

  private get amplify() {
    return import('@aws-amplify/core').then((result) => result.Amplify);
  }

  private get auth() {
    return import('@aws-amplify/auth').then((result) => result.Auth);
  }

  setAuthorizationUserState(user: string | undefined): void {
    this.userAuthorizationState = user;
  }

  getAuthorizationUserState(): string | undefined {
    return this.userAuthorizationState;
  }

  async init(): Promise<void> {
    (await this.amplify).configure({
      Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_AWS_AUTH_REGION,
        userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_USER_POOL_CLIENT_ID,
      },
      oauth: {
        scope: ['email', 'openid', 'phone', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'token',
      },
    });
  }

  async getUser(): Promise<CognitoUser> {
    return (await this.auth).currentAuthenticatedUser({ bypassCache: true });
  }

  getDataFromUser(user?: CognitoUser): Promise<IUser | null> {
    if (!user) {
      return Promise.resolve(null);
    }
    const id = user.getUsername();
    return new Promise<IUser>((resolve, reject) => {
      user.getUserData((error, response) => {
        if (error || !response) {
          reject(error);
        }
        resolve({
          id: id,
          name: response?.UserAttributes.find((i) => i.Name === 'name')?.Value ?? '',
          email: response?.UserAttributes.find((i) => i.Name === 'email')?.Value ?? '',
          phone: response?.UserAttributes.find((i) => i.Name === 'phone_number')?.Value ?? '',
        });
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async signIn(username: string, password: string): Promise<CognitoUser | any> {
    return (await this.auth).signIn(username, password);
  }

  async forgotPassword(username: string): Promise<string> {
    return (await this.auth).forgotPassword(username);
  }

  async forgetPasswordOnSubmit(username: string, code: string, newPassword: string): Promise<void> {
    await (await this.auth).forgotPasswordSubmit(username, code, newPassword);
    return;
  }

  async completeNewPassword(user: CognitoUser, newPassword: string): Promise<CognitoUser> {
    return (await this.auth).completeNewPassword(user, newPassword);
  }

  async signOut(): Promise<void> {
    this.userAuthorizationState = undefined;
    return (await this.auth).signOut();
  }

  async getJWT(): Promise<string | null> {
    try {
      const userSession = await (await this.auth).currentSession();
      return userSession.isValid() ? userSession.getAccessToken().getJwtToken() : null;
    } catch (error) {
      return null;
    }
  }
}

export default new AuthService();
