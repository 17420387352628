import type Joi from 'joi';
import _ from 'lodash';

import { extractJoiPresence } from 'src/shared/containers/UiFormBuilder/extractJoiPresence';

import { combineSchemaValidationResult } from './combineSchemaValidationResult';

export function simulateTouchEvent(form?: HTMLFormElement | null) {
  Array.from(form?.getElementsByTagName('fieldset') ?? []).forEach((i) => i.classList.add('simulated-touch'));
}

/**
 * If valid - will return object without unknown fields and with all fields converted to their types.
 */
export async function validateAndCleanup<T>(schema: Joi.AnySchema, data: T): Promise<{ valid: boolean; data: T }> {
  // Remove forbidden fields
  const fieldsPresence = extractJoiPresence(schema, data);
  const dataShallowClone = _.clone(data);
  for (const [fieldName, presence] of Object.entries(fieldsPresence)) {
    if (presence === 'forbidden') {
      _.set(dataShallowClone as object, fieldName, undefined);
    }
  }

  // Validate
  const validation = await combineSchemaValidationResult(
    schema.validateAsync(dataShallowClone, {
      abortEarly: true,
      skipFunctions: true,
      stripUnknown: true,
    })
  );
  const valid = !_.has(validation, 'error');
  if (!valid) {
    console.debug(validation, data);
    console.warn('Validation error', validation.error);
  }
  return { valid, data: validation.value };
}
