import type * as Types from '../../../../core/apollo/__generated__/utilityGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetMetricsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  utilityServiceType: Types.UtilityServiceTypes;
}>;

export type GetMetricsQuery = {
  getUtilityMeters: Array<{
    meterId: number;
    meterUID: string;
    name?: string | null;
    metrics: Array<{
      id: number;
      isPrimary: boolean;
      measurement: Types.MetricsMeasurementType;
      type: Types.MetricType;
    }>;
  }>;
};

export type GetMetricsDataQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  metricIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  metricsFilter: Types.MeterMetricDataIntervalsInput;
}>;

export type GetMetricsDataQuery = {
  getUtilityMetersMetricsData: Array<{ metricId: number; exists: boolean; timestamp: Date; value?: number | null }>;
};

export type GetDashboardBreakdownTableQueryVariables = Types.Exact<{
  datePeriod: Types.MonthRangeInput;
  meterIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  siteId: Types.Scalars['Int']['input'];
}>;

export type GetDashboardBreakdownTableQuery = {
  getUtilityDashboardBreakdownTable: Array<{
    accountName: string;
    meterName: string;
    meterCost: number;
    metricValue: number;
    percentOfTotal: number;
  }>;
};

export type GetDashboardKPIsQueryVariables = Types.Exact<{
  currentPeriod: Types.MonthRangeInput;
  meterIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  previousPeriod: Types.MonthRangeInput;
  siteId: Types.Scalars['Int']['input'];
}>;

export type GetDashboardKPIsQuery = {
  getUtilityDashboardKPIs: {
    cost?: number | null;
    costPercentToPreviousPeriod?: number | null;
    primaryValue?: number | null;
    primaryValuePercentToPreviousPeriod?: number | null;
    carbonEmission?: number | null;
    carbonEmissionPercentToPreviousPeriod?: number | null;
  };
};

export type GetForecastMetricDataQueryVariables = Types.Exact<{
  dateTimeFilter: Types.MeterMetricDataIntervalsInput;
  meterId: Types.Scalars['Int']['input'];
  metricType: Types.MetricType;
  siteId: Types.Scalars['Int']['input'];
}>;

export type GetForecastMetricDataQuery = {
  getUtilityForecastMetricData: Array<{
    timestamp: Date;
    min?: number | null;
    value?: number | null;
    max?: number | null;
    exists: boolean;
  }>;
};

export type GetPerformanceBreakdownTableQueryVariables = Types.Exact<{
  currentPeriod: Types.MonthRangeInput;
  meterIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
  previousPeriod: Types.MonthRangeInput;
  siteId: Types.Scalars['Int']['input'];
}>;

export type GetPerformanceBreakdownTableQuery = {
  getUtilityPerformanceBreakdownTable: Array<{
    name: Types.UtilityInvoiceItemType;
    measurementType: Types.MetricsMeasurementType;
    currentValue?: number | null;
    previousValue?: number | null;
    delta?: number | null;
    percentChange?: number | null;
  }>;
};

export type GetPerformanceChartDataQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  meterIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  datePeriod: Types.MonthRangeInput;
}>;

export type GetPerformanceChartDataQuery = {
  getUtilityPerformanceChart: Array<{ year: number; month: number; value: number; cost: number }>;
};

export const GetMetricsDocument = gql`
  query GetMetrics($siteId: Int!, $utilityServiceType: UtilityServiceTypes!) {
    getUtilityMeters(siteId: $siteId, type: $utilityServiceType) {
      meterId
      meterUID
      name
      metrics {
        id
        isPrimary
        measurement
        type
      }
    }
  }
`;
export const GetMetricsDataDocument = gql`
  query GetMetricsData($siteId: Int!, $metricIds: [Int!]!, $metricsFilter: MeterMetricDataIntervalsInput!) {
    getUtilityMetersMetricsData(siteId: $siteId, metricIds: $metricIds, metricsFilter: $metricsFilter) {
      metricId
      exists
      timestamp
      value
    }
  }
`;
export const GetDashboardBreakdownTableDocument = gql`
  query GetDashboardBreakdownTable($datePeriod: MonthRangeInput!, $meterIds: [Int!]!, $siteId: Int!) {
    getUtilityDashboardBreakdownTable(datePeriod: $datePeriod, meterIds: $meterIds, siteId: $siteId) {
      accountName
      meterName
      meterCost
      metricValue
      percentOfTotal
    }
  }
`;
export const GetDashboardKPIsDocument = gql`
  query GetDashboardKPIs(
    $currentPeriod: MonthRangeInput!
    $meterIds: [Int!]!
    $previousPeriod: MonthRangeInput!
    $siteId: Int!
  ) {
    getUtilityDashboardKPIs(
      currentPeriod: $currentPeriod
      meterIds: $meterIds
      previousPeriod: $previousPeriod
      siteId: $siteId
    ) {
      cost
      costPercentToPreviousPeriod
      primaryValue
      primaryValuePercentToPreviousPeriod
      carbonEmission
      carbonEmissionPercentToPreviousPeriod
    }
  }
`;
export const GetForecastMetricDataDocument = gql`
  query GetForecastMetricData(
    $dateTimeFilter: MeterMetricDataIntervalsInput!
    $meterId: Int!
    $metricType: MetricType!
    $siteId: Int!
  ) {
    getUtilityForecastMetricData(
      dateTimeFilter: $dateTimeFilter
      meterId: $meterId
      metricType: $metricType
      siteId: $siteId
    ) {
      timestamp
      min
      value
      max
      exists
    }
  }
`;
export const GetPerformanceBreakdownTableDocument = gql`
  query GetPerformanceBreakdownTable(
    $currentPeriod: MonthRangeInput!
    $meterIds: [Int!]
    $previousPeriod: MonthRangeInput!
    $siteId: Int!
  ) {
    getUtilityPerformanceBreakdownTable(
      currentPeriod: $currentPeriod
      meterIds: $meterIds
      previousPeriod: $previousPeriod
      siteId: $siteId
    ) {
      name
      measurementType
      currentValue
      previousValue
      delta
      percentChange
    }
  }
`;
export const GetPerformanceChartDataDocument = gql`
  query GetPerformanceChartData($siteId: Int!, $meterIds: [Int!]!, $datePeriod: MonthRangeInput!) {
    getUtilityPerformanceChart(siteId: $siteId, meterIds: $meterIds, datePeriod: $datePeriod) {
      year
      month
      value
      cost
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetMetrics(
      variables: GetMetricsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetMetricsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMetricsQuery>(GetMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetMetrics',
        'query',
        variables
      );
    },
    GetMetricsData(
      variables: GetMetricsDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetMetricsDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMetricsDataQuery>(GetMetricsDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetMetricsData',
        'query',
        variables
      );
    },
    GetDashboardBreakdownTable(
      variables: GetDashboardBreakdownTableQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetDashboardBreakdownTableQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDashboardBreakdownTableQuery>(GetDashboardBreakdownTableDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetDashboardBreakdownTable',
        'query',
        variables
      );
    },
    GetDashboardKPIs(
      variables: GetDashboardKPIsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetDashboardKPIsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDashboardKPIsQuery>(GetDashboardKPIsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetDashboardKPIs',
        'query',
        variables
      );
    },
    GetForecastMetricData(
      variables: GetForecastMetricDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetForecastMetricDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetForecastMetricDataQuery>(GetForecastMetricDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetForecastMetricData',
        'query',
        variables
      );
    },
    GetPerformanceBreakdownTable(
      variables: GetPerformanceBreakdownTableQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPerformanceBreakdownTableQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPerformanceBreakdownTableQuery>(GetPerformanceBreakdownTableDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetPerformanceBreakdownTable',
        'query',
        variables
      );
    },
    GetPerformanceChartData(
      variables: GetPerformanceChartDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPerformanceChartDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPerformanceChartDataQuery>(GetPerformanceChartDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetPerformanceChartData',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
