import React from 'react';
import { Outlet } from 'react-router-dom';

import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import DetailsPageLayout from 'src/fragments/layouts/DetailsPageLayout';
import { getSelectedSitesForHeaderRow } from 'src/modules/sites/gql/getSelectedSitesForHeaderRow.resources.gql';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';

const SiteDetailsLayoutPage: React.FC<{
  tabs: string[];
}> = (props) => {
  const siteId = useNumberParam('id', false);
  const isCreationFlow = isNaN(siteId);

  const { response: site, isInitialLoading } = useDataFetchOnMountWithDeps(
    () =>
      (isCreationFlow ? Promise.resolve(undefined) : getSelectedSitesForHeaderRow([siteId])).then((data) => data?.[0]),
    [siteId]
  );

  const title = site && site.nameOrAddess;
  const subtitle = site && `${site.streetNumber} ${site.streetName} | ${site.regionWithState}`;

  return (
    <DetailsPageLayout
      idParamName='id'
      entity='Site'
      entityName={title}
      subtitle={subtitle}
      isInitialLoading={isInitialLoading}
      isFailed={false}
      removeBottomMargin
    >
      <UrlTabs names={props.tabs} disabled={isCreationFlow} />
      <Outlet />
    </DetailsPageLayout>
  );
};

export default SiteDetailsLayoutPage;
