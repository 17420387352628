import ReactjsPopup from '@tblbuildings/reactjs-popup';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useValueChanged } from 'src/cdk/hooks/useValueChanged';
import { useAppDispatch } from 'src/core/store/hooks';
import { Button } from 'src/shared/components/Button/Button';

import { ConfigAndFilter } from '../filter.interface';
import {
  closeFiltersSidebar,
  selectFilterAndConfigIdForPage,
  selectFilterFields,
  selectIsOpenSidebar,
  setFilters,
} from '../filtersSlice';

import ConfigSelector from './ConfigSelector';
import styles from './Filter.module.scss';
import { FieldFactory } from './FilterFieldFactory';

interface SidebarProps {
  storageKey: string;
}

export const FilterSidebarPopup: React.FC<SidebarProps> = ({ storageKey }) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLFormElement>(null);

  const isOpen = useSelector(selectIsOpenSidebar);

  const fields = useSelector(selectFilterFields);
  const originalFilterAndConfig = useSelector(selectFilterAndConfigIdForPage(storageKey), _.isEqual);

  const [form, setForm] = useState<ConfigAndFilter>({
    filters: {},
  });
  const { isNotChanged, setOriginalValue } = useValueChanged(form);

  useEffect(() => {
    if (isOpen) {
      setForm(originalFilterAndConfig);
      setOriginalValue(originalFilterAndConfig);
    }
  }, [isOpen, storageKey]);

  function setValueByName(name: string, value: unknown) {
    setForm((prev) => ({
      filterConfigId: prev.filterConfigId,
      filters: { ...prev.filters, [name]: value },
    }));
  }

  return (
    <ReactjsPopup
      overlayStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      open={isOpen}
      onClose={() => {
        setForm({
          filters: {},
        });
        dispatch(closeFiltersSidebar());
      }}
      className='sidebar'
      closeOnDocumentClick={true}
      nested
      disableFocusContentOnOpen={true}
      lockScroll={true}
      position={['top right']}
      modal
    >
      <form className={classNames('card', 'el-04', styles['sidebar-container'])} id='tbl-modal' ref={ref}>
        <h4 className='mb-20'>Filters and Configurations</h4>

        <div className={styles['buttons-block']}>
          <Button
            onClick={() => {
              setForm({
                filters: {},
              });
            }}
            label='Clear All'
            variant='secondary'
          />
          <Button
            onClick={() => {
              // TODO: use for validation
              // const isValid = ref.current?.reportValidity() ?? false;
              dispatch(
                setFilters({
                  storageKey,
                  filterConfigId: form.filterConfigId,
                  filters: form.filters,
                })
              );
              dispatch(closeFiltersSidebar());
            }}
            label='Apply'
            variant='primary'
            type='submit'
            disabled={isNotChanged}
          />
        </div>

        <h5 className='mt-32 mb-16'>Configurations</h5>

        <ConfigSelector storageKey={storageKey} configAndFilter={form} setConfigAndFilter={setForm} />

        <h5 className='mt-40'>Filters</h5>

        <div className={classNames(styles['sidebar-content'])}>
          {fields.map((field) => (
            <FieldFactory
              key={field.name}
              field={field}
              value={form.filters[field.name]}
              setValueByName={setValueByName}
            />
          ))}
        </div>
      </form>
    </ReactjsPopup>
  );
};
