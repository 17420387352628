import 'rc-slider/assets/index.css';

import { NumberRange } from 'src/interfaces';

import { InputNumeric } from '../InputNumeric/InputNumeric';

import { RangeSlider } from './RangeSlider';
import styles from './RangeSliderWithInputs.module.scss';

export interface RangeSliderWithInputsProps {
  label: string;
  min: number;
  max: number;
  value: NumberRange;
  onChange: (v: NumberRange) => void;
  reverse?: boolean;
  disabled?: boolean;
}

export const RangeSliderWithInputs: React.FC<RangeSliderWithInputsProps> = ({
  min,
  max,
  reverse = false,
  onChange,
  value,
  label,
  disabled,
}) => {
  const [left, right] = value;

  const leftValue = reverse ? right : left;
  const rightValue = reverse ? left : right;

  const maxForLeftInput = reverse ? max : right - 1;
  const minForLeftInput = reverse ? left + 1 : min;

  const maxForRightInput = reverse ? right - 1 : max;
  const minForRightInput = reverse ? min : left + 1;

  return (
    <>
      <p className={'body-small color-secondary ' + styles['label']}>{label}</p>
      <div className={styles['row']} style={{ marginBottom: 32 }}>
        <div className={styles['input-wrapper']}>
          <InputNumeric
            disabledHandleChangeValidation={true}
            value={leftValue}
            min={minForLeftInput}
            max={maxForLeftInput}
            onChange={(leftNewValue) => onChange(reverse ? [rightValue, leftNewValue] : [leftNewValue, rightValue])}
            disabled={disabled}
          />
          <p className='color-secondary caption-semi-bold'>{reverse ? 'max' : 'min'}</p>
        </div>

        <RangeSlider
          min={min}
          max={max}
          pushable={true}
          onChange={(newValue) => onChange(newValue)}
          value={value}
          reverse={reverse}
          disabled={disabled}
        />

        <div className={styles['input-wrapper']}>
          <InputNumeric
            disabledHandleChangeValidation={true}
            value={rightValue}
            min={minForRightInput}
            max={maxForRightInput}
            onChange={(rightNewValue) => onChange(reverse ? [rightNewValue, leftValue] : [leftValue, rightNewValue])}
            disabled={disabled}
          />
          <p className='color-secondary caption-semi-bold'>{reverse ? 'min' : 'max'}</p>
        </div>
      </div>
    </>
  );
};
