import classNames from 'classnames';

import { Icon } from '../Icon/Icon';
import { CustomTooltip } from '../Popup';

interface Props {
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
  /**
   * Max width (in px) of the tooltip, by default - unlimited
   */
  maxWidth?: number;
}

export const InfoTooltip: React.FC<Props> = ({ children, className, disabled, maxWidth }) => {
  return (
    <CustomTooltip
      hideArrow
      disabled={disabled}
      maxWidth={maxWidth}
      triggerComponent={(isOpen) => (
        <Icon
          className={classNames('with-pointer', className)}
          icon='info'
          size='s'
          color={isOpen ? 'secondary' : 'tertiary'}
          style={{
            opacity: disabled ? 0.5 : 1,
          }}
        />
      )}
    >
      <div className='body-small color-secondary'>{children}</div>
    </CustomTooltip>
  );
};
