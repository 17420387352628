import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';
import { SystemSequence } from 'src/enums';

import {
  ChangeSystemSequenceMutation,
  ChangeSystemSequenceMutationVariables,
} from './__generated__/changeSystemSequence.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterUpdatedSequence =
  | Extract<ChangeSystemSequenceMutation['updateSequence'], { __typename: 'SystemSteamPrvStation' }>
  | Extract<ChangeSystemSequenceMutation['updateSequence'], { __typename: 'SystemHwBoilerTekmar248' }>
  | Extract<ChangeSystemSequenceMutation['updateSequence'], { __typename: 'SystemStBoilerFHtMpc' }>
  | Extract<ChangeSystemSequenceMutation['updateSequence'], { __typename: 'SystemHwBoilerHtHwr' }>
  | Extract<ChangeSystemSequenceMutation['updateSequence'], { __typename: 'SystemPackageAltc24Prog' }>
  | Extract<ChangeSystemSequenceMutation['updateSequence'], { __typename: 'SystemLightingVive' }>;

const CHANGE_SYSTEM_SEQUENCE = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangeSystemSequence($systemId: Int!, $sequenceId: Int!) {
    updateSequence(sequenceId: $sequenceId, systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changeSystemSequence(
  systemId: number,
  sequence: SystemSequence
): Promise<DataAfterUpdatedSequence | undefined> {
  const result = await resourcesAPI.mutate<ChangeSystemSequenceMutation, ChangeSystemSequenceMutationVariables>({
    mutation: CHANGE_SYSTEM_SEQUENCE,
    variables: { systemId, sequenceId: sequence.valueOf() },
  });

  const updateResult = result.data?.updateSequence as DataAfterUpdatedSequence;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
