import { differenceInMinutes } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { GLOBAL_TIME_INTERVAL } from 'src/constants';
import { OneMinuteObservedInterval } from 'src/core/service/oneMinuteObservedInterval';

/**
 * Returns current date, and updates its value every minute
 */
export function useGlobalNowWithMinuteInterval(func?: (time?: Date) => void): Date {
  const [lastUpdateTime, setLastUpdateTime] = useState(OneMinuteObservedInterval.observableInstance.readLatestValue());
  const [now, setNow] = useState(OneMinuteObservedInterval.observableInstance.readLatestValue());

  const update = useCallback(
    (value: Date) => {
      if (func && differenceInMinutes(value, lastUpdateTime) >= GLOBAL_TIME_INTERVAL) {
        func(value);
        setLastUpdateTime(value);
      }
      setNow(value);
    },
    [lastUpdateTime, func]
  );

  useEffect(() => {
    OneMinuteObservedInterval.observableInstance.unregister(update);
    OneMinuteObservedInterval.observableInstance.register(update);

    return () => {
      OneMinuteObservedInterval.observableInstance.unregister(update);
    };
  }, [lastUpdateTime, func]);

  return now;
}
