import { differenceInCalendarDays, differenceInHours, differenceInMonths } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';

import { DatePickerRangeType } from '../DateRangePicker/config';

export const DatePickerNonHoursButtons: DatePickerRangeType[] = [
  DatePickerRangeType.THIS_WEEK,
  DatePickerRangeType.THIS_YEAR,
  DatePickerRangeType.LAST_WEEK,
  DatePickerRangeType.LAST_YEAR,
  DatePickerRangeType.THIS_MONTH,
  DatePickerRangeType.LAST_6_MONTHS,
  DatePickerRangeType.LAST_MONTH,
  DatePickerRangeType.LAST_12_MONTHS,
];

/**
 * Get available steps for metrics based on selected range.
 * If range is less than 25 hours, available step is 15 minutes only.
 * If range is more than 30 days, available steps are 1 hour and 1 day only.
 * Otherwise - any range is available.
 */
export function getAvailableSteps(range: DateRange): MetricsStep[] {
  if (!range.from || !range.to) {
    return [];
  }
  if (differenceInHours(range.to, range.from) <= 25) {
    return [MetricsStep.FIFTEEN_MINUTES];
  }
  if (Math.round(differenceInCalendarDays(range.to, range.from)) > 30) {
    return [MetricsStep.HOUR, MetricsStep.DAY];
  }
  return [MetricsStep.FIFTEEN_MINUTES, MetricsStep.HOUR, MetricsStep.DAY];
}

/**
 * Get step for metrics based on selected range.
 * If range is less than 25 hours, step is 15 minutes.
 * If range is less than 6 days, step is 1 hour.
 * If range is more than 6 days, step is 1 day.
 */
export function defineStepFromDateRange(from: Date, to: Date): MetricsStep {
  if (differenceInHours(to, from) <= 25) {
    return MetricsStep.FIFTEEN_MINUTES;
  }

  if (Math.round(differenceInCalendarDays(to, from)) <= 6) {
    return MetricsStep.HOUR;
  }

  if (differenceInMonths(to, from) <= 3) {
    return MetricsStep.DAY;
  }

  return MetricsStep.MONTH;
}
