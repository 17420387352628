import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

import { METRIC_SYMBOL } from 'src/constants';
import { MetricsMeasurementType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { Button } from 'src/shared/components/Button/Button';

import { MetricWithData } from '../../../interface';
import SelectMetricsModal from '../SelectMetricsModal/SelectMetricsModal';

import styles from './SelectedMetricsTile.module.scss';

export interface SelectedMetricsTileProps {
  metrics: MetricWithData[];
  updateMetrics: (metrics: MetricWithData[]) => void;
}

const SelectedMetricsTile: React.FC<SelectedMetricsTileProps> = ({ metrics, updateMetrics }) => {
  const metricsToDisplay = metrics.filter((i) => i.measurement !== MetricsMeasurementType.USAGE);
  const [isSelectMetricsModalOpen, setSelectMetricsModalOpen] = useState(false);

  const loadedMetricsCount = metricsToDisplay.length;
  const selectedMetricsCount = _.sumBy(metricsToDisplay, (i) => (i.selected ? 1 : 0));
  const selectedMeasurementLabels: string[] = _.uniq(
    metricsToDisplay.filter((metric) => metric.selected).map((metric) => _.get(METRIC_SYMBOL, metric.measurement, ''))
  );

  const selectMetricsModal = isSelectMetricsModalOpen && (
    <SelectMetricsModal
      metrics={metricsToDisplay}
      updateMetrics={(metricsWithData) => {
        // Always load metrics if there are any usage metrics
        updateMetrics([
          ...metricsWithData,
          ...metrics
            .filter((i) => i.measurement === MetricsMeasurementType.USAGE)
            .map((i) => ({ ...i, selected: true })),
        ]);
        setSelectMetricsModalOpen(false);
      }}
      cancelAction={() => setSelectMetricsModalOpen(false)}
    />
  );
  return (
    <div className={classNames('card', 'flat', styles['selected-metrics-container'])}>
      <div className={classNames('p-12', 'color-secondary', 'caption', 'd-flex')}>
        Selected Metrics:{' '}
        <span className='ml-8'>
          <span className='color-primary'>{selectedMetricsCount}</span> of {loadedMetricsCount}
        </span>
        {!!selectedMeasurementLabels.length && (
          <div className={classNames('ml-16', 'color-primary')}>({selectedMeasurementLabels.join(', ')})</div>
        )}
      </div>

      <Button
        label='Select Metrics'
        className={styles['button']}
        onClick={() => setSelectMetricsModalOpen(true)}
        shape='rect'
        type='button'
        variant='flat'
      />
      {selectMetricsModal}
    </div>
  );
};

export default SelectedMetricsTile;
