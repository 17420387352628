import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _, { NumericDictionary } from 'lodash';

import { mapAsOptions } from 'src/cdk/mappers/mapAsOptions';
import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import type { RootState } from 'src/core/store/store';
import { sequenceDisplayValue, SystemSequence } from 'src/enums';
import { OptionItem } from 'src/shared/components/Select';
import { CardLayout } from 'src/shared/containers/UniversalCard/UniversalCard';

import { SystemGroups } from './gql/getSystemsForSite.resources.gql';
import { AnySystem, AnySystemSpecificFields } from './interface';
import { systemCardLayoutService } from './service/systemCardLayoutService';

/**
 * `undefined` - means that all floors are selected.
 *
 * `null` - means that no floor is defined for system.
 *
 * `number` - means that floor with defined number is selected.
 */
export type FloorNumber = string | undefined | null;

interface ISystemsSlice {
  lastUpdatedDatetime: Date;
  systemType: SystemType[];
  /**
   * Global search field on systems page
   */
  search: string;
  /**
   * Selected floor
   */
  floorPerSite: NumericDictionary<FloorNumber>;
  /**
   * Used to store only one system by id to prevent re-rendering
   *
   * Used to prevent re-rendering of all cards if some card was updated
   */
  systemsMap: NumericDictionary<AnySystem>;
  /**
   * Used to define what systems are currently available to the user under 1 site
   *
   * Used to prevent re-rendering of all cards if some site was filtered by floor
   */
  systemIdsAndAlertsPerSite: NumericDictionary<{ systemIds: number[] }>;

  /**
   * System card layout
   */
  cardLayout: CardLayout;
  systemGroupsMap: NumericDictionary<SystemGroups>;
  systemGroupIdsPerSite: NumericDictionary<{ systemGroupIds: number[] }>;
  systemIdsPerSystemGroup: NumericDictionary<{ systemIds: number[] }>;
}

const getInitialState = (): ISystemsSlice => ({
  lastUpdatedDatetime: new Date(),
  systemType: [],
  systemsMap: {},
  systemIdsAndAlertsPerSite: {},
  floorPerSite: {},
  search: '',
  cardLayout: systemCardLayoutService.init(),
  systemGroupsMap: {},
  systemGroupIdsPerSite: {},
  systemIdsPerSystemGroup: {},
});

export const systemsSlice = createSlice({
  name: 'systems',
  initialState: getInitialState(),
  reducers: {
    resetSlice: () => getInitialState(),
    setSystems: (state, action: PayloadAction<AnySystem[]>) => {
      state.lastUpdatedDatetime = new Date();
      // Update list of all system ids available to the current user
      state.systemIdsAndAlertsPerSite = {};
      state.systemIdsPerSystemGroup = {};
      for (const system of action.payload) {
        state.systemsMap[system.id] = system;
        if (!state.systemIdsAndAlertsPerSite[system.siteId]) {
          state.systemIdsAndAlertsPerSite[system.siteId] = { systemIds: [] };
        }
        state.systemIdsAndAlertsPerSite[system.siteId].systemIds.push(system.id);
        if (system.systemGroupId) {
          if (!state.systemIdsPerSystemGroup[system.systemGroupId]) {
            state.systemIdsPerSystemGroup[system.systemGroupId] = { systemIds: [] };
          }
          state.systemIdsPerSystemGroup[system.systemGroupId].systemIds.push(system.id);
        }
      }
    },
    setSystemType: (state, action: PayloadAction<SystemType[]>) => {
      state.systemType = action.payload;
    },
    setSingleSystem: (state, action: PayloadAction<AnySystem>) => {
      const system = action.payload;
      state.systemsMap[system.id] = system;
    },
    updateSystem: (state, action: PayloadAction<[number, AnySystemSpecificFields]>) => {
      const [systemId, fields] = action.payload;
      state.systemsMap[systemId] = { ...state.systemsMap[systemId], ...fields };
    },
    updateSystemSetpoint: (state, action: PayloadAction<[number, number]>) => {
      const [systemId, setpoint] = action.payload;
      // Hacky way to not exclude setpoint for Weather station systems
      const system = { ...state.systemsMap[systemId], setpoint };
      state.systemsMap[systemId] = system;
    },
    updateSystemSequence: (state, action: PayloadAction<[number, SystemSequence]>) => {
      const [systemId, sequence] = action.payload;
      state.systemsMap[systemId] = {
        ...state.systemsMap[systemId],
        sequence: {
          id: sequence,
          name: sequenceDisplayValue[sequence],
        },
      };
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setFloorPerSite: (state, action: PayloadAction<[number, FloorNumber]>) => {
      const [siteId, floor] = action.payload;
      state.floorPerSite[siteId] = floor;
    },
    setCardLayout: (state, action: PayloadAction<CardLayout>) => {
      systemCardLayoutService.setLayout(action.payload);
      state.cardLayout = action.payload;
    },
    setSystemGroups: (state, action: PayloadAction<SystemGroups[]>) => {
      state.systemGroupsMap = {};
      state.systemGroupIdsPerSite = {};
      for (const systemGroup of action.payload) {
        state.systemGroupsMap[systemGroup.id] = systemGroup;
        if (!state.systemGroupIdsPerSite[systemGroup.siteId]) {
          state.systemGroupIdsPerSite[systemGroup.siteId] = { systemGroupIds: [] };
        }
        state.systemGroupIdsPerSite[systemGroup.siteId].systemGroupIds.push(systemGroup.id);
      }
    },
    setSingleSystemGroup: (state, action: PayloadAction<SystemGroups>) => {
      const systemGroup = action.payload;
      state.systemGroupsMap[systemGroup.id] = systemGroup;
    },
  },
});

export const {
  resetSlice: resetSystemsSlice,
  setSystems,
  setSingleSystem,
  updateSystem,
  updateSystemSetpoint,
  updateSystemSequence,
  setSearchQuery,
  setFloorPerSite,
  setSystemType,
  setCardLayout,
  setSystemGroups,
  setSingleSystemGroup,
} = systemsSlice.actions;

export const selectCardLayout = (state: RootState): CardLayout => state.systems.cardLayout;

export const selectLastUpdatedDatetime = (state: RootState): Date => state.systems.lastUpdatedDatetime;

export const selectedFloorPerSite =
  (siteId: number) =>
  (state: RootState): FloorNumber =>
    state.systems.floorPerSite[siteId];

export const selectSystemIdsForSite =
  (siteId: number) =>
  (state: RootState): number[] =>
    (state.systems.systemIdsAndAlertsPerSite[siteId]?.systemIds ?? []).filter((systemId) => {
      const system = state.systems.systemsMap[systemId];
      const showByQuery = isSystemIncludesSearchQuery(state, systemId);

      const floor = state.systems.floorPerSite[siteId];
      const showByFloor = floor === undefined || system.floor === floor;

      return showByQuery && showByFloor;
    });

export const selectSystems =
  <T extends AnySystem>() =>
  (state: RootState): T[] =>
    _.values(state.systems.systemsMap) as T[];

export const selectSystemsForSite =
  <T extends AnySystem>(siteId: number) =>
  (state: RootState): T[] =>
    selectSystemIdsForSite(siteId)(state)
      .map((systemId) => state.systems.systemsMap[systemId] as T)
      .filter((i) => !!i);

/**
 * Should return list with the next order:
 * - undefined - First option to select all floors
 * - 1 - Ordered floors
 * - 2
 * - ...
 * - null - Last option to select unassigned floors
 */
export const selectAvailableFloorsBySystemIds =
  (siteId: number) =>
  (state: RootState): OptionItem<FloorNumber>[] => {
    // `sortBy` by will put `null` values to the end
    const options = _.uniq(
      (state.systems.systemIdsAndAlertsPerSite[siteId]?.systemIds ?? [])
        .filter((systemId) => isSystemIncludesSearchQuery(state, systemId))
        .map((systemId) => state.systems.systemsMap[systemId].floor)
    ).map((floor) => ({
      key: floor,
      displayValue: floor?.toString() ?? 'Unassigned',
    }));

    return [
      // Always add `All` option to the start
      {
        key: undefined,
        displayValue: 'All',
      },
      ...options,
    ];
  };

export const selectSystemsAsOptions =
  (siteId: number) =>
  (state: RootState): OptionItem<number>[] => {
    const systems = _.uniq(
      (state.systems.systemIdsAndAlertsPerSite[siteId]?.systemIds ?? [])
        .filter((systemId) => isSystemIncludesSearchQuery(state, systemId))
        .map((systemId) => state.systems.systemsMap[systemId])
    );

    return mapAsOptions(systems, 'id', 'name');
  };

// TODO: Provide a better solution for type resolving, currently it is based on assumption
export const selectedSystemById =
  <T extends AnySystem>(systemId: number) =>
  (state: RootState): T | undefined =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state.systems.systemsMap[systemId] as any as T;

export const selectedSystemsByIds =
  <T extends AnySystem>(systemIds: number[]) =>
  (state: RootState): Array<T | undefined> =>
    systemIds.map((systemId) => state.systems.systemsMap[systemId] as unknown as T);

export const selectSystemGroupsBySiteId =
  (siteId: number) =>
  (
    state: RootState
  ): {
    systemGroup: SystemGroups;
    systemsInfo: AnySystem[];
  }[] => {
    return (state.systems.systemGroupIdsPerSite[siteId]?.systemGroupIds ?? [])
      .filter((systemGroupId) => filterSystemGroupBySearchQuery(state, systemGroupId))
      .map((systemGroupId) => {
        const systemIdsForSystemGroup = state.systems.systemIdsPerSystemGroup[systemGroupId];
        return {
          systemGroup: state.systems.systemGroupsMap[systemGroupId],
          systemsInfo: systemIdsForSystemGroup
            ? systemIdsForSystemGroup.systemIds.map((systemId) => state.systems.systemsMap[systemId])
            : [],
        };
      });
  };

function filterSystemGroupBySearchQuery(state: RootState, systemGroupId: number): boolean {
  const systemGroup = state.systems.systemGroupsMap[systemGroupId];
  const searchQuery = state.systems.search.toLowerCase().trim();

  if (_.isEmpty(searchQuery)) {
    return true;
  } else {
    return systemGroup.name.toLowerCase().includes(searchQuery);
  }
}

export const selectedSystemsBySystemGroupById =
  <T extends AnySystem>(systemGroupId: number) =>
  (state: RootState): T[] | undefined => {
    return (
      (state.systems.systemIdsPerSystemGroup[systemGroupId]?.systemIds ?? [])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((systemId) => state.systems.systemsMap[systemId]) as any as T[]
    );
  };

export const selectedSystemGroupById =
  (systemGroupId: number) =>
  (state: RootState): SystemGroups | undefined =>
    state.systems.systemGroupsMap[systemGroupId];

/**
 * Search is case insensetive.
 *
 * @returns `true` if there is no search query or if system name includes search query.
 */
function isSystemIncludesSearchQuery(state: RootState, systemId: number): boolean {
  const system = state.systems.systemsMap[systemId];
  const searchQuery = state.systems.search.toLowerCase().trim();

  if (_.isEmpty(searchQuery)) {
    return true;
  } else {
    return system.name.toLowerCase().includes(searchQuery);
  }
}

export default systemsSlice.reducer;
