import React, { useEffect, useState } from 'react';

import { LoadingStatus } from 'src/enums';

export interface FetchStatus {
  /**
   * Error can represent as well any error: Network, GraphQL or plain JS error
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  isFailed: boolean;
  /**
   * Trigger that callback when data needs to bew updated even when deps are not changed
   */
  refetch: () => Promise<void>;
}

/**
 * Use that hook to send initial request on component init and global loading/error handling.
 * Thunk should rethrow any exception to make that hook work.
 *
 * @param fetch Can accept Thunk or any Promise to get data
 * @param onSuccess Callback is optional, but it can be used to set data to local component state
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFetchOnMountWithDeps<T = void>(
  fetch: () => Promise<T>,
  onSuccess?: (data: T) => void,
  deps?: React.DependencyList,
  updateStatusOnEachRequest?: boolean
): FetchStatus {
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
  const [error, setError] = useState(null);

  function refetch(): Promise<void> {
    if (updateStatusOnEachRequest && loadingStatus !== LoadingStatus.INITIAL) {
      setLoadingStatus(LoadingStatus.WAITING);
    }
    return fetch()
      .then((data) => {
        setLoadingStatus(LoadingStatus.LOADED);
        if (onSuccess && data) {
          onSuccess(data);
        }
      })
      .catch((error) => {
        setError(error);
        setLoadingStatus(LoadingStatus.FAILED);
      });
  }

  useEffect(() => {
    refetch();
  }, deps || []);

  return {
    error,
    isInitialLoading: loadingStatus === LoadingStatus.INITIAL,
    isLoading: loadingStatus === LoadingStatus.WAITING || loadingStatus === LoadingStatus.INITIAL,
    isLoaded: loadingStatus === LoadingStatus.LOADED,
    isFailed: loadingStatus === LoadingStatus.FAILED,
    refetch,
  };
}
