import { gql } from '@apollo/client';
import _ from 'lodash';

import { resourcesAPI } from 'src/core/apollo/api';
import { OptionItem } from 'src/shared/components/Select';

import {
  GetTenantsOptionsForSiteQuery,
  GetTenantsOptionsForSiteQueryVariables,
  GetTenantsForSiteQuery,
  GetTenantsForSiteQueryVariables,
} from './__generated__/getTenantsOptionsForSite.resources.gql';

export type TenantInfo = Omit<GetTenantsOptionsForSiteQuery['getTenantsBySite'], '__typename'>;

const GET_TENANTS_OPTIONS_FOR_SITE = gql`
  query GetTenantsOptionsForSite($siteId: Int!) {
    getTenantsBySite(siteId: $siteId) {
      id
      name
    }
  }
`;

const GET_TENANTS_FOR_SITE = gql`
  query GetTenantsForSite($siteId: Int!) {
    getTenantsBySite(siteId: $siteId) {
      id
      name
      email
      phone
      contactName
    }
  }
`;

export async function getTenantsOptionsForSite(siteId: number): Promise<OptionItem<number>[]> {
  const result = await resourcesAPI.query<GetTenantsOptionsForSiteQuery, GetTenantsOptionsForSiteQueryVariables>({
    query: GET_TENANTS_OPTIONS_FOR_SITE,
    variables: {
      siteId,
    },
  });

  return result.data.getTenantsBySite.map((tenant) => ({ key: tenant.id, displayValue: tenant.name }));
}

export async function getTenantsForSite(
  siteId: number
): Promise<Record<number, GetTenantsForSiteQuery['getTenantsBySite'][0]>> {
  const result = await resourcesAPI.query<GetTenantsForSiteQuery, GetTenantsForSiteQueryVariables>({
    query: GET_TENANTS_FOR_SITE,
    variables: {
      siteId,
    },
  });

  return _.keyBy(result.data.getTenantsBySite, 'id');
}
