import { Outlet, useParams } from 'react-router';

import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import DetailsPageLayout from 'src/fragments/layouts/DetailsPageLayout';
import api from 'src/logic/systems/gql';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';

interface Props {
  tabsNames: string[];
}

const SystemDetailsPage: React.FC<Props> = ({ tabsNames }) => {
  const params = useParams<{ id: string }>();
  const systemId = Number(params.id);
  const isCreationFlow = !params.id || params.id === 'create';

  const { isInitialLoading, isFailed, response } = useDataFetchOnMountWithDeps(
    () =>
      isCreationFlow
        ? Promise.resolve(undefined)
        : api.GetSystem({ systemId }).then((response) => response.getSystemFullInfo),
    [systemId, isCreationFlow]
  );

  return (
    <DetailsPageLayout
      idParamName='id'
      entity='System'
      entityName={response?.name}
      relatedSiteId={response?.siteId}
      isInitialLoading={isInitialLoading}
      isFailed={isFailed}
      removeBottomMargin
    >
      <UrlTabs disabled={isCreationFlow} names={tabsNames} />
      <Outlet />
    </DetailsPageLayout>
  );
};

export default SystemDetailsPage;
