import React, { useMemo } from 'react';

import { AlertColor } from 'src/core/apollo/__generated__/alertsGlobalTypes';
import { OnlineStatus, SiteFeatureType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { useAppSelector } from 'src/core/store/hooks';
import { SYSTEM_ONLINE_STATUS_TO_DESCRIPTION, OnlineStatusToTitle } from 'src/enums';
import { selectHasSystemNotifications, selectNotificationsContentByFilters } from 'src/logic/alerts/alertsSlice';
import { selectSiteHasFeature } from 'src/modules/sites/sitesSlice';

import Tag, { TagType } from './Tag';
import styles from './Tag.module.scss';

// TODO: rework whole component

interface Props {
  siteId?: number;
  systemId?: number;
  onlineStatus: OnlineStatus | null | undefined;
  withDescription?: boolean;
  className?: string;
  title?: string;
  description?: string;
  color?: AlertColor;
  showIndicator?: boolean;
}

const SystemAlertTag: React.FC<Props> = (props) => {
  const hasFeature = useAppSelector(selectSiteHasFeature(props.siteId, SiteFeatureType.ALERTS));
  const alert = useAppSelector(selectNotificationsContentByFilters({ systemId: props.systemId }));

  const [tagType, tagMessage] = useMemo(() => {
    if (props.onlineStatus === OnlineStatus.OFFLINE || props.onlineStatus === OnlineStatus.NOT_CONFIGURED) {
      return ['error', OnlineStatusToTitle[props.onlineStatus!]];
    }
    const color = props.color || alert.color;
    const message = props.title ?? (alert.data?.[0] as string);
    if (color === AlertColor.RED) {
      return ['error', message];
    }
    if (color === AlertColor.ORANGE) {
      return ['warning', message];
    }
    if (color === AlertColor.YELLOW) {
      return ['light-warning', message];
    }
    return [undefined, ''];
  }, [props.onlineStatus, props.color, props.title, alert]);

  if (!tagType || !hasFeature) {
    return null;
  }

  const description = props.description ?? SYSTEM_ONLINE_STATUS_TO_DESCRIPTION[props.onlineStatus!];

  return (
    <Tag type={tagType as TagType} className={props.className}>
      <>
        <p className={props.withDescription ? 'pt-8' : ''}>{tagMessage}</p>
        {props.withDescription && <p className='caption pt-8 pb-8'>{description}</p>}

        {/* TODO: rework indicator & send request to API to mark notification as read */}
        {props.showIndicator && <span className={styles['indicator']} />}
      </>
    </Tag>
  );
};

export function hasAlert(system: { onlineStatus?: OnlineStatus | undefined | null; id: number; siteId: number }) {
  const hasNotification = useAppSelector(selectHasSystemNotifications(system.id));
  const hasFeature = useAppSelector(selectSiteHasFeature(system.siteId, SiteFeatureType.ALERTS));

  return (hasFeature && hasNotification) || !(!system.onlineStatus || system.onlineStatus === OnlineStatus.ONLINE);
}

export default SystemAlertTag;
