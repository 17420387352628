import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type SchedulerProfileSystemConfigurationSettingsFragmentFragment = {
  setpoint?: number | null;
  setpointCool?: number | null;
  setpointHeat?: number | null;
  setpointMax?: number | null;
  setpointMin?: number | null;
  outsideTempMax?: number | null;
  outsideTempMin?: number | null;
  mode?: Types.ModeType | null;
  fan?: Types.FanType | null;
  systemState?: Types.SystemStatus | null;
  occupiedSetpointCool?: number | null;
  occupiedSetpointHeat?: number | null;
  unoccupiedSetpointCool?: number | null;
  unoccupiedSetpointHeat?: number | null;
  space?: Types.SpaceType | null;
  setLock?: Types.LockType | null;
};

export type GetNextScheduleConfigurationQueryVariables = Types.Exact<{
  systemId: Types.Scalars['Int']['input'];
}>;

export type GetNextScheduleConfigurationQuery = {
  getUpcomingSchedulerConfig?: {
    changeAt: Date;
    name?: string | null;
    config: {
      sequenceId: number;
      settings?: {
        setpoint?: number | null;
        setpointCool?: number | null;
        setpointHeat?: number | null;
        setpointMax?: number | null;
        setpointMin?: number | null;
        outsideTempMax?: number | null;
        outsideTempMin?: number | null;
        mode?: Types.ModeType | null;
        fan?: Types.FanType | null;
        systemState?: Types.SystemStatus | null;
        occupiedSetpointCool?: number | null;
        occupiedSetpointHeat?: number | null;
        unoccupiedSetpointCool?: number | null;
        unoccupiedSetpointHeat?: number | null;
        space?: Types.SpaceType | null;
        setLock?: Types.LockType | null;
      } | null;
    };
  } | null;
};

export type GetSchedulePlanQueryVariables = Types.Exact<{
  systemId: Types.Scalars['Int']['input'];
}>;

export type GetSchedulePlanQuery = {
  getSystemSchedulePlan: Array<{
    scheduleName: string;
    overridden: boolean;
    config: {
      date?: Date | null;
      dayWeek: number;
      fromHour: number;
      toHour: number;
      sequenceId: number;
      settings?: {
        setpoint?: number | null;
        setpointCool?: number | null;
        setpointHeat?: number | null;
        setpointMax?: number | null;
        setpointMin?: number | null;
        outsideTempMax?: number | null;
        outsideTempMin?: number | null;
        mode?: Types.ModeType | null;
        fan?: Types.FanType | null;
        systemState?: Types.SystemStatus | null;
        occupiedSetpointCool?: number | null;
        occupiedSetpointHeat?: number | null;
        unoccupiedSetpointCool?: number | null;
        unoccupiedSetpointHeat?: number | null;
        space?: Types.SpaceType | null;
        setLock?: Types.LockType | null;
      } | null;
    };
  }>;
};

export const SchedulerProfileSystemConfigurationSettingsFragmentFragmentDoc = gql`
  fragment SchedulerProfileSystemConfigurationSettingsFragment on SchedulerProfileSystemConfigurationSettings {
    setpoint
    setpointCool
    setpointHeat
    setpointMax
    setpointMin
    outsideTempMax
    outsideTempMin
    mode
    fan
    systemState
    occupiedSetpointCool
    occupiedSetpointHeat
    unoccupiedSetpointCool
    unoccupiedSetpointHeat
    space
    setLock
  }
`;
export const GetNextScheduleConfigurationDocument = gql`
  query GetNextScheduleConfiguration($systemId: Int!) {
    getUpcomingSchedulerConfig(systemId: $systemId) {
      changeAt
      name
      config {
        sequenceId
        settings {
          ...SchedulerProfileSystemConfigurationSettingsFragment
        }
      }
    }
  }
  ${SchedulerProfileSystemConfigurationSettingsFragmentFragmentDoc}
`;
export const GetSchedulePlanDocument = gql`
  query GetSchedulePlan($systemId: Int!) {
    getSystemSchedulePlan(systemId: $systemId) {
      scheduleName
      overridden
      config {
        date
        dayWeek
        fromHour
        toHour
        sequenceId
        settings {
          ...SchedulerProfileSystemConfigurationSettingsFragment
        }
      }
    }
  }
  ${SchedulerProfileSystemConfigurationSettingsFragmentFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetNextScheduleConfiguration(
      variables: GetNextScheduleConfigurationQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetNextScheduleConfigurationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetNextScheduleConfigurationQuery>(GetNextScheduleConfigurationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetNextScheduleConfiguration',
        'query',
        variables
      );
    },
    GetSchedulePlan(
      variables: GetSchedulePlanQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSchedulePlanQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSchedulePlanQuery>(GetSchedulePlanDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetSchedulePlan',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
