import _ from 'lodash';

import { mapAsOptions } from 'src/cdk/mappers/mapAsOptions';
import { DeviceTypes } from 'src/core/apollo/__generated__/iotGlobalTypes';
import { SensorDeviceType, SiteFeatureType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { DRAggregator } from 'src/core/apollo/__generated__/utilityGlobalTypes';
import { connectSdk, iotAPI, resourcesAPI, usersAPI, utilityAPI } from 'src/core/apollo/api';
import { GATEWAY_MANUFACTURER_OPTIONS, GATEWAY_MODEL_OPTIONS } from 'src/core/enum-options';
import { GATEWAY_MANUFACTURER_MODEL_RELATION } from 'src/core/gateway-manufacturer';
import { getSubmeterTypesForSite } from 'src/modules/systems/gql/getSubmeterTypesForSite.resources.gql';
import { getSystemModels } from 'src/modules/systems/gql/getSystemModels.resources.gql';
import type { OptionItem } from 'src/shared/components/Select';

import { getSdk as getIotSdk } from './gql/__generated__/options.iot.generated';
import { getSdk as getResourcesSdk } from './gql/__generated__/options.resources.generated';
import { getSdk as getUsersSdk } from './gql/__generated__/options.users.generated';
import { getSdk as getUtilitySdk } from './gql/__generated__/options.utility.generated';

// TODO: rework it
export type { GetDeviceModelTypesOptionsQuery } from '../options/gql/__generated__/options.iot.generated';

const api = {
  ...connectSdk(iotAPI, getIotSdk),
  ...connectSdk(resourcesAPI, getResourcesSdk),
  ...connectSdk(usersAPI, getUsersSdk),
  ...connectSdk(utilityAPI, getUtilitySdk),
};

const cachedUsers = connectSdk(usersAPI, getUsersSdk, 'cache-first');

enum DefaultElectricitySubmeterType {
  APARTMENT = 'Apartment',
  CHILLER = 'Chiller',
  COMMON = 'Common',
  CONDO_CO_OP = 'Condo/Co-Op',
  HVAC = 'HVAC',
  LIGHTING = 'Lighting',
  MANAGEMENT = 'Management',
  TENANT = 'Tenant',
  VACANT = 'Vacant',
}

const optionsApi = {
  getAllUsersOptions: cachedUsers.GlobalUserOptions,
  getGateways: () =>
    api.GetGatewaysOptions().then((r) =>
      mapAsOptions(
        r.gatewayOptions,
        'id',
        (i) => i.gatewayId.toString(),
        ({ version, siteId }) => ({ version, siteId })
      )
    ),
  getSites: () =>
    api.GetSitesOptions().then((r) =>
      mapAsOptions(r.sites, 'id', 'name', ({ regionId, streetNumber, streetName, name }) => {
        const description = [streetNumber, streetName].filter((i) => !!i).join(' ');

        return {
          regionId,
          description: description === name ? undefined : description,
        };
      })
    ),
  getRegions: () => api.GetRegionsOptions().then((r) => mapAsOptions(r.regions, 'id', 'name')),
  getOrganizations: () => api.GetOrganizationsOptions().then((r) => mapAsOptions(r.organizations, 'id', 'name')),
  getSystemGroupsBySiteAndSystemType(): Promise<{
    [siteId: number]: {
      [systemType in SystemType]: OptionItem<number>[];
    };
  }> {
    return api.GetSystemGroupsOptions().then(
      (r) =>
        _.chain(mapAsOptions(r.systemGroups, 'id', 'name', ({ type, siteId }) => ({ type, siteId })))
          .groupBy((i) => i.config!.siteId)
          .mapKeys((_, key) => Number(key))
          .mapValues((items) => _.groupBy(items, (i) => i.config!.type))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .value() as any
    );
  },
  getDRMetersBySite(): Promise<{
    [siteId: number]: OptionItem<
      number,
      {
        meterId?: string | null;
        aggregator?: DRAggregator | null;
      }
    >[];
  }> {
    return api.GetDRMeterOptions().then((r) => {
      return _.chain(
        mapAsOptions(r.getDRMeters, 'id', 'name', ({ meterId, aggregator, siteId }) => ({
          meterId,
          aggregator,
          siteId,
        }))
      )
        .groupBy((i) => i.config!.siteId)
        .mapKeys((_, key) => Number(key))
        .value();
    });
  },
  getDeviceModelTypesGrouped(type: DeviceTypes): Promise<{
    [key in SensorDeviceType | SystemType]: Awaited<
      ReturnType<typeof api.GetDeviceModelTypesOptions>
    >['getDeviceTypes'];
  }> {
    return api.GetDeviceModelTypesOptions().then((r) => {
      const deviceTypes = r.getDeviceTypes.filter((i) => i.type === type);
      return _.groupBy(deviceTypes, (i) => (type === DeviceTypes.SENSOR ? i.sensorType : i.systemType));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any;
  },
  getDeviceModelsMap() {
    return api.GetDeviceModelTypesOptions().then((r) => _.keyBy(r.getDeviceTypes, 'id'));
  },
  getDeviceProfiles() {
    return api
      .GetDeviceProfiles()
      .then((r) => (r?.getDeviceProfiles ? mapAsOptions(r.getDeviceProfiles, 'id', 'name') : []));
  },
  // TODO: integrate with backend when will be provided
  getGatewayManufacturersWithModels: () =>
    Promise.resolve(
      GATEWAY_MANUFACTURER_OPTIONS.map((option) => ({
        ...option,
        config: GATEWAY_MODEL_OPTIONS.filter((modelOption) =>
          GATEWAY_MANUFACTURER_MODEL_RELATION[option.key].includes(modelOption.key)
        ),
      }))
    ),
  systemFeatureModels: () =>
    // TODO: use new API
    getSystemModels().then((models) =>
      _.groupBy(
        _.sortBy(
          models.map((model) => ({
            key: model.modelId,
            displayValue: model.modelName,
            config: model.systemType,
          })),
          'displayValue'
        ),
        'config'
      )
    ),
  submeterTypeOptions: (siteId?: number): Promise<OptionItem<string>[]> =>
    !siteId
      ? Promise.resolve([])
      : // TODO: use new API
        getSubmeterTypesForSite(siteId).then((data) => {
          const existingOptions: OptionItem<string>[] = data.map((key) => ({
            key,
            displayValue: key,
          }));

          const defaultOptions = _.values(DefaultElectricitySubmeterType).map((key) => ({
            key,
            displayValue: key,
          }));
          return _.sortBy(_.uniqBy([...defaultOptions, ...existingOptions], 'key'), 'key');
        }),
  siteSpaceOptions: (siteId: number): Promise<OptionItem<number>[]> =>
    api
      .GetSiteSpacesOptions({ siteId })
      .then((r) => _.map(r.siteSpacesOptions, (space) => ({ key: space.id, displayValue: space.name }))),
  tenantLeaseOptions: (tenantId: number): Promise<OptionItem<number>[]> =>
    api
      .GetTenantLeaseOptions({ tenantId })
      .then((r) => _.map(r.tenantLeaseOptions, (lease) => ({ key: lease.id, displayValue: lease.accountNumber }))),
  siteUsersOptions: (
    siteId: number,
    featureType?: SiteFeatureType
  ): Promise<OptionItem<string, { description: string; organizationId: number }>[]> =>
    api.GetSiteUserOptions({ siteId, featureType }).then((r) =>
      _.map(r.siteUserOptions, (user) => ({
        key: user.id,
        displayValue: user.name,
        config: { description: user.email, organizationId: user.organizationId },
      }))
    ),
};

export default optionsApi;
