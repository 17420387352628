import { atf } from 'src/cdk/formatter/relativeTimeFormat';

export interface StatusWithDateProps {
  status: string;
  lastStatusChangedDatetime?: Date;
  timezone?: string | null;
}

const StatusWithDate: React.FC<StatusWithDateProps> = ({ status, lastStatusChangedDatetime, timezone }) => {
  return (
    <>
      <span className='body-small-semi-bold color-secondary'>{status}</span>
      {lastStatusChangedDatetime && (
        <span className='body-small color-secondary'> since {atf(lastStatusChangedDatetime, false, timezone)}</span>
      )}
    </>
  );
};

export default StatusWithDate;
