import _ from 'lodash';

import { MultiSelectWithSearch } from 'src/shared/components/Select';

import { IFilterField, IFilterFieldMultiSelect } from '../../filter.interface';
import { FilterChip } from '../FilterChip';

import { FilterLabel } from './FieldLabel';

export const isMultiSelectField = (field: IFilterField): field is IFilterFieldMultiSelect =>
  field.type === 'multiselect';

export const FilterFieldMultiSelect: React.FC<{
  field: IFilterFieldMultiSelect;
  value: unknown | undefined;
  onChange: (value: unknown | undefined) => void;
}> = (props) => {
  const valueAsArray = _.isArray(props.value) ? props.value : [];

  const [selectedItems, optionsToDisplay] = _.partition(props.field.options ?? [], (option) =>
    _.some(valueAsArray, (v) => _.isEqual(v, option.key))
  );

  if (_.isEmpty(props.field.options)) {
    return null;
  }

  return (
    <div>
      <FilterLabel field={props.field} />

      <MultiSelectWithSearch
        disabled={false}
        onClick={(value) => props.onChange([...valueAsArray, ...value])}
        options={optionsToDisplay}
        value={[]}
      />
      <div
        className='d-flex gap-8 mt-8'
        style={{
          flexWrap: 'wrap',
        }}
      >
        {selectedItems &&
          selectedItems.map((i) => (
            <FilterChip
              key={i.key as React.Key}
              label={i.displayValue}
              onDelete={() => {
                props.onChange(valueAsArray.filter((v) => !_.isEqual(v, i.key)));
              }}
            />
          ))}
      </div>
    </div>
  );
};
