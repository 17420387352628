/* eslint-disable react-redux/no-unused-prop-types */
import classNames from 'classnames';

import { SortingType } from 'src/enums';
import { SortingConfiguration } from 'src/interfaces';

import { Icon } from '../Icon/Icon';

import styles from './TableHeader.module.scss';

/**
 * Provides ability to infer type of the data passed to table column.
 */
export type TypeExpectedByColumn<T> = T extends TableColumn<infer U> ? U : never;

/**
 * By default table column has Any type, because it is not needed for header.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TableColumn<T = any, C = any> {
  /**
   * Represents title of the column
   * @example "First Name"
   */
  title: string;
  /**
   * Should be one of the keys of the data passed to table
   * @example "firstName"
   * @example "user.name"
   */
  propertyName: string;
  propertyNameToSortBy?: string;
  /**
   * Defines if table can sort by this column.
   * *Note*: currently sorting is done on front-end side only.
   */
  sortable?: boolean;
  /**
   * Can be passed to specify CSS Grid size of the column
   */
  size?: string;
  /**
   * Render function can be used to customize cell of the table for current column
   * @example
   * (row) => <p> <b>{row.user.name}</b> <br> {row.user.organization} </p>,
   */
  render?: (row: T, cellValue: C, index: number, disabled?: boolean) => JSX.Element;
  /**
   * Render function can be used to customize cell of the table for current column
   * @example
   * () => <><Icon><br><p>test text</p></>,
   */
  renderHeader?: () => JSX.Element;
  skeleton?: JSX.Element;

  /**
   * Alignning both the header and the column data
   */
  align?: 'center' | 'end';

  hideIfDisabledRow?: boolean;

  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TableColumnDocs(props: TableColumn): JSX.Element {
  return <>dummy component, used only to generate docs for TableColumn interface</>;
}

interface TableHeaderProps {
  columns: TableColumn[];
  sortConfig?: SortingConfiguration;
  updateSortConfig?: (newSortConfig: SortingConfiguration) => void;
  gridTemplateColumns?: string;
  gridColumnGap?: string;
  /**
   * Center header titles
   */
  center?: boolean;
  /**
   * Change bottom margin if needed
   */
  bottomSpace?: number;
  className?: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({ gridTemplateColumns, gridColumnGap, ...props }) => {
  function onColumnClick(column: TableColumn) {
    const sortType =
      props?.sortConfig?.sortType === SortingType.Ascending ? SortingType.Descending : SortingType.Ascending;
    const data = { sortType, propertyName: column.propertyNameToSortBy || column.propertyName };
    props?.updateSortConfig && props.updateSortConfig(data);
  }

  return (
    <div
      className={classNames(
        styles['table-header'],
        {
          [`mb-${props.bottomSpace}`]: !!props.bottomSpace,
        },
        props.className
      )}
      style={{ gridTemplateColumns, gridColumnGap }}
    >
      {props.columns.map((it, i) => (
        <div
          className={classNames('card', 'flat', styles['header-column'], {
            ['with-pointer']: it.sortable,
            [styles['sortable']]: it.sortable,
            [styles['center']]: props.center,
            [styles['column-center']]: it.align === 'center',
            [styles['column-end']]: it.align === 'end',
          })}
          key={i}
          onClick={it.sortable ? () => onColumnClick(it) : undefined}
        >
          {it.renderHeader ? it.renderHeader() : <p className='body-small-semi-bold'>{it.title}</p>}
          {it.sortable &&
            (props?.sortConfig?.propertyName === it.propertyNameToSortBy ||
              props?.sortConfig?.propertyName === it.propertyName) && (
              <Icon
                size='s'
                icon={props?.sortConfig?.sortType === SortingType.Ascending ? 'sort-up' : 'sort-down'}
                className={classNames(styles['icon'])}
              />
            )}
        </div>
      ))}
    </div>
  );
};

export default TableHeader;
