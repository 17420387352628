import Joi from 'joi';
import _ from 'lodash';

// TODO: add documentation
// TODO: add tests

function getPresense(schema?: Joi.SchemaLike): string | undefined {
  if (Joi.isSchema(schema)) {
    return schema?.$_getFlag('presence');
  }
}

function getAllowedValues(schema?: Joi.SchemaLike): unknown[] | undefined {
  if (Joi.isSchema(schema)) {
    // TODO: do not use internal fields
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (schema as any)?._valids?._values;
  }
}

function extractPresenseFromIsCondition(condition: Joi.WhenOptions, refValue: unknown): string | undefined {
  const validValues = getAllowedValues(condition.is);
  if (condition && validValues) {
    if (validValues instanceof Set && validValues.has(refValue)) {
      return getPresense(condition.then);
    }
    if (_.isArray(validValues) && validValues.includes(refValue)) {
      return getPresense(condition.then);
    }
  }
  if (condition.otherwise) {
    return getPresense(condition.otherwise);
  }
}

function extractPresenseFromSwitchCondition(condition: Joi.WhenOptions, refValue: unknown): string | undefined {
  let presence;
  for (const caseSchema of condition.switch || []) {
    presence = extractPresenseFromIsCondition(caseSchema, refValue);
    if (presence) {
      break;
    }
  }
  if (!presence) {
    presence = extractPresenseFromIsCondition(condition, refValue);
  }
  if (presence) {
    return presence;
  }
}

export function extractJoiPresence(schemaObject: Joi.Schema, valueObject: unknown): Record<string, string> {
  const result: Record<string, string> = {};
  const keys = schemaObject.$_terms.keys;
  for (const { key, schema } of keys) {
    const fieldSchema = schema;
    const basePresence = getPresense(fieldSchema);
    if (basePresence) {
      result[key] = basePresence;
    } else if (fieldSchema.$_terms.whens) {
      const whens = fieldSchema.$_terms.whens;
      if (whens.length === 1) {
        const when = whens[0];
        // Handle case when field has single "is: value" condition
        let presence;
        if (when.is) {
          // TODO: find a better way to get value by refrence
          const value = when.ref && when.ref.display !== 'ref:..' ? _.get(valueObject, when.ref.path) : valueObject;
          const condition = when.is.validate(value, {
            abortEarly: true,
            skipFunctions: true,
            stripUnknown: true,
          });
          presence = getPresense(!condition.error ? when.then : when.otherwise);
        } else if (when.switch) {
          const refValue = _.get(valueObject, when.ref.path);
          presence = extractPresenseFromSwitchCondition(when, refValue);
        }
        if (presence) {
          result[key] = presence;
        }
      }
    }
  }
  return result;
}
