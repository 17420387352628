import ReactjsPopup from '@tblbuildings/reactjs-popup';

import { PopupActions } from './interface';

interface DropdownProps {
  forwardedRef?: React.Ref<PopupActions>;
  className?: string;
  isOpen?: boolean;
  /**
   * Can be used to display big popups always under trigger
   */
  alwaysUnderTrigger?: boolean;
  triggerComponent?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  onShow?: (event?: React.SyntheticEvent) => void;
  onHide?: () => void;
  contentStyle?: React.CSSProperties;
  /**
   * Use it if you want to place dropdown in another dropdown
   */
  rootId?: string;
  children: React.ReactNode;
}

/**
 * By defaul [ReactjsPopup] does not prevents default Click event on trigger component
 * - so in different scenarios (like dropdown in link) it may cause an error.
 * But it prevents default when right-click is used as event.
 *
 * To solve the problem - event is set to "right-click", but developer needs to open modal, from code
 * it can be done via ref. See example below.
 *
 * @example
 * const popupRef = useRef<PopupActions>(null);
 * //..
 * <Dropdown
 *  forwardedRef={popupRef}
 *  triggerComponent={
 *   <button
 *     type='button'
 *     onClick={(e) => {
 *        e.preventDefault();
 *        popupRef?.current?.toggle();
 *     }}
 *     //..
 */
const Dropdown: React.FC<DropdownProps> = ({
  forwardedRef,
  className,
  isOpen,
  alwaysUnderTrigger,
  triggerComponent,
  children,
  onShow,
  onHide,
  contentStyle,
  rootId,
}) => {
  return (
    <ReactjsPopup
      ref={forwardedRef}
      trigger={triggerComponent}
      onOpen={onShow}
      onClose={onHide}
      position={alwaysUnderTrigger ? 'bottom right' : ['bottom left', 'top left', 'bottom right', 'top right']}
      closeOnDocumentClick
      nested={!!rootId}
      closeOnEscape
      disableFocusContentOnOpen={!!rootId} // Disable focus if that dropdown has nested dropdowns
      on={[]}
      arrow={false}
      modal={false}
      lockScroll={false}
      className={className}
      open={isOpen}
      contentStyle={{ ...contentStyle, paddingTop: '4px', paddingBottom: '4px' }}
      rootId={rootId ?? 'tbl-modal'} // if modal is opened - dropdown will be nested in it, otherwise it will be placed in default body root
      repositionOnResize
      automaticallyDefineMinWidth={true}
    >
      {children}
    </ReactjsPopup>
  );
};

export default Dropdown;
