import { datetimeTypePolicy, dateTypePolicy } from 'src/core/apollo/utils/type-policies';

export const scalarTypePolicies = {
  AdditionalCharge: {
    fields: {
      createdAt: datetimeTypePolicy,
      endDate: dateTypePolicy,
      startDate: dateTypePolicy,
      updatedAt: datetimeTypePolicy,
    },
  },
  ApiKey: { fields: { expirationDate: datetimeTypePolicy } },
  CalendarizedMeterConsumption: { fields: { month: dateTypePolicy, peakDemandTimestamp: datetimeTypePolicy } },
  ChargePointStation: {
    fields: {
      lastActivity: datetimeTypePolicy,
      statusChangedAt: datetimeTypePolicy,
      warrantyExpiration: datetimeTypePolicy,
    },
  },
  ChargePointStationPort: { fields: { statusChangedAt: datetimeTypePolicy } },
  ChargePointStationsInsightsKPI: { fields: { peakDemandTimestamp: datetimeTypePolicy } },
  DateInterval: { fields: { from: dateTypePolicy, to: dateTypePolicy } },
  DeviceIntegration: { fields: { installationDate: dateTypePolicy } },
  ElectricitySubmeterDetails: { fields: { lastActivity: datetimeTypePolicy } },
  ElectricitySubmeterTrendsKPI: { fields: { peakDemandTimestamp: datetimeTypePolicy } },
  HistoricalAdjustment: {
    fields: {
      invoiceBillingMonth: dateTypePolicy,
      invoiceEndPeriod: dateTypePolicy,
      invoiceStartPeriod: dateTypePolicy,
      updatedAt: datetimeTypePolicy,
    },
  },
  IAQMetricsHeatMapData: { fields: { timestamp: datetimeTypePolicy } },
  Invoice: {
    fields: {
      billingMonth: dateTypePolicy,
      dispatchDate: dateTypePolicy,
      endPeriod: dateTypePolicy,
      startPeriod: dateTypePolicy,
    },
  },
  LeaseBilling: { fields: { billingStartDate: dateTypePolicy } },
  LeaseBillingCharge: { fields: { createdAt: datetimeTypePolicy, endDate: dateTypePolicy, startDate: dateTypePolicy } },
  LeaseGeneral: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  LeaseShort: { fields: { startBillingDate: dateTypePolicy } },
  PendingAdjustmentInfo: {
    fields: {
      endDate: dateTypePolicy,
      leaseStartBillingDate: dateTypePolicy,
      nextInvoiceDate: dateTypePolicy,
      startDate: dateTypePolicy,
      updatedAt: datetimeTypePolicy,
    },
  },
  SchedulerProfileCoverage: { fields: { lastUpdated: datetimeTypePolicy } },
  SchedulerProfileSystemConfiguration: { fields: { date: dateTypePolicy } },
  SensorExtendedGeneralInfo: { fields: { updatedAt: datetimeTypePolicy } },
  SensorFullInfo: { fields: { updatedAt: datetimeTypePolicy } },
  SmartOutletTrendsData: { fields: { timestamp: datetimeTypePolicy } },
  Space: { fields: { updatedAt: datetimeTypePolicy } },
  SpaceOccupationHistoryTableData: { fields: { expirationDate: dateTypePolicy, occupationDate: dateTypePolicy } },
  SpaceOverview: { fields: { occupationEndDate: dateTypePolicy, occupationStartDate: dateTypePolicy } },
  SpaceSystem: { fields: { dateAdded: dateTypePolicy } },
  StackingPlan: { fields: { expirationDate: dateTypePolicy, occupationDate: dateTypePolicy } },
  SubmeterRate: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  SubmetersHeatMapData: { fields: { timestamp: datetimeTypePolicy } },
  SubmetersTrendsData: { fields: { timestamp: datetimeTypePolicy } },
  SystemChargePointStation: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemElectricityMeter: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemElectricitySubmeter: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemFullInfo: { fields: { configurationUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy } },
  SystemGroup: { fields: { updatedAt: datetimeTypePolicy } },
  SystemHwBoilerHtHwr: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemHwBoilerTekmar248: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemLightingVive: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemMeterBreakdownTable: { fields: { peakDemandTimestamp: datetimeTypePolicy } },
  SystemMetricData: { fields: { timestamp: datetimeTypePolicy } },
  SystemNaturalGasMeter: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemOverriddenSchedulerProfileModel: { fields: { endTime: datetimeTypePolicy } },
  SystemPackageAltc24Prog: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemPackageEcobeeDefault: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemPaginatedGeneralInfo: { fields: { configurationUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy } },
  SystemSensorGeneralInfo: { fields: { dateAdded: datetimeTypePolicy, updatedAt: datetimeTypePolicy } },
  SystemSmartOutlet: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemStBoilerFHtMpc: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemSteamMeter: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemSteamPrvStation: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemTblTci: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemWaterMeter: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  SystemWeatherStationWts506: {
    fields: { lastActivity: datetimeTypePolicy, statusUpdatedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  UpcomingScheduleConfig: { fields: { changeAt: datetimeTypePolicy } },
};
