import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  SelectedMetricsForSystemQuery,
  SelectedMetricsForSystemQueryVariables,
} from './__generated__/getMetricsForSystem.resources.gql';

export type ISystemAnalyticMetric = SelectedMetricsForSystemQuery['systemMetrics'][0];

const GET_METRICS_FOR_SYSTEM = gql`
  query SelectedMetricsForSystem($systemIds: [Int!]!) {
    systemMetrics(systemIds: $systemIds) {
      id
      name
      type
      measurement
      view
      relationType
      systemId
    }
  }
`;

export async function getMetricsForSystem(systemIds: number[]): Promise<ISystemAnalyticMetric[]> {
  try {
    const result = await resourcesAPI.query<SelectedMetricsForSystemQuery, SelectedMetricsForSystemQueryVariables>({
      query: GET_METRICS_FOR_SYSTEM,
      variables: {
        systemIds,
      },
    });

    return result.data.systemMetrics;
  } catch (e) {
    console.error(e);
    return [];
  }
}
