import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { Icon } from '../Icon/Icon';
import { SupportedIcon } from '../Icon/gen/suported-icons';

import styles from './Input.module.scss';

export interface InputProps<T = string> {
  placeholder?: string;
  icon?: SupportedIcon;

  /**
   * disabled={false} by default
   */
  disabled?: boolean;
  value: T;
  className?: string;
  onChange: (value: T) => void;
  onPaste?: (value: T) => void;
  onBlur?: (value: T) => void;
  updateOnFocus?: (value: T) => T;
  onSubmit?: (element: HTMLInputElement) => void;
  type?: 'text' | 'number' | 'password' | 'email' | 'currency';
  min?: number;
  max?: number;
  /**
   * 'id' allows to bond label and corresponding input field inside a form
   */
  id?: string;
  tabIndex?: number;
  /**
   * autoFocus={false} by default
   */
  autoFocus?: boolean;
  maxLength?: number;
  readonly?: boolean;
  /**
   * Regex pattern to match inputed value
   */
  pattern?: string;
  required?: boolean;
  hasError?: boolean;
  onEscape?: (element: HTMLInputElement) => void;
  inputMask?: Inputmask.Instance;
}

export function Input<T extends string | number | readonly string[] | undefined = string>({
  placeholder,
  icon,
  disabled = false,
  value,
  className,
  onChange,
  onBlur,
  onSubmit,
  type = 'text',
  min,
  max,
  id,
  tabIndex,
  autoFocus = false,
  maxLength,
  readonly = false,
  pattern,
  required = false,
  hasError,
  onEscape,
  onPaste,
  updateOnFocus,
  inputMask,
}: InputProps<T>): React.ReactElement {
  const inputRef = useRef(null);
  const maskRef = useRef<Inputmask.Instance>();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (maskRef.current) {
      maskRef.current.remove();
    }
    if (inputMask && inputRef.current) {
      maskRef.current = inputMask.mask(inputRef.current);
    }
    return () => {
      maskRef.current?.remove();
      maskRef.current = undefined;
    };
  }, [inputMask]);

  return (
    <div className={classNames(styles['input-wrapper'], className)}>
      {icon && <Icon icon={icon} size='s' className={styles['icon']} />}
      <input
        ref={inputRef}
        readOnly={readonly}
        tabIndex={tabIndex}
        className={classNames('input', styles['input'], {
          [styles['with-icon']]: !!icon,
          ['touched']: touched,
          ['has-error']: hasError,
        })}
        type={type}
        min={min}
        max={max}
        name={id}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={(e) => {
          if (updateOnFocus) {
            const result = updateOnFocus(e.target.value as T);
            onChange(result);
          }
        }}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value as T);
        }}
        onPaste={(e) => {
          if (onPaste) {
            e.preventDefault();
            onPaste(e.clipboardData.getData('text') as T);
          }
        }}
        onBlur={(e) => {
          e.preventDefault();
          if (!touched) {
            setTouched(true);
          }
          onBlur && onBlur(e.target.value as T);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onSubmit) {
            onSubmit(e.currentTarget);
          }
          if (e.key === 'Escape' && onEscape) {
            onEscape(e.currentTarget);
          }
        }}
        value={value as T}
        pattern={pattern}
        required={required}
        autoFocus={autoFocus}
        maxLength={maxLength}
      />
    </div>
  );
}
