import _, { isNumber } from 'lodash';

import { numberInAbbrFormat } from 'src/cdk/formatter/numberFormatter';
import { METRIC_SYMBOL, N_A } from 'src/constants';
import { MetricsMeasurementType, MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { ColorConfig } from 'src/enums';

import { RenderUnit } from '../../Unit/RenderUnit';

export interface IMetricsDataAtIndex {
  id: number | string;
  styleVariant: ColorConfig;
  customStyleVariant?: string;
  value: number | string; // instead of undefined, set empty string, to have consistent sorting.
  measurementLabel: React.ReactNode;
  measurementType: MetricsMeasurementType;
  name: string | JSX.Element;
  max?: number | string;
  min?: number | string;
  systemName?: string;
  /**
   * If none - don't render icon
   *
   * @default 'line'
   */
  renderAs?: 'square' | 'line' | 'none';
}

export interface ITooltipData {
  titleOrTimestamp: Date | string;
  subtitle?: string;
  metricsDataAtPointedIndex: IMetricsDataAtIndex[];
  stepIndex?: number;
}

export interface IFitwellData {
  value: number;
  measurement: MetricsMeasurementType;
}

//TODO: Review interface optional fields
export interface IChartLine {
  data?: (IChartData | null)[];
  measurement: MetricsMeasurementType;
  /**
   * styleVariant variation that of the checkbox;
   * ColorConfig.blueSolidOpaque by default
   */
  styleVariant?: ColorConfig;
  id: number | string;
  name: string | JSX.Element;
  precision?: number;
  systemId?: number;
}

export interface IChartData {
  timestamp: Date;
  value?: number | null;
  min?: number | null;
  max?: number | null;
}

/**
 * If tick value is not integer - ignore it
 */
export function formatVerticalTick(
  tick: number,
  index: number,
  lastIndex: number,
  measurement?: string,
  isDecimalTicsSupported = false
): string {
  if (_.isInteger(tick) || isDecimalTicsSupported) {
    return index !== lastIndex || !measurement ? numberInAbbrFormat(tick) : measurement || '';
  } else {
    return index === lastIndex ? measurement || '' : '';
  }
}

export function getDomainY(lines: IChartLine[], fitwell?: IFitwellData): [number, number] {
  const flatLines = _.flatMap(lines, 'data');

  if (fitwell) {
    flatLines.push({ value: fitwell.value });
  }

  const min = _.minBy(flatLines, 'value')?.value;
  const max = _.maxBy(flatLines, 'value')?.value;

  if (!isNumber(min) || !isNumber(max)) {
    return [-1, 1];
  }

  //2% of buffer, so lines won't touch border of the chart
  const diff = max - min;
  let buffer = diff * 0.02;

  if (buffer < 1) {
    buffer = 1;
  }

  return [min - buffer, max + buffer];
}

/**
 * Define how to format single tick
 *
 * For ten minutes step - return 11:00 AM
 * For hour step - return 1a 2p
 * For day step & less or equal 7 days - return 31st Sep
 * For day step - return 31
 */
export function formatXAxis(
  tick: Date,
  shouldRender: (time: Date) => boolean,
  axisRender: (time: Date) => string,
  tickIndex: number,
  isTrendline: boolean,
  selectedTick?: number
): string {
  if ((isTrendline && tickIndex !== selectedTick) || (!isTrendline && !shouldRender(tick))) {
    return '';
  }

  return axisRender(tick);
}

export function getOpacityForUsageMetrics(metricsStep: MetricsStep, value?: number | null): number {
  if (isNumber(value)) {
    switch (metricsStep) {
      case MetricsStep.FIFTEEN_MINUTES:
        return value / 15;
      case MetricsStep.HOUR:
        return value / 60;
      case MetricsStep.DAY:
        return value / (60 * 24);
    }
  }
  return 0;
}

export function formatValueToDisplay(value?: number | null, precision?: number): string | number {
  if (isNumber(value)) {
    if (precision) {
      return Number(value.toFixed(precision));
    } else {
      return Math.round(value);
    }
  } else {
    return N_A; // empty string is for sorting, to keep the value last
  }
}

/**
 * Gets frame of metricsData from chartLines with on the position `stepIndex`
 */
export function getMetricsDataAtIndex(
  chartLines: IChartLine[],
  usageChartLines: IChartLine[],
  stepIndex: number,
  metricsStep: MetricsStep,
  fitwell?: IFitwellData,
  systemsInfo?: { id: number; name: string }[]
): IMetricsDataAtIndex[] {
  const result: IMetricsDataAtIndex[] = mapDataToMetrics(chartLines, stepIndex, metricsStep, systemsInfo);
  const usageResult: IMetricsDataAtIndex[] = mapDataToMetrics(usageChartLines, stepIndex, metricsStep, systemsInfo);

  if (fitwell) {
    result.push({
      id: -1,
      value: fitwell.value,
      measurementLabel: '',
      measurementType: fitwell.measurement,
      styleVariant: ColorConfig.whiteSolidTranslucent,
      name: 'Fitwell',
      ...(metricsStep !== MetricsStep.FIFTEEN_MINUTES && {
        min: fitwell.value,
        max: fitwell.value,
      }),
    } as IMetricsDataAtIndex);
  }

  // Order by:
  // 1. measurement type
  // 2. put empty values in the bottom
  // 3. value
  // 4. name
  const orderedResult = _.orderBy(
    result,
    ['measurementType', (result) => isNaN(Number(result.value)), (result) => Number(result.value), 'name'],
    ['asc', 'asc', 'desc', 'asc']
  );
  return [...orderedResult, ...usageResult];
}

function mapDataToMetrics(
  data: IChartLine[],
  stepIndex: number,
  metricsStep: MetricsStep,
  systemsInfo?: { id: number; name: string }[]
): IMetricsDataAtIndex[] {
  const systems = systemsInfo && _.keyBy(systemsInfo, 'id');
  const systemIdsAdded = new Set<number>();

  return data.map((line) => {
    const { value, min, max } = (line.data && line.data[stepIndex]) || {};
    let systemName;
    if (line.systemId && !systemIdsAdded.has(line.systemId) && systems) {
      systemName = systems[line.systemId]?.name;
      systemIdsAdded.add(line.systemId);
    }

    const r = {
      id: line.id,
      value: formatValueToDisplay(value, line.precision),
      measurementLabel: <RenderUnit unit={METRIC_SYMBOL[line.measurement]} />,
      styleVariant: line.styleVariant ?? ColorConfig.whiteSolidTranslucent,
      measurementType: line.measurement,
      name: line.name,
      ...(metricsStep !== MetricsStep.FIFTEEN_MINUTES && {
        min: formatValueToDisplay(min, line.precision),
        max: formatValueToDisplay(max, line.precision),
      }),
      systemName,
    };
    return r;
  });
}

/**
 * Width of block for hover
 *
 * Block with stepIndex=0 has only half of the width
 */
export function calculateWidthStepBlock(numXTicks: number, xMax: number, stepIndex: number): number {
  const width = xMax / (numXTicks - 1);
  if (stepIndex === 0 || stepIndex === numXTicks - 1) {
    return width / 2;
  }
  return width;
}

/**
 * X coordinate of block for hover
 */
export function calculateXCoordinateOfStepBlock(numXTicks: number, xMax: number, stepIndex: number): number {
  const widthOfStepBlock = xMax / (numXTicks - 1);
  let leftOffset = 0;
  if (stepIndex > 0) {
    // Left offset is needed because block with stepIndex=0(first block) is half of the width,
    // so we need to offset each next block to this distance
    leftOffset = widthOfStepBlock / 2;
  }

  return widthOfStepBlock * stepIndex - leftOffset;
}

export const usageConfig = {
  padding: 1,
  lineHeight: 12,
  verticalPadding: 8,
  spaceBetweenBlocks: {
    large: 2,
    small: 1,
    none: 0,
  },
  borderRadius: {
    large: 3,
    small: 2,
    none: 0,
  },
};

export function calcUsageVerticalOffset(index: number): number {
  return index * usageConfig.lineHeight + usageConfig.verticalPadding * index + usageConfig.verticalPadding;
}
