import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import type { RootState } from 'src/core/store/store';
import { LoadingStatus, ROLE_RANK, UserPermission } from 'src/enums';
import api, { Department, User } from 'src/logic/users/gql';

import { getSystemGroupsForSite } from '../../modules/systems/gql/getSystemsForSite.resources.gql';

import * as T from './permissionsTree';

interface PermissionsSlice {
  expandedRegion: null | number;
  expandedSite: null | number;
  //
  treeStatus: LoadingStatus;
  // used for recalculation of organization data
  oldRoleRank: number;
  organizationId: number;
  //
  tree: T.Tree;
  defaultTree: T.Tree;
}

const initialState: PermissionsSlice = {
  expandedRegion: null,
  expandedSite: null,
  treeStatus: LoadingStatus.LOADED,
  oldRoleRank: 0,
  organizationId: 0,
  tree: [],
  defaultTree: [],
};

export const loadRegionsToSystemsTree = createAsyncThunk(
  'permissions/loadRegionsToSystemsTree',
  async (organizationId?: number) => {
    // TODO: split onto two thunks
    const [systemGroups, treeResponse] = await Promise.all([
      getSystemGroupsForSite(),
      api.GetRegionsToSystemsTree({ organizationId }),
    ]);
    return {
      systemGroups,
      treeResponse: treeResponse.getRegionsToSystemsTree,
    };
  }
);

export const resetRegionsToSystemsTree = createAsyncThunk('permissions/loadRegionsToSystemsTree', async () => {
  return {
    systemGroups: [],
    treeResponse: [],
  };
});

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: initialState,
  reducers: {
    resetSlice: () => initialState,
    setUserPermissions: (state, action: PayloadAction<{ user: User; departments?: Department[] } | undefined>) => {
      const { user, departments = [] } = action.payload || {};
      state.expandedSite = null;
      state.expandedRegion = null;
      state.oldRoleRank = 0;
      state.organizationId = 0;
      if (user) {
        if (user.role === UserRoles.ADMIN) {
          T.preselectTreeForAdmin(state.tree, user.organization_id!);
        }

        for (const department of departments) {
          T.preselectTreeForPermissions(state.tree, department);
        }

        T.selectTreeForPermissions(state.tree, user);
        state.oldRoleRank = ROLE_RANK[user.role];
        state.organizationId = user.organization_id;

        if (user.role === UserRoles.SUPER_ADMIN) {
          // Clean all values for super-admin
          T.toggleSelectForAllNodes(state.tree, false);
          T.togglePreselectForAllNodes(state.tree, true);
        }
        T.removeDoubleFlags(state.tree);
      }
    },
    setDepartmentPermissions: (state, action: PayloadAction<Department | undefined>) => {
      const department = action.payload;
      state.expandedSite = null;
      state.expandedRegion = null;
      state.oldRoleRank = 0;
      state.organizationId = 0;

      if (department) {
        state.tree = T.filterTreeForOrg(state.defaultTree, department.organizationId);
        T.selectTreeForPermissions(state.tree, department);
      }
      T.removeDoubleFlags(state.tree);
    },
    toggleItemSelected: (state, action: PayloadAction<T.NodePath>) => {
      const node = T.findNode(state.tree, action.payload);
      if (!node) {
        console.warn('Node not found for path ', action.payload);
        return;
      }
      T.toggleNode(node);
      T.selectParentNodes(state.tree, action.payload);
      T.removeDoubleFlags(state.tree);
    },
    toggleSeveralItemsSelected: (
      state,
      action: PayloadAction<{
        paths: T.NodePath[];
        state: boolean;
      }>
    ) => {
      for (const path of action.payload.paths) {
        const node = T.findNode(state.tree, path);
        if (!node) {
          console.warn('Node not found for path ', action.payload);
          continue;
        }
        T.toggleNode(node, action.payload.state);
      }
      T.selectParentNodes(state.tree, action.payload.paths[0]);
      T.removeDoubleFlags(state.tree);
    },
    expandRegion: (state, action: PayloadAction<number>) => {
      state.expandedRegion = action.payload;
      state.expandedSite = null;
    },
    expandSite: (state, action: PayloadAction<number>) => {
      state.expandedSite = action.payload;
    },
    deExpandItem: (state, action: PayloadAction<UserPermission>) => {
      switch (action.payload) {
        case UserPermission.REGION:
          state.expandedRegion = null;
          state.expandedSite = null;
          break;
        case UserPermission.SITE:
          state.expandedSite = null;
      }
    },
    handleRoleChange: (state, action: PayloadAction<UserRoles>) => {
      const { oldRoleRank } = state;
      const role = action.payload;
      const newRoleRank = ROLE_RANK[role];

      // if user was demoted from admin or super-admin
      // remove all old access to organizations permissions.
      if (oldRoleRank > newRoleRank) {
        T.toggleSelectForAllNodes(state.tree, false);
        T.togglePreselectForAllNodes(state.tree, false);
      }

      // if user was promoted from user to admin
      // provide access to the organization
      if (newRoleRank === ROLE_RANK[UserRoles.ADMIN]) {
        T.togglePreselectForAllNodes(state.tree, false);
        T.preselectTreeForAdmin(state.tree, state.organizationId);
      }

      // if super-admin - provide access to everything
      if (newRoleRank === ROLE_RANK[UserRoles.SUPER_ADMIN]) {
        T.toggleSelectForAllNodes(state.tree, false);
        T.togglePreselectForAllNodes(state.tree, true);
      }

      state.oldRoleRank = newRoleRank;
      T.removeDoubleFlags(state.tree);
    },
    handleOrgChange: (state, action: PayloadAction<number>) => {
      const organizationId = action.payload;
      const roleRank = state.oldRoleRank;

      // if user is already an admin or super-admin - do not change anything
      if (roleRank === ROLE_RANK[UserRoles.SUPER_ADMIN]) {
        // Do nothing
      }

      // if user is an admin
      // provide access to the organization
      if (roleRank === ROLE_RANK[UserRoles.ADMIN]) {
        T.togglePreselectForAllNodes(state.tree, false);
        T.preselectTreeForAdmin(state.tree, state.organizationId);
      }

      state.organizationId = organizationId;
      T.removeDoubleFlags(state.tree);
    },
    handleDepartmentsChange: (state, action: PayloadAction<Department[]>) => {
      const departments = action.payload;
      const roleRank = state.oldRoleRank;

      // if user is already an admin or super-admin - do not change anything
      if (roleRank === ROLE_RANK[UserRoles.SUPER_ADMIN] || roleRank === ROLE_RANK[UserRoles.ADMIN]) {
        // Do nothing
        return;
      }

      // if user is an admin
      // provide access to the organization
      T.togglePreselectForAllNodes(state.tree, false);
      for (const department of departments) {
        T.preselectTreeForPermissions(state.tree, department);
      }
      T.removeDoubleFlags(state.tree);
    },
    filterByOrg: (state, action: PayloadAction<number>) => {
      const organizationId = action.payload;
      state.tree = T.filterTreeForOrg(state.defaultTree, organizationId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadRegionsToSystemsTree.pending, (state) => {
        state.treeStatus = LoadingStatus.WAITING;
        state.expandedSite = null;
        state.expandedRegion = null;
      })
      .addCase(loadRegionsToSystemsTree.fulfilled, (state, action) => {
        const { treeResponse, systemGroups } = action.payload;
        state.treeStatus = LoadingStatus.LOADED;

        state.tree = T.mapResponsesToModel(treeResponse, systemGroups || []);
        state.defaultTree = _.cloneDeep(state.tree);
      })
      .addCase(loadRegionsToSystemsTree.rejected, (state) => {
        state.treeStatus = LoadingStatus.FAILED;
      });
  },
});

export const {
  resetSlice: resetPermissionsSlice,
  toggleItemSelected,
  toggleSeveralItemsSelected,
  expandRegion,
  expandSite,
  setUserPermissions,
  setDepartmentPermissions,
  deExpandItem,
  handleRoleChange,
  handleOrgChange,
  handleDepartmentsChange,
  filterByOrg,
} = permissionsSlice.actions;

export const selectRegions = (state: RootState) => T.getFirstLayerNodes(state.permissions.tree);

export const selectSites = (state: RootState) =>
  T.getSecondLayerNodes(state.permissions.tree, state.permissions.expandedRegion);

export const selectSiteItems = (state: RootState) =>
  T.getThirdLayerNodes(state.permissions.tree, state.permissions.expandedRegion, state.permissions.expandedSite);

export const selectTreeIsLoading = (state: RootState): boolean =>
  state.permissions.treeStatus === LoadingStatus.WAITING;

export const selectItemsErrorMessage =
  (type: UserPermission) =>
  (state: RootState): string | undefined => {
    const { expandedRegion, expandedSite, treeStatus, tree: ttree } = state.permissions;
    if (treeStatus === LoadingStatus.FAILED) {
      return 'Failed to load permissions';
    }

    switch (type) {
      case UserPermission.REGION:
        if (_.isEmpty(ttree)) {
          return 'No regions were found';
        }
        return undefined;
      case UserPermission.SITE:
        if (!expandedRegion) {
          return 'First select region';
        }
        const regionNode = T.findNode(ttree, { regionId: expandedRegion });
        if (_.isEmpty(regionNode?.children)) {
          return 'No sites exists for this region';
        }
        return undefined;
      case UserPermission.SYSTEMS:
        if (!expandedRegion) {
          return 'First select region';
        }
        if (!expandedSite) {
          return 'First select site';
        }
        const siteNode = T.findNode(ttree, { regionId: expandedRegion, siteId: expandedSite });
        if (_.isEmpty(siteNode?.children)) {
          return 'No systems or integrations exists for this site';
        }
        return undefined;
    }
  };

export const selectExpandedRegionId = (state: RootState): number | null => state.permissions.expandedRegion;

export const selectExpandedSiteId = (state: RootState): number | null => state.permissions.expandedSite;

export const selectTree = (state: RootState) => state.permissions.tree;

export default permissionsSlice.reducer;
