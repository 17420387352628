import { gql } from '@apollo/client';
import { format } from 'date-fns';
import _ from 'lodash';

import { DATE_FORMAT } from 'src/constants';
import { resourcesAPI } from 'src/core/apollo/api';
import { OptionItem } from 'src/shared/components/Select';

import {
  GetLeasesBySiteQuery,
  GetLeasesBySiteQueryVariables,
} from './__generated__/getBillableLeasesOptionsForSite.resources.gql';

export type LeaseInfo = GetLeasesBySiteQuery['getLeasesBySite'] & { startBillingDate: Date };

const GET_LEASES_OPTIONS_FOR_SITE = gql`
  query GetLeasesBySite($siteId: Int!) {
    getLeasesBySite(siteId: $siteId) {
      id
      accountNumber
      tenantId
      startBillingDate
    }
  }
`;

export async function getBillableLeasesOptionsForSite(
  siteId: number
): Promise<OptionItem<number, { tenantId: number; startBillingDate: Date }>[]> {
  const result = await resourcesAPI.query<GetLeasesBySiteQuery, GetLeasesBySiteQueryVariables>({
    query: GET_LEASES_OPTIONS_FOR_SITE,
    variables: {
      siteId,
    },
  });

  return _.uniqBy(
    result.data.getLeasesBySite
      .filter(({ startBillingDate }) => Boolean(startBillingDate))
      .map((item) => ({
        key: item.id,
        displayValue: item.accountNumber?.toString() || item.id.toString(),
        config: {
          tenantId: item.tenantId,
          startBillingDate: item.startBillingDate!,
          description: format(item.startBillingDate!, DATE_FORMAT.DATE_SHORT),
        },
      })),
    'key'
  );
}
