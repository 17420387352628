import { SystemGroupType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';

/**
 * Guarantee that no system type will be lost/forgotten, when new type will be added
 */
const mapper: Record<SystemType, SystemGroupType> = {
  [SystemType.STEAM_PRV_STATION]: SystemGroupType.STEAM_PRV,
  [SystemType.HW_BOILER_HT_HWR]: SystemGroupType.STEAM_BOILER,
  [SystemType.HW_BOILER_TEKMAR_284]: SystemGroupType.STEAM_BOILER,
  [SystemType.ST_BOILER_F_HT_MPC]: SystemGroupType.STEAM_BOILER,
  [SystemType.PACKAGE_ALTC24PROG]: SystemGroupType.AIR_HANDLING_UNIT,
  [SystemType.PACKAGE_ECOBEE_DEFAULT]: SystemGroupType.AIR_HANDLING_UNIT,
  [SystemType.PACKAGE_HONEYWELL_TC500AN]: SystemGroupType.AIR_HANDLING_UNIT,
  [SystemType.TBL_TCI]: SystemGroupType.TBL_TCI,
  [SystemType.LUTRON_VIVE_LIGHTING]: SystemGroupType.LIGHTING_SYSTEM,
  [SystemType.ELECTRICITY_SUBMETER]: SystemGroupType.SUBMETER,
  [SystemType.ELECTRICITY_METER]: SystemGroupType.METER,
  [SystemType.NATURAL_GAS_METER]: SystemGroupType.METER,
  [SystemType.STEAM_METER]: SystemGroupType.METER,
  [SystemType.WATER_METER]: SystemGroupType.METER,
  [SystemType.WEATHER_STATION_WTS506]: SystemGroupType.WEATHER_STATION,
  [SystemType.SMART_OUTLET_T0006623]: SystemGroupType.SMART_OUTLET,
  [SystemType.CHARGE_POINT]: SystemGroupType.CHARGE_POINT,
};

export function mapSystemTypeToGroupType(type?: SystemType | null): SystemGroupType | null {
  return type ? mapper[type] ?? null : null;
}
