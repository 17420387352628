import { format } from 'date-fns';
import { type Matcher, type DateRange, isDateAfterType, isDateBeforeType, isDateRange } from 'react-day-picker';

export function toYearMonth(date: Date | null | undefined) {
  if (!date) {
    return isNaN;
  }

  return Number(`${format(date, 'yyyyMM')}`);
}

function isMonthInRange(date: Date, range: DateRange): boolean {
  let { from, to } = range;
  if (from && to) {
    const isRangeInverted = toYearMonth(to) < toYearMonth(from);
    if (isRangeInverted) {
      [from, to] = [to, from];
    }
    return toYearMonth(date) >= toYearMonth(from) && toYearMonth(date) <= toYearMonth(to);
  }

  if (to) {
    return toYearMonth(date) < toYearMonth(to);
  }

  if (from) {
    return toYearMonth(date) > toYearMonth(from);
  }

  return false;
}

export function monthMatchModifiers(date: Date, matchers: Matcher | Matcher[]): boolean {
  const matchersArr = !Array.isArray(matchers) ? [matchers] : matchers;
  return matchersArr.some((matcher: Matcher) => {
    if (isDateRange(matcher)) {
      return isMonthInRange(date, matcher);
    }
    if (isDateAfterType(matcher)) {
      return toYearMonth(date) > toYearMonth(matcher.after);
    }
    if (isDateBeforeType(matcher)) {
      return toYearMonth(date) < toYearMonth(matcher.before);
    }

    throw new Error(`Mathcer type is not supported.`);
  });
}
