import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { selectSiteById } from 'src/modules/sites/sitesSlice';
import { mapSystemTypeToColumnConfig } from 'src/modules/systems/pages/SystemPage/config';
import SmartOutletCard from 'src/modules/systems/system-cards/SmartOutletCard/SmartOutletCard';
import { selectedSystemGroupById, selectedSystemsBySystemGroupById } from 'src/modules/systems/systemsSlice';
import ElevatedBox from 'src/shared/components/ElevatedBox/ElevatedBox';
import { CircularLoader } from 'src/shared/components/Loader/Loader';
import TableHeader from 'src/shared/components/TableHeader/TableHeader';

const SystemGroupsPage: React.FC = () => {
  const systemGroupId = useNumberParam('id');
  const systemGroup = useSelector(selectedSystemGroupById(systemGroupId));
  const systems = useSelector(selectedSystemsBySystemGroupById(systemGroupId), _.isEqual);
  const site = useSelector(selectSiteById(systemGroup?.siteId));

  if (!systems || !systemGroup) {
    return <CircularLoader />;
  }

  if (!systems?.[0]) {
    <ElevatedBox error>Failed to load zones</ElevatedBox>;
  }

  return (
    <section className='card el-04 p-24'>
      <TableHeader
        columns={mapSystemTypeToColumnConfig[systemGroup.type] ?? []}
        className='universal-card-table-view'
      />
      {systems &&
        systems.map((system) => {
          switch (system.type) {
            case SystemType.SMART_OUTLET_T0006623:
              return (
                <SmartOutletCard
                  key={system.id}
                  groupId={systemGroup.id}
                  systemId={system.id}
                  timezone={site?.timezone}
                />
              );
            default:
              return <></>;
          }
        })}
    </section>
  );
};

export default SystemGroupsPage;
