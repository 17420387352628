import React, { useState } from 'react';

import { Input } from 'src/shared/components/Input/Input';

interface Props {
  id?: string;
  name?: string;
  onChange: VoidFunction;
}

const ConfigForm: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string>(props.name ?? '');

  return (
    <div className='d-flex flex-col gap-8 mb-8'>
      <p className='color-secondary body-small'>Configuration Name</p>
      <input type='hidden' name='id' value={props.id} />
      <Input
        required
        placeholder='Enter Config name'
        id='configName'
        className='w-100'
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          props.onChange();
        }}
        onBlur={() => {
          props.onChange();
        }}
      />
      {!props.id && (
        <div className='d-flex'>
          <p
            className='color-secondary caption break-white-space'
            // That style will fit the text to the width of the container
            style={{
              flex: 'auto',
              width: '0',
            }}
          >
            Current filter selections will be saved as a Configuration.
          </p>
        </div>
      )}
    </div>
  );
};

export default ConfigForm;
