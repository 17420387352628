import { EffectCallback, useEffect, useRef } from 'react';

export function useOnMount(effect: EffectCallback): void {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!isFirstRun.current) {
      return;
    }
    isFirstRun.current = false;
    return effect();
  }, []);
}
