import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

import { useNumberParam } from 'src/cdk/hooks/useNumberParam';
import { SiteFeatureType, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { useAppDispatch, useAppSelector } from 'src/core/store/hooks';
import DetailsPageLayout from 'src/fragments/layouts/DetailsPageLayout';
import { readAllAlertNotificationsForSystems } from 'src/logic/alerts/alertsActions';
import { selectSiteHasFeature } from 'src/modules/sites/sitesSlice';
import { fetchSingleSystemIfNotExist } from 'src/modules/systems/systemsActions';
import { selectedSystemById } from 'src/modules/systems/systemsSlice';
import { Routes } from 'src/routes';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';

interface Props {
  settings: Partial<Record<SystemType, [Routes, string[]]>>;
}

const SystemDetailsPage: React.FC<Props> = ({ settings }) => {
  const dispatch = useAppDispatch();
  const systemId = useNumberParam('id');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  const system = useSelector(selectedSystemById(systemId));
  const hasScheduleFeature = useAppSelector(selectSiteHasFeature(system?.siteId, SiteFeatureType.SCHEDULING));

  useEffect(() => {
    dispatch(readAllAlertNotificationsForSystems([systemId]));
    dispatch(fetchSingleSystemIfNotExist(systemId))
      .catch(() => setIsFailed(true))
      .finally(() => setIsLoading(false));
  }, [systemId]);

  const [returnPage, tabs] = useMemo(() => {
    let returnPageUrl = '..';
    let additionalTabs: string[] = [];

    if (system && settings[system.type]) {
      returnPageUrl = settings[system.type]![0];
      additionalTabs = [...settings[system.type]![1]];
    }

    if (!_.isNil(_.get(system, 'averageSpaceTemperature'))) {
      additionalTabs.push('Space Temperature');
    }

    if (hasScheduleFeature) {
      additionalTabs.push('Schedule');
    }

    return [returnPageUrl, ['General', ...additionalTabs, 'Activity']];
  }, [system?.type, settings, hasScheduleFeature]);

  return (
    <DetailsPageLayout
      backUrl={returnPage}
      idParamName='id'
      entity='System'
      entityName={system?.name || ''}
      relatedSiteId={system?.siteId}
      isInitialLoading={isLoading}
      isFailed={isFailed}
      removeBottomMargin
    >
      <UrlTabs names={tabs} />
      <Outlet />
    </DetailsPageLayout>
  );
};

export default SystemDetailsPage;
