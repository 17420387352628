import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltipInPortal } from '@visx/tooltip';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import withParentSizeThrottle from 'src/cdk/HOCs/withParentSizeThrottle';

import styles from './ComposableChartWrapper.module.scss';
import { ChartContextProvider, ChartPadding } from './chartContext';

/**
 * Type for xDomain. It is an array of strings, numbers or dates.
 */
export type XDomain = (string | number | Date)[];
export type YDomain = [number, number];

interface Props {
  xDomain: XDomain;
  yDomain: YDomain;
  /**
   * Amount of pixels chart should take
   */
  isLoading?: boolean;
  /**
   * Amount of pixels chart should take. It is required to set this prop to avoid jumping of the chart
   * and prevent infinite loop of rendering.
   *
   * If height is passed as a string - then extraSpaceBetweenXAxisAndChart is ignored
   */
  height: number | string;
  isEmpty?: boolean;
  bubbleClassName?: string;
  padding?: ChartPadding;
  /**
   * If specified - it will not increase the height of "drawing area" of the chart,
   * but it will increase the height of the SVG chart.
   */
  extraSpaceBetweenXAxisAndChart?: number;
}

const DEFAULT_PADDING: ChartPadding = {
  top: 24,
  bottom: 30,
  left: 50,
  right: 50,
};

/**
 * Component is responsible for rendering chart wrapper and monitoring size changes.
 */
function ComposableChartWrapper({
  xDomain,
  yDomain,
  isLoading,
  children,
  height,
  isEmpty,
  bubbleClassName,
  padding = DEFAULT_PADDING,
  extraSpaceBetweenXAxisAndChart = 0,
}: React.PropsWithChildren<Props>): JSX.Element {
  const nonInteractive = isLoading || isEmpty;

  return (
    <div
      className={classNames(styles['composable-chart-container'], {
        [styles['disabled']]: nonInteractive,
      })}
      style={{ height: typeof height === 'number' ? height + extraSpaceBetweenXAxisAndChart + 'px' : height }}
    >
      <SVGWrapperWithResize
        xDomain={xDomain}
        yDomain={yDomain}
        isLoading={isLoading}
        extraSpaceBetweenXAxisAndChart={extraSpaceBetweenXAxisAndChart}
        padding={padding}
      >
        {children}
      </SVGWrapperWithResize>
      {nonInteractive && (
        <div className={classNames('caption', 'color-secondary', styles['composable-bubble-block'], bubbleClassName)}>
          {isLoading ? 'Loading...' : 'No Data'}
        </div>
      )}
    </div>
  );
}

/**
 * Internal/Private component is responsible for SVG and building X/Y scales.
 */
const SVGWrapperWithResize = withParentSizeThrottle(function SVGWrapper({
  xDomain,
  yDomain,
  isLoading,
  children,
  padding = DEFAULT_PADDING,
  extraSpaceBetweenXAxisAndChart,
  parentWidth = 0,
  parentHeight = 0,
}: React.PropsWithChildren<{
  xDomain: (string | number | Date)[];
  yDomain: number[];
  isLoading?: boolean;
  parentWidth?: number;
  parentHeight?: number;
  extraSpaceBetweenXAxisAndChart: number;
  padding?: ChartPadding;
}>): JSX.Element {
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true, // if set to true - will be an error - https://github.com/airbnb/visx/issues/737
    scroll: true,
  });

  const xMax = parentWidth - padding.left - padding.right;
  const yMax = parentHeight - extraSpaceBetweenXAxisAndChart - padding.bottom - padding.top;

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        domain: xDomain,
        // paddingOuter: 0,
        // paddingInner: 0.2,
      }),
    [xMax, xDomain]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: yDomain,
        round: true,
        nice: true,
        // If we clamp values and we have stacking bar chart which has 2 values in 1 Stack: [null, 4000]
        // and yDomain is [2000, 4000] - then the chart will be rendered incorrectly, because
        // yScale will clamp `null` value to `0`, which is lower than yMin
        //
        // If we remove clamp - then the chart will be rendered correctly,
        // because yScale for `null` value will be `2000`
        //
        // Note: when yDomain is [0, 4000] - it works as expected without clamp
        //
        // Instead of adjusting implementation for each chart and manually clamp values - it is better to do it here.
        // TODO: review all charts implementations to ensure that clamp does not break anything across the app
        clamp: true,
      }),
    [yMax, yDomain]
  );

  // TODO: debug why SVG rerenders on every resize, but not when parentHeight/width changes
  // console.log('render SVG', parentWidth, parentHeight, yMax, xMax);

  return (
    <svg
      ref={containerRef}
      className={classNames(styles['composable-chart'], {
        [styles['loading']]: isLoading,
      })}
      style={{ width: '100%', height: '100%' }}
      viewBox={`0 0 ${parentWidth} ${parentHeight}`}
    >
      <ChartContextProvider
        value={{
          TooltipPortal: TooltipInPortal,
          xMax,
          yMax,
          padding,
          xScale,
          yScale,
        }}
      >
        {children}
      </ChartContextProvider>
    </svg>
  );
});

export default ComposableChartWrapper;
