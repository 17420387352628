import { useCallback } from 'react';

import { useDataFetchOnMount } from 'src/cdk/hooks/useFetchDataOnMount';
import { N_A } from 'src/constants';

import optionsApi from '.';

/**
 * Hook to get user display name by id
 * Note: all users are cached to reduce the number of requests
 */
export function useUserDisplay() {
  const { response = {} } = useDataFetchOnMount(() =>
    optionsApi.getAllUsersOptions().then((r) => {
      return (r.getUserOptions || []).reduce(
        (acc, curr) => {
          acc[curr.id] = curr.displayAs;
          return acc;
        },
        {} as Record<string, string>
      );
    })
  );

  const displayAs = useCallback((userId?: string | null) => (!userId ? N_A : response[userId] || N_A), [response]);

  return displayAs;
}
