import { gql } from '@apollo/client';

export const SMART_OUTLET_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment SmartOutletSystemSpecificFields on SystemSmartOutlet {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    outletState
  }
`;

export const WEATHER_STATION_WTS506_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment WeatherStationWts506SystemSpecificFields on SystemWeatherStationWts506 {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    battery
    temperature
    humidity
    pressure
    rainfallTotal
    windDirection
    windSpeed
  }
`;

export const HW_BOILER_HT_HWR_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment HwBoilerHtHwrSystemSpecificFields on SystemHwBoilerHtHwr {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    supplyTemperature
    setpoint
    oat
    oatRangeLow
    oatRangeHigh
    spaceTempRangeLow
    spaceTempRangeHigh
    averageSpaceTemperature
    setpointMin
    setpointMax
  }
`;

export const ST_BOILER_F_HT_MPC_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment StBoilerFHtMpcSystemSpecificFields on SystemStBoilerFHtMpc {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    supplyTemperature
    setpoint
    oat
    oatRangeLow
    oatRangeHigh
    spaceTempRangeLow
    spaceTempRangeHigh
    averageSpaceTemperature
    setpointMin
    setpointMax
  }
`;

export const HW_BOILER_TEKMAR_284_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment HwBoilerTekmar284SystemSpecificFields on SystemHwBoilerTekmar248 {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    supplyTemperature
    setpoint
    oat
    oatRangeLow
    oatRangeHigh
    spaceTempRangeLow
    spaceTempRangeHigh
    averageSpaceTemperature
    setpointMin
    setpointMax
  }
`;

export const PACKAGE_ALTC24PROG_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment PackageAltc24ProgSystemSpecificFields on SystemPackageAltc24Prog {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    spaceTemperature
    setpoint
    setpointCool
    setpointHeat
    setpointMin
    setpointMax
    economySetpointCool
    economySetpointHeat
    occupiedSetpointCool
    occupiedSetpointHeat
    unoccupiedSetpointCool
    unoccupiedSetpointHeat
    focusAlgorithm
    setLock
    replaceFilter
    space
    spaceEffective
    packageSystemMode
    fan
    packageSystemModeEffective
    fanEffective
    occupancyType
    oat
    model {
      name
      fanLevels
      coolingStages
      heatingStages
      filter
    }
  }
`;

export const PRV_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment PRVSystemSpecificFields on SystemSteamPrvStation {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    outletPressure
    setpoint
    oat
    oatRangeLow
    oatRangeHigh
    spaceTempRangeLow
    spaceTempRangeHigh
    averageSpaceTemperature
    setpointMin
    setpointMax
  }
`;

export const LUTRON_VIVE_LIGHTING_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment LutronViveLightingSystemSpecificFields on SystemLightingVive {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    setpoint
    lightingState
    disableOccupancy
    occupancyState
    unoccupiedLevel
    occupiedLevel
    loadShedAllowed
    loadShedGoal
    occupancyMode
    totalPower
    maximumPower
    oat
    lastActivity
    manufacturer
    manufacturerModel
  }
`;

export const CHARGE_POINT_STATION_SYSTEM_SPECIFIC_FIELDS = gql`
  fragment ChargePointStationSystemSpecificFields on SystemChargePointStation {
    status
    statusUpdatedAt
    mode
    updatedAt
    sequence {
      id
      name
    }
    station {
      allowedLoad
      isShed
      lastActivity
      mode
      percentShed
      stationLoad
    }
    ports {
      allowedLoad
      faultReason
      id
      isShed
      level
      status
      statusChangedAt
      percentShed
      portLoad
      portNumber
      power
    }
    onlineStatus
  }
`;

export const SYSTEM_UNION_SPECIFIC_FIELDS = gql`
  ${PRV_SYSTEM_SPECIFIC_FIELDS}
  ${HW_BOILER_TEKMAR_284_SYSTEM_SPECIFIC_FIELDS}
  ${ST_BOILER_F_HT_MPC_SYSTEM_SPECIFIC_FIELDS}
  ${HW_BOILER_HT_HWR_SYSTEM_SPECIFIC_FIELDS}
  ${PACKAGE_ALTC24PROG_SYSTEM_SPECIFIC_FIELDS}
  ${LUTRON_VIVE_LIGHTING_SYSTEM_SPECIFIC_FIELDS}
  ${WEATHER_STATION_WTS506_SYSTEM_SPECIFIC_FIELDS}
  ${SMART_OUTLET_SYSTEM_SPECIFIC_FIELDS}
  ${CHARGE_POINT_STATION_SYSTEM_SPECIFIC_FIELDS}
  fragment SystemUnionSpecificFields on SystemUnion {
    ... on SystemSteamPrvStation {
      ...PRVSystemSpecificFields
    }
    ... on SystemHwBoilerTekmar248 {
      ...HwBoilerTekmar284SystemSpecificFields
    }
    ... on SystemStBoilerFHtMpc {
      ...StBoilerFHtMpcSystemSpecificFields
    }
    ... on SystemHwBoilerHtHwr {
      ...HwBoilerHtHwrSystemSpecificFields
    }
    ... on SystemPackageAltc24Prog {
      onlineStatus
      ...PackageAltc24ProgSystemSpecificFields
    }
    ... on SystemLightingVive {
      ...LutronViveLightingSystemSpecificFields
    }
    ... on SystemWeatherStationWts506 {
      ...WeatherStationWts506SystemSpecificFields
    }
    ... on SystemSmartOutlet {
      ...SmartOutletSystemSpecificFields
    }
    ... on SystemChargePointStation {
      ...ChargePointStationSystemSpecificFields
    }
  }
`;

export const SYSTEM_UNION_FIELDS = gql`
  ${PRV_SYSTEM_SPECIFIC_FIELDS}
  ${HW_BOILER_TEKMAR_284_SYSTEM_SPECIFIC_FIELDS}
  ${ST_BOILER_F_HT_MPC_SYSTEM_SPECIFIC_FIELDS}
  ${HW_BOILER_HT_HWR_SYSTEM_SPECIFIC_FIELDS}
  ${PACKAGE_ALTC24PROG_SYSTEM_SPECIFIC_FIELDS}
  ${LUTRON_VIVE_LIGHTING_SYSTEM_SPECIFIC_FIELDS}
  ${WEATHER_STATION_WTS506_SYSTEM_SPECIFIC_FIELDS}
  ${SMART_OUTLET_SYSTEM_SPECIFIC_FIELDS}
  ${CHARGE_POINT_STATION_SYSTEM_SPECIFIC_FIELDS}
  fragment SystemUnionFields on SystemUnion {
    ... on SystemSteamPrvStation {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...PRVSystemSpecificFields
    }
    ... on SystemHwBoilerTekmar248 {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...HwBoilerTekmar284SystemSpecificFields
    }
    ... on SystemStBoilerFHtMpc {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...StBoilerFHtMpcSystemSpecificFields
    }
    ... on SystemHwBoilerHtHwr {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...HwBoilerHtHwrSystemSpecificFields
    }
    ... on SystemPackageAltc24Prog {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...PackageAltc24ProgSystemSpecificFields
    }
    ... on SystemLightingVive {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...LutronViveLightingSystemSpecificFields
    }
    ... on SystemWeatherStationWts506 {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...WeatherStationWts506SystemSpecificFields
    }
    ... on SystemSmartOutlet {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      systemGroupId
      ...SmartOutletSystemSpecificFields
    }
    ... on SystemChargePointStation {
      id
      siteId
      name
      floor_type
      floor
      onlineStatus
      type
      groupType
      systemGroupId
      schedulerProfile {
        underSchedulerControl
        name
      }
      overriddenSchedulerProfile {
        sequenceId
        settings {
          fan
          mode
          outsideTempMax
          outsideTempMin
          setpoint
          setpointCool
          setpointHeat
          setpointMin
          setpointMax
          systemState
          occupiedSetpointCool
          occupiedSetpointHeat
          unoccupiedSetpointCool
          unoccupiedSetpointHeat
          space
        }
        endTime
      }
      station {
        allowedLoad
        id
        isShed
        lastActivity
        manufacturer
        mode
        model
        name
        percentShed
        stationId
        stationLoad
        warranty
        warrantyExpiration
        status
        statusChangedAt
      }
      ports {
        allowedLoad
        connector
        faultReason
        id
        isShed
        level
        status
        statusChangedAt
        percentShed
        portLoad
        portNumber
        power
      }
      lastActivity
      type
      ...ChargePointStationSystemSpecificFields
    }
  }
`;
