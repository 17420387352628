import { useSelector } from 'react-redux';

import { UNIT } from 'src/constants';
import SystemAlertTag, { hasAlert } from 'src/shared/components/Tag/SystemAlertTag';
import { WithUnit } from 'src/shared/components/Unit/WithUnit';
import {
  CardLayout,
  UniversalCard,
  UniversalChart,
  UniversalDataList,
  UniversalHeader,
  UniversalMainProperty,
  UniversalSetpoint,
} from 'src/shared/containers/UniversalCard/UniversalCard';

import GenericSystemHeader from '../../components/containers/GenericSystemHeader/GenericSystemHeader';
import LayoutWithSettingsAndAlarm from '../../components/containers/LayoutWithSettingsAndAlarm/LayoutWithSettingsAndAlarm';
import { WeatherStationWts506SystemCard } from '../../gql/getSystemsForSite.resources.gql';
import { selectedSystemById } from '../../systemsSlice';

import styles from './WeatherSystemCard.module.scss';

export interface WeatherSystemCardProps {
  systemId: number;
  layout: CardLayout;
  timezone?: string | null;
}

const WeatherSystemCard: React.FC<WeatherSystemCardProps> = (props) => {
  const system = useSelector(selectedSystemById<WeatherStationWts506SystemCard>(props.systemId));

  if (!system) {
    return <p>Cannot find system with id {props.systemId}</p>;
  }

  const hideSettings = true;
  const disabledSettings = true;
  // Alarms are supported by PRV systems
  const hideAlarm = false;

  const layout = props.layout;

  const propertiesToDisplay = [
    { title: 'Temperature', propertyName: 'temperature', value: system.temperature, unit: UNIT.FAHRENHEIT },
    { title: 'Humidity', propertyName: 'humidity', value: system.humidity, unit: UNIT.PERCENTAGE },
    { title: 'Pressure', propertyName: 'pressure', value: system.pressure, unit: UNIT.HECTO_PASCAL, asBigNumber: true },
    { title: 'Rainfall Total', propertyName: 'rainfallTotal', value: system.rainfallTotal, unit: UNIT.MILLIMETER },
    { title: 'Wind Direction', propertyName: 'windDirection', value: system.windDirection, unit: UNIT.DEGREES },
    { title: 'Wind Speed', propertyName: 'windSpeed', value: system.windSpeed, unit: UNIT.MILES_PER_HOUR },
    { title: 'Battery', propertyName: 'battery', value: system.battery, unit: UNIT.PERCENTAGE },
  ];

  return (
    <UniversalCard
      layout={layout}
      className={layout === CardLayout.list ? styles['weather-list-card'] : styles['weather-horizontal-card']}
    >
      <UniversalHeader assumeHeader>
        <GenericSystemHeader
          layout={layout}
          systemId={props.systemId}
          name={system.name}
          floor={system.floor}
          floorType={system.floor_type}
          status={system.status}
          lastStatusChangedDatetime={system.statusUpdatedAt}
          onlineStatus={system.onlineStatus}
          timezone={props.timezone}
        />
      </UniversalHeader>
      {hasAlert(system) && (
        <UniversalMainProperty order={-1} moveToHeaderWhenVertical hideWhenList hideWhenHorizontal>
          <SystemAlertTag systemId={system.id} onlineStatus={system.onlineStatus} />
        </UniversalMainProperty>
      )}
      {/* Hide alerts and chart from horizontal view (used on details page) */}
      {layout === CardLayout.list && (
        <UniversalChart moveToLeftWhenHorizontal>
          <LayoutWithSettingsAndAlarm
            siteId={system.siteId}
            disabledSettings={disabledSettings}
            hideAlarm={hideAlarm}
            hideSettings={hideSettings}
            onSettingsClick={() => {
              // do nothing
            }}
            layout={layout}
            systemId={system.id}
          >
            <div />
          </LayoutWithSettingsAndAlarm>
        </UniversalChart>
      )}
      {/* For list view - each property should be displayed as a separate column */}
      {layout === CardLayout.list &&
        propertiesToDisplay.map((item) => (
          <UniversalMainProperty horizontalLabel={item.title} key={item.propertyName}>
            <UniversalSetpoint verticalLabel={item.title}>
              <WithUnit value={item.value} unit={item.unit} dimmedUnit asBigNumber={item.asBigNumber} />
            </UniversalSetpoint>
          </UniversalMainProperty>
        ))}
      {/* For horizontal view - properties are split onto 2 sections */}
      <UniversalDataList moveToLeftWhenHorizontal>
        {propertiesToDisplay.slice(0, 4).map((item) => (
          <>
            <dt>{item.title}</dt>
            <dd>
              <WithUnit unit={item.unit} value={item.value} dimmedUnit asBigNumber={item.asBigNumber} />
            </dd>
          </>
        ))}
      </UniversalDataList>
      <UniversalDataList>
        {propertiesToDisplay.slice(4, 7).map((item) => (
          <>
            <dt>{item.title}</dt>
            <dd>
              <WithUnit unit={item.unit} value={item.value} dimmedUnit asBigNumber={item.asBigNumber} />
            </dd>
          </>
        ))}
      </UniversalDataList>
    </UniversalCard>
  );
};

export default WeatherSystemCard;
