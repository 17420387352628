import classNames from 'classnames';
import { addYears, subYears } from 'date-fns';
import React from 'react';
import { type Matcher, type DateRange } from 'react-day-picker';

import { Button } from '../../Button/Button';

import styles from './MonthPicker.module.scss';
import { Months } from './Months';

interface MonthPickerProps {
  selected?: DateRange | undefined;
  month?: Date;
  disabled?: Matcher[] | undefined;
  onMonthClick: (date: Date) => void;
  onYearChange: (date: Date) => void;
}

export const MonthPicker: React.FC<MonthPickerProps> = ({ selected, month, disabled, onMonthClick, onYearChange }) => {
  const today = new Date();
  const currentDate = month || today;
  const currentYear = currentDate.getFullYear();

  return (
    <div className={classNames('d-flex justify-content-space-between gap-40', styles['month-range-picker-wrapper'])}>
      <div className={styles['month-picker-from-wrapper']}>
        <div className='d-flex flex-col justify-content-space-between gap-16'>
          <div className='mt-24 d-flex align-items-center'>
            <Button
              icon='angle-left'
              shape='rect'
              type='button'
              variant='flat'
              onClick={() => {
                onYearChange(subYears(currentDate, 1));
              }}
            />
            <span className='subtitle ml-48'>{currentYear - 1}</span>
          </div>
          <div className={styles['months-wrapper']}>
            <Months year={currentYear - 1} selected={selected} disabled={disabled} onMonthClick={onMonthClick} />
          </div>
        </div>
      </div>

      <div className={styles['month-picker-to-wrapper']}>
        <div className='d-flex flex-col justify-content-space-between gap-16'>
          <div className='mt-24 d-flex align-items-center justify-content-end'>
            <span className='subtitle mr-48'>{currentYear}</span>
            <Button
              icon='angle-right'
              shape='rect'
              type='button'
              variant='flat'
              onClick={() => {
                onYearChange(addYears(currentDate, 1));
              }}
            />
          </div>
          <div className={styles['months-wrapper']}>
            <Months
              year={currentYear}
              selected={selected}
              disabled={disabled}
              onMonthClick={(month) => {
                onMonthClick(month);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
