import { mapRecordAsOptions } from 'src/cdk/mappers/mapAsOptions';
import { SpaceStatusToLabel } from 'src/enums';

import { AlertAction } from './apollo/__generated__/alertsGlobalTypes';
import * as l from './enum-labels';

export const GATEWAY_VERSION_OPTIONS = mapRecordAsOptions(l.GATEWAY_VERSION_LABEL);

export const GATEWAY_CONNECTIVITY_OPTIONS = mapRecordAsOptions(l.GATEWAY_CONNECTIVITY_LABEL);

export const GATEWAY_MANUFACTURER_OPTIONS = mapRecordAsOptions(l.GATEWAY_MANUFACTURER_LABEL);

export const GATEWAY_FREQUENCY_BAND_OPTIONS = mapRecordAsOptions(l.GATEWAY_FREQUENCY_BAND_LABEL);

export const GATEWAY_MODEL_OPTIONS = mapRecordAsOptions(l.GATEWAY_MODEL_LABEL);

export const SPACE_STATUS_OPTIONS = mapRecordAsOptions(SpaceStatusToLabel);

export const ALERT_COLOR_OPTIONS = mapRecordAsOptions(l.ALERT_COLOR_LABEL, {
  keepOriginalOrder: true,
});

export const ALERT_ACTION_OPTIONS = mapRecordAsOptions(l.ALERT_ACTION_LABEL).map((i) => ({
  ...i,
  hidden: i.key === AlertAction.WEB || i.key === AlertAction.SMS,
  config: {
    description: i.key === AlertAction.SMS ? 'Not Supported' : undefined,
  },
}));

export const ALERT_OPERATOR_OPTIONS = mapRecordAsOptions(l.ALERT_OPERATOR_LABEL);

export const CONDITION_EVENT_OPTIONS = mapRecordAsOptions({
  ...l.CALCULATION_CONDITION_EVENT_LABEL,
  ...l.INSTANT_CONDITION_EVENT_LABEL,
});

export const OCCUPANCY_TYPE_OPTIONS = mapRecordAsOptions(l.OCCUPANCY_TYPE_LABEL).map((i) => ({
  ...i,
  config: {
    description: l.OCCUPANCY_TYPE_DESCRIPTION[i.key],
  },
}));

export const METER_MONITORING_FREQUENCY_OPTIONS = mapRecordAsOptions(l.METER_MONITORING_FREQUENCY_LABEL, {
  keepOriginalOrder: true,
});

export const METER_MONITORING_PREPAY_OPTIONS = mapRecordAsOptions(l.METER_MONITORING_PREPAY_LABEL);

export const SYSTEM_LOG_TYPE_OPTIONS = mapRecordAsOptions(l.LOG_TYPE_LABEL);

export const APPEARANCE_OPTIONS = mapRecordAsOptions(l.APPEARANCE_LABEL);

export const CP_REGION_OPTIONS = mapRecordAsOptions(l.CP_REGION_LABEL);

export const CP_STATION_MODE_TYPE_OPTIONS = mapRecordAsOptions(l.CP_STATION_MODE_TYPE_LABEL, {
  keepOriginalOrder: true,
}).map((i) => ({
  ...i,
  config: {
    description: i.key.includes('SHED') ? 'Not Supported' : undefined,
  },
}));
