import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { AuditLogResourceType } from 'src/core/apollo/__generated__/logsGlobalTypes';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import Guard from 'src/fragments/layouts/guard/Guard';
import LogsPage from 'src/materials/logs/pages/LogsPage';
import { Routes } from 'src/routes';
import UrlTabs from 'src/shared/components/Tabs/UrlTabs';
import PageHeaderWithActions from 'src/shared/containers/PageHeaderWithActions';

import SiteDetailsLayoutPage from './sites/[:id]/page';
import UserDetailsPage from './users/[:id]/page';

const router: RouteObject[] = [
  {
    path: Routes.Admin,
    element: <Guard roles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]} />,
    errorElement: <ExceptionHandler />,
    children: [
      {
        element: (
          <>
            <PageHeaderWithActions
              title='Administration'
              subtitle='Manage organizations, sites, users and their permissions'
              removeBottomMargin
            />
            <UrlTabs names={['Sites', 'Organizations', 'Departments', 'Users']} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: 'organizations',
            lazy: asLazyPage(() => import('../../modules/organizations/pages/OrganizationsPage')),
          },
          {
            path: 'sites',
            lazy: asLazyPage(
              () => import('../../modules/sites/pages/SitesAdministrationPage/SitesAdministrationPage'),
              import('../../modules/sites/pages/SitesAdministrationPage/SitesAdministrationPage.skeleton')
            ),
          },
          {
            path: 'users',
            lazy: asLazyPage(() => import('./users/page')),
          },
          {
            path: 'departments',
            lazy: asLazyPage(() => import('./departments/page')),
          },
          {
            path: '*',
            index: true,
            element: <Navigate to='sites' />,
          },
        ],
      },
      // TODO: restructure pages content and location
      {
        path: 'sites/:id',
        element: (
          <Guard roles={[UserRoles.SUPER_ADMIN]}>
            <SiteDetailsLayoutPage tabs={['Details', 'Floors', 'Features']} />
          </Guard>
        ),
        children: [
          {
            path: 'details',
            lazy: asLazyPage(() => import('./sites/[:id]/details/page')),
          },
          {
            path: 'floors',
            lazy: asLazyPage(() => import('./sites/[:id]/floors/page')),
          },
          {
            path: 'features',
            lazy: asLazyPage(() => import('./sites/[:id]/features/page')),
          },
          {
            path: '*',
            element: <Navigate to='site' />,
          },
        ],
      },
      {
        path: 'organizations/create',
        element: <Guard roles={[UserRoles.SUPER_ADMIN]} />,
        children: [
          {
            index: true,
            lazy: asLazyPage(
              () => import('src/modules/organizations/pages/OrganizationDetails/OrganizationDetailsPage')
            ),
          },
        ],
      },
      {
        path: 'organizations/:id',
        lazy: asLazyPage(() => import('src/modules/organizations/pages/OrganizationDetails/OrganizationDetailsPage')),
      },
      {
        path: 'users/:id',
        element: <UserDetailsPage tabsNames={['General', 'Activity']} />,
        children: [
          {
            path: 'general',
            lazy: asLazyPage(() => import('./users/[:id]/details/page')),
          },
          {
            path: 'activity',
            element: <LogsPage resourceIdParamName='id' resourceType={AuditLogResourceType.USER} />,
          },
          {
            path: '*',
            element: <Navigate to='general' />,
          },
        ],
      },
      {
        path: 'departments/:id',
        lazy: asLazyPage(() => import('./departments/[:id]/page')),
      },
      // {
      //   path: '*',
      //   element: <NotFoundPage />,
      // },
    ],
  },
];

export default router;
