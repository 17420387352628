import React, { useEffect, useState } from 'react';

import { NumberRange } from 'src/interfaces';
import { Modal } from 'src/shared/components/Popup';

import { IOatResetRanges } from '../../../interface';

import OATReset from './OATReset';

export interface OATResetModalProps {
  open: boolean;
  title: string;
  subtitleText: string;
  acceptAction: (newConfig: IOatResetRanges) => void;
  cancelAction: () => void;
  oatTemperatureLimits: NumberRange;
  setpointLimits: NumberRange;
  defaultOatTemperature: NumberRange;
  defaultSetpoint: NumberRange;
  oatTemperatureLabel: string;
  setpointLabel: string;
  disabledSettings?: boolean;
}

const OATResetModal: React.FC<OATResetModalProps> = (props) => {
  const [selectedOatTemperature, setSelectedOatTemperature] = useState(props.defaultOatTemperature);
  const [selectedSetpoint, setSelectedSetpoint] = useState(props.defaultSetpoint);

  useEffect(() => {
    setSelectedOatTemperature(props.defaultOatTemperature);
    setSelectedSetpoint(props.defaultSetpoint);
  }, [props.defaultOatTemperature, props.defaultSetpoint]);

  return (
    <Modal
      isOpen={props.open}
      acceptAction={() =>
        props.acceptAction({
          setpointMin: selectedSetpoint[0],
          setpointMax: selectedSetpoint[1],
          outsideTempMin: selectedOatTemperature[0],
          outsideTempMax: selectedOatTemperature[1],
        })
      }
      disableAccept={props.disabledSettings}
      cancelAction={props.cancelAction}
      titleText={`Adjust Values: ${props.title}`}
      subtitleText={props.subtitleText}
      actionButtonLabel='Save'
      cancelButtonLabel='Cancel'
    >
      <OATReset
        oatTemperatureLimits={props.oatTemperatureLimits}
        setpointLimits={props.setpointLimits}
        oatTemperatureLabel={props.oatTemperatureLabel}
        setpointLabel={props.setpointLabel}
        selectedOatTemperature={selectedOatTemperature}
        setSelectedOatTemperature={setSelectedOatTemperature}
        selectedSetpoint={selectedSetpoint}
        setSelectedSetpoint={setSelectedSetpoint}
        disabled={props.disabledSettings}
      />
    </Modal>
  );
};

export default OATResetModal;
