import React, { useMemo } from 'react';
import { Navigate, Route, RouteProps, Routes, useLocation, useNavigate } from 'react-router-dom';

import { slugify } from 'src/cdk/utils/slugify';

import Tabs from './Tabs';

export interface UrlTabsProps {
  /**
   * Names of the tabs. Automatically converted to slugs.
   */
  names: string[];
  disabled?: boolean;
}

/**
 * When using that component, make sure that the parent route does not have `exact` prop.
 */
const UrlTabs: React.FC<UrlTabsProps> = ({ names, disabled }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const config = useMemo(() => {
    return names.map((name, index) => {
      const slug = slugify(name);
      const uri = slug;

      return {
        id: index,
        name: name,
        // React Router v6 will automatically add the parent route path to the uri
        uri: uri,
        slug,
        onClick: () => navigate(uri),
      };
    });
  }, [names]);

  const selectedTab = config.findIndex((i) => {
    return location.pathname.endsWith(i.slug);
  });

  // Automatically redirect to the first tab if non matches
  if (selectedTab === -1) {
    return <Navigate to={config[0].uri} replace />;
  }

  return <Tabs disabled={disabled} config={config} selected={selectedTab} />;
};

/**
 * Use this component to define a route for a tab.
 */
export const TabRoute: React.FC<{
  tabName: string;
  element?: JSX.Element;
  component?: RouteProps['Component'];
}> = ({ component, tabName, element }) => {
  const slug = slugify(tabName);

  return (
    <Routes>
      <Route path={slug} Component={component} element={element} />
    </Routes>
  );
};

export default UrlTabs;
