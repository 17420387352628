import { subDays, differenceInDays, subMonths, differenceInMonths } from 'date-fns';

import { UTCStringToLocalDate, mapDateToYearMonthStr } from 'src/cdk/utils/datetimeToDate';

export function calcPreviousPeriod(from: Date, to: Date): [Date, Date] {
  return [subDays(from, differenceInDays(to, from) + 1), subDays(from, 1)];
}

export function calcPreviousPeriodForMonthYear(from: string, to: string): [YearMonthStr, YearMonthStr] {
  const start = UTCStringToLocalDate(from);
  const end = UTCStringToLocalDate(to);
  return [
    mapDateToYearMonthStr(subMonths(start, differenceInMonths(end, start) + 1)),
    mapDateToYearMonthStr(subMonths(start, 1)),
  ];
}
