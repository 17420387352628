import { useState } from 'react';

import { FetchStatus, useFetchOnMountWithDeps } from './useFetchOnMountWithDeps';

export interface FetchStatusWithData<T> extends FetchStatus {
  response?: T;
}

/**
 * That hook behaves in the same way as `useFetchOnMountWithDeps` but also
 * it stores/caches response as internal state and returns it
 */
export function useDataFetchOnMountWithDeps<T = void>(
  fetch: () => Promise<T>,
  deps?: React.DependencyList,
  updateStatusOnEachRequest?: boolean
): FetchStatusWithData<T> {
  const [response, setResponse] = useState<T>();

  const fetchStatus = useFetchOnMountWithDeps(fetch, setResponse, deps, updateStatusOnEachRequest);

  return {
    ...fetchStatus,
    response,
  };
}
