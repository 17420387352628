import MultiSelect from '../MultiSelect/MultiSelect';
import { SelectProps } from '../interface';

export default function Select<T>({
  value,
  disabled,
  label,
  secondLabel,
  options,
  disabledWhenFewerOptions = true,
  className,
  id,
  labelPosition,
  placeholder,
  onClick,
  renderOption,
  useRenderInSelectedField = true,
  customElement,
  customElementPosition: elementPosition,
  stickyCustomElement: isStickyElement,
  markAsLoading: markAsLoading,
  required = false,
  popupActionsRef,
}: SelectProps<T>): JSX.Element {
  return (
    <MultiSelect<T>
      value={[value]}
      disabled={disabled}
      label={label}
      secondLabel={secondLabel}
      options={options}
      disabledWhenFewerOptions={disabledWhenFewerOptions}
      className={className}
      id={id}
      labelPosition={labelPosition}
      placeholder={placeholder}
      onClick={(selected) => onClick(selected[0])}
      renderOption={renderOption}
      useRenderInSelectedField={useRenderInSelectedField}
      isMultiselectMode={false}
      customElement={customElement}
      customElementPosition={elementPosition}
      stickyCustomElement={isStickyElement}
      markAsLoading={markAsLoading}
      required={required}
      popupActionsRef={popupActionsRef}
    />
  );
}
