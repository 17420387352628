import classNames from 'classnames';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';

import { Icon } from 'src/shared/components/Icon/Icon';
import { SupportedIcon } from 'src/shared/components/Icon/gen/suported-icons';

import styles from './Navigation.module.scss';

const NavigationItem: React.FC<{
  className?: string;
  label?: string;
  linkTo?: string;
  /**
   * If provided - will override default icon
   */
  iconElement?: React.ReactElement;
  /**
   * If provided - will display it
   */
  icon?: SupportedIcon;
  iconClassName?: string;
  onClick?: (e?: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>) => void;
  isActive?: boolean;
  children?: React.ReactElement;
}> = ({ linkTo, label, iconElement, icon, onClick, isActive, className, children, iconClassName }) => {
  const innerContent = (
    <>
      {iconElement ? (
        iconElement
      ) : icon ? (
        <Icon icon={icon} size='m' className={classNames(styles['nav-icon'], iconClassName)} />
      ) : null}
      <p className='body-semi-bold color-tertiary'>{label}</p>
      {children}
    </>
  );
  const itemClassName = classNames(styles['nav-item'], { [styles['active']]: isActive }, className);

  return isNil(linkTo) ? (
    <button onClick={onClick} type='button' className={itemClassName}>
      {innerContent}
    </button>
  ) : (
    <Link to={linkTo} onClick={onClick} className={itemClassName}>
      {innerContent}
    </Link>
  );
};

export default NavigationItem;
