import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  UpdatePackageSystemResetFilterMutation,
  UpdatePackageSystemResetFilterMutationVariables,
} from './__generated__/updatePackageSystemResetFilter.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterPackageSystemResetFilter = Extract<
  UpdatePackageSystemResetFilterMutation['updatePackageSystemResetFilter'],
  {
    __typename: 'SystemPackageAltc24Prog';
  }
>;

const CHANGE_PACKAGE_SYSTEM_RESET_FILTER = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation UpdatePackageSystemResetFilter($systemId: Int!) {
    updatePackageSystemResetFilter(systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function updatePackageSystemResetFilter(
  systemId: number
): Promise<DataAfterPackageSystemResetFilter | undefined> {
  const result = await resourcesAPI.mutate<
    UpdatePackageSystemResetFilterMutation,
    UpdatePackageSystemResetFilterMutationVariables
  >({
    mutation: CHANGE_PACKAGE_SYSTEM_RESET_FILTER,
    variables: { systemId },
  });

  const updateResult = result.data?.updatePackageSystemResetFilter as DataAfterPackageSystemResetFilter;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
