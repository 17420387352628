import React, { lazy } from 'react';

import withSuspense from 'src/cdk/HOCs/withSuspense/withSuspence';

import { SkeletonLoader } from '../../Loader/Loader';

const skeleton = <SkeletonLoader height='16px' width='16px' />;

const CheckboxWithPartial = withSuspense(
  lazy(() => import('./CheckboxWithPartial')),
  skeleton
);

export default CheckboxWithPartial;
