import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { TableColumn } from 'src/shared/components/TableHeader/TableHeader';

const columns: TableColumn[] = [
  { title: 'Zone Name', propertyName: 'name' },
  { title: 'Sequence', propertyName: 'sequence' },
  { title: 'Mode', propertyName: 'mode' },
];

export const mapSystemTypeToColumnConfig: { [key in SystemType]?: TableColumn[] } = {
  [SystemType.LUTRON_VIVE_LIGHTING]: [...columns, { title: 'Light Intensity', propertyName: 'setpoint' }],
  [SystemType.SMART_OUTLET_T0006623]: columns,
};
