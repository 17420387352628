import { connectSdk, resourcesAPI, usersAPI } from 'src/core/apollo/api';

import { GetDepartmentByIdQuery, getSdk as getDepartmentSdk } from './__generated__/department.users.generated';
import {
  GetRegionsToSystemsTreeQuery,
  getSdk as getPermissionsSdk,
} from './__generated__/permissions.resources.generated';
import { GetUserByIdQuery, getSdk as getUserSdk } from './__generated__/user.users.generated';

export type RegionTree = GetRegionsToSystemsTreeQuery['getRegionsToSystemsTree'][0];

export type User = GetUserByIdQuery['user'];

export type Department = GetDepartmentByIdQuery['department'];

export default {
  ...connectSdk(usersAPI, getUserSdk),
  ...connectSdk(usersAPI, getDepartmentSdk),
  ...connectSdk(resourcesAPI, getPermissionsSdk),
};
