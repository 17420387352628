import { useSelector } from 'react-redux';

import { OnlineStatus, SystemMode, SystemStatus } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { UserRoles } from 'src/core/apollo/__generated__/usersGlobalTypes';
import { selectUserRole } from 'src/core/store/global/globalSlice';
import { useAppDispatch } from 'src/core/store/hooks';
import { SystemSequence } from 'src/enums';
import OverrideScheduleProfileForSystemModal from 'src/modules/scheduler/modal/OverrideScheduleProfileForSystemModal/OverrideScheduleProfileForSystemModal';
import { RoutesResolver } from 'src/routes';
import { Button } from 'src/shared/components/Button/Button';
import { ButtonGroup } from 'src/shared/components/ButtonGroup/ButtonGroup';
import {
  CardLayout,
  UniversalCard,
  UniversalChart,
  UniversalHeader,
  UniversalMainProperty,
} from 'src/shared/containers/UniversalCard/UniversalCard';

import GenericSystemHeader from '../../components/containers/GenericSystemHeader/GenericSystemHeader';
import LayoutWithSettingsAndAlarm from '../../components/containers/LayoutWithSettingsAndAlarm/LayoutWithSettingsAndAlarm';
import SequenceSelector from '../../components/containers/SequenceSelector/SequenceSelector';
import { SmartOutletSystemCard } from '../../gql/getSystemsForSite.resources.gql';
import { changeOutletSystemModeForSingleSystem, changeSequenceForSingleSystem } from '../../systemsActions';
import { selectedSystemById } from '../../systemsSlice';
import { isDRTypeSequence } from '../../utils/system.utils';

export interface SmartOutletCardProps {
  systemId: number;
  groupId: number;
  timezone?: string | null;
  disableLink?: boolean;
}

const SmartOutletCard: React.FC<SmartOutletCardProps> = ({ systemId, groupId, timezone, disableLink = true }) => {
  const dispatch = useAppDispatch();
  const system = useSelector(selectedSystemById<SmartOutletSystemCard>(systemId));
  const currentUserRole = useSelector(selectUserRole);

  if (!system) {
    return <p>Cannot find system with id {systemId}</p>;
  }

  const systemAutoControl =
    isDRTypeSequence(system.sequence.id) || system.schedulerProfile?.underSchedulerControl === true;
  const disabled =
    system.sequence.id === SystemSequence.Local ||
    system.onlineStatus === OnlineStatus.OFFLINE ||
    system.onlineStatus === OnlineStatus.NOT_CONFIGURED ||
    system.mode === SystemMode.LOCAL ||
    currentUserRole === UserRoles.MANAGER ||
    systemAutoControl;

  function updateModeOnRemote(status: SystemStatus): Promise<void> {
    if (status !== system?.outletState) {
      return dispatch(changeOutletSystemModeForSingleSystem(systemId, status));
    }
    return Promise.resolve();
  }

  function changeSequence(value: SystemSequence): Promise<void> {
    return dispatch(changeSequenceForSingleSystem(systemId, value));
  }

  return (
    <UniversalCard layout={CardLayout.list}>
      <UniversalHeader assumeHeader>
        <GenericSystemHeader
          systemId={systemId}
          name={system.name}
          floor={system.floor}
          floorType={system.floor_type}
          status={system.status}
          lastStatusChangedDatetime={system.statusUpdatedAt}
          onlineStatus={system.onlineStatus}
          disableLink={disableLink}
          layout={CardLayout.list}
          timezone={timezone}
          alterLink={RoutesResolver.SystemGroupDetails(groupId)}
        />
      </UniversalHeader>
      <UniversalMainProperty horizontalLabel='Setpoint'>
        <SequenceSelector
          hideLabel
          disabled={disabled}
          sequenceId={system.sequence.id}
          systemType={system.type}
          schedulerProfile={system.schedulerProfile}
          onChange={changeSequence}
          layout={CardLayout.list}
        >
          <OverrideScheduleProfileForSystemModal
            systemId={system.id}
            isScheduled={systemAutoControl}
            isOverridden={!!system.overriddenSchedulerProfile}
          />
        </SequenceSelector>
      </UniversalMainProperty>
      <UniversalChart>
        <LayoutWithSettingsAndAlarm
          siteId={system.siteId}
          hideSettings={true}
          disabledSettings={true}
          onSettingsClick={() => ({})}
          hideAlarm={false}
          layout={CardLayout.list}
          systemId={system.id}
        />
      </UniversalChart>
      <UniversalMainProperty horizontalLabel=''>
        <ButtonGroup>
          <Button
            className='body-small'
            size='small'
            label='On'
            disabled={disabled}
            isPressed={system.outletState === SystemStatus.ON}
            onClick={() => updateModeOnRemote(SystemStatus.ON)}
          />
          <Button
            className='body-small'
            size='small'
            label='Off'
            disabled={disabled}
            isPressed={system.outletState === SystemStatus.OFF}
            onClick={() => updateModeOnRemote(SystemStatus.OFF)}
          />
        </ButtonGroup>
      </UniversalMainProperty>
    </UniversalCard>
  );
};

export default SmartOutletCard;
