import classNames from 'classnames';
import type { CountryCode } from 'libphonenumber-js';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import FloorSelector from 'src/modules/systems/components/FloorSelector/FloorSelector';
import { Checkbox } from 'src/shared/components/Checkbox/Checkbox';
import { DatePicker } from 'src/shared/components/DatePicker/DatePicker/DatePicker';
import { InfoTooltip } from 'src/shared/components/InfoTooltip/InfoTooltip';
import { Input } from 'src/shared/components/Input/Input';
import { MultiSelectWithSearch } from 'src/shared/components/Select';
import SelectWithSearch from 'src/shared/components/Select/SelectWithSearch/SelectWithSearch';
import { TextArea } from 'src/shared/components/TextArea/TextArea';

import { phone } from './phone';
import { AnyFieldProps, FieldComponentMap, FormFieldType } from './uiSchemaModel';

// TODO: review messages customisation
//
// .messages({
//   'any.required': '{{#label}} is required!!',
//   'string.empty': "{{#label}} can't be empty!!",
// }),

export const uiFormFields: FieldComponentMap = {
  [FormFieldType.UIElement]: function UiField(props) {
    if (props.field.hidden) {
      return null;
    }

    const CustomFC = props.field.render;

    return (
      <fieldset className={classNames(props.field.fieldsetClassName)} style={buildFieldStyles(props)}>
        {CustomFC && <CustomFC {...props} />}
      </fieldset>
    );
  },
  [FormFieldType.CustomField]: function CustomField(props) {
    const CustomFC = props.field.render;

    if (props.field.hidden) {
      return null;
    }

    return (
      <fieldset className={classNames(props.field.fieldsetClassName)} style={buildFieldStyles(props)}>
        {CustomFC && <CustomFC {...props} />}
      </fieldset>
    );
  },
  [FormFieldType.Text]: function StringField(props) {
    return (
      <FieldBuilder {...props}>
        <Input
          id={props.fieldName}
          value={props.value ?? ''}
          onChange={(value) => props.onChange(value)}
          className={classNames('w-100')}
          hasError={!!props.error}
          placeholder={props.field.placeholder ?? props.field.label}
          icon={props.field.icon}
          inputMask={props.field.inputMask}
          disabled={props.field.disabled}
          updateOnFocus={props.field.onFocus}
        />
      </FieldBuilder>
    );
  },
  [FormFieldType.Phone]: function PhoneField(props) {
    const [countryCode, setCountryCode] = useState<CountryCode>('US');
    const formatTyped = useMemo(() => phone.formatTyped(countryCode), []);

    useEffect(() => {
      if (props.value !== formatTyped.getNumber()) {
        formatTyped.update(props.value ?? '');
        if (countryCode !== formatTyped.getCountry()) {
          setCountryCode(formatTyped.getCountry() || 'US');
          formatTyped.update(props.value ?? '');
        }
      }
    }, [props.value]);

    function onCountryChange(v: CountryCode) {
      setCountryCode(v);
      formatTyped.setCountry(v);
    }

    function onValueChange(value: string) {
      const modelValue = formatTyped.update(value);
      if (countryCode !== formatTyped.getCountry()) {
        setCountryCode(formatTyped.getCountry());
      }
      return props.onChange(modelValue);
    }

    return (
      <FieldBuilder {...props} fieldsetClassName='input-phone'>
        <SelectWithSearch
          options={phone.countryOptions}
          value={countryCode}
          onClick={onCountryChange}
          className='input-phone-country'
        />
        <Input
          id={props.fieldName}
          value={formatTyped.getUI()}
          onChange={onValueChange}
          className={classNames('w-100 input-phone-number')}
          hasError={!!props.error}
          placeholder={props.field.placeholder ?? props.field.label}
          // icon={props.field.icon}
          // inputMask={props.field.inputMask}
          disabled={props.field.disabled}
          // updateOnFocus={props.field.onFocus}
        />
      </FieldBuilder>
    );
  },
  [FormFieldType.TextArea]: function StringField(props) {
    return (
      <FieldBuilder {...props}>
        <TextArea
          id={props.fieldName}
          value={props.value ?? ''}
          onChange={(value) => (props.field.required ? props.onChange(value) : props.onChange(value || undefined))}
          className={classNames('w-100')}
          hasError={!!props.error}
          placeholder={props.field.placeholder ?? props.field.label}
          icon={props.field.icon}
          disabled={props.field.disabled}
          maxAmountOfLines={0}
        />
      </FieldBuilder>
    );
  },
  [FormFieldType.Checkbox]: function BooleanField(props) {
    const selected = props.field.mapValueToFlag!(props.value);

    if (props.field.hidden) {
      return null;
    }

    return (
      <fieldset
        className={classNames('d-flex gap-4 align-items-center', props.field.fieldsetClassName)}
        style={buildFieldStyles(props)}
      >
        <Checkbox
          label={props.field.label}
          selected={selected}
          onClick={() => props.onChange(props.field.mapFlagToValue!(!selected))}
          disabled={props.field.disabled}
        />
        <DescriptionBuilder description={props.field.description} />
      </fieldset>
    );
  },
  [FormFieldType.Date]: function DateField(props) {
    const { min, max } = props.field;
    const from = min;
    const to = max;
    const disabledDays = props.field.disabledDays;

    return (
      <FieldBuilder {...props}>
        <DatePicker
          // TODO: TBD add ID to be able to click on "label" and open the calendar
          // id={props.fieldName}
          value={props.value}
          onChange={(value) => props.onChange(value)}
          className={classNames('w-100')}
          hasError={!!props.error}
          from={from}
          to={to}
          disabledDays={disabledDays}
          disabled={props.field.disabled}
        />
      </FieldBuilder>
    );
  },
  [FormFieldType.Select]: function SelectField(props) {
    useEffect(() => {
      if (props.field.disableAutoSelect) {
        return;
      }
      if (props.field.options?.length === 1) {
        props.onChange(props.field.options[0].key);
      }
    }, [props.field.options]);

    return (
      <FieldBuilder {...props}>
        <SelectWithSearch
          options={props.field.options ?? []}
          value={props.value}
          onClick={(value) => props.onChange(value)}
          className={classNames('w-100')}
          hasError={!!props.error}
          renderOption={props.field.renderOption}
          disabled={props.field.disabled}
          onAddNewItem={props.field.onAddNewItem}
          entityName={props.field.entityName}
          placeholder={props.field.placeholder}
        />
      </FieldBuilder>
    );
  },
  [FormFieldType.Multiselect]: function MultiselectField(props) {
    useEffect(() => {
      if (props.field.disableAutoSelect) {
        return;
      }
      if (props.field.options?.length === 1) {
        props.onChange([props.field.options[0].key]);
      }
    }, [props.field.options]);

    const selectedItems = useMemo(
      () =>
        !props.field.showSelectedValuesInTooltip ||
        _.isNil(props.value) ||
        _.isNil(props.field.options) ||
        _.isEmpty(props.value) ||
        _.isEmpty(props.field.options) ? undefined : (
          <>
            {props.field.options
              .filter((option) => props.value.includes(option.key))
              .map((option) => (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                <p key={(option.key as any).toString()}>{option.displayValue}</p>
              ))}
          </>
        ),
      [props.value]
    );

    const field = useMemo(() => {
      const total = props.field.options?.length ?? 0;
      if (props.field.showSelectedValuesInCounter && total > 0) {
        const selected = props.value?.length ?? 0;
        return props.field.copyWith({
          label: `${props.field.label} (${selected} of ${total})`,
        });
      }
      return props.field;
    }, [props.value, props.field.options]);

    return (
      <FieldBuilder {...props} field={field} description={selectedItems}>
        <MultiSelectWithSearch
          options={props.field.options ?? []}
          value={props.value ?? []}
          onClick={(value) => props.onChange(value)}
          className={classNames('w-100')}
          hasError={!!props.error}
          disabled={props.field.disabled}
          transformSelectedOption={props.field.transformSelectedOption}
          placeholder={props.field.placeholder}
        />
      </FieldBuilder>
    );
  },
  [FormFieldType.Floor]: function FloorField(props) {
    return (
      <FieldBuilder {...props}>
        <FloorSelector
          siteId={props.field.siteId}
          deviceType={props.field.deviceType}
          selectedFloor={props.value?.floor}
          selectedType={props.value?.floorType}
          className={classNames('w-100')}
          onChange={(floorData) => props.onChange({ floor: floorData.floor, floorType: floorData.type })}
          hasError={!!props.error}
          disabled={props.field.disabled}
        />
      </FieldBuilder>
    );
  },
};

export const FieldBuilder: React.FC<
  Omit<AnyFieldProps, 'value' | 'onChange'> & { description?: string | JSX.Element }
> = (props) => {
  const { fieldName, field, children, error, fieldsetClassName: fieldsetClassNameProp } = props;
  const { label, required, fieldsetClassName, description, hint, hideOptional, hidden } = field;

  if (hidden) {
    return null;
  }

  return (
    <fieldset className={classNames(fieldsetClassName, fieldsetClassNameProp)} style={buildFieldStyles(props)}>
      <div className='color-secondary d-flex gap-2 align-items-center'>
        <label htmlFor={fieldName}>
          {label}
          {label === ' ' ? <>&nbsp;</> : ''} {/* To display label even if it is empty */}
          {required || hideOptional ? null : <span>&nbsp;(optional)</span>}
        </label>
        <DescriptionBuilder description={props.description ?? description} />
      </div>
      <div className='mt-8 mb-2'>{children}</div>
      {!!hint && !error ? <p className='body-extra-small color-secondary'>{hint}</p> : null}
      {!hint || !!error ? (
        <p className='body-extra-small color-error input-error-message'>
          {error ?? ''}
          &nbsp;
        </p>
      ) : null}
    </fieldset>
  );
};

export const DescriptionBuilder: React.FC<{ description?: string | JSX.Element }> = ({ description }) => {
  if (!description) {
    return null;
  }

  return <InfoTooltip>{description}</InfoTooltip>;
};

function buildFieldStyles(props: Pick<AnyFieldProps, 'field' | 'fieldName'>): React.CSSProperties {
  return {
    gridArea: props.field.autoFlow ? undefined : props.field.gridArea || props.fieldName,
  };
}
