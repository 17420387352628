import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import { GetSystemModelsQuery, GetSystemModelsQueryVariables } from './__generated__/getSystemModels.resources.gql';

export type SystemModel = GetSystemModelsQuery['getSystemModels'][0];

const GET_SYSTEM_MODELS = gql`
  query GetSystemModels {
    getSystemModels {
      modelId
      systemType
      modelName
    }
  }
`;

export async function getSystemModels(): Promise<SystemModel[]> {
  const result = await resourcesAPI.query<GetSystemModelsQuery, GetSystemModelsQueryVariables>({
    query: GET_SYSTEM_MODELS,
  });

  return result.data.getSystemModels;
}
