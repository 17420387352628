import classNames from 'classnames';

import styles from './Tag.module.scss';

export type TagType = 'error' | 'warning' | 'light-warning' | 'secondary';

interface Props {
  type?: TagType;
  className?: string;
  children?: React.ReactNode;
}

const Tag: React.FC<Props> = (props) => {
  if (!props.type) {
    return <></>;
  }
  return (
    <div className={classNames('tag', styles['tag'], 'body-small-semi-bold', styles[props.type], props.className)}>
      {props.children}
    </div>
  );
};

export default Tag;
