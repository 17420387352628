import { AnyD3Scale, DefaultOutput, ScaleTypeToD3Scale } from '@visx/scale';
import createBandScale from '@visx/scale/lib/scales/band';
import createLinearScale from '@visx/scale/lib/scales/linear';
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { createContext, useContext, useMemo } from 'react';

export interface ChartPadding {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

interface ChartContextData {
  TooltipPortal: React.FC<TooltipInPortalProps>;
  xMax: number;
  yMax: number;
  padding: ChartPadding;
  /**
   * xDomain may have expected gaps (when some hours are filtered-out) between values,
   * so we need always to use scaleBand even for date ranges.
   *
   * It is expected that API will fill gaps with `null` values but valid timestamps.
   */
  xScale: ScaleTypeToD3Scale<DefaultOutput, string | number | Date>['band'];
  yScale: AnyD3Scale;
}

const ChartContext = createContext<ChartContextData | null>(null);

/**
 * Hook to get chart context data.
 * Provides ability to override yScale domain only for this usage, without affecting context.
 */
export function useChartContext(yDomain?: number[]): ChartContextData {
  const contextData = useContext(ChartContext) ?? {
    xMax: 0,
    yMax: 0,
    TooltipPortal: () => null,
    xScale: createBandScale({}),
    yScale: createLinearScale({}),
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  };

  // If yDomain is not provided, use default yScale, otherwise create custom yScale with provided domain
  const yScale = useMemo(() => {
    if (!yDomain) {
      return contextData.yScale;
    }
    const customScale = contextData.yScale.copy();
    customScale.domain(yDomain);
    return customScale;
  }, [contextData.yScale, yDomain]);

  return {
    ...contextData,
    yScale,
  };
}

export const ChartContextProvider = ChartContext.Provider;
