import { UNIT } from 'src/constants';
import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';

export const MAP_SYSTEM_METER_TYPE_TO_UNIT: Partial<Record<SystemType, UNIT>> = {
  [SystemType.ELECTRICITY_METER]: UNIT.KWH,
  [SystemType.ELECTRICITY_SUBMETER]: UNIT.KWH,
  [SystemType.NATURAL_GAS_METER]: UNIT.THERMS,
  [SystemType.STEAM_METER]: UNIT.MILLION_POUNDS,
  [SystemType.WATER_METER]: UNIT.HUNDRED_CUBIC_FEET,
};

export const SystemMetersInfo: Partial<Record<SystemType, { url: string; label: string }>> = {
  [SystemType.ELECTRICITY_METER]: {
    url: 'electricity',
    label: 'Electric Meters',
  },
  [SystemType.NATURAL_GAS_METER]: {
    url: 'gas',
    label: 'Natural Gas Meters',
  },
  [SystemType.STEAM_METER]: {
    url: 'steam',
    label: 'Steam Meters',
  },
  [SystemType.WATER_METER]: {
    url: 'water',
    label: 'Water Meters',
  },
};
