export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date };
};

export type AuditLog = {
  actions: Scalars['String']['output'];
  alertId?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  comment_id?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  initiator: Scalars['String']['output'];
  initiator_id: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['Int']['output']>;
  resourceId: Scalars['String']['output'];
  type: AuditLogType;
};

export type AuditLogComment = {
  comment: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export enum AuditLogResourceType {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum AuditLogType {
  ACTIVITY = 'ACTIVITY',
  ALERT = 'ALERT',
  EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
  NOTE = 'NOTE',
  SMS_NOTIFICATION = 'SMS_NOTIFICATION',
}

export type LogsFilterInput = {
  currentPage: Scalars['Int']['input'];
  perPageCount: Scalars['Int']['input'];
  type?: InputMaybe<AuditLogType>;
  userFilterIds: Array<Scalars['String']['input']>;
};

export type Mutation = {
  addComment: AuditLogComment;
  addNoteLog: Scalars['Boolean']['output'];
};

export type MutationaddCommentArgs = {
  auditLogId: Scalars['Int']['input'];
  comment: Scalars['String']['input'];
  commentId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationaddNoteLogArgs = {
  note: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
  resourceType: AuditLogResourceType;
};

export type PaginatedAuditLog = {
  data: Array<AuditLog>;
  pagination: Pagination;
};

export type Pagination = {
  currentPage: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  nextPage: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  prevPage: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  resourceAuditLogs: PaginatedAuditLog;
  systemUsersForFilter: Array<Scalars['String']['output']>;
};

export type QueryresourceAuditLogsArgs = {
  logsFilterInput: LogsFilterInput;
  resourceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceType: AuditLogResourceType;
};

export type QuerysystemUsersForFilterArgs = {
  resourceIds: Array<Scalars['String']['input']>;
  resourceType: AuditLogResourceType;
};
