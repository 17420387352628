import { ColorConfig } from 'src/enums';

export const colorConfigOrder: ColorConfig[] = [
  ColorConfig.blueSolidOpaque,
  ColorConfig.darkBlueSolidOpaque,
  ColorConfig.orangeSolidOpaque,
  ColorConfig.whiteSolidOpaque,
  ColorConfig.greenSolidOpaque,
  ColorConfig.redSolidOpaque,

  ColorConfig.blueDashedOpaque,
  ColorConfig.darkBlueDashedOpaque,
  ColorConfig.orangeDashedOpaque,
  ColorConfig.whiteDashedOpaque,
  ColorConfig.greenDashedOpaque,
  ColorConfig.redDashedOpaque,
  ColorConfig.greenDottedOpaque,

  ColorConfig.blueDottedOpaque,
  ColorConfig.darkBlueDottedOpaque,
  ColorConfig.orangeDottedOpaque,
  ColorConfig.whiteDottedOpaque,
  ColorConfig.greenDottedOpaque,
  ColorConfig.redDottedOpaque,

  ColorConfig.blueSolidTranslucent,
  ColorConfig.darkBlueSolidTranslucent,
  ColorConfig.orangeSolidTranslucent,
  ColorConfig.whiteSolidTranslucent,
  ColorConfig.greenSolidTranslucent,
  ColorConfig.redSolidTranslucent,

  ColorConfig.blueDashedTranslucent,
  ColorConfig.darkBlueDashedTranslucent,
  ColorConfig.orangeDashedTranslucent,
  ColorConfig.whiteDashedTranslucent,
  ColorConfig.greenDashedTranslucent,
  ColorConfig.redDashedTranslucent,

  ColorConfig.blueDottedTranslucent,
  ColorConfig.darkBlueDottedTranslucent,
  ColorConfig.orangeDottedTranslucent,
  ColorConfig.whiteDottedTranslucent,
  ColorConfig.greenDottedTranslucent,
  ColorConfig.redDottedTranslucent,
];

// TODO: rename and move this file to utils folder

/**
 * Provides available color based on current index.
 * Uses rotation, so after 36th time - colors will start to repeat themselves.
 */
export function chartColorByIndex(index: number): ColorConfig {
  return colorConfigOrder[index % colorConfigOrder.length];
}
