import { useState } from 'react';

import { ColorConfig, LoadingStatus, SupportedColors } from 'src/enums';

import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import CheckboxWithPartial from '../Checkbox/CheckboxWithPartial';
import { CheckboxState } from '../Checkbox/interface';

import styles from './CheckboxGroup.module.scss';

export interface CheckboxGroupProps<T> {
  items: CheckboxGroupItemProps<T>[];
  onClick: (id: T, checked: boolean) => void;
  disableGroup?: boolean;
  /**
   * Represents amount of columns to split checkbox group
   *
   * @default 1
   */
  amountOfColumns?: number;
  ellipsis?: boolean;
}

export interface CheckboxGroupItemProps<T> {
  id: T;
  name: string;
  selected: boolean;
  loadingStatus?: LoadingStatus;
  /**
   * disabled={false} by default
   */
  disabled?: boolean;
  /**
   * styleVariant variation that of the checkbox;
   * ColorConfig.blueSolidOpaque by default
   */
  styleVariant?: ColorConfig;
  dotWithColor?: SupportedColors;
  children?: CheckboxGroupItemProps<T>[];
}

//disabled state opacity
export function CheckboxGroup<T extends string | number | symbol>(props: CheckboxGroupProps<T>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedParentsState, setExpandedParentsState] = useState<Record<T, boolean>>({} as any);

  function onExpandClick(parentId: T) {
    setExpandedParentsState((state) => ({ ...state, [parentId]: !expandedParentsState[parentId] }));
  }

  function onGroupCheckBoxClick(children?: CheckboxGroupItemProps<T>[]) {
    if (!children) {
      return;
    }
    if (children.every((child) => child.selected)) {
      children.forEach((child) => props.onClick(child.id, false));
      return;
    }
    children.forEach((child) => props.onClick(child.id, true));
  }

  return (
    <div
      className={styles['checkbox-group']}
      style={{
        gridTemplateColumns: Array(props.amountOfColumns ?? 1)
          .fill('1fr')
          .join(' '),
      }}
    >
      {props.items.map((it) =>
        it.children && it.children.length > 0 ? (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <div className={styles['subgroup-container']} key={it.id as any}>
            <div className={styles['head']}>
              <CheckboxWithPartial
                label={it.name}
                state={getParentCheckboxState(it.children)}
                onClick={() => onGroupCheckBoxClick(it.children)}
                disabled={it.disabled || props.disableGroup}
                ellipsis={props.ellipsis}
                dotWithColor={it.dotWithColor}
              />
              <Button
                variant='flat'
                onClick={() => onExpandClick(it.id)}
                icon={expandedParentsState[it.id] ? 'dropdown-up' : 'dropdown-down'}
                size='small'
              />
            </div>

            {expandedParentsState[it.id] && (
              <div className={styles['subgroup-children']}>
                {it.children.map((child) => (
                  <Checkbox
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    key={child.id as any}
                    label={child.name}
                    selected={child.selected}
                    disabled={child.disabled || props.disableGroup}
                    styleVariant={it.styleVariant}
                    onClick={() => props.onClick(child.id, !child.selected)}
                    ellipsis={props.ellipsis}
                    dotWithColor={it.dotWithColor}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          <Checkbox
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            key={it.id as any}
            selected={it.selected}
            label={it.name}
            styleVariant={it.styleVariant}
            disabled={it.disabled || props.disableGroup}
            onClick={() => props.onClick(it.id, !it.selected)}
            ellipsis={props.ellipsis}
            dotWithColor={it.dotWithColor}
          />
        )
      )}
    </div>
  );
}

function getParentCheckboxState<T>(children: CheckboxGroupItemProps<T>[]): CheckboxState {
  if (children.every((child) => child.selected)) {
    return CheckboxState.Checked;
  }
  if (children.some((child) => child.selected)) {
    return CheckboxState.Partial;
  }
  return CheckboxState.Empty;
}
