import { ApolloClient, from, InMemoryCache, InMemoryCacheConfig, NormalizedCacheObject } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';

import authService from 'src/core/service/authService';

import { authMiddleware, errorHandlingMiddleware, withToken } from './middleware';

const HttpWebsocketProtocolMapping: Record<string, string> = {
  ['https']: 'wss',
  ['http']: 'ws',
};

/**
 * Generate Client for Apollo to access GraphQL API
 */
export function apolloClientFactory(
  suffix: string,
  cacheConfig?: InMemoryCacheConfig,
  supportFormData?: boolean
): ApolloClient<NormalizedCacheObject> {
  const url = process.env['REACT_APP_GQL_' + suffix];

  if (!url) {
    throw new Error(`Missing REACT_APP_GQL_${suffix} env variable`);
  }

  const uploadLink = createUploadLink({
    uri: url,
    ...(supportFormData && { headers: { 'Apollo-Require-Preflight': 'true' } }),
  });

  const cache = new InMemoryCache(cacheConfig);

  return new ApolloClient({
    // WARN! Order matters! Do not change it
    link: from([withToken, errorHandlingMiddleware, authMiddleware.concat(uploadLink)]),
    cache,
    defaultOptions: {
      // When need to set custom policy - choose the one which works best for your use-case
      // https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
      query: {
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        // TODO: Define how to update codebase to so we can use `cache-and-network` policy
        fetchPolicy: 'network-only',
      },
      mutate: {
        fetchPolicy: 'network-only',
      },
    },
  });
}

export function apolloSubscriptionClientFactory(
  suffix: string,
  cacheConfig?: InMemoryCacheConfig
): ApolloClient<NormalizedCacheObject> {
  const reactURL = process.env['REACT_APP_GQL_' + suffix];
  if (!reactURL) {
    throw new Error(`Missing REACT_APP_GQL_${suffix} env variable`);
  }

  const [protocol, url] = reactURL.split('://', 2);

  const uploadLink = new GraphQLWsLink(
    createClient({
      url: `${HttpWebsocketProtocolMapping[protocol]}://${url}`,
      lazy: true,
      keepAlive: 60000,
      retryAttempts: 999,
      shouldRetry: () => true,
      connectionParams: async () => {
        const token = await authService.getJWT();
        return {
          authToken: token ? `Bearer ${token}` : '',
        };
      },
    })
  );

  const cache = new InMemoryCache(cacheConfig);

  return new ApolloClient({
    link: from([errorHandlingMiddleware, uploadLink]),
    cache,
  });
}
