import { gql } from '@apollo/client';

import { resourcesAPI } from 'src/core/apollo/api';

import {
  ChangePackageSystemSetpointMutation,
  ChangePackageSystemSetpointMutationVariables,
} from './__generated__/changePackageSystemSetpoint.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterUpdatingSetpoint = Extract<
  ChangePackageSystemSetpointMutation['updatePackageSystemSetpoint'],
  { __typename: 'SystemPackageAltc24Prog' }
>;

const CHANGE_PACKAGE_SYSTEM_SETPOINT = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangePackageSystemSetpoint($systemId: Int!, $setpointValue: Int!) {
    updatePackageSystemSetpoint(setpoint: $setpointValue, systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changePackageSystemSetpoint(
  systemId: number,
  setpoint: number
): Promise<DataAfterUpdatingSetpoint | undefined> {
  const result = await resourcesAPI.mutate<
    ChangePackageSystemSetpointMutation,
    ChangePackageSystemSetpointMutationVariables
  >({
    mutation: CHANGE_PACKAGE_SYSTEM_SETPOINT,
    variables: { systemId, setpointValue: setpoint },
  });

  const updateResult = result.data?.updatePackageSystemSetpoint as DataAfterUpdatingSetpoint;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
