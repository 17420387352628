import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _, { NumericDictionary } from 'lodash';

import { N_A } from 'src/constants';
import { OnlineStatus, SystemStatus, SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import type { RootState } from 'src/core/store/store';
import { ISiteIAQScore } from 'src/interfaces';

import { SiteFitwell } from '../sites/gql/getSiteFitwell.resources.gql';
import { selectedSites } from '../sites/sitesSlice';

import { IAQScorePerSite } from './gql/getIAQScorePerSite.resources.gql';
import { IAQScoreConfig } from './gql/getIAQSystemConfig.resources.gql';
import { IAQModelMeasurements } from './gql/getIAQSystemsForSite.resources.gql';
import { AnyIAQSystem } from './interface';
import { IAQFilterValues } from './pages/IAQDetailsPage/utils';

interface IIAQSystemsSlice {
  iaqsMap: NumericDictionary<AnyIAQSystem>;
  siteFitwell: SiteFitwell;
  iaqScorePerSiteMap: NumericDictionary<IAQScorePerSite>;
  iaqIdsPerFloor: Record<string, number[]>;
  floorOrder: string[];
  selectedIAQs: Record<number, boolean>;
  supportedMeasurements?: IAQModelMeasurements;
  scoreConfig?: IAQScoreConfig;
  lastUpdatedDateForSiteInfo: Date;
  lastUpdatedDateForScorePerSite: Date;
}

const getInitialState = (): IIAQSystemsSlice => ({
  iaqsMap: {},
  iaqScorePerSiteMap: {},
  iaqIdsPerFloor: {},
  floorOrder: [],
  selectedIAQs: {},
  lastUpdatedDateForSiteInfo: new Date(),
  siteFitwell: null,
  lastUpdatedDateForScorePerSite: new Date(),
});

export const systemsSlice = createSlice({
  name: 'iaq',
  initialState: getInitialState(),
  reducers: {
    resetSlice: () => getInitialState(),
    setIAQScorePerSites: (state, action: PayloadAction<IAQScorePerSite[]>) => {
      state.iaqScorePerSiteMap = _.keyBy(action.payload, 'siteId');
      state.lastUpdatedDateForScorePerSite = new Date();
    },
    setScoreConfig: (state, action: PayloadAction<IAQScoreConfig>) => {
      state.scoreConfig = action.payload;
    },
    setIAQSystems: (state, action: PayloadAction<AnyIAQSystem[]>) => {
      // Update list of all system ids available to the current user
      state.iaqIdsPerFloor = {};
      state.selectedIAQs = [];
      state.iaqsMap = {};
      state.floorOrder = [];
      let supportedMeasurements: Partial<IAQModelMeasurements> = {};
      for (const iaqSystem of action.payload) {
        state.iaqsMap[iaqSystem.id] = iaqSystem;
        const floor = iaqSystem.floor || 'Unknown';
        if (!state.iaqIdsPerFloor[floor]) {
          state.iaqIdsPerFloor[floor] = [];
          state.floorOrder.push(floor);
        }
        state.iaqIdsPerFloor[floor].push(iaqSystem.id);
        state.selectedIAQs[iaqSystem.id] = true;
        supportedMeasurements = _.mergeWith(supportedMeasurements, iaqSystem.model, (obj, inc) => obj || inc);
      }
      state.supportedMeasurements = _.omit(supportedMeasurements, ['name', '__typename']) as IAQModelMeasurements;
    },
    setSiteFitwell: (state, action: PayloadAction<SiteFitwell>) => {
      state.siteFitwell = action.payload;
    },
    setLastUpdatedSiteInfoDate: (state, action: PayloadAction<Date>) => {
      state.lastUpdatedDateForSiteInfo = action.payload;
    },
  },
});

export const {
  resetSlice: resetIaqSlice,
  setIAQSystems,
  setIAQScorePerSites,
  setScoreConfig,
  setLastUpdatedSiteInfoDate,
  setSiteFitwell,
} = systemsSlice.actions;

export const selectIAQScorePerSites = (state: RootState): Array<ISiteIAQScore> => {
  const orderedList = _.chain(selectedSites(state))
    .map((site) => ({
      ...site,
      ...(state.iaq.iaqScorePerSiteMap[site.id] ?? {}),
      disabled: !(site.availableSystemByTypes?.includes(SystemType.TBL_TCI) ?? true),
    }))
    .orderBy(['disabled'])
    .value();

  return orderedList;
};

export const selectAllIAQsIds = (state: RootState): number[] =>
  _.entries(state.iaq.selectedIAQs).map(([id]) => Number(id));

export const selectSelectedIAQsIds = (state: RootState): number[] =>
  _.entries(state.iaq.selectedIAQs)
    .filter(([, selected]) => selected)
    .map(([id]) => Number(id));

export const selectTotalScore =
  (filters?: IAQFilterValues) =>
  (state: RootState): number => {
    const selectedIaqIds = filters
      ? selectSelectedIAQsDataWithFilter(filters)(state)
          .map((iaq) => iaq.id)
          .filter((id) => state.iaq.iaqsMap[Number(id)].status === SystemStatus.ON)
      : _.entries(state.iaq.selectedIAQs)
          .filter(([id, selected]) => selected && state.iaq.iaqsMap[Number(id)].status === SystemStatus.ON)
          .map(([id]) => Number(id));

    const selectedIaqIdsForScoreCalculation = selectedIaqIds.filter((id) => {
      const iaqSystem = state.iaq.iaqsMap[id];
      return (
        iaqSystem.onlineStatus !== OnlineStatus.OFFLINE &&
        iaqSystem.onlineStatus !== OnlineStatus.NOT_CONFIGURED &&
        iaqSystem.status !== SystemStatus.OFF
      );
    });

    const scoreSum = selectedIaqIdsForScoreCalculation.reduce(
      (total, id) => total + (state.iaq.iaqsMap[id].score ?? 0),
      0
    );

    return Math.round(scoreSum / selectedIaqIdsForScoreCalculation.length);
  };

export const selectFieldRound =
  (field: string, precision?: number, filters?: IAQFilterValues) =>
  (state: RootState): string => {
    const iaqField = field as keyof AnyIAQSystem;
    const iaqsMap = state.iaq.iaqsMap;

    const selectedIaqIds = filters
      ? selectSelectedIAQsDataWithFilter(filters)(state).map((iaq) => Number(iaq.id))
      : _.entries(state.iaq.selectedIAQs)
          .filter(([, selected]) => selected)
          .map(([id]) => Number(id));

    const onlineIAQs = selectedIaqIds.filter((id) => iaqsMap[id].status === SystemStatus.ON);
    if (!onlineIAQs.length) {
      return N_A;
    }

    const fieldValues = onlineIAQs
      .map((id) => {
        const iaq = iaqsMap[Number(id)];
        return iaq[iaqField];
      })
      .filter((value) => _.isNumber(value));

    const mean = _.mean(fieldValues);

    return _.isNumber(mean) && !isNaN(mean) ? mean.toFixed(precision) : N_A;
  };

export const selectSelectedIAQsData = (state: RootState): AnyIAQSystem[] =>
  state.iaq.floorOrder.flatMap((floor) => {
    const ids = state.iaq.iaqIdsPerFloor[floor];
    return _.chain(ids)
      .filter((id) => state.iaq.selectedIAQs[id])
      .map((id) => state.iaq.iaqsMap[Number(id)])
      .sortBy('name')
      .value();
  });

export const selectScoreConfig = (state: RootState): IAQScoreConfig | undefined => state.iaq.scoreConfig;

export const selectSupportedMeasurements =
  (filters?: IAQFilterValues) =>
  (state: RootState): IAQModelMeasurements => {
    const systemIds = selectSelectedIAQsDataWithFilter(filters)(state).map((iaq) => iaq.id);

    if (_.isEmpty(systemIds) || !systemIds) {
      if (state.iaq.supportedMeasurements) {
        return state.iaq.supportedMeasurements;
      }

      return {
        temperature: false,
        relativeHumidity: false,
        co2: false,
        co: false,
        o3: false,
        tvoc: false,
        pm2_5: false,
        pm10: false,
        ch2o: false,
        score: false,
        damperPosition: false,
        damperEffective: false,
      };
    }

    const selectedIaqs = systemIds.map((id) => state.iaq.iaqsMap[Number(id)]);

    return {
      temperature: _.filter(selectedIaqs, 'model.temperature').length > 0,
      relativeHumidity: _.filter(selectedIaqs, 'model.relativeHumidity').length > 0,
      co2: _.filter(selectedIaqs, 'model.co2').length > 0,
      co: _.filter(selectedIaqs, 'model.co').length > 0,
      o3: _.filter(selectedIaqs, 'model.o3').length > 0,
      tvoc: _.filter(selectedIaqs, 'model.tvoc').length > 0,
      pm2_5: _.filter(selectedIaqs, 'model.pm2_5').length > 0,
      pm10: _.filter(selectedIaqs, 'model.pm10').length > 0,
      ch2o: _.filter(selectedIaqs, 'model.ch2o').length > 0,
      damperPosition: _.filter(selectedIaqs, 'sensors.damperPosition').length > 0,
      damperEffective: _.filter(selectedIaqs, 'sensors.damperEffective').length > 0,
      score: true,
    };
  };

export const selectLastUpdatedScorePerSiteDate = (state: RootState): Date => state.iaq.lastUpdatedDateForScorePerSite;

export const selectSiteFitwell = (state: RootState): SiteFitwell => state.iaq.siteFitwell;

export const selectSelectedIAQsIdsWithFilter =
  (filters?: IAQFilterValues) =>
  (state: RootState): number[] =>
    selectSelectedIAQsDataWithFilter(filters)(state).map((iaq) => iaq.id);

export const selectSelectedIAQsDataWithFilter =
  (filters?: IAQFilterValues) =>
  (state: RootState): AnyIAQSystem[] =>
    state.iaq.floorOrder.flatMap((floor) => {
      const ids = state.iaq.iaqIdsPerFloor[floor];
      return _.chain(ids)
        .filter((id) => state.iaq.selectedIAQs[id])
        .map((id) => state.iaq.iaqsMap[Number(id)])
        .filter((iaq) => {
          if (!filters || _.isEmpty(filters)) {
            return true;
          }
          if (filters.floors?.length && iaq.floor && !filters.floors.includes(iaq.floor)) {
            return false;
          }
          if (filters.groupedSystemIds?.length && !filters.groupedSystemIds.flatMap((i) => i).includes(iaq.id)) {
            return false;
          }
          return true;
        })
        .sortBy('name')
        .value();
    });

export default systemsSlice.reducer;
