import type * as Types from '../../../../core/apollo/__generated__/utilityGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type UtilityDataOverviewQueryVariables = Types.Exact<{
  siteIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  utilityServiceType: Types.UtilityServiceTypes;
}>;

export type UtilityDataOverviewQuery = {
  getUtilityDataOverview: Array<{
    siteId: number;
    amountOfAccounts: number;
    amountOfMeters: number;
    consumptionForMonthBeforePreviousMonth?: number | null;
    consumptionForPreviousMonth?: number | null;
    consumptionChange?: number | null;
    costForMonthBeforePreviousMonth?: number | null;
    costForPreviousMonth?: number | null;
    costChange?: number | null;
  }>;
};

export const UtilityDataOverviewDocument = gql`
  query UtilityDataOverview($siteIds: [Int!]!, $utilityServiceType: UtilityServiceTypes!) {
    getUtilityDataOverview(siteIds: $siteIds, utilityServiceType: $utilityServiceType) {
      siteId
      amountOfAccounts
      amountOfMeters
      consumptionForMonthBeforePreviousMonth
      consumptionForPreviousMonth
      consumptionChange
      costForMonthBeforePreviousMonth
      costForPreviousMonth
      costChange
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    UtilityDataOverview(
      variables: UtilityDataOverviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UtilityDataOverviewQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UtilityDataOverviewQuery>(UtilityDataOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UtilityDataOverview',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
