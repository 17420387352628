import { ApolloError } from '@apollo/client';
import type { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import _ from 'lodash';

import Logger from 'src/core/service/logger';

type ExpectedError = { message: string; extensions: { code?: string } };

export function isApolloError(error: unknown): error is ApolloError {
  return error instanceof ApolloError;
}

export function showMessageIfNotApolloException(error: unknown, message: string): void {
  if (isApolloError(error)) {
    // Do nothing, error will be handled by global error handler
  }
  console.error(error);
  Logger.error(message);
}

/**
 * Server may return error in two ways:
 * 1. As a NetworkError
 * 2. As a list of GraphQLErrors
 * This function tries to get HTTP status code from different sources
 */
export function getErrorMessage(networkError?: NetworkError, gqlErrors: GraphQLErrors = []): string | undefined {
  for (const error of gqlErrors) {
    const message = getMessage(error);
    if (message) {
      return message;
    }
  }

  if (networkError) {
    return 'Sorry, some network error occurred';
  }
}

export function isUnauthorized(networkError?: NetworkError, gqlErrors: GraphQLErrors = []): boolean {
  for (const error of gqlErrors) {
    if (error.extensions.code === 'UNAUTHORIZED') {
      return true;
    }
  }
  if (_.get(networkError, 'statusCode') === 401) {
    return true;
  }
  return false;
}

/**
 * In case of several validation errors, server returns array of messages for each field / validation
 */
function getMessage(error: ExpectedError): string | undefined {
  const code = _.get(error, 'extensions.code');
  switch (code) {
    case 'FORBIDDEN':
      // TODO: throw exception so router can display error page
      return 'Sorry, you do not have permission';

    case 'NOT_FOUND':
      // TODO: throw exception so router can display error page
      return 'Sorry, the requested resource does not exist';

    case 'REQUEST_TIMEOUT':
      return 'Sorry, request took too long to process';

    case 'CONFLICT':
    case 'INTERNAL_SERVER_ERROR':
    case 'BAD_USER_INPUT':
    case 'GRAPHQL_VALIDATION_FAILED':
      // TODO: throw exception so router can display error page
      return 'Sorry, something went wrong';

    case 'BAD_REQUEST':
      if (_.isArray(error.message)) {
        return _.first(error.message) as string;
      }
      return error.message;
    default:
      console.error('Unknown error code', code);
  }
}
