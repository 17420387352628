import { gql } from '@apollo/client';

import { SystemStatus } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  ChangeLightingSystemModeMutation,
  ChangeLightingSystemModeMutationVariables,
} from './__generated__/changeLightingSystemMode.resources.gql';
import { SYSTEM_UNION_SPECIFIC_FIELDS } from './fragments.resources.gql';

export type DataAfterUpdatingLightingMode = Extract<
  ChangeLightingSystemModeMutation['updateLightingSystemMode'],
  {
    __typename: 'SystemLightingVive';
  }
>;

const CHANGE_LIGHTING_SYSTEM_MODE = gql`
  ${SYSTEM_UNION_SPECIFIC_FIELDS}
  mutation ChangeLightingSystemMode($systemId: Int!, $mode: SystemStatus!) {
    updateLightingSystemMode(mode: $mode, systemId: $systemId) {
      __typename
      ...SystemUnionSpecificFields
    }
  }
`;

export async function changeLightingSystemMode(
  systemId: number,
  mode: SystemStatus
): Promise<DataAfterUpdatingLightingMode | undefined> {
  const result = await resourcesAPI.mutate<ChangeLightingSystemModeMutation, ChangeLightingSystemModeMutationVariables>(
    {
      mutation: CHANGE_LIGHTING_SYSTEM_MODE,
      variables: { systemId, mode },
    }
  );

  const updateResult = result.data?.updateLightingSystemMode as DataAfterUpdatingLightingMode;

  if (updateResult) {
    return {
      ...updateResult,
      // Cache is updated in background, so we need to map returned data here
      updatedAt: new Date(updateResult.updatedAt),
      statusUpdatedAt: new Date(updateResult.statusUpdatedAt),
    };
  }
}
