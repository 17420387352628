import { MetricType, SystemGroupType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { persistentStorage } from 'src/core/service/persistentStorage';
import { PersistentStorageKeys } from 'src/enums';

/**
 * Service provide methods to get selected by user system metrics and update selected metrics for specific system
 */
class SystemMetricsStorageService {
  /**
   * Record<system group type, metric types[]>
   * @private
   */
  private data: Record<string, MetricType[]> = {};

  init() {
    const data = persistentStorage.getObject<Record<string, MetricType[]>>(
      PersistentStorageKeys.SelectedSystemsMetrics
    );
    if (data) {
      this.data = data;
    }
  }

  update(systemGroupType: SystemGroupType, metricTypes: MetricType[]): void {
    this.data[systemGroupType] = metricTypes;
    persistentStorage.setObject<Record<string, MetricType[]>>(PersistentStorageKeys.SelectedSystemsMetrics, this.data);
  }

  getBySystemId(systemGroupType: SystemGroupType): MetricType[] {
    return this.data[systemGroupType] || [];
  }

  getAll(): Record<number, MetricType[]> {
    return this.data;
  }
}

export const systemMetricsStorageService = new SystemMetricsStorageService();
