import React, { useEffect, useState } from 'react';

import { selectModal, setModal } from 'src/core/store/global/globalSlice';
import { useAppDispatch, useAppSelector } from 'src/core/store/hooks';
import ConfirmationMessageModal from 'src/shared/components/ConfirmationMessageModal/ConfirmationMessageModal';

const ConfirmationModalContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(selectModal);
  const [data, setData] = useState<{}>({});

  const isOpen = !!modal;

  useEffect(() => {
    if (isOpen) {
      setData({});
    }
  }, [isOpen]);

  return (
    <ConfirmationMessageModal
      isOpen={isOpen}
      type={modal?.type}
      title={modal?.title || ''}
      subtitle={modal?.subtitle || ''}
      actionButtonLabel={modal?.actionButtonLabel}
      cancelButtonLabel={modal?.cancelButtonLabel}
      onCancel={() => {
        modal?.callback({ result: false, data });
        dispatch(setModal(undefined));
      }}
      onAccept={() => {
        modal?.callback({ result: true, data });
        dispatch(setModal(undefined));
      }}
    >
      {modal?.renderContent?.(data, setData)}
    </ConfirmationMessageModal>
  );
};

export default ConfirmationModalContainer;
