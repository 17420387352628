import { RouteObject } from 'react-router-dom';

import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import NotFoundPage from 'src/fragments/NotFoundPage/NotFoundPage';

import DownloadReportPage from './report';

const router: RouteObject[] = [
  {
    path: 'download',
    children: [
      {
        path: 'report/*',
        element: <DownloadReportPage />,
        errorElement: <ExceptionHandler />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

export default router;
