import { gql } from '@apollo/client';

import { OverrideSchedulerProfileInput } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  OverrideSchedulerProfileMutation,
  OverrideSchedulerProfileMutationVariables,
} from './__generated__/overrideSchedulerProfile.resources.gql';

const OVERRIDE_SCHEDULER_PROFILE = gql`
  mutation OverrideSchedulerProfile($input: OverrideSchedulerProfileInput!) {
    overrideSchedulerProfile(input: $input)
  }
`;

export async function overrideSchedulerProfile(payload: OverrideSchedulerProfileInput): Promise<boolean | undefined> {
  const result = await resourcesAPI.mutate<OverrideSchedulerProfileMutation, OverrideSchedulerProfileMutationVariables>(
    {
      mutation: OVERRIDE_SCHEDULER_PROFILE,
      variables: {
        input: payload,
      },
    }
  );

  return result.data?.overrideSchedulerProfile ?? false;
}
