import classNames from 'classnames';
import React, { forwardRef } from 'react';

import styles from './Icon.module.scss';
import genStyles from './gen/icomoon.module.css';
import { SupportedIcon } from './gen/suported-icons';

export type SupportedIconColors = 'primary' | 'secondary' | 'red' | 'orange' | 'tertiary' | 'green' | 'yellow';
export type SupportedIconSizes = 'xs' | 's' | 'm' | 'l';

export interface IconProps {
  icon: SupportedIcon;

  /**
   * 'primary' color is used by default.
   */
  color?: SupportedIconColors;

  /**
   * 'l' size is used by default.
   *
   * T-Shirt sizing is has corelation with pixel-sizing:
   *
   * xs = 12x12
   *
   *  s = 16x16
   *
   *  m = 20x20
   *
   *  l = 24x24
   */
  size?: SupportedIconSizes;

  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  style?: React.CSSProperties;

  /**
   * If true, icon will be rotating via CSS animations.
   */
  rotating?: boolean;
}

export const Icon = forwardRef<HTMLSpanElement, IconProps>(function Icon(
  { icon, size = 'l', color = 'primary', className = '', style, rotating, ...props },
  ref
) {
  return (
    <span
      ref={ref}
      className={classNames([
        'icon',
        'icon-gen',
        genStyles[`icon-ic-${icon}`],
        styles[`icon-col-${color}`],
        styles[size],
        rotating && styles['rotating'],
        { 'with-pointer': !!props.onClick },
        className,
      ])}
      style={style}
      {...props}
    />
  );
});
