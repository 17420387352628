import _ from 'lodash';
import { createContext, useContext, useEffect, useMemo } from 'react';

import { useDataFetchOnMountWithDeps } from 'src/cdk/hooks/useFetchDataOnMountWithDeps';
import { useOnMount } from 'src/cdk/hooks/useOnMount';
import { mapAsOptions } from 'src/cdk/mappers/mapAsOptions';
import { mapUnitToPrecision } from 'src/cdk/mappers/mapUnitToPrecision';
import { fillColorByIndex } from 'src/cdk/utils/fillColorByIndex';
import { METRIC_SYMBOL, N_A } from 'src/constants';
import { SystemType } from 'src/core/apollo/__generated__/utilityGlobalTypes';
import { METRIC_TYPE_LABEL } from 'src/core/enum-labels';
import { useAppDispatch, useAppSelector } from 'src/core/store/hooks';
import store, { RootState } from 'src/core/store/store';
import { Metric } from 'src/materials/metrics/interface';
import { resetFilterFields, setFilterFields } from 'src/modules/filters/filtersSlice';
import { useFilterValues } from 'src/modules/filters/public/useFilterValues';
import { selectSiteById } from 'src/modules/sites/sitesSlice';
import { ChargeStationSystemCard } from 'src/modules/systems/gql/getSystemsForSite.resources.gql';
import { fetchSystemsForSelectedSites } from 'src/modules/systems/systemsActions';
import {
  selectedSystemById,
  selectSystemIdsForSite,
  selectSystemsForSite,
  setSystemType,
} from 'src/modules/systems/systemsSlice';

import api from './gql';

type FilterValues = {
  systemIds: number[];
  ports: string[];
  levels: (string | null)[];
};

interface ContextData {
  siteId: number;
  filtersStorageKey: string;
}

const ChargingStationsContext = createContext<ContextData | null>(null);

const selectSystemsDataForFilters = (siteId: number) => (state: RootState) => {
  return selectSystemsForSite<ChargeStationSystemCard>(siteId)(state).map((system) => {
    const portsOptionsData = (system.ports || []).map((port) => ({
      portNumber: port.portNumber,
      level: port.level,
    }));
    return {
      id: system.id,
      name: system.name,
      ports: portsOptionsData,
      levels: portsOptionsData,
    };
  });
};

export function useChargingStationsContextProvider(siteId: number) {
  const dispatch = useAppDispatch();

  const filtersStorageKey = `charging-stations-${siteId}`;

  useOnMount(() => {
    dispatch(setSystemType([SystemType.CHARGE_POINT]));
  });

  const { isLoading } = useDataFetchOnMountWithDeps(() => dispatch(fetchSystemsForSelectedSites(siteId)), [siteId]);

  const systemsFilterOptions = useAppSelector(selectSystemsDataForFilters(siteId), _.isEqual);

  useEffect(() => {
    dispatch(resetFilterFields());
    dispatch(
      setFilterFields([
        {
          label: 'Stations',
          name: 'systemIds',
          options: mapAsOptions(systemsFilterOptions, 'id', 'name'),
          type: 'multiselect',
        },
        {
          label: 'Ports',
          name: 'ports',
          options: mapAsOptions(
            systemsFilterOptions.flatMap((i) => i.ports),
            'portNumber',
            'portNumber',
            undefined,
            { uniqByKey: true }
          ),
          type: 'multiselect',
        },
        {
          label: 'Levels',
          name: 'levels',
          options: mapAsOptions(
            systemsFilterOptions.flatMap((i) => i.levels),
            'level',
            'level',
            undefined,
            { uniqByKey: true }
          ),
          type: 'multiselect',
        },
      ])
    );
  }, [systemsFilterOptions]);

  const data = useMemo<ContextData>(
    () => ({
      siteId,
      filtersStorageKey,
    }),
    [siteId]
  );

  return {
    isLoading,
    Provider: ProviderHOC(data),
  };
}

function ProviderHOC(contextData: ContextData): React.FC<React.PropsWithChildren> {
  return function ChargingStationsContextProvider({ children }) {
    return <ChargingStationsContext.Provider value={contextData}>{children}</ChargingStationsContext.Provider>;
  };
}

export function useChargingStationsContext() {
  const context = useContext(ChargingStationsContext);
  if (!context) {
    throw new Error('useChargingStationsContext must be used within a ChargingStationsContextProvider');
  }

  const { siteId } = context;
  const { filterValues } = useFilterValues<FilterValues>({ storageKey: context.filtersStorageKey });
  const site = useAppSelector(selectSiteById(siteId));
  const systems = useAppSelector(selectSystemsForSite<ChargeStationSystemCard>(siteId), _.isEqual);
  const systemIds = useAppSelector(selectSystemIdsForSite(siteId), _.isEqual);

  const { response: metrics = [] } = useDataFetchOnMountWithDeps<Metric[]>(
    () =>
      _.isEmpty(systemIds)
        ? Promise.resolve([])
        : api
            .GetChargingStationsMetrics({
              systemIds: _.isEmpty(filterValues.systemIds) ? systemIds : filterValues.systemIds,
              portNumbers: filterValues.ports,
              levels: filterValues.levels,
            })
            .then((res) => {
              const state = store.getState();

              return res.getChargePointStationsMetrics.map((metric, index) => {
                const system = selectedSystemById<ChargeStationSystemCard>(metric.systemId)(state);
                const systemName = system?.name ?? N_A;
                const portNumber = system?.ports?.find((port) => port.id === metric.portId)?.portNumber ?? N_A;

                return {
                  ...metric,
                  // TODO: review solution
                  tooltipColumnValue: [
                    ['Station', systemName],
                    ['Port', portNumber],
                  ],
                  name: METRIC_TYPE_LABEL[metric.type],
                  unit: METRIC_SYMBOL[metric.measurement],
                  precision: mapUnitToPrecision(METRIC_SYMBOL[metric.measurement]),
                  styleVariant: fillColorByIndex(index),
                } satisfies Metric;
              });
            }),
    [filterValues.levels, filterValues.ports, filterValues.systemIds, systemIds]
  );

  const result = useMemo(() => {
    const filteredSystems = systems
      .map((system) => {
        const byStation = _.isEmpty(filterValues.systemIds) ? true : filterValues.systemIds.includes(system.id);

        if (!byStation) {
          return undefined;
        }

        return {
          ...system,
          ports: (system.ports || []).filter((port) => {
            const byPort = _.isEmpty(filterValues.ports) ? true : filterValues.ports.includes(port.portNumber);
            const byLevel = _.isEmpty(filterValues.levels) ? true : filterValues.levels.includes(port.level || null);

            return byPort && byLevel;
          }),
        };
      })
      .filter((system) => !!system);

    return {
      siteId: context.siteId,
      siteName: site?.nameOrAddess || N_A,
      timezone: site?.timezone || 'UTC',
      filtersStorageKey: context.filtersStorageKey,
      systems: filteredSystems,
      filterValues: filterValues,
      metrics: metrics,
      metricIds: metrics.map((m) => m.id),
    };
  }, [context, filterValues, systems, site, metrics]);

  return result;
}
