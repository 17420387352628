import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Content, GroupedContentList, ContentList } from 'src/atoms/GroupedList/GroupedList';
import { AlertColor } from 'src/core/apollo/__generated__/alertsGlobalTypes';
import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import type { RootState } from 'src/core/store/store';

import { UnresolvedNotification } from './interface';

type NotificationsWithColor = {
  color?: AlertColor;
  data: Content;
};

const COLOR_PRIORITY: Record<AlertColor, number> = {
  [AlertColor.RED]: 3,
  [AlertColor.ORANGE]: 2,
  [AlertColor.YELLOW]: 1,
  [AlertColor.GREEN]: 0,
};

interface IAlertsSlice {
  loading: boolean;
  notifications: UnresolvedNotification[];
  bySystem: Record<number, NotificationsWithColor>;
  bySite: Record<number, NotificationsWithColor>;
  bySiteAndSystemType: Record<string, NotificationsWithColor>;
}

const getInitialState = (loading: boolean): IAlertsSlice => ({
  loading: loading,
  notifications: [],
  bySystem: {},
  bySite: {},
  bySiteAndSystemType: {},
});

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: getInitialState(true),
  reducers: {
    resetSlice: () => getInitialState(false),
    setUnresolvedNotifications: (state, action: PayloadAction<UnresolvedNotification[]>) => {
      // Reset state
      state.notifications = action.payload;
      state.bySite = {};
      state.bySiteAndSystemType = {};
      state.bySystem = {};

      for (const notification of action.payload) {
        // Group notifications by system
        addItem(state.bySystem, notification.systemId, notification.color, notification.name);

        // Group notifications by site
        addItemToGroup(
          state.bySite,
          notification.siteId,
          notification.color,
          `[${notification.floor}] ${notification.systemName}`,
          notification.name
        );

        // Group notifications by site and system type
        addItemToGroup(
          state.bySiteAndSystemType,
          `${notification.siteId}|${notification.systemType}`,
          notification.color,
          `[${notification.floor}] ${notification.systemName}`,
          notification.name
        );
      }
      state.loading = false;
    },
    markAlertNotificationsAsRead: (state, action: PayloadAction<number[]>) => {
      for (const notification of state.notifications) {
        if (action.payload.includes(notification.id)) {
          notification.isRead = true;
        }
      }
    },
    markAllUnresolvedAlertNotificationsAsRead: (state) => {
      for (const notification of state.notifications) {
        notification.isRead = true;
      }
    },
  },
});

export const {
  resetSlice: resetAlertsSlice,
  setUnresolvedNotifications,
  markAlertNotificationsAsRead,
  markAllUnresolvedAlertNotificationsAsRead,
} = alertsSlice.actions;

export const selectSystemNotifications =
  (systemId: number) =>
  (state: RootState): UnresolvedNotification[] => {
    return state.alerts.notifications.filter((i) => i.systemId === systemId);
  };

export const selectHasSystemNotifications =
  (systemId: number) =>
  (state: RootState): boolean => {
    return state.alerts.notifications.some((i) => i.systemId === systemId);
  };

export const selectNotificationsContentByFilters =
  ({
    siteId,
    siteIds,
    systemId,
    systemTypes,
  }: {
    siteId?: number;
    siteIds?: number[];
    systemTypes?: SystemType[];
    systemId?: number;
  }) =>
  (state: RootState): NotificationsWithColor => {
    console.assert(!(siteIds && siteId && systemId), 'siteIds and systemId cannot be provided at the same time');

    // build notifications for single system
    if (systemId) {
      return state.alerts.bySystem[systemId] ?? NO_ALERTS;
    }

    // build notifications for single site
    if (siteId) {
      // with several system types
      if (!_.isEmpty(systemTypes)) {
        const data =
          systemTypes?.map((systemType) => {
            const key = `${siteId}|${systemType}`;
            return state.alerts.bySiteAndSystemType[key] ?? {};
          }) ?? [];
        const result = _.merge({}, ...data);
        return _.isEmpty(result) ? NO_ALERTS : result;
      }
      // when no system types provided
      return state.alerts.bySite[siteId] ?? NO_ALERTS;
    }

    if (siteIds) {
      // with several sites
      const sitesWithAlerts = siteIds
        .map((siteId) => ({
          siteName: state.sites.data.entities[siteId]?.nameOrAddess,
          color: state.alerts.bySite[siteId]?.color,
          alertsData: state.alerts.bySite[siteId],
        }))
        .filter(({ alertsData, siteName }) => !_.isEmpty(alertsData) && siteName);

      if (_.isEmpty(sitesWithAlerts)) {
        return NO_ALERTS;
      }

      const highestColor = _.maxBy(sitesWithAlerts, (i) => _.get(COLOR_PRIORITY, i.color ?? '', undefined))?.color;

      return {
        color: highestColor,
        data: [
          {
            name: 'Sites',
            items: sitesWithAlerts.map(({ siteName }) => siteName!),
          },
        ],
      };
    }

    return NO_ALERTS;
  };

export const selectAlertColorsPerSite = (state: RootState): Record<number, AlertColor | undefined> =>
  _.mapValues(state.alerts.bySite, (notifications) => notifications.color);

export default alertsSlice.reducer;

const NO_ALERTS = { data: ['No Alerts'] };

function addItem<T extends string | number>(
  list: Record<T, NotificationsWithColor>,
  key: T,
  newColor: AlertColor,
  value: string
): void {
  const current = list[key];
  const color = getHigherColor(current?.color, newColor);
  const data = (current?.data ?? []) as ContentList;
  data.push(value);

  list[key] = { color, data };
}

function addItemToGroup<T extends string | number>(
  list: Record<T, NotificationsWithColor>,
  key: T,
  newColor: AlertColor,
  value: string,
  groupName: string
): void {
  const current = list[key];
  const color = getHigherColor(current?.color, newColor);
  const data = (current?.data ?? []) as GroupedContentList[];
  const group = data.find((item) => item.name === groupName);
  if (group) {
    group.items.push(value);
  } else {
    data.push({
      name: groupName,
      items: [value],
    });
  }

  list[key] = { color, data };
}

/**
 * Compares two colors and returns the one with higher priority.
 */
function getHigherColor(currentColor?: AlertColor, newColor?: AlertColor): AlertColor | undefined {
  if (currentColor) {
    if (!newColor) {
      return currentColor;
    }
    if (COLOR_PRIORITY[currentColor] > COLOR_PRIORITY[newColor]) {
      return currentColor;
    }
    return newColor;
  }
  return newColor;
}
