import { gql } from '@apollo/client';

import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  GetSystemsForSiteQuery,
  GetSystemsForSiteQueryVariables,
  GetSystemGroupsForSiteQuery,
  GetSystemGroupsForSiteQueryVariables,
} from './__generated__/getSystemsForSite.resources.gql';
import { SYSTEM_UNION_FIELDS } from './fragments.resources.gql';

type S = GetSystemsForSiteQuery['systems'][0];
export type PRVSystemCard = Extract<S, { __typename: 'SystemSteamPrvStation' }>;
export type HwBoilerTekmar284SystemCard = Extract<S, { __typename: 'SystemHwBoilerTekmar248' }>;
export type StBoilerFHtMpcSystemCard = Extract<S, { __typename: 'SystemStBoilerFHtMpc' }>;
export type HwBoilerHtHwrSystemCard = Extract<S, { __typename: 'SystemHwBoilerHtHwr' }>;
export type PackageAltc24ProgSystemCard = Extract<S, { __typename: 'SystemPackageAltc24Prog' }>;
export type SystemSchedulerProfile = Extract<S, { __typename: 'SystemSteamPrvStation' }>['schedulerProfile'];
export type LightingViveSystemCard = Extract<S, { __typename: 'SystemLightingVive' }>;
export type WeatherStationWts506SystemCard = Extract<S, { __typename: 'SystemWeatherStationWts506' }>;
export type SmartOutletSystemCard = Extract<S, { __typename: 'SystemSmartOutlet' }>;
export type ChargeStationSystemCard = Extract<S, { __typename: 'SystemChargePointStation' }>;
export type SystemGroups = GetSystemGroupsForSiteQuery['systemGroups'][0];

export type SupportableSystemModels = Extract<S, { __typename: 'SystemPackageAltc24Prog' }>['model'] | undefined;

export type SupportableSystems =
  | PRVSystemCard
  | HwBoilerTekmar284SystemCard
  | StBoilerFHtMpcSystemCard
  | HwBoilerHtHwrSystemCard
  | PackageAltc24ProgSystemCard
  | LightingViveSystemCard
  | WeatherStationWts506SystemCard
  | SmartOutletSystemCard
  | ChargeStationSystemCard;

const GET_SYSTEMS_FOR_SITE = gql`
  ${SYSTEM_UNION_FIELDS}
  query GetSystemsForSite($siteIds: [Int!]!, $types: [SystemType!]!) {
    systems(siteIds: $siteIds, types: $types) {
      __typename
      ...SystemUnionFields
    }
  }
`;

const GET_SYSTEM_GROUPS_FOR_SITE = gql`
  query GetSystemGroupsForSite($siteIds: [Int!], $types: [SystemType!]) {
    systemGroups(siteIds: $siteIds, types: $types) {
      id
      siteId
      name
      type
    }
  }
`;

export async function getSystemsForSite(siteId: number, types: SystemType[]): Promise<SupportableSystems[]> {
  const result = await resourcesAPI.query<GetSystemsForSiteQuery, GetSystemsForSiteQueryVariables>({
    query: GET_SYSTEMS_FOR_SITE,
    variables: {
      siteIds: [siteId],
      types,
    },
  });
  return (result.data.systems as SupportableSystems[]) ?? [];
}

export async function getSystemGroupsForSite(siteIds?: number[], types?: SystemType[]): Promise<SystemGroups[]> {
  const result = await resourcesAPI.query<GetSystemGroupsForSiteQuery, GetSystemGroupsForSiteQueryVariables>({
    query: GET_SYSTEM_GROUPS_FOR_SITE,
    variables: {
      siteIds,
      types,
    },
  });
  return result.data.systemGroups ?? [];
}
