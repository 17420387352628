import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Routes } from 'src/routes';

import { selectIsAuthenticated } from '../store/global/globalSlice';

const ProtectedRoutesWrapper: React.FC<React.PropsWithChildren> = (props) => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    const redirectTo = `${pathname}${search}`;
    return <Navigate to={Routes.Login} replace state={redirectTo} />;
  }

  return props.children ? props.children : <Outlet />;
};

export default ProtectedRoutesWrapper;
