import type * as Types from '../../../../core/apollo/__generated__/alertsGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetAlertsOverviewAcrossSitesQueryVariables = Types.Exact<{
  siteIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type GetAlertsOverviewAcrossSitesQuery = {
  alertsOverviewAcrossSites: Array<{
    lastNotificationTime?: Date | null;
    siteId: number;
    totalConfiguredAlerts?: number | null;
    unreadNotificationsCount?: number | null;
    totalSubscribedAlerts?: number | null;
    unresolvedNotificationsCount?: number | null;
  }>;
};

export type GetAlertsOverviewOnSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  organizationId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetAlertsOverviewOnSiteQuery = {
  alertsOverviewPerSite: Array<{
    id: number;
    organizationId: number;
    enabled: boolean;
    name: string;
    actions: Array<Types.AlertAction>;
    subscribed: boolean;
    expressionDescription?: string | null;
    systemsCount: number;
    updatedAt: Date;
    createdBy?: string | null;
  }>;
};

export type GetNotificationsHistoryQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  count: Types.Scalars['Int']['input'];
  filter: Types.NotificationsFilterInput;
  page: Types.Scalars['Int']['input'];
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetNotificationsHistoryQuery = {
  getPaginatedNotificationsHistory: {
    data: Array<{
      alertId: number;
      color: Types.AlertColor;
      description?: string | null;
      floor?: string | null;
      floorType: Types.FloorType;
      id: number;
      isDeleted: boolean;
      isRead: boolean;
      name: string;
      resolvedAt?: Date | null;
      siteId: number;
      systemGroupId?: number | null;
      systemId: number;
      systemName: string;
      systemType: Types.SystemType;
      triggeredAt: Date;
      updatedAt: Date;
      userId: string;
    }>;
    pagination: { total: number; perPage: number; currentPage: number };
  };
};

export type ToggleAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['Int']['input'];
  enabled: Types.Scalars['Boolean']['input'];
}>;

export type ToggleAlertMutation = { toggleAlert: boolean };

export type SubscribeToAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['Int']['input'];
  subscribe: Types.Scalars['Boolean']['input'];
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  actions: Array<Types.AlertAction> | Types.AlertAction;
}>;

export type SubscribeToAlertMutation = { subscribeToAlert: boolean };

export type BulkSubscribeToAlertsMutationVariables = Types.Exact<{
  alertIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  subscribe: Types.Scalars['Boolean']['input'];
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  actions: Array<Types.AlertAction> | Types.AlertAction;
}>;

export type BulkSubscribeToAlertsMutation = { bulkSubscribeToAlerts: boolean };

export type DeleteAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['Int']['input'];
}>;

export type DeleteAlertMutation = { deleteAlert: boolean };

export const GetAlertsOverviewAcrossSitesDocument = gql`
  query GetAlertsOverviewAcrossSites($siteIds: [Int!]!) {
    alertsOverviewAcrossSites(siteIds: $siteIds) {
      lastNotificationTime
      siteId
      totalConfiguredAlerts
      unreadNotificationsCount
      totalSubscribedAlerts
      unresolvedNotificationsCount
    }
  }
`;
export const GetAlertsOverviewOnSiteDocument = gql`
  query GetAlertsOverviewOnSite($siteId: Int!, $organizationId: Int, $userId: String) {
    alertsOverviewPerSite(organizationId: $organizationId, siteId: $siteId, userId: $userId) {
      id
      organizationId
      enabled
      name
      actions
      subscribed
      expressionDescription
      systemsCount
      updatedAt
      createdBy
    }
  }
`;
export const GetNotificationsHistoryDocument = gql`
  query GetNotificationsHistory(
    $siteId: Int!
    $count: Int!
    $filter: NotificationsFilterInput!
    $page: Int!
    $userId: String
  ) {
    getPaginatedNotificationsHistory(siteId: $siteId, count: $count, filter: $filter, page: $page, userId: $userId) {
      data {
        alertId
        color
        description
        floor
        floorType
        id
        isDeleted
        isRead
        name
        resolvedAt
        siteId
        systemGroupId
        systemId
        systemName
        systemType
        triggeredAt
        updatedAt
        userId
      }
      pagination {
        total
        perPage
        currentPage
      }
    }
  }
`;
export const ToggleAlertDocument = gql`
  mutation ToggleAlert($alertId: Int!, $enabled: Boolean!) {
    toggleAlert(alertId: $alertId, enabled: $enabled)
  }
`;
export const SubscribeToAlertDocument = gql`
  mutation SubscribeToAlert($alertId: Int!, $subscribe: Boolean!, $userId: String, $actions: [AlertAction!]!) {
    subscribeToAlert(alertId: $alertId, subscribe: $subscribe, userId: $userId, actions: $actions)
  }
`;
export const BulkSubscribeToAlertsDocument = gql`
  mutation BulkSubscribeToAlerts($alertIds: [Int!]!, $subscribe: Boolean!, $userId: String, $actions: [AlertAction!]!) {
    bulkSubscribeToAlerts(alertIds: $alertIds, subscribe: $subscribe, userId: $userId, actions: $actions)
  }
`;
export const DeleteAlertDocument = gql`
  mutation DeleteAlert($alertId: Int!) {
    deleteAlert(alertId: $alertId)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetAlertsOverviewAcrossSites(
      variables: GetAlertsOverviewAcrossSitesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetAlertsOverviewAcrossSitesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAlertsOverviewAcrossSitesQuery>(GetAlertsOverviewAcrossSitesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAlertsOverviewAcrossSites',
        'query',
        variables
      );
    },
    GetAlertsOverviewOnSite(
      variables: GetAlertsOverviewOnSiteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetAlertsOverviewOnSiteQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAlertsOverviewOnSiteQuery>(GetAlertsOverviewOnSiteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAlertsOverviewOnSite',
        'query',
        variables
      );
    },
    GetNotificationsHistory(
      variables: GetNotificationsHistoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetNotificationsHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetNotificationsHistoryQuery>(GetNotificationsHistoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetNotificationsHistory',
        'query',
        variables
      );
    },
    ToggleAlert(
      variables: ToggleAlertMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<ToggleAlertMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ToggleAlertMutation>(ToggleAlertDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ToggleAlert',
        'mutation',
        variables
      );
    },
    SubscribeToAlert(
      variables: SubscribeToAlertMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<SubscribeToAlertMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeToAlertMutation>(SubscribeToAlertDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SubscribeToAlert',
        'mutation',
        variables
      );
    },
    BulkSubscribeToAlerts(
      variables: BulkSubscribeToAlertsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<BulkSubscribeToAlertsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BulkSubscribeToAlertsMutation>(BulkSubscribeToAlertsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'BulkSubscribeToAlerts',
        'mutation',
        variables
      );
    },
    DeleteAlert(
      variables: DeleteAlertMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteAlertMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteAlertMutation>(DeleteAlertDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteAlert',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
