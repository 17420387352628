import type * as Types from '../../../../core/apollo/__generated__/usersGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type CreateTenantUserMutationVariables = Types.Exact<{
  createUserInput: Types.CreateTenantUserInput;
}>;

export type CreateTenantUserMutation = {
  createTenantUser: {
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    role: Types.UserRoles;
    deleted: boolean;
    spaceIds?: Array<number> | null;
    tenantId: number;
    leaseIds: Array<number>;
    lastLogin?: Date | null;
    loginCount?: number | null;
  };
};

export type UpdateTenantUserMutationVariables = Types.Exact<{
  updateUserInput: Types.UpdateTenantUserInput;
  userId: Types.Scalars['String']['input'];
}>;

export type UpdateTenantUserMutation = { updateTenantUser: boolean };

export type DeleteTenantUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;

export type DeleteTenantUserMutation = { deleteTenantUser: boolean };

export type GetTenantUsersQueryVariables = Types.Exact<{
  tenantId: Types.Scalars['Int']['input'];
}>;

export type GetTenantUsersQuery = {
  tenantUsers: Array<{
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    role: Types.UserRoles;
    deleted: boolean;
    spaceIds?: Array<number> | null;
    tenantId: number;
    leaseIds: Array<number>;
    lastLogin?: Date | null;
    loginCount?: number | null;
  }>;
};

export const CreateTenantUserDocument = gql`
  mutation CreateTenantUser($createUserInput: CreateTenantUserInput!) {
    createTenantUser(createUserInput: $createUserInput) {
      id
      name
      email
      phone
      role
      deleted
      spaceIds
      tenantId
      leaseIds
      lastLogin
      loginCount
    }
  }
`;
export const UpdateTenantUserDocument = gql`
  mutation UpdateTenantUser($updateUserInput: UpdateTenantUserInput!, $userId: String!) {
    updateTenantUser(updateUserInput: $updateUserInput, userId: $userId)
  }
`;
export const DeleteTenantUserDocument = gql`
  mutation DeleteTenantUser($userId: String!) {
    deleteTenantUser(userId: $userId)
  }
`;
export const GetTenantUsersDocument = gql`
  query GetTenantUsers($tenantId: Int!) {
    tenantUsers(userIds: null, tenantId: $tenantId) {
      id
      name
      email
      phone
      role
      deleted
      spaceIds
      tenantId
      leaseIds
      lastLogin
      loginCount
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateTenantUser(
      variables: CreateTenantUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateTenantUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateTenantUserMutation>(CreateTenantUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateTenantUser',
        'mutation',
        variables
      );
    },
    UpdateTenantUser(
      variables: UpdateTenantUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateTenantUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTenantUserMutation>(UpdateTenantUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateTenantUser',
        'mutation',
        variables
      );
    },
    DeleteTenantUser(
      variables: DeleteTenantUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteTenantUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTenantUserMutation>(DeleteTenantUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteTenantUser',
        'mutation',
        variables
      );
    },
    GetTenantUsers(
      variables: GetTenantUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTenantUsersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTenantUsersQuery>(GetTenantUsersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetTenantUsers',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
