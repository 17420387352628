import classNames from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/core/store/hooks';

import { FilterChip } from '../components/FilterChip';
import { openFiltersSidebar, selectDisplayValuesForCurrentPage, setFilters } from '../filtersSlice';

interface Props {
  storageKey: string;
  scope?: string;
  className?: string;
}

export const FilterChips: React.FC<Props> = ({ storageKey, scope, className }) => {
  const dispatch = useAppDispatch();
  const chips = useSelector(selectDisplayValuesForCurrentPage(storageKey, scope), _.isEqual);

  return (
    <div
      className={classNames('d-flex gap-8', className)}
      style={{ height: '32px', whiteSpace: 'nowrap', overflowX: 'auto' }}
    >
      {chips.map((item) => (
        <FilterChip
          key={item.label}
          prefix={item.label + ':'}
          label={item.values.join(', ')}
          onClick={() => {
            dispatch(openFiltersSidebar());
          }}
          trimmed
          tooltipContent={item.values.map((i) => (
            <p key={i}>{i}</p>
          ))}
        />
      ))}
      {!_.isEmpty(chips) && (
        <FilterChip
          label='Clear All'
          variant='red'
          onClick={() => {
            dispatch(setFilters({ storageKey, filters: {} }));
          }}
        />
      )}
    </div>
  );
};
