import { useAppDispatch } from 'src/core/store/hooks';
import { Button } from 'src/shared/components/Button/Button';

import { FilterSidebarPopup } from '../components/FilterSidebarPopup';
import { openFiltersSidebar } from '../filtersSlice';

interface SidebarFilterProps {
  storageKey: string;
}

export const FilterButton: React.FC<SidebarFilterProps> = ({ storageKey }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        icon='funnel'
        label='Filters'
        onClick={() => {
          dispatch(openFiltersSidebar());
        }}
      />
      <FilterSidebarPopup storageKey={storageKey} />
    </>
  );
};
