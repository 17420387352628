import { gql } from '@apollo/client';
import { utcToZonedTime } from 'date-fns-tz';
import _ from 'lodash';

import { timestampInTZ } from 'src/cdk/utils/datetimeToDate';
import { MetricDataIntervalsInput, MetricType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';
import { deDupMetrics, mapMetricToMethod } from 'src/core/utils/deDupMetrics';

import {
  SystemMetricsDataByIntervalsQuery,
  SystemMetricsDataByIntervalsQueryVariables,
} from './__generated__/getMetricDataWithIntervals.resources.gql';

export type ISystemMetricData = SystemMetricsDataByIntervalsQuery['systemMetricsDataByIntervals'][0];
export type ISystemMetricDataWithoutMetricId = Omit<ISystemMetricData, 'systemMetricId'>;

const SYSTEM_METRICS_DATA_BY_INTERVALS = gql`
  query SystemMetricsDataByIntervals($metricIds: [Int!]!, $metricDataIntervalsInput: MetricDataIntervalsInput!) {
    systemMetricsDataByIntervals(metricIds: $metricIds, metricDataIntervalInput: $metricDataIntervalsInput) {
      systemMetricId
      timestamp
      value
      min
      max
      exists
    }
  }
`;

export async function getMetricsDataForSystemWithIntervals(
  metricIds: number[],
  filter: MetricDataIntervalsInput,
  timeZone: string,
  mapMetricToType: Record<number, MetricType>
): Promise<ISystemMetricData[]> {
  if (_.isEmpty(metricIds)) {
    return [];
  }

  const data = (
    await resourcesAPI.query<SystemMetricsDataByIntervalsQuery, SystemMetricsDataByIntervalsQueryVariables>({
      query: SYSTEM_METRICS_DATA_BY_INTERVALS,
      variables: {
        metricIds,
        metricDataIntervalsInput: {
          step: filter.step,
          // TS expects Date, but it is actually string
          from: timestampInTZ(filter.from, timeZone) as unknown as Date,
          to: timestampInTZ(filter.to, timeZone) as unknown as Date,
          weekDays: filter.weekDays,
          hours: filter.hours,
        },
      },
    })
  ).data.systemMetricsDataByIntervals;

  const convertedTime = data.map((metric) => ({
    ...metric,
    timestamp: utcToZonedTime(metric.timestamp, timeZone),
  }));

  const deDupedMetrics = deDupMetrics(
    convertedTime,
    (item) => `${item.systemMetricId}_${item.timestamp.toISOString()}`,
    (item) => mapMetricToMethod(mapMetricToType[item.systemMetricId])
  );

  return deDupedMetrics.reverse();
}
