import React, { useState } from 'react';

import { toastService } from 'src/core/service/toastService';

import { Button } from '../Button/Button';
import { SkeletonLoader } from '../Loader/Loader';

interface ExportButtonProps {
  disabled?: boolean;
  onClick: () => Promise<void>;
}
export const ExportButton: React.FC<ExportButtonProps> = ({ onClick, disabled = false }) => {
  const [isLoading, setIsLoading] = useState(false);

  async function exportMetricsData() {
    try {
      setIsLoading(true);
      await onClick();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      console.warn(error);
      toastService.error('Failed to export data');
      setIsLoading(false);
    }
  }

  // TODO: move loading flag inside of button to make behaviour default, but skeleton size should be detected automatically
  return isLoading ? (
    <SkeletonLoader type='block' width='75px' height='36px' stronger />
  ) : (
    <Button disabled={disabled} variant='primary' label='Export' onClick={() => exportMetricsData()} />
  );
};
