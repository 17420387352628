import { Modal } from '../Popup';

import styles from './ConfirmationMessageModal.module.scss';

export interface ConfirmationMessageModalProps {
  title: string;
  /**
   * @default 'success'
   */
  type?: 'confirm' | 'success' | 'danger';
  subtitle?: string;
  /**
   * @default 'Ok'
   */
  actionButtonLabel?: string;
  /**
   * If not provided, the modal will not show a cancel button
   */
  cancelButtonLabel?: string;
  onAccept: () => void;
  onCancel: () => void;
  isOpen: boolean;
  children?: React.ReactNode;
}

const ConfirmationMessageModal: React.FC<ConfirmationMessageModalProps> = ({
  title,
  type = 'success',
  subtitle = '',
  actionButtonLabel = 'Ok',
  cancelButtonLabel,
  onAccept,
  onCancel,
  isOpen,
  children,
}) => {
  return (
    <Modal
      titleText={title}
      className={styles[type]}
      subtitleText={subtitle}
      acceptAction={onAccept}
      cancelAction={onCancel}
      isOpen={isOpen}
      actionButtonLabel={actionButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      displayAcceptActionsAsDanger={type === 'danger'}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationMessageModal;
