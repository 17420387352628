import { toDate } from 'date-fns';

export function roundToLocalDays(dirtyDate: Date | number, upToUpperLimit = false): Date {
  const date = toDate(dirtyDate);

  return toDate(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      upToUpperLimit ? 23 : 0,
      upToUpperLimit ? 59 : 0,
      upToUpperLimit ? 59 : 0
    )
  );
}
