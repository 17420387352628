import { IFilterFieldBase } from '../../filter.interface';

export const FilterLabel: React.FC<{
  field: IFilterFieldBase;
}> = (props) => {
  return (
    <div
      className='d-flex justify-content-space-between mb-8'
      style={{
        alignItems: 'baseline',
      }}
    >
      <label className='color-secondary'>{props.field.label}</label>
      {props.field.scope && <label className='caption color-secondary'>{props.field.scope.join(', ')}</label>}
    </div>
  );
};
