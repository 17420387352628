import { connectSdk, resourcesAPI } from 'src/core/apollo/api';

import { getSdk as getCPStationSdk } from './__generated__/charging-station.resources.generated';
import { getSdk as getInsightsSdk } from './__generated__/insights.resources.generated';
import { getSdk as getScheduleSdk } from './__generated__/schedule.resources.generated';
import { getSdk as getDetailsSdk } from './__generated__/system.resources.generated';

const base = connectSdk(resourcesAPI, getDetailsSdk);

const api = {
  ...base,
  ...connectSdk(resourcesAPI, getScheduleSdk),
  ...connectSdk(resourcesAPI, getCPStationSdk),
  ...connectSdk(resourcesAPI, getInsightsSdk),
  GetSystemsForConnectionOnSite(siteId?: number, addFloorAsPrefix?: boolean) {
    if (!siteId) {
      return Promise.resolve([]);
    } else {
      return base.GetSystemsForConnectionOnSite({ siteId }).then((i) =>
        addFloorAsPrefix
          ? i.systemsShortInfoBySiteId.map((i) => ({
              ...i,
              name: `[${i.floor}] ${i.name}`,
            }))
          : i.systemsShortInfoBySiteId
      );
    }
  },
  GetSystemOptionsForSiteAndOrg(siteId: number, organizationId: number) {
    return base.GetSystemOptionsForSite({ siteId, organizationId }).then((i) => i.systemOptionsForSite);
  },
  GetSystemOptionsForSite(siteId: number, organizationId?: number | null) {
    return base.GetSystemOptionsForSite({ siteId, organizationId }).then((i) => i.systemOptionsForSite);
  },
};

export default api;
