/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { CircularLoader } from 'src/shared/components/Loader/Loader';

const defaultLoader = <CircularLoader />;

/**
 * This HOC intended to wrap components that you want to lazy load {@link https://reactjs.org/docs/code-splitting.html}
 *
 * Usage example:
 *
 * const componentToBeLazyLoaded = withSuspense(lazy(() => import(pathToComponent)));
 *
 * @param {React.LazyExoticComponent<React.FC>} Component Component that you want to exclude from bundle and lazy load on demand
 * @param {React.ReactElement} Loader Component that will be displayed till component load
 */
function withSuspense<T>(
  Component: React.LazyExoticComponent<React.FC<T>>,
  Loader: React.ReactElement | null = defaultLoader
): React.FC<React.PropsWithChildren<T>> {
  // eslint-disable-next-line react/display-name
  return (props): React.ReactElement => (
    <React.Suspense fallback={Loader}>
      <Component {...(props as any)} />
    </React.Suspense>
  );
}

export default withSuspense;
