import { gql } from '@apollo/client';

import { mapDateToYearMonthStr } from 'src/cdk/utils/datetimeToDate';
import { InvoicesFilterInput } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  GetHistoricalAdjustmentsForSiteQuery,
  GetHistoricalAdjustmentsForSiteQueryVariables,
} from './__generated__/getHistoricalAdjustmentsForSite.resources.gql';

export type HistoricalAdjustment = GetHistoricalAdjustmentsForSiteQuery['getHistoricalAdjustmentsForSite'][0];

const GET_HISTORICAL_ADJUSTMENTS_FOR_SITE = gql`
  query GetHistoricalAdjustmentsForSite($siteId: Int!, $filter: InvoicesFilterInput!) {
    getHistoricalAdjustmentsForSite(siteId: $siteId, filter: $filter) {
      id
      name
      leaseId
      tenantName
      invoiceId
      invoiceKey
      leaseAccountNumber
      description
      invoiceStartPeriod
      invoiceEndPeriod
      invoiceBillingMonth
      chargeAmount
      updatedAt
      updatedBy
      fileUrl
    }
  }
`;

export async function getHistoricalAdjustmentsForSite(
  siteId: number,
  tenantIds: number[] | undefined,
  from: Date,
  to: Date
): Promise<HistoricalAdjustment[]> {
  const result = await resourcesAPI.query<
    GetHistoricalAdjustmentsForSiteQuery,
    GetHistoricalAdjustmentsForSiteQueryVariables
  >({
    query: GET_HISTORICAL_ADJUSTMENTS_FOR_SITE,
    variables: {
      siteId,
      filter: {
        tenantIds,
        from: mapDateToYearMonthStr(from),
        to: mapDateToYearMonthStr(to),
      } as InvoicesFilterInput,
    },
  });

  return result.data.getHistoricalAdjustmentsForSite.map((item) => ({
    ...item,
    invoiceStartPeriod: new Date(item.invoiceStartPeriod),
    invoiceEndPeriod: new Date(item.invoiceEndPeriod),
    invoiceBillingMonth: new Date(item.invoiceBillingMonth),
    updatedAt: new Date(item.updatedAt),
  }));
}
