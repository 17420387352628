import { getUrlToProtectedFile } from 'src/cdk/hooks/useUrlToProtectedFile';
import { DownloadDataType } from 'src/enums';

import { toastService } from './toastService';

/**
 * This function will download string data as a file.
 *
 * The data will be uri encoded and passed as href atrribute to **Link** element
 *
 * Data encoding will be done based on the provided data type.
 *
 * @param {string} downloadData Data represented as string.
 * @param {string} dataType Type of the downloading data
 * @param {string} filename File name with file extension
 */
export function downloadData(downloadData: string, dataType: DownloadDataType, filename: string): void {
  downloadByUrl(generateDownloadData(downloadData, dataType), filename);
}

/**
 * This function will download data by URL, by adding **Link** element to the page and clicking on it.
 *
 * The filename will be passed as dowload attribute to the **Link** element,
 * but if the download file already has a name, it wont be overwritten.
 *
 * After the click on the **Link** element, the element will be deleted
 *
 * @param downloadData Data represented as string.
 * @param filename File name with file extension
 * @param onSeparateTab If true, the file will be downloaded on a separate tab
 */
export async function downloadByUrl(url: string, filename: string, onSeparateTab?: boolean): Promise<void> {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  if (onSeparateTab) {
    link.target = '_blank';
  }
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
}

export async function downloadFromServer(url: string, filename?: string) {
  // Download file from server
  try {
    const fileInfo = await getUrlToProtectedFile(url);
    if (!fileInfo) {
      toastService.error('Failed to download file, please contact administrator');
      return;
    }
    await downloadByUrl(fileInfo.url, (filename ? filename : fileInfo.name) ?? '', true);
  } catch (error) {
    console.error(error);
  }
}

function generateDownloadData(data: string, type: DownloadDataType): string {
  switch (type) {
    case DownloadDataType.CSV:
      return 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data);
    default:
      return data;
  }
}
