import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PersistentStorageKeys } from 'src/enums';
import { IUser } from 'src/interfaces';
import type { ConfirmationMessageModalProps } from 'src/shared/components/ConfirmationMessageModal/ConfirmationMessageModal';
import { isSuperAdmin } from 'src/shared/utils/user.utils';

import { UserAppearance, UserRoles } from '../../apollo/__generated__/usersGlobalTypes';
import { persistentStorage } from '../../service/persistentStorage';
import type { RootState } from '../store';

import type { UserConstraints } from './gql/getCurrentUserConstraints.users.gql';

export type ModalConfig<T extends {} = {}> = Omit<
  ConfirmationMessageModalProps,
  'onCancel' | 'isOpen' | 'onAccept' | 'children'
> & {
  callback: (result: { result: boolean; data: T }) => void;
  renderContent?: (value: T, onChange: (value: T) => void) => React.ReactNode;
};

interface IGlobalSlice {
  navFixedAndExpanded: boolean;
  /**
   * Defines if application was loaded.
   * After first time user is set - that flag becomes truthy.
   */
  loading: boolean;
  /**
   * If user is null - that means that current session is not authorized.
   */
  user: IUser | null;
  /**
   * Used to display generic/reusable confirmation modal.
   * If empty - modal is hidden.
   */
  modal?: ModalConfig;
  constraints: UserConstraints;
  pageHistory: [string, string];
}

const getInitialState = (loading: boolean): IGlobalSlice => ({
  loading,
  user: null,
  navFixedAndExpanded: persistentStorage.getBoolean(PersistentStorageKeys.ExpandedAndFixedNavBar),
  constraints: {} as UserConstraints,
  pageHistory: ['', ''],
});

export const globalSlice = createSlice({
  name: 'global',
  initialState: getInitialState(true),
  reducers: {
    resetSlice: () => getInitialState(false),
    setUserWithConstraints: (state, action: PayloadAction<{ user: IUser | null; constraints: UserConstraints }>) => {
      state.loading = false;
      state.user = action.payload.user;
      state.constraints = action.payload.constraints;
    },
    setModal: (state, action: PayloadAction<ModalConfig | undefined>) => {
      state.modal = action.payload;
    },
    setConstraints: (state, action: PayloadAction<UserConstraints>) => {
      state.constraints = action.payload;
    },
    setNavFixedAndExpanded: (state, action: PayloadAction<boolean>) => {
      state.navFixedAndExpanded = action.payload;
      persistentStorage.setBoolean(PersistentStorageKeys.ExpandedAndFixedNavBar, action.payload);
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.pageHistory[0] = state.pageHistory[1];
      state.pageHistory[1] = action.payload;
    },
    removeSiteFromConstraints: (state, action: PayloadAction<number>) => {
      state.constraints.sites = state.constraints.sites?.filter((siteId) => siteId !== action.payload);
    },
    setUserAppearance: (state, action: PayloadAction<UserAppearance>) => {
      state.constraints.appearance = action.payload;
    },
    setUserState: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
  },
});

export const {
  resetSlice: resetGlobalSlice,
  setUserWithConstraints,
  setConstraints,
  setModal,
  setNavFixedAndExpanded,
  setCurrentPage,
  removeSiteFromConstraints,
  setUserAppearance,
  setUserState,
} = globalSlice.actions;

export const selectIsAuthenticated = (state: RootState): boolean => !!state.global.user;
export const selectModal = (state: RootState) => state.global.modal;
export const selectIsLoading = (state: RootState): boolean => state.global.loading;
export const selectAvailableSites = (state: RootState): number[] => state.global.constraints?.sites ?? [];
export const selectUserRole = (state: RootState): UserRoles => state.global.constraints.role;
export const selectIsSuperAdmin = (state: RootState): boolean => isSuperAdmin(state.global.constraints.role);
export const selectAvailableSystems = (state: RootState): number[] => state.global.constraints?.systems ?? [];
export const selectUserId = (state: RootState): string | undefined => state.global.user?.id;
export const selectUserName = (state: RootState): string | undefined => state.global.user?.name;
export const selectUserEmail = (state: RootState): string | undefined => state.global.user?.email;
export const selectNavFixedAndExpanded = (state: RootState): boolean => state.global.navFixedAndExpanded;
export const selectPreviousPage = (state: RootState): string => state.global.pageHistory[0];
export const selectUser = (state: RootState) => state.global.user;
export const selectUserConstraints = (state: RootState) => state.global.constraints;
export const selectUserOrganization = (state: RootState): number | null | undefined =>
  state.global.constraints?.organization_id;

export default globalSlice.reducer;
