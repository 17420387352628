import { format } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import _ from 'lodash';

export function datetimeToLocalDate(d: Date): Date {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export function UTCStringToLocalDate(s: string): Date {
  const parsed = new Date(s);
  return new Date(parsed.getUTCFullYear(), parsed.getUTCMonth(), parsed.getUTCDate());
}

export function localDateToUTCString(d: Date): string {
  return localDateToUTC(d).toJSON();
}

export function localDateToUTC(d: Date): Date {
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
}

export function mapDateToDateStr(d: Date): DateStr;
export function mapDateToDateStr(d: Date | null): DateStr | null;
export function mapDateToDateStr(d: Date | undefined): DateStr | undefined;
export function mapDateToDateStr(d: Date | null | undefined): DateStr | null | undefined;
export function mapDateToDateStr(d: Date | null | undefined): DateStr | null | undefined {
  if (_.isDate(d)) {
    return format(d, 'yyyy-MM-dd') as DateStr;
  }
  return d;
}

export function mapDateToYearMonthStr(d: Date): YearMonthStr;
export function mapDateToYearMonthStr(d: Date | null): YearMonthStr | null;
export function mapDateToYearMonthStr(d: Date | undefined): YearMonthStr | undefined;
export function mapDateToYearMonthStr(d: Date | null | undefined): YearMonthStr | null | undefined;
export function mapDateToYearMonthStr(d: Date | null | undefined): YearMonthStr | null | undefined {
  if (_.isDate(d)) {
    return format(d, 'yyyy-MM') as YearMonthStr;
  }
  return d;
}

/**
 * It will return ISO string as if it is in specific timezone
 *
 * For relative timestamps it will shift hours according to timezone
 *
 * For absolute timestamps it will not change hours/days values, but will convert it to specific timezone
 *
 * Timestamp is considered relative if it is not start or end of the day
 */
export function timestampInTZ(timestamp: Date, timeZone: string): string {
  const isStartOrEndOfTheDay =
    (timestamp.getHours() === 0 && timestamp.getMinutes() === 0 && timestamp.getSeconds() === 0) ||
    (timestamp.getHours() === 23 && timestamp.getMinutes() === 59 && timestamp.getSeconds() === 59);

  // If not start or end of the day, we consider it as a relative date
  if (!isStartOrEndOfTheDay) {
    return formatInTimeZone(timestamp, timeZone, "yyyy-MM-dd'T'HH:mm:ss.000XXX");
  }

  // If date is absolute, we need to adjust it according to timezone
  const timestampInTZ = zonedTimeToUtc(timestamp.toISOString(), timeZone);
  return formatInTimeZone(timestampInTZ, timeZone, "yyyy-MM-dd'T'HH:mm:ss.000XXX");
}
