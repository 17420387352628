import { RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { SiteFeatureType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import Guard from 'src/fragments/layouts/guard/Guard';
import { Routes } from 'src/routes';

const router: RouteObject[] = [
  {
    path: Routes.ScheduleProfiles,
    element: <Guard feature={SiteFeatureType.SCHEDULING} />,
    children: [
      {
        index: true,
        lazy: asLazyPage(() => import('src/modules/scheduler/pages/SchedulerSitesPage')),
      },
      {
        path: ':siteId',
        element: <Guard feature={SiteFeatureType.SCHEDULING} siteIdRouteParamName='siteId' />,
        children: [
          {
            path: '',
            lazy: asLazyPage(() => import('src/modules/scheduler/pages/SchedulerPage')),
          },
        ],
      },
    ],
  },
];

export default router;
