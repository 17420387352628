export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: DateStr; output: Date };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date };
  /** A field whose value is either an IPv4 or IPv6 address: https://en.wikipedia.org/wiki/IP_address. */
  IP: { input: string; output: string };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File };
};

export enum CPClientVersions {
  FIVE_ONE = 'FIVE_ONE',
}

export type CPIntegrationCredentialsData = {
  cpPassword: Scalars['String']['input'];
  cpRegion: CPRegions;
  cpUsername: Scalars['String']['input'];
  cpVersion: CPClientVersions;
};

export enum CPRegions {
  CENTRAL_AMERICA = 'CENTRAL_AMERICA',
  EUROPE = 'EUROPE',
  NORTH_AMERICA = 'NORTH_AMERICA',
}

export type ChargePointConnection = {
  credentialsId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  manufacturer: Scalars['String']['output'];
  model: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Int']['output'];
  portsCount: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  stationId: Scalars['String']['output'];
  stationName: Scalars['String']['output'];
  status: ChargePointStatus;
  statusChangedAt: Scalars['DateTime']['output'];
  systemId: Scalars['Int']['output'];
  type: SystemIntegrationType;
};

export type ChargePointCredentials = {
  clientVersion: CPClientVersions;
};

export type ChargePointEntity = {
  credentialsId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  ports: Array<ChargePointEntityPort>;
  stationId: Scalars['String']['output'];
  stationManufacturer: Scalars['String']['output'];
  stationModel: Scalars['String']['output'];
  type: SystemIntegrationType;
  zipCode: Scalars['String']['output'];
};

export type ChargePointEntityPort = {
  connector: Scalars['String']['output'];
  level: Scalars['String']['output'];
  portNumber: Scalars['String']['output'];
};

export enum ChargePointStatus {
  AVAILABLE = 'AVAILABLE',
  INUSE = 'INUSE',
  REACHABLE = 'REACHABLE',
  UNKNOWN = 'UNKNOWN',
  UNREACHABLE = 'UNREACHABLE',
}

export enum CommunicationChannelType {
  ALGORITHM = 'ALGORITHM',
  AWS_IOT_LORAWAN = 'AWS_IOT_LORAWAN',
  BACNET = 'BACNET',
  LORA = 'LORA',
  LORAWANLOCAL = 'LORAWANLOCAL',
  MODBUS = 'MODBUS',
  MQTT = 'MQTT',
  SENSORS = 'SENSORS',
}

export type CreateGatewayInput = {
  connectivity?: InputMaybe<GatewayConnectivityType>;
  description?: InputMaybe<Scalars['String']['input']>;
  enclosureId?: InputMaybe<Scalars['String']['input']>;
  floor: Scalars['String']['input'];
  floorType: FloorType;
  frequencyBand?: InputMaybe<FrequencyBandRFRegion>;
  gatewayId: Scalars['String']['input'];
  gatewayUUID?: InputMaybe<Scalars['UUID']['input']>;
  iccid?: InputMaybe<Scalars['String']['input']>;
  installationDate?: InputMaybe<Scalars['Date']['input']>;
  ipAddress?: InputMaybe<Scalars['IP']['input']>;
  manufacturer: GatewayManufacturer;
  model: GatewayModel;
  physicalLocation?: InputMaybe<Scalars['String']['input']>;
  routerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  siteId: Scalars['Int']['input'];
  subnetMask?: InputMaybe<Scalars['IP']['input']>;
  version: GatewayVersion;
};

export type CreateSystemIntegrationCredentialsInput = {
  cpCredentialsData?: InputMaybe<CPIntegrationCredentialsData>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  type: SystemIntegrationType;
  veoliaCredentialsData?: InputMaybe<VeoliaIntegrationCredentialsData>;
};

export type DeviceProfile = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DeviceType = {
  communicationChannel: CommunicationChannelType;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  functionName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  manufacturer: Scalars['String']['output'];
  measurementName?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sensorType?: Maybe<SensorDeviceType>;
  systemType?: Maybe<SystemType>;
  type: DeviceTypes;
  typeVersion: GatewayVersion;
};

export enum DeviceTypes {
  DEVICE = 'DEVICE',
  SENSOR = 'SENSOR',
}

export enum FloorType {
  BASEMENT = 'BASEMENT',
  CELLAR = 'CELLAR',
  LOBBY = 'LOBBY',
  MEZZANINE = 'MEZZANINE',
  NO_FLOOR = 'NO_FLOOR',
  NUMBER = 'NUMBER',
  ROOF = 'ROOF',
  SUBCELLAR = 'SUBCELLAR',
}

export enum FrequencyBandRFRegion {
  AS923_1 = 'AS923_1',
  AS923_2 = 'AS923_2',
  AS923_3 = 'AS923_3',
  AS923_4 = 'AS923_4',
  AU915 = 'AU915',
  CN470 = 'CN470',
  CN779 = 'CN779',
  EU433 = 'EU433',
  EU868 = 'EU868',
  IN865 = 'IN865',
  KR920 = 'KR920',
  RU864 = 'RU864',
  US915 = 'US915',
}

export type Gateway = {
  connectivity?: Maybe<GatewayConnectivityType>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enclosureId?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  floorType?: Maybe<FloorType>;
  frequencyBand?: Maybe<FrequencyBandRFRegion>;
  gatewayId: Scalars['String']['output'];
  gatewayUUID?: Maybe<Scalars['UUID']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  installationDate?: Maybe<Scalars['Date']['output']>;
  ipAddress?: Maybe<Scalars['IP']['output']>;
  manufacturer?: Maybe<GatewayManufacturer>;
  model?: Maybe<GatewayModel>;
  physicalLocation?: Maybe<Scalars['String']['output']>;
  routerIPAddress?: Maybe<Scalars['IP']['output']>;
  siteId: Scalars['Int']['output'];
  subnetMask?: Maybe<Scalars['IP']['output']>;
  version: GatewayVersion;
};

export enum GatewayConnectivityType {
  CELLULAR = 'CELLULAR',
  ETHERNET = 'ETHERNET',
  WIFI = 'WIFI',
}

export type GatewayFile = {
  gatewayId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isCertificate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  outdatedAt?: Maybe<Scalars['DateTime']['output']>;
  type: Scalars['String']['output'];
  uploadedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type GatewayFilterInput = {
  floors?: InputMaybe<Array<Scalars['String']['input']>>;
  gatewayIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  gatewayVersions?: InputMaybe<Array<GatewayVersion>>;
  siteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum GatewayManufacturer {
  MILESIGHT = 'MILESIGHT',
  TBL = 'TBL',
  TEKTELIC = 'TEKTELIC',
}

export enum GatewayModel {
  KONA_MICRO = 'KONA_MICRO',
  NODE_S = 'NODE_S',
  NODE_X = 'NODE_X',
  UG56 = 'UG56',
  UG65 = 'UG65',
  UG67 = 'UG67',
}

export type GatewayOption = {
  gatewayId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  version: GatewayVersion;
};

export type GatewayOverview = {
  connectivity?: Maybe<GatewayConnectivityType>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enclosureId?: Maybe<Scalars['String']['output']>;
  files: Array<GatewayFile>;
  floor?: Maybe<Scalars['String']['output']>;
  floorType?: Maybe<FloorType>;
  frequencyBand?: Maybe<FrequencyBandRFRegion>;
  gatewayId: Scalars['String']['output'];
  gatewayUUID?: Maybe<Scalars['UUID']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  installationDate?: Maybe<Scalars['Date']['output']>;
  ipAddress?: Maybe<Scalars['IP']['output']>;
  manufacturer?: Maybe<GatewayManufacturer>;
  model?: Maybe<GatewayModel>;
  physicalLocation?: Maybe<Scalars['String']['output']>;
  routerIPAddress?: Maybe<Scalars['IP']['output']>;
  sensorsCount: Scalars['Int']['output'];
  siteId: Scalars['Int']['output'];
  subnetMask?: Maybe<Scalars['IP']['output']>;
  systemsCount: Scalars['Int']['output'];
  version: GatewayVersion;
};

export enum GatewayTargetVersion {
  TBL_V2 = 'TBL_V2',
}

export enum GatewayVersion {
  AWS_IOT_LORAWAN = 'AWS_IOT_LORAWAN',
  TBL_V1 = 'TBL_V1',
  TBL_V2 = 'TBL_V2',
}

export type Mutation = {
  collectCPStationsMetrics: Scalars['Boolean']['output'];
  createGateway: Gateway;
  deleteGateway: Scalars['Boolean']['output'];
  deleteGatewayFile: Scalars['Boolean']['output'];
  deleteSystemIntegrationConnection: Scalars['Boolean']['output'];
  deleteSystemIntegrationCredentials: Scalars['Boolean']['output'];
  markGatewayFileAsRelevant: Scalars['Boolean']['output'];
  putMonitor: Scalars['Boolean']['output'];
  sendPastResetData: Scalars['Boolean']['output'];
  setupSystemIntegrationConnection: NewIntegratedConnection;
  setupSystemIntegrationCredentials: Scalars['Int']['output'];
  updateGateway: Gateway;
  updateGatewayVersion: Scalars['String']['output'];
  updateSystemIntegrationConnection: Scalars['Boolean']['output'];
  updateSystemIntegrationCredentials: Scalars['Boolean']['output'];
  uploadGatewayFile: GatewayFile;
};

export type MutationcreateGatewayArgs = {
  createGatewayInput: CreateGatewayInput;
};

export type MutationdeleteGatewayArgs = {
  gatewayId: Scalars['Int']['input'];
};

export type MutationdeleteGatewayFileArgs = {
  fileId: Scalars['Int']['input'];
  gatewayId: Scalars['Int']['input'];
};

export type MutationdeleteSystemIntegrationConnectionArgs = {
  connectionId: Scalars['Int']['input'];
  integrationType: SystemIntegrationType;
};

export type MutationdeleteSystemIntegrationCredentialsArgs = {
  credentialsId: Scalars['Int']['input'];
};

export type MutationmarkGatewayFileAsRelevantArgs = {
  fileId: Scalars['Int']['input'];
  gatewayId: Scalars['Int']['input'];
};

export type MutationputMonitorArgs = {
  command: ResetMonitorCommand;
  deviceId: Scalars['String']['input'];
};

export type MutationsendPastResetDataArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type MutationsetupSystemIntegrationConnectionArgs = {
  credentialsId: Scalars['Int']['input'];
  entityId: Scalars['String']['input'];
  siteId: Scalars['Int']['input'];
};

export type MutationsetupSystemIntegrationCredentialsArgs = {
  createCredentialsInput: CreateSystemIntegrationCredentialsInput;
};

export type MutationupdateGatewayArgs = {
  gatewayId: Scalars['Int']['input'];
  updateGatewayInput: UpdateGatewayInput;
};

export type MutationupdateGatewayVersionArgs = {
  updateGatewayVersionInput: UpdateGatewayVersion;
};

export type MutationupdateSystemIntegrationConnectionArgs = {
  connectionId: Scalars['Int']['input'];
  integrationType: SystemIntegrationType;
  updateConnectionInput: UpdateSystemIntegrationConnectionInput;
};

export type MutationupdateSystemIntegrationCredentialsArgs = {
  credentialsId: Scalars['Int']['input'];
  updateCredentialsInput: UpdateSystemIntegrationCredentialsInput;
};

export type MutationuploadGatewayFileArgs = {
  uploadGatewayFile: UpdateGatewayFileInput;
};

export type NewIntegratedConnection = {
  connectionId: Scalars['Int']['output'];
  systemId: Scalars['Int']['output'];
  type: SystemIntegrationType;
};

export type PaginatedGatewaysOverview = {
  data: Array<GatewayOverview>;
  pagination: Pagination;
};

export type Pagination = {
  currentPage: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  nextPage: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  prevPage: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  gateway: Gateway;
  gatewayFiles: Array<GatewayFile>;
  gatewayOptions: Array<GatewayOption>;
  gateways: PaginatedGatewaysOverview;
  getDeviceProfiles: Array<DeviceProfile>;
  getDeviceTypes: Array<DeviceType>;
  getGatewayList: Array<Gateway>;
  getSystemIntegrationConnections: Array<SystemIntegrationConnectionsUnion>;
  getSystemIntegrationEntitiesToConnect: Array<SystemIntegrationEntitiesUnion>;
  getSystemIntegrationsCredentials: Array<SystemIntegrationCredentials>;
  systemIntegrations: Array<SystemIntegration>;
};

export type QuerygatewayArgs = {
  gatewayId: Scalars['Int']['input'];
};

export type QuerygatewayFilesArgs = {
  gatewayId: Scalars['Int']['input'];
};

export type QuerygatewaysArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  filter: GatewayFilterInput;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetGatewayListArgs = {
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetSystemIntegrationConnectionsArgs = {
  credentialsId?: InputMaybe<Scalars['Int']['input']>;
  integrationType?: InputMaybe<SystemIntegrationType>;
};

export type QuerygetSystemIntegrationEntitiesToConnectArgs = {
  credentialsId?: InputMaybe<Scalars['Int']['input']>;
  integrationType?: InputMaybe<SystemIntegrationType>;
};

export type QuerygetSystemIntegrationsCredentialsArgs = {
  integrationType: SystemIntegrationType;
};

export enum ResetMonitorCommand {
  CREATE = 'CREATE',
  MODIFY = 'MODIFY',
}

export enum SensorDeviceType {
  DAMPER_EFFECTIVE = 'DAMPER_EFFECTIVE',
  DAMPER_EXPECTED = 'DAMPER_EXPECTED',
  PRESSURE = 'PRESSURE',
  SPACE_TEMPERATURE = 'SPACE_TEMPERATURE',
  TEMPERATURE_HUMIDITY = 'TEMPERATURE_HUMIDITY',
  WATER_FLOW = 'WATER_FLOW',
}

export type SystemIntegration = {
  connectedAt?: Maybe<Scalars['DateTime']['output']>;
  connectedEntities: Scalars['Int']['output'];
  expiryAt?: Maybe<Scalars['DateTime']['output']>;
  integrationsCount: Scalars['Int']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: SystemIntegrationType;
};

export type SystemIntegrationConnectionsUnion = ChargePointConnection;

export type SystemIntegrationCredentials = {
  connectedEntitiesCount: Scalars['Int']['output'];
  cpFields?: Maybe<ChargePointCredentials>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  credentialsId: Scalars['Int']['output'];
  invalidatedAt?: Maybe<Scalars['DateTime']['output']>;
  isValid: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['Int']['output']>;
  type: SystemIntegrationType;
};

export type SystemIntegrationEntitiesUnion = ChargePointEntity;

export enum SystemIntegrationType {
  CHARGE_POINT = 'CHARGE_POINT',
  VEOLIA_METERS = 'VEOLIA_METERS',
}

export enum SystemType {
  CHARGE_POINT = 'CHARGE_POINT',
  ELECTRICITY_METER = 'ELECTRICITY_METER',
  ELECTRICITY_SUBMETER = 'ELECTRICITY_SUBMETER',
  HW_BOILER_HT_HWR = 'HW_BOILER_HT_HWR',
  HW_BOILER_TEKMAR_284 = 'HW_BOILER_TEKMAR_284',
  LUTRON_VIVE_LIGHTING = 'LUTRON_VIVE_LIGHTING',
  NATURAL_GAS_METER = 'NATURAL_GAS_METER',
  PACKAGE_ALTC24PROG = 'PACKAGE_ALTC24PROG',
  PACKAGE_ECOBEE_DEFAULT = 'PACKAGE_ECOBEE_DEFAULT',
  PACKAGE_HONEYWELL_TC500AN = 'PACKAGE_HONEYWELL_TC500AN',
  SMART_OUTLET_T0006623 = 'SMART_OUTLET_T0006623',
  STEAM_METER = 'STEAM_METER',
  STEAM_PRV_STATION = 'STEAM_PRV_STATION',
  ST_BOILER_F_HT_MPC = 'ST_BOILER_F_HT_MPC',
  TBL_TCI = 'TBL_TCI',
  WATER_METER = 'WATER_METER',
  WEATHER_STATION_WTS506 = 'WEATHER_STATION_WTS506',
}

export type UpdateCPIntegrationCredentialsData = {
  cpPassword?: InputMaybe<Scalars['String']['input']>;
  cpUsername?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDeviceGatewayVersionInput = {
  deviceId: Scalars['String']['input'];
  networkIndex: Scalars['Int']['input'];
  subchannel: Scalars['Int']['input'];
};

export type UpdateGatewayFileInput = {
  file: Scalars['Upload']['input'];
  fileName: Scalars['String']['input'];
  gatewayId: Scalars['Int']['input'];
};

export type UpdateGatewayInput = {
  connectivity?: InputMaybe<GatewayConnectivityType>;
  description?: InputMaybe<Scalars['String']['input']>;
  enclosureId?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  floorType?: InputMaybe<FloorType>;
  iccid?: InputMaybe<Scalars['String']['input']>;
  installationDate?: InputMaybe<Scalars['Date']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<GatewayManufacturer>;
  model?: InputMaybe<GatewayModel>;
  physicalLocation?: InputMaybe<Scalars['String']['input']>;
  routerIPAddress?: InputMaybe<Scalars['String']['input']>;
  subnetMask?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGatewayVersion = {
  check?: InputMaybe<Scalars['Boolean']['input']>;
  devicesData?: InputMaybe<Array<UpdateDeviceGatewayVersionInput>>;
  gatewayId: Scalars['String']['input'];
  targetVersion: GatewayTargetVersion;
};

export type UpdateSystemIntegrationConnectionInput = {
  name: Scalars['String']['input'];
};

export type UpdateSystemIntegrationCredentialsInput = {
  cpCredentialsData?: InputMaybe<UpdateCPIntegrationCredentialsData>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: SystemIntegrationType;
  veoliaCredentialsData?: InputMaybe<UpdateVeoliaIntegrationCredentialsData>;
};

export type UpdateVeoliaIntegrationCredentialsData = {
  client_id: Scalars['String']['input'];
  client_secret: Scalars['String']['input'];
};

export type VeoliaIntegrationCredentialsData = {
  client_id: Scalars['String']['input'];
  client_secret: Scalars['String']['input'];
};
