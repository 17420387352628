import { RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { SiteFeatureType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import NotFoundPage from 'src/fragments/NotFoundPage/NotFoundPage';
import OverviewPerSiteLayout from 'src/fragments/layouts/OverviewPerSiteLayout';
import Guard from 'src/fragments/layouts/guard/Guard';
import { Routes } from 'src/routes';

const router: RouteObject[] = [
  {
    path: Routes.DemandResponse,
    element: <Guard feature={SiteFeatureType.ADR} />,
    children: [
      {
        index: true,
        lazy: asLazyPage(() => import('./page'), import('./skeleton')),
      },
      {
        path: ':siteId',
        element: <Guard feature={SiteFeatureType.ADR} siteIdRouteParamName='siteId' />,
        children: [
          {
            path: '',
            element: <OverviewPerSiteLayout title='Demand Response' tabsNames={['History', 'Systems', 'Meters']} />,
            errorElement: <ExceptionHandler />,
            children: [
              {
                path: 'history',
                lazy: asLazyPage(() => import('../../modules/adr/pages/ADRPage')),
              },
              {
                path: 'systems',
                lazy: asLazyPage(
                  () => import('./[:siteId]/connected-systems/page'),
                  import('./[:siteId]/connected-systems/skeleton')
                ),
              },
              {
                path: 'meters',
                lazy: asLazyPage(() => import('./[:siteId]/meters/page'), import('./[:siteId]/meters/skeleton')),
              },
              {
                path: '*',
                element: <NotFoundPage />,
              },
            ],
          },
          {
            path: 'history/:eventId',
            lazy: asLazyPage(() => import('../../modules/adr/pages/EventDetails/EventDetailsPage')),
          },
          {
            path: 'systems/:systemId',
            lazy: asLazyPage(() => import('./[:siteId]/[:systemId]/page'), import('./[:siteId]/[:systemId]/skeleton')),
          },
        ],
      },
    ],
  },
];

export default router;
