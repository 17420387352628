import { scaleLinear } from '@visx/scale';
import classNames from 'classnames';

import withParentSizeThrottle, { WithParentSizeProvidedProps } from 'src/cdk/HOCs/withParentSizeThrottle';
import { UNIT } from 'src/constants';
import Logger from 'src/core/service/logger';

import { WithUnit } from '../../Unit/WithUnit';

import styles from './StripedChart.module.scss';

export interface StripedChartProps extends WithParentSizeProvidedProps {
  min: number;
  max: number;
  /**
   * Triangle pointer on the chart.
   * Clamped according to [min, max]
   */
  targetValue: number;
  /**
   * Occupancy of gradient on the chart
   * Clamped according to [min, max]
   */
  currentValue: number;
  unit: UNIT;
  /**
   * disabled={false} by default
   */
  disabled?: boolean;
  gradient?: 'default' | 'cool' | 'heat' | 'electricity' | 'disabled';
  hideTriangle?: boolean;
}

const LINE_SIDE_SPACE = 6;
const STROKE_SIZE = 10;
const STEPS_DIVIDER_WIDTH_MAX = 2;
const STEPS_DIVIDER_WIDTH_MIN = 0;

//target triangle configuration
const TARGET_TRIANGLE_HEIGHT = 5;
const TARGET_TRIANGLE_WIDTH = 8;

const StripedChartSVG: React.FC<StripedChartProps> = ({
  min,
  max,
  targetValue,
  currentValue,
  unit,
  disabled = false,
  parentWidth,
  hideTriangle = false,
  gradient = 'default',
}) => {
  if (!parentWidth) {
    return null;
  }

  if (min >= max || isNaN(min) || isNaN(max)) {
    Logger.assert(isNaN(min), 'min value must be a number');
    Logger.assert(isNaN(max), 'max value must be a number');
    Logger.assert(min <= max, 'min property cannot be bigger then max');
    return null;
  }

  const SVG_WIDTH = parentWidth - 100;

  const domain = [min, max];
  const amountOfSteps = max - min;
  const currentValueScale = scaleLinear({ domain, range: [0, amountOfSteps], clamp: true });
  const targetValueScale = scaleLinear({ domain, range: [0, amountOfSteps], clamp: true });

  const STEPS_DIVIDER_WIDTH = scaleLinear({
    domain: [0, 184],
    range: [STEPS_DIVIDER_WIDTH_MAX, STEPS_DIVIDER_WIDTH_MIN],
    clamp: true,
    round: true,
  })(amountOfSteps);

  const stepsDividerWidth = (SVG_WIDTH - STEPS_DIVIDER_WIDTH * (amountOfSteps - 1)) / amountOfSteps;
  const filledPath = (((stepsDividerWidth + STEPS_DIVIDER_WIDTH) * currentValueScale(currentValue)) / SVG_WIDTH) * 100;

  const strokeDashArrayPattern = Array(amountOfSteps)
    .fill(null)
    .map((_, index) => {
      if (index === 0) {
        return `0,${stepsDividerWidth}`;
      }
      if (index === Array(amountOfSteps).length) {
        return `0,${stepsDividerWidth}`;
      }
      return `${STEPS_DIVIDER_WIDTH},${stepsDividerWidth}`;
    })
    .join(', ');

  const trianglePosition =
    LINE_SIDE_SPACE -
    STEPS_DIVIDER_WIDTH / 2 +
    (stepsDividerWidth + STEPS_DIVIDER_WIDTH) * targetValueScale(targetValue);

  const triangleLeftCorner = `${trianglePosition - TARGET_TRIANGLE_WIDTH / 2},0`;
  const triangleRightCorner = `${trianglePosition + TARGET_TRIANGLE_WIDTH / 2},0`;
  const triangleBottomCorner = `${trianglePosition},${TARGET_TRIANGLE_HEIGHT}`;

  const triangleTargetPate = `${triangleLeftCorner} ${triangleRightCorner} ${triangleBottomCorner}`;

  return (
    <div className={`striped-chart ${styles['striped-chart']}`}>
      <div className={styles['striped-chart-container']}>
        <div className={styles['striped-chart-svg']}>
          <div style={{ left: `-${LINE_SIDE_SPACE}px` }} className={styles['striped-chart-triangle']}>
            <svg
              className={classNames(styles['chart'], styles['triangle'])}
              width={SVG_WIDTH + LINE_SIDE_SPACE * 2}
              height={TARGET_TRIANGLE_HEIGHT + 2}
              style={{ opacity: hideTriangle ? 0 : disabled ? 0.28 : 1 }}
            >
              <polyline className={styles['target-triangle']} points={triangleTargetPate} />
            </svg>
          </div>
          <svg
            className={classNames(styles['chart'], styles[gradient])}
            width={SVG_WIDTH}
            height={8}
            style={{ opacity: disabled ? 0.28 : 1 }}
          >
            <line
              className={styles['background-line']}
              x1={`${filledPath}%`}
              y1='50%'
              x2='100%'
              y2='50%'
              strokeWidth={STROKE_SIZE}
            />

            <line
              x1='0'
              y1='50%'
              x2='100%'
              y2='50%'
              stroke='currentColor'
              strokeDasharray={strokeDashArrayPattern}
              strokeWidth={STROKE_SIZE}
            />
          </svg>
        </div>
      </div>
      <div className={styles['striped-chart-value']}>
        <p className='color-primary'>
          <WithUnit value={currentValue} unit={unit} />
        </p>
      </div>
    </div>
  );
};

const StripedChart = withParentSizeThrottle(StripedChartSVG);

export default StripedChart;
