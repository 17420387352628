import {
  differenceInCalendarDays,
  differenceInDays,
  differenceInMinutes,
  format,
  formatDistanceToNowStrict,
  isSameDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { DATE_FORMAT } from 'src/constants';
import { MetricsStep } from 'src/core/apollo/__generated__/resourcesGlobalTypes';

/**
 * Relative time format.
 * If datetime is bigger than 1 day - then it will render date and month only.
 */
export function rtf(datetime: Date, timezone?: string | null): string {
  const now = new Date();
  const days = Math.abs(differenceInDays(datetime, now));
  if (days < 1) {
    return formatDistanceToNowStrict(datetime, { addSuffix: true });
  }
  return atf(datetime, false, timezone);
}

/**
 * Absolute time format.
 * If not same day as today - then it will render date and month with time,
 * otherwise - display only time
 *
 * @param {Date} datetime - the date that should be converted
 * @param {boolean} alwaysAbsolute - flag, should we always render as Absolute time format.
 */
export function atf(datetime: Date, alwaysAbsolute?: boolean, timezone?: string | null): string {
  const timezoneDate = timezone ? utcToZonedTime(datetime.toISOString(), timezone) : datetime;
  const timezoneNow = timezone ? utcToZonedTime(new Date().toISOString(), timezone) : new Date();
  if (!alwaysAbsolute && isSameDay(timezoneDate, timezoneNow)) {
    return format(timezoneDate, DATE_FORMAT.LOCAL_TIME);
  }
  return format(timezoneDate, DATE_FORMAT.DATE_TIME_FULL);
}

// TODO: Test this functionality & cover with tests
export function toAtfDate(datetime: Date, timezone?: string | null): string {
  const timezoneDate = timezone ? utcToZonedTime(datetime.toISOString(), timezone) : datetime;
  const timezoneNow = timezone ? utcToZonedTime(new Date().toISOString(), timezone) : new Date();

  if (isSameDay(timezoneDate, timezoneNow)) {
    return `Today at ${format(timezoneDate, DATE_FORMAT.LOCAL_TIME)}`;
  }

  const days = Math.abs(differenceInCalendarDays(timezoneDate, timezoneNow));

  if (days === 1) {
    return `Tommorow at ${format(timezoneDate, DATE_FORMAT.LOCAL_TIME)}`;
  }

  if (days === -1) {
    return `Yesterday at ${format(timezoneDate, DATE_FORMAT.LOCAL_TIME)}`;
  }

  return `${format(timezoneDate, DATE_FORMAT.DATE_FULL)} at ${format(timezoneDate, DATE_FORMAT.LOCAL_TIME)}`;
}

export function dateDiff(d1: Date, d2: Date = new Date(), unit: 'minutes' = 'minutes'): number {
  if (unit === 'minutes') {
    return Math.abs(differenceInMinutes(d1, d2));
  }

  throw 'Unsupported unit type';
}

export function getTimestampFormat(step: MetricsStep): string {
  switch (step) {
    case MetricsStep.FIFTEEN_MINUTES:
    case MetricsStep.HOUR:
      return DATE_FORMAT.DATE_TIME_FULL;
    case MetricsStep.DAY:
      return DATE_FORMAT.DATE_FULL;
    case MetricsStep.MONTH:
      return DATE_FORMAT.MONTH_YEAR_FULL;
    case MetricsStep.YEAR:
      return DATE_FORMAT.YEAR_FULL;
  }
}

export function getExportTimestampFormat(step: MetricsStep): string {
  switch (step) {
    case MetricsStep.FIFTEEN_MINUTES:
    case MetricsStep.HOUR:
      return `${DATE_FORMAT.DATE_NUMBERS} hh:mm a`;
    case MetricsStep.DAY:
      return DATE_FORMAT.DATE_NUMBERS;
    case MetricsStep.MONTH:
      return DATE_FORMAT.MONTH_YEAR_FULL;
    case MetricsStep.YEAR:
      return DATE_FORMAT.YEAR_FULL;
  }
}

export function convertTimeToDistance(time: string): string {
  const [hours, minutes, seconds] = time.split(':').map(Number);

  if (hours === 0 && minutes === 0 && seconds === 0) {
    return '0 min';
  }
  const distance: string[] = [];
  if (hours) {
    distance.push(`${hours} hr`);
  }

  if (minutes) {
    distance.push(`${minutes} min`);
  }

  if (seconds) {
    distance.push(`${seconds} sec`);
  }

  return distance.join(' ');
}
