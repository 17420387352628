import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetChargePointInsightsKPIQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  chargePointFilter: Types.SystemChargePointStationInsightsFilterInput;
}>;

export type GetChargePointInsightsKPIQuery = {
  getChargePointInsightsKPI: {
    energy?: number | null;
    energyPercentToPreviousPeriod?: number | null;
    gasolineSavings?: number | null;
    gasolineSavingsPercentToPreviousPeriod?: number | null;
    peakDemand?: number | null;
    peakDemandTimestamp?: Date | null;
    peakDemandPercentToPreviousPeriod?: number | null;
    sessionsCount?: number | null;
    sessionsCountPercentToPreviousPeriod?: number | null;
  };
};

export type GetChargePointInsightsTableDataQueryVariables = Types.Exact<{
  siteId: Types.Scalars['Int']['input'];
  chargePointFilter: Types.SystemChargePointStationInsightsFilterInput;
}>;

export type GetChargePointInsightsTableDataQuery = {
  getChargePointInsightsTableData: Array<{
    avgBatterCharged: number;
    avgChargeTime: string;
    electricity: number;
    numberOfSessions: number;
    percentOfTotal: number;
    portNumber: string;
    stationId: number;
    utilization: number;
  }>;
};

export const GetChargePointInsightsKPIDocument = gql`
  query GetChargePointInsightsKPI($siteId: Int!, $chargePointFilter: SystemChargePointStationInsightsFilterInput!) {
    getChargePointInsightsKPI(siteId: $siteId, chargePointFilter: $chargePointFilter) {
      energy
      energyPercentToPreviousPeriod
      gasolineSavings
      gasolineSavingsPercentToPreviousPeriod
      peakDemand
      peakDemandTimestamp
      peakDemandPercentToPreviousPeriod
      sessionsCount
      sessionsCountPercentToPreviousPeriod
    }
  }
`;
export const GetChargePointInsightsTableDataDocument = gql`
  query GetChargePointInsightsTableData(
    $siteId: Int!
    $chargePointFilter: SystemChargePointStationInsightsFilterInput!
  ) {
    getChargePointInsightsTableData(siteId: $siteId, chargePointFilter: $chargePointFilter) {
      avgBatterCharged
      avgChargeTime
      electricity
      numberOfSessions
      percentOfTotal
      portNumber
      stationId
      utilization
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetChargePointInsightsKPI(
      variables: GetChargePointInsightsKPIQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetChargePointInsightsKPIQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetChargePointInsightsKPIQuery>(GetChargePointInsightsKPIDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetChargePointInsightsKPI',
        'query',
        variables
      );
    },
    GetChargePointInsightsTableData(
      variables: GetChargePointInsightsTableDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetChargePointInsightsTableDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetChargePointInsightsTableDataQuery>(GetChargePointInsightsTableDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetChargePointInsightsTableData',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
