export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    SystemUnion: [
      'SystemChargePointStation',
      'SystemElectricityMeter',
      'SystemElectricitySubmeter',
      'SystemHwBoilerHtHwr',
      'SystemHwBoilerTekmar248',
      'SystemLightingVive',
      'SystemNaturalGasMeter',
      'SystemPackageAltc24Prog',
      'SystemPackageEcobeeDefault',
      'SystemSmartOutlet',
      'SystemStBoilerFHtMpc',
      'SystemSteamMeter',
      'SystemSteamPrvStation',
      'SystemTblTci',
      'SystemWaterMeter',
      'SystemWeatherStationWts506',
    ],
  },
};
export default result;
