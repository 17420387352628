import { gql } from '@apollo/client';

import { UTCStringToLocalDate } from 'src/cdk/utils/datetimeToDate';
import { InvoicesFilterInput } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  GetSiteInvoicesForTenantsQuery,
  GetSiteInvoicesForTenantsQueryVariables,
} from './__generated__/getInvoicesForSite.resources.gql';

export type Invoice = GetSiteInvoicesForTenantsQuery['getInvoicesForSite'][0];
export type InvoiceCharges = Invoice['charges'][0];

const GET_SITE_INVOICES_FOR_TENANTS = gql`
  query GetSiteInvoicesForTenants($siteId: Int!, $filter: InvoicesFilterInput!) {
    getInvoicesForSite(siteId: $siteId, filter: $filter) {
      id
      leaseId
      tenantName
      dispatchDate
      startPeriod
      endPeriod
      billingMonth
      totalUsageCost
      totalPeakDemandCost
      totalAmount
      fileUrl
      charges {
        chargeName
        chargeValue
      }
      invoiceKey
      spaces {
        spaceName
      }
    }
  }
`;

export async function getInvoicesForSite(siteId: number, filter: InvoicesFilterInput): Promise<Invoice[]> {
  const result = await resourcesAPI.query<GetSiteInvoicesForTenantsQuery, GetSiteInvoicesForTenantsQueryVariables>({
    query: GET_SITE_INVOICES_FOR_TENANTS,
    variables: {
      siteId,
      filter: {
        from: filter.from,
        to: filter.to,
        tenantIds: filter.tenantIds,
      },
    },
  });

  return result.data.getInvoicesForSite.map((invoice) => ({
    ...invoice,
    startPeriod: UTCStringToLocalDate(invoice.startPeriod as unknown as string),
    endPeriod: UTCStringToLocalDate(invoice.endPeriod as unknown as string),
    billingMonth: new Date(invoice.billingMonth),
  }));
}
