import { useState } from 'react';

import { useAppDispatch } from 'src/core/store/hooks';
import type { AppThunk } from 'src/core/store/store';
import { LoadingStatus } from 'src/enums';

import { useOnMount } from './useOnMount';

export interface FetchStatus {
  /**
   * Error can represent as well any error: Network, GraphQL or plain JS error
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  isLoading: boolean;
  isLoaded: boolean;
  isFailed: boolean;

  /**
   * Trigger that callback when data needs to bew updated even when deps are not changed
   */
  refetch: () => Promise<void>;
}

/**
 * Use that hook to send initial request on component init and global loading/error handling.
 * Thunk should rethrow any exception to make that hook work.
 *
 * @param fetch Can accept Thunk or any Promise to get data
 * @param onSuccess Callback is optional, but it can be used to set data to local component state
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFetchOnMount<T = void>(
  fetch: AppThunk<T> | (() => Promise<T>),
  onSuccess?: (data: T) => void
): FetchStatus {
  const dispatch = useAppDispatch();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.WAITING);
  const [error, setError] = useState(null);

  function refetch(): Promise<void> {
    return (fetch instanceof Promise ? fetch : dispatch(fetch))
      .then((data) => {
        setLoadingStatus(LoadingStatus.LOADED);
        if (onSuccess && data) {
          onSuccess(data);
        }
      })
      .catch((error) => {
        setError(error);
        setLoadingStatus(LoadingStatus.FAILED);
      });
  }

  useOnMount(() => {
    refetch();
  });

  return {
    error,
    isLoading: loadingStatus === LoadingStatus.WAITING,
    isLoaded: loadingStatus === LoadingStatus.LOADED,
    isFailed: loadingStatus === LoadingStatus.FAILED,
    refetch,
  };
}
