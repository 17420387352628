import { mapDateToYearMonthStr } from 'src/cdk/utils/datetimeToDate';
import { MonthRangeInput } from 'src/core/apollo/__generated__/utilityGlobalTypes';
import { connectSdk, exportAPI, utilityAPI } from 'src/core/apollo/api';

import { getSdk as getBillsExportSdk } from './__generated__/bills.export.generated';
import { getSdk as getBillsSdk } from './__generated__/bills.utility.generated';
import { getSdk as getDataSdk } from './__generated__/data.utility.generated';
import {
  EnergyStar_GetUtilityIntegrationEntitiesToConnectQuery,
  getSdk as getIntegrationSdk,
  GetUtilityIntegrationConnectionsQuery as GetUtilityAPIMetersQuery,
  GetUtilityAuthorizationsQuery,
  GetUtilityIntegrationEntitiesToConnectQuery as GetUtilityMetersToConnectQuery,
} from './__generated__/integration.utility.generated';
import { getSdk as getOverviewSdk } from './__generated__/overview.utility.generated';

export type ConnectedConEdMeter = Extract<
  GetUtilityAPIMetersQuery['getMeterIntegrations'][0],
  { __typename: 'ConedOruMeterIntegration' }
> & { siteTimezone: string };

export type AvailableConEdMeter = GetUtilityMetersToConnectQuery['getUtilityMetersToConnect'][0] & {
  siteTimezone: string;
  connect: () => void;
};

export type ConnectedEnergyStarMeter = Extract<
  GetUtilityAPIMetersQuery['getMeterIntegrations'][0],
  { __typename: 'EnergyStarMeterIntegration' }
>;

export type AvailableEnergyStarProperty =
  EnergyStar_GetUtilityIntegrationEntitiesToConnectQuery['energyStarProperties'][0];

export type ConnectedUtilityAPIMeter = Extract<
  GetUtilityAPIMetersQuery['getMeterIntegrations'][0],
  { __typename: 'UtilityAPIMeterIntegration' }
> & { siteTimezone: string };

export type AvailableUtilityAPIMeter = GetUtilityMetersToConnectQuery['getUtilityMetersToConnect'][0] & {
  siteTimezone: string;
  connect: () => void;
};

export type UtilityAuthorizations = GetUtilityAuthorizationsQuery['getUtilityAuthorizations'][0] & {
  siteTimezone: string;
  refresh: () => void;
  edit: () => void;
};

// TODO: move to core/cdk/etc..
export function mapToYearMonthInterval(dateRange: [Date, Date]): MonthRangeInput {
  return {
    from: mapDateToYearMonthStr(dateRange[0]),
    to: mapDateToYearMonthStr(dateRange[1]),
  };
}

const api = {
  ...connectSdk(utilityAPI, getIntegrationSdk),
  ...connectSdk(utilityAPI, getOverviewSdk),
  ...connectSdk(utilityAPI, getDataSdk),
  ...connectSdk(utilityAPI, getBillsSdk),
  ...connectSdk(exportAPI, getBillsExportSdk),

  // // TODO: remove forcast mocks
  // GetMetricsForecastData: getMetricsData,
  // GetForecastedMetricData: getForecastedMetricData,

  // // TODO: replace dashboard mock with real API
  // GetUtilityDashboardKPIs(
  //   siteId: number,
  //   metricIds: number[],
  //   currentPeriod: DateInterval,
  //   previousPeriod: DateInterval
  // ): Promise<{
  //   primaryValue?: number | null;
  //   primaryValuePercentToPreviousPeriod?: number | null;
  //   cost?: number | null;
  //   costPercentToPreviousPeriod?: number | null;
  //   carbonEmission?: number | null;
  //   carbonEmissionPercentToPreviousPeriod?: number | null;
  // }> {
  //   console.warn('Generating mock data for GetUtilityDashboardKPIs', { metricIds, currentPeriod, previousPeriod });

  //   return delayAsync(400).then(() =>
  //     Promise.resolve({
  //       primaryValue: 1000,
  //       primaryValuePercentToPreviousPeriod: null,
  //       cost: 1000,
  //       costPercentToPreviousPeriod: 10,
  //       carbonEmission: 10,
  //       carbonEmissionPercentToPreviousPeriod: -3,
  //     })
  //   );
  // },
  // GetUtilityDashboardBreakdownTable(
  //   siteId: number,
  //   metricIds: number[],
  //   currentPeriod: DateInterval
  // ): Promise<
  //   {
  //     accountName: string;
  //     meterName: string;
  //     metricValue: number;
  //     meterCost: number;
  //     percentOfTotal: number;
  //   }[]
  // > {
  //   console.warn('Generating mock data for GetUtilityDashboardBreakdownTable', {
  //     siteId,
  //     metricIds,
  //     currentPeriod,
  //   });
  //   return delayAsync(2000).then(() => {
  //     return [
  //       {
  //         accountName: 'Account Name',
  //         meterName: 'Meter Name',
  //         metricValue: 1000,
  //         meterCost: 1000,
  //         percentOfTotal: 10,
  //       },
  //     ];
  //   });
  // },
  // GetUtilityPerformanceBreakdownTable(
  //   siteId: number,
  //   meterIds: number[],
  //   currentPeriod: DateInterval,
  //   previousPeriod: DateInterval
  // ): Promise<
  //   {
  //     // Rollup values from metrics + invoices related to the metrics
  //     // Example names: null, 'Cost', 'Tax', ...
  //     name?: string | null; // keep empty if it is metric value, ex: 'Energy' - because different metrics may have different names
  //     measurementType: MetricsMeasurementType; // USD / kWh / etc
  //     currentValue?: number;
  //     priorValue?: number;
  //     // delta?: number; // can be calculated on UI
  //     // percentChange?: number; // also can be calculated on UI
  //   }[]
  // > {
  //   console.warn('Generating mock data for GetUtilityPerformanceBreakdownTable', {
  //     siteId,
  //     meterIds,
  //     currentPeriod,
  //     previousPeriod,
  //   });
  //   return delayAsync(2000).then(() => {
  //     return [
  //       {
  //         name: null,
  //         measurementType: MetricsMeasurementType.KWH,
  //         currentValue: 1000,
  //         priorValue: 800,
  //       },
  //       {
  //         name: 'Cost',
  //         measurementType: MetricsMeasurementType.USD,
  //         currentValue: 1000,
  //         priorValue: 800,
  //       },
  //     ];
  //   });
  // },
};

// START OF FORECAST MOCK
// function getMetricsData(variables: GetMetricsDataQueryVariables): Promise<GetMetricsDataQuery> {
//   console.log('generating mock data for metrics', variables);
//   const monthsDiff = differenceInCalendarMonths(
//     new Date(variables.metricsFilter.to),
//     new Date(variables.metricsFilter.from)
//   );
//   console.log(variables.metricsFilter.to, variables.metricsFilter.from, monthsDiff);
//   return Promise.resolve({
//     getUtilityMetersMetricsData: _.flatMap(variables.metricIds as number[], (id) =>
//       _.range(0, monthsDiff).map((i) => ({
//         exists: true,
//         timestamp: addMonths(new Date(variables.metricsFilter.from), i),
//         value: Math.random() * 300,
//         metricId: id,
//       }))
//     ),
//   });
// }

// function getForecastedMetricData(
//   meterId: number,
//   metricType: MetricType,
//   dateTimeFilter: GetMetricsDataQueryVariables['metricsFilter']
// ): Promise<
//   Array<{
//     metricId: number;
//     exists: boolean;
//     timestamp: Date;
//     value?: number | null;
//     min?: number | null;
//     max?: number | null;
//   }>
// > {
//   const monthsDiff = differenceInCalendarMonths(new Date(dateTimeFilter.to), new Date(dateTimeFilter.from));
//   console.log('generating forecasted mock data for metrics', { meterId, metricType, dateTimeFilter });
//   return Promise.resolve(
//     _.range(0, monthsDiff).map((i) => ({
//       metricId: -1,
//       exists: i > 0,
//       timestamp: addMonths(new Date(dateTimeFilter.from), i),
//       min: Math.random() * 100,
//       value: Math.random() * 100 + 100,
//       max: Math.random() * 100 + 200,
//     }))
//   );
// }
// END OF MOCK FORECAST

export default api;
