import type * as Types from '../../../../core/apollo/__generated__/iotGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetGatewaysOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetGatewaysOptionsQuery = {
  gatewayOptions: Array<{ id: number; version: Types.GatewayVersion; siteId: number; gatewayId: string }>;
};

export type GetDeviceModelTypesOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetDeviceModelTypesOptionsQuery = {
  getDeviceTypes: Array<{
    id: number;
    name: string;
    systemType?: Types.SystemType | null;
    sensorType?: Types.SensorDeviceType | null;
    typeVersion: Types.GatewayVersion;
    type: Types.DeviceTypes;
    manufacturer: string;
    model: string;
    communicationChannel: Types.CommunicationChannelType;
    description: string;
  }>;
};

export type GetDeviceProfilesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetDeviceProfilesQuery = { getDeviceProfiles: Array<{ id: string; name: string }> };

export const GetGatewaysOptionsDocument = gql`
  query GetGatewaysOptions {
    gatewayOptions {
      id
      version
      siteId
      gatewayId
    }
  }
`;
export const GetDeviceModelTypesOptionsDocument = gql`
  query GetDeviceModelTypesOptions {
    getDeviceTypes {
      id
      name
      systemType
      sensorType
      typeVersion
      type
      manufacturer
      model
      communicationChannel
      description
    }
  }
`;
export const GetDeviceProfilesDocument = gql`
  query GetDeviceProfiles {
    getDeviceProfiles {
      id
      name
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetGatewaysOptions(
      variables?: GetGatewaysOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetGatewaysOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetGatewaysOptionsQuery>(GetGatewaysOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetGatewaysOptions',
        'query',
        variables
      );
    },
    GetDeviceModelTypesOptions(
      variables?: GetDeviceModelTypesOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetDeviceModelTypesOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDeviceModelTypesOptionsQuery>(GetDeviceModelTypesOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetDeviceModelTypesOptions',
        'query',
        variables
      );
    },
    GetDeviceProfiles(
      variables?: GetDeviceProfilesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetDeviceProfilesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDeviceProfilesQuery>(GetDeviceProfilesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetDeviceProfiles',
        'query',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
