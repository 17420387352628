import { ColorConfig } from 'src/enums';

const colors = [
  ColorConfig.blueSolidOpaque,
  ColorConfig.darkBlueSolidOpaque,
  ColorConfig.orangeSolidOpaque,
  ColorConfig.whiteSolidOpaque,
  ColorConfig.greenSolidOpaque,
  ColorConfig.redSolidOpaque,
  ColorConfig.blueSolidTranslucent,
  ColorConfig.darkBlueSolidTranslucent,
  ColorConfig.orangeSolidTranslucent,
  ColorConfig.whiteSolidTranslucent,
  ColorConfig.greenSolidTranslucent,
  ColorConfig.redSolidTranslucent,
];

/**
 * Provides available color based on current index.
 * Uses rotation, so after 12th time - colors will start to repeat themselves.
 */
export function fillColorByIndex(index: number): ColorConfig {
  return colors[index % colors.length];
}
