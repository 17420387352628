import { Navigate, RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import ExceptionHandler from 'src/fragments/ExceptionHandler/ExceptionHandler';
import { Routes } from 'src/routes';

import LogsPageComponent from './logsPage';
import UserSettingsPage from './page';

const router: RouteObject[] = [
  {
    path: Routes.UserSettings,
    errorElement: <ExceptionHandler />,
    children: [
      {
        path: '',
        element: <UserSettingsPage tabsNames={['General', 'Logs']} />,
        children: [
          {
            path: 'general',
            lazy: asLazyPage(() => import('./details/page')),
          },
          {
            path: 'logs',
            element: <LogsPageComponent />,
          },
          {
            path: '*',
            element: <Navigate to='general' />,
          },
        ],
      },
    ],
  },
];

export default router;
