import React, { lazy } from 'react';

import withSuspence from 'src/cdk/HOCs/withSuspense/withSuspence';

import { SkeletonLoader } from '../Loader/Loader';

import { MultiSelectWithSearchProps } from './MultiSelectWithSearch/MultiSelectWithSearch';
import { SelectWithSearchProps } from './SelectWithSearch/SelectWithSearch';
import { SelectWithSearchAndFormProps } from './SelectWithSearch/SelectWithSearchAndForm';
import { MultiSelectProps, SelectProps } from './interface';

export type { OptionItem } from './interface';

const skeleton = <SkeletonLoader width='160px' height='36px' />;

const skeletonFlexible = <SkeletonLoader height='36px' />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyMultiSelect<T = any> = React.FC<MultiSelectProps<T>>;
const MultiSelect: LazyMultiSelect = withSuspence(
  lazy(() => import('./MultiSelect/MultiSelect')),
  skeleton
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazySelect<T = any> = React.FC<SelectProps<T>>;
const Select: LazySelect = withSuspence(
  lazy(() => import('./Select/Select')),
  skeleton
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazySelectWithSearch<T = any> = React.FC<SelectWithSearchProps<T>>;
const SelectWithSearch: LazySelectWithSearch = withSuspence(
  lazy(() => import('./SelectWithSearch/SelectWithSearch')),
  skeleton
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyMultiSelectWithSearch<T = any> = React.FC<MultiSelectWithSearchProps<T>>;
const MultiSelectWithSearch: LazyMultiSelectWithSearch = withSuspence(
  lazy(() => import('./MultiSelectWithSearch/MultiSelectWithSearch')),
  skeletonFlexible
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazySelectWithSearchAndForm<T = any> = React.FC<SelectWithSearchAndFormProps<T>>;
const SelectWithSearchAndForm: LazySelectWithSearchAndForm = withSuspence(
  lazy(() => import('./SelectWithSearch/SelectWithSearchAndForm')),
  skeletonFlexible
);

export { Select, MultiSelect, SelectWithSearch, MultiSelectWithSearch, SelectWithSearchAndForm };
