import classnames from 'classnames';
import React from 'react';

import styles from './ButtonGroup.module.scss';

export interface ButtonGroupProps {
  className?: string;

  /**
   * If true, all buttons will be stretched to the same width
   */
  stretch?: boolean;
  children?: React.ReactNode; // TODO: force children to be Button
}
export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, className, stretch }) => {
  return (
    <div
      className={classnames(styles['btn-group'], className)}
      style={
        stretch
          ? {
              display: 'grid',
              gridTemplateColumns: `repeat(${React.Children.count(children)}, 1fr)`,
            }
          : undefined
      }
    >
      {children}
    </div>
  );
};
