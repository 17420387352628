import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { ColorIndicator } from 'src/atoms/ColorIndicator/ColorIndicator';
import { numberWithCommasFormat } from 'src/cdk/formatter/numberFormatter';
import { N_A } from 'src/constants';
import { ColorConfig } from 'src/enums';

import { RenderUnit, UNIT } from '../../Unit/RenderUnit';

import styles from './ComposableTooltipTable.module.scss';

export interface TooltipDataItem {
  renderAs: 'square' | 'none' | 'line';
  styleVariant: ColorConfig | string;
  id: string | number;
  label: string;

  /**
   * Used to pass additional data to tooltip
   */
  meta?: { [key: string]: string | number | null };

  /**
   * Used to display values on the right side of the label
   */
  values: { [key: string]: string | number | null };
}

/**
 * Keys of resulted object will be used as title for table columns.
 * Also, based on the order of keys - table columns are rendered.
 */
export function buildValuesForTooltipDataItem(
  dataItem: { min?: number | null; value?: number | null; max?: number | null },
  showMinMax: boolean,
  precision?: number
): { [key: string]: string | number } {
  const valLabel = showMinMax ? 'avg' : 'val';

  if (!showMinMax) {
    return { [valLabel]: numberWithCommasFormat(dataItem.value, precision) };
  }

  return {
    min: numberWithCommasFormat(dataItem.min, precision),
    [valLabel]: numberWithCommasFormat(dataItem.value, precision),
    max: numberWithCommasFormat(dataItem.max, precision),
  };
}

export interface TooltipColumn {
  property: string;
  title: string;
  size: string;
}

interface Props {
  /**
   * Used to inform user what is displayed in table (e.g. 'Metric', 'System')
   */
  title: string;
  /**
   * Used to inform user what type of data is displayed in table (e.g. 'Temperature', 'Power')
   */
  valuesTitle: string | JSX.Element;
  unit?: UNIT;
  precedingColumns?: TooltipColumn[];
  items: TooltipDataItem[];
  wide?: boolean;
}

function ComposableTooltipTable({ title, valuesTitle, precedingColumns = [], unit, items, wide }: Props): JSX.Element {
  if (_.isEmpty(items)) {
    return <></>;
  }

  const valueColumns = _.keys(_.first(items)?.values ?? {});

  const cssGridColumns = (
    precedingColumns.map((i) => i.size).join(' ') +
    (wide ? ' 200px ' : ' 142px ') +
    _.repeat('1fr ', valueColumns.length)
  ).trim();

  return (
    <div
      className={styles['table']}
      style={{
        gridTemplateColumns: cssGridColumns,
      }}
    >
      {/* First header */}
      {precedingColumns.map((column) => {
        return (
          <p key={column.property} className={styles['first-title']}>
            {column.title}
          </p>
        );
      })}
      <p className={styles['first-title']}>{title}</p>
      <p
        className={classNames(styles['first-title'], valueColumns.length === 1 ? 'text-right' : null)}
        style={{ gridColumn: `span ${valueColumns.length}` }}
      >
        {valuesTitle}
        {unit ? <RenderUnit unit={unit} wrapInBrackets /> : ''}
      </p>

      {/* Second header */}
      {valueColumns.length === 1 ? null : (
        <>
          {precedingColumns.map((column) => {
            return <div key={column.property} />;
          })}
          <div /> {/* Empty cell for label */}
          {valueColumns.map((column) => {
            return (
              <p key={column} className={styles['second-title']}>
                {column}
              </p>
            );
          })}
        </>
      )}

      {/* Table data */}
      {items.map((item) => (
        <React.Fragment key={item.id}>
          {/* Custom columns */}
          {precedingColumns.map((column) => {
            return (
              <p key={column.property} className='body-small one-line-ellipsis'>
                {_.get(item, column.property)}
              </p>
            );
          })}
          {/* Label */}
          <p className='body-small one-line-ellipsis'>
            <span className={styles['icon']}>
              <ColorIndicator renderAs={item.renderAs} styleVariant={item.styleVariant} />
            </span>
            {item.label}
          </p>
          {/* Values */}
          {valueColumns.map((column) => {
            const val = item.values[column];

            return (
              <p key={column} className='body-small-semi-bold text-right'>
                {_.isNumber(val) || _.isString(val) ? val : N_A}
              </p>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
}

export default ComposableTooltipTable;
