import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { useTooltip } from '@visx/tooltip';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import './ComposableChartTooltip.scss';

import { useChartContext } from './chartContext';
import { TooltipForXValue } from './interface';

interface Props<T, V> {
  data: V[];
  /**
   * Render tooltip content based on data at pointed index
   */
  buildTooltipContent: (xValue: T, values: V[]) => JSX.Element;
  /**
   * Amount of extra vertical pixels tooltip should take,
   * it will increase height of tooltip area but not shift it
   */
  verticalPadding?: number;
}

function getKey(x: Date | string): string {
  return x instanceof Date ? x.toISOString() : x;
}

function ComposableChartTooltip<
  V extends { timestamp: Date | string; exists?: boolean },
  T extends V['timestamp'] = V['timestamp'],
>({ buildTooltipContent, data, verticalPadding = 0 }: Props<T, V>): JSX.Element {
  const { TooltipPortal, xMax, yMax, xScale, padding } = useChartContext();

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip<TooltipForXValue<T, V>>();

  const groupedData = useMemo(() => {
    const result: Record<string, V[]> = {};

    data.forEach((item) => {
      const key = getKey(item.timestamp);
      if (!result[key]) {
        result[key] = [];
      }
      if (_.isUndefined(item.exists)) {
        result[key].push(item);
      } else if (item.exists) {
        result[key].push(item);
      }
    });

    return result;
  }, [data]);

  const handleMouseOver = useCallback(
    (event: React.MouseEvent<SVGRectElement, MouseEvent>, stepIndex: number, xValue: T) => {
      const coords = localPoint(event);
      if (!coords || coords.x < 0) {
        return;
      }

      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: {
          xValue,
          stepIndex,
          data: groupedData[getKey(xValue)] || [],
        },
      });
    },
    [showTooltip, groupedData]
  );

  if (!xScale.length) {
    return <></>;
  }

  return (
    <Group name='tooltip-handler' left={padding.left} top={padding.top}>
      {/* Render highlighted area */}
      {tooltipData?.xValue !== undefined && (
        <rect
          x={0}
          style={{
            transform: `translateX(${xScale(tooltipData.xValue) ?? 0}px)`,
          }}
          width={xScale.step()}
          height={yMax + verticalPadding}
          className='composable-tooltip-step-block'
        />
      )}

      {/* Area responsible for tooltip handling */}
      <rect
        x={0}
        width={xMax}
        height={yMax + verticalPadding}
        fill='transparent'
        onMouseMove={(event) => {
          const xInRect = event.clientX - (event.target as SVGElement).getBoundingClientRect().left;
          if (xInRect < 0) {
            hideTooltip();
            return;
          }
          const domain = xScale.domain();
          const stepIndex = Math.floor((xInRect / xMax) * domain.length);
          const xValue = domain[stepIndex] as T;
          // Prevent rerendering tooltip if it's already opened for the same xValue
          if (tooltipOpen && tooltipData && tooltipData.xValue === xValue) {
            return;
          }
          handleMouseOver(event, stepIndex, xValue);
        }}
        onMouseOut={() => {
          hideTooltip();
        }}
      />

      {tooltipOpen && tooltipData && tooltipData.xValue && TooltipPortal && (
        <TooltipPortal
          key={tooltipData.xValue.toString()}
          top={tooltipTop}
          unstyled
          applyPositionStyle
          className='composable-tooltip-wrapper'
          left={tooltipLeft}
        >
          <div className='composable-tooltip'>{buildTooltipContent(tooltipData.xValue, tooltipData.data)}</div>
        </TooltipPortal>
      )}
    </Group>
  );
}

export default ComposableChartTooltip;
