import classnames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { PACKAGED_SYSTEM_SETPOINT_MAX, PACKAGED_SYSTEM_SETPOINT_MIN, UNIT } from 'src/constants';
import { LearningAlgorithmType, LockType, OccupancyType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { Button } from 'src/shared/components/Button/Button';
import { ButtonGroup } from 'src/shared/components/ButtonGroup/ButtonGroup';
import { Icon } from 'src/shared/components/Icon/Icon';
import { NumericControls } from 'src/shared/components/NumericControls/NumericControls';
import { Modal } from 'src/shared/components/Popup';
import { RangeSliderWithInputs } from 'src/shared/components/RangeSlider/RangeSliderWithInputs';
import { UniversalSetpoint } from 'src/shared/containers/UniversalCard/UniversalCard';
import { getUpdatedObjectProperties } from 'src/shared/utils/common.utils';

import { PackageAltc24ProgSystemCard } from '../../../gql/getSystemsForSite.resources.gql';
import { IPackagedSystemSettings } from '../../../interface';

import styles from './PackagedSystemSettingsModal.module.scss';

export interface PackagedSystemSettingsModalProps {
  title: string;
  acceptAction: (newConfig: IPackagedSystemSettings) => void;
  cancelAction: () => void;
  isOpen: boolean;
  disabledSettings?: boolean;
  system: PackageAltc24ProgSystemCard;
  settings: IPackagedSystemSettings;
}

const LearningAlgorithmInfo: Record<LearningAlgorithmType, { label: string; description: string }> = {
  [LearningAlgorithmType.BALANCE]: {
    label: 'Balance',
    description: 'Leverage unoccupied setpoints when occupancy status is unoccupied',
  },
  [LearningAlgorithmType.COMFORT]: {
    label: 'Comfort',
    description: 'Maintain occupied setpoints irrespective of occupancy status',
  },
  [LearningAlgorithmType.ENERGY]: {
    label: 'Energy',
    description: 'Shut down system when space is unoccupied',
  },
};

const PackagedSystemSettingsModal: React.FC<PackagedSystemSettingsModalProps> = (props) => {
  const [settings, setSettings] = useState<IPackagedSystemSettings>(props.settings);

  useEffect(() => {
    setSettings(props.settings);
  }, [props.settings]);

  return (
    <Modal
      isOpen={props.isOpen}
      acceptAction={() => props.acceptAction(getUpdatedObjectProperties(props.settings, settings))}
      disableAccept={isEmpty(settings) || isEqual(settings, props.settings) || props.disabledSettings}
      cancelAction={props.cancelAction}
      titleText={props.title}
      subtitleText='Default settings of system'
      actionButtonLabel='Save'
      cancelButtonLabel='Cancel'
    >
      <div className={styles['settings-modal-content']}>
        {props.system.occupancyType !== OccupancyType.MANUAL && (
          <div className={classnames('card', 'flat', styles['mode-info-container'])}>
            <Icon icon='warning-alt' size='s' color='secondary' className={styles['icon']} />
            <div>
              <span className={classnames('ml-16', 'color-secondary', 'body-semi-bold')}>
                {settings.focusAlgorithm && LearningAlgorithmInfo[settings.focusAlgorithm].label}
              </span>
              <p className={classnames('ml-16', 'color-secondary', 'body-small')}>
                {settings.focusAlgorithm && LearningAlgorithmInfo[settings.focusAlgorithm].description}
              </p>
            </div>
          </div>
        )}

        <div className={classnames(styles['row'], 'mt-12')}>
          {props.system.occupancyType !== OccupancyType.MANUAL && (
            <div>
              <p className='body-small color-secondary'>Focus of Learning Algorithm</p>
              <ButtonGroup>
                <Button
                  className='body-small'
                  label={LearningAlgorithmInfo[LearningAlgorithmType.ENERGY].label}
                  size='small'
                  isPressed={settings.focusAlgorithm === LearningAlgorithmType.ENERGY}
                  disabled={props.disabledSettings}
                  onClick={() => setSettings({ ...settings, focusAlgorithm: LearningAlgorithmType.ENERGY })}
                />
                <Button
                  className='body-small'
                  label={LearningAlgorithmInfo[LearningAlgorithmType.BALANCE].label}
                  size='small'
                  isPressed={settings.focusAlgorithm === LearningAlgorithmType.BALANCE}
                  disabled={props.disabledSettings}
                  onClick={() =>
                    setSettings({
                      ...settings,
                      focusAlgorithm: LearningAlgorithmType.BALANCE,
                    })
                  }
                />
                <Button
                  className='body-small'
                  label={LearningAlgorithmInfo[LearningAlgorithmType.COMFORT].label}
                  size='small'
                  isPressed={settings.focusAlgorithm === LearningAlgorithmType.COMFORT}
                  disabled={props.disabledSettings}
                  onClick={() =>
                    setSettings({
                      ...settings,
                      focusAlgorithm: LearningAlgorithmType.COMFORT,
                    })
                  }
                />
              </ButtonGroup>
            </div>
          )}
          <div className={`${props.system.occupancyType === OccupancyType.MANUAL ? 'w-100' : ''}`}>
            <p className='body-small color-secondary'>Local Control</p>
            <ButtonGroup>
              <Button
                className={classnames(
                  {
                    [styles['button-width-manual']]: props.system.occupancyType === OccupancyType.MANUAL,
                  },
                  'body-small'
                )}
                size='small'
                label='Disabled'
                disabled={props.disabledSettings}
                isPressed={settings.setLock === LockType.LOCKED}
                onClick={() => setSettings({ ...settings, setLock: LockType.LOCKED })}
              />
              <Button
                className={classnames(
                  {
                    [styles['button-width-manual']]: props.system.occupancyType === OccupancyType.MANUAL,
                  },
                  'body-small'
                )}
                size='small'
                label='Enabled'
                disabled={props.disabledSettings}
                isPressed={settings.setLock === LockType.UNLOCKED}
                onClick={() => setSettings({ ...settings, setLock: LockType.UNLOCKED })}
              />
            </ButtonGroup>
          </div>
        </div>

        {settings.setpointMin && settings.setpointMax && (
          <div className='mt-40'>
            <RangeSliderWithInputs
              label={`Setpoint Limits (${UNIT.FAHRENHEIT})`}
              value={[settings.setpointMin, settings.setpointMax]}
              onChange={([setpointMin, setpointMax]) =>
                setSettings((prevValue) => ({ ...prevValue, setpointMin, setpointMax }))
              }
              min={PACKAGED_SYSTEM_SETPOINT_MIN}
              max={PACKAGED_SYSTEM_SETPOINT_MAX}
              disabled={props.disabledSettings}
            />
          </div>
        )}

        {settings.occupiedSetpointHeat && settings.occupiedSetpointCool && (
          <>
            <p className='mb-8 color-secondary'>{`Occupied Setpoints (${UNIT.FAHRENHEIT})`}</p>
            <div className={classnames(styles['row'], 'mb-32')}>
              <div className={styles['universal-setpoint']}>
                <UniversalSetpoint verticalLabel='Heat'>
                  <NumericControls
                    min={settings.setpointMin!}
                    max={settings.setpointMax!}
                    onChange={(occupiedSetpointHeat) =>
                      setSettings((prevValue) => ({ ...prevValue, occupiedSetpointHeat }))
                    }
                    onSubmit={(occupiedSetpointHeat) =>
                      setSettings((prevValue) => ({ ...prevValue, occupiedSetpointHeat }))
                    }
                    value={settings.occupiedSetpointHeat}
                    className={styles['numeric-control']}
                    disabled={props.disabledSettings}
                  />
                </UniversalSetpoint>
              </div>
              <div className={styles['universal-setpoint']}>
                <UniversalSetpoint verticalLabel='Cool'>
                  <NumericControls
                    min={settings.setpointMin!}
                    max={settings.setpointMax!}
                    onChange={(occupiedSetpointCool) =>
                      setSettings((prevValue) => ({ ...prevValue, occupiedSetpointCool }))
                    }
                    onSubmit={(occupiedSetpointCool) =>
                      setSettings((prevValue) => ({ ...prevValue, occupiedSetpointCool }))
                    }
                    value={settings.occupiedSetpointCool}
                    className={styles['numeric-control']}
                    disabled={props.disabledSettings}
                  />
                </UniversalSetpoint>
              </div>
            </div>
          </>
        )}

        {settings.unoccupiedSetpointHeat && settings.unoccupiedSetpointCool && (
          <>
            <p className='mb-8 color-secondary'>{`Unoccupied Setpoints (${UNIT.FAHRENHEIT})`}</p>
            <div className={classnames(styles['row'])}>
              <div className={styles['universal-setpoint']}>
                <UniversalSetpoint verticalLabel='Heat'>
                  <NumericControls
                    min={settings.setpointMin!}
                    max={settings.setpointMax!}
                    onChange={(unoccupiedSetpointHeat) =>
                      setSettings((prevValue) => ({ ...prevValue, unoccupiedSetpointHeat }))
                    }
                    onSubmit={(unoccupiedSetpointHeat) =>
                      setSettings((prevValue) => ({ ...prevValue, unoccupiedSetpointHeat }))
                    }
                    value={settings.unoccupiedSetpointHeat}
                    className={styles['numeric-control']}
                    disabled={
                      (settings.focusAlgorithm !== LearningAlgorithmType.BALANCE &&
                        props.system.occupancyType !== OccupancyType.MANUAL) ||
                      props.disabledSettings
                    }
                  />
                </UniversalSetpoint>
              </div>
              <div className={styles['universal-setpoint']}>
                <UniversalSetpoint verticalLabel='Cool'>
                  <NumericControls
                    min={settings.setpointMin!}
                    max={settings.setpointMax!}
                    onChange={(unoccupiedSetpointCool) =>
                      setSettings((prevValue) => ({ ...prevValue, unoccupiedSetpointCool }))
                    }
                    onSubmit={(unoccupiedSetpointCool) =>
                      setSettings((prevValue) => ({ ...prevValue, unoccupiedSetpointCool }))
                    }
                    value={settings.unoccupiedSetpointCool}
                    className={styles['numeric-control']}
                    disabled={
                      (settings.focusAlgorithm !== LearningAlgorithmType.BALANCE &&
                        props.system.occupancyType !== OccupancyType.MANUAL) ||
                      props.disabledSettings
                    }
                  />
                </UniversalSetpoint>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PackagedSystemSettingsModal;
