import { N_A } from 'src/constants';

import { IFilterField } from '../filter.interface';

import { FilterFieldMultiSelect, isMultiSelectField } from './fields/FilterFieldMultiSelect';
import { FilterFieldSearch, isSearchField } from './fields/FilterFieldSearch';
import { FilterFieldSelect, isSelectField } from './fields/FilterFieldSelect';

export const FieldFactory: React.FC<{
  field: IFilterField;
  value: unknown | undefined;
  setValueByName: (name: string, value: unknown) => void;
}> = ({ field, value, setValueByName }) => {
  if (isSelectField(field)) {
    return (
      <FilterFieldSelect
        key={field.name}
        field={field}
        value={value}
        onChange={(value) => setValueByName(field.name, value)}
      />
    );
  }

  if (isMultiSelectField(field)) {
    return (
      <FilterFieldMultiSelect
        key={field.name}
        field={field}
        value={value}
        onChange={(value) => setValueByName(field.name, value)}
      />
    );
  }

  if (isSearchField(field)) {
    return (
      <FilterFieldSearch
        key={field.name}
        field={field}
        value={value}
        onChange={(value) => setValueByName(field.name, value)}
      />
    );
  }

  console.error('Cannot render field', field);
  return <>{N_A}</>;
};
