import classNames from 'classnames';

import styles from './Tabs.module.scss';

export interface TabConfig {
  id: number;
  name: string;
  onClick: () => void;
}

export interface TabsProps {
  config: TabConfig[];
  selected: number;
  disabled?: boolean;
}

function Tabs(props: TabsProps): React.ReactElement<TabsProps> {
  const { config, selected } = props;
  return (
    <>
      <div className='d-flex justify-content-space-between align-items-center mt-16 mb-12'>
        <div className={classNames(styles['tabs-container'])}>
          {config.map((item) => (
            <button
              type='button'
              key={item.name}
              onClick={props.disabled || selected === item.id ? undefined : item.onClick}
              className={classNames(styles['button'], {
                [styles['selected']]: selected === item.id,
                'body-semi-bold color-primary': selected === item.id,
                'default-text color-secondary': selected !== item.id,
                'with-pointer': !props.disabled,
                'opacity-50 no-pointer': props.disabled,
              })}
            >
              {item.name}
            </button>
          ))}
        </div>
        <div />
      </div>
      <div className='d-flex justify-content-space-between align-items-center mb-24'>
        <div className='d-flex align-items-center gap-8' id='tabs-left-portal'>
          {/* Here can be some content */}
        </div>
        <div className='d-flex align-items-center gap-8' id='tabs-right-portal'>
          {/* Here can be some actions */}
        </div>
      </div>
    </>
  );
}

export default Tabs;
