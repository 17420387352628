import _, { isEmpty, map, partition } from 'lodash';

import { UserNotification } from 'src/core/apollo/__generated__/alertsGlobalTypes';
import Logger from 'src/core/service/logger';
import { AppThunk } from 'src/core/store/store';
import syncNotificationsService from 'src/logic/alerts/syncNotificationsService';

import { markAlertNotificationsAsRead, setUnresolvedNotifications } from './alertsSlice';
import api from './gql';

export const fetchMyUnresolvedNotifications = (): AppThunk => async (dispatch) => {
  try {
    const { unresolvedNotifications } = await api.GetMyUnresolvedNotifications();

    dispatch(setUnresolvedNotifications(unresolvedNotifications));

    syncNotificationsService.sync(unresolvedNotifications);
  } catch (e) {
    Logger.error('Failed to load notifications');
  }
};

export const updateNotificationsFromSubscription =
  (notifications: UserNotification[]): AppThunk =>
  async (dispatch, getState) => {
    if (isEmpty(notifications)) {
      return;
    }
    try {
      const [resolvedNotifications, unresolvedNotifications] = partition(notifications, 'resolvedAt');
      const resolvedNotificationsIds = map(resolvedNotifications, 'id');
      const stateNotifications = getState().alerts.notifications.filter(
        ({ id }) => !resolvedNotificationsIds.includes(id)
      );
      const combinedNotifications = [...stateNotifications, ...unresolvedNotifications];

      dispatch(setUnresolvedNotifications(combinedNotifications));

      syncNotificationsService.sync(combinedNotifications);
    } catch (e) {
      Logger.error('Failed to update notifications from subscription');
    }
  };

export const readAllAlertNotificationsForSystems =
  (systemIds: number[]): AppThunk =>
  async (dispatch, getState) => {
    if (systemIds.length === 0) {
      return;
    }
    try {
      const unreadAndUnresolvedIds = getState()
        .alerts.notifications.filter((i) => systemIds.includes(i.systemId) && !i.isRead)
        .map((i) => i.id);

      if (_.isEmpty(unreadAndUnresolvedIds)) {
        console.debug('No notifications to mark as read');
        return;
      }

      await api.ReadNotifications({ notificationIds: unreadAndUnresolvedIds });

      dispatch(markAlertNotificationsAsRead(unreadAndUnresolvedIds));
    } catch (e) {
      console.error(e);
      Logger.error('Failed to mark notification as read');
    }
  };
