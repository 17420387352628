import classNames from 'classnames';
import _ from 'lodash';

import SelectWithSearch from 'src/shared/components/Select/SelectWithSearch/SelectWithSearch';

import { IFilterField, IFilterFieldSelect } from '../../filter.interface';
import styles from '../Filter.module.scss';
import { FilterChip } from '../FilterChip';

import { FilterLabel } from './FieldLabel';

export const isSelectField = (field: IFilterField): field is IFilterFieldSelect => field.type === 'select';

export const FilterFieldSelect: React.FC<{
  field: IFilterFieldSelect;
  value: unknown | undefined;
  onChange: (value: unknown | undefined) => void;
}> = (props) => {
  const selectedItem = props.field.options?.find((option) => option.key === props.value);
  const optionsToDisplay = props.field.options?.filter((option) => option.key !== props.value) ?? [];

  if (_.isEmpty(props.field.options)) {
    return null;
  }

  return (
    <div>
      <FilterLabel field={props.field} />

      <SelectWithSearch
        className={classNames(styles['select'])}
        disabled={false}
        onClick={(value) => props.onChange(value)}
        options={optionsToDisplay}
        value={undefined}
      />
      <div className='mt-8'>
        {selectedItem && (
          <FilterChip
            label={selectedItem.displayValue}
            onDelete={() => {
              props.onChange(undefined);
            }}
          />
        )}
      </div>
    </div>
  );
};
