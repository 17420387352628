import { useParams } from 'react-router';

/**
 * @throws Error if param is not a number
 */
export function useNumberParam(paramName: string, shouldThrow = true): number {
  const params = useParams();
  const param = params[paramName];
  const number = Number(param);

  if (shouldThrow && isNaN(number)) {
    throw new Error(`Failed to identify ${paramName} from url`);
  }

  return number;
}
