import { AxisBottom } from '@visx/axis';
import { timeDay as day, timeHour as hour, timeMonth as month, timeWeek as week, timeYear as year } from 'd3-time';
import { timeFormat as format } from 'd3-time-format';
import { differenceInHours, startOfDay } from 'date-fns';
import _, { isDate } from 'lodash';
import React, { useMemo } from 'react';

import styles from './ComposableChartXYAxis.module.scss';
import { useChartContext } from './chartContext';

// Tick format implementation is copied from D3 library and adjusted to our needs
// That formatting logic is used for TimeScale, but we need to apply it for BandScale
// const formatMillisecond = format('.%L');
// const formatSecond = format(':%S');
// const formatMinute = format('%I:%M');
const formatHour = format('%I %p');
const formatDay = format('%d');
const formatWeek = format('%d'); // add %a to show day of the week
const formatMonth = format('%b');
const formatYear = format('%Y');

function tickFormat(date: Date): string {
  return (
    hour(date) < date
      ? () => '' // skip all X axis ticks if they are not aligned with hours
      : day(date) < date
        ? formatHour
        : month(date) < date
          ? week(date) < date
            ? formatDay
            : formatWeek
          : year(date) < date
            ? formatMonth
            : formatYear
  )(date);
}

type Formatter = (v: number | string | Date) => string;

const defaultFormatter: Formatter = (v) => v.toString();

const dateFormatter = tickFormat as Formatter;

/**
 * For ranges more than 2 days it is important to pass StartOfDay timestamp to formatter
 * otherwise - specific hours will be displayed when hour filter/intervals are applied
 */
const startOfTheDayFormatter = ((v: Date) => {
  return tickFormat(startOfDay(v));
}) as Formatter;

interface Props {
  /**
   * Top offset of the axis
   */
  top?: number;
}

const ComposableChartXAxis: React.FC<Props> = ({ top = 0 }) => {
  const { yMax, xScale, padding } = useChartContext();

  const [columnTickValues, format] = useMemo(() => {
    const xAxisDomain = xScale.domain();
    const min = _.first(xAxisDomain);
    const max = _.last(xAxisDomain);

    // Return all values which has 0 minutes
    if (!min || !max) {
      return [[], defaultFormatter];
    }

    if (isDate(min) && isDate(max)) {
      return buildTickValuesAndFormatterForDateDomain(xAxisDomain as Date[]);
    }

    // If domain is not based on dates - return all values
    return [xAxisDomain, defaultFormatter];
  }, [xScale]);

  return (
    <AxisBottom
      left={padding.left}
      top={yMax + padding.top + top}
      scale={xScale}
      axisLineClassName={styles['axis-line-bottom']}
      hideTicks
      tickClassName={styles['x-axis']}
      tickValues={columnTickValues}
      tickFormat={format}
    />
  );
};

function buildTickValuesAndFormatterForDateDomain(domain: Date[]): [Date[], Formatter] {
  const min = _.first(domain)!;
  const max = _.last(domain)!;
  console.assert(max && min, 'Domain should not be empty');

  const hours = differenceInHours(max, min, { roundingMethod: 'ceil' });
  const days = hours / 24;

  if (hours < 48) {
    // Return only odd hours
    return [domain.filter((d) => d.getHours() % 2 === 0), dateFormatter];
  }

  const comparator =
    days > 370
      ? // To return only consecutive years
        (d: Date) => d.getFullYear()
      : days > 33
        ? // To return only consecutive months
          (d: Date) => d.getMonth()
        : // To return only consecutive days
          (d: Date) => d.getDate();

  return [
    domain.filter((d, i) => (i > 0 ? comparator(d) !== comparator(domain[i - 1]) : true)),
    startOfTheDayFormatter,
  ];
}

export default ComposableChartXAxis;
