/**
 *
 *
 *
 *
 * NOTE:
 *
 * That file is a copy of VisX withParentSizeModern HOC
 * @see https://github.com/airbnb/visx/blob/master/packages/visx-responsive/src/enhancers/withParentSizeModern.tsx
 * @note Latest commit 00ae2b2 on Jan 24
 *
 * Current solution uses Throttle instead of Debounce to provide smoother resising during animations
 *
 *
 *
 *
 */
import { throttle } from 'lodash';
import React from 'react';

const CONTAINER_STYLES = { width: '100%', height: '100%' };

// @TODO remove when upgraded to TS 4 which has its own declaration
interface ResizeObserverEntry {
  contentRect: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
}
declare type ResizeObserverCallback = (entries: ResizeObserverEntry[]) => void;
interface ResizeObserver {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (callback: ResizeObserverCallback): ResizeObserver;
  observe(el: Element): void;
  disconnect(): void;
}

interface PrivateWindow {
  ResizeObserver: ResizeObserver;
}

export type WithParentSizeProps = {
  throttleTime?: number;
  enableThrottleLeadingCall?: boolean;
};

type WithParentSizeState = {
  parentWidth?: number;
  parentHeight?: number;
  initialWidth?: number;
  initialHeight?: number;
};

export type WithParentSizeProvidedProps = WithParentSizeState;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function withParentSizeThrottle<BaseComponentProps>(
  BaseComponent: React.ComponentType<BaseComponentProps & WithParentSizeProvidedProps>
) {
  return class WrappedComponent extends React.Component<
    BaseComponentProps & WithParentSizeProps & WithParentSizeProvidedProps,
    WithParentSizeState
  > {
    static defaultProps = {
      throttleTime: 50,
      enableThrottleLeadingCall: true,
    };
    state = {
      parentWidth: undefined,
      parentHeight: undefined,
    };
    animationFrameID = 0;
    resizeObserver: ResizeObserver | undefined;
    container: HTMLDivElement | null = null;

    componentDidMount() {
      if (!window.ResizeObserver) {
        console.warn('Resize observer not supported');
        return;
      }
      this.resizeObserver = new (window as unknown as PrivateWindow).ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const { width, height } = entry.contentRect;
          this.animationFrameID = window.requestAnimationFrame(() => {
            this.resize({
              width,
              height,
            });
          });
        });
      });
      if (this.container) {
        this.resizeObserver.observe(this.container);
      }
    }

    componentWillUnmount() {
      window.cancelAnimationFrame(this.animationFrameID);
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      this.resize.cancel();
    }

    setRef = (ref: HTMLDivElement) => {
      this.container = ref;
    };

    resize = throttle(
      ({ width, height }: { width: number; height: number }) => {
        this.setState({
          parentWidth: width,
          parentHeight: height,
        });
      },
      this.props.throttleTime,
      { leading: this.props.enableThrottleLeadingCall }
    );

    render() {
      const { initialWidth, initialHeight } = this.props;
      const { parentWidth = initialWidth, parentHeight = initialHeight } = this.state;
      return (
        <div style={CONTAINER_STYLES} ref={this.setRef}>
          {parentWidth !== null && parentHeight !== null && (
            <BaseComponent parentWidth={parentWidth} parentHeight={parentHeight} {...this.props} />
          )}
        </div>
      );
    }
  };
}
