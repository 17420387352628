import type * as Types from '../../../../core/apollo/__generated__/resourcesGlobalTypes';

import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from '@apollo/client';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export type GetChargingStationsOverviewAcrossSitesQueryVariables = Types.Exact<{
  siteIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type GetChargingStationsOverviewAcrossSitesQuery = {
  getCPStationSitesOverview: Array<{
    siteId: number;
    stationsCount: number;
    portsCount: number;
    lastMonthUptime?: number | null;
    lastMonthUptimePercentChange?: number | null;
    lastMonthUtilization?: number | null;
    lastMonthUtilizationPercentChange?: number | null;
  }>;
};

export type GetChargingStationsMetricsQueryVariables = Types.Exact<{
  levels?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>
  >;
  portNumbers?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  systemIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type GetChargingStationsMetricsQuery = {
  getChargePointStationsMetrics: Array<{
    portId?: number | null;
    id: number;
    measurement: Types.MetricsMeasurementType;
    name: string;
    relationType: Types.MetricsRelationType;
    systemId: number;
    type: Types.MetricType;
    view: Types.MetricsView;
  }>;
};

export type UpdateChargePointModeMutationVariables = Types.Exact<{
  mode: Types.ChargePointStationModeType;
  systemId: Types.Scalars['Int']['input'];
}>;

export type UpdateChargePointModeMutation = { updateChargePointSystemMode: boolean };

export type UpdateChargePointSettingsMutationVariables = Types.Exact<{
  systemId: Types.Scalars['Int']['input'];
  updateStationSettings: Types.UpdateChargePointStationSettings;
}>;

export type UpdateChargePointSettingsMutation = { updateChargePointSystemSettings: boolean };

export const GetChargingStationsOverviewAcrossSitesDocument = gql`
  query GetChargingStationsOverviewAcrossSites($siteIds: [Int!]!) {
    getCPStationSitesOverview(siteIds: $siteIds) {
      siteId
      stationsCount
      portsCount
      lastMonthUptime
      lastMonthUptimePercentChange
      lastMonthUtilization
      lastMonthUtilizationPercentChange
    }
  }
`;
export const GetChargingStationsMetricsDocument = gql`
  query GetChargingStationsMetrics($levels: [String], $portNumbers: [String!], $systemIds: [Int!]!) {
    getChargePointStationsMetrics(levels: $levels, portNumbers: $portNumbers, systemIds: $systemIds) {
      portId
      id
      measurement
      name
      relationType
      systemId
      type
      view
    }
  }
`;
export const UpdateChargePointModeDocument = gql`
  mutation UpdateChargePointMode($mode: ChargePointStationModeType!, $systemId: Int!) {
    updateChargePointSystemMode(mode: $mode, systemId: $systemId)
  }
`;
export const UpdateChargePointSettingsDocument = gql`
  mutation UpdateChargePointSettings($systemId: Int!, $updateStationSettings: UpdateChargePointStationSettings!) {
    updateChargePointSystemSettings(systemId: $systemId, updateStationSettings: $updateStationSettings)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetChargingStationsOverviewAcrossSites(
      variables: GetChargingStationsOverviewAcrossSitesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetChargingStationsOverviewAcrossSitesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetChargingStationsOverviewAcrossSitesQuery>(
            GetChargingStationsOverviewAcrossSitesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetChargingStationsOverviewAcrossSites',
        'query',
        variables
      );
    },
    GetChargingStationsMetrics(
      variables: GetChargingStationsMetricsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetChargingStationsMetricsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetChargingStationsMetricsQuery>(GetChargingStationsMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetChargingStationsMetrics',
        'query',
        variables
      );
    },
    UpdateChargePointMode(
      variables: UpdateChargePointModeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateChargePointModeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateChargePointModeMutation>(UpdateChargePointModeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateChargePointMode',
        'mutation',
        variables
      );
    },
    UpdateChargePointSettings(
      variables: UpdateChargePointSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateChargePointSettingsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateChargePointSettingsMutation>(UpdateChargePointSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateChargePointSettings',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
