import { ValidationError, ValidationResult } from 'joi';

import Logger from 'src/core/service/logger';

/**
 * This util is used to wrap Joi validationSchema.validateAsync request
 * and handle `.external` errors.
 * - Each validator used in `.external` should throw a proper ValidationError error
 * - If the validator used in `.external` will throw different error, it will be logged at the top of the screen
 *
 * @param promise validationSchema.validateAsync request
 * @returns returns the parsed object or throws proper Joi error
 */
export async function combineSchemaValidationResult<TSchema>(
  promise: Promise<TSchema>
): Promise<ValidationResult<TSchema>> {
  try {
    const result = await promise;
    return { value: result } as ValidationResult<TSchema>;
  } catch (error) {
    if (error instanceof ValidationError) {
      return { error, value: null };
    }
    throw Logger.error(error);
  }
}
