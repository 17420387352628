import classNames from 'classnames';

import { SystemType } from 'src/core/apollo/__generated__/resourcesGlobalTypes';
import { sequenceDisplayValue, SystemSequence, systemTypeToSequenceMap } from 'src/enums';
import { SystemSchedulerProfile } from 'src/modules/systems/gql/getSystemsForSite.resources.gql';
import { isDRTypeSequence } from 'src/modules/systems/utils/system.utils';
import { OptionItem, Select } from 'src/shared/components/Select';
import { CardLayout } from 'src/shared/containers/UniversalCard/UniversalCard';

import styles from './SequenceSelector.module.scss';

export interface SequenceSelectorProps extends React.PropsWithChildren {
  systemType: SystemType;
  disabled: boolean;
  sequenceId: SystemSequence;
  hideLabel?: boolean;
  labelPosition?: 'up' | 'inside';
  schedulerProfile?: SystemSchedulerProfile | null;
  layout: CardLayout;
  onChange: (value: SystemSequence) => void | Promise<void>;
}

const SequenceSelector: React.FC<SequenceSelectorProps> = (props) => {
  const nonSelectableSequence =
    props.sequenceId === SystemSequence.Local || isDRTypeSequence(props.sequenceId) ? props.sequenceId : null;
  const availableSystemSequences: OptionItem<SystemSequence>[] = nonSelectableSequence
    ? [
        {
          key: nonSelectableSequence,
          displayValue: sequenceDisplayValue[nonSelectableSequence],
        },
      ]
    : systemTypeToSequenceMap[props.systemType].map((sequence) => ({
        key: sequence,
        displayValue: sequenceDisplayValue[sequence],
      }));
  const disableSelect = nonSelectableSequence ? true : props.disabled;

  return (
    <div className={classNames(styles['sequence-wrapper'], styles[props.layout])}>
      <Select
        className={styles['sequence-select']}
        label={props.hideLabel ? undefined : 'Sequence'}
        labelPosition={props.hideLabel ? undefined : props.labelPosition ? props.labelPosition : 'inside'}
        disabled={disableSelect}
        options={
          props.layout === CardLayout.horizontal || !props.schedulerProfile
            ? availableSystemSequences
            : [{ key: 0, displayValue: props.schedulerProfile.name }]
        }
        value={props.layout === CardLayout.horizontal || !props.schedulerProfile ? props.sequenceId : 0}
        onClick={props.onChange}
      />
      {/* Assumed that only override button with modal is used as child, and it should not be displayed here */}
      {props.layout === CardLayout.horizontal ? null : props.children}
    </div>
  );
};

export default SequenceSelector;
