import { datetimeTypePolicy } from 'src/core/apollo/utils/type-policies';

export const scalarTypePolicies = {
  Alert: { fields: { createdAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy } },
  AlertOverview: { fields: { updatedAt: datetimeTypePolicy } },
  SiteAlertsOverview: { fields: { lastNotificationTime: datetimeTypePolicy } },
  UnresolvedNotification: { fields: { triggeredAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy } },
  UserNotification: {
    fields: { resolvedAt: datetimeTypePolicy, triggeredAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
};
