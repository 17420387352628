import { RouteObject } from 'react-router-dom';

import asLazyPage from 'src/cdk/HOCs/asLazyPage';
import { Routes } from 'src/routes';

export const staticRoutes: RouteObject[] = [
  {
    path: Routes.PrivacyPolicy,
    lazy: asLazyPage(() => import('./pages/PrivacyPolicyPage/PrivacyPolicyPage')),
  },
  {
    path: Routes.TermsOfUse,
    lazy: asLazyPage(() => import('./pages/TermsOfUsePage/TermsOfUsePage')),
  },
];
