import { useState } from 'react';

import { AppThunk } from 'src/core/store/store';

import { FetchStatus, useFetchOnMount } from './useFetchOnMount';

export interface FetchStatusWithData<T> extends FetchStatus {
  response?: T;
}

/**
 * That hook behaves in the same way as `useFetchOnMount` but also
 * it stores/caches response as internal state and returns
 */
export function useDataFetchOnMount<T = void>(fetch: AppThunk<T> | (() => Promise<T>)): FetchStatusWithData<T> {
  const [response, setResponse] = useState<T>();

  const fetchStatus = useFetchOnMount(fetch, setResponse);

  return {
    ...fetchStatus,
    response,
  };
}
