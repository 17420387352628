// In future this file will be removed, and backend will return gateway manufacturers with models

import { GatewayManufacturer, GatewayModel, GatewayVersion } from './apollo/__generated__/iotGlobalTypes';

export const GATEWAY_MANUFACTURER_MODEL_RELATION: Record<GatewayManufacturer, GatewayModel[]> = {
  [GatewayManufacturer.MILESIGHT]: [GatewayModel.UG56, GatewayModel.UG65, GatewayModel.UG67],
  [GatewayManufacturer.TBL]: [GatewayModel.NODE_S, GatewayModel.NODE_X],
  [GatewayManufacturer.TEKTELIC]: [GatewayModel.KONA_MICRO],
};

export const GATEWAY_MANUFACTURERS_BY_VERSION = {
  [GatewayVersion.AWS_IOT_LORAWAN]: [GatewayManufacturer.MILESIGHT, GatewayManufacturer.TEKTELIC],
  [GatewayVersion.TBL_V2]: [GatewayManufacturer.TBL],
  [GatewayVersion.TBL_V1]: [GatewayManufacturer.TBL],
};
