import _ from 'lodash';

import { MetricType } from '../apollo/__generated__/resourcesGlobalTypes';

// TODO: improve performance

type AggregationMethod = 'max' | 'mean' | 'sum';

const map: Partial<Record<MetricType, AggregationMethod>> = {
  [MetricType.ENERGY_CONSUMPTION]: 'sum',
  [MetricType.GAS_USAGE]: 'sum',
  [MetricType.STEAM_CONSUMPTION]: 'sum',
  [MetricType.WATER_CONSUMPTION]: 'sum',
  [MetricType.COOLING]: 'sum',
  [MetricType.COOLING_2]: 'sum',
  [MetricType.COOLING_3]: 'sum',
  [MetricType.FAN]: 'sum',
  [MetricType.HEATING]: 'sum',
  [MetricType.HEATING_2]: 'sum',
  [MetricType.HEATING_3]: 'sum',
  [MetricType.OCCUPANCY]: 'sum',
  [MetricType.LIGHT]: 'sum',
  [MetricType.POWER_DEMAND]: 'max',
  [MetricType.GAS_DEMAND]: 'max',
  [MetricType.STEAM_DEMAND]: 'max',
  [MetricType.WATER_DEMAND]: 'max',
};

/**
 * Converts a metric type to a method that should be used to aggregate the data.
 */
export function mapMetricToMethod(metric?: MetricType): AggregationMethod {
  return metric ? map[metric] ?? 'mean' : 'mean';
}

type VObject = {
  value?: number | null;
  min?: number | null;
  max?: number | null;
};

/**
 * @see https://tblbuildingsciences.atlassian.net/wiki/spaces/TBLO/pages/281772033/Handle+DST+changes
 *
 * @note This function mutates the original array, but you still need to use returned value
 *
 * @param arr Array of metric values to deduplicate
 * @param keyGetter Defines which values should be considered duplicates
 * @param methodGetter Defines which method should be used to aggregate the data (usually based on metric type)
 *
 * @returns Array of deduplicated values
 */
export function deDupMetrics<T extends VObject>(
  arr: T[],
  keyGetter: (item: T) => string,
  methodGetter: (item: T) => AggregationMethod
): T[] {
  const dupIndexes = findDuplicateIndexes(arr, keyGetter);
  if (_.isEmpty(dupIndexes)) {
    // To avoid unnecessary computations - return original array if there are no duplicates to process
    return arr;
  }
  const mergedData = mergeDuplicates(arr, dupIndexes, methodGetter);
  return mergedData;
}

/**
 * Mutates original array and merges duplicated values
 */
function mergeDuplicates<T extends VObject>(
  arr: T[],
  dupIndexes: number[][],
  methodGetter: (item: T) => AggregationMethod
): T[] {
  for (const di of dupIndexes) {
    const processedValue = arr[di[0]];
    const method = methodGetter(processedValue);
    if (method === 'sum') {
      for (const i of di.slice(1)) {
        processedValue.value = mergeValues(processedValue.value, arr[i].value);
        processedValue.min = _.min([processedValue.min, arr[i].min]);
        processedValue.max = _.max([processedValue.max, arr[i].max]);
        arr[i] = undefined as unknown as T;
      }
    }
    if (method === 'mean') {
      for (const i of di.slice(1)) {
        processedValue.value = mergeValues(processedValue.value, arr[i].value);
        processedValue.min = _.min([processedValue.min, arr[i].min]);
        processedValue.max = _.max([processedValue.max, arr[i].max]);
        arr[i] = undefined as unknown as T;
      }
      processedValue.value = processedValue.value ? processedValue.value / di.length : processedValue.value;
    }
    if (method === 'max') {
      for (const i of di.slice(1)) {
        processedValue.value = _.max([processedValue.value, arr[i].value]);
        processedValue.min = _.min([processedValue.min, arr[i].min]);
        processedValue.max = _.max([processedValue.max, arr[i].max]);
        arr[i] = undefined as unknown as T;
      }
    }
    arr[di[0]] = processedValue;
  }

  return arr.filter((item) => !_.isNil(item));
}

/**
 * Returns indexes of values where key ( defined by @see keyGetter ) is duplicated
 */
function findDuplicateIndexes<T>(arr: T[], keyGetter: (item: T) => string): number[][] {
  const indexMap = new Map<string, number[]>();
  const dupMap: Record<string, number[]> = {};

  for (let i = 0; i < arr.length; i++) {
    const value = arr[i];
    const key = keyGetter(value);

    if (indexMap.has(key)) {
      const existingIndexes = indexMap.get(key)!;
      existingIndexes.push(i);
      dupMap[key] = existingIndexes;
    } else {
      indexMap.set(key, [i]);
    }
  }

  indexMap.clear();

  return _.values(dupMap);
}

function mergeValues(a?: number | null, b?: number | null): number | null | undefined {
  if (_.isNil(a)) {
    return b;
  }

  if (_.isNil(b)) {
    return a;
  }

  return a! + b!;
}
