import { connectSdk, resourcesAPI, usersAPI, exportAPI } from 'src/core/apollo/api';

import { getSdk as getBillingExportSdk } from './__generated__/billing.export.generated';
import { getSdk as getLeaseSdk } from './__generated__/lease.resources.generated';
import { getSdk as getTenantUsersSdk } from './__generated__/tenantUser.users.generated';

const api = {
  ...connectSdk(resourcesAPI, getLeaseSdk),
  ...connectSdk(usersAPI, getTenantUsersSdk),
  ...connectSdk(exportAPI, getBillingExportSdk),
};

export default api;
