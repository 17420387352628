import _ from 'lodash';

import { N_A } from 'src/constants';
import { OptionItem } from 'src/shared/components/Select';

interface Settings {
  /**
   * By default does not forces unique key
   */
  uniqByKey?: boolean;
  /**
   * By default orders by displayValue
   */
  keepOriginalOrder?: boolean;
}

export function mapAsOptions<
  T,
  K extends keyof T = keyof T,
  D extends keyof T = keyof T,
  KD extends string | number | T[K] = T[K],
  C = unknown,
>(
  items: T[] | undefined = [],
  keyField: K | ((item: T) => KD),
  displayValueField: D | ((item: T) => string),
  configBuilder?: (item: T) => C,
  settings?: Settings
): OptionItem<KD, C>[] {
  let processedItems = _.chain(items);
  if (settings?.uniqByKey) {
    processedItems = processedItems.uniqBy(keyField);
  }

  let chain = processedItems
    .map((item) => ({
      key: keyField instanceof Function ? keyField(item) : (item[keyField] as KD),
      displayValue:
        displayValueField instanceof Function ? displayValueField(item) : _.get(item, displayValueField, N_A),
      config: configBuilder ? configBuilder(item) : undefined,
    }))
    .toArray();

  if (!settings?.keepOriginalOrder) {
    chain = chain.orderBy(['displayValue'], ['asc']);
  }

  return chain.value();
}

/**
 * Returns an array of OptionItem with grouped keys, so each OptionItem has an array of keys which have same display value
 */
export function mapAsGroupedOptions<
  T,
  K extends keyof T = keyof T,
  D extends keyof T = keyof T,
  KD extends string | number | T[K] = T[K],
  C = unknown,
>(
  items: T[] | undefined = [],
  keyField: K | ((item: T) => KD),
  displayValueField: D | ((item: T) => string),
  configBuilder?: (item: T) => C,
  settings?: Settings
): OptionItem<KD[], C[]>[] {
  let processedItems = _.chain(items);
  if (settings?.uniqByKey) {
    processedItems = processedItems.uniqBy(keyField);
  }

  let chain = processedItems
    .map((item) => ({
      key: keyField instanceof Function ? keyField(item) : (item[keyField] as KD),
      displayValue:
        displayValueField instanceof Function ? displayValueField(item) : _.get(item, displayValueField, N_A),
      config: configBuilder ? configBuilder(item) : undefined,
    }))
    .groupBy('displayValue')
    .map((value) => ({
      key: value.map((v) => v.key),
      displayValue: value[0].displayValue,
      config: configBuilder ? value.map((v) => v.config!) : undefined,
    }))
    .toArray();

  if (!settings?.keepOriginalOrder) {
    chain = chain.orderBy(['displayValue'], ['asc']);
  }

  return chain.value();
}

export function mapPairsAsOptions<K, V>(items: [K, V][], settings?: Settings): OptionItem<K>[] {
  return mapAsOptions(items, '0', '1', undefined, settings);
}

export function mapRecordAsOptions<K extends string>(object: Record<K, string>, settings?: Settings): OptionItem<K>[] {
  return mapPairsAsOptions(_.toPairs(object) as [K, string][], settings);
}
