import { gql } from '@apollo/client';

import { N_A } from 'src/constants';
import { resourcesAPI } from 'src/core/apollo/api';

import {
  convertBillOccurenceKeyToLabel,
  identifyBillOccurenceOption,
} from '../components/Adjustments/adjustmentBillPeriod.utils';

import {
  GetPendingAdjustmentsForSiteQuery,
  GetPendingAdjustmentsForSiteQueryVariables,
} from './__generated__/getPendingAdjustmentsForSite.resources.gql';

export type PendingAdjustment = Omit<
  GetPendingAdjustmentsForSiteQuery['getPendingAdjustmentsForSite'][0],
  '__typename'
> & {
  occurenceLabel: string;
};

const GET_PENDING_ADJUSTMENTS_FOR_SITE = gql`
  query GetPendingAdjustmentsForSite($siteId: Int!, $tenantIds: [Int!]) {
    getPendingAdjustmentsForSite(siteId: $siteId, tenantIds: $tenantIds) {
      id
      name
      leaseId
      tenantName
      description
      nextInvoiceDate
      leaseStartBillingDate
      startDate
      endDate
      amountPerInvoice
      updatedAt
      updatedBy
    }
  }
`;

export async function getPendingAdjustmentsForSite(
  siteId: number,
  currentTimeOnSite: Date,
  tenantIds?: number[]
): Promise<PendingAdjustment[]> {
  const result = await resourcesAPI.query<
    GetPendingAdjustmentsForSiteQuery,
    GetPendingAdjustmentsForSiteQueryVariables
  >({
    query: GET_PENDING_ADJUSTMENTS_FOR_SITE,
    variables: {
      siteId,
      tenantIds,
    },
  });

  return result.data.getPendingAdjustmentsForSite.map((item) => {
    const resultItem = {
      ...item,
      nextInvoiceDate: item.nextInvoiceDate,
      updatedAt: item.updatedAt,
      startDate: item.startDate,
      endDate: item.endDate ? item.endDate : null,
      leaseStartBillingDate: item.leaseStartBillingDate,
      occurenceLabel: N_A,
    };
    console.log(
      {
        leaseStartBillingDate: resultItem.leaseStartBillingDate,
        currentTimeOnSite,
        endDate: resultItem.endDate,
      },
      identifyBillOccurenceOption(resultItem.leaseStartBillingDate, currentTimeOnSite, resultItem.endDate)
    );
    resultItem.occurenceLabel = convertBillOccurenceKeyToLabel(
      identifyBillOccurenceOption(resultItem.leaseStartBillingDate, currentTimeOnSite, resultItem.endDate)
    );
    return resultItem;
  });
}
