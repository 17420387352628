import { datetimeTypePolicy, dateTypePolicy } from 'src/core/apollo/utils/type-policies';

export const scalarTypePolicies = {
  ConedOruMeterIntegration: {
    fields: {
      createdAt: datetimeTypePolicy,
      deletedAt: datetimeTypePolicy,
      expiry: datetimeTypePolicy,
      updatedAt: datetimeTypePolicy,
    },
  },
  DRConfigInfo: { fields: { updatedAt: datetimeTypePolicy } },
  DREvent: { fields: { endDate: datetimeTypePolicy, startDate: datetimeTypePolicy } },
  DRMeterIntegration: {
    fields: { createdAt: datetimeTypePolicy, deletedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  EnergyStarMeterIntegration: {
    fields: {
      createdAt: datetimeTypePolicy,
      deletedAt: datetimeTypePolicy,
      expiry: datetimeTypePolicy,
      updatedAt: datetimeTypePolicy,
    },
  },
  MeterIntegration: {
    fields: { createdAt: datetimeTypePolicy, deletedAt: datetimeTypePolicy, updatedAt: datetimeTypePolicy },
  },
  MetricFields: { fields: { timestamp: datetimeTypePolicy } },
  SiteDREventStatus: { fields: { eventEnd: datetimeTypePolicy, eventStart: datetimeTypePolicy } },
  SystemDRConfig: { fields: { updatedAt: datetimeTypePolicy } },
  UtilityAPIMeterIntegration: {
    fields: {
      createdAt: datetimeTypePolicy,
      deletedAt: datetimeTypePolicy,
      expiry: datetimeTypePolicy,
      updatedAt: datetimeTypePolicy,
    },
  },
  UtilityAuthorization: { fields: { createdAt: datetimeTypePolicy, expiry: datetimeTypePolicy } },
  UtilityIntegration: { fields: { connectedAt: datetimeTypePolicy, expiryAt: datetimeTypePolicy } },
  UtilityInvoice: {
    fields: {
      billingMonth: dateTypePolicy,
      created_at: datetimeTypePolicy,
      endDate: dateTypePolicy,
      startDate: dateTypePolicy,
      statementDate: dateTypePolicy,
      updated_at: datetimeTypePolicy,
    },
  },
  UtilityMeterList: { fields: { authorizationCreatedAt: datetimeTypePolicy } },
  UtilityMetricData: { fields: { timestamp: datetimeTypePolicy } },
};
