/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ApolloClient, FetchPolicy, NormalizedCacheObject } from '@apollo/client';
import type { GraphQLClient } from 'graphql-request';

const QUERY = 'query';
const MUTATION = 'mutation';

type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

/**
 * This utility function provides ability to connect Apollo Client to SDK generated with codegen
 */
export function connectSdk<T>(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  sdkBuilder: (client: GraphQLClient, withWrapper: SdkFunctionWrapper) => T,
  fetchPolicy?: FetchPolicy
): T {
  const client = {
    request(documentNode: any, variables: any, { operationName, operationType, ...headers }: any) {
      switch (operationType) {
        case QUERY:
          return apolloClient
            .query({
              query: documentNode,
              fetchPolicy,
              variables,
            })
            .then((r) => r.data);
        case MUTATION:
          return apolloClient
            .mutate({
              mutation: documentNode,
              variables,
            })
            .then((r) => r.data);
        default:
          throw new Error('Not supported');
      }
    },
  } as unknown as GraphQLClient;

  return sdkBuilder(client, (action, operationName, operationType) =>
    action({ operationName, operationType: operationType ?? QUERY })
  );
}
