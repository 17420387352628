import { alertsAPI, connectSdk } from 'src/core/apollo/api';

import { GetAlertQuery, getSdk as getDetailsSdk } from './__generated__/details.alerts.generated';
import { getSdk as getNotificationsSdk } from './__generated__/notifications.alerts.generated';
import { getSdk as getOverviewSdk } from './__generated__/overview.alerts.generated';

export type AlertRecipient = GetAlertQuery['alertDetails']['recipients'][0];

const api = {
  ...connectSdk(alertsAPI, getNotificationsSdk),
  ...connectSdk(alertsAPI, getOverviewSdk),
  ...connectSdk(alertsAPI, getDetailsSdk),
};

export default api;
