import type { CognitoUser } from '@aws-amplify/auth';

import Logger from 'src/core/service/logger';
import { resetPermissionsSlice } from 'src/logic/users/permissionsSlice';

import { resetFiltersSlice, setFilterConfigurationsForAllPages } from '../../modules/filters/filtersSlice';
import { resetIaqSlice } from '../../modules/iaq/iaqSlice';
import { resetOrganizationsSlice } from '../../modules/organizations/organizationsSlice';
import { resetSchedulerSlice } from '../../modules/scheduler/schedulerSlice';
import { fetchSelectedSites } from '../../modules/sites/sitesActions';
import { resetSitesSlice } from '../../modules/sites/sitesSlice';
import { resetSystemsSlice } from '../../modules/systems/systemsSlice';
import { exportAPI, iotAPI, logsAPI, notificationsAPI, resourcesAPI, usersAPI, utilityAPI } from '../apollo/api';
import { resetGlobalSlice, setUserWithConstraints } from '../store/global/globalSlice';
import { UserConstraints, getCurrentUserConstraints } from '../store/global/gql/getCurrentUserConstraints.users.gql';
import store from '../store/store';

import authService from './authService';
import { OneMinuteObservedInterval } from './oneMinuteObservedInterval';
import { memoryRouteStorage, memoryStorage } from './persistentStorage';

/**
 * Perform logout action and all lifecycle hooks, like cleanup storages, cache, etc.
 */
export async function logout(): Promise<void> {
  Logger.info('onLogout');

  // Stop global reusable interval
  OneMinuteObservedInterval.stop();

  try {
    await authService.signOut();

    resourcesAPI.clearStore();
    usersAPI.clearStore();
    notificationsAPI.clearStore();
    logsAPI.clearStore();
    utilityAPI.clearStore();
    iotAPI.clearStore();
    exportAPI.clearStore();
    memoryStorage.clear();
    memoryRouteStorage.clear();
    store.dispatch(resetFiltersSlice());
    store.dispatch(resetGlobalSlice());
    store.dispatch(resetIaqSlice());
    store.dispatch(resetOrganizationsSlice());
    store.dispatch(resetSchedulerSlice());
    store.dispatch(resetSitesSlice());
    store.dispatch(resetSystemsSlice());
    store.dispatch(resetPermissionsSlice());
    import('src/logic/notifications/userNotificationsSubscriptionService').then(
      ({ userNotificationsSubscriptionService }) => {
        userNotificationsSubscriptionService.unsubscribe();
      }
    );
  } catch (e) {
    Logger.error('Logout failed!', e as Error);
  }
}

/**
 * Perform login action and all lifecycle hooks, like fetch current user, etc.
 *
 * Note: Login should be called always after app init.
 */
export async function login(user: CognitoUser | null = null, loggedInFromLoginPage = false): Promise<void> {
  Logger.info('onLogin');

  if (!user) {
    try {
      user = await authService.getUser();
    } catch (error) {
      Logger.warn('Cannot get current user from Cognito');
      Logger.warn('Get user during login failed!', error as Error);

      // Logout if cannot get current user from Cognito
      logout();

      return;
    }
  }

  // Start global reusable interval
  if (!OneMinuteObservedInterval.isRunning()) {
    OneMinuteObservedInterval.start();
  }

  let constraints: UserConstraints | undefined;

  try {
    constraints = await getCurrentUserConstraints(loggedInFromLoginPage);
    if (loggedInFromLoginPage) {
      Logger.success('Successfully logged in');
    }
  } catch (error) {
    Logger.warn('Logged in successfully, but services are not available, please try again later');

    // Logout if cannot get current user from Cognito
    logout();

    return;
  }

  // Ensure that there is only one dispatch of global slice in the end,
  // to prevent extra re-render on app init
  const userData = await authService.getDataFromUser(user);
  store.dispatch(
    setUserWithConstraints({
      constraints,
      user: userData,
    })
  );

  store.dispatch(setFilterConfigurationsForAllPages(constraints.filterConfigs ?? []));

  // Ensure that cached selected site info is loaded, because it is needed for navbar
  // and user may open app on administration page
  store.dispatch(fetchSelectedSites());

  // Fetch notifications after login in async way, because it is not very important data
  import('src/logic/alerts/alertsActions').then(({ fetchMyUnresolvedNotifications }) => {
    store.dispatch(fetchMyUnresolvedNotifications());
  });

  import('src/logic/notifications/userNotificationsSubscriptionService').then(
    ({ userNotificationsSubscriptionService }) => {
      userNotificationsSubscriptionService.subscribe();
    }
  );
}

/**
 * Show popup when server throws exception
 */
export async function handleServerException(message: string): Promise<void> {
  Logger.error(`Server exception: ${message}`);
}
